<template>
  <section
    v-if="hasOrganization && userAccessReady && userRole"
    class="admin-main"
    :class="{ 'drawer--open': getter_is_side_menu_open_desktop_ui }"
  >
    <template v-if="navigatingOrganization">
      <div v-if="userRole === 'admin-super'">
        <admin-super></admin-super>
      </div>

      <div v-if="userRole === 'admin-organization'">
        <admin-organization :userRole="userRole" :drawerOpenPopupStyle="drawerOpenPopupStyle"></admin-organization>
      </div>

      <div v-else>
        <p class="title mt-5 px-5">{{ $t("all.noAccessToAdminTools") }}</p>
      </div>
    </template>

    <template v-else-if="userRole === 'admin-organization' || userRole === 'admin-group'">
      <admin-workspace-group-main
        v-if="navigatingWorkspaceGroup"
        :drawerOpenPopupStyle="drawerOpenPopupStyle"
      ></admin-workspace-group-main>

      <admin-workspace-main v-else :drawerOpenPopupStyle="drawerOpenPopupStyle"></admin-workspace-main>
    </template>
  </section>
</template>

<script>
import Vue from "vue";
import MixinDB from "./MixinDB.vue";
import MixinUser from "./MixinUser.vue";
import AdminSuper from "@/components/AdminSuper.vue";
import AdminOrganization from "@/components/AdminOrganization.vue";
import AdminGroup from "@/components/AdminGroup.vue";
import AdminWorkspaceGroupMain from "@/components/DAdmin/workspaceGroup/AdminWorkspaceGroupMain.vue";
import AdminWorkspaceMain from "@/components/DAdmin/workspaceGroup/AdminWorkspaceMain.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "admin-main",
  components: {
    AdminSuper,
    AdminOrganization,
    AdminGroup,
    AdminWorkspaceGroupMain,
    AdminWorkspaceMain,
  },
  mixins: [MixinDB, MixinUser],
  data() {
    return {
      hasOrganization: false,
      navigatingOrganization: true,
      navigatingWorkspaceGroup: false,
    };
  },
  computed: {
    ...mapGetters("global", ["getters_user_access_ready", "getter_is_side_menu_open_desktop_ui"]),
    organization() {
      if (this.userProfile[0]) {
        return this.$store.state.userProfile[0].organization;
      }
    },
    userRole() {
      if (this.userProfile[0]) {
        return this.$store.state.userProfile[0].role;
      }
    },
    userProfile() {
      //get user profile from vuex
      return this.$store.state.userProfile;
    },
    userAccessReady() {
      return this.getters_user_access_ready;
    },
    drawerOpenPopupStyle() {
      return this.getter_is_side_menu_open_desktop_ui
        ? { left: "calc(0px - var(--side-bar-width))", width: "calc(100% + var(--side-bar-width))", top: "-70px" }
        : {};
    },
  },
  created() {
    this.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getUserProfileFromVuex();
        if (!this.$organization || !this.clientKey || !this.clientSettings) {
          this.getUserRole(user).then(() => {
            this.hasOrganization = true;
          });
        }
      }
    });
  },
  async mounted() {
    this.setVisibility(this.$route.params);
    await this.getWorkspaceGroupList({ workspacesType: "admin" });
  },
  methods: {
    ...mapActions("workspaceGroups", ["getWorkspaceGroupList"]),
    getUserProfileFromVuex() {
      Vue.prototype.$user = this.auth().currentUser;
      this.$store.dispatch("getUserProfile", this.$user);
    },
    setVisibility(routeParams) {
      const { workspaceGroupId, workspaceId } = routeParams;
      const isAdminGroup = this.userRole === "admin-group";

      this.navigatingOrganization = isAdminGroup ? false : !workspaceGroupId && !workspaceId;
      this.navigatingWorkspaceGroup = !!workspaceGroupId;

      if (isAdminGroup && !workspaceGroupId && !workspaceId) {
        this.$router.push({
          name: "AdminWorkspaceGroupWorkspaces",
          params: { workspaceGroupId: this.$defaultWorkspaceGroupId },
        });
      }
    },
  },
  watch: {
    $route: function (to) {
      this.setVisibility(to.params);
    },
    userRole: function (role) {
      if (role === "admin-group") {
        const { workspaceGroupId, workspaceId } = this.$route.params;
        this.navigatingOrganization = false;
        if (!workspaceGroupId && !workspaceId) {
          return this.$router.push("/navigator/home");
        }
        this.navigatingWorkspaceGroup = !!workspaceGroupId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-main {
  position: fixed;
  top: 70px;
  left: 0px;
  height: calc(100% - 70px);
  width: 100%;
  background-color: #fbfbfb;
  color: #78767a;
  overflow: hidden;
  transition: all 0.3s;
  &.drawer--open {
    left: var(--side-bar-width);
    width: calc(100% - var(--side-bar-width));
    transform: translate(0);
    height: 100vh;
    overflow: inherit;
  }
}
</style>
