<template>
  <div class="d-admin-permissions">
    <div class="content-title">{{ $t("admin.masterPassword") }}</div>
    <div class="permissions-scroll">
      <div class="switch-control-div mt-3">
        <div class="products-title">{{ $t("admin.employeeIDAccess") }}</div>
        <p class="products-subtitle">{{ $t("admin.employeeIDAccessMsg") }}</p>
        <p v-if="isPending || isResting" class="pending-save-msg">
          <span>{{ isPending ? $t("admin.savePasswordMsg") : $t("admin.resetPasswordMsg") }}</span>
        </p>
        <div v-if="showRestBtn" class="password-input">
          <div class="password-form mr-3">
            <d-text-field
              v-model="currentPassword"
              readonly
              :append-icon="showCurrentPassword ? 'visibility' : 'visibility_off'"
              :type="showCurrentPassword ? 'text' : 'password'"
              :label="$t('admin.currentPassword')"
              @click-append="clickShowCurrentPassword()"
              autocomplete="chrome-off"
              :key="'currentPassword' + showCurrentPassword"
            ></d-text-field>
          </div>
          <v-btn
            depressed
            round
            class="body-2 text-capitalize white--text no-transform save-btn"
            color="#4689f4"
            @click="resetPassword()"
            >{{ $t("auth.resetPassword") }}</v-btn
          >
        </div>
        <div v-if="isPending || isResting" class="password-input">
          <v-form autocomplete="off" v-model="formValid" class="password-form mr-3">
            <d-text-field
              v-model="employeeIdMP"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :rules="[rules.validPassword, rules.noWhiteSpace, rules.max]"
              :type="showPassword ? 'text' : 'password'"
              :label="$t('auth.password')"
              :disabled="!employeeMPEnabled"
              @click-append="showPassword = !showPassword"
              autocomplete="chrome-off"
              key="newPassword"
            ></d-text-field>
          </v-form>
          <v-btn
            depressed
            round
            class="body-2 text-capitalize white--text no-transform save-btn"
            color="#4689f4"
            :disabled="!formValid"
            @click="savePassword()"
            >{{ $t("all.save") }}</v-btn
          >
          <v-btn
            v-if="isResting"
            depressed
            round
            outline
            class="body-2 text-capitalize no-transform save-btn ml-3"
            color="#4689f4"
            @click="cancelResetPassword()"
            >{{ $t("all.cancel") }}</v-btn
          >
        </div>
        <div class="switch-div">
          <v-switch
            v-model="employeeMPEnabled"
            color="#4689F3"
            class="vertical-center"
            :readonly="true"
            @click.stop="clickEmployeeMPEnabledSwitch()"
          ></v-switch>
        </div>
      </div>
    </div>

    <d-change-permission-confirmation
      v-if="showConfirmation == 'reset-password'"
      :title="$t('admin.resetMasterPassword')"
      :content="$t('admin.resetMasterPasswordMsg')"
      :style="drawerOpenPopupStyle"
      @close-popup="showConfirmation = ''"
      @change-permission="enableMasterPassword()"
    ></d-change-permission-confirmation>

    <d-change-permission-confirmation
      v-if="showConfirmation == 'enable-password'"
      :title="$t('admin.enableMasterPassword')"
      :content="$t('admin.employeeIDAccessMsg')"
      :actionBtn="$t('admin.yesEnable')"
      :style="drawerOpenPopupStyle"
      @close-popup="showConfirmation = ''"
      @change-permission="enableMasterPassword()"
    ></d-change-permission-confirmation>

    <d-change-permission-confirmation
      v-if="showConfirmation == 'disable-password'"
      :title="$t('admin.disableMasterPassword')"
      :content="$t('admin.disableMasterPasswordMsg')"
      :actionBtn="$t('admin.yesDisable')"
      :style="drawerOpenPopupStyle"
      @close-popup="showConfirmation = ''"
      @change-permission="disableMasterPassword()"
    ></d-change-permission-confirmation>

    <d-alert v-model="alert.isSuccess" type="success" :message="alert.alertText"></d-alert>
    <d-alert v-model="alert.isFailed" type="error" :message="alert.alertText"></d-alert>
  </div>
</template>

<script>
import Vue from "vue";
import IconBase from "@/components/IconBase.vue";
import DIconDone from "@/components/icons/DIconDone.vue";
import DChangePermissionConfirmation from "@/components/ui_components/DChangePermissionConfirmation.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import DTextField from "@/components/ui_components/DTextField.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import { updateOrganizationSettings, getEmployeeIdMP } from "@/server/organization-settings-server.js";
import { isEnableMasterPassword } from "@/js/employeeid-login/employeeid-login-service.js";

export default {
  name: "AdminMasterPassword",
  props: {
    drawerOpenPopupStyle: Object,
  },
  watch: {},
  mixins: [MixinUser, MixinDB],
  created() {
    this.customizedPermissions = this.getCustomizedPermissions();
    this.initPageDataAfterFetchingDataFromDB();
  },
  mounted() {},
  data() {
    return {
      showConfirmation: "",
      employeeMPEnabled: null,
      customizedPermissions: null,
      showPassword: false,
      showCurrentPassword: false,
      currentPassword: "******",
      currentPasswordInDb: "",
      employeeIdMP: "",
      formValid: false,
      rules: {
        noWhiteSpace: (value) => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        required: (value) => !!value || this.$t("rules.required"),
        max: (v) => (v && v.length <= 25) || this.$t("rules.max25"),
        min: (v) => (v && v.length >= 6) || this.$t("rules.min6"),
        validPassword: (value) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_+=[\]{}|\\,.?:"';<>/-])[^]{10,}$/;
          return (
            pattern.test(value) || this.$t("rules.validPasswordMin10")
            // "Password needs to be at least 10 characters, include lower and upper case letter, number, and special character."
          );
        },
      },
      alert: {
        isSuccess: false,
        isFailed: false,
        alertText: "",
      },
      isPending: false,
      showRestBtn: false,
      isResting: false,
    };
  },
  components: {
    IconBase,
    DIconDone,
    DChangePermissionConfirmation,
    DAlert,
    DTextField,
  },
  methods: {
    clickShowCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
      this.currentPassword = this.showCurrentPassword ? this.currentPasswordInDb : "******";
    },
    cancelResetPassword() {
      this.isResting = false;
      this.showRestBtn = true;
    },
    resetPassword() {
      this.isResting = true;
      this.showRestBtn = false;
    },
    clickEmployeeMPEnabledSwitch() {
      if (this.isPending && this.employeeMPEnabled) {
        this.isPending = false;
        this.employeeMPEnabled = false;
        return;
      }
      if (this.employeeMPEnabled) {
        // show turn feature off popup
        this.showConfirmation = "disable-password";
      } else {
        // enable password field, Master Password won't set until password is saved;
        this.employeeMPEnabled = true;
        this.isPending = true;
      }
    },
    async initPageDataAfterFetchingDataFromDB({ recursiveCount } = {}) {
      if (recursiveCount > 10) {
        console.error("recursive count is over limit", recursiveCount);
        return;
      }

      const IsNeedToWaitUntilMixinUserHasInitialized = this.$clientKey === undefined;
      if (IsNeedToWaitUntilMixinUserHasInitialized) {
        setTimeout(() => {
          this.initPageDataAfterFetchingDataFromDB({
            recursiveCount: (recursiveCount || 0) + 1,
          });
        }, 300);
        return;
      }

      const masterPassword = await isEnableMasterPassword({
        clientKey: this.$clientKey,
      });

      const { employeeIdMP } = await getEmployeeIdMP({ clientKey: this.$clientKey });

      this.employeeMPEnabled = masterPassword.isEnabled;
      this.updateCurrentPasswordFromDB({
        newPassword: employeeIdMP,
      });

      if (masterPassword.isEnabled) {
        this.showRestBtn = true;
      }
    },
    savePassword() {
      if (this.isPending) {
        this.showConfirmation = "enable-password";
        return;
      }
      if (this.isResting) {
        this.showConfirmation = "reset-password";
      }
    },
    updateCustomizedPermissionsToDb() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .updateDocument("settings", self.$organization, {
            customizedPermissions: self.customizedPermissions,
          })
          .then(() => {
            Vue.prototype.$clientSettings.customizedPermissions = self.customizedPermissions;
            self.updateIdleSetting(
              self.customizedPermissions.allowAutoLogout,
              self.customizedPermissions.autoLogoutTimeout
            );
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    disableMasterPassword() {
      updateOrganizationSettings({
        organization: this.$organization,
        updateData: {
          employeeIdMPEnabled: false,
          employeeIdMP: null,
        },
      }).then((response) => {
        if (response) {
          this.employeeMPEnabled = false;
          this.employeeIdMP = "";
          this.showConfirmation = "";
          this.isPending = false;
          this.isResting = false;
          this.showRestBtn = false;

          this.alert.alertText = this.$t("admin.alertDisablePasswordSuccess");
          this.alert.isSuccess = true;
        } else {
          this.alert.alertText = this.$t("admin.alertDisablePasswordFailed");
          this.alert.isFailed = true;
        }
      });
    },
    updateCurrentPasswordFromDB({ newPassword }) {
      this.currentPasswordInDb = newPassword;
      if (this.showCurrentPassword) {
        this.currentPassword = newPassword;
      }
    },
    enableMasterPassword() {
      const newPassword = this.employeeIdMP;
      updateOrganizationSettings({
        organization: this.$organization,
        updateData: {
          employeeIdMPEnabled: true,
          employeeIdMP: this.employeeIdMP,
        },
      }).then((response) => {
        if (response) {
          this.employeeMPEnabled = true;
          this.isPending = false;
          this.showConfirmation = "";
          this.employeeIdMP = "";
          this.isResting = false;
          this.showRestBtn = true;

          this.updateCurrentPasswordFromDB({
            newPassword,
          });

          this.alert.alertText = this.$t("admin.alertEnablePasswordSuccess");
          this.alert.isSuccess = true;
        } else {
          this.alert.alertText = this.$t("admin.alertEnablePasswordFailed");
          this.alert.isFailed = true;
        }
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
.d-admin-permissions {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  text-align: left;
}
.permissions-scroll {
  position: absolute;
  width: 100%;
  height: calc(100% - 84px);
  top: 84px;
  left: 0;
  overflow: auto;
  padding: 0 32px 64px 32px;
}
.permissions-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.content-title {
  color: #3f3f3f;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 20px;
}
.products-title {
  color: #3f3f3f;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 4px;
}
.products-subtitle {
  color: #919191;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 4px 0;
}
.pending-save-msg {
  height: 20px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #4689f3;
}
.switch-control-div {
  position: relative;
  width: 100%;
  min-width: 760px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
  padding: 24px 88px 24px 24px;
}
.switch-div {
  position: absolute;
  right: 24px;
  top: 0;
  height: 80px;
  width: 36px;
}
.logout-switch-div {
  position: absolute;
  right: 24px;
  top: 12px;
  height: 50px;
  width: 36px;
}
.vertical-center {
  position: absolute;
  top: 50%;
  top: calc(50% - 6px);
  transform: translateY(-50%);
}
.password-input {
  display: inline-flex;
  height: 100px;
  width: 100%;
  margin-top: 52px;
}
.save-btn {
  min-width: 120px;
  margin: 28px 0 0 0;
}
.password-form {
  width: 320px;
}
</style>
