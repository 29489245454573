<template>
  <div class="settings-categories">
    <div
      v-for="cat in categories"
      :key="cat.id"
      class="category-box"
      :class="{ 'hide-category': !cat.subcategories || cat.subcategories.length == 0 }"
    >
      <div
        class="category-label"
        :class="{
          'category-label--selected': categorySelectedMap[cat.id] && categorySelectedMap[cat.id].length > 0,
        }"
      >
        {{ cat.name }}
      </div>
      <div class="arrow-div">
        <button>
          <v-icon color="#8D909F">keyboard_arrow_down</v-icon>
        </button>
      </div>
      <div class="open-category-selector" @click="openMenu(cat)"></div>
      <div class="selected-div">
        <div v-for="item in categorySelectedMap[cat.id]" :key="item.id" class="selected-chip">
          <span class="chip-text text-no-wrap">
            {{ item.name }}
            <button class="unselect-btn" @click="$emit('toggle-category-item', { item })">
              <icon-base color="#FFFFFF" height="8" width="8">
                <d-icon-close2 />
              </icon-base>
            </button>
          </span>
        </div>
      </div>
      <div
        v-if="openCatId === cat.id"
        class="selector-div"
        @mouseover="mouseoverMenu = true"
        @mouseleave="mouseoverMenu = false"
      >
        <d-category-searchable-selector
          class="d-category-selector"
          dark
          open
          block
          :categoryId="cat.id"
          :categoryName="cat.name"
          :selectedItems="selectedItems"
          @add-category-item="$emit('toggle-category-item', $event)"
          @remove-category-item="$emit('toggle-category-item', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconClose2 from "../icons/DIconClose2.vue";
import DCategorySearchableSelector from "../ui_components/DCategorySearchableSelector.vue";

export default {
  name: "BuilderCategoriesForm",
  props: {
    categories: Array,
    categorySelectedMap: Object,
    selectedItems: Array,
  },
  components: {
    IconBase,
    DIconClose2,
    DCategorySearchableSelector,
  },
  mixins: [],
  watch: {
    selectedItems: function () {
      if (this.selectedItems.length > 0) {
        this.madeChanges = true;
      } else {
        this.madeChanges = false;
      }
    },
  },
  updated() {},
  data: function () {
    return {
      mouseoverMenu: false,
      openCatId: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    openMenu(category) {
      this.openCatId = category.id;
    },
    closeMenu() {
      if (this.openCatId != "" && !this.mouseoverMenu) {
        this.openCatId = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.settings-categories {
  position: relative;
}
.category-box {
  position: relative;
  width: 100%;
  min-height: 54px;
  border-bottom: 1px solid #52545d;
  text-align: left;
  margin-bottom: 22px;
}
.hide-category {
  display: none;
}
.category-label {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 28px;
  text-transform: capitalize;
  width: calc(100% - 30px);
  word-break: break-all;
  &--selected {
    font-size: 12px;
    line-height: 14px;
    padding-top: 0px;
  }
}
.arrow-div {
  position: absolute;
  right: 0;
  top: 26px;
}
.selected-div {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4px;
  pointer-events: none;
}
.selected-chip {
  position: relative;
  height: 28px;
  border-radius: 14px;
  background-color: #52545d;
  padding: 0 30px 0 12px;
  margin-right: 4px;
  margin-top: 6px;
  pointer-events: auto;
  overflow: hidden;
}
.chip-text {
  display: inline-block;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
}
.unselect-btn {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  height: 28px;
  line-height: 20px;
  padding: 0 12px 0 8px;
}
.open-category-selector {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.selector-div {
  position: absolute;
  top: 2px;
  width: 100%;
  z-index: 9;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
}
.d-category-selector {
  position: relative;
  width: 100% !important;
}
</style>
