<template >
  <div class="d-admin-setting">
    <div class="setting-list-div">
      <button
        v-if="$access.categoriesCreation"
        class="dh-setting-categories setting-list-button"
        :class="{'selected-item': showContent=='categories'}"
        @click="showContent='categories'"
        autotest="workflow-categories-btn"
      >
        <icon-base width="16" height="16" class="setting-list-icon">
          <d-icon-keyword />
        </icon-base>

        <div class="setting-list-text">{{$t('admin.workflowCategories')}}</div>
      </button>
      <button
        v-if="$access.languages"
        class="dh-setting-languages setting-list-button"
        :class="{'selected-item': showContent=='languages'}"
        @click="showContent='languages'"
        autotest="default-language-btn"
      >
        <icon-base width="16" height="16" class="setting-list-icon">
          <d-icon-translation />
        </icon-base>

        <div class="setting-list-text">{{$t('all.defaultInterfaceLanguage')}}</div>
      </button>
      <button
        v-if="$access.permissions"
        class="dh-setting-permissions setting-list-button"
        :class="{'selected-item': showContent=='permissions'}"
        @click="showContent='permissions'"
        autotest="permissions-btn"
      >
        <icon-base width="16" height="16" class="setting-list-icon">
          <d-icon-circle-lock />
        </icon-base>
        <div class="setting-list-text">{{$t('admin.permissions')}}</div>
      </button>
      <button
        v-if="getter_has_employee_id_login && $access.masterPassword"
        class="dh-setting-sso-mapping setting-list-button"
        :class="{'selected-item': showContent=='master-password'}"
        @click="showContent='master-password'"
      >
        <icon-base width="15" height="15" class="setting-list-icon">
          <d-icon-key />
        </icon-base>
        <div class="setting-list-text">{{$t('admin.masterPassword')}}</div>
      </button>
      <button
        v-if="$access.ssoMapping && showSsoMapping"
        class="dh-setting-sso-mapping setting-list-button"
        :class="{'selected-item': showContent=='sso-mapping'}"
        @click="showContent='sso-mapping'"
      >
        <icon-base width="16" height="16" class="setting-list-icon">
          <d-icon-user-outline />
        </icon-base>
        <div class="setting-list-text">{{$t('admin.ssoMapping')}}</div>
      </button>
      <button
        v-if="$access.companyLogo"
        class="dh-setting-sso-mapping setting-list-button"
        :class="{'selected-item': showContent=='company-logo'}"
        @click="showContent='company-logo'"
      >
        <icon-base width="16" height="16" class="setting-list-icon">
          <d-icon-user-outline />
        </icon-base>
        <div class="setting-list-text">{{$t('admin.qrCodeAndWatermark')}}</div>
      </button>
    </div>
    <div class="setting-content-div">
      <admin-categories v-if="$access.categoriesCreation && showContent=='categories'"></admin-categories>
      <admin-languages v-if="$access.languages && showContent=='languages'"></admin-languages>
      <d-admin-permissions v-if="$access.permissions && showContent=='permissions'"></d-admin-permissions>
      <admin-master-password v-if="getter_has_employee_id_login && showContent=='master-password'"></admin-master-password>
      <d-admin-sso-mapping v-if="$access.ssoMapping && showContent=='sso-mapping'"></d-admin-sso-mapping>
      <admin-company-logo v-if="$access.companyLogo && showContent=='company-logo'" />
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconUserOutline from "@/components/icons/DIconUserOutline.vue";
import DAdminSsoMapping from "@/components/ui_components/DAdminSsoMapping.vue";
import DIconUserLine from "@/components/icons/DIconUserLine.vue";
import DAdminPermissions from "@/components/ui_components/DAdminPermissions.vue";
import AdminCategories from "@/components/DAdmin/AdminCategories.vue";
import AdminLanguages from "@/components/DAdmin/AdminLanguages.vue";
import AdminMasterPassword from "@/components/DAdmin/Settings/AdminMasterPassword.vue";
import AdminCompanyLogo from "@/components/DAdmin/Settings/CompanyLogo/AdminCompanyLogo.vue";
import DIconCircleLock from "@/components/icons/DIconCircleLock.vue";
import DIconTranslation from "@/components/icons/DIconTranslation.vue";
import DIconKeyword from "@/components/icons/DIconKeyword.vue";
import DIconKey from "@/components/icons/DIconKey.vue";
import { mapGetters } from "vuex";

export default {
  name: "DAdminSettings",
  props: {},
  watch: {},
  mounted() {
    if (
      this.$clientSettings.products &&
      this.$clientSettings.products.ssoMapping
    ) {
      this.showSsoMapping = true;
    }
    if (this.$access.categoriesCreation) {
      this.showContent = "categories";
    } else {
      this.showContent = "permissions";
    }
  },
  data() {
    return {
      showContent: "categories",
      showSsoMapping: false
    };
  },
  components: {
    IconBase,
    DIconUserOutline,
    DAdminSsoMapping,
    DIconUserLine,
    DAdminPermissions,
    AdminCategories,
    AdminLanguages,
    AdminMasterPassword,
    AdminCompanyLogo,
    DIconCircleLock,
    DIconTranslation,
    DIconKeyword,
    DIconKey
  },
  methods: {},
  computed: {
    ...mapGetters("permission", ["getter_has_employee_id_login"]),
  }
};
</script>

<style scoped>
.d-admin-setting {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}
.setting-list-div {
  position: relative;
  height: 100%;
  width: 260px;
  padding: 24px 32px 32px 56px;
  text-align: left;
}
.setting-list-button {
  position: relative;
  color: #4a4a4a;
  cursor: pointer;
  margin: 16px 0;
  width: 100%;
}
.setting-list-button:hover {
  color: #4689f3;
}
.selected-item {
  color: #4689f3;
}
.setting-list-icon {
  position: absolute;
  left: 0;
  top: 0;
}
.setting-list-text {
  position: relative;
  width: 100%;
  padding-left: 22px;
  padding-top: 1px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
}
.setting-content-div {
  position: relative;
  height: 100%;
  width: calc(100% - 260px);
}
</style>
