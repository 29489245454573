module.exports = {
  ad: {
    highlights: "Points forts",
    highlightsIntro: "Ici, vous aurez toujours une vision rapide des nouvelles communiquées de fonctionnalités, comme celle-ci. De plus, Stephanie vous rappellera et aidera à garder une trace de votre formation professionnelle continue.",
    recommendations: "Recommandations",
    recommendationsIntro: "Stephanie recommandera des flux de travail en fonction de vos activités. Elle apprendra sur chaque interaction que vous avez avec elle!",
    favoritesIntro: "Vous êtes-vous déjà demandé à quel point il serait génial d'avoir tous les flux de travail dont vous avez le plus besoin, juste là, vous attendez ?! Maintenant, vous avez un endroit spécial pour les garder prêts à partir!",
    categoriesIntro: "Tant de flux de travail, si peu de temps ... ne serait-il pas agréable de simplement cliquer et d'afficher des catégories qui vous concernent? Maintenant vous pouvez! :)",
    loginMsgTitle: "Nouvelle mise à jour des fonctionnalités!",
    loginMsg: "Vous pouvez maintenant rechercher facilement pour tes espaces de travail. De plus, consultez les recommandations de contenu conçues pour vous aider à utiliser de la meilleure façon votre compte DeepHow."
  },
  all: {
    success: "Succès",
    player: "Lecteur",
    editor: "Éditeur",
    admin: "Administrateur",
    analytics: "Analytique",
    skills: "Compétences",
    skill: "Compétence",
    unpublished: "Non publié",
    published: "Publié",
    signOut: "Déconnexion",
    step: "étape",
    steps: "pas",
    numStep: "0 étape | 1 étape | {count} étapes",
    transcription: "Transcription",
    no: "Non",
    yes: "Oui",
    delete: "effacer",
    diagram: "diagramme",
    all: "Tous",
    discard: "jeter",
    save: "sauvegarder",
    next: "Suivant",
    process: "Traiter",
    cancel: "Annuler",
    close: "Fermer",
    allUsers: "Tous les utilisateurs",
    users: "Utilisateurs",
    user: "utilisateur",
    numUsers: " utilisateurs",
    numUser: " utilisateur",
    countUsers: "0 utilisateur | 1 utilisateur | {count} utilisateurs",
    workspaces: "Espaces de travail",
    workspace: "Espace de travail",
    workspaceGroup: "Groupe d'espace de travail",
    workflows: "Flux de travail",
    workflow: "Flux de travail",
    organization: "Organisation",
    business: "Entreprise",
    create: "créer",
    name: "Nom",
    namePerson: "Nom",
    email: "E-mail",
    employeeId: "ID d'employé",
    role: "rôle",
    organizationAdmin: "Administrateur de l'organisation",
    workspaceAdmin: "Administrateur de l'espace de travail",
    publisher: "Éditeur",
    viewer: "Téléspectateur",
    organizationAdmins: "Administrateurs de l'organisation",
    workspaceAdmins: "Administrateurs de l'espace de travail",
    publishers: "Éditeurs",
    viewers: "Les spectateurs",
    add: "Ajouter",
    attachments: "accessoires",
    private: "privé",
    public: "Publique",
    content: "Contenu",
    noRecordFound: "Aucun enregistrement trouvé",
    loadingRecords: "Chargement des enregistrements ...",
    teams: "Équipes",
    loadedAllRecords: "Tous les enregistrements chargés",
    language: "Langue",
    interfaceLanguage: "Langue de l'interface",
    personalInfo: "Informations personnelles",
    privacyPolicy: "Politique de confidentialité",
    team: "Équipe",
    unselect: "Désélectionné",
    select: "Sélectionner",
    applied: "Appliqué",
    noAccessToAdminTools: "Ce compte d'utilisateur n'a actuellement pas accès aux outils d'administration.",
    changePhoto: "Changer la photo",
    removePhoto: "Retirer photo",
    profilePhoto: "Photo de profil",
    takeOrUpload: " Prendre ou télécharger une photo.",
    useCamera: "Utiliser la caméra",
    uploadPhoto: "Envoyer la photo",
    editPhoto: "Modifier photo",
    retake: "Reprendre",
    takePhoto: "Prendre une photo",
    countWorkspaces: "Espace de travail | Espace de travail | Espaces de travail",
    whatsNew: "Quoi de neuf?",
    howToUse: "Comment utiliser",
    defaultInterfaceLanguage: "Langue d'interface par défaut",
    newVersionSnackbarText: "Une nouvelle version de DeePhow est disponible.",
    refresh: "rafraîchir",
    unpublishedList: "Non publié",
    publishedList: "Publié",
    userAgreement: "Accord de l'utilisateur",
    numAttachments: "0 accessoire | 1 accessoire | {count} accessoires",
    attachment: "accessoire",
    knowledgeBase: "Base de connaissances DeepHow",
    changeName: "Changer de nom",
    changePassword: "Changer le mot de passe",
    openInApp: "Ouvert à Deephow Navigator",
    userNewToDeepHow: "Êtes-vous nouveau dans DeepHow?",
    downloadTheApp: "Télécharger l'application",
    continueToUseBrowser: "Continuer à utiliser le navigateur",
    contentManager: "Gestionnaire de contenu",
    gen2Forbidden: "En tant qu'utilisateur Gen2 Light, vous ne pouvez pas vous connecter pour le moment.",
    confirm: "Confirmer",
    myWorkspaceGroup: "Mes groupes d'espace de travail",
    otherWorkspaceGroup: "Autres groupes d'espace de travail",
    searchResult: "Résultats de recherche",
    noResultsFound: "Aucun résultat trouvé.",
    browseWorkspace: "Parcourir l'espace de travail...",
    version: "version",
  },
  password: {
    createYourAccount: "Créez votre compte",
    checkNameChoosePassword: "Vérifiez votre nom et choisissez un mot de passe",
    createAccount: "Créer un compte",
    createNewPassword: "Créer un nouveau mot de passe",
    selectNewPassword: "Sélectionnez un nouveau mot de passe pour votre compte",
    newPassword: "Nouveau mot de passe",
    confirmNewPassword: "Confirmer le nouveau mot de passe",
    passwordMustMatch: "Le mot de passe doit correspondre.",
    confirm: "Confirmer",
    passwordCreatedMsg: "Votre nouveau mot de passe a été créé, <br> Veuillez patienter pendant que nous vous redirige ...",
    goToDeepHowPlayer: "Aller au lecteur de DeepHow",
    failedResetPassword: "Impossible de réinitialiser le mot de passe. Votre compte a déjà été créé ou il y a un problème de réseau.",
    alertSetPasswordFailed: "Échec de la définition du mot de passe. Votre compte a déjà été créé ou il y a un problème de réseau.",
    linkExpired: "Ce lien a expiré",
    passwordExpired: "Votre lien de réinitialisation de mot de passe a expiré.",
    inviteExpired: "Votre invitation a expiré.",
    resetPasswordMsg: "Cliquez sur le bouton ci-dessous pour demander un email de réinitialisation du mot de passe.",
    // added 09/08/2022
    alertFailedResetPassword: "Échec de la réinitialisation du mot de passe.",
    alertSuccessfullyResetPassword: "Réinitialisez avec succès le mot de passe, veuillez patienter pendant que nous vous redirigeons pour vous connecter à nouveau… <br><br> ou cliquez sur le bouton ci-dessous.",
    newPasswordNotTheSame: "Le nouveau mot de passe ne doit pas être le même que l'ancien.",
    alertCurrentPasswordIncorrect: "Mot de passe actuel incorrect",
    alertTooManyAttempts: "Trop de tentatives pour valider le mot de passe actuel. Réessayez plus tard.",
  },
  analytics: {
    overview: "Aperçu",
    engagement: "Engagement",
    activeUsers: "Utilisateurs actifs",
    workflowsCreated: "Flux de travail créés",
    workflowsPublished: "Flux de travail publiés",
    workflowViews: "Vues de flux de travail",
    timeCreated: "Temps créé",
    timePublished: "Temps publié",
    timeViewed: "Temps vu",
    searchWorkspace: "Recherche d'espaces de travail ...",
    mostUsers: "La plupart des utilisateurs",
    mostWorkflowsCreated: "La plupart des flux de travail créés",
    numberOfUsers: "Nombre d'utilisateurs",
    organizationOverview: "Aperçu de l'organisation",
    numWorkflowsIn: "Flux de travail dans",
    searchWorkflow: "Recherche de workflows ...",
    createdBy: "Créé par",
    usersIn: "Utilisateurs dans",
    searchUser: "Rechercher des utilisateurs ...",
    watchTime: "Temps de regarder",
    workflowsViewed: "Flux de travail vue",
    percentViewed: "% Vu",
    dateViewed: "Date de vue",
    watchWorkflow: "Regarder le flux de travail",
    backToUsers: "Retour aux utilisateurs",
    backToWorkflows: "Retour aux flux de travail",
    likes: "Aime",
    avgTimeViewed: "Avg. Temps vu",
    numberofViews: "Nombre de vues",
    workspacesPerPage: "Espaces de travail par page",
    usersPerPage: "Utilisateurs par page",
    workflowsPerPage: "Flux de travail par page",
    exportTable: "Table d'exportation",
    Top10Viewers: "Top Téléspectateurs",
    Top10Publishers: "Top Éditeurs",
    totalViews: "Vues totales",
    msgNoDataYet: "Pas de données disponibles",
    msgNoSearchResults: "Aucun enregistrements correspondants trouvés",
    hms: "(H:M:S)",
    viewHistory: "Voir l'historique",
    views: "Vues",
    totalViewingTime: "Temps de visionnage total",
    usersViewedThisWorkflow: "<b>0</ b> L'utilisateur a vu ce flux de travail | <b>1</b> L'utilisateur a vu ce flux de travail | <b>{count}</ b> Les utilisateurs ont vu ce flux de travail",
    date: "Date",
    // added 2022/07/11
    dashboards: "Tableaux de bord",
    reactions: "Réactions",
    userReaction: "Réaction de l'utilisateur",
    viewAll: "Voir tout",
    favorites: "Favoris",
  },
  admin: {
    status: "Statut",
    active: "Actif",
    inactive: "Inactif",
    invited: "Invité",
    invitationExpired: "Invitation expirée",
    couldNotInvited: "Ne pouvait pas être invité",
    hintSearchUsers: "Rechercher les utilisateurs ...",
    hintSearchWorkspaces: "Rechercher Espace de travail ...",
    hintSearchBusiness: "Rechercher des affaires ...",
    alertSuccessfullyInviteUser: "Invité avec succès l'utilisateur.",
    alertFailedInviteUser: "Impossible d'inviter l'utilisateur.",
    alertSuccessfullyDeletedUser: "Supprimé avec succès l'utilisateur.",
    alertFailedDeleteUser: "Échec de la suppression de l'utilisateur.",
    alertSuccessfullyCreatedWorkspace: "Créé avec succès Espace de travail.",
    alertSuccessfullyEditedWorkspace: "Espace de travail édité avec succès.",
    alertSuccessfullyCreatedBusiness: "Créé avec succès.",
    alertSuccessfullyEditedBusiness: "Entreprise modifiée avec succès.",
    alertUserInvitationSuccessfullyResent: "Invitation utilisateur Ventiler avec succès.",
    alertFailedResendUserInvitation: "Impossible de renvoyer une invitation utilisateur.",
    alertUserInvitationSuccessfullyDelete: "Invitation utilisateur supprimée avec succès.",
    alertFailedDeleteUserInvitation: "Échec de la suppression d'une invitation utilisateur.",
    resendInvitation: "Renvoyer l'invitation",
    popupMsgResendInvitation: "Êtes-vous sûr de vouloir renvoyer une invitation à",
    popupBtnSend: "Oui envoyer",
    deleteInvitation: "Supprimer l'invitation",
    popupMsgDeleteInvitation: "Êtes-vous sûr de vouloir supprimer l'invitation à",
    addUser: "Ajouter un utilisateur",
    msgNoMembersYet: "Cette organisation n'a pas encore de membres.",
    msgNoRecordsAllUsers: "Aucun enregistrement correspondant trouvé dans tous les utilisateurs.",
    createWorkspace: "Créer un espace de travail",
    msgNoWorkspacesYet: "Cette organisation n'a pas encore d'espaces de travail.",
    msgNoRecordsWorkspaces: "Aucun enregistrement correspondant trouvé dans les espaces de travail.",
    msgNoRecordsWorkspacesGroupAdmin: "Ce compte n'a pas encore d'espaces de travail.",
    createBusiness: "Créer des affaires",
    msgNoBusinessYet: "Cette organisation n'a pas encore d'entreprise.",
    msgNoRecordsBusiness: "Aucun enregistrement correspondant trouvé dans les affaires.",
    usersIn: "Utilisateurs dans",
    tableMsgNoUsersYet: "Cet espace de travail n'a pas encore de membres.",
    tableMsgNoRecordsThisWorkspace: "Aucun enregistrement correspondant trouvé dans cet espace de travail.",
    memberAt: "Membre de",
    alerBusinessAlreadyInSystem: "Affaires déjà dans le système",
    alerFailedAddBusiness: "Impossible d'ajouter des affaires.",
    alertFailedEditBusiness: "Échec de la modification des affaires.",
    alertWorkspaceAlreadyInSystem: "Ce nom d'espace de travail est déjà dans le système.",
    aletFailedCreateWorkspace: "Impossible de créer l'espace de travail.",
    alertFailedEditWorkspace: "Échec de la modification de l'espace de travail.",
    resendAllInvites: "Renvoyer tous les invitations",
    resendAllInvitesMsg: "Vous enverrez une invitation à toutes les invitations en attente et expirées. Es-tu sûr?",
    yesSendInvites: "Oui, envoyez des invitations",
    businessesPerPage: "Entreprises par page",
    //add org user
    popupTitleAddOrgUser: "Ajouter l'utilisateur de l'organisation Admin",
    invite: "Inviter",
    popupTitleNewUserInvited: "Nouvel utilisateur invité!",
    popupMsgNewUserInvitedOrg: "a été invité. <br> Après avoir validé le compte, il aura accès à cette organisation.",
    popupTitleExistingUserInvited: "Utilisateur existant ajouté!",
    popupMsgExistingUserInvitedOrg: "a été ajouté à cette organisation.",
    popupBtnAddAnotherUser: "Ajouter un autre utilisateur",
    alertFailedCheckPendingInvitations: "Impossible de vérifier si l'utilisateur a en attente d'invitations.",
    alertFailedAddExistingUserAsOrganizationAdmin: "Impossible d'ajouter un utilisateur existant en tant qu'organisation admin",
    alertAddExistingUserAsOrganizationAdmin: "Ajouter un utilisateur existant en tant qu'organisation admin.",
    alertUserAlreadyOrganizationAdmin: "Utilisateur déjà dans le système comme administrateur d'organisation.",
    alertFailedCheckUserAlreadyInSystem: "Échec de la vérification de l'utilisateur déjà dans le système.",
    //Add user to workspace
    addUserToWorkspace: "Ajouter l'utilisateur à l'espace de travail",
    companyEmail: "E-mail de la société",
    employeeIdOnly: "ID d'employé seulement",
    fullName: "Nom complet",
    workspaceName: "Nom de l'espace de travail",
    businessName: "Nom d'entreprise",
    popupMsgNewUserInvited: "a été invité. <br> Après avoir validé le compte, il aura accès à cet espace de travail.",
    popupMsgExistingUserInvited: "a été ajouté à cet espace de travail.",
    popupTitleNewUserCreated: "Nouvel utilisateur créé!",
    popupTitleUserAlreadyinSystem: "Utilisateur déjà dans le système",
    alertUserIsAlreadyInWorkspace: "Cet utilisateur ({info}) est déjà dans cet espace de travail.",
    alertPendingInvitation: "L'utilisateur a une invitation en attente. Veuillez attendre que l'utilisateur accepte l'invitation actuelle.",
    searchingforUsers: "À la recherche d'utilisateurs ...",
    noResultsFound: "Aucun résultat trouvé.",
    // Change User Role
    popupTitleChangeUserRole: "Changer le rôle d'utilisateur",
    popupMsgChangeUser: "Voulez-vous vraiment changer le rôle de <b>{name}</b> de <b>{oldRole}</b> à <b>{newRole}</b>?",
    popupBtnChange: "Oui, changez",
    alertUpdatedUserSettings: "Paramètres utilisateur mis à jour avec succès.",
    // Remove User
    popupTitleRemoveUser: "Supprimer l'utilisateur de l'espace de travail",
    popupMsgRemoveUserFromWorkspace: "Voulez-vous vraiment supprimer <b>{name}</b> de l'espace de travail ?",
    popupBtnRemove: "Oui, supprimer",
    alertRemoveUser: "Supprimé avec succès l'utilisateur de l'espace de travail.",
    // Delete User
    popupTitleDeleteUser: "Supprimer l'utilisateur du système",
    popupMsgDeleteUserFromAllWorkspaces: "Si vous faites cela, vous perdrez tous les enregistrements concernant cet utilisateur <b>{name}</b> et il sera supprimé de tous les espaces de travail et rôles qui lui sont attribués.",
    popupBtnDelete: "Oui, supprimer",
    tooltipEditUser: "Modifier l'utilisateur",
    tooltipResendInvite: "Renvoyer inviter",
    tooltipCancelInvite: "Annuler l'invitation",
    tooltipRemoveUser: "Supprimer l'utilisateur",
    // settings
    settings: "Paramètres",
    location: "lieu",
    hireDate: "Date d'embauche",
    department: "département",
    position: "position",
    site: "site",
    userId: "Identifiant d'utilisateur",
    ssoMapping: "Cartographie SSO",
    ssoMappingNoData: "Il n'y a pas encore de données à la carte. <br /> Vous devez télécharger le CSV via FTP, veuillez contacter le support.",
    attributes: "Les attributs",
    oneAttributeToMap: "Vous pouvez uniquement sélectionner un attribut pour mapper les utilisateurs dans des espaces de travail",
    selectAttribute: "Sélectionner l'attribut",
    changeAttribute: "Attribut de changement",
    discardChanges: "Annuler les modifications",
    applyChanges: "Appliquer les modifications",
    applyChangesMsg: "Vous allez apporter des modifications aux espaces de travail suivants, êtes-vous sûr?",
    mappingValuesUpdated: "Valeurs de mappage mis à jour",
    mappingValuesUpdatedSubtitle: "Afin de mettre à jour avec succès le mappage, vous devez appliquer des modifications. Vous pouvez le faire lorsque vous avez fini de modifier tous les espaces de travail.",
    values: "Valeurs",
    clickPlusToAddValues: "Cliquez sur l'icône Plus pour ajouter des valeurs",
    selectValues: "Sélectionner des valeurs",
    searchForValues: "Recherche de valeurs ...",
    assign: "Attribuer",
    assignValues: "Attribuer des valeurs",
    addUsersBtn: "Oui, ajoutez des utilisateurs",
    YesChangeBtn: "Oui, changez",
    numMore: "{count} Plus",
    removeValues: "Supprimer les valeurs",
    remove: "Supprimer",
    changeAttributeMessage: "Êtes-vous sûr de vouloir changer d'attribut? <br/> Vous perdrez toute la cartographie réelle que vous avez pour cet attribut.",
    discardChangesMessage: "Vous allez perdre toutes les modifications que vous avez faites depuis votre dernière sauvegarde, êtes-vous sûr?",
    discardChangesBtn: "Oui, jeter les changements",
    applyChangesBtn: "Oui, appliquer des changements",
    alertDiscardedSuccess: "Mappage avec succès mis au rebut.",
    alertAppliedSuccess: "Cartographie appliquée avec succès.",
    alertAppliedFailed: "Échec de l'application de la cartographie.",
    //permissions
    permissions: "Autorisation",
    products: "Produits DeepHow",
    roleAccessToProduct: "Définir ci-dessous, quels rôles d'utilisateur pourront accéder à chaque produit",
    analytics: "Analytique",
    skillsManager: "Gestion des compétences",
    skillsPlayer: "Lecteur de compétences",
    workflowCapture: "Capture de flux de travail",
    workflowEditor: "Éditeur de flux de travail",
    organizationAdmin: "Organisation admin",
    groupAdmin: "Administrateur d'espace de travail",
    publisher: "Éditeurs",
    viewer: "Téléspectateur",
    rolesPermission: "Permission de rôles",
    rolesPermissionMessage: "Êtes-vous sûr de vouloir modifier l'autorisation de ce produit? <br/> Les modifications seront effectuées immédiatement.",
    inviteUser: "Inviter l'utilisateur",
    workflowSharing: "Partage de flux de travail",
    allowUserstoSharePublicLinks: "Permettre aux utilisateurs de partager des liens publics",
    changeWorkflowSharingExplanation: "En désactivant le partage des liens publics, les flux de travail publics ne seront accédés que par les utilisateurs qui sont connectés à la plate-forme.",
    allowWorkflowSharingMessage: "Êtes-vous sûr de vouloir activer le partage des liens publics?",
    preventWorkflowSharingMessage: "Êtes-vous sûr de vouloir désactiver le partage des liens publics? Les changements seront effectués immédiatement.",
    workflowDownload: "Téléchargement de flux de travail",
    allowUsersToDownloadWorkflows: "Autoriser les utilisateurs à télécharger des flux de travail",
    allowDownloadWorkflowsMessage: "Êtes-vous sûr de vouloir permettre aux utilisateurs de télécharger des flux de travail?",
    preventDownloadWorkflowsMessage: "Êtes-vous sûr de vouloir empêcher les utilisateurs de télécharger des flux de travail?",
    autoLogout: "Déconnexion automatique",
    autoLogoutAfterSelectedTime: "Les utilisateurs seront automatiquement déconnés après l'heure sélectionnée.",
    numMinutes: "0 minute | 1 minute | {count} minutes",
    changeLogoutTimeMessage: "Êtes-vous sûr de vouloir modifier le temps de déconnexion automatique à {num} minutes?",
    enableAutoLogout: "Êtes-vous sûr de vouloir activer la déconnexion automatique?",
    disableAutoLogout: "Êtes-vous sûr de vouloir désactiver la déconnexion automatique?",
    changesMadeImmediately: "Les changements seront effectués immédiatement.",
    sessionIsAboutToExpire: "Votre session est sur le point d'expirer.",
    youWillBeLoggedOutNumSeconds: "Vous serez déconnecté dans 0 seconde | Vous serez déconnecté dans {num} secondes",
    continueSession: "Continuer la session",
    //system languages
    languages: "Langues",
    systemLanguage: "Langue du système",
    searchForWorkspaces: "Recherche d'espaces de travail ...",
    workspacesPerPage: "Espaces de travail par page",
    workspace: "Espace",
    workspaceLanguage: "Langue",
    changeLanguage: "Changer de langue",
    popupMsgChangeLanguage: "Le changement de langue affectera tous les utilisateurs de ce flux de travail, êtes-vous sûr de vouloir changer?",
    alertLanguageSuccessfullyChanged: "Changé avec succès",
    alertFailedChangeLanguage: "Impossible de changer de langue",
    // Categories
    categories: "Catégories",
    alertCategoriesNotSaved: "Catégories non enregistrées, réessayez plus tard.",
    noCategoryResult: "Aucun résultat de recherche de catégorie",
    noCategoriesAddedYet: "Pas de catégories encore ajoutées",
    categoriesAssignment: "Affectation des catégories",
    categoryPlaceholder: "Catégories de recherche et sous-catégories ...",
    previouslyUsed: "Précédemment utilisé",
    allCategories: "Toutes catégories",
    workflowCategories: "Catégories de flux de travail",
    searchForCategories: "Recherche de catégories ...",
    categoryItems: "éléments",
    categoryItemsNum: "éléments",
    createCategory: "Créer une catégorie",
    editCategory: "Catégorie d'édition",
    categoryName: "Nom",
    noCategoriesCreatedYet: "Aucune catégorie créée encore.",
    noItemsCreatedYet: "Aucun éléments créé encore.",
    categoriesPerPage: "Catégories par page",
    itemsPerPage: "objets par page",
    alertCategoryAlreadyInSystem: "Catégorie déjà dans le système.",
    alertFailedAddCategory: "Impossible d'ajouter une catégorie.",
    alertFailedEditCategory: "Échec de la modification de la catégorie.",
    deleteCategory: "Supprimer la catégorie",
    popupMsgDeleteCategory: "Êtes-vous sûr de vouloir supprimer la catégorie",
    itemsIn: "Articles dans",
    searchForItems: "Recherche d'élémentss ...",
    createItem: "Créer un éléments",
    editItem: "Modifier l'éléments",
    deleteItem: "Effacer l'éléments",
    itemName: "Nom",
    alertItemAlreadyInSystem: "Article déjà dans le système.",
    alertFailedAddItem: "Impossible d'ajouter un élément.",
    alertSuccessAddItem: "Avec succès pour ajouter un éléments.",
    alertFailedEditItem: "Échec de la modification de l'élément.",
    alertSuccessEditItem: "Avec succès pour éditer l'élément.",
    //Teams
    teamsPerPage: "Équipes par page",
    max1000UsersPerTeam: "* Max 1000 utilisateurs par équipe.",
    usersSelected: "0 utilisateur sélectionné | 1 utilisateur sélectionné | {count} utilisateurs sélectionnés",
    selectAll: "Tout sélectionner",
    removeAll: "Enlever tout",
    createTeam: "Créer une équipe",
    editTeam: "Éditeur",
    teamName: "Nom",
    teamsIn: "Des équipes dans",
    searchForTeams: "Recherche de équipes ...",
    searchForUsers: "Recherche d'utilisateurs ...",
    noUsersAddedYet: "Aucun utilisateur n'a encore ajouté à votre équipe.",
    dateAdded: "date ajoutée",
    alertTeamAlreadyInSystem: "Équipe déjà dans le système.",
    alertFailedEditTeam: "Échec de la modification de l'équipe.",
    deleteTeam: "Supprimer l'équipe",
    popupMsgDeleteTeam: "Vous supprimerez l'équipe <b> {name} </b>. Êtes-vous sûr de vouloir faire ça?",
    noTeamsCreatedYet: "Aucune équipe créée encore.",
    popupMsgDeleteItem: "Êtes-vous sûr de vouloir supprimer l'éléments",
    removeUser: "Supprimer l'utilisateur",
    popupMsgRemoveUser: "Vous supprimerez <b> {name} </b> de cette équipe. Êtes-vous sûr de vouloir faire ça?",
    alertErrorGettingWorkspaceList: "Erreur lors de la liste des espaces de travail.",
    alertSuccessfullyCreatedTeam: "A créé avec succès l'équipe",
    alertFailedCreatedTeam: "Omis de créer l'équipe.",
    alertSuccessfullyUpdatedTeam: "Mis à jour avec succès l'équipe.",
    alertSuccessfullyRemovedUserFromTeam: "Supprimé avec succès l'utilisateur de l'équipe.",
    alertFailedRemovedUserFromTeam: "Échec de la suppression de l'utilisateur de l'équipe.",
    alertSuccessfullyAddedUserToTeam: "Ajouté avec succès l'utilisateur à l'équipe.",
    alertFailedAddedUserToTeam: "Impossible d'ajouter l'utilisateur à l'équipe.",
    alertWarningAddedUserToTeamAlready: "L'utilisateur a déjà été ajouté à l'équipe.",
    //added 2022/02/03
    alertAllInvitationResentSuccess: "Toutes les invitations ont été envoyé avec succès.",
    alertAllInvitationResentFailed: "Impossible de renvoyer toutes les invitations.",
    alertCheckUserInSystemFailed: "Impossible de vérifier si l'utilisateur est déjà dans le système.",
    alertOrganizationAdminAssignedFailed: "L'administrateur d'organisation ne peut pas être attribué à un espace de travail",
    //added 2022/02/07
    alertFailedDeleteItem: "Échec de la suppression de l'élément.",
    alertSuccessfullyDeletedItem: "Élément supprimé avec succès.",
    alertCategoryDeleteFailed: "Échec de la suppression de la catégorie.",
    alertSuccessfullyDeletedCategory: "Catégorie supprimée avec succès.",
    //added 2022/02/08
    upgradeToOrgAdmin: "Mise à niveau vers org. Admin",
    yesUpgrade: "Oui, mettez à niveau",
    popupMsgUpgradeToOrgAdmin: "Êtes-vous sûr de vouloir mettre à niveau <b>{name}</b>? Org. Les administrateurs ont un accès complet à configurer les paramètres de votre organisation et bien plus encore ...",
    alertFailedUpgradeToOrg: "Échec de la mise à niveau vers Org. Admin.",
    alertSuccessfullyUpgradeToOrg: "Mis à niveau avec succès vers Org. Admin.",
    // TODO added 2022/02/17
    userActive: "Utilisateur actif?",
    profile: "Profil",
    // TODO: added 2022/02/09 settings > Master Password
    masterPassword: "Mot de passe maître",
    enableMasterPassword: "Activer le mot de passe principal",
    disableMasterPassword: "Désactiver le mot de passe principal",
    resetMasterPassword: "Réinitialiser le mot de passe principal",
    employeeIDAccess: "Accès ID d'employé",
    employeeIDAccessMsg:"Si vous activez cette fonctionnalité, les utilisateurs qui n'accédaient qu'avec l'identifiant d'employé devront entrer ce mot de passe pour accéder à DeepHow.",
    disableMasterPasswordMsg:"Si vous éteignez cette fonctionnalité, les utilisateurs qui n'accédaient qu'avec l'identifiant d'employé ne devront pas entrer un mot de passe pour accéder à DeepHow.",
    resetMasterPasswordMsg:"Les utilisateurs qui n'accédaient qu'avec l'identifiant d'employé devront utiliser ce nouveau mot de passe pour accéder à DeepHow.",
    insertPassword: "Insérer le mot de passe",
    alertEnablePasswordFailed: "Impossible d'activer le mot de passe principal",
    alertEnablePasswordSuccess: "Mot de passe principal activé avec succès.",
    alertDisablePasswordFailed: "Impossible de désactiver le mot de passe principal.",
    alertDisablePasswordSuccess: "Mot de passe principal désactivé avec succès.",
    savePasswordMsg: "Cette fonctionnalité ne sera pas activée tant que votre mot de passe n'est pas enregistré avec succès.",
    resetPasswordMsg: "Entrez un nouveau mot de passe principal et enregistrez.",
    currentPassword: "Mot de passe actuel",
    yesEnable: "Oui, activer",
    yesDisable: "Oui, désactiver",
    downgradeOrgAdmin: "Rétrograder l'administrateur de l'organisation",
    popupMsgDowngradeOrgAdmin: "Êtes-vous sûr de vouloir rétrograder <b>{name}</b>? Il sera dégradé au rôle que vous avez sélectionné dans tous les espaces de travail qu'il appartient.",
    selectRole: "Sélectionnez le rôle de cet utilisateur",
    yesDowngrade: "Oui, rétrograder",
    alertFailedDowngradeToOrg: "Échec de la modification du rôle en {userRole}",
    alertSuccessfullyDowngradeToOrg: "a changé le rôle avec succès en {userRole}",
    lastActivity: "Dernière connexion",
    coverColor: "Couleur de couverture",
    defaultLanguage: "Llangage par défaut",
    blue: "Bleu",
    red: "Rouge",
    green: "Vert",
    yellow: "Jaune",
    purple: "Mauve",
    belongsToWorkspaces: "Appartient aux espaces de travail",
    alertFailedSaveChanges: "Llangage par défaut.",
    // TODO: added 04/02
    alertFailedRemoveUser: "Llangage par défaut.",
    // TODO: added 0608
    privateOrnot: "privé?",
    privateWorkspaceInfo: "Cet espace de travail ne sera vu que par les membres qui y sont affectés…",
    noPermission: "Vous n'êtes pas autorisé à voir cela.",
    noPermissionContact: "La page auquel vous essayez d'accéder a un accès restreint. <br> Si vous pensez que c'est une erreur, contactez votre administrateur.",
    privateCannotChanged: "Private setting cannot be changed after the workspace is created.",
    // Shared Account
    sharedAccount: "Compte partagé",
    addManually: "Ajouter manuellement",
    addASharedAccount:"Ajouter un compte partagé",
    addASharedAccountMsg: "Les comptes partagés ne peuvent pas être utilisés pour regarder les flux de travail individuellement, ils ne sont utilisés que pour les utilisateurs d'identification des employés pour créer des comptes à DeepHow et garder une trace de chaque utilisateur dans des appareils partagés ...",
    accountName: "Nom du compte",
    alertFailedAddShared: "Échec de l'ajout de compte partagé",
    alertSuccessfullyCreatedShared: "Compte partagé créé avec succès",
    alertSuccessfullyAddExistingShared: "Ajout de compte partagé existant avec succès",
    accountId: "identifiant de compte",
    addSharedAccount:"Ajouter un compte partagé",
    sharedAccountId:"ID de compte partagé",
    unselectedTeam: "équipe non sélectionnée",
    // TODO: added 2022/06/22
    workflowReview: "Examen du workflow",
    workflowReviewDescription: "Si cette option est allumée, les administrateurs de l'espace de travail pourront choisir si leurs espaces de travail nécessitent une revue de workflow ou non",
    preventReviewWorkflowsMessage: "Êtes-vous sûr d'empêcher les utilisateurs d'examiner les workflows?",
    allowReviewWorkflowsMessage: "Êtes-vous sûr de souhaiter que les utilisateurs examinent les workflows?",
    // TODO: added 2022/06/29
    addAsReviewer: "Ajouter en tant que réviseur",
    removeAsReviewer: "Supprimer en tant que réviseur",
    addAsReviewerSuccessMessage: "Ajouter {user} en tant que critique avec succès.",
    addAsReviewerFailedMessage: "Impossible d'ajouter {user} comme examinateur.",
    removeAsReviewerSuccessMessage: "Supprimer {user} en tant que critique avec succès.",
    removeAsReviewerFailedMessage: "Échec de la suppression de {user} en tant que réviseur.",
    // Shared Account Admin settings 07/27
    allowsSharedAccountMsg: "Permettez aux administrateurs de l'organisation et de l'espace de travail de créer des comptes partagés. Les utilisateurs pourront s'auto-signer à l'aide de leurs comptes d'ID d'employé dans un appareil partagé.",
    disableSharedAccountMsg: "Êtes-vous sûr de vouloir désactiver le compte partagé? <br> Des modifications seront apportées immédiatement.",
    enableSharedAccountMsg: "Êtes-vous sûr d'activer le compte partagé? <br> Des modifications seront apportées immédiatement.",
    alertEnableSharedAccountFailed: "Échec de l'activation du compte partagé.",
    alertEnableSharedAccountSuccess: "Compte partagé permis avec succès.",
    alertDisableSharedAccountFailed: "Échec de désactiver le compte partagé.",
    alertDisableSharedAccountSuccess: "Compte partagé avec succès désactivé.",
    // QR Code & Watermark
    qrCodeAndWatermark: "Code QR et filigrane",
    insertYourBrand: "Insérez votre marque",
    uploadYourLogo: "Téléchargez votre logo",
    uploadingYourLogo: "Téléchargement de votre logo",
    logoSuccessfullyUploaded: "Logo Téléchargé avec succès",
    errorInUpload: "Erreur dans votre téléchargement",
    errorInUploadMsg: "Type de fichier non pris en charge. Veuillez sélectionner le type de fichier suivant: .png",
    uploadLogoMsg: "Faites glisser et déposez un <b>.png</b> avec un fichier maximum <b>3 MB</b> ou <span>parcourir</span> depuis l'ordinateur.",
    uploadLogoReplaceMsg: "Glisser ou <span>parcourir</span> pour remplacer par une image de l'ordinateur ou <span id='DUploadImage_removeLogo'>supprimer</span> l'image actuelle.",
    simulation: "Simulation",
    simulationMsg: "Voir ci-dessous comment votre logo sera présenté dans le filigrane des vidéos et dans les codes QR.",
    workflowTitle: "Titre du workflow",
    logoAlreadyUploaded: "Logo déjà téléchargé",
    alertLogoSuccessfullyRemoved: "Logo avec succès supprimé.",
    alertLogoFailedRemoved: "Impossible de supprimer le logo.",
    alertUserInOtherOrg: "Cet utilisateur existe déjà dans une autre organisation.",
    // added 10/13 delete workspace
    deleteWorkspace: "Supprimer l'espace de travail",
    popupMsgDeleteWorkspace: "Êtes-vous sûr de vouloir supprimer cet espace de travail ({name})?",
    alertDeleteGroupSuccess: "Supprimer avec succès cet espace de travail.",
    alertDeleteGroupFailedWorkflows: "Il y a des workflows attribués à cet espace de travail, vous ne pouvez donc pas le supprimer.",
    alertDeleteGroupFailedSkills: "Il y a des compétences attribuées à cet espace de travail, vous ne pouvez donc pas le supprimer.",
    alertDeleteGroupFailedTeams: "Il y a des équipes affectées à cet espace de travail, vous ne pouvez donc pas le supprimer.",
    // added 11/3 delete team
    alertDeleteTeamSuccess: "Supprimé avec succès cette équipe.",
    alertDeleteTeamFailedSkills: "Cette équipe n'est pas autorisée à supprimer, en raison des compétences existantes affectées à.",
    alertDeleteTeamFailed: "N'a pas réussi à supprimer cette équipe.",
    // Change Profile Name
    changeProfileName: "Modifier le nom du profil",
    allowsChangeProfileNameMsg: "Les utilisateurs sont autorisés à modifier leurs noms sur leurs profils.",
    disableChangeProfileNameMsg: "Êtes-vous sûr de vouloir désactiver le nom de profil de changement? <br> Des modifications seront apportées immédiatement.",
    enableChangeProfileNameMsg: "Êtes-vous sûr d'activer le nom du profil de modification? <br> Des modifications seront apportées immédiatement.",
    alertEnableChangeProfileNameSuccess: "Nom du profil de modification de changement avec succès.",
    alertEnableChangeProfileNameFailed: "Échec de l'activation du nom du profil de modification.",
    alertDisableChangeProfileNameSuccess: "Nom de profil de modification avec succès.",
    alertDisableChangeProfileNameFailed: "Échec de désactiver le nom du profil de modification.",
    // Workflow Comments
    workflowComments: "Commentaires du workflow",
    allowsWorkflowCommentsMsg: "Les utilisateurs sont autorisés à laisser des commentaires aux workflows publiés.",
    disableWorkflowCommentsMsg: "Êtes-vous sûr de vouloir désactiver les commentaires du flux de travail? <br> Des modifications seront apportées immédiatement.",
    enableWorkflowCommentsMsg: "Êtes-vous sûr d'activer les commentaires du flux de travail? <br> Des modifications seront apportées immédiatement.",
    alertEnableWorkflowCommentsSuccess: "Commentaires de workflow permis avec succès.",
    alertEnableWorkflowCommentsFailed: "Échec de l'activation des commentaires du flux de travail.",
    alertDisableWorkflowCommentsSuccess: "Commentaires de workflow handicapés avec succès.",
    alertDisableWorkflowCommentsFailed: "N'a pas réussi à désactiver les commentaires du flux de travail.",
    // Workspace Groups
    workspaceGroupsNum: "Groupe d'espace de travail | groupe d'espace de travail | groupes d'espace de travail",
    searchWorkspaceGroups: "Rechercher des groupes de travail ...",
    noWorkspaceGroupsYet: "Aucun groupe d'espace de travail toujours créé.",
    createWorkspaceGroup: "Créer un groupe d'espace de travail",
    editWorkspaceGroup: "Modifier le groupe d'espace de travail",
    workspaceGroupName: "Nom du groupe d'espace de travail",
    editWorkspace: "Modifier l'espace de travail",
    userNum: "utilisateur | utilisateur | utilisateurs",
    teamNum: "Équipe | Équipe | Équipes",
    defaultLanguage: "Llangage par défaut",
    deleteWorkspaceGroup: "Supprimer le groupe d'espace de travail",
    alertCreateWorkspaceGroupSuccess: "Il a créé avec succès le groupe d'espace de travail.",
    alertWorkspaceGroupNameAlreadyExists: "Ce nom de groupe d'espace de travail est déjà dans le système.",
    alertEditWorkspaceGroupSuccess: "Groupe d'espace de travail réussi.",
    alertEditWorkspaceGroupFailed: "N'a pas réussi à modifier le groupe d'espace de travail.",
    alertDeleteWorkspaceGroupSuccess: "Il a réussi à exclure ce groupe d'espace de travail.",
    alertDeleteWorkspaceGroupFailed: "Échec à exclure ce groupe d'espace de travail.",
    alertWorkspaceAssignedDeleteGroupFailed: "Il y a des espaces de travail attribués à ce groupe d'espace de travail, vous ne pouvez donc pas l'exclure.",
    deleteWorkspaceGroupMessage: "Êtes-vous sûr de vouloir supprimer ce groupe d'espace de travail (<b>{name}</b>)?",
    alertWorkspaceNameAlreadyExists: "Le nom de l'espace de travail existe déjà.",
  },
  builder: {
    publish: "Publier",
    reviewTranscription: "Consultez la transcription",
    viewer: "Téléspectateur",
    links: "Liens",
    trim: "Garniture",
    translation: "Traduction",
    share: "Partager",
    addDiagramMsg: "Faites glisser ou cliquez pour ajouter des diagrammes",
    onlyImageAccepted: "Seuls les fichiers image acceptés",
    unsupportedImageFormats: "Formats d'image non pris en charge: svg, tiff, xbm",
    addDiagram: "Ajouter un diagramme",
    start: "Début",
    end: "Finir",
    resetToOriginal: "Réinitialiser à l'original",
    trimStartMsg: "Choisissez où vous voulez que votre vidéo démarre et finit",
    trimProcessingMsg: "La garniture n'est pas disponible pendant le traitement du flux de travail. <br> s'il vous plaît revenir plus tard",
    cannotTrimLessThan3s: "Vous ne pouvez pas couper des vidéos plus courte que 3 secondes",
    trimVideo: "Couper la vidéo",
    trimStep: "Couper le pas",
    editWorkflow: "Modifier le workflow",
    popupBtnEdit: "Oui, modifier",
    popupBtnPublish: "Oui, publie",
    popupBtnUnpublish: "Oui, dépublier",
    popupMsgTrimConfirmation: "Êtes-vous sûr de vouloir couper?",
    popupMsgTrimWithSaveConfirmation: "Vous avez des changements non gravés sur votre flux de travail. Nous allons enregistrer les changements pour vous.",
    popupBtnTrim: "Oui, couper",
    popupMsgTranscoding: "Le transcodage est en cours.",
    popupMsgTrimBackLater: "Vous pouvez reprendre la modification du flux de travail après la fin du transcodage.",
    popupMsgTrimSuccess: "Votre pas a été coupé avec succès!",
    done: "Terminé",
    publishWorkflow: "Publier le flux de travail",
    popupMsgEditWorkflow: "Si vous passez en mode édition, votre flux de travail sera automatiquement dépublié, êtes-vous sûr de vouloir continuer?",
    popupMsgUnpublishWorkflow: "Vous allez dépublier votre flux de travail, êtes-vous sûr de vouloir faire cela?",
    alertSaveFailed: "Impossible d'enregistrer le flux de travail.",
    alertTrimResetSuccess: "L'étape est réinitialisée avec succès.",
    alertTrimResetFailed: "Échec de la réinitialisation de l'étape.",
    alertTrimCanNotReset: "Cette étape ne peut pas être réinitialisée.",
    resetStep: "Réinitialiser l'étape",
    popupMsgResetStep: "Êtes-vous sûr de vouloir réinitialiser? Les diagrammes ajoutés à cette étape seront perdus.",
    popupBtnReset: "Oui, réinitialiser",
    hintSearchBuilder: "Recherche de workflows et de mots-clés ...",
    stepsSelected: "choisi",
    clear: "Dégager",
    insert: "Insérer",
    reset: "Réinitialiser",
    resetWorkflow: "Réinitialiser le flux de travail",
    resetWorkflowAlert: "Vous perdrez tout le travail effectué dans ce Worflow et recommencerez depuis le début, êtes-vous sûr de vouloir continuer?",
    resetWorkflowKeepCopy: "Gardez une copie de ce workflow",
    // Transcript / Translation
    closeTranscript: "Transcription fermée",
    popupMsgCloseTranscript: "Vous avez des changements non gravés sur votre transcription. Si vous continuez, vous allez <b> perdre toutes les modifications </ b>. Es-tu sûr?",
    stepNoTranscript: "Pas sans relevé de transcription",
    originalTranscript: "Transcription originale",
    addLanguageTooltip: "Cliquez ici pour ajouter de nouvelles langues à votre vidéo",
    closeTranslation: "Fermer la traduction",
    popupMsgCloseTranslation: "Vous avez des changements non gravés sur votre traduction. Si vous continuez, vous allez <b> perdre toutes les modifications </ b>. Es-tu sûr?",
    switchTranslation: "Changer traduction",
    popupBtnNoSwitch: "Non, juste basculer",
    popupBtnYesSwitch: "Oui, modifier et enregistrer",
    createAudio: "Créer audio",
    addNewLanguageTooltip: "Cliquez ici pour ajouter une nouvelle langue à votre vidéo",
    popupMsgCreateAudio: "Êtes-vous sûr de vouloir créer les traductions audio de votre flux de travail? Nous vous encourageons vivement à ne le faire qu'une seule fois, puisque Stephanie traitera toute votre transcription.",
    popupBtnYesCreate: "Oui, créez",
    alertAudioBeingCreated: "Votre audio de traduction est en cours de création.",
    alertTranscriptionSaved: "Transcription sauvegardée avec succès.",
    alertTranscriptionSaveFailed: "Échec de l'enregistrement de la transcription.",
    alertTranslationsSaved: "Traductions sauvegardées avec succès.",
    alertTranslationsSaveFailed: "Échec de sauvegarde des traductions.",
    alertAudioCreated: "Audio créé avec succès.",
    alertAudioFailed: "Impossible de créer votre audio de traduction.",
    alertNoAudioGenerated: "Aucun audio n'a été généré car il n'y a pas de mise à jour sur la traduction.",
    alertErrorCreateTranscription: "Erreur lors de la création de la transcription.",
    //Share workflow
    shareWorkflow: "Partager le flux de travail",
    fullWorkflow: "Flux de travail complet",
    specificSteps: "Étapes spécifiques",
    chooseShareType: "Choisissez Type de partage",
    qrCode: "QR Code",
    link: "Lien",
    embedLink: "Emballer lien",
    privateWorkflow: "Flux de travail privé",
    publicWorkflow: "Flux de travail public",
    close: "Fermer",
    saveQRCode: "Enregistrer le code QR",
    download: "Télécharger",
    copyLink: "Copier le lien",
    copied: "Copié",
    downloading: "Téléchargement",
    videoPrivacy: "Vidéo Confidentialité",
    popupMsgVideoPrivacy: "Lorsque vous tournez votre flux de travail public, tout le monde peut avoir accès, même sans compte de diamètre. Es-tu sûr?",
    includeSubtitles: "Inclure des sous-titres",
    noSubtitles: "Pas de sous-titres",
    selectLanguage: "Sélectionnez la langue",
    workflowReady: "Flux de travail prêt!",
    prepareToDownload: "Préparez-vous à télécharger",
    processingDownloadVideoMsg: "Ne fermez pas cette page! <br /> Dans quelques minutes, votre fichier sera prêt pour le téléchargement.",
    processingWorkflow: "Traitement du flux de travail ...",
    openAnotherTabMsg: "Pendant que vous attendez, vous pouvez naviguer sur DeepHow dans un autre onglet",
    openNewTab: "Ouvrir nouvel onglet",
    alertErrorPreparingDownload: "Une erreur s'est produite lors de la préparation de votre téléchargement. Veuillez réessayer ou contacter DeePhow pour obtenir de l'aide.",
    hide: "Cacher",
    addNewLink: "Ajouter un nouveau lien",
    clickToAddLinks: "Cliquez pour ajouter des liens à cette étape",
    addALink: "Ajouter un lien",
    editLink: "Lien d'édition",
    addLinkAddress: "Entrez l'adresse ci-dessous pour ajouter un lien à cette étape.",
    addLinkLable: "Voulez-vous ajouter une étiquette?",
    clickOpenOfficialDocument: "i.e.: Cliquez pour ouvrir le document officiel",
    //settings /categories
    settings: "Paramètres",
    categories: "Catégories",
    noCategories: "Vous n'avez pas encore créé de catégories.",
    selectCategoriesMessage: "Sélectionnez au moins une catégorie afin que les utilisateurs puissent filtrer facilement le contenu du contenu",
    closeSettings: "Paramètres de fermeture",
    popupMsgCloseSettings: "Vous avez des changements non enregistrés. Si vous continuez, vous perdrez </b>. Es-tu sûr?",
    workflowCategories: "Catégories de flux de travail",
    popupBtnAssignCategoriesAndPublish: "Attribuer des catégories et publier",
    popupBtnSkipAndPublish: "Passer cette étape et publier quand même",
    popupMsgworkflowCategories: "Avant de publier, pourquoi ne sélectionnez-vous pas certaines catégories pour votre flux de travail. Les utilisateurs peuvent donc le trouver plus facilement?",
    alertCategoriesSaved: "Catégories sauvegardées avec succès.",
    alertCategoriesSaveFailed: "Impossible d'économiser des catégories.",
    searchForCategoryName: "Continuez la recherche sur {name} ...",
    loadingCategories: "Chargement de catégories ...",
    alertFailedDownloadVideo: "Échec du téléchargement de la vidéo",
    // Workflow Cover
    workflowCover: "Couverture de flux de travail",
    selectFromTheVideo: "Sélectionnez de la vidéo",
    browseForAFile: "Parcourir pour un fichier",
    dropFileMessage: "Déposez votre fichier ou cliquez ici pour le naviguer.",
    selectedImage: "Image sélectionnée",
    noImageSelected: "Aucune image sélectionnée",
    alertSuccessfullyUpdatedCover: "Couverture de flux de travail mis à jour avec succès.",
    alertFailedUpdatedCover: "Échec de la mise à jour du couvercle du flux de travail.",
    selectAnotherImage: "Sélectionnez une autre image",
    alertErrorStepLostTranscription: "Vous avez actuellement des étapes qui n'ont pas été traduites. Veuillez les traduire avant de pouvoir télécharger votre flux de travail.",
    annotation: "Annotation",
    allNotes: "Toutes les notes",
    noNotesAddedYet: "Aucune note ajoutée encore.",
    popupMsgEnterTrim: "Votre pas a des annotations. Si vous souhaitez couper la vidéo, ces annotations vont disparaître",
    popupMsgEnterErase: "Votre pas a des annotations. Si vous souhaitez couper la vidéo, ces annotations vont disparaître",
    popupMsgEnterAnnotation: "Veuillez vous assurer de couper ou effacer le vidéo avant d'ajouter des annotations.",
    workingOn: "Travaille sur",
    eraseStep: "Effacer les étapes",
    popupMsgEraseSuccess: "Votre pas a été effacée avec succès!",
    popupBtnYesErase: "Oui, effacez",
    popupMsgEraseConfirmation: "Êtes-vous sûr de vouloir effacer?",
    eraseProcessingMsg: "Effacer n'est pas disponible alors que le traitement du flux de travail est en cours. <br> Veuillez revenir plus tard",
    translateOnlyTitlesBtn: "Traduire les titres et les noms de pas",
    untitled: "Sans titre",
    hintNoEmptyTitle: "Le titre ne peut pas être vide",
    popupMsgSwitchTranslation: "Vous avez des changements non sauvés dans votre traduction <b> {lang} </b>. Souhaitez-vous les enregistrer avant de changer?",
    alertNoEmptyContent: "Les titres ou les phrases ne peuvent pas être vides. Veuillez saisir le contenu.",
    PDF: "Pdf",
    PDFSizeLimit: "O tamanho do arquivo PDF é limitado a 50 MB",
    addPDF: "Ajouter PDF",
    onlyPDFAccepted: "Seuls les fichiers PDF acceptés",
    preview: "Aperçu",
    // Send to reviewer
    sendToReviewer: "Envoyer à l'examinateur",
    alertTitleSelectReviewers: "Sélectionnez l'utilisateur pour revoir votre flux de travail",
    alertSendToReviewSuccess: "Votre flux de travail a été envoyé avec succès pour réviser.",
    alertSendToReviewFailed: "Échec de l'envoi",
    subtitlesSize: "Taille des sous-titres",
    small: "Petit",
    medium:"Moyen",
    big: "Grand",
    yourVideoReadyToDownload: "Votre vidéo prête à télécharger.",
    yourVideoWasSuccessfullyDownloaded: "Votre flux de travail a été téléchargé avec succès.",
    notAvailable: "pas disponible",
    downloadFileFormat: "format",
    selectDownloadWorkflowQuality: "Sélectionnez la qualité vidéo",
    downloadSizeLimitedMsg: "Télécharger SCORM à {resolution} prend en charge jusqu'à {duration} min les workflows.",
    workflowTotalDurationMsg: "Flux Total Durée: {minute} min {second} sec.",
    // Export Compliance Wire
    export: "Exporter",
    code: "Code",
    organization: "Organisation",
    approvalDate: "Date d'approbation",
    processing: "Traitement",
    workflowShouldBePublic: "Le flux de travail doit être public.",
    alertExportComplianceWireSuccess: "Document de contrôle d'exportation réussi. Code: {code}",
    alertExportComplianceWireExists: "Le document de contrôle existe déjà. Code: {code}",
    alertExportComplianceWireFailed: "Quelque chose s'est mal passé lors de l'exportation du document de contrôle.",
    confirmedPublished: "Êtes-vous sûr de vouloir publier ce workflow? <br> Si vous faites cela, tout le monde pourra le regarder.",
    versionDescription: "Version {num} Description (Facultatif):",
    versionIsDuplicated: "La version est dupliquée",
  },
  editor: {
    closeWorkflow: "Fermer le flux de travail",
    popupMsgCloseWorkflow: "Vous avez des changements non gravés sur votre flux de travail. Si vous continuez, vous perdrez </b>. Es-tu sûr?",
    popupBtnClose: "Oui, fermer",
    processWorkflow: "Processus de flux de travail",
    popupMsgProcessWorkflow: "Super! On dirait que vous avez terminé votre commentaire et que vous voulez que Stephanie le traite. <br><br> Vous ne <b>pourrez pas </ b> retourner à cet écran de révision. Es-tu prêt?",
    popupBtnProcess: "Oui, processus",
    alertIsSaving: "L'épargne est en cours.",
    alertNoEmptySentence: "Les phrases ne peuvent pas être vides. Veuillez entrer ou supprimer les phrases, puis enregistrez à nouveau.",
    alertIsSaved: "Vos modifications ont été enregistrées.",
    alertWasUnpublished: "Votre flux de travail a été dépublié avec succès.",
    alertWasPublished: "Votre flux de travail a été publié avec succès.",
    alertNoMoreSteps: "Vous ne pouvez plus ajouter des étapes ici.",
    alertNoEmptyTitle: "Le titre ne peut pas être vide. Veuillez entrer le titre, puis enregistrez à nouveau.",
    scrollToVoice: "Défiler jusqu'à la voix",
    noAudioMsg: "Nous n'avons trouvé aucun audio à transcrire dans votre flux de travail ...",
    youWillDeleteStep: "Vous allez supprimer l'étape",
    areYouSure: "es-tu sûr?",
    areYouSureDelete: "Êtes-vous sûr de votre façon de supprimer?",
    replace: "Remplacer",
    keyword: "Mot-clé",
    find: "Trouver",
    replaceWith: "Remplacer par",
    replaceAll: "Remplace tout",
    replaceAllMsg: "Vous remplacerez tous ces mots.",
    of: "de",
    msgToSteps: "Analyser votre flux de travail ... <br> Veuillez patienter ...",
    msgToEditor1: "Permet maintenant de passer en revue le flux de travail avant de publier",
    msgToEditor2: "Je vais vous guider dans chaque étapes sans souci",
    msgToBuilder1: "Presque là-bas, donnez-moi une seconde",
    msgToBuilder2: "Je crée les étapes de votre flux de travail",
    newStep: "Nouvelle étape",
    hintNoEmptySentence: "Les phrases ne peuvent pas être vides",
    hintNoEmptyTitle: "(Le titre ne peut pas être vide)",
    alertNoTranscription: "Pas de transcription pour le flux de travail.",
    alertEmptyingTranscription: "ATTENTION! Vous videz la transcription. Tu ne peux pas le faire",
    alertStepCorrupted: "Step {count} est corrompu. Veuillez le supprimer avant le traitement",
    // Editor 2.0
    trim: "Couper les bords",
    erase: "Effacer",
    voiceOver: "Enregistrer la voix",
    videoEditing: "Montage vidéo",
    segmentation: "Segmentation",
    popupMsgRestToOriginal: "Êtes-vous sûr de vouloir réinitialiser à la vidéo d'origine? Tous les changements ajoutés seront perdus.",
    trimHintMessage: "Utilisez les flèches pour sélectionner la zone que vous souhaitez couper",
    eraseHintMessage: "Utilisez les flèches pour sélectionner la zone que vous souhaitez effacer",
    voiceOverHintMessage: "Sélectionnez la zone que vous souhaitez enregistrer la voix...",
    voiceOverStartHint: "Appuyez ici pour commencer votre enregistrement ...",
    voiceOverStart: "Enregistrer",
    voiceOverSave: "Enregistrement",
    voiceOverCancel: "Annuler",
    voiceOverCancelPopupMsg: "Êtes-vous sûr de vouloir supprimer votre enregistrement?",
    voiceOverPause: "Pause",
    voiceOverResume: "CV",
    unableToGetAudioInput: "Aucun périphérique d'entrée audio détecté",
    restart: "redémarrer",
    editVideo: "Éditer vidéo",
    popupRequestTranscription: "Ne peut pas trouver la transcription pour le flux de travail. Créons un nouveau.",
    popupMsgEditVideoWarning: "Toutes les modifications apportées sur la transcription seront perdues si vous modifiez votre vidéo, êtes-vous sûr de vouloir modifier?",
    popupMsgReTranscriptionWarning: "Remarque: Si vous avez effectué des actions de montage vidéo (telles que la garniture / effacer / la voix-off), vous ne pourrez pas utiliser la fonction de recommandation.",
    popupMsgReTranscriptionWarningMultiEditing: "Remarque : si vous avez effectué des actions de montage vidéo (telles que couper/doublage), vous ne pourrez pas utiliser la fonction de retranscription.",
    rotate: "Tourner",
    rotateLeft: "Tourne à gauche",
    rotateRight: "Tourner à droite",
    goBack: "retourner",
    goBackToEditorHome: "Retournez à l'éditeur",
    zoom: "agrandir",
    // transcription new features
    transcriptionCopySuccess: "Transcription copiée dans votre presse-papiers",
    keyTerms: "Mots clés",
    AILabs: "Laboratoires AI",
    copyTranscription: "Copier la transcription",
    changeLanguage: "Changer de langue",
    copy: "copie",
    changeLanguageTitle: "Veuillez sélectionner la nouvelle langue pour votre transcription",
    changeLanguageSubTitle: "Stephanie devra revoir toute votre transcription.",
    reProcessingYourVideo: "Stéphanie remonte votre vidéo ...",
    goBackToEditor: "Vous pouvez attendre ici ou retourner à l'éditeur pour continuer à travailler!",
    // keyterms: added on 08/09/2022
    addTerm: "Ajouter un terme",
    removeTerm: "Supprimer le terme",
    termsFound: "<span>0</span> terme trouvé | <span>1</span> terme trouvé | <span>{count}</span> termes trouvés",
    loadingTerms: "Termères de chargement",
    termsInfo: "Les termes peuvent être très utiles lors de la recherche de contenus spécifiques dans les workflows.",
    // Editor 3.0
    cut: "Couper",
    tabsWarningMessages: "Avis! Si vous quittez cette page sans économiser, toutes les modifications que vous avez apportées seront perdues.",
    versionHistory: "Historique des versions",
    seeMore:"en voir plus ...",
    seeLess:"Voir moins ...",
    workflowCreated:"Flux de travail créé",
    createdBy: "Créé par {name}",
    publishedBy: "Publié par {name}",
    lastUpdateBy: "Dernière mise à jour par {name}",
    // Version History add by andy 01/08/2023
    emptyTitle: "No versions yet!",
    emptyContent: "This Workflow has not been published yet, as soon as you publish for the first time you will start seeing the versions here."
  },
  player: {
    loadingVideo: "Chargement de la vidéo ...",
    loadingLanguage: "Chargement de la langue...",
    previousStep: "Étape précédente",
    nextStep: "L'étape suivante",
    audio: "l'audio",
    subtitles: "Les sous-titres",
    autoPlay: "Lecture automatique",
    off: "Désactivé",
    on: "Sur",
    quality: "Qualité",
    speed: "La vitesse",
    normal: "Normal",
    rotatePhone: "<span class='primary--text'>Rotation</span> votre téléphone pour une<br> meilleure expérience",
    alertTranscodingFailed: "Oups, on dirait que le transcodage vidéo a échoué. Veuillez contacter DeePhow pour obtenir une assistance supplémentaire.",
    alertTranscodingStillProgress: "Oups, on dirait que le transcodage vidéo est toujours en cours. Merci de revenir plus tard.",
    settings: "Paramètres",
    openInBrowser: "Ouvrir dans le navigateur",
    goToHome: " Aller à la maison",
    thisWorkflowWasUnpublished: "Ce flux de travail n'a pas été publié",
    thisWorkflowWasDeleted: " Ce flux de travail a été supprimé",
    talkToSupervisor: "Si vous pensez que ce flux de travail devrait être publié, contactez votre superviseur",
    selectWorkspace: "Sélectionnez Espace de travail",
    // player2.0
    home: "Accueil",
    myWorkflows: "Mes flux de travail",
    recommendedForYou: "Recommandé pour vous",
    favorites: "Favoris",
    welcomeToDeepHowNavigator: "Bienvenue sur DeepHow Navigateur",
    numNewSkillsTrainings: "<span> 0 entraînements de compétences </span> de <br> qui vous est assigné. | <span> 1 entraînements de compétences </span><br> qui vous sont assignés. | <span> {count} formations de compétences </span><br> qui vous est assigné.",
    clickForTips: "Cliquez ici pour certains conseils intelligents.",
    learnHow: "Apprendre",
    goToSkills: "Aller aux compétences",
    at: "au ",
    youAreIn: "Vous êtes dans",
    loadingRecommendations: "Chargement des recommandations ...",
    loadingFavoriteWorkflows: "Chargement des flux de travail préférés ...",
    noFavoriteWorkflows: "Vous n'avez pas encore d'articles dans vos favoris.",
    canFindSavedItemsHere: "Vous pouvez trouver tous vos articles enregistrés ici.",
    removeWorkflowFromFavorites: "Êtes-vous sûr de vouloir supprimer ce flux de travail de vos Favoris?",
    yesGoAhead: "Oui, vas y",
    thisWorkflowIsNotAvailable: "Ce flux de travail n'est pas disponible",
    dismiss: "Rejeter",
    navigator: "Navigateur",
    howToUseNavigator: "Apprenez à utiliser le nouvel <br><b>DeepHow Navigateur</b>",
    checkTheNewFeatures: "Vérifiez les nouvelles fonctionnalités.",
    unknownUser: "Utilisateur inconnu",
    okCloseBtn: "Ok, fermer.",
    emptyRecommendWorkflowTitle: "Hey! Où est le contenu?",
    emptyRecommendWorkflowDescription: "Ne t'inquiète pas! Dès que les flux de travail sont créés, je vous le recommanderai. Reviens bientôt!",
    thisWorkflowDoesNotExist: "Ce flux de travail n'existe pas",
    // navigator
    resume: "Continuer",
    myWorkspaces: "Mes espaces de travail",
    otherWorkspaces: "Autres espaces de travail",
    loading: "Chargement...",
    loadingfavoriteWorkspaces: "Chargement des espaces de travail préférés ...",
    removeWorkspaceFromFavorites: "Êtes-vous sûr de vouloir supprimer cet espace de travail de vos favoris?",
    YouAreViewing: "Vous consultez",
    allWorkspaces: "Tous les espaces de travail",
    removeFromFavorites: "Retirer des favoris",
    favoriteThisVideo: "Favoris cette vidéo",
    shareThisVideo: "Partager cette vidéo",
    viewAll: "Voir tout",
    openFile: "Fichier ouvert",
    suggestedKeyterms: "Termes clés suggérés",
    // workflow player added 10/10/2022
    posted: "Affiché",
    views: "0 vue | 1 vues | {count} vues",
    saveFavorite: "Sauvegarder",
    react: "Réagir",
    numColleaguesReact: "0 collègues ont réagi | 1 collègue a réagi | {count} collègues ont réagi",
    youAndNumColleaguesReact: "<span>Vous</span> et 0 collègues ont réagi | <span>Vous</span> et 1 collègue a réagi | <span>Vous</span> et {count} collègues ont réagi",
    youReacted: "<span>Vous</span> ont réagi",
    userReaction: "Réaction de l'utilisateur",
    fullscreen: "Plein écran",
    exitFullscreen: "Quitter le mode plein écran",
    // 1/23/2023 Comments
    comments: "commentaires",
    deleteComment: "Supprimer le commentaire",
    deleteCommentMsg: "Êtes-vous sûr de vouloir supprimer ce commentaire? <br> Cela ne peut pas être défait.",
    edit: "Modifier",
    updating: "Mise à jour ...",
    changesSaved: "Changements sauvegardés.",
    noComments: "Aucun commentaire pour l'instant…",
    makeFirstComment: "Faites le premier commentaire ci-dessous",
    comment: "commentaire",
    writeAComment: "Écrire un commentaire...",
    edited: "édité",
    emojiRecent: "Fréquemment utilisé",
    emojiSmileys: "Smileys et émoticône",
    emojiPeople: "Personnes et corps",
    emojiNature: "Animaux et nature",
    emojiFoods: "Nourriture et boisson",
    emojiActivity: "Activité",
    emojiPlaces: "Voyages et lieux",
    emojiObjects: "Objets",
    emojiSymbols: "Symboles",
    emojiFlags: "Drapeaux",
    emojiCustom: "Personnalisé",
    back: "retourner",
    feedback: "Avis",
    leaveAFeedback: "Donner un avis",
    leaveAFeedbackMsg: "Parlez-nous de tout problème, laissez une suggestion ou même une critique constructive.",
    typeYourMessageHere: "Tapez votre message ici",
    submit: "Soumettre",
    thankYou: "Merci.",
    feedbackSuccessMsg: "Vos commentaires ont bien été envoyé.",
    alertFeedbackFailedMsg: "Échec de l'envoi de vos commentaires. Veuillez réessayer.",
    diagrams: "Diagrammes",
    pdfFiles: "Fichiers PDF",
    theaterMode: "Mode théâtre",
    exitTheaterMode: "Sortir du mode théâtre",
    // TODO: 09/08/2023
    enableWorkflowRepeat: "Activer la répétition du flux de travail",
    enableStepRepeat: "Activer la répétition des étapes",
    disableRepeat: "Désactiver la répétition",
  },
  profile: {
    // added 12/09/2021
    addPositionLocation: "Ajouter la position et l'emplacement",
    noWorkflowsCreated: "Aucun flux de travail n'est encore créé.",
    comeBackLater: "Revenez plus tard.",
    createdWorkflows: "Création de workflows",
    profileOwnerNoContentMsg: "<span>hey, {name}! Voici votre nouvelle page de profil.</span> <br> À l'avenir, vous pourrez partager des connaissances avec tout le monde. Attendez!",
    profileColleguesNoContentMsg: "<span>c'est la page de profil de {name}.</span> <br> Il n'est pas encore en mesure de publier du contenu, mais dans un avenir proche, il le fera!",
    editProfile: "Editer le profil",
    personalInformation: "Informations personnelles",
    personalInformationMsg: "Les personnes visitant votre profil verront les informations suivantes.",
    photo: "photo",
    position: "Positionner",
    locationRegion: "Emplacement / région",
    whatYouDo: "Faites savoir aux gens ce que vous faites dans cette organisation.",
    whereYouBased: "Faites savoir aux gens où vous êtes basé.",
    accountManagement: "Gestion de compte",
    accountManagementMsg: "Ces informations sont privées et n'apparaîtront pas dans votre profil public.",
    identifyMyself: "Je m'identifie comme",
    yourPassword: "Votre mot de passe",
    nonDeclared: "Non déclaré (par défaut)",
    male: "Homme",
    female: "Femme",
    nonBinary: "Non binaire",
    viewProfile: "Voir le profil",
    alertFailedToUpdateProfile: "Échec de la mise à jour de votre profil."
  },
  explorer: {
    search: "Chercher",
    in: "en&nbsp;",
    howTo: "Comment…",
    didYouMean: "Vouliez-vous dire",
    searchInTitle: "Dans le titre",
    searchInContent: "Dans le contenu",
    searchInVideo: "Suggestions de Stephanie",
    title: "Titre",
    searchStartMsg1: "Qu'est-ce que tu cherches?",
    searchStartMsg2: "Utilisez la zone de recherche ci-dessus",
    sortBy: "Trier par",
    lastUpdated: "Dernière mise à jour",
    alphabetically: "Alphabétiquement",
    workflowsIn: "Flux de travail dans",
    tagNew: "Nouvelle",
    tagProcessing: "Traitement",
    msgProcessing: "Stephanie traite votre flux de travail ... <br/> Attendez :)",
    tagFailed: "Échoué",
    msgFailed: "Oups, quelque chose s'est mal passé tout en traitant le discours ou la vidéo. Nous allons réessayer bientôt ...",
    msgVideoLimitExceeded: "La vidéo a dépassé la longueur prise en charge par DeepHow. Il sera automatiquement rechargé dans deux flux de travail ou plus.",
    msgVideoExceededMaximum: "La vidéo dépasse la longueur maximale du flux de travail soutenu par DeepHow. Il sera automatiquement divisé en deux workflows ou plus",
    tagTranscoding: "Transcodage",
    msgTranscoding: "Stephanie est transcodant votre flux de travail ... <br/> Veuillez patienter :)",
    deleteWorkflow: "Supprimer le workflow",
    popupBtnDelete: "Oui, supprimer",
    popupMsgDeleteWorkflow: "Êtes-vous sûr de vouloir supprimer ce flux de travail? Vous perdrez toutes les étapes et données relatives à ce flux de travail.",
    noUnpublishedWorkflows: "Vous n'avez pas encore de flux de travail non publié ...",
    noPublishedWorkflows: "Vous n'avez pas encore publié de workflows ...",
    startCreatingWorkflow: "Commençons à créer un flux de travail!",
    startPublishingWorkflow: "Commençons de publier le flux de travail!",
    noSearchResult: "Aucun résultat trouvé ...",
    trySearchAgain: "S'il vous plaît essayer de vous chercher",
    searchingForWorkflows: "À la recherche de flux de travail ...",
    secondsAgo: "Il y a 0 seconde | Il y a 1 seconde | Il y a {count} secondes",
    minutesAgo: "Il y a 0 minute | Il y a 1 minute | Il y a {count} minutes",
    hoursAgo: "Il y a 0 heure | Il y a 1 heure | Il y a {count} heures",
    daysAgo: "Il y a 0 jour | Il y a 1 jour | Il y a {count} jours",
    monthsAgo: "Il y a 0 mois | Il y a 1 mois | Il y a {count} mois",
    yearsAgo: "Il y a 0 ans | Il y a 1 ans | Il y a {count} années",
    sAgo: "{count} s",
    minAgo: "{count} min",
    hAgo: "{count} h",
    dAgo: "{count} j",
    mAgo: "{count} m",
    yAgo: "{count} a",
    renameWorkflow: "Renommer le flux de travail",
    moveWorkflowTo: "Déplacer le flux de travail vers",
    duplicateWorkflow: "Dupliquer le flux de travail",
    unpublishWorkflow: "Dépublier flux de travail",
    shareKnowhow: "Partager le savoir-faire",
    or: "Ou alors",
    clickHere: "Cliquez ici",
    andCopyMsg: "et copier le lien d'URL pour envoyer par e-mail",
    successfulSharing: "Partage réussi.",
    send: "Envoyer",
    typeHereAMessage: "Tapez ici un message",
    wellDone: "Bien fait",
    numMore: "{count} plus",
    moveWorkflow: "Déplacer le flux de travail",
    currentWorkspace: "Espace de travail actuel",
    whereToMsg: "Où voulez-vous déménager <br> ce flux de travail?",
    move: "mouvement",
    alertSuccessfullyMoved: "Déplacé avec succès le flux de travail.",
    alertFailedMoved: "Échoué à déplacer le flux de travail.",
    noNotificationsMsg: "Vous n'avez actuellement aucune notification",
    betaSearch: "Sachez que la suggestion de Stephanie est toujours en version bêta ...",
    relevantSteps: "Étapes pertinentes:",
    relevance: "pertinence",
    resultsFor: "{num} résultats pour <b>&ldquo;{input}&rdquo;</b>",
    resultFor: "{num} résultat pour <b>&ldquo;{input}&rdquo;</b>",
    byPublisher: "Par Éditeur",
    alertFailedShareWorkflow: "Échec de partage de workflow.",
  },
  new: {
    newWorkflow: "Nouveau flux de travail",
    uploadVideo: "Télécharger une video",
    recordScreen: "Écran d'enregistrement",
    replaceVideoSubtitle: "Faites glisser ou cliquez pour remplacer votre vidéo.",
    recordScreenSubtitle: "Stephanie est prêt à commencer à enregistrer votre écran.",
    workflowName: "Nom du flux de travail",
    workflowLanguage: "Langue de flux de travail",
    addToWorkspace: "Ajouter à l'espace de travail",
    upload: "Télécharger",
    startRecording: "Commencer l'enregistrement",
    cancel: "Annuler",
    wrongUploadFile: "Type de fichier non pris en charge. Veuillez sélectionner le type de fichier suivant:",
    limitFileDuration: "Durée du fichier prend en charge jusqu'à {duration} min. Veuillez utiliser l'application DeEphow Uploader pour Windows",
    limitFileDurationGen2: "Durée du fichier prend en charge jusqu'à {duration} min.",
    uploadingVideo: "Téléchargement de la vidéo",
    uploadingVideoSubtitle: "Veuillez patienter pendant que Stephanie télécharge votre vidéo",
    renderingVideo: "Rendu / Sauvegarde de la vidéo",
    renderingVideoSubtitle: "Veuillez patienter pendant que Stephanie rend votre vidéo",
    almostDone: "Nous avons presque fini!",
    uploadingStep1: "Étape 1 de 2 - Analyser le flux de travail ...",
    uploadingStep2: "Téléchargement de la vidéo ...",
    successSubtitle: "Votre flux de travail a été téléchargé avec succès",
    goToEditor: "Aller à l'éditeur",
    goToContentManger: "Allez chez le gestionnaire de contenu",
    uploadingAlert: "La modification ou la fermeture de l'onglet Navigateur provoquera des erreurs de téléchargement.",
    recordingScreen: "Écran d'enregistrement",
    support15Min: "Conserver à l'esprit Screen Capture prend en charge jusqu'à <b>15 min</b>.",
    hereWeGo: "Nous y voilà!",
    remember15Min: "N'oubliez pas que vous avez 15 min.",
    windowsAppAvailable: "L'application Windows <br> est maintenant disponible.",
    windowsAppAvailableNews: "L'application DeepHow Uploader est déjà disponible pour Windows. Vous pouvez maintenant télécharger des fichiers plus grands et également l'écran.",
    downloadNow: "Télécharger maintenant!",
    deepHowWindowsApp: "Application de Windows DeepHow",
    downloadAndInstalltheWindowsApp: "Téléchargez et installez l'application Windows.",
    notAllowedToInstall: "Téléchargez et installez l'application Windows. ",
    downloadThisVersion: "Téléchargez cette version",
    and: " et ",
    watchThisVideo: "<span>regarder cette vidéo</span> sur la façon de l'utiliser.",
    // Added on 2022/01/19
    abiAuthorizationLabel: "Je reconnais par la présente que toutes les personnes de cette vidéo ont signé la photographie <span> autorisation d'enregistrement de la photographie / vidéo </span> et relâchent spécifiques à mon pays.",
    abiPrivacyLabel: "Je conviens par la présente que j'ai lu et compris la <span>déclaration de confidentialité d'ABinBev photo / enregistrement vidéo. </span>",
    // Added on 2022/06/15
    importFromZoom: "Importer depuis Zoom",
    importFromZoomSteps: "Étape {step} de {totalStep}",
    selectVideosFrom: "Sélectionnez des vidéos à partir",
    videoSelected: "<span> 0 </span> video sélectionné | <span> 1 </span> video sélectionné | <span> {count} </span> vidéos sélectionnées",
    workflowInfo: "Informations sur le workflow",
    newImport: "Nouvelle importation",
    orBackToEditor: "ou retour à l'<span>Éditeur</span>",
    importFromZoomSuccessMsg: "Vos vidéos commenceront le téléchargement bientôt, cela peut prendre un certain temps avant qu'ils ne soient disponibles pour l'édition.",
    zoomFailedUploadMsg: "Échec de la téléchargement de la vidéo Zoom",
    zoomPeriodLastMonth: "le mois dernier",
    zoomPeriodPastMonth: "passé {n} mois",
    loadingZoomRecordings: "Chargement des vidéos",
    zoomNoRecordingsInfo: "Aucun enregistrement trouvé pour la période sélectionnée.",
    yourVideoExceedTwoHours: "Votre vidéo dépasse 2 heures et ne peut pas être téléchargée.",
    yourVideoExceed3GSize: "Votre vidéo dépasse 3 Go et ne peut pas être téléchargée.",
    yourVideoExceedTwoHoursAnd3GSize: "Votre vidéo dépasse 2 heures, 3 Go et ne peut pas être téléchargée.",
    videoUploadWarningMessage: "Faites glisser ou cliquez pour importer votre vidéo (uniquement MP4 ou WebM). La taille est de 3 Go et jusqu'à 2 heures.",
    videoUploadWarningMessageGen2: "Faites glisser ou cliquez pour importer votre vidéo (uniquement MP4 ou WebM). jusqu'à 40 minutes.",
  },
  auth: {
    aboutDeephow: "À propos de DeepHow",
    authTitle: "Première solution AI pour la formation des métiers qualifiés.",
    authIntro: "DeepHow ponte le déficit des compétences dans la fabrication, le service et la réparation via une plate-forme d'apprentissage alimentée par l'AI basée sur des vidéos interactives sur les vidéos.",
    allRightReserved: "Tous les droits sont réservés",
    logIn: "Connexion",
    logOut: "Se déconnecter",
    logInSubtitle: "Veuillez insérer vos coordonnées pour vous connecter.",
    logInSubtitleSso: "Veuillez cliquer sur ci-dessous pour vous connecter.",
    emailOrId: "E-mail ou identifiant d'employé",
    hello: "Bonjour,",
    passwordSubtitle: "Veuillez insérer votre mot de passe.",
    password: "Mot de passe",
    forgotPassword: "Mot de passe oublié",
    forgotPasswordSubtitle: "Veuillez insérer votre e-mail afin que nous puissions vous envoyer un lien pour créer un nouveau mot de passe.",
    email: "E-mail",
    resetPassword: "Réinitialiser le mot de passe",
    alertResetSuccess: "Veuillez vérifier votre boîte de réception de courrier électronique.",
    alertResetFailed: "Impossible de récupérer le mot de passe.",
    alertInvalidId: "ID d'employé invalide.",
    successMsg: "Veuillez vérifier votre courrier électronique et cliquez sur le lien pour définir un nouveau mot de passe pour votre compte.",
    logInWithSSO: "Connectez-vous avec SSO",
    loggingInWithSSO: "Connexion avec SSO ...",
    logInWithGoogle: "Connectez-vous avec Google",
    logInWithMicrosoft: "Connectez-vous avec Microsoft",
    loadingSso: "Chargement de SSO ...",
    about: "Sur",
    // 12/17/2021 iframe
    loginToYourAccount: "<b>Connexion</b> à votre compte",
    pleaseInputYourPassword: "Veuillez saisir votre mot de passe.",
    backToLogin: "Retour connexion",
    // added 03/02/2022
    wrongPassword: "Mauvais mot de passe.",
    // Shared Account
    sharedDevice: "Dispositif partagé",
    loginExistingAccount: "Connectez-vous avec le compte existant",
    createNewAccount: "Créer un nouveau compte",
    noUsersMsg: "Aucun utilisateur n'a encore connecté à cet appareil partagé ...",
    insertIDMsg: "Veuillez insérer votre identifiant d'employé",
    createIdUserMsg: "Nous sommes <b>presque là-bas</b>! <br>Veuillez remplir vos coordonnées ci-dessous.",
    confirmEmployeeID: "Confirmer l'identifiant des employés",
    welcomeToDeepHow: "Bienvenue sur DeepHow, <b>{name}</b>",
    confirmWorkspaceTeamMsg: "Veuillez confirmer votre espace de travail et sélectionner votre équipe!",
    LetsGetStarted: "Commençons!",
    // added 05/26/2022
    useSsoLogin: "La connexion par e-mail / mot de passe n'est pas activée pour votre organisation. Veuillez vous connecter avec SSO.",
    // added 02/17/2023
    cookieAlert: "J'accepte l'utilisation de cookies comme détaillé dans notre <a href='{pdfUrl}' target='_blank' >politique de confidentialité </a>.",
    useCookie: "Nous utilisons des cookies!",
    useCookieContext: "Nous utilisons des cookies pour nous assurer que vous aurez une expérience géniale en utilisant DeepHow!",
    privacyPolicy: "Politique de confidentialité",
    acceptCookies: "Accepter les cookies"
  },
  rules: {
    required: "Obligatoire.",
    invalidEmail: "Email invalide.",
    noSpace: "Impossible d'ajouter des espaces.",
    validPassword: "Le mot de passe doit comporter au moins 6 caractères, inclure le numéro, la lettre inférieure et la majuscule.",
    maxPassword: "Max 16 caractères.",
    mustMatch: "Le mot de passe doit correspondre.",
    max25: "Max 25 caractères.",
    max90: "Max 90 caractères.",
    max100: "Max 100 caractères.",
    max140: "Max 140 caractères.",
    numMaxCharacters: "Max 0 caractere. | Max 1 caractere. | Max {count} caractères.",
    min6: "6 caractères minimum.",
    noWhiteSpace: "Entrez le texte comme titre.",
    noAllSpace: "Ne peut pas utiliser d'espaces comme nom.",
    invalidEmployeeID: "ID d'employé invalide.",
    invalidUrl: "URL invalide",
    mustMatchId: "L'identité de l'employé doit correspondre.",
    canNotEnterSharedAccount: "Je ne peux pas insérer l'ID de compte partagé.",
    validPasswordMin10: "Le mot de passe doit comporter au moins 10 caractères, inclure le nombre, le caractère spécial, la lettre inférieure et supérieure.",
    invalidName: "Nom incorrect.",
  },
  alert: {
    success: "Succès",
    info: "Information",
    alert: "Alerte",
    error: "Erreur",
    dontShowThisMsgAgain: "Ne montrez plus ce message",
    unexpectedError: "Une erreur inattendue s'est produite. Veuillez réessayer ou contacter DeepHow pour obtenir de l'aide.",
  },
  skills: {
    skillsMatrix: "Matrice de compétences",
    createSkill: "Créer des compétences",
    skillsCreated: "Compétences créées",
    skillsPublished: "Compétences publiées",
    skillsIn: "Compétences dans",
    hintSearchSkills: "Recherche de compétences ...",
    lastUpdated: "Dernière mise à jour",
    skillsPerPage: "Compétences par page",
    usersAssigned: "Utilisateurs attribués",
    status: "Statut",
    createdBy: "Par",
    skillsSelected: "compétence sélectionnée | compétence sélectionnée | compétences sélectionnées",
    updatedBy: "Par",
    deleteSkill: "Supprimer les compétences",
    popupMsgDeleteSkill: "Êtes-vous sûr de vouloir supprimer cette compétence?",
    deleteSkills: "Supprimer les compétences",
    popupMsgDeleteSkills: "Êtes-vous sûr de vouloir supprimer ces compétences?",
    clearAll: "Tout effacer",
    clearAllFilters: "Limpar todos os filtros",
    clearFilter: "Effacer filtre",
    delete: "Supprimer",
    youAreViewing: "Vous regardez",
    numWorkflows: "0 flux de travail | 1 flux de travail | {count} flux de travail",
    searchIn: "Rechercher dans",
    loadingSkillMatrix: "Chargement de la matrice de compétences ...",
    createSkillSubtitle: "Sélectionnez un nom et où vous souhaitez le télécharger",
    skillName: "Nom de compétence",
    create: "Créer",
    editSkill: "Modifier la compétence",
    popupMsgEditSkill: "Si vous allez en mode édition, votre compétence sera automatiquement dépublié, êtes-vous sûr de vouloir continuer?",
    publishSkill: "Publier la compétence",
    popupMsgPublishSkill: "Vous allez publier vos compétences et les utilisateurs pourront le voir, êtes-vous sûr de vouloir faire cela?",
    alertFailedSaveSkill: "Échec au sauvegarder la compétence",
    alertSkillSuccessfullyPublished: "Votre compétence a été publiée avec succès.",
    alertSkillSuccessfullyUnpublished: "Votre compétence a été dépublié avec succès.",
    alertSkillSuccessfullySaved: "Votre compétence a été sauvegardée avec succès",
    alertFailedSavedSkill: "Échec au sauvegarder la compétence",
    alertFailedPublishSkill: "Échoué à publier des compétences",
    alertSkillSuccessfullyDeleted: "Votre compétence a été supprimée avec succès",
    alertSkillFailedDeleted: "Échec de la suppression de la compétence",
    content: "Contenu",
    addContent: "Ajouter du contenu",
    workflowDeleted: "Flux de travail supprimé",
    workflowsSelected: "Flux de travail sélectionné | Flux de travail sélectionné | Flux de travail sélectionnés",
    selectAll: "Tout sélectionner",
    remove: "Supprimer",
    noContentMsg: "Vous n'avez pas encore de contenu dans vos compétences",
    assignUsers: "Attribuer des utilisateurs",
    noUserMsg: "Vous n'avez pas encore d'utilisateurs attribués à votre formation",
    numUsers: "<b>0</b> Utilisateur | <b>1</b> Utilisateur | <b>{count}</ b> utilisateurs",
    assignedOn: "Assigné",
    certifiedBy: "Certifié par",
    progress: "Le progrès",
    aletUserSuccessfullyRemoved: "L'utilisateur a été supprimé avec succès.",
    removeUser: "Supprimer l'utilisateur",
    popupMsgRemoveUserFromSkill: "Voulez-vous vraiment supprimer <b>{name}</b> de la compétence?",
    assign: "Attribuer",
    successfulAssigned: "Attribué avec succès",
    AddedToSkill: "Ajouté à la compétence.",
    loadingSkills: "Chargement de compétences ...",
    searchingForSkills: "Recherche de compétences ...",
    toDo: "Faire",
    completed: "Terminé",
    assignedBy: "Assigné par",
    min: "min",
    nextPart: "Partie suivante",
    noSkillsAssignedMsg: "Vous n'avez pas encore aucune compétence assignée.",
    noSkillsAssignedMsg2: "Nous vous ferons savoir quand vous avez quelque chose de nouveau!",
    noCompletedSkillsMsg: "Vous n'avez pas encore de compétences terminées.",
    noCompletedSkillsMsg2: "Commençons les compétences d'apprentissage!",
    noDataSkillsMatrix: "Vous n'avez pas encore de données dans la matrice de compétences ...",
    certificate: "Certificat",
    certificateMsg: "Les utilisateurs obtiendront un certificat de completage si cela finit de manière successive cette compétence.",
    selectCertificateMsg: "Sélectionnez ci-dessous les utilisateurs qui seront en mesure de valider le certificat:",
    validateSkill: "Valider la compétence",
    validateSkillMsg: "Êtes-vous sûr de vouloir valider <b>{name}</b> dans la compétence {skill}? <br/> Vous ne serez pas capable de l'annuler.",
    yesValidate: "Oui, validez",
    noValidate: "Non",
    certifier: "Certificateur",
    signaturesOn: "sur",
    teamProgress: "Progrès de l'équipe",
    openTeam: "Équipe ouverte",
    removeCertificate: "Supprimer le certificat",
    removeCertificateMsg: "Vous supprimerez tous les utilisateurs capables de valider le certificat, êtes-vous sûr?",
    yesRemove: "Oui, supprimer",
    mustAddUserMsg: "* Le certificat ne sera pas activé si aucun utilisateur n'est ajouté.",
    skillCover: "Couverture de compétences",
    alertSuccessfullyUpdatedSkillCover: "La couverture de compétences a été mise à jour avec succès.",
    alertFailedUpdatedSkillCover: "Échec de la mise à jour de la couverture des compétences.",
    closeSkill: "Fermer compétence",
    allWorkflowsDeleted: "Tous les flux de travail sont supprimés dans cette compétence.",
    waitingForValidation: "En attente de validation",
    validatedBy: "Validé par:",
    skillWasUnpublished: "La compétence n'a pas été publiée.",
    skillWasUnpublishedMsg: "Je vous ferai savoir quand cette compétence devient à nouveau disponible. Pour l'instant, vous êtes tous bien!",
    notAssignedToSkill: "Vous n'êtes pas affecté à cette compétence.",
    notAssignedToSkillMsg: "Désolé, cette page n'est disponible que pour les utilisateurs qui ont été attribués à cette compétence.",
    remindPendingUsers: "Rappeler aux utilisateurs en attente",
    alertSuccessRemindPendingUsers: "Rappelait avec succès des utilisateurs en attente.",
    alertFailedRemindPendingUsers: "Impossible de rappeler aux utilisateurs en attente.",
    alertNoPendingUsers: "Aucun utilisateur en attente trouvé.",
    numVideo: "0 vidéo | 1 vidéo | {count} vidéos",
    // Skill Deadline
    deadline: "Date limite",
    deadlineMsg: "Définissez une période mondiale pour que les utilisateurs finissent de regarder cette compétence. Après la date limite, les utilisateurs en attente seront informés ...",
    deadlineForThisSkill: "Activez la date limite pour cette compétence.",
    certificateForThisSkill: "Activez le certificat pour cette compétence.",
    deadlineStartsMsg: "La période commence à compter compte tenu de la date à laquelle l'utilisateur a été attribué.",
    selectAPeriod: "Sélectionnez une période",
    finished: "Achevé",
    editDeadline: "Modifier la date limite",
    editDeadlineMsg: "En modifiant la date limite, la précédente ne sera plus valide et la nouvelle période sera appliquée à tous les utilisateurs de cette compétence. <br><br> Êtes-vous sûr de vouloir continuer?",
    alertSuccessNewDeadline: "La nouvelle date limite est maintenant appliquée à tous les utilisateurs.",
    alertSuccessRemoveDeadline: "La date limite de compétence a été supprimée avec succès.",
    alertFailedDeadline: "Échec de la mise à jour des échéance des compétences.",
    dueToday: "Dû aujourd'hui",
    turnOffDeadlineMsg: "En désactivant la date limite, les données précédentes seront perdues. La prochaine fois qu'il sera activé, il réinitialisera toutes les dates de démarrage de l'utilisateur. <br> <br> Êtes-vous sûr de vouloir continuer?"
  },
  time: {
    numDays: "0 jour | 1 jour | {count} jours",
    numWeeks: "0 semaine | 1 semaine | {count} semaines",
    numMonths: "0 mois | 1 mois | {count} mois",
    numDaysLeft: "0 jour à gauche | 1 jour à gauche | {count} jours",
    numDaysDelayed: "0 jour retardé | 1 jour retardé | {count} Days retardés",
  },
  notification: {
    sharedWorkflow: "partagé un flux de travail",
    assignedNewSkill: "partagé une compétence",
    sentAnnouncement: "envoyé une annonce",
    needValidation: "besoin de votre validation",
    noNotificationsMsg: "Vous n'avez actuellement aucune notification",
    justNow: "Juste maintenant",
    updatedSkill: "Mise à jour de la compétence",
    unpublishedSkill: "compétence est maintenant non publié",
    sentAReminder: "vous a envoyé un rappel sur cette compétence. Commencez à regarder afin qu'il puisse suivre vos progrès.",
    assignedNewSkillDue: "vous a attribué une nouvelle compétence. Vous avez <span>{time}</span> pour finir de le regarder.",
    delayedSkillMsg: "Vous avez une <span> compétence retardée </span>. Commencez à regarder maintenant pour se remettre sur la bonne voie!",
    daysLeftMsg: "Vous n'avez que <span>{time}</span> pour finir de regarder cette compétence. Dépêchez-vous pour que vous ne soyez pas retardé!",
    askToReviewWorkflow: "vous a demandé de revoir un flux de travail.",
    reactedWorkflowMsg: "<span>{user}</span> a réagi à votre flux de travail.",
    reactedWorkflowMsgTwoUsers: "<span>{user1} et {user2}</span> ont réagi à votre flux de travail.",
    reactedWorkflowMsgMoreUsers: "<span>{user1}, {user2} et {count} autre</span> a réagi à votre flux de travail.",
  },
  mediaPicker: {
    insertFullWorkflow: "Insérer pleinement le flux de travail",
    selectWorkflowStep: "Sélectionnez Étape de flux de travail",
    stepSelected: " étape sélectionnée"
  },
  zoomIntegration: {
    settingsTitle: "Paramètres de Zoom",
    settingsSubtitle: "L'intégration",
    deleteCopy: "Supprimer la copie de la vidéo enregistrée de Zoom Cloud",
    goToDeephow: "Aller à Deephow",
    landingSuccess: "<b> DeepHow Know-How Video App </b> Installé réussi.",
    landingError: "Une erreur s'est produite lors de l'installation de l'application vidéo <b> de DeepHow Know-How </b>. Veuillez contacter <a href = 'mailto: service@deephow.com'>service@deephow.com</a> pour le soutien.",
    landingWarning: "L'intégration de zoom n'est pas activée pour votre organisation.",
  },
  reactions: {
    likeIt: "J'aime ça",
    greatContent: "Excellent contenu",
    wellPresented: "Bien présenté",
    niceProduction: "Belle production",
  },
  maven: {
    // TODO: maven chat added on 03/15/2023
    mavenChat: "Chat",
    searchInWorkspace: "Search in {workspace}",
    showReferences: "Show references",
    hideReferences: "Hide references",
    helloMessage: "Hello {userName},",
    assistMessage: "How can I assist you today?",
    askMeAnything: "Ask me anything...",
    tryOtherQuestionMessage: "You can try asking other question.",
    audioIsReady: "Please recording your audio clip in English...",
    audioLimitationTime: "The audio limitation time is 3 mins.",
    clearChatHistory: "Clear chat history",
    newLineHint: "new line: shift + Enter",
    // TODO: maven map added on 04/14/2023
    map: "Map",
    resetMap: "reset map",
    filterWorkspaces: "filter workspaces",
    showQuestions: "show questions",
    hideQuestions: "hide questions",
    showClusters: "show clusters",
    hideClusters: "hide clusters",
    question: "Question",
    loadingMap: "Loading map...",
    clearChatHistoryConfirmation: "Clearing the chat history will start a new chat. Are you sure?",
    clearConfirm: "Yes, clear",
  },
  $vuetify: {
    dataIterator: {
      rowsPerPageText: "Objets par page:",
      rowsPerPageAll: "Tous",
      pageText: "{0}-{1} de {2}",
      noResultsText: "Aucun enregistrements correspondants trouvés",
      nextPage: "Page suivante",
      prevPage: "Page précédente"
    },
    dataTable: {
      rowsPerPageText: "Rangées par page:"
    },
    noDataText: "Pas de données disponibles",
    carousel: {
      prev: "Visuel précédent",
      next: "Suivant"
    }
  }
};
