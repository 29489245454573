import { IS_MOBILE } from "@/constants/device-version";
import Vue from "vue";

export const namespaced = true;
export const state = {
  alertConfig: {
    index: 0,
    isOpen: false,
    manualClose: false, // if true, won't auto-close alert after 3 sec
    message: "",
    type: "",
    upper: false,
  },
  userProfileConfig: {
    uid: "",
    isOpen: false,
  },
  userAccessReady: false,
  isSideMenuOpen: false,
};

export const getters = {
  getters_user_access_ready(state) {
    return state.userAccessReady;
  },
  getter_is_side_menu_open_desktop_ui(state) {
    return state.isSideMenuOpen && !IS_MOBILE;
  },
  getter_is_adjusted_position_by_opened_side_menu: (state, getters) => (routeName) => {
    const widthChangedRoutes = ["WorkspaceExplorer", "NavigatorHome"];
    return (
      getters.getter_is_side_menu_open_desktop_ui &&
      widthChangedRoutes.indexOf(routeName) >= 0 &&
      !state.userProfileConfig.isOpen
    );
  },
  getter_is_side_menu_open(state) {
    return state.isSideMenuOpen;
  },
};

export const mutations = {
  MUTATE_ALERT_CLOSE(state) {
    state.alertConfig.isOpen = false;
  },
  MUTATE_ALERT_INFO(state, { type = "info", message, upper = false, manualClose = false }) {
    state.alertConfig.type = type;
    state.alertConfig.message = message;
    state.alertConfig.upper = upper;
    state.alertConfig.manualClose = manualClose;
    state.alertConfig.index += 1;
    state.alertConfig.isOpen = true;
  },
  SET_USER_PROFILE_CLOSE(state) {
    state.userProfileConfig.uid = "";
    state.userProfileConfig.isOpen = false;
  },
  SET_USER_PROFILE_OPEN(state, uid) {
    state.userProfileConfig.uid = uid;
    state.userProfileConfig.isOpen = true;
  },
  SET_USER_ACCESS_READY(state) {
    state.userAccessReady = true;
  },
  MUTATE_SIDE_MENU_OPEN(state, { isOpen }) {
    state.isSideMenuOpen = isOpen;
  },
};

export const actions = {
  closeAlert({ commit }) {
    commit("MUTATE_ALERT_CLOSE");
  },
  openAlert({ commit }, payload) {
    commit("MUTATE_ALERT_INFO", payload);
  },
  setUserProfileClose({ commit }) {
    commit("SET_USER_PROFILE_CLOSE");
  },
  setUserProfileOpen({ commit }, { uid }) {
    commit("SET_USER_PROFILE_OPEN", uid);
  },
  setUserAccessReady({ commit }) {
    commit("SET_USER_ACCESS_READY");
  },
  closeSideBar({ commit }) {
    commit("MUTATE_SIDE_MENU_OPEN", { isOpen: false });
  },
  openSideBar({ commit }) {
    commit("MUTATE_SIDE_MENU_OPEN", { isOpen: true });
  },
};
