import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";

const organizationSettingsServer = axios.create({
  baseURL:
    environment.backendType === "firebase"
      ? environment.service[environment.env].admin
      : environment.service[environment.env]["china"].admin,
});

const config = {
  url: "admin/organization/settings",
  token: "rG5kXk0CDbhgF4RBlNoV",
};

export async function getOrganizationSettings(requestBodyOptions) {
  try {
    const requestBody = {
      token: config.token,
      action: "get",
    };
    if (requestBodyOptions.organization) {
      requestBody.userToken = await authChoice().currentUser.getIdToken();
    }
    Object.assign(requestBody, { ...requestBodyOptions });
    const { data } = await organizationSettingsServer.post(config.url, requestBody);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateOrganizationSettings({ organization, updateData }) {
  try {
    const requestBody = {
      organization,
      token: config.token,
      action: "update",
      userToken: await authChoice().currentUser.getIdToken(),
      data: updateData,
    };

    const { data } = await organizationSettingsServer.post(config.url, requestBody);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getEmployeeIdMP({ clientKey }) {
  try {
    const requestBody = {
      clientKey,
      token: config.token,
      action: "getMP",
      userToken: await authChoice().currentUser.getIdToken(),
    };

    const { data } = await organizationSettingsServer.post(config.url, requestBody);
    return data;
  } catch (error) {
    console.log(error);
  }
}
