import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].deephowApiServer
    : environment.service[environment.env]["china"].deephowApiServer;
}

const teamsServer = axios.create({
  baseURL: getBaseUrl(),
});

teamsServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

const apiWrapper = apiWrapperGenerator(teamsServer);

export const createTeam = ({ workspace, name }) => apiWrapper("post", `/v1/teams/`, { workspace, name });

export const updateTeam = ({ teamId, name }) => apiWrapper("patch", `/v1/teams/${teamId}`, { name });

export const deleteTeam = ({ teamId }) => apiWrapper("delete", `/v1/teams/${teamId}`);

export const addTeamUsers = ({ teamId, users }) => apiWrapper("post", `/v1/teams/${teamId}/addUsers`, { users });

export default teamsServer;
