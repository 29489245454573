import Cookies from "js-cookie";

/**
 * 
 * @param {import('./sso-subdomain-types').SsoSubdomain.Method.RedriectToVendorDomain.targetDomainType} targetDomain 
 * @returns 
 */
function createCookieKey(targetDomain){
    const cookieKey = "sso-subdomain-email";
    return `${targetDomain}_${cookieKey}`;
}


function saveEmailAccountIntoSpecificDomain(emailAccount, targetDomain) {
  if(!emailAccount){
    return;
  }

  Cookies.set(createCookieKey(targetDomain), emailAccount, {
    // after 10 seconds to expired
    "Max-Age": "10",    
    domain: '.deephow.ai'
  });
}

export function redriectToVendorDomain(emailAccount, targetDomain) {
  if(!targetDomain || !emailAccount){
    return;
  }

  saveEmailAccountIntoSpecificDomain(emailAccount, targetDomain)
  location.href = `https://${targetDomain}`;
}


export function getEmailAccountOfRedirection(){
  const targetDomain = location.hostname;
  return Cookies.get(createCookieKey(targetDomain))
}