import apiServer from "./api-server";
import axios from "axios";
import Vue from "vue";

export async function syncPrivatePlayerTrackingToServer({ trackingInfo }) {
  const payload = {
    ...trackingInfo,
    location: {},
  };
  const { data } = await apiServer.post(`/v1/tracking`, payload);
  if (data.success) {
    return { ok: true, data };
  } else {
    const errorMessage = `Failed to sync Private player tracking info to server - error: ${data.message}`;
    console.log(errorMessage);
    return { ok: false, errorMessage };
  }
}

export async function syncPublicPlayerTrackingToServer({ trackingInfo }) {
  try {
    const data = {
      doc: trackingInfo,
      workflowId: trackingInfo.workflowId,
    };
    const { status, statusText } = await axios({
      method: "post",
      url: Vue.prototype.$service.player + "tracking/public-videos",
      data,
    });
    const isSuccess = status === 200;
    if (isSuccess) {
      return { ok: true };
    }
    const error = `status: ${status}, statusText: ${statusText}`;
    throw error;
  } catch (error) {
    const errorMessage = `Failed to sync Public player tracking info to server -  error: ${error}`;
    console.log(errorMessage);
    return { ok: false, errorMessage };
  }
}
