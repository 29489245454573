<template lang="html">
  <section class="mixin-user"></section>
</template>

<script>
import Vue from "vue";
import firebase from "firebase";
import axios from "axios";
import eventbus from "@/main";
import auth from "../../auth.js";
import AliOss from "../js/AliOss";
import Analytics from "../js/analytics/analytics";
import environment from "../../env";
import { recordLoginActivityOfEmailLogin } from "@/server/login-activity-server.js";
import { getBrowserVersion } from "@/js/getBrowserVersion.js";
import { setLogoutLocalStorage, setLogoutSharedAccountLocalStorage } from "@/js/login/login.js";
import { isEnableMasterPassword } from "@/js/employeeid-login/employeeid-login-service.js";
import { removeShareAccountFromLocalStorage, getShareAccountFromLocalStorage } from "@/js/user/share-account.js";
import {
  BROWSER_TAB_ID_KEY,
  BROWSER_TAB_KEY,
  LOGOUT_NOTIFY_KEY,
  removeIdleLocalStorage,
  setLogoutImmediatelyLocalStorage,
} from "@/js/idle/idle.js";
import { extractEmpolyeeIdFromAccount, getEmployeeIdEmail } from "@/js/user/employee-id.js";
import { getSharedAccountEmail, extractEmpolyeeIdFromSharedAccount } from "@/js/user/share-account.js";
import { getEmployeeIdLoginToken, getDisposablePwd } from "@/server/admin-fixed-token-server.js";
import { mapActions } from "vuex";

export default {
  name: "mixin-user",
  data() {
    return {
      auth: auth,
      defaultCustomizedPermissions: {
        disablePublicLinks: false,
        disableDownloads: false,
        allowAutoLogout: false,
        autoLogoutTimeout: "15",
      },
      aliOss: AliOss,
    };
  },
  methods: {
    ...mapActions("global", ["setUserAccessReady"]),
    ...mapActions("permission", ["setHasEmployeeIdLogin"]),
    signInWithSAML() {
      console.log("Logging with SAML");
      const provider = new firebase.auth.SAMLAuthProvider("saml.jumpcloud.dh.test");

      this.auth()
        .signInWithPopup(provider)
        .then((res) => {
          self.processLogin(res.credential);
        })
        .catch((e) => {
          if (e.code == "auth/account-exists-with-different-credential") {
            console.log("Account found with different credential");
            self.processLogin(e.credential);
          } else {
            console.log("Error in login", e);
          }
        });
    },
    /**
     * You must provide a parameter (hostname or email)
     * @params {string} hostname - when you want to login with SSO
     * @param  {string} email - when you want to get the correct login domain
     */
    callCheckIdp(hostname, email) {
      const self = this;
      this.isLoadingSso = true;

      const data = {
        token: "rG5kXk0CDbhgF4RBlNoV",
      };

      if (hostname) {
        data["hostname"] = hostname;
      } else if (email) {
        data["email"] = email;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(self.$service.sso + "/check-idp", data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }).finally(() => {
        this.isLoadingSso = false;
      });
    },
    /**
     * Get login domain by email
     * @params {string} emailAccount - email pattern
     * @params {function} supportSsoLogin - passing check-idp API response that can know redirect to which domain
     * @params {function} notYetSupportSsoLogin - when check-idp API response'status is 404
     */
    checkSsoLoginStatus(emailAccount, supportSsoLogin, notYetSupportSsoLogin) {
      const self = this;

      const ignoreHostname = null;
      self
        .callCheckIdp(ignoreHostname, emailAccount)
        .then((res) => {
          let isNeedRedirectToOtherDomain = false;
          const data = res.data;
          if (data && data.domain) {
            isNeedRedirectToOtherDomain = location.hostname !== data.domain;
          }
          supportSsoLogin({
            ...data,
            isNeedRedirectToOtherDomain,
          });
        })
        .catch((e) => {
          const responseStatus = e.response && e.response.status ? e.response.status : null;
          const isNotYetSupportSsoLogin = responseStatus === 404;
          if (isNotYetSupportSsoLogin) {
            notYetSupportSsoLogin();
          } else {
            alert("The internet connection is not stable, please try again later.");
          }
        });
    },
    signInWithSSO() {
      const self = this;

      const ignoreEmail = null;
      self
        .callCheckIdp(window.location.hostname, ignoreEmail)
        .then((res) => {
          self.signInWithCustomSSO(res.data);
        })
        .catch((e) => {
          console.error(e);
          alert("Login failed");
        });
    },
    signInWithGoogleSSO() {
      this.launchSSOLogin(this.auth().signInWithPopup({ gcpIdp: "google" }));
    },
    signInWithMicrosoftSSO() {
      this.launchSSOLogin(this.auth().signInWithPopup({ gcpIdp: "microsoft" }));
    },
    signInWithCustomSSO(ssoConfig) {
      this.launchSSOLogin(this.auth().signInWithPopup(ssoConfig));
    },
    signOutWithError() {
      this.isLoggingInSso = false;
      this.isLoadingSso = false;
      this.auth()
        .signOut()
        .then(() => {
          console.log("User signed out");
        })
        .catch((e) => {
          console.log("Could not sign out user");
        })
        .finally(() => {
          alert("Login failed");
        });
    },
    launchSSOLogin(signInPromise) {
      const self = this;

      signInPromise
        .then((res) => {
          self.validateOAuth(res.credential).then((response) => {
            if (response === "error") {
              self.signOutWithError();
              return;
            }

            if (response.data.useCustomToken) {
              self
                .auth()
                .signInWithCustomToken(response.data.userToken)
                .then(() => {
                  eventbus.$emit("show-cookie-banner");
                })
                .catch(function (error) {
                  self.isLoggingInSso = false;
                  alert("Login failed");

                  return;
                });
            }

            //should only do this for the first time user
            //must refresh idToken to ensure the client side have updated token if the user is newly created
            this.auth()
              .currentUser.getIdToken(true)
              .then((idToken) => {
                self.$root.$children[0]
                  .initializeUser(self.auth().currentUser)
                  .then(() => {
                    // china sso need blow code to redirect.
                    // call App.vue redirectRouter function which contain gen2 logic and if userProfile not ready,
                    // call api immediately
                    self.$root.$children[0].redirectRouter();
                    eventbus.$emit("show-cookie-banner");
                  })
                  .catch((e) => {
                    this.isLoggingInSso = false;
                    console.log("Cannot initialize user");
                  });
              })
              .catch((err) => {
                this.isLoggingInSso = false;
                console.log("Cannot get the idToken");
              });
          });
        })
        .catch((e) => {
          if (e.code == "auth/account-exists-with-different-credential") {
            console.log("Account found with different credential");

            self.validateOAuth(e.credential).then((response) => {
              if (response === "error") {
                self.signOutWithError();
                return;
              }

              self
                .auth()
                .signInWithCustomToken(response.data.userToken)
                .then(() => {
                  location.reload();
                  eventbus.$emit("show-cookie-banner");
                })
                .catch(function (error) {
                  self.isLoggingInSso = false;
                  location.reload();
                });
            });
          } else {
            console.error(e);
            self.isLoggingInSso = false;
            self.isLoadingSso = false;
          }
        });
    },
    validateOAuth(credential) {
      this.isLoadingSso = false;
      this.isLoggingInSso = true;

      const self = this;
      const data = {
        token: "rG5kXk0CDbhgF4RBlNoV",
        idToken: credential.idToken,
        accessToken: credential.accessToken,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(self.$service.sso + "/validate-oauth", data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            //console.log("validate oauth error", err);
            //reject(err);
            resolve("error"); // reject doesn't seem to work
          });
      });
    },
    getUidByEmail(email) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByFieldValue("users", "email", email)
          .then((data) => {
            if (data.length > 0) {
              resolve(data[0].id);
            } else {
              resolve("no such user");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    generateClientkeyForEmployeeId({ employeeId }) {
      const hostname = window.location.hostname;
      const subdomain = hostname.slice(0, hostname.indexOf("."));
      let clientKey;
      if (employeeId.includes("@")) {
        clientKey = employeeId.slice(employeeId.indexOf("@") + 1);
      } else {
        clientKey = subdomain;
      }

      return clientKey;
    },
    /**
     * @params
     *  account: {employeeID}@{clientKey}
     *  employeeIdMP: master password
     * @return
     *  {
     *    ok: Boolean,
     *    data: { isSharedAccount: Boolean, clientKey: String, employeeId: String },
     *    errorMessage: String(if ok is true, errorMessage is null)
     *  }
     */
    async signInWithEmployeeId(account, employeeIdMP) {
      if (environment.backendType === "ali") {
        const clientKey = this.generateClientkeyForEmployeeId({ employeeId: account });
        if (!clientKey) {
          alert("client key is required for employee id login.");
          return { ok: false };
        }
        // Get disposable pwd by emplyee ID
        const employeeId = extractEmpolyeeIdFromAccount({ account });
        const data = { clientKey, employeeId, employeeIdMP };
        const {
          ok,
          data: { password, shared },
          errorMessage,
        } = await getDisposablePwd(data);
        if (!ok) {
          return { ok: false, errorMessage };
        }

        // Ready to use disposable pwd to sign in with email
        const employeeIdToGetEmail = shared
          ? extractEmpolyeeIdFromSharedAccount({ account })
          : extractEmpolyeeIdFromAccount({ account });
        const emailParams = { clientKey, employeeId: employeeIdToGetEmail };
        const email = shared ? getSharedAccountEmail(emailParams) : getEmployeeIdEmail(emailParams);
        const signInInfo = await auth()
          .signInWithEmailAndPassword(email, password)
          .catch((error) => {
            console.error(`Failed to sign in with email: ${error.code} - ${error.message}`);
          });

        // Record and Analytics
        Analytics.setEventProperties({
          Version: this.$settings.web.versions[environment.env]["minimum"],
        });
        const user = signInInfo.user;
        const resData = shared
          ? { isSharedAccount: true, sharedAccount: account, user }
          : { isSharedAccount: false, user };
        return { ok: true, data: resData };
      } else {
        const clientKey = this.generateClientkeyForEmployeeId({ employeeId: account });
        if (!clientKey) {
          return { ok: false, errorMessage: "client key is required for employee id login." };
        }
        const employeeId = extractEmpolyeeIdFromAccount({ account });

        // Get custom token
        const data = { clientKey, employeeId, password: employeeIdMP };
        const response = await getEmployeeIdLoginToken(this.$service.admin, data);
        if (!response.ok) {
          return { ok: false, errorMessage: response.errorMessage };
        }

        // Ready to sign in
        const token = response.data.userToken;
        const signInInfo = await auth().signInWithCustomToken(token);
        const isUserSharedAccount = response.data.shared;
        const user = signInInfo.user;
        const resData = isUserSharedAccount
          ? { isSharedAccount: true, sharedAccount: account, user }
          : { isSharedAccount: false, user };

        // Record and Analytics
        Analytics.setEventProperties({
          Version: this.$settings.web.versions[environment.env]["minimum"],
        });
        return { ok: true, data: resData };
      }
    },
    recoverPassword(email) {
      const self = this;
      return new Promise(function (resolve, reject) {
        const data = {
          token: "rG5kXk0CDbhgF4RBlNoV",
          email: email,
          baseUrl: self.$baseUrl,
          languageCode: self.$i18n.locale,
        };
        axios
          .post(self.$service.admin + "reset/forgot", data)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getIdToken() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .auth()
          .currentUser.getIdToken()
          .then(function (idToken) {
            resolve(idToken);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    signInWithEmail(email, password) {
      if (email.indexOf("@") === -1) {
        email += "@deephow.ai";
      }

      return this.auth()
        .signInWithEmailAndPassword(email, password)
        .then((signInInfo) => {
          Analytics.setEventProperties({
            Version: this.$settings.web.versions[environment.env]["minimum"],
          });
          return signInInfo;
        })
        .then(({ user }) => {
          recordLoginActivityOfEmailLogin({
            user,
            deviceInfo: getBrowserVersion(),
          });
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            alert("Wrong password.");
          } else {
            alert(errorMessage);
          }
          console.log(error);
        });

      if (window.self == window.top) {
        // is not loging in on iFrame
        this.toggleFullScreen();
      }
    },
    /**
     * Sign in with shared account, and get master pwd through API
     * @return Boolean
     */
    async signInWithSharedAccount({ account }) {
      if (environment.backendType === "ali") {
        let isSuccess = true;
        const masterPwdRep = await isEnableMasterPassword({
          clientKey: this.$clientKey,
        });
        // Get disposable pwd by emplyee ID
        const employeeId = `${extractEmpolyeeIdFromSharedAccount({ account })}.shared`;
        const data = {
          clientKey: this.$clientKey,
          employeeId,
          employeeIdMP: masterPwdRep.password,
        };
        const {
          ok,
          data: { password },
        } = await getDisposablePwd(data);
        if (!ok) {
          isSuccess = false;
          return isSuccess;
        }

        // Ready to use disposable pwd to sign in with email
        const employeeIdToGetEmail = extractEmpolyeeIdFromSharedAccount({ account });
        const emailParams = { clientKey: this.$clientKey, employeeId: employeeIdToGetEmail };
        const email = getSharedAccountEmail(emailParams);
        const signInInfo = await auth().signInWithEmailAndPassword(email, password);
        if (!signInInfo) {
          isSuccess = false;
          return isSuccess;
        }

        // Record and Analytics
        Analytics.setEventProperties({
          Version: this.$settings.web.versions[environment.env]["minimum"],
        });

        isSuccess = true;
        return isSuccess;
      } else {
        let isSuccess = true;
        try {
          const response = await isEnableMasterPassword({
            clientKey: this.$clientKey,
          });
          const employeeId = `${extractEmpolyeeIdFromSharedAccount({ account })}.shared`;
          const pwdObj = response.isEnabled ? { password: response.password } : {};
          const data = {
            employeeId,
            clientKey: this.$clientKey,
            ...pwdObj,
          };
          const token_response = await getEmployeeIdLoginToken(this.$service.admin, data);
          if (!token_response.ok) {
            throw new Error("Failed to get employee ID login token.");
          }
          const token = token_response.data.userToken;
          const signInInfo = await auth().signInWithCustomToken(token);
          if (!signInInfo) {
            throw new Error("Failed to sign in with custom token.");
          }

          // Record and Analytics
          Analytics.setEventProperties({
            Version: this.$settings.web.versions[environment.env]["minimum"],
          });
        } catch (e) {
          console.error(e);
          isSuccess = false;
        } finally {
          return isSuccess;
        }
      }
    },
    toggleFullScreen() {
      // TODO: 2022/11/22 replace below code with togglePlayerFullscreen() in js/workflow/fullscreen.js
      // apply on: UserNotifications.vue
      if (!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement)) {
        const _element = document.documentElement;
        if (_element.requestFullscreen) {
          _element.requestFullscreen();
        } else {
          if (_element.mozRequestFullScreen) {
            _element.mozRequestFullScreen();
          } else {
            if (_element.webkitRequestFullscreen) {
              _element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
          }
        }
      }
    },
    cancelFullScreen() {
      // TODO: 2022/11/22 (ready to deprecate)
      // this method is not applyed on any components
      // if you want to use it, please use closePlayerFullscreen() in js/workflow/fullscreen.js instead
      if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else {
          if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else {
            if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen();
            }
          }
        }
      }
    },
    getGroupName(id) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocument("groups", id)
          .then((data) => {
            resolve(data.name);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setPrototypeUser(user = null) {
      Vue.prototype.$user = user;
    },
    setPrototypeOrganization(organization = null) {
      Vue.prototype.$organization = organization;
    },
    initAccess() {
      Vue.prototype.$access = {};
      Vue.prototype.$access["permissions"] = false;
      Vue.prototype.$access["ssoMapping"] = false;
      Vue.prototype.$access["categoriesCreation"] = false;
      Vue.prototype.$access["teams"] = false;
      Vue.prototype.$access["categories"] = true;
      Vue.prototype.$access["languages"] = false;
      Vue.prototype.$access["isOrgAdmin"] = false;
      Vue.prototype.$access["masterPassword"] = false;
      Vue.prototype.$access["companyLogo"] = false;
    },
    setUserAccess() {
      return new Promise(
        function (resolve, reject) {
          try {
            if (this.$role === "admin-organization" || this.$role === "admin-super") {
              Vue.prototype.$access["player"] = true;
              Vue.prototype.$access["editor"] = true;
              Vue.prototype.$access["admin"] = true;
              Vue.prototype.$access["analytics"] = true;
              Vue.prototype.$access["skills"] = true;
              Vue.prototype.$access["teams"] = true;
              Vue.prototype.$access["languages"] = true;
              Vue.prototype.$access["permissions"] = true;
              Vue.prototype.$access["ssoMapping"] = true;
              Vue.prototype.$access["categoriesCreation"] = true;
              Vue.prototype.$access["isOrgAdmin"] = true;
              Vue.prototype.$access["masterPassword"] = true;
              Vue.prototype.$access["companyLogo"] = true;
            } else if (this.$role === "admin-group") {
              Vue.prototype.$access["player"] = true;
              Vue.prototype.$access["editor"] = true;
              Vue.prototype.$access["admin"] = true;
              Vue.prototype.$access["analytics"] = true;
              Vue.prototype.$access["skills"] = true;
              Vue.prototype.$access["teams"] = true;
            } else if (this.$role === "viewer") {
              Vue.prototype.$access["player"] = true;
            } else if (this.$role === "publisher") {
              Vue.prototype.$access["player"] = true;
              Vue.prototype.$access["editor"] = true;
              Vue.prototype.$access["skills"] = true;
            } else {
              //allow custom role in the future
            }
            //overwrite default setting if cloud setting is available
            if (this.$clientSettings && this.$clientSettings.permissions) {
              // const accessCategoriesCreation = this.$clientSettings.permissions.categoriesCreation && this.$clientSettings.permissions.categoriesCreation.includes(this.$role);
              // Vue.prototype.$access["categoriesCreation"] = accessCategoriesCreation;
              const accessSkills =
                this.$clientSettings.permissions.skillsManager &&
                this.$clientSettings.permissions.skillsManager.includes(this.$role);
              Vue.prototype.$access["skills"] = accessSkills;
              const accessAnalytics =
                this.$clientSettings.permissions.analytics &&
                this.$clientSettings.permissions.analytics.includes(this.$role);
              Vue.prototype.$access["analytics"] = accessAnalytics;
            }
            // const hostname = window.location.hostname;
            // const subdomain = hostname.slice(0,hostname.indexOf("."));
            // if(hostname == "localhost" || subdomain.includes("dev") || subdomain.includes("beta")){
            //     // Vue.prototype.$access["categories"] = true;
            // }

            // TODO: consider to refactor $access to Vuex.
            // record user access is ready to Vuex, will be used to avoid race condition.
            this.setUserAccessReady();
            resolve();
          } catch (err) {
            reject(err);
          }
        }.bind(this)
      );
    },
    setUserStorageAccess(retry = 3) {
      retry--;
      const self = this;
      if (!self.aliOss) {
        return;
      }
      this.auth()
        .currentUser.getIdToken()
        .then((idToken) => {
          const adminRequireUserData = {
            organization: self.$userProfile.organization,
            role: self.$userProfile.role,
            idToken: idToken,
          };
          self.aliOss.setUserStsData(adminRequireUserData);
        })
        .catch((error) => {
          console.error(error);
          if (retry > 0) {
            self.setUserStorageAccess(retry);
          }
        });
    },
    /** set and get methods of $userProfile and Vue.prototype instance about org setting */
    async setOrgSettings(setting = {}) {
      Vue.prototype.$defaultWorkspaceGroupId = setting.defaultWorkspaceGroupId;
      Vue.prototype.$defaultGroup = setting.defaultGroup;
      Vue.prototype.$clientKey = setting.clientKey;
      this.setHasEmployeeIdLogin(setting.employeeIdLogin);
      Vue.prototype.$clientSettings = setting;
    },
    async getOrgSettings(organization) {
      try {
        const settingFromDB = this.getDocument("settings", organization);
        return settingFromDB;
      } catch (e) {
        console.error(`failed to get user settings: ${e}`);
      }
    },
    setVueUserProfile(data = { organization, locale, role, shared, groups }) {
      Vue.prototype.$user = this.auth().currentUser;
      Vue.prototype.$userProfile = data;
      Vue.prototype.$organization = data.organization;
      Vue.prototype.$locale = data.locale;
      Vue.prototype.$role = data.role;
      Vue.prototype.$isSharedAccount = data.shared || false;
      Vue.prototype.$groups = data.groups;
    },
    async getUserDataFromDB({ uid = "" }) {
      try {
        const userFromDB = await this.getDocument("users", uid);
        if (!userFromDB.role) throw new Error("This email account is not registered with DeepHow.");

        return userFromDB;
      } catch (e) {
        console.error(e);
      }
    },
    async getUserRole(user) {
      try {
        const userFromDB = await this.getUserDataFromDB({ uid: user.uid });
        /** set this.$userProfile */
        const userProfile = {
          ...userFromDB,
          locale: userFromDB.locale || "en-us",
        };
        this.setVueUserProfile(userProfile);

        /** get and set org settings of vue.prototype */
        const settingData = await this.getOrgSettings(userFromDB.organization);
        this.setOrgSettings(settingData);

        /** tracking user log */
        Analytics.setUserOrganization(settingData.organization);
      } catch (e) {
        console.error(e);
      }
    },
    /** TODO: other not organize methods */
    getCustomizedPermissions() {
      if (this.$clientSettings.customizedPermissions) {
        this.defaultCustomizedPermissions = this.$clientSettings.customizedPermissions;
      }

      return this.defaultCustomizedPermissions;
    },
    handleStrWithSpecialChar(value) {
      /** After escapeHTML, still convert some character to previous status */
      const escapeHTML = this.$escapeHtml(value);
      const charToBackRegx = /&apos;/g;
      return escapeHTML.replace(charToBackRegx, "'");
    },
    addOrgAdminUser(email, role, displayName, languageCode) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        const type = "org-admin";
        const data = {
          idToken: await self.getIdToken(),
          displayName: self.handleStrWithSpecialChar(displayName),
          organization: self.$organization,
          role: role,
          email: self.handleStrWithSpecialChar(email),
          baseUrl: self.$baseUrl,
          languageCode: languageCode,
        };

        axios
          .post(self.$service.admin + "user/add", {
            token: "rG5kXk0CDbhgF4RBlNoV",
            type: type,
            data: data,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            console.warn(err);
            reject(err);
          });
      });
    },
    async addGroupUser({ email, role, displayName, groupId, languageCode }) {
      try {
        const type = "group-user";
        const emailToLowerCase = email.toLowerCase();
        const idToken = await this.getIdToken();
        const data = {
          idToken,
          displayName: this.handleStrWithSpecialChar(displayName),
          organization: this.$organization,
          role,
          email: this.handleStrWithSpecialChar(emailToLowerCase),
          groupId,
          baseUrl: this.$baseUrl,
          languageCode,
        };
        return axios.post(this.$service.admin + "user/add", {
          token: "rG5kXk0CDbhgF4RBlNoV",
          type,
          data,
        });
      } catch (err) {
        return err;
      }
    },
    async addPasswordlessUser(employeeId, role, displayName, groups, languageCode, teams) {
      try {
        const type = "user-passwordless";
        const idToken = await this.getIdToken();
        const teamsObj = teams && teams.length > 0 ? { teams } : {};
        const data = {
          idToken,
          displayName: this.handleStrWithSpecialChar(displayName),
          organization: this.$organization,
          role,
          employeeId: this.handleStrWithSpecialChar(employeeId),
          groups,
          ...teamsObj,
          clientKey: this.$clientKey,
          baseUrl: this.$baseUrl,
          languageCode,
        };
        const axios_result = await axios.post(this.$service.admin + "user/add", {
          token: "rG5kXk0CDbhgF4RBlNoV",
          type,
          data,
        });
        return axios_result;
      } catch (e) {
        console.error(e);
      }
    },
    async addSharedUser(employeeId, role, displayName, groups, languageCode) {
      console.log("addUser", employeeId, role, displayName, groups, languageCode);
      const type = "user-shared";
      const data = {
        idToken: await this.getIdToken(),
        displayName: this.$escapeHtml(displayName),
        organization: this.$organization,
        role,
        employeeId: this.$escapeHtml(employeeId),
        groups,
        clientKey: this.$clientKey,
        baseUrl: this.$baseUrl,
        languageCode,
      };
      try {
        return axios.post(this.$service.admin + "user/add", {
          token: "rG5kXk0CDbhgF4RBlNoV",
          type,
          data,
        });
      } catch (err) {
        console.warn(err);
      }
    },
    getEmailOfPasswordlessUser(employeeId) {
      return this.$clientKey + "." + employeeId.toLowerCase() + "@deephow.ai";
    },
    getEmailOfSharedAccountUser(employeeId) {
      return `${this.$clientKey}.shared.${employeeId.toLowerCase()}@deephow.ai`;
    },
    /** methods: logout */
    signOutSetWindowsApp() {
      const userProfile = this.$store.state.userProfile;
      if (this.$backendType != "ali") {
        const downloaded = JSON.parse(localStorage["downloadWindowsApp" + userProfile.id] || false);
        if (!downloaded) {
          localStorage.setItem("showWindowsApp" + userProfile.id, false);
        }
      }
    },
    async logout() {
      removeIdleLocalStorage(BROWSER_TAB_ID_KEY);
      removeIdleLocalStorage(BROWSER_TAB_KEY);
      removeIdleLocalStorage(LOGOUT_NOTIFY_KEY);
      if (this.$isSharedAccount) {
        removeShareAccountFromLocalStorage();
      }
      const sharedAccount = getShareAccountFromLocalStorage();
      if (sharedAccount) {
        const isLoginSuccess = await this.signInWithSharedAccount({ account: sharedAccount });
        if (isLoginSuccess) {
          this.$router.push("/shared");
        } else {
          this.$router.push("/");
        }
        setLogoutSharedAccountLocalStorage();
      } else {
        /** In global version, when logout trigger in workflow player, it may cause logout failed
         * to solve it, we add "setLogoutImmediatelyLocalStorage()"
         * and it would check after location reload in App.vue
         */
        const regexPrivateLink = new RegExp(/\/playermain/);
        const isInWorkflowPlayer = regexPrivateLink.test(location.pathname);
        const isChinaEnv = environment.backendType === "ali";
        if (isInWorkflowPlayer && !isChinaEnv) setLogoutImmediatelyLocalStorage();

        this.signOutSetWindowsApp();
        await this.auth().signOut();

        this.$router.push("/");
      }

      /** refresh page across all browser tabs */
      setLogoutLocalStorage();

      location.reload();
      this.$nextTick(() => this.cancelFullScreen());
    },
  },
};
</script>
