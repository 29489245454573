import environment from "../../../env";
import firebase from "firebase";
import db from "../../../db";
import { getSortedWorkflows } from "@/js/workflow/sortWorkflows.js";

function fetchWorkflows({ comparisons, methods, updateLastDocFn, self }) {
  return new Promise((resolve, reject) => {

    const result = [];
    db.getDocumentByQuery2.call(
      self,
      "workflows",
      comparisons,
      methods,
      querySnapshot => {
        querySnapshot.forEach(doc => {
          result.push(doc.data());
        });

        const workflowArray = environment.backendType === 'ali' ? querySnapshot : querySnapshot.docs;

        updateLastDocFn({
          lastDoc: workflowArray[workflowArray.length - 1]
        });
        resolve(result);
      }
    );
  }).catch(err => {
    console.error(err);
  });
}

function resetToOriginalOrder({
  targetArray
}) {
  targetArray.sort((prev, next) => prev.originalIndex - next.originalIndex);
}

export const namespaced = true;
const getGoogleFirestore = function () {
  return environment.backendType !== "ali" ? firebase.firestore() : null;
};
export const state = {
  recommends_from_api: [],
  workspaces_from_api: [],
  workspaces_only_like: [],
  workspaces_only_keywords: [],
  workflows_by_workspaces: [],
  workflows_by_search_api_with_workspace: {
    title: [],
    content: [],
    publisher: [],
  },
  title_content_numbers: {
    title: 0,
    content: 0,
    publisher: 0,
  },
  workflows_by_workspaces_sort_rule: {},
  workspace_sort_rule: {},
  workspaces_assigned_private: [],
  workspaces_numbers_from_analytic_api: [],
  stephanie_suggestions: {},
  play_main_data: {},
  share_popup_data: {},
};

export const mutations = {
  STORE_RECOMMEND_DATA(state, payload) {
    state.recommends_from_api = payload;
  },
  STORE_CONTINUE_WATCHING_DATA(state, newContinueWatchingData) {
    const continueWatchingSource = state.recommends_from_api.find((data) => data.recommendType === "continue-watching");
    if (continueWatchingSource) {
      continueWatchingSource.workflows = newContinueWatchingData.workflows;
    }
  },
  STORE_WORKSPACES_DATA(state, payload) {
    const workspace_classified = payload.reduce(
      (accumulation, current) => {
        if (current.isInThisGroup) {
          accumulation["assign"] = accumulation["assign"].concat(current);
        } else if (current.isLike) {
          accumulation["isLike"] = accumulation["isLike"].concat(current);
        } else {
          accumulation["isNotLike"] = accumulation["isNotLike"].concat(current);
        }
        return accumulation;
      },
      { assign: [], isLike: [], isNotLike: [] }
    );
    sortFunctionForWorkspace("alphabetically", workspace_classified["assign"]);
    sortFunctionForWorkspace("alphabetically", workspace_classified["isLike"]);
    // sortFunctionForWorkspace(
    //   "alphabetically",
    //   workspace_classified["isNotLike"]
    // );
    state.workspaces_from_api = []
      .concat(workspace_classified["assign"])
      .concat(workspace_classified["isLike"]);
    // .concat(workspace_classified["isNotLike"]);
  },
  STORE_WORKFLOW_BY_WORKSPACES(state, workflows) {
    state.workflows_by_workspaces = workflows;
  },
  STORE_WORKFLOW_SORT_RULE(state, rule) {
    state.workflows_by_workspaces_sort_rule = rule;
  },
  STORE_WORKSPACE_SORT_RULE(state, rule) {
    state.workspace_sort_rule = rule;
  },
  STORE_ONLY_LIKE_WORKSPACES(state, workspaces = []) {
    state.workspaces_only_like = workspaces;
  },
  STORE_ONLY_KEYWORD_WORKSPACES(state, workspaces = []) {
    if (Array.isArray(workspaces)) {
      workspaces.forEach((workspace, index) => {
        workspace.originalIndex = index;
      })
    }

    state.workspaces_only_keywords = workspaces;
  },
  STORE_WORKSPACES_ASSIGNED_PRIVATE(state, payload = []) {
    state.workspaces_assigned_private = payload;
  },
  STORE_WORKSPACE_NUMBER_FROM_ANALYTIC_API(state, payload = []) {
    state.workspaces_numbers_from_analytic_api = payload;
  },
  CLEAN_WORKFLOW_BY_WORKSPACE(state) {
    state.workflows_by_workspaces = [];
  },
  STORE_WORKFLOW_BY_SEARCH_API(state, { tabName, data }) {
    state.workflows_by_search_api_with_workspace[
      tabName
    ] = state.workflows_by_search_api_with_workspace[tabName].concat(data);
  },
  COUNT_SEARCH_API_TITLE_CONTENT_NUMBER(state, tabName) {
    state.title_content_numbers[tabName]++;
  },
  MERGE_INTO_WORKFLOW_BY_WORKSPACE(state, newWorkflowList) {
    const isNoDataToMerge = !Array.isArray(newWorkflowList) || newWorkflowList.length === 0;
    if (isNoDataToMerge) {
      return;
    }

    const workflowIndexDictionary = {};
    state.workflows_by_workspaces.forEach((workflow, index) => {
      workflowIndexDictionary[workflow.id] = index;
    });

    const mergedWorkflowList = [...state.workflows_by_workspaces];
    newWorkflowList.forEach((newWorkflow) => {
      const replaceIndex = workflowIndexDictionary[newWorkflow.id];
      if (replaceIndex) {
        // replace
        mergedWorkflowList.splice(replaceIndex, 1, newWorkflow);
      } else {
        // inster
        mergedWorkflowList.push(newWorkflow);
      }
    });

    state.workflows_by_workspaces = mergedWorkflowList;
  },
  CLEAN_WORKFLOW_BY_SEARCH_API(state) {
    state.workflows_by_search_api_with_workspace = {
      title: [],
      content: [],
      publisher: [],
    };
    state.title_content_numbers = {
      title: 0,
      content: 0,
      publisher: 0,
    };
  },
  STORE_STEPHANIE_SUGGESTION(state, payload = {}) {
    state.stephanie_suggestions = payload;
  },
  STORE_PLAY_MAIN_DATA(state, payload = {}) {
    state.play_main_data = payload;
  },
  CLEAN_PLAY_MAIN_DATA(state) {
    state.play_main_data = {};
  },
  STORE_SHARE_POPUP_DATA(state, payload = {}) {
    state.share_popup_data = payload;
  },
  CLEAN_SHARE_POPUP_DATA(state) {
    state.share_popup_data = {};
  },
};

export const getters = {
  getters_recommends_data: (state) => state.recommends_from_api,
  getters_workflows_without_keyword: (state) => {
    let filter_workflow = state.workflows_by_workspaces.filter(
      (workflow) =>
        !workflow.archived && !workflow.deleted
    );

    if (state.workflows_by_workspaces_sort_rule) {
      filter_workflow = getSortedWorkflows(
        state.workflows_by_workspaces_sort_rule.sort,
        filter_workflow
      );
    }
    return filter_workflow || [];
  },
  getters_workspaces_data: (state) => state.workspaces_from_api,
  getters_only_like_workspaces: (state) =>
    state.workspaces_only_like.filter(({ isInThisGroup }) => !isInThisGroup),
  getters_only_keywords_workspaces: (state) => {
    const targetArray = state.workspaces_only_keywords;

    resetToOriginalOrder({
      targetArray
    });
    sortFunctionForWorkspace(state.workspace_sort_rule.sort, targetArray);

    return targetArray;
  },
  getters_workspace_published_number: (state) => (workspaceId) => {
    if (!workspaceId) {
      return state.workspaces_numbers_from_analytic_api.reduce(
        (accumulation, current) => {
          // all group only count and show workflows of public workspace
          const isPrivate = state.workspaces_assigned_private.indexOf(current.id) >= 0;
          return isPrivate ? accumulation : accumulation + current.workflows.published;
        }, 0);
    } else {
      const target = state.workspaces_numbers_from_analytic_api.find(
        ({ id }) => id === workspaceId
      );
      if (target && target.workflows) {
        return target.workflows.published;
      }
    }
  },
  getters_workflows_by_tabName: (state) => (tabName, sortRule) => {
    let target = state.workflows_by_search_api_with_workspace[tabName];
    if (Array.isArray(target)) {
      target = getSortedWorkflows(sortRule, target);
    }
    return target || [];
  },
  getters_stephanie_suggestions: (state) =>
    state.stephanie_suggestions.results || [],
  getters_play_main_data: (state) => state.play_main_data || {},
  getters_share_popup_data: (state) => state.share_popup_data || {},
  getters_is_not_in_private_workspace: (state) => (workflow) => {
    return workflow.privateWorkspace && state.workspaces_assigned_private.indexOf(workflow.group) === -1;
  },
};

export const actions = {
  async fetchMoreWorkflowsByGroup({ commit }, payload) {
    this.$db = getGoogleFirestore();
    const newWorkflowList = await fetchWorkflows({
      ...payload,
      self: this
    });

    commit('MERGE_INTO_WORKFLOW_BY_WORKSPACE', newWorkflowList)
  },
  async storeContinueWatchingData({ commit, state }, apiResult = []) {
    try {
      const isEmptyData = !Array.isArray(apiResult) || apiResult.length === 0;
      if (isEmptyData) {
        return;
      }
      let newContinueWatchingData = apiResult.find((data) => data.recommendType === "continue-watching");
      if (!newContinueWatchingData) {
        return;
      }

      this.$db = getGoogleFirestore();

      const promises = [];
      newContinueWatchingData.workflows
        .forEach((workflow_data) => {
          const promise_fn = async () => {
            workflow_data["workflow"] = await db.getDocument
            .call(this, "workflows", workflow_data.workflowId)
            .catch(err => {
                console.warn("Faild to get workflow data", err);
                return null;
              });
          };
          promises.push(promise_fn());
        });

      await Promise.all(promises);
      // filter out the workflow if can not get the detail data from db
      newContinueWatchingData.workflows = newContinueWatchingData.workflows.filter(
        workflow_data => workflow_data && workflow_data.workflow
      );
      commit("STORE_CONTINUE_WATCHING_DATA", newContinueWatchingData);
    } catch (error) {
      console.log(error);
    }
  },
  async storeRecommendData({ commit }, apiResult = []) {
    try {
      this.$db = getGoogleFirestore();

      const promises = [];
      apiResult.forEach(({ workflows }) => {
        workflows.forEach((workflow_data) => {
          const promise_fn = async () => {
            workflow_data["workflow"] = await db.getDocument
              .call(this, "workflows", workflow_data.workflowId)
              .catch(err => {
                console.warn("Faild to get workflow data", err);
                return null;
              });
          };
          promises.push(promise_fn());
        });
      });
      await Promise.all(promises);
      // filter out the workflow if can not get the detail data from db
      apiResult.forEach(item => {
        if (item.workflows) {
          item.workflows = item.workflows.filter(workflow_data => workflow_data && workflow_data.workflow);
        }
      });
      commit("STORE_RECOMMEND_DATA", apiResult);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchWorkflowsByGroup({ commit }, payload) {
    try {
      commit("CLEAN_WORKFLOW_BY_WORKSPACE");

      this.$db = getGoogleFirestore();
      const result = await fetchWorkflows({
        ...payload,
        self: this
      });
      commit("STORE_WORKFLOW_BY_WORKSPACES", result);
    } catch (error) {
      console.log(error);
    }
  },
  changeWorkflowSortRule({ commit }, rule) {
    commit("STORE_WORKFLOW_SORT_RULE", rule);
  },
  changeWorkspaceSortRule({ commit }, rule) {
    commit("STORE_WORKSPACE_SORT_RULE", rule);
  },
  storeWorkspaceData({ commit }, apiResult = []) {
    commit("STORE_WORKSPACES_DATA", apiResult);
  },
  storeOnlyLikeWorkspaces({ commit }, apiResult = []) {
    commit("STORE_ONLY_LIKE_WORKSPACES", apiResult);
  },
  storeOnlyKeywordsWorkspaces({ commit }, apiResult = []) {
    commit("STORE_ONLY_KEYWORD_WORKSPACES", apiResult);
  },
  storeWorkspacesAssignedPrivate({ commit }, apiResult = []) {
    commit("STORE_WORKSPACES_ASSIGNED_PRIVATE", apiResult);
  },
  storeWorkspacesNumberFromAnalyticApi({ commit }, apiResult = []) {
    commit("STORE_WORKSPACE_NUMBER_FROM_ANALYTIC_API", apiResult);
  },
  async storeWorkflowBySearchApi({ commit }, apiResult = []) {
    commit("CLEAN_WORKFLOW_BY_SEARCH_API");
    let workflow_ids = null;

    if (Array.isArray(apiResult)) {
      workflow_ids = apiResult.reduce(
        (accumulation, { highlight, id }) => {
          highlight.forEach((tabName) => {
            commit("COUNT_SEARCH_API_TITLE_CONTENT_NUMBER", tabName);
            accumulation[tabName].push(id);
          });
          return accumulation;
        },
        { title: [], content: [], publisher: [] }
      );
    }

    if (workflow_ids) {
      let promise = [];
      this.$db = getGoogleFirestore();
      for (const tabName in workflow_ids) {
        if (
          workflow_ids.hasOwnProperty(tabName) &&
          Array.isArray(workflow_ids[tabName])
        ) {
          workflow_ids[tabName].map((workflow_id) => {
            const promise_fn = async () => {
              const data = await db.getDocument.call(
                this,
                "workflows",
                workflow_id
              );
              commit("STORE_WORKFLOW_BY_SEARCH_API", { tabName, data });
            };
            promise.push(promise_fn());
          });
        }
      }
      await Promise.all(promise);
    }
  },
  storeStephanieSuggestion({ commit }, apiResult = {}) {
    commit("STORE_STEPHANIE_SUGGESTION", apiResult);
  },
  storePlayMainData({ commit }, data) {
    commit("CLEAN_PLAY_MAIN_DATA");
    commit("STORE_PLAY_MAIN_DATA", data);
  },
  cleanPlayMainData({ commit }) {
    commit("CLEAN_PLAY_MAIN_DATA");
  },
  storeShareData({ commit }, data) {
    commit("CLEAN_SHARE_POPUP_DATA");
    commit("STORE_SHARE_POPUP_DATA", data);
  },
  cleanShareData({ commit }) {
    commit("CLEAN_SHARE_POPUP_DATA");
  },
  replaceWorkspaceByWorkspaceId({ state }, { workspaceId, data }) {
    const index = state.workspaces_from_api.findIndex(
      ({ id }) => id === workspaceId
    );
    if (index !== -1) {
      state.workspaces_from_api[index] = data;
    }
  },
};

const sortFunctionForWorkspace = (rules, targetArray) => {
  if (rules === null) return;
  switch (rules) {
    case "alphabetically":
      targetArray.sort(
        (first, second) =>
          first.name
            .trim()
            .toLowerCase()
            .charCodeAt(0) -
          second.name
            .trim()
            .toLowerCase()
            .charCodeAt(0)
      );
      break;
    case "last-updated":
      const getUploadedAtValueFn = (workspace) => {
        const updatedAtValue = workspace.updatedAt;
        const isChinaVersion = environment.backendType === "ali";
        return isChinaVersion ? updatedAtValue : updatedAtValue._seconds;
      }
      targetArray.sort(
        (first, second) => getUploadedAtValueFn(second) - getUploadedAtValueFn(first)
      );
      break;
  }
};
