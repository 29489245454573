<template>
  <svg width="24" height="24" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0H2C0.875 0 0 0.90625 0 2V12C0 13.125 0.875 14 2 14H16C17.0938 14 18 13.125 18 12V2C18 0.90625 17.0938 0 16 0ZM5.25 8.0625C5.84375 8.65625 6.78125 8.65625 7.375 8.0625C7.65625 7.78125 8.15625 7.78125 8.4375 8.0625C8.71875 8.375 8.71875 8.84375 8.4375 9.125C7.84375 9.71875 7.09375 10 6.3125 10C5.53125 10 4.78125 9.71875 4.1875 9.125C3.625 8.5625 3.3125 7.8125 3.3125 7C3.3125 6.21875 3.59375 5.46875 4.1875 4.90625C5.375 3.71875 7.25 3.71875 8.4375 4.90625C8.71875 5.1875 8.71875 5.65625 8.4375 5.96875C8.125 6.25 7.65625 6.25 7.375 5.96875C6.78125 5.375 5.84375 5.375 5.25 5.96875C4.96875 6.25 4.8125 6.625 4.8125 7C4.8125 7.40625 4.96875 7.78125 5.25 8.0625ZM11.25 8.0625C11.8438 8.65625 12.7812 8.65625 13.375 8.0625C13.6562 7.78125 14.1562 7.78125 14.4375 8.0625C14.7188 8.375 14.7188 8.84375 14.4375 9.125C13.8438 9.71875 13.0938 10 12.3125 10C11.5312 10 10.7812 9.71875 10.1875 9.125C9.625 8.5625 9.3125 7.8125 9.3125 7C9.3125 6.21875 9.59375 5.46875 10.1875 4.90625C11.375 3.71875 13.25 3.71875 14.4375 4.90625C14.7188 5.1875 14.7188 5.65625 14.4375 5.96875C14.125 6.25 13.6562 6.25 13.375 5.96875C12.7812 5.375 11.8438 5.375 11.25 5.96875C10.9688 6.25 10.8125 6.625 10.8125 7C10.8125 7.40625 10.9688 7.78125 11.25 8.0625Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "DIconSubtitle",
};
</script>

<style scoped>
</style>
