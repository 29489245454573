import { render, staticRenderFns } from "./AdminWorkspaceMain.vue?vue&type=template&id=51ca896f&scoped=true&"
import script from "./AdminWorkspaceMain.vue?vue&type=script&lang=js&"
export * from "./AdminWorkspaceMain.vue?vue&type=script&lang=js&"
import style0 from "./AdminWorkspaceMain.vue?vue&type=style&index=0&id=51ca896f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ca896f",
  null
  
)

export default component.exports