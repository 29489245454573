/**
 * Get the employee ID from sign-in account
 * account format: {employeeID}@{clientKey}
 * @param { account: String }
 *  account: {employeeId}@{clientKey}
 * @returns String - employeeID
 */
export const extractEmpolyeeIdFromAccount = ({ account = "" }) => {
  return account.includes("@")
    ? account.substring(0, account.indexOf("@"))
    : account;
};

/**
 * Get email by employee ID and clientKey
 * @param { clientKey: String, employeeID: String }
 * @returns String - email combined with employeeID and clientKey
 * email format: {clientKey}.{employee ID with lower case}@deephow.ai
 */
export const getEmployeeIdEmail = ({ clientKey = "", employeeId = "", }) => {
  const lowerCaseEmployeeId = employeeId.toLowerCase();
  return `${clientKey}.${lowerCaseEmployeeId}@deephow.ai`;
};

/**
 * Get account by employee ID and clientKey
 * @param { clientKey: String, employeeID: String }
 * @returns String - account combined with employeeID and clientKey
 * account format: {employeeID}@{clientKey}
 */
export const getEmployeeIdAccount = ({ clientKey = "", employeeId = "", }) => {
  return `${employeeId}@${clientKey}`;
};