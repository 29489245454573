<template >
  <div class="PlayerPublic">
    <!-- loading -->
    <player-loading v-if="isLoading" />

    <!-- unpublished video -->
    <player-unpublished-video
      v-else-if="showErrorMsg"
      isPublic
      :isUsingIframe="isUsingIframe"
      :isMobileDevice="IS_MOBILE"
    >
      <span v-if="isDeleted" v-html="$t('player.thisWorkflowIsNotAvailable')"></span>
      <span v-else-if="isNotExists" v-html="$t('player.thisWorkflowDoesNotExist')"></span>
    </player-unpublished-video>

    <component
      v-else
      :is="IS_MOBILE ? 'player-processed-video-mobile' : 'player-processed-video'"
      :ref="IS_MOBILE ? 'PlayerProcessedVideoPhone' : 'PlayerProcessedVideo'"
      isPublic
      :workflow="workflowData"
      :steps="getters_steps"
      :attachmentsData="getter_attachments"
      :stepId="stepId"
      :isIosDevice="isIosDevice"
      :isUsingIframe="isUsingIframe"
      :iframeFullscreen="iframeFullscreen"
      :isPlayerAttachmentShowUp.sync="isHidePlayerAnnotation"
      @fetchVideoDataAndCurrentStepIndex="fetchVideoDataAndCurrentStepIndex"
    >
      <template slot="annotation">
        <PlayerAnnotation :isHideCanvas.sync="isHidePlayerAnnotation" :workflow="workflowData" :videoData="videoData" />
      </template>
    </component>
  </div>
</template>

<script >
import PlayerLoading from "@/components/DWorkflowPlayer/player/message/PlayerLoading.vue";
import PlayerProcessedVideo from "@/components/DWorkflowPlayer/player/PlayerProcessedVideo.vue";
import PlayerProcessedVideoMobile from "@/components/DWorkflowPlayer/player/PlayerProcessedVideoMobile.vue";
import PlayerUnpublishedVideo from "@/components/DPlayer/PlayerUnpublishedVideo.vue";
import PlayerAnnotation from "@/components/DPlayer/PlayerAnnotation";
import { mapActions, mapState, mapGetters } from "vuex";
import { IS_MOBILE } from "@/constants/device-version";

export default {
  name: "PlayerPublic",
  // note: this is a copy of "@/components/PlayerPublic.vue" and removed unsed code
  // if these are some bugs could compare to the old version
  // the old version will be removed when new version is stable
  props: {
    isIosDevice: {
      type: Boolean,
    },
  },
  components: {
    PlayerLoading,
    PlayerProcessedVideo,
    PlayerProcessedVideoMobile,
    PlayerUnpublishedVideo,
    PlayerAnnotation,
  },
  data() {
    const videoData = {
      clientWidth: 0,
      clientHeight: 0,
      currentStepIndex: 0,
      currentVideoTime: 0,
    };
    return {
      IS_MOBILE,
      videoData,
      workflowId: "",
      stepId: "",
      isUsingIframe: false,
      iframeFullscreen: false,
      isLoading: true,
      isPublished: true,
      isDeleted: false,
      isNotExists: false,
      isHidePlayerAnnotation: false,
    };
  },
  async created() {
    this.workflowId = this.$route.params.workflowId;
    this.stepId = this.$route.params.stepId;
    const { isSuccess } = await this.fetchWorkflowData({ workflowId: this.workflowId, isPublic: true });
    this.isLoading = false;
    if (isSuccess) {
      if (!this.workflowData.published) {
        this.showErrorMessage();
        this.clearWorkflowData();
      }
    } else {
      this.showErrorMessage();
    }

    if (window.self != window.top) {
      this.isUsingIframe = true;
      this.iframeFullscreen = false;
      window.addEventListener("fullscreenchange", this.fullscreenChangeHandler);
    }
  },
  beforeDestroy() {
    window.removeEventListener("fullscreenchange", this.fullscreenChangeHandler);
  },
  methods: {
    ...mapActions("workflowPlayer", ["fetchWorkflowData", "clearWorkflowData", "showErrorMessage"]),
    fullscreenChangeHandler() {
      this.iframeFullscreen = !this.iframeFullscreen;
      if (this.iframeFullscreen) {
        this.$refs.PlayerProcessedVideo.playing = true;
      } else {
        this.$refs.PlayerProcessedVideo.closeAllComponents();
        this.$refs.PlayerProcessedVideo.playing = false;
      }
    },
    fetchVideoDataAndCurrentStepIndex(videoData) {
      for (const key in videoData) {
        if (Object.prototype.hasOwnProperty.call(videoData, key)) {
          this.videoData[key] = videoData[key];
        }
      }
    },
  },
  computed: {
    ...mapState("workflowPlayer", ["workflowData", "showErrorMsg"]),
    ...mapGetters("workflowPlayer", ["getters_steps", "getter_attachments"]),
  },
};
</script>

<style lang="scss" scoped>
.PlayerPublic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #242527;
}
</style>
