<template>
  <div class="DesktopStepNameTooltip">
    <div class="DesktopStepNameTooltip__button">
      <slot></slot>
    </div>
    <div v-if="!hidden" class="DesktopStepNameTooltip__tooltip">
      <div>{{ isPrevious ? $t("player.previousStep") : $t("player.nextStep") }}</div>
      <div class="DesktopStepNameTooltip__tooltip--title text-truncate">{{ stepNumber }}. {{ stepTitle }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesktopStepNameTooltip",
  props: {
    isPrevious: Boolean,
    stepTitle: String,
    stepNumber: String,
    hidden: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.DesktopStepNameTooltip {
  position: relative;
  &__tooltip {
    z-index: 2;
    position: absolute;
    top: -86px;
    left: calc(50% - 140px);
    height: 74px;
    width: 280px;
    padding: 13px 24px;
    border-radius: 4px;
    background: rgba(23, 24, 26, 0.8);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    text-align: left;
    pointer-events: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    opacity: 0;
    transition: 0.3s;
    &--title {
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  &:hover &__tooltip {
    opacity: 1;
    transition: 0.3s;
  }
}
</style>