/**
 * API doc
 * https://docs.google.com/document/d/1oCVXJcqsrNoSVjjIkQchRHeX2S97SbPehbkndomm554/edit#
 */
import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import stepServer from "./step-server";

const attachmentServer = axios.create({
  baseURL:
    environment.backendType === "firebase"
      ? environment.service[environment.env].deephowApiServer
      : environment.service[environment.env]["china"].deephowApiServer,
});

attachmentServer.interceptors.request.use(
  async config => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  error => Promise.resolve(error)
);

export const fetchAttachmentData = async stepId => {
  try {
    const { data } = await stepServer.get(`/v1/steps/${stepId}/addons`);
    return { ...data, ok: data.success };
  } catch (error) {
    console.log(error);
    return { ok: false, error, message: error.message };
  }
};

export const putUpdateAttachmentData = async ({ stepId, addonId, requestBody }) => {
  try {
    if (stepId == null || addonId == null) {
      return {
        ok: false,
        message: `Missing params: ${stepId == null && "stepId: " + stepId + ","} ${addonId == null &&
          "addonId: " + addonId}`,
      };
    }
    const { data } = await stepServer.put(`/v1/steps/${stepId}/addons/${addonId}`, requestBody);
    return { ...data, ok: data.success };
  } catch (error) {
    console.log(error);
    return { ok: false, error, message: error.message };
  }
};

export const postCreateAttachmentData = async ({ stepId, requestBody }) => {
  try {
    const { data } = await stepServer.post(`/v1/steps/${stepId}/addons`, requestBody);
    return { ...data, ok: data.success };
  } catch (error) {
    console.log(error);
    return { ok: false, error, message: error.message };
  }
};
