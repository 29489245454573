module.exports = {
  ad: {
    highlights: "이슈",
    highlightsIntro: "이곳에서 당신은 새로운 게시 기능을 빠르게 파악할 수 있습니다. 바로 이 소개와 같습니다. 아울러 Stephanie도 현재 진행 중인 기능 교육을 당신에게 안내하거나 도와드립니다.",
    recommendations: "추천",
    recommendationsIntro: "Stephanie는 당신의 시청 기록에 근거하여 워크플로우를 추천합니다. 그녀는 당신과 그녀의 모든 소통을 통해 배우게 됩니다!",
    favoritesIntro: "이런 생각 해본 적 있나요? 당신이 가장 자주 사용하는 워크플로우가 늘 그곳에서 대기하고 있다면 얼마나 좋을까? ！ 이제 당신은 특정 위치에서 수시로 당신의 워크플로우를 확인할 수 있습니다!",
    categoriesIntro: "수많은 워크플로우와 늘 부족한 시간.... 단 한 번의 클릭으로 당신이 원하는 유형만 모아 볼 수 있다면 얼마나 좋을까요? 이제 당신도 가능합니다! :)",
    loginMsgTitle: "새 기능이 업그레이드 되었습니다!",
    loginMsg: "이제 워크스페이스를 손쉽게 검색 가능합니다. 또한, 새로운 기능인 내용 추천 기능을 사용해 보십시오. 당신의 DeepHow 계정을 더 잘 이용할 수 있게 도움을 제공해 드립니다.",
  },
  all: {
    success: "완료",
    player: "플레이어",
    editor: "편집기",
    admin: "관리",
    analytics: "통계분석",
    skills: "기능",
    skill: "기능",
    unpublished: "미게시 ",
    published: "게시 완료",
    signOut: "로그아웃",
    step: "단계",
    steps: "단계",
    numStep: "{count}단계",
    transcription: "문자 전환",
    no: "아니요",
    yes: "예",
    delete: "삭제",
    diagram: "이미지",
    all: "전부",
    discard: "버리다",
    save: "저장",
    next: "다음 단계",
    process: "처리",
    cancel: "취소",
    close: "OFF",
    allUsers: "모든 사용자",
    users: "사용자",
    user: "사용자",
    numUsers: "개 사용자",
    numUser: "개 사용자",
    countUsers: "사용자 <b>{count}</b>개 ",
    workspaces: "워크스페이스",
    workspace: "워크스페이스",
    workspaceGroup: "작업 공간 그룹",
    workflows: "워크플로우",
    workflow: "워크플로우",
    organization: "조직기구",
    business: "업무",
    create: "새로 만들기",
    name: "명칭",
    namePerson: "이름",
    email: "이메일",
    employeeId: "직원ID",
    role: "역할",
    organizationAdmin: "조직기구 관리자",
    workspaceAdmin: "워크스페이스 관리자",
    publisher: "발표자",
    viewer: "방문자",
    organizationAdmins: "조직기구 관리자",
    workspaceAdmins: "워크스페이스 관리자",
    publishers: "발표자",
    viewers: "방문자",
    add: "추가",
    attachments: "별첨",
    private: "프라이빗",
    public: "공개",
    content: "내용",
    noRecordFound: "데이터가 없습니다",
    loadingRecords: "데이터 로딩 중...",
    teams: "그룹",
    loadedAllRecords: "모든 데이터 로딩 완료",
    language: "언어",
    interfaceLanguage: "인터페이스 언어",
    privacyPolicy: "개정정보 보호 정책",
    personalInfo: "개인 정보",
    noAccessToAdminTools: "이 사용자는 현재 관리 툴을 이용할 권한이 없습니다",
    team: "그룹",
    unselect: "선택 취소",
    select: "선택",
    applied: "선택 완료",
    changePhoto: "프로필 사진 바꾸기",
    removePhoto: "프로필 사진 제거",
    profilePhoto: "프로필 사진 ",
    takeOrUpload: "프로필 사진 촬영 또는 사진 업로드 ",
    useCamera: "프로필 사진 촬영",
    uploadPhoto: "프로필 사진 업로드",
    editPhoto: "프로필 사진 편집",
    retake: "재촬영",
    takePhoto: "사진 촬영",
    countWorkspaces: "개 워크스페이스",
    whatsNew: "신규 버전 변화",
    howToUse: "사용방법",
    defaultInterfaceLanguage: "인터페이스 기본 언어 ",
    newVersionSnackbarText: "신규 버전의 DeepHow에 접속했습니다.",
    refresh: "새로고침",
    unpublishedList: "미게시",
    publishedList: "게시 완료",
    userAgreement: "사용자 약관",
    numAttachments: "첨부 파일 0개 | 첨부 파일 1개 | 첨부 파일 {count}개",
    attachment: "별첨",
    knowledgeBase: "DeepHow지식 기반",
    changeName: "이름을 바꿔라",
    changePassword: "비밀번호를 변경하십시오",
    openInApp: "Deephow Navigator에서 열립니다",
    userNewToDeepHow: "당신은 Deephow를 처음 사용합니까?",
    downloadTheApp: "앱을 다운로드하십시오",
    continueToUseBrowser: "브라우저를 계속 사용하십시오",
    contentManager: "컨텐츠 관리자",
    gen2Forbidden: "Gen2 Light 사용자로서 지금 당장 로그인 할 수 없습니다.",
    confirm: "확정",
    myWorkspaceGroups: "내 작업 공간 그룹",
    otherWorkspaceGroups: "다른 작업 공간 그룹",
    searchResult: "검색 결과",
    noResultsFound: "검색결과 없음",
    browseWorkspace: "작업 공간 찾아보기...",
    version: "버전",
  },
  password: {
    createYourAccount: "당신의 계정 생성하기",
    checkNameChoosePassword: "당신의 이름을 검사하고 비밀번호 선택",
    createAccount: "계정 새로 만들기",
    createNewPassword: "새 비밀번호 설정",
    selectNewPassword: "당신의 계정을 위해 새 비밀번호를 선택하십시오",
    newPassword: "새 비밀번호",
    confirmNewPassword: "새 비밀번호 확인",
    passwordMustMatch: "새 비밀번호와 확인 비밀번호가 일치하지 않습니다",
    confirm: "확정",
    passwordCreatedMsg: "당신의 새 비밀번호 설정이 완료되었습니다. <br> 페이지로 이동하니 잠시만 대기해 주십시오...",
    goToDeepHowPlayer: "DeepHow 플레이어에 접속하기",
    failedResetPassword: "비밀번호 재설정 실패. 당신의 계정이 초기화되었거나 네트워크에 문제가 있습니다.",
    alertSetPasswordFailed: "비밀번호 설정 실패. 당신의 계정이 초기화되었거나 네트워크에 문제가 있습니다.",
    linkExpired: "링크 기한 만료",
    passwordExpired: "비밀번호 재설정 링크의 기한이 만료되었습니다.",
    inviteExpired: "당신의 초대 링크의 기한이 만료되었습니다",
    resetPasswordMsg: "하단 버튼을 클릭하여 새로 발송한 비밀번호 재설정 이메일에 접속하십시오.",
    // added 09/08/2022
    alertFailedResetPassword: "암호를 재설정하지 못했습니다.",
    alertSuccessfullyResetPassword: "비밀번호를 성공적으로 재설정하고 다시 로그인하도록 리디렉션하는 동안 기다리십시오… <br><br>아래 버튼을 클릭하십시오.",
    newPasswordNotTheSame: "새 비밀번호는 이전 암호와 같지 않아야합니다.",
    alertCurrentPasswordIncorrect: "현재 비밀번호가 잘못되었습니다",
    alertTooManyAttempts: "현재 암호를 검증하려는 시도가 너무 많습니다. 나중에 다시 시도하십시오.",
  },
  analytics: {
    overview: "개요",
    engagement: "참여도",
    activeUsers: "활성화할 사용자",
    workflowsCreated: "생성된 워크플로우",
    workflowsPublished: "게시된 워크플로우",
    workflowViews: "워크플로우 시청 횟수",
    timeCreated: "생성 시간",
    timePublished: "게시 시간",
    timeViewed: "시청 시간",
    searchWorkspace: "워크스페이스 검색 중...",
    mostUsers: "최대 사용자 ",
    mostWorkflowsCreated: "생성된 워크플로우 최대 수량",
    numberOfUsers: "사용자 수량",
    organizationOverview: "조직기구 개요",
    numWorkflowsIn: "개의 워크플로우가",
    searchWorkflow: "워크플로우 검색 중...",
    createdBy: "작가",
    usersIn: "사용자는",
    searchUser: "사용자 검색...",
    watchTime: "시청 시간",
    workflowsViewed: "시청한 워크플로우",
    percentViewed: "시청 백분비 ",
    dateViewed: "시청 시간",
    watchWorkflow: "워크플로우 재생",
    backToUsers: "사용자 리스트로 돌아가기",
    backToWorkflows: "워크플로우 리스트로 돌아가기",
    likes: "좋아요 클릭",
    avgTimeViewed: "평균 시청 시간",
    numberofViews: "시청 횟수",
    workspacesPerPage: "매 페이지의 워크스페이스 수량",
    usersPerPage: "매 페이지의 사용자 수량",
    workflowsPerPage: "매 페이지의 워크플로우 수량",
    exportTable: "도표 출력",
    TopViewers: "방문자 순위",
    TopPublishers: "발표자 순위",
    totalViews: "총 시청 횟수",
    msgNoDataYet: "이 도표에는 데이터가 없습니다",
    msgNoSearchResults: "부합되는 데이터가 없습니다",
    hms: "(시:분:초)",
    viewHistory: "시청 기록",
    views: "시청 횟수",
    totalViewingTime: "총 시청 시간",
    usersViewedThisWorkflow: "개 사용자가 이 워크플로우를 시청했습니다",
    date: "시간",
    // added 2022/07/11
    dashboards: "대시 보드",
    reactions: "반응",
    userReaction: "고객 피드백",
    viewAll: "전체 보기",
    favorites: "즐겨찾기",
  },
  admin: {
    status: "상태",
    active: "활성화",
    inactive: "비활성화",
    invited: "초대 완료",
    invitationExpired: "초대 유효기간 만료",
    couldNotInvited: "초대 불가",
    hintSearchUsers: "사용자 검색...",
    hintSearchWorkspaces: "워크스페이스 검색 중...",
    hintSearchBusiness: "업무 검색...",
    alertSuccessfullyInviteUser: "사용자 초대 완료.",
    alertFailedInviteUser: "사용자 초대 실패.",
    alertSuccessfullyDeletedUser: "사용자 삭제 완료.",
    alertFailedDeleteUser: "사용자 삭제 실패.",
    alertSuccessfullyCreatedWorkspace: "워크스페이스 새로 만들기 완료.",
    alertSuccessfullyEditedWorkspace: "워크스페이스 편집하기 완료.",
    alertSuccessfullyCreatedBusiness: "업무 새로 만들기 완료.",
    alertSuccessfullyEditedBusiness: "업무 편집 완료.",
    alertUserInvitationSuccessfullyResent: "사용자 초대 이메일 발송 완료.",
    alertFailedResendUserInvitation: "사용자 초대 이메일 발송 실패",
    alertUserInvitationSuccessfullyDelete: "사용자 초대 삭제 완료.",
    alertFailedDeleteUserInvitation: "사용자 초대 삭제 실패.",
    resendInvitation: "초대 이메일 재발송",
    popupMsgResendInvitation: "당신은 정말 초대 이메일을 재발송하시겠습니까",
    popupBtnSend: "네, 발송하겠습니다.",
    deleteInvitation: "초대 삭제",
    popupMsgDeleteInvitation: "정말 초대를 삭제하시겠습니까?",
    addUser: "사용자 추가",
    msgNoMembersYet: "이 조직기구에는 추가된 사용자가 없습니다.",
    msgNoRecordsAllUsers: "모든 사용자 중에 부합되는 기록이 없습니다.",
    createWorkspace: "워크스페이스 새로 만들기",
    msgNoWorkspacesYet: "이 조직기구에는 생성된 워크스페이스가 없습니다.",
    msgNoRecordsWorkspaces: "모든 워크스페이스 중에 부합되는 기록이 없습니다",
    msgNoRecordsWorkspacesGroupAdmin: "이 계정에는 워크스페이스가 없습니다",
    createBusiness: "업무 새로 만들기",
    msgNoBusinessYet: "이 조직기구에는 생성된 업무가 없습니다.",
    msgNoRecordsBusiness: "모든 업무 중에 부합되는 기록이 없습니다.",
    usersIn: "사용자는",
    tableMsgNoUsersYet: "이 워크스페이스에 추가된 구성원이 없습니다.",
    tableMsgNoRecordsThisWorkspace: "워크스페이스 중에 부합되는 기록이 없습니다",
    memberAt: "속함",
    alerBusinessAlreadyInSystem: "시스템에 업무가 있습니다.",
    alerFailedAddBusiness: "업무 추가 실패.",
    alertFailedEditBusiness: "업무 편집 실패.",
    alertWorkspaceAlreadyInSystem: "시스템에 워크스페이스가 있습니다.",
    aletFailedCreateWorkspace: "워크스페이스 추가 실패.",
    alertFailedEditWorkspace: "워크스페이스 편집 실패.",
    resendAllInvites: "모든 초대 재발송",
    resendAllInvitesMsg: "초대 미검증 및 초대 기한이 만료된 모든 사용자에게 새 초대 이메일을 발송하시겠습니까?",
    yesSendInvites: "네, 초대를 발송하겠습니다",
    businessesPerPage: "페이지당 비즈니스",
    //add org user
    popupTitleAddOrgUser: "조직기구 관리자 추가하기",
    invite: "초대",
    popupTitleNewUserInvited: "새 사용자 초대 완료!",
    popupMsgNewUserInvitedOrg: "초대 완료. <br>계정 인증 후 그는 이 조직기구에 로그인할 수 있습니다",
    popupTitleExistingUserInvited: "기존 사용자를 추가하였습니다!",
    popupMsgExistingUserInvitedOrg: "이 조직기구에 추가하였습니다.",
    popupBtnAddAnotherUser: "다른 사용자 추가하기",
    alertFailedCheckPendingInvitations: "사용자 초대 상황 찾기에 실패했습니다.",
    alertFailedAddExistingUserAsOrganizationAdmin: "기존 사용자를 조직기구 관리자로 설정하기 실패.",
    alertAddExistingUserAsOrganizationAdmin: "기존 사용자를 조직기구 관리자로 설정하기 완료.",
    alertUserAlreadyOrganizationAdmin: "이 사용자는 조직기구 관리자로 설정되었습니다.",
    alertFailedCheckUserAlreadyInSystem: "사용자가 시스템에 가입했는지 찾지 못했습니다.",
    //Add user to workspace
    addUserToWorkspace: "사용자를 워크스페이스에 추가하기",
    companyEmail: "회사 이메일",
    employeeIdOnly: "직원 ID만 있습니다",
    fullName: "이름",
    workspaceName: "워크스페이스 명칭",
    businessName: "업무 명칭",
    popupMsgNewUserInvited: "초대했습니다. <br>계정 인증 후 이 사용자는 이 워크스페이스에 로그인할 수 있습니다.",
    popupMsgExistingUserInvited: "이 워크스페이스에 추가했습니다",
    popupTitleNewUserCreated: "사용자 새로 만들기 완료! ",
    popupTitleUserAlreadyinSystem: "시스템에 사용자가 있습니다",
    alertUserIsAlreadyInWorkspace: "이 사용자({info})는 이 워크스페이스에 있습니다.",
    alertPendingInvitation: "이 사용자는 초대 이메일을 인증하지 않았습니다. 사용자가 초대를 수락한 후 다시 이 작업을 수행하십시오.",
    searchingforUsers: "사용자 찾기 중...",
    noResultsFound: "검색결과 없음",
    // Change User Role
    popupTitleChangeUserRole: "사용자 역할 변경",
    popupMsgChangeUser: "정말 <b>{name}</b>의 역할을<b>{oldRole}</b>에서<b>{newRole}</b>로 변경하시겠습니까?",
    popupBtnChange: "네, 저장하겠습니다",
    alertUpdatedUserSettings: "사용자 설정을 업데이트했습니다.",
    // Remove User
    popupTitleRemoveUser: "작업 공간 밖으로 이동",
    popupMsgRemoveUserFromWorkspace: "당신은 이 워크스페이스에서 <b>{name}</b>를 제거하시겠습니까?",
    popupBtnRemove: "네, 제거하겠습니다",
    alertRemoveUser: "사용자를 워크스페이스에서 제거하였습니다",
    // Delete User
    popupTitleDeleteUser: "시스템에서 사용자 제거",
    popupMsgDeleteUserFromAllWorkspaces: "이렇게 할 경우 당신은 이 사용자 <b>{name}</b>의 모든 데이터를 분실하게 됩니다. 그는 모든 워크스페이스에서 제거되며 설정된 역할이 취소됩니다.",
    popupBtnDelete: "네, 삭제하겠습니다",
    tooltipEditUser: "사용자 편집",
    tooltipResendInvite: "초대 재발송",
    tooltipCancelInvite: "초대 삭제",
    tooltipRemoveUser: "사용자 제거",
    // settings
    settings: "설정",
    location: "주소",
    hireDate: "입사 시간",
    department: "부서",
    position: "직위",
    site: "근무 지점",
    userId: "사용자 ID",
    ssoMapping: "사용자 맵핑",
    ssoMappingNoData: "맵핑에 사용할 데이터가 없습니다<br /> 당신은 FTP를 통해 업로드해야 합니다. 기술지원 부서에 연락하십시오.",
    attributes: "속성",
    oneAttributeToMap: "당신은 한 가지 속성만 선택해 사용자를 워크스페이스에 맵핑할 수 있습니다",
    selectAttribute: "속성 선택",
    changeAttribute: "속성 변경",
    discardChanges: "변경 철회",
    applyChanges: "변경 적용",
    applyChangesMsg: "당신은 다음과 같이 워크스페이스의 변경을 적용하고자 합니다. <br>계속하시겠습니까?",
    mappingValuesUpdated: "맵핑 속성값이 변경되었습니다",
    mappingValuesUpdatedSubtitle: "맵핑을 업데이트 하려면 변경 적용을 클릭해야 합니다. 당신은 모든 워크스페이스에서 모든 조정을 마친 뒤 이 단계를 실행할 수 있습니다.",
    values: "속성값",
    clickPlusToAddValues: "더하기 기호를 클릭하여 속성값을 추가하기",
    selectValues: "속성값 선택",
    searchForValues: "속성값 검색...",
    assign: "설정",
    assignValues: "속성값 설정",
    addUsersBtn: "네, 사용자를 추가하겠습니다",
    YesChangeBtn: "네, 변경하겠습니다",
    numMore: "이외에 {count}개",
    removeValues: "속성값 제거",
    remove: "제거",
    changeAttributeMessage: "정말 속성을 변경하시겠습니까? 당신은 이 속성에 설정되었던 모든 맵핑을 분실하게 됩니다.",
    discardChangesMessage: "당신은 지난 번에 저장한 모든 변경 내용을 분실하게 됩니다. <br>그래도 계속하시겠습니까?",
    discardChangesBtn: "네, 변경을 철회하겠습니다",
    applyChangesBtn: "네, 변경을 적용하겠습니다",
    alertDiscardedSuccess: "맵핑 변경 철회 완료",
    alertAppliedSuccess: "맵핑 변경 적용 완료",
    alertAppliedFailed: "맵핑 변경 적용 실패",
    //permissions
    permissions: "사용자 권한",
    products: "DeepHow 제품",
    roleAccessToProduct: "이 정의에서 모든 사용자 역할을 방문할 수 있는 제품",
    analytics: "통계분석",
    skillsManager: "기능 관리",
    skillsPlayer: "기능 플레이어",
    workflowCapture: "워크플로우 촬영",
    workflowEditor: "워크플로우 편집기",
    organizationAdmin: "조직기구 관리자",
    groupAdmin: "워크스페이스 관리자",
    publisher: "발표자",
    viewer: "방문자",
    rolesPermission: "역할 권한",
    rolesPermissionMessage: "이 제품의 사용자 권한 설정을 정말 변경하시겠습니까?  변경하면 즉시 적용됩니다.",
    inviteUser: "사용자 초대하기",
    workflowSharing: "워크플로우 공유",
    allowUserstoSharePublicLinks: "모든 사용자가 공공링크를 공유하도록 허용 ",
    changeWorkflowSharingExplanation: "공공링크 공유 옵션을 닫을 경우 사용자는 플랫폼에 로그인하여 모든 공공 워크플로우를 방문해야 합니다.",
    allowWorkflowSharingMessage: "정말 사용자 공공링크 공유를 허용하시겠습니까?",
    preventWorkflowSharingMessage: "정말 사용자 공공링크 공유를 차단하시겠습니까? 변경하면 즉시 적용됩니다.",
    workflowDownload: "워크플로우 다운로드",
    allowUsersToDownloadWorkflows: "모든 사용자가 워크플로우를 다운로드하도록 허용",
    allowDownloadWorkflowsMessage: "정말 사용자가 워크플로우를 다운로드하는 것을 허용하시겠습니까?",
    preventDownloadWorkflowsMessage: "정말 사용자가 워크플로우를 다운로드하는 것을 차단하시겠습니까?",
    autoLogout: "자동 로그아웃",
    autoLogoutAfterSelectedTime: "사용자는 선택한 시간이 지난 후 자동으로 시스템에서 로그아웃 됩니다.",
    numMinutes: "{count}분",
    changeLogoutTimeMessage: "자동 로그아웃 시간을 {num}분으로 수정하시겠습니까?",
    enableAutoLogoutMessage: "자동 로그아웃을 정말 열겠습니까?",
    disableAutoLogoutMessage: "자동 로그아웃을 정말 닫겠습니까?",
    changesMadeImmediately: "변경하면 즉시 적용됩니다.",
    sessionIsAboutToExpire: "당신은 한동안 활동하지 않았습니다.",
    youWillBeLoggedOutNumSeconds: "당신은 {count}초 뒤에 자동으로 로그아웃 됩니다.",
    continueSession: "로그인 유지",
    //system languages
    languages: "언어",
    systemLanguage: "시스템 언어",
    searchForWorkspaces: "워크스페이스 검색...",
    workspacesPerPage: "매 페이지의 워크스페이스 수량",
    workspace: "워크스페이스",
    workspaceLanguage: "언어",
    changeLanguage: "언어 교체",
    popupMsgChangeLanguage: "언어를 교체하면 워크스페이스 내의 모든 사용자에게 영향을 미치게 됩니다. 정말 교체하시겠습니까? ",
    alertLanguageSuccessfullyChanged: "언어 교체 완료",
    alertFailedChangeLanguage: "언어 교체 실패",
    // Categories
    categories: "분류",
    alertCategoriesNotSaved: "카테고리가 저장되지 않고 나중에 다시 시도하십시오.",
    noCategoryResult: "카테고리 검색 결과가 없습니다",
    noCategoriesAddedYet: "아직 추가 된 카테고리가 없습니다",
    categoriesAssignment: "카테고리 할당",
    categoryPlaceholder: "검색 범주 및 하위 범주 ...",
    previouslyUsed: "이전에 사용되었습니다",
    allCategories: "모든 카테고리",
    workflowCategories: "워크플로우 분류",
    searchForCategories: "분류 검색...",
    categoryItems: "항목명",
    categoryItemsNum: "항목 수량",
    createCategory: "분류 새로 만들기",
    editCategory: "분류 편집하기",
    categoryName: "분류명",
    noCategoriesCreatedYet: "생성된 분류가 없습니다",
    noItemsCreatedYet: "생성된 항목이 없습니다",
    categoriesPerPage: "매 페이지의 분류 수량",
    itemsPerPage: "매 페이지의 항목 수량",
    alertCategoryAlreadyInSystem: "시스템에 분류가 있습니다.",
    alerFailedAddCategory: "분류 생성 실패.",
    alertFailedEditCategory: "분류 편집하기 실패",
    deleteCategory: "분류 삭제",
    popupMsgDeleteCategory: "분류를 정말 삭제하시겠습니까?",
    itemsIn: "항목은",
    searchForItems: "항목 검색...",
    createItem: "항목 생성",
    editItem: "항목 편집하기",
    deleteItem: "항목 삭제",
    itemName: "항목명",
    alertItemAlreadyInSystem: "시스템에 항목이 있습니다.",
    alertFailedAddItem: "항목 새로 만들기 실패.",
    alertSuccessAddItem: "항목 새로 만들기 완료",
    alertFailedEditItem: "항목 편집하기 실패",
    alertSuccessEditItem: "항목 편집하기 완료",
    //Teams
    teamsPerPage: "매 페이지의 그룹 수",
    max1000UsersPerTeam: "* 매 그룹의 최대 사용자수 1000명",
    usersSelected: "선택한 사용자 {count}개 ",
    selectAll: "전체 선택",
    removeAll: "전체 선택 취소",
    createTeam: "그룹 새로 만들기",
    editTeam: "그룹 편집하기",
    teamName: "그룹명",
    teamsIn: "그룹은",
    searchForTeams: "그룹 검색...",
    searchForUsers: "사용자 검색...",
    noUsersAddedYet: "그룹에 추가된 사용자가 없습니다.",
    dateAdded: "추가된 날짜",
    alertTeamAlreadyInSystem: "이 그룹은 시스템에 있습니다.",
    alertFailedEditTeam: "그룹 편집하기 실패",
    deleteTeam: "그룹 삭제",
    popupMsgDeleteTeam: "그룹 <b>{name}</b>를 삭제하시겠습니까? 정말 삭제하시겠습니까?",
    noTeamsCreatedYet: "생성된 그룹이 없습니다",
    popupMsgDeleteItem: "항목을 정말 삭제하시겠습니까?",
    removeUser: "사용자 제거",
    popupMsgRemoveUser: "그룹에서 사용자 <b>{name}</b>를 제거하시겠습니까? 정말 제거하시겠습니까?",
    alertErrorGettingWorkspaceList: "워크스페이스 리스트 가져오기에 실패했습니다.",
    alertSuccessfullyCreatedTeam: "그룹 새로 만들기 완료.",
    alertFailedCreatedTeam: "그룹 새로 만들기 실패.",
    alertSuccessfullyUpdatedTeam: "그룹 편집하기 완료.",
    alertSuccessfullyRemovedUserFromTeam: "그룹에서 사용자를 제거했습니다.",
    alertFailedRemovedUserFromTeam: "그룹에서 사용자를 제거하기에 실패했습니다.",
    alertSuccessfullyAddedUserToTeam: "그룹에 사용자를 추가했습니다.",
    alertFailedAddedUserToTeam: "그룹에 사용자를 추가하기에 실패했습니다.",
    alertWarningAddedUserToTeamAlready: "사용자는 이미 팀에 추가되었습니다.",
    // added 2022/02/03
    alertAllInvitationResentSuccess: "모든 사용자 초대 이메일 재발송 완료",
    alertAllInvitationResentFailed: "사용자 초대 이메일 재발송 실패",
    alertCheckUserInSystemFailed: "사용자의 시스템 등록 여부 확인에 실패했습니다.",
    alertOrganizationAdminAssignedFailed: "조직기구 관리자를 단일 워크스페이스에 추가할 수 없습니다.",
    // added 2022/02/07
    alertFailedDeleteItem: "항목 삭제 실패.",
    alertSuccessfullyDeleteItem: "항목 삭제 완료.",
    alertCategoryDeleteFailed: "분류 삭제 실패.",
    alertSuccessfullyDeletedCategory: "분류 삭제 완료.",
    // added 2022/02/08
    upgradeToOrgAdmin: "조직기구 관리자로 업그레이드.",
    yesUpgrade: "네. 업그레이드하겠습니다.",
    popupMsgUpgradeToOrgAdmin: "정말 <b>{name}</b>를 업그레이드 하시겠습니까? 조직기구 관리자는 당신이 조직한 설정을 배치할 수 있는 완전한 권한을 가집니다.",
    alertFailedUpgradeToOrg: "조직기구 관리자로 업그레이드에 실패.",
    alertSuccessfullyUpgradeToOrg: "조직기구 관리자로 업그레이드 완료.",
    // TODO added 2022/02/17
    userActive: "활성화할 사용자는?",
    profile: "파일",
    // TODO: added 2022/02/09 settings > Master Password
    masterPassword: "메인 비밀번호",
    enableMasterPassword: "메인 비밀번호 사용하기",
    disableMasterPassword: "메인 비밀번호 취소",
    resetMasterPassword: "메인 비밀번호 재설정",
    employeeIDAccess: "직원 ID 로그인",
    employeeIDAccessMsg: "이 기능을 사용하면 직원 ID로 로그인을 한 사용자는 다음의 비밀번호를 입력해야만 DeepHow에 로그인 가능",
    disableMasterPasswordMsg: "이 기능을 닫으면 직원 ID로 로그인을 한 사용자는 비밀번호를 입력하지 않고 DeepHow에 로그인 가능",
    resetMasterPasswordMsg: "직원 ID로 로그인을 한 사용자는 새 비밀번호를 입력해야만 DeepHow에 로그인 가능",
    insertPassword: "비밀번호를 입력해 주십시오",
    alertEnablePasswordFailed: "메인 비밀번호 사용하기 실패",
    alertEnablePasswordSuccess: "메인 비밀번호 사용하기 완료",
    alertDisablePasswordFailed: "메인 비밀번호 취소하기 실패",
    alertDisablePasswordSuccess: "메인 비밀번호 취소하기 완료",
    savePasswordMsg: "하단에 입력한 비밀번호가 저장되어야만 메인 비밀번호를 사용할 수 있습니다.",
    resetPasswordMsg: "새 메인 비밀번호를 입력하고 저장하기",
    currentPassword: "현재 비밀번호",
    yesEnable: "네, 사용하겠습니다.",
    yesDisable: "네, 취소하겠습니다.",
    downgradeOrgAdmin: "조직기구 관리자 다운그레이드",
    popupMsgDowngradeOrgAdmin: "정말 <b>{name}</b>를 다운그레이드 하시겠습니까? 해당 사용자는 소속되어 있는 모든 워크스페이스에서 당신이 선택한 역할로 다운그레이드 됩니다.",
    selectRole: "해당 사용자의 역할 선택하기",
    yesDowngrade: "네. 다운그레이드하겠습니다",
    alertFailedDowngradeToOrg: "{userRole}로 다운그레이드 실패",
    alertSuccessfullyDowngradeToOrg: "{userRole}로 다운그레이드 완료",
    lastActivity: "마지막 로그인",
    coverColor: "커버 색상",
    defaultLanguage: "기본 언어",
    blue: "블루",
    red: "레드",
    green: "그린",
    yellow: "옐로우",
    purple: "퍼플",
    belongsToWorkspaces: "워크스페이스에 속함",
    alertFailedSaveChanges: "저장 및 수정에 실패했습니다.",
    // TODO: added 04/02
    alertFailedRemoveUser: "사용자를 워크스페이스에서 제거하기에 실패했습니다.",
    // TODO: added 0608
    privateOrnot: "사적인?",
    privateWorkspaceInfo: "이 작업 공간은 할당 된 회원 만 볼 수 있습니다…",
    noPermission: "당신은 이것을 볼 수 없습니다.",
    noPermissionContact: "액세스하려는 페이지는 액세스가 제한되어 있습니다. <br> 이것이 실수라고 생각되면 관리자에게 문의하십시오.",
    privateCannotChanged: "작업 공간이 작성된 후에는 개인 설정을 변경할 수 없습니다.",
    // Shared Account
    sharedAccount: "공유계정",
    addManually: "수작업 추가",
    addASharedAccount: "공유계정 추가",
    addASharedAccountMsg: "공유계정은 개인의 워크플로우 시청에 사용될 수 없으며 직원 ID 사용자가 DeepHow에 본인의 새 계정을 생성하는데 사용되거나 공유설비의 직원 ID 사용자를 추적하는데 사용될 수 있습니다...",
    accountName: "계정명",
    alertFailedAddShared: "공유계정 추가 실패",
    alertSuccessfullyCreatedShared: "공유계정 새로 만들기 완료",
    alertSuccessfullyAddExistingShared: "이미 생성된 공유계정 추가 완료",
    accountId: "계정ID",
    addSharedAccount: "공유계정 추가",
    sharedAccountId: "공유계정 ID",
    unselectedTeam: "그룹 선택 취소",
    // TODO: added 2022/06/22 //*** The following translation is not confirmed ***/
    workflowReview: "작업 프로세스 검토",
    workflowReviewDescription: "이 옵션이 활성화되면 작업 공간 관리자는 워크 플로에서 작업 공간을 검토 해야하는지 여부를 선택할 수 있습니다.",
    preventReviewWorkflowsMessage: "워크 플로 리뷰를 닫으시겠습니까?",
    allowReviewWorkflowsMessage: "사용자가 워크 플로를 볼 수 있도록 하시겠습니까?",
    // TODO: added 2022/06/29
    addAsReviewer: "리뷰어로 추가하십시오",
    removeAsReviewer: "리뷰어를 삭제하십시오",
    addAsReviewerSuccessMessage: "리뷰어로 {user}를 성공적으로 추가하십시오.",
    addAsReviewerFailedMessage: "리뷰어로 {user}를 추가하지 못합니다.",
    removeAsReviewerSuccessMessage: "리뷰어로 {user}를 성공적으로 삭제합니다.",
    removeAsReviewerFailedMessage: "{user}는 검토 자로 제거 할 수 없습니다.",
    // Shared Account Admin settings 07/27
    allowsSharedAccountMsg: "조직 및 작업 공간 관리자는 공유 계정을 만들 수 있습니다. 사용자는 공유 장치에 직원 ID 계정을 등록 할 수 있습니다.",
    disableSharedAccountMsg: "계정 공유 기능을 닫을 수 있습니까? <br> 수정은 즉시 적용됩니다.",
    enableSharedAccountMsg: "계정 공유 기능을 열고 싶습니까? <br> 수정은 즉시 적용됩니다.",
    alertEnableSharedAccountFailed: "공유 계정을 여는 기능이 실패했습니다.",
    alertEnableSharedAccountSuccess: "공유 계정을 여는 기능이 성공적입니다.",
    alertDisableSharedAccountFailed: "공유 계정 기능 실패를 닫습니다.",
    alertDisableSharedAccountSuccess: "공유 계정 기능을 성공적으로 닫으십시오.",
    // QR Code & Watermark
    qrCodeAndWatermark: "QR 코드 및 워터 마크",
    insertYourBrand: "브랜드를 삽입하십시오",
    uploadYourLogo: "로고를 업로드하십시오",
    uploadingYourLogo: "로고를 업로드합니다",
    logoSuccessfullyUploaded: "로고가 성공적으로 업로드되었습니다",
    errorInUpload: "업로드의 오류",
    errorInUploadMsg: "파일 유형은 지원되지 않습니다. 다음 파일 유형을 선택하십시오. .png",
    uploadLogoMsg: "최대 <b>3 MB</b> 파일로 <b>.PNG</b> 를 드래그 앤 드롭 또는 <span>컴퓨터에서 찾아보십시오</span>.",
    uploadLogoReplaceMsg: "컴퓨터에서 이미지로 교체하도록 드래그하거나 <span>찾아</span> 보거나 현재 이미지를 <span id='DUploadImage_removeLogo'>제거하십시오</span>.",
    simulation: "시뮬레이션",
    simulationMsg: "아래의 로고가 비디오 워터 마크 및 QR 코드에서 어떻게 제시되는지 아래를 참조하십시오.",
    workflowTitle: "워크 플로 제목",
    logoAlreadyUploaded: "이미 로고가 업로드되었습니다",
    alertLogoSuccessfullyRemoved: "로고가 성공적으로 제거되었습니다.",
    alertLogoFailedRemoved: "로고를 제거하지 못했습니다.",
    alertUserInOtherOrg: "이 사용자는 이미 다른 조직에 존재합니다.",
    // added 10/13 delete workspace
    deleteWorkspace: "작업 공간을 삭제합니다",
    popupMsgDeleteWorkspace: "이 작업 공간 ({name})을 삭제 하시겠습니까?",
    alertDeleteGroupSuccess: "이 작업 공간을 성공적으로 삭제하십시오.",
    alertDeleteGroupFailedWorkflows: "이 작업 공간에 워크 플로가 할당되어 있으므로 삭제할 수 없습니다.",
    alertDeleteGroupFailedSkills: "이 작업 공간에 기술이 할당 된 기술이 있으므로 삭제할 수 없습니다.",
    alertDeleteGroupFailedTeams: "이 작업 공간에 할당 된 팀이 있으므로 삭제할 수 없습니다.",
    // added 11/3 delete team
    alertDeleteTeamSuccess: "이 팀을 성공적으로 삭제했습니다.",
    alertDeleteTeamFailedSkills: "이 팀은 기존 기술이 지정되어 있기 때문에 제거 할 수 없습니다.",
    alertDeleteTeamFailed: "이 팀을 삭제하지 못했습니다.",
    // Change Profile Name
    changeProfileName: "프로필 이름을 변경합니다",
    allowsChangeProfileNameMsg: "사용자는 프로필에서 이름을 변경할 수 있습니다.",
    disableChangeProfileNameMsg: "변경 프로필 이름을 비활성화 하시겠습니까? <br> 변경이 즉시 이루어집니다.",
    enableChangeProfileNameMsg: "변경 프로필 이름을 활성화 하시겠습니까? <br> 변경이 즉시 이루어집니다.",
    alertEnableChangeProfileNameSuccess: "변경 프로필 이름을 성공적으로 활성화했습니다.",
    alertEnableChangeProfileNameFailed: "변경 프로필 이름을 활성화하지 못했습니다.",
    alertDisableChangeProfileNameSuccess: "변경 프로필 이름을 성공적으로 비활성화했습니다.",
    alertDisableChangeProfileNameFailed: "변경 프로필 이름을 비활성화하지 못했습니다.",
    // Workflow Comments
    workflowComments: "워크 플로우 댓글",
    allowsWorkflowCommentsMsg: "사용자는 게시 된 워크 플로에 의견을 남길 수 있습니다.",
    disableWorkflowCommentsMsg: "워크 플로우 댓글을 비활성화 하시겠습니까? <br> 변경이 즉시 이루어집니다.",
    enableWorkflowCommentsMsg: "워크 플로우 댓글을 활성화 하시겠습니까? <br> 변경이 즉시 이루어집니다.",
    alertEnableWorkflowCommentsSuccess: "워크 플로우 댓글을 성공적으로 활성화했습니다.",
    alertEnableWorkflowCommentsFailed: "워크 플로우 댓글을 활성화하지 못했습니다.",
    alertDisableWorkflowCommentsSuccess: "성공적으로 비활성화 된 워크 플로우 댓글.",
    alertDisableWorkflowCommentsFailed: "워크 플로우 주석을 비활성화하지 못했습니다.",
    // Workspace Groups
    workspaceGroupsNum: "작업 공간 그룹 | 작업 공간 그룹 | 작업 공간 그룹",
    searchWorkspaceGroups: "작업 공간 그룹 검색 ...",
    noWorkspaceGroupsYet: "아직 작업 공간 그룹이 생성되지 않았습니다.",
    createWorkspaceGroup: "작업 공간 그룹을 만듭니다",
    editWorkspaceGroup: "작업 공간 그룹 편집",
    workspaceGroupName: "작업 공간 그룹 이름",
    editWorkspace: "작업 공간 편집",
    userNum: "사용자 | 사용자 | 사용자",
    teamNum: "그룹 | 그룹 | 그룹",
    defaultLanguage: "기본 언어",
    deleteWorkspaceGroup: "작업 공간 그룹을 삭제합니다",
    alertCreateWorkspaceGroupSuccess: "작업 공간 그룹을 성공적으로 만들었습니다.",
    alertWorkspaceGroupNameAlreadyExists: "이 작업 공간 그룹 이름은 이미 시스템에 있습니다.",
    alertEditWorkspaceGroupSuccess: "작업 공간 그룹을 성공적으로 편집했습니다.",
    alertEditWorkspaceGroupFailed: "작업 공간 그룹을 편집하지 못했습니다.",
    alertDeleteWorkspaceGroupSuccess: "이 작업 공간 그룹을 성공적으로 삭제했습니다.",
    alertDeleteWorkspaceGroupFailed: "이 작업 공간 그룹을 삭제하지 못했습니다.",
    alertWorkspaceAssignedDeleteGroupFailed: "이 그룹에 작업 공간이 할당되어 있으므로 삭제할 수 없습니다.",
    deleteWorkspaceGroupMessage: "이 작업 공간 그룹 (<b>{name}</b>)을 삭제 하시겠습니까?",
    alertWorkspaceNameAlreadyExists: "작업 공간 이름이 이미 존재합니다.",
  },
  builder: {
    publish: "게시",
    reviewTranscription: "전환 검토",
    viewer: "브라우저",
    links: "링크",
    trim: "편집",
    translation: "번역",
    share: "공유",
    addDiagramMsg: "클릭하거나 드래그하여 이미지 추가하기",
    onlyImageAccepted: "이미지 형식 파일만 지원",
    unsupportedImageFormats: "지원 불가 이미지 형식: svg, tiff, xbm",
    addDiagram: "이미지 추가",
    start: "시작",
    end: "종료",
    resetToOriginal: "초기상태로 복구",
    trimStartMsg: "동영상의 시작 위치와 끝나는 위치를 선택하십시오.",
    trimProcessingMsg: "워크플로우를 처리하는 중에 편집 기능을 사용할 수 없습니다. 잠시 후 시도하십시오",
    cannotTrimLessThan3s: "3초 미만의 동영상은 편집할 수 없습니다",
    trimVideo: "동영상 편집",
    trimStep: "편집 단계",
    editWorkflow: "워크플로우 편집",
    popupBtnEdit: "네, 편집하겠습니다",
    popupBtnPublish: "네, 게시하겠습니다",
    popupBtnUnpublish: "네, 게시를 취소하겠습니다",
    popupMsgTrimConfirmation: "정말 편집하시겠습니까?",
    popupMsgTrimWithSaveConfirmation: "당신의 워크플로우 중에 저장되지 않은 내용이 있습니다. 우리는 당신을 위해 이 내용을 저장합니다.",
    popupBtnTrim: "네, 편집하겠습니다",
    popupMsgTranscoding: "인코딩 중",
    popupMsgTrimBackLater: "인코딩 완료 후 당신은 계속해서 워크플로우를 편집할 수 있습니다",
    popupMsgTrimSuccess: "당신의 단계 세그먼트 편집이 완료되었습니다!",
    done: "완성",
    publishWorkflow: "워크플로우 게시",
    popupMsgEditWorkflow: "편집 모드에 진입하면 당신의 워크플로우는 자동으로 게시 취소가 됩니다. 그래도 계속하시겠습니까? ",
    popupMsgUnpublishWorkflow: "당신의 워크플로우 게시가 취소됩니다. 정말 그렇게 하시겠습니까?",
    alertSaveFailed: "워크플로우 저장 실패",
    alertTrimResetSuccess: "단계 복구 완료.",
    alertTrimResetFailed: "단계 복구 실패.",
    alertTrimCanNotReset: "이 단계를 복구할 수 없습니다.",
    resetStep: "단계 복구",
    popupMsgResetStep: "정말 복구하시겠습니까? 이 단계에 추가된 이미지가 분실될 수 있습니다.",
    popupBtnReset: "네, 복구하겠습니다",
    hintSearchBuilder: "워크플로우 및 키워드 검색...",
    stepsSelected: "선택",
    clear: "제거",
    insert: "삽입",
    reset: "초기화",
    resetWorkflow: "워크 플로를 재설정합니다",
    resetWorkflowAlert: "이 웜에서 수행 한 모든 작업을 잃어 버리고 처음부터 다시 시작할 것입니다. 계속하고 싶습니까?",
    resetWorkflowKeepCopy: "이 워크 플로의 사본을 보관하십시오",
    // Transcript / Translation
    closeTranscript: "심사 전환 닫기",
    popupMsgCloseTranscript: "당신의 자막에 저장되지 않은 수정 사항이 있습니다. 계속하실 경우 <b>모든 수정</b>이 취소될 수 있습니다. 계속하시겠습니까?",
    stepNoTranscript: "이 단계에는 자막이 없습니다",
    originalTranscript: "초기 자막",
    addLanguageTooltip: "클릭하여 당신의 동영상에 더 많은 언어 자막을 추가하십시오",
    closeTranslation: "번역 닫기",
    popupMsgCloseTranslation: "당신의 번역 내용이 저장되지 않았습니다 계속하실 경우 <b>모든 수정</b>이 취소될 수 있습니다. 계속하시겠습니까?",
    switchTranslation: "번역 교체",
    popupBtnNoSwitch: "아니요, 교체하겠습니다",
    popupBtnYesSwitch: "네, 저장 후 교체하겠습니다",
    createAudio: "음성 생성하기",
    addNewLanguageTooltip: "이곳을 클릭하여 동영상에 새 언어를 추가하십시오",
    popupMsgCreateAudio: "당신은 정말 워크플로우에 번역 음성을 생성하시겠습니까? Stephanie가 모든 번역을 생성해주기 때문에1회만 생성할 것을 권장합니다.",
    popupBtnYesCreate: "네, 생성하겠습니다",
    alertAudioBeingCreated: "번역 음성 생성 중",
    alertTranscriptionSaved: "자막 저장 완료",
    alertTranscriptionSaveFailed: "자막 저장 실패",
    alertTranslationsSaved: "번역 저장 완료",
    alertTranslationsSaveFailed: "번역 저장 실패",
    alertAudioCreated: "번역 음성 생성 완료",
    alertAudioFailed: "번역 음성 생성 실패",
    alertNoAudioGenerated: "번역이 업데이트되지 않아 생성된 음성이 없습니다. ",
    alertErrorCreateTranscription: "생성된 문자 전환에 오류가 발생했습니다",
    //Share workflow
    shareWorkflow: "워크플로우 공유",
    fullWorkflow: "모든 워크플로우 ",
    specificSteps: "지정 단계",
    chooseShareType: "공유방식 선택",
    qrCode: "큐알코드",
    link: "링크",
    embedLink: "인라인 링크",
    privateWorkflow: "프라이빗 워크플로우 ",
    publicWorkflow: "워크플로우 공개",
    close: "OFF",
    saveQRCode: "큐알코드 저장하기",
    download: "다운로드",
    copyLink: "링크 복사",
    copied: "복사 완료",
    downloading: "다운로드 중",
    videoPrivacy: "동영상 개인정보 보호",
    popupMsgVideoPrivacy: "당신이 워크플로우를 공개하면 DeepHow 계정이 없이도 누구나 방문할 수 있게 됩니다. 계속하시겠습니까?",
    includeSubtitles: "자막 있음",
    noSubtitles: "자막 없음",
    selectLanguage: "자막 언어 선택",
    workflowReady: "워크플로우 다운로드 준비 완료!",
    prepareToDownload: "다운로드 파일 생성하기",
    processingDownloadVideoMsg: "이 페이지를 닫지 마십시오!<br />분 내에 다운로드 파일을 생성할 수 있습니다.",
    processingWorkflow: "워크플로우 생성 중...",
    openAnotherTabMsg: "대기 과정에 당신은 새 탭을 열어 DeepHow를 조회할 수 있습니다",
    openNewTab: "새 탭 열기",
    alertErrorPreparingDownload: "다운로드 파일 생성에 에러가 발생했습니다. 다시 시도하거나 DeepHow에 연락해 협조를 받으십시오.",
    hide: "감추기",
    addNewLink: "새 링크 추가하기",
    clickToAddLinks: "클릭하여 이 단계에 링크를 추가하기",
    addALink: "링크 1개 추가하기",
    editLink: "링크 편집",
    addLinkAddress: "이 단계에 추가할 링크를 입력하십시오",
    addLinkLable: "탭을 하나 추가하시겠습니까?",
    clickOpenOfficialDocument: "예시: 클릭하여 공식홈페이지 파일 조회하기",
    //settings /categories
    settings: "설정",
    categories: "분류",
    noCategories: "생성된 분류가 없습니다",
    selectCategoriesMessage: "적오도 한 개의 분류를 선택하여 사용자가 더 쉽게 전반 내용을 필처하게 합니다",
    closeSettings: "설정 취소",
    popupMsgCloseSettings: "저장되지 않은 수정 사항이 있습니다. 계속하실 경우 <b>모든 수정</b>이 취소될 수 있습니다. 계속하시겠습니까?",
    workflowCategories: "워크플로우 분류",
    popupBtnAssignCategoriesAndPublish: "분류 설정 및 게시",
    popupBtnSkipAndPublish: "이 단계를 무시하고 계속해서 게시하기",
    popupMsgworkflowCategories: "게시하기 전에 워크플로우에 일부 분류를 설정하시면 사용자가 더 쉽게 찾을 수 있습니다.",
    alertCategoriesSaved: "분류 저장 완료.",
    alertCategoriesSaveFailed: "분류 저장 실패.",
    searchForCategoryName: "{name}에서 검색...",
    loadingCategories: "분류 로딩 중...",
    alertFailedDownloadVideo: "동영상 다운로드 실패",
    // Workflow Cover
    workflowCover: "워크플로우 표지",
    selectFromTheVideo: "동영상에서 선택",
    browseForAFile: "로컬에서 선택",
    dropFileMessage: "이미지를 드래그하여 추가하거나 클릭하여 로컬에서 선택하기",
    selectedImage: "선택한 이미지",
    noImageSelected: "이미지 미선택",
    alertSuccessfullyUpdatedCover: "워크플로우 표지 업데이트 완료.",
    alertFailedUpdatedCover: "워크플로우 표지 업데이트 실패.",
    selectAnotherImage: "다른 이미지 선택",
    alertErrorStepLostTranscription: "아직 번역하지 않은 단계가 남아 있습니다. 번역을 완료한 이후에 다운로드 하십시오.",
    annotation: "주석",
    allNotes: "모든 메모",
    noNotesAddedYet: "아직 메모가 추가되지 않았습니다.",
    popupMsgEnterTrim: "당신의 단계에는 주석이 있습니다. 비디오를 다듬려면 이러한 주석이 사라집니다.",
    popupMsgEnterErase: "당신의 단계에는 주석이 있습니다. 비디오를 지우고 싶다면 이러한 주석이 사라질 것입니다.",
    popupMsgEnterAnnotation: "주석을 추가하기 전에 비디오를 다듬거나 지우십시오.",
    workingOn: "처리 중",
    eraseStep: "단계 삭제",
    popupMsgEraseSuccess: "당신의 단계 세그먼트 삭제가 완료되었습니다!",
    popupBtnYesErase: "네, 삭제하겠습니다",
    popupMsgEraseConfirmation: "정말 삭제하시겠습니까?",
    eraseProcessingMsg: "워크플로우를 처리하는 중에 삭제 기능을 사용할 수 없습니다. 잠시 후 시도하십시오",
    translateOnlyTitlesBtn: "번역 제목 및 단계 명칭",
    untitled: "제목 없음",
    hintNoEmptyTitle: "제목이 비어있습니다",
    popupMsgSwitchTranslation: "<b>{lang}</b> 번역 중에 저장하지 않은 수정 사항이 있습니다. 번역을 전환하기 전에 수정 사항을 저장하시겠습니까?",
    alertNoEmptyContent: "제목과 문장이 비어있습니다. 입력하신 뒤에 저장하십시오.",
    PDF: "PDF",
    PDFSizeLimit: "PDF 파일의 최대 크기 50 MB",
    addPDF: "PDF 추가하기",
    onlyPDFAccepted: "PDF 파일형식만 지원",
    preview: "시사",
    // Send to reviewer
    sendToReviewer: "리뷰어에게 보내십시오",
    alertTitleSelectReviewers: "워크 플로를 검토하려면 사용자를 선택하십시오.",
    alertSendToReviewSuccess: "워크 플로우가 검토를 위해 성공적으로 발송되었습니다.",
    alertSendToReviewFailed: "검토를 보내지 못했습니다",
    subtitlesSize: "자막 크기",
    small: "작은",
    medium:"중간",
    big: "큰",
    yourVideoReadyToDownload: "비디오 다운로드 준비가되었습니다.",
    yourVideoWasSuccessfullyDownloaded: "워크 플로가 성공적으로 다운로드되었습니다.",
    notAvailable: "사용할 수 없습니다",
    downloadFileFormat: "체재",
    selectDownloadWorkflowQuality: "비디오 품질을 선택하십시오",
    downloadSizeLimitedMsg: "{resolution}에서 SCORM을 다운로드하면 최대 {duration} 분 워크 플로우를 지원합니다.",
    workflowTotalDurationMsg: "워크 플로 총 기간 : {minute} 분 {second} 초.",
    // Export Compliance Wire
    export: "내보내다",
    code: "코드",
    organization: "조직기구",
    approvalDate: "승인일",
    processing: "처리 중",
    workflowShouldBePublic: "워크 플로는 공개되어야합니다.",
    alertExportComplianceWireSuccess: "제어 문서를 성공적으로 수출합니다. 코드 : {code}",
    alertExportComplianceWireExists: "제어 문서가 이미 존재합니다. 코드 : {code}",
    alertExportComplianceWireFailed: "제어 문서를 내보내는 동안 무언가 잘못되었습니다.",
    confirmedPublished: "이 워크 플로를 게시하고 싶습니까? <br>이 작업을 수행하면 모두가 볼 수 있습니다.",
    versionDescription: "버전 {num} 설명 (선택 사항) :",
    versionIsDuplicated: "버전은 복제됩니다",
  },
  editor: {
    closeWorkflow: "워크플로우 닫기",
    popupMsgCloseWorkflow: "당신의 워크플로우에 저장되지 않은 수정 사항이 있습니다. 계속하실 경우 <b>모든 수정</b>이 취소될 수 있습니다. 계속하시겠습니까?",
    popupBtnClose: "네, 닫겠습니다",
    processWorkflow: "워크플로우 수행",
    popupMsgProcessWorkflow: "굿! 보아하니 당신은 심사를 완료했고 Stephanie가 계속하여 처리해 주기를 원하시네요. 계속할 경우 당신은 이 심사 페이지로 <b>돌아올 수 없습니다</b>. 준비 되었습니까?",
    popupBtnProcess: "네, 계속하겠습니다",
    alertIsSaving: "저장 중입니다.",
    alertNoEmptySentence: "문장이 비어있습니다. 입력 또는 문장 삭제 후 저장하십시오.",
    alertIsSaved: "수정 사항을 저장했습니다.",
    alertWasUnpublished: "워크플로우의 게시가 취소되었습니다.",
    alertWasPublished: "워크플로우의 게시가 완료되었습니다.",
    alertNoMoreSteps: "이곳에 새로운 단계를 추가할 수 없습니다.",
    alertNoEmptyTitle: "제목이 비어있습니다. 제목을 입력하신 뒤에 저장하십시오.",
    scrollToVoice: "현재 자막으로 이동",
    noAudioMsg: "이 워크플로우에서 문자로 전환 가능한 음성을 찾지 못했습니다...",
    youWillDeleteStep: "당신은 단계를 삭제합니다",
    areYouSure: "계속하시겠습니까?",
    areYouSureDelete: "정말 삭제하시겠습니까?",
    replace: "대체",
    keyword: "키워드",
    find: "찾기",
    replaceWith: "교체",
    replaceAll: "전부 교체",
    replaceAllMsg: "전부 교체됩니다",
    of: "/",
    msgToSteps: "당신의 워크플로우를 분석 중입니다. <br>잠시만 기다려 주십시오...",
    msgToEditor1: "게시하기 전에 워크플로우 심사하기",
    msgToEditor2: "걱정하지 마세요. 다음 단계를 안내해드릴 것입니다",
    msgToBuilder1: "곧 해결됩니다. 잠시만 기다려 주십시오",
    msgToBuilder2: "당신의 워크플로우를 위해 단계를 생성하고 있습니다",
    newStep: "새 단계",
    hintNoEmptySentence: "문장이 비어있습니다",
    hintNoEmptyTitle: "(제목이 비어있습니다다)",
    alertNoTranscription: "이 워크플로우는 문자 전환이 없습니다",
    alertEmptyingTranscription: "경고: 문자 전환 결과가 비어있는 동영상을 지원하지 않습니다",
    alertStepCorrupted: "{count} 단계는 손상되었습니다. 다음 단계로 넘어가기 전에 삭제하십시오. ",
    // Editor 2.0
    trim: "컷아웃",
    erase: "삭제",
    voiceOver: "더빙",
    videoEditing: "동영상 편집",
    segmentation: "단계 구분",
    popupMsgRestToOriginal: "동영상을 정말 초기화하시겠습니까? 이미 저장된 모든 내용이 삭제됩니다.",
    trimHintMessage: "양측의 화살표를 이용해 컷아웃할 부분을 선택해 주십시오",
    eraseHintMessage: "양측의 화살표를 이용해 삭제할 부분을 선택해 주십시오",
    voiceOverHintMessage: "양측의 화살표를 이용해 더빙할 부분을 선택해 주십시오",
    voiceOverStart: "녹음",
    voiceOverStartHint: "이곳을 클릭하여 녹음 시작…",
    voiceOverSave: "녹음 저장",
    voiceOverCancel: "포기",
    voiceOverCancelPopupMsg: "당신의 녹음을 정말 삭제하시겠습니까?",
    voiceOverPause: "일시정지",
    voiceOverResume: "계속",
    unableToGetAudioInput: "녹음 설비 인식에 실패했습니다",
    restart: "다시 시작하기",
    editVideo: "동영상 편집",
    popupRequestTranscription: "워크플로우에 자막이 없습니다. 새 자막을 생성하십시오",
    popupMsgEditVideoWarning: "동영상을 편집하면 자막이 변경될 수 있습니다. 그래도 편집하시겠습니까?",
    popupMsgReTranscriptionWarning: "참고 : 비디오 편집 작업 (예 : 컷아웃/삭제/더빙)을 수행 한 경우 재 전송 기능을 사용할 수 없습니다.",
    popupMsgReTranscriptionWarningMultiEditing: "참고: 비디오 편집 작업(예: 컷/더빙 등)을 수행한 경우, 재전사 기능을 사용할 수 없습니다.",
    rotate: "회전",
    rotateLeft: "좌측으로 회전",
    rotateRight: "우측으로 회전",
    goBack: "b뒤쪽에",
    goBackToEditorHome: "편집기 리스트로 돌아가기",
    zoom: "줌",
    // transcription new features
    transcriptionCopySuccess: "클립 보드에 전사가 복사되었습니다",
    keyTerms: "핵심 용어",
    AILabs: "AI 실험실",
    copyTranscription: "전사를 복사하십시오",
    changeLanguage: "언어를 변경하십시오",
    copy: "복사",
    changeLanguageTitle: "전사의 새로운 언어를 선택하십시오",
    changeLanguageSubTitle: "Stephanie는 모든 전사를 재 처리해야합니다.",
    reProcessingYourVideo: "Stephanie는 비디오를 다시 처리하고 있습니다 ...",
    goBackToEditor: "여기서 기다리거나 편집자에게 돌아가서 계속 일할 수 있습니다!",
    // keyterms: added on 08/09/2022
    addTerm: "용어 추가",
    removeTerm: "용어 제거",
    termsFound: "<span>0</span> 찾은 용어 | <span>1</span> 찾은 용어 | <span>{count}</span> 찾은 용어",
    loadingTerms: "로딩 기간",
    termsInfo: "주요 용어는 워크플로에서 특정 콘텐츠를 검색할 때 매우 유용할 수 있습니다.",
    // Editor 3.0
    cut: "자르다",
    tabsWarningMessages: "경고! 이 페이지를 저장하지 않고 떠나면 모든 변경 사항이 손실됩니다.",
    versionHistory: "버전 역사",
    seeMore:"더보기...",
    seeLess:"덜 ...",
    workflowCreated:"워크 플로가 생성되었습니다",
    createdBy: "{name}에 의해 생성",
    publishedBy: "{name}에 의해 게시",
    lastUpdateBy: "{name}의 마지막 업데이트",
    // Version History add by andy 01/08/2023
    emptyTitle: "No versions yet!",
    emptyContent: "This Workflow has not been published yet, as soon as you publish for the first time you will start seeing the versions here."
  },
  player: {
    loadingVideo: "동영상 로딩 중 입니다...",
    loadingLanguage: "음성 로딩 중입니다...",
    previousStep: "이전 단계",
    nextStep: "다음 단계",
    audio: "볼륨",
    subtitles: "자막",
    autoPlay: "자동 재생",
    off: "끄기",
    on: "켜기",
    quality: "화질",
    speed: "재생속도",
    normal: "정상",
    rotatePhone: "스크린을 <span class='primary--text'>회전하여</span> <br> 더 나은 체험을 하십시오.",
    alertTranscodingFailed: "워크플로우 인코딩 실패, DeepHow에 연락하여 협조를 받으십시오.",
    alertTranscodingStillProgress: "이 워크플로우는 인코딩 중입니다. 잠시만 기다려 주십시오.",
    settings: "설정",
    openInBrowser: "브라우저로 열기",
    goToHome: "메인페이지로 돌아가기",
    thisWorkflowWasUnpublished: "워크플로우 미게시",
    thisWorkflowWasDeleted: "워크플로우가 삭제되었습니다",
    talkToSupervisor: "이 워크플로우가 게시 상태여야 한다고 생각하시면 당신의 매너지와 연락하십시오",
    selectWorkspace: "워크스페이스 선택",
    // player 2.0:
    home: "메인페이지",
    myWorkflows: "나의 워크플로우",
    recommendedForYou: "당신을 위해 추천합니다",
    favorites: "즐겨찾기",
    welcomeToDeepHowNavigator: "DeepHow 네비게이션을 사용해 주셔서 감사합니다",
    numNewSkillsTrainings: "<span>{count}개 기능이</span><br>당신에게 지정되었습니다.",
    clickForTips: "이곳을 클릭하여 알림을 확인하십시오.",
    learnHow: "알림 확인하기",
    goToSkills: "기능 들어가기",
    at: "에서",
    youAreIn: "조회 중",
    loadingRecommendations: "추천 로딩 중...",
    loadingFavoriteWorkflows: "즐겨찾기에 추가된 워크플로우 로딩 중...",
    noFavoriteWorkflows: "당신은 즐겨찾기에 내용을 추가하지 않았습니다",
    canFindSavedItemsHere: "당신은 이곳에서 즐겨찾기에 추가한 모든 내용을 확인할 수 있습니다",
    removeWorkflowFromFavorites: "이 워크플로우를 즐겨찾기에서 제거하시겠습니까?",
    yesGoAhead: "네, 제거하겠습니다",
    thisWorkflowIsNotAvailable: "워크플로우 사용 불가",
    dismiss: "더 보지 않기",
    navigator: "네비게이션",
    howToUseNavigator: "<b>DeepHow네비게이션</b><br> 사용방법",
    checkTheNewFeatures: "새로운 기능 보기",
    unknownUser: "미지의 사용자",
    okCloseBtn: "네, 닫겠습니다",
    emptyRecommendWorkflowTitle: "내용은 어디에 있나요?",
    emptyRecommendWorkflowDescription: "걱정마세요! 새 워크플로우가 생성되면. 당신에게 내용을 추천합니다. 빠른 시일에 다시 만나길 바랍니다!",
    thisWorkflowDoesNotExist: "이 워크플로우는 존재하지 않습니다.",
    // navigator
    resume: "지난번 시청",
    myWorkspaces: "나의 워크스페이스",
    otherWorkspaces: "기타 워크스페이스",
    loading: "로딩 중...",
    loadingfavoriteWorkspaces: "즐겨찾기에 추가된 워크스페이스 로딩 중...",
    removeWorkspaceFromFavorites: "이 워크스페이스를 즐겨찾기에서 제거하시겠습니까?",
    YouAreViewing: "조회 중",
    allWorkspaces: "모든 워크스페이스",
    removeFromFavorites: "즐겨찾기에서 제거",
    favoriteThisVideo: "이 동영상을 즐겨찾기에 추가하기",
    shareThisVideo: "이 동영상 공유하기",
    viewAll: "전체 보기",
    openFile: "파일 열기",
    suggestedKeyterms: "추천 키워드",
    // workflow player added 10/10/2022
    posted: "에 게시",
    views: "조회수 {count}회",
    saveFavorite: "수집",
    react: "피드백",
    numColleaguesReact: "{count} 동료 피드백",
    youAndNumColleaguesReact: "<span>당신</span>과 {count}명의 동료 피드백",
    youReacted: "<span>당신</span>동료 피드백",
    userReaction: "고객 피드백",
    fullscreen: "전체 화면",
    exitFullscreen: "전체 화면 종료",
    // 1/23/2023 Comments
    comments: "코멘트",
    deleteComment: "주석을 삭제하십시오",
    deleteCommentMsg: "이 의견을 삭제 하시겠습니까? <br> 이것은 취소 할 수 없습니다.",
    edit: "편집하다",
    updating: "업데이트 ...",
    changesSaved: "변경된 변경.",
    noComments: "아직 댓글이 없습니다…",
    makeFirstComment: "아래 첫 번째 의견을 작성하십시오",
    comment: "논평",
    writeAComment: "코멘트 쓰기...",
    edited: "편집",
    emojiRecent: "자주 사용되는",
    emojiSmileys: "스마일 & 이모티콘",
    emojiPeople: "사람과 몸",
    emojiNature: "동물 및 자연",
    emojiFoods: "음식 및 음료",
    emojiActivity: "활동",
    emojiPlaces: "여행 및 장소",
    emojiObjects: "사물",
    emojiSymbols: "기호",
    emojiFlags: "깃발",
    emojiCustom: "관습",
    back: "b뒤쪽에",
    feedback: "피드백",
    leaveAFeedback: "피드백 남기기",
    leaveAFeedbackMsg: "문제점을 알려주시거나, 제안이나 건설적인 비판을 남겨주세요.",
    typeYourMessageHere: "여기에 메시지를 입력하세요",
    submit: "제출",
    thankYou: "감사합니다.",
    feedbackSuccessMsg: "피드백이 성공적으로 전송되었습니다.",
    alertFeedbackFailedMsg: "피드백 전송에 실패했습니다. 다시 시도해주세요.",
    diagrams: "다이어그램",
    pdfFiles: "PDF 파일",
    theaterMode: "극장 모드",
    exitTheaterMode: "극장 모드 출구",
    // TODO: 09/08/2023
    enableWorkflowRepeat: "워크 플로 반복을 활성화합니다",
    enableStepRepeat: "단계 반복을 활성화하십시오",
    disableRepeat: "반복을 비활성화하십시오",
  },
  profile: {
    // added 12/09/2021
    addPositionLocation: "위치와 위치를 추가하십시오",
    noWorkflowsCreated: "아직 작성된 워크 플로가 없습니다.",
    comeBackLater: "나중에 다시 오세요.",
    createdWorkflows: "생성 된 워크 플로",
    profileOwnerNoContentMsg: "<span>이봐, {name}! 다음은 새 프로필 페이지입니다.</span> <br> 앞으로 모든 사람과 지식을 공유 할 수 있습니다. 기다려!",
    profileColleguesNoContentMsg: "<span>이것은 {name}의 프로필 페이지입니다.</span> <br> 아직 콘텐츠를 게시 할 수는 없지만 가까운 시일 내에 그는 할 것입니다!",
    editProfile: "프로필 수정",
    personalInformation: "개인 정보",
    personalInformationMsg: "프로필을 방문하는 사람들은 다음 정보를 볼 수 있습니다.",
    photo: "사진",
    position: "위치",
    locationRegion: "위치/ 지역",
    whatYouDo: "이 조직에서 당신이하는 일을 사람들에게 알리십시오.",
    whereYouBased: "사람들에게 당신이 어디에 있는지 알려주십시오.",
    accountManagement: "계정 관리",
    accountManagementMsg: "이 정보는 비공개이며 공개 프로필에 표시되지 않습니다.",
    identifyMyself: "나는 나 자신을",
    yourPassword: "너의 비밀번호",
    nonDeclared: "비 선고 (기본값)",
    male: "남성",
    female: "여성",
    nonBinary: "비 이진",
    viewProfile: "프로필보기",
    alertFailedToUpdateProfile: "프로필을 업데이트하지 못했습니다."
  },
  explorer: {
    search: "찾기",
    in: "",
    howTo: "지식 찾기...",
    didYouMean: "관련 표제어",
    searchInTitle: "제목",
    searchInContent: "내용",
    searchInVideo: "Stephanie 제안",
    title: "제목",
    searchStartMsg1: "무엇을 찾고 있습니까?",
    searchStartMsg2: "상기 검색창을 이용하기",
    sortBy: "순서",
    lastUpdated: "최신 업데이트",
    alphabetically: "명칭별",
    workflowsIn: "워크플로우는",
    tagNew: "새로 만들기",
    tagProcessing: "처리 중",
    msgProcessing: "Stephanie가 당신의 워크플로우를 처리하고 있습니다....<br/>잠시만 기다려 주십시오 : )",
    tagFailed: "실패",
    msgFailed: "앗, 동영상 처리 시 에러가 발생했습니다, <br/>잠시 후 다시 시도하십시오...",
    msgVideoLimitExceeded: "동영상이 DeepHow 지원 가능 분량을 초과했습니다. 이는 2개 또는 더 많은 워크플로우에서 자동으로 재업로드됩니다.",
    msgVideoExceededMaximum: "비디오는 Deephow에서 지원하는 최대 워크 플로 길이를 초과합니다. 두 개 이상의 워크 플로에서 자동으로 분할됩니다.",
    tagTranscoding: "인코딩 중",
    msgTranscoding: "Stephanie가 당신의 워크플로우를 인코딩 중입니다...<br/>잠시만 기다려 주십시오 : )",
    deleteWorkflow: "워크플로우 삭제",
    popupBtnDelete: "네, 삭제하겠습니다",
    popupMsgDeleteWorkflow: "정말 이 워크플로우를 삭제하시겠습니까? 당신은 이 워크플로우와 관련된 모든 단계와 데이터를 분실하게 됩니다.",
    noUnpublishedWorkflows: "게시되지 않은 워크플로우가 없습니다...",
    noPublishedWorkflows: "게시된 워크플로우가 없습니다...",
    startCreatingWorkflow: "워크플로우를 생성해 봅시다!",
    startPublishingWorkflow: "워크플로우를 게시해 봅시다!",
    noSearchResult: "검색결과 없음...",
    trySearchAgain: "다시 시도하십시오",
    searchingForWorkflows: "워크플로우 찾는 중...",
    secondsAgo: "{count}초 전",
    minutesAgo: "{count}분 전",
    hoursAgo: "{count}시간 전",
    daysAgo: "{count}일 전",
    monthsAgo: "{count}개월 전",
    yearsAgo: "{count}년 전",
    sAgo: "{count}초 전",
    minAgo: "{count}분 전",
    hAgo: "{count}시간 전",
    dAgo: "{count}일 전",
    mAgo: "{count}개월 전",
    yAgo: "{count}년 전",
    renameWorkflow: "워크플로우 명칭 재설정",
    moveWorkflowTo: "워크플로우를 이동하기",
    duplicateWorkflow: "워크플로우 복사",
    unpublishWorkflow: "게시 취소",
    shareKnowhow: "기능 공유",
    or: "또는",
    clickHere: "이곳을 클릭하십시오",
    andCopyMsg: "링크를 복사하여 이메일로 공유 가능",
    successfulSharing: "공유 완료",
    send: "발송",
    typeHereAMessage: "여기에 메시지를 입력하십시오",
    wellDone: "굿",
    numMore: "이외에 {count}명",
    moveWorkflow: "워크플로우를 이동하기",
    currentWorkspace: "현재 워크스페이스",
    whereToMsg: "이 워크플로우를 이동하겠습니까?",
    move: "이동",
    alertSuccessfullyMoved: "워크플로우 이동 완료",
    alertFailedMoved: "워크플로우 이동 실패",
    noNotificationsMsg: "받은 메시지가 없습니다",
    betaSearch: "Stephanie 제안은 여전히 ​​베타에 있습니다 ...",
    relevantSteps: "관련 단계:",
    relevance: "관련",
    resultsFor: "{num}개 검색 결과",
    resultFor: "{num}개 검색 결과",
    byPublisher: "발표자",
    alertFailedShareWorkflow: "워크 플로를 공유하지 못했습니다.",
  },
  new: {
    newWorkflow: "새 워크플로우",
    uploadVideo: "동영상 업로드",
    recordScreen: "스크린 녹화",
    replaceVideoSubtitle: "드래그 또는 클릭하여 동영상 교체",
    recordScreenSubtitle: "Stephanie는 당신의 스크린을 녹화하기 위한 준비를 마쳤습니다",
    workflowName: "워크플로우 명칭",
    workflowLanguage: "워크플로우 언어",
    addToWorkspace: "추가하기",
    upload: "업로드",
    startRecording: "녹음 개시",
    cancel: "취소",
    wrongUploadFile: "이 파일 형식을 지원하지 않습니다 지원 가능 형식:",
    limitFileDuration: "지원 가능한 동영상의 최장 길이는 {duration}분이며 Windows app 버전의 DeepHow 업로드 툴을 이용하면 더 큰 용량의 파일도 업로드 가능합니다.",
    limitFileDurationGen2: "지원 가능한 동영상의 최장 길이는 {duration}분이며",
    uploadingVideo: "동영상 업로드 중",
    uploadingVideoSubtitle: "잠시만 기다려 주십시오. Stephanie가 당신의 동영상을 업로드하고 있습니다.",
    renderingVideo: "렌더링 중/동영상 저장",
    renderingVideoSubtitle: "잠시만 기다려 주십시오, Stephanie가 당신의 동영상을 렌더링 중입니다",
    almostDone: "곧 해결됩니다!",
    uploadingStep1: "2단계 중의 1단계, 워크플로우 분석",
    uploadingStep2: "동영상 업로드 중 입니다...",
    successSubtitle: "워크플로우 업로드가 완료되었습니다",
    goToEditor: "편집기로 이동하기",
    goToContentManger: "컨텐츠 관리자에게 이동하십시오",
    uploadingAlert: "브라우저에서 나가거나 끄면 탭에서 업로드 오류가 발생합니다",
    recordingScreen: "스크린 녹화 중",
    support15Min: "지원 가능한 화면 녹화 최장 시간은 <b>15분</b>",
    hereWeGo: "준비 시작!",
    remember15Min: "주어진 시간은 15분입니다",
    windowsAppAvailable: "Windows app<br>에 접속했습니다",
    windowsAppAvailableNews: "Windows app버전의 DeepHow 업로드 툴에 접속했습니다. 당신은 더 큰 용량의 파일 업로드 및 화면 녹화를 진행할 수 있습니다.",
    downloadNow: "클릭하여 다운로드!",
    deepHowWindowsApp: "DeepHow Windows App",
    downloadAndInstalltheWindowsApp: "Windows App 다운로드 및 설치",
    notAllowedToInstall: "당신은 상기 버전을 설치할 수 없습니다,",
    downloadThisVersion: "이 버전 다운로드",
    and: "또한",
    watchThisVideo: "<span>해설 동영상 시청</span>.",
    // Added on 2022/01/19
    abiAuthorizationLabel: "저는 이 영상에 출연하는 모든 개인이 <span>사진/동영상에 개인 초상권을 사용하고</span> 저의 소재지 국가에 한해 발표하도록 허가하는 사항에 관해 계약 체결 및 권한 부여 를 했음을 인정합니다.",
    abiPrivacyLabel: "저는 ABInbev 의 <span>사진/동영상 녹화 개인정보 보호 정책을 읽고</span> 이해했음을 인정합니다.",
    // Added on 2022/06/15
    importFromZoom: "Zoom에서 가져오기",
    importFromZoomSteps: "{totalStep}단계 중 {step}단계",
    selectVideosFrom: "에서 비디오 선택",
    videoSelected: "동영상 <span>{count}</span>개 선택됨",
    workflowInfo: "워크플로 정보",
    newImport: "새 가져오기",
    orBackToEditor: "또는 <span>편집기</span>로 돌아가기",
    importFromZoomSuccessMsg: "동영상이 곧 업로드되기 시작하며 편집할 수 있게 되기까지 다소 시간이 걸릴 수 있습니다.",
    zoomFailedUploadMsg: "Zoom 비디오를 업로드하지 못했습니다.",
    zoomPeriodLastMonth: "지난 달",
    zoomPeriodPastMonth: "지난 {n}개월",
    loadingZoomRecordings: "동영상 로드 중",
    zoomNoRecordingsInfo: "선택한 기간에 대한 녹화가 없습니다.",
    yourVideoExceedTwoHours: "비디오는 2 시간을 초과하며 업로드 할 수 없습니다.",
    yourVideoExceed3GSize: "비디오는 3GB를 초과하며 업로드 할 수 없습니다.",
    yourVideoExceedTwoHoursAnd3GSize: "비디오는 2 시간, 3GB를 초과하며 업로드 할 수 없습니다.",
    videoUploadWarningMessage: "드래그 또는 클릭하여 비디오 (MP4 또는 웹 만) 최대를 가져옵니다. 크기는 3GB이고 최대 2 시간입니다.",
    videoUploadWarningMessageGen2: "드래그 또는 클릭하여 비디오 (MP4 또는 웹 만) 최대를 가져옵니다. 최대 40 분.",
  },
  auth: {
    aboutDeephow: "DeepHow에 관하여",
    authTitle: "기능교육을 위한 최초의 인공지능 솔루션.",
    authIntro: "DeepHow는 인공지능 기술에 기반한 인터랙티브 운영식 동영상 교육플랫폼이며 제조, 서비스, 수리 분야의 기능 격차를 줄여주고 있습니다.",
    allRightReserved: "모든 권리 보류",
    logIn: "로그인",
    logOut: "로그아웃",
    logInSubtitle: "당신의 자세한 정보를 입력하여 로그인하십시오.",
    logInSubtitleSso: "다음 버튼을 클릭하여 로그인 하십시오",
    emailOrId: "이메일 또는 직원 ID",
    hello: "안녕하세요,",
    passwordSubtitle: "비밀번호를 입력해 주십시오",
    password: "비밀번호",
    forgotPassword: "비밀번호를 잊어버림",
    forgotPasswordSubtitle: "당신의 이메일을 입력하시면 새 비밀번호 생성하기 링크를 보내드립니다.",
    email: "이메일",
    resetPassword: "비밀번호 재설정",
    alertResetSuccess: "당신의 이메일 수신함을 확인하십시오",
    alertResetFailed: "비밀번호 복구 실패.",
    alertInvalidId: "유효하지 않은 직원ID입니다.",
    successMsg: "당신의 이메일을 확인하시고 링크를 클릭하여 계정의 새 비밀번호를 설정하십시오.",
    logInWithSSO: "SSO으로 로그인하기",
    loggingInWithSSO: "SSO로 로그인 중...",
    logInWithGoogle: "Google계정으로 로그인",
    logInWithMicrosoft: "Microsoft 계정으로 로그인",
    loadingSso: "SSO 로그인 로딩 중...",
    about: "관하여",
    // 12/17/2021 iframe
    loginToYourAccount: "당신의 계정으로 로그인 하십시오",
    pleaseInputYourPassword: "비밀번호를 입력해 주십시오",
    backToLogin: "로그인 페이지로 돌아가기",
    // added 03/02/2022
    wrongPassword: "비밀번호가 틀렸습니다.",
    // Shared Account
    sharedDevice: "설비 공유",
    loginExistingAccount: "기존 계정으로 로그인",
    createNewAccount: "새 계정 만들기 ",
    noUsersMsg: "아직 이 공유설비로 로그인한 사용자가 없습니다...",
    insertIDMsg: "당신의 직원 ID를 입력하십시오",
    createIdUserMsg: "곧 해결됩니다! <br> 하단에 당신의 정보를 기입하십시오.",
    confirmEmployeeID: "직원ID 확인",
    welcomeToDeepHow: "DeepHow를 방문해 주셔서 감사합니다, <b>{name}</b>",
    confirmWorkspaceTeamMsg: "당신의 워크스페이스와 그룹을 확인하십시오.",
    LetsGetStarted: "시작합시다!",
    // added 05/26/2022
    useSsoLogin: "당신의 조직은 이메일/비밀번호를 이용하여 로그인하기를 사용하지 않았습니다. SSO로 로그인 하십시오.",
    // added 02/17/2023
    cookieAlert: "나는 <a href='{pdfUrl}' target='_blank'> 개인 정보 보호 정책 </a>에 자세히 설명 된 쿠키의 사용을 받아들입니다.",
    useCookie: "우리는 쿠키를 사용합니다!",
    useCookieContext: "우리는 쿠키를 사용하여 DeepHow를 사용하여 멋진 경험을 할 수 있도록합니다!",
    privacyPolicy: "개정정보 보호 정책",
    acceptCookies: "쿠키를 수락하십시오"
  },
  rules: {
    required: "필수기입",
    invalidEmail: "유효하지 않은 이메일",
    noSpace: "스페이스 삽입 불가",
    validPassword: "비밀번호는 숫자, 영문 대소문자를 포함한 6자리 이상이어야 합니다",
    maxPassword: "최대 16자리 문자부호",
    mustMatch: "비밀번호가 일치해야 합니다",
    max25: "최대 25자리 문자부호 ",
    max90: "최대 90자리 문자부호 ",
    max100: "최대 100자리 문자부호 ",
    max140: "최대 140자리 문자부호 ",
    numMaxCharacters: "최대 {count}자리 문자부호",
    min6: "최소 6자리 문자부호",
    noWhiteSpace: "스페이스로 제목을 대체할 수 없습니다",
    noAllSpace: "스페이스로 명칭을 대체할 수 없습니다",
    invalidEmployeeID: "유효하지 않은 직원ID입니다",
    invalidUrl: "유효하지 않은 URL",
    mustMatchId: "직원 ID가 일치해야 합니다",
    canNotEnterSharedAccount: "공유 계정 ID를 삽입 할 수 없습니다.",
    validPasswordMin10: "비밀번호는 10 자 이상이어야하며 번호, 특수 문자, 하위 및 대문자를 포함합니다.",
    invalidName: "잘못된 이름.",
  },
  alert: {
    success: "완료",
    info: "알림",
    alert: "경고",
    error: "오류",
    dontShowThisMsgAgain: "이 메시지를 다시 표시하지 마십시오",
    unexpectedError: "예상치 못한 오류가 발생했습니다. 도움을 받으려면 다시 시도하거나 DeepHow에 연락하십시오.",
  },
  skills: {
    skillsMatrix: "기능 행렬",
    createSkill: "기능 새로 만들기",
    skillsCreated: "생성한 기능",
    skillsPublished: "게시된 기능",
    skillsIn: "기능은",
    hintSearchSkills: "기능 검색...",
    lastUpdated: "최신 업데이트",
    skillsPerPage: "매 페이지의 기능 수량",
    usersAssigned: "배분된 사용자",
    status: "상태",
    createdBy: "생성자",
    skillsSelected: "선택한 기능   개",
    updatedBy: "업데이트원",
    deleteSkill: "기능 삭제",
    popupMsgDeleteSkill: "이 기능을 정말 삭제하시겠습니까?",
    deleteSkills: "기능 삭제",
    popupMsgDeleteSkills: "이 기능을 정말 삭제하시겠습니까?",
    clearAll: "다시 선택",
    clearAllFilters: "모든 선택 취소",
    clearFilter: "선택 취소",
    delete: "삭제",
    youAreViewing: "당신은 조회 중입니다",
    numWorkflows: "{count}개 워크플로우",
    searchIn: "검색",
    loadingSkillMatrix: "기능 행렬 로딩 중...",
    createSkillSubtitle: "제목을 입력하고 업로드할 워크스페이스를 선택하십시오",
    skillName: "기능 제목",
    create: "새로 만들기",
    editSkill: "편집 기능",
    popupMsgEditSkill: "편집 모드에 진입하면 당신의 기능은 자동으로 게시 취소가 됩니다. 그래도 계속하시겠습니까? ",
    publishSkill: "기능 게시",
    popupMsgPublishSkill: "당신이 기능을 게시하면 모든 사용자가 볼 수 있습니다. 그래도 계속하시겠습니까?",
    alertFailedSaveSkill: "기능 저장 실패",
    alertSkillSuccessfullyPublished: "당신의 기능이 게시되었습니다",
    alertSkillSuccessfullyUnpublished: "당신의 기능 게시가 취소되었습니다",
    alertSkillSuccessfullySaved: "당신의 기능이 저장되었습니다",
    alertFailedSavedSkill: "기능 저장 실패",
    alertFailedPublishSkill: "기능 게시 실패",
    alertSkillSuccessfullyDeleted: "당신의 기능이 삭제되었습니다",
    alertSkillFailedDeleted: "기능 삭제 실패",
    content: "내용",
    addContent: "내용 추가하기",
    workflowDeleted: "워크플로우가 삭제되었습니다",
    workflowsSelected: "개 선택한 워크플로우",
    selectAll: "전체 선택",
    remove: "제거",
    noContentMsg: "이 기능에는 추가된 내용이 없습니다",
    assignUsers: "사용자 추가",
    noUserMsg: "당신은 이 교육에 사용자를 추가하지 않았습니다",
    numUsers: "<b>{count}</b>개 사용자",
    assignedOn: "추가하기",
    certifiedBy: "인증",
    progress: "진도",
    aletUserSuccessfullyRemoved: "사용자가 제거되었습니다",
    removeUser: "사용자 제거",
    popupMsgRemoveUserFromSkill: "당신은 이 기능에서 <b>{name}</b>를 제거하시겠습니까?",
    assign: "추가",
    successfulAssigned: "추가 완료",
    AddedToSkill: "추가 완료",
    loadingSkills: "기능 로딩 중...",
    searchingForSkills: "기능 검색 중...",
    toDo: "완성 대기",
    completed: "완료",
    assignedBy: "배분인",
    min: "분",
    nextPart: "다음 파트",
    noSkillsAssignedMsg: "완성 대기 중인 기능이 없습니다",
    noSkillsAssignedMsg2: "새 메시지가 있으면 알려드리겠습니다!",
    noCompletedSkillsMsg: "완성된 기능이 없습니다",
    noCompletedSkillsMsg2: "기능을 배워 봅시다!",
    noDataSkillsMatrix: "기능 행렬에 데이터가 없습니다...",
    certificate: "합격 인증서",
    certificateMsg: "사용자는 기능을 다 배운 후 합격 인증서를 받게 됩니다.",
    selectCertificateMsg: "이 기능을 인증할 수 있는 사용자 선택:",
    validateSkill: "인증 기능",
    validateSkillMsg: "정말 <b>{name}</b>의 기능을 인증{skill}하시겠습니까?<br />당신은 인증을 철회할 수 없습니다.",
    yesValidate: "네, 인증하겠습니다",
    noValidate: "돌아가기",
    certifier: "인증인",
    signaturesOn: "에서",
    teamProgress: "그룹 진도",
    openTeam: "그룹 열기",
    removeCertificate: "인증 제거",
    removeCertificateMsg: "정말 이 기능을 인증할 수 있는 모든 사용자를 제거하시겠습니까?",
    yesRemove: "네, 제거하겠습니다",
    mustAddUserMsg: "* 추가된 사용자가 없으면 인증이 활성화되지 않습니다.",
    skillCover: "기능 표지",
    alertSuccessfullyUpdatedSkillCover: "기능 표지 업데이트 완료.",
    alertFailedUpdatedSkillCover: "기능 표지 업데이트 실패.",
    closeSkill: "기능 닫기",
    allWorkflowsDeleted: "이 기능 중의 모든 워크플로우가 삭제되었습니다.",
    waitingForValidation: "인증 대기",
    validatedBy: "인증인:",
    skillWasUnpublished: "이 기능은 게시가 취소되었습니다",
    skillWasUnpublishedMsg: "이 기능을 다시 게시하면, 당신에게 알려드리겠습니다.",
    notAssignedToSkill: "이 기능은 당신에게 배분되지 않았습니다",
    notAssignedToSkillMsg: "죄송합니다. 이 기능을 배분 받은 사용자만이 내용을 조회할 수 있습니다.",
    remindPendingUsers: "미완성 사용자에게 알림",
    alertSuccessRemindPendingUsers: "미완성 사용자에게 알림 완료",
    alertFailedRemindPendingUsers: "미완성 사용자에게 알림 실패",
    alertNoPendingUsers: "미완성 사용자를 찾을 수 없습니다",
    numVideo: "0개의 동영상 | 1개의 동영상 | {count}개의 동영상",
    // Skill Deadline
    deadline: "마감일",
    deadlineMsg: "사용자를 위해 이 기능 배우기를 완성하는 통일된 기한 설정하기 마감일 이후 미완성 사용자는 통지를 받게 됩니다.",
    deadlineForThisSkill: "이 기능의 마감일 기능 사용하기",
    certificateForThisSkill: "이 기능의 인증 기능을 사용하기",
    deadlineStartsMsg: "카운트다운 종료는 이 사용자가 이 기능에 추가된 시점부터 계산됩니다.",
    selectAPeriod: "기한 선택하기",
    finished: "완성",
    editDeadline: "마감일 수정",
    editDeadlineMsg: "마감일이 수정되면 그 전의 마감일은 더 이상 유효하지 않으며 이 기능의 모든 사용자에게 새 기한이 적용됩니다. <br><br>정말 수정하시겠습니까?",
    alertSuccessNewDeadline: "모든 사용자의 마감일이 업데이트되었습니다.",
    alertSuccessRemoveDeadline: "이 기능의 마감일 기능이 취소되었습니다",
    alertFailedDeadline: "마감일 업데이트에 실패했습니다.",
    dueToday: "오늘 마감",
    turnOffDeadlineMsg: "마감일을 제거하면 기존에 설정한 마감일이 삭제되며, 다시 열었을 때 모든 사용자의 마감일이 전부 재설정됩니다. <br><br>정말 수정하시겠습니까?",
  },
  time: {
    numDays: "0일 | 1일 | {count}일",
    numWeeks: "0주 | 1주 | {count}주",
    numMonths: "0개월 | 1개월 | {count}개월",
    numDaysLeft: "남은 일수 0일 | 남은 일수 1일 | 남은 일수{count}일",
    numDaysDelayed: "기한 만료 0일 | 기한 만료 1일 | 기한 만료 {count}일",
  },
  notification: {
    sharedWorkflow: "워크플로우 공유",
    assignedNewSkill: "기능 공유 완료",
    sentAnnouncement: "공고 발송 완료",
    needValidation: "당신의 인증이 필요합니다",
    noNotificationsMsg: "받은 메시지가 없습니다",
    justNow: "방금",
    updatedSkill: "기능 업그레이드",
    unpublishedSkill: "기능 게시 취소",
    sentAReminder: "이 기능을 배울 것을 안내합니다. 시청을 시작하면 그는 당신의 학습 진도를 조회할 수 있습니다.",
    assignedNewSkillDue: "이 기능을 배울 것을 안내합니다. 당신은 <span>{time}</span> 시간 내에 완성하면 됩니다.",
    delayedSkillMsg: "당신에게 <span> 유효기간이 만료된 기능</span>이 있습니다. 시청을 완료하여 진도 따라잡기!",
    daysLeftMsg: "당신은 <span>{time}</span> 시간 내에 이 기능을 배울 수 있습니다. 빨리 서둘러야 지연되지 않습니다!",
    askToReviewWorkflow: "워크 플로를 검토하도록 요청했습니다.",
    reactedWorkflowMsg: "<span>{user}</span>님이 귀하의 워크플로에 반응했습니다.",
    reactedWorkflowMsgTwoUsers: "<span>{user1} 및 {user2}</span>는 작업 흐름에 반응했습니다.",
    reactedWorkflowMsgMoreUsers: "<span>{user1}, {user2}및 기타 {count}명이 </span>귀하의 워크플로에 반응했습니다.",
  },
  mediaPicker: {
    insertFullWorkflow: "모든 워크플로우 삽입",
    selectWorkflowStep: "워크플로우 단계 선택",
    stepSelected: "개 선택한 단계",
  },
  zoomIntegration: {
    settingsTitle: "Zoom 설정",
    settingsSubtitle: "일체화",
    deleteCopy: "Zoom Cloud에서 녹화한 동영상 사본 삭제하기",
    goToDeephow: "DeepHow 이동",
    landingSuccess: "<b>DeepHow Know-How Video App</b> 성공적으로 설치됨",
    landingError: "<b>DeepHow Know-How Video App</b>설치 중 오류가 발생했습니다. 지원을 받으려면<a href = 'mailto: service@deephow.com'>service@deephow.com</a>에 문의하십시오.",
    landingWarning: "조직에 줌 통합이 가능하지 않습니다.",
  },
  reactions: {
    likeIt: "좋아요",
    greatContent: "훌륭한 콘텐츠",
    wellPresented: "잘 나타나있는",
    niceProduction: "멋진 프로덕션",
  },
  maven: {
    // TODO: maven chat added on 03/15/2023
    mavenChat: "Chat",
    searchInWorkspace: "Search in {workspace}",
    showReferences: "Show references",
    hideReferences: "Hide references",
    helloMessage: "Hello {userName},",
    assistMessage: "How can I assist you today?",
    askMeAnything: "Ask me anything...",
    tryOtherQuestionMessage: "You can try asking other question.",
    audioIsReady: "Please recording your audio clip in English...",
    audioLimitationTime: "The audio limitation time is 3 mins.",
    clearChatHistory: "Clear chat history",
    newLineHint: "new line: shift + Enter",
    // TODO: maven map added on 04/14/2023
    map: "Map",
    resetMap: "reset map",
    filterWorkspaces: "filter workspaces",
    showQuestions: "show questions",
    hideQuestions: "hide questions",
    showClusters: "show clusters",
    hideClusters: "hide clusters",
    question: "Question",
    loadingMap: "Loading map...",
    clearChatHistoryConfirmation: "Clearing the chat history will start a new chat. Are you sure?",
    clearConfirm: "Yes, clear",
  },
  $vuetify: {
    dataIterator: {
      rowsPerPageText: "매 페이지의 기록 수량:",
      rowsPerPageAll: "모두",
      pageText: "{0}-{1} 총 {2} 개",
      noResultsText: "일치한 기록을 찾을 수 없습니다",
      nextPage: "다음 페이지",
      prevPage: "이전 페이지",
    },
    dataTable: {
      rowsPerPageText: "매 페이지 행렬수: ",
    },
    noDataText: "사용할 수 없는 데이터",
    carousel: {
      prev: "이전 단계",
      next: "다음 단계",
    }
  }
};
