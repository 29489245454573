function insertScript(appid) {
  const r = document.createElement("script");
  r.type = "text/javascript";
  r.async = !0;
  r.src = "https://cdn.heapanalytics.com/js/heap-" + appid + ".js";
  const a = document.getElementsByTagName("script")[0];
  a.parentNode.insertBefore(r, a);
}
const Heap = {
  initialize({ clientId }) {
    window.heap = window.heap || [];
    heap.load = function (e, t) {
      window.heap.appid = e;
      window.heap.config = t || {};
      insertScript(e);
      for (
        let n = function (e) {
            return function () {
              heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            "addEventProperties",
            "addUserProperties",
            "clearEventProperties",
            "identify",
            "resetIdentity",
            "removeEventProperty",
            "setEventProperties",
            "track",
            "unsetEventProperty",
          ],
          o = 0;
        o < p.length;
        o++
      )
        heap[p[o]] = n(p[o]);
    };
    try {
      heap.load(clientId);
    } catch (error) {
      console.log(error);
    }
  },
  setUserUid(uid) {
    try {
      heap.identify(uid);
    } catch (error) {
      console.error(error);
    }
  },
  setUserOrganization(organizationId) {
    try {
      heap.addUserProperties({ "Organization ID": organizationId });
    } catch (error) {
      console.error(error);
    }
  },
  setUserGroup(groupId) {
    try {
      heap.addUserProperties({ groupId: groupId });
    } catch (error) {
      console.error(error);
    }
  },
  setEnteredEditorSteps(workflowId) {
    try {
      heap.track("EnteredEditorSteps", { "Workflow ID": workflowId });
    } catch (error) {
      console.error(error);
    }
  },
  setEnteredEditorTranscription(workflowId) {
    try {
      heap.track("EnteredEditorTranscription", { "Workflow ID": workflowId });
    } catch (error) {
      console.error(error);
    }
  },
  setEventProperties(events = {}) {
    try {
      heap.addEventProperties(events);
    } catch (error) {
      console.error(error);
    }
  },
  removeEventProperties(eventName = "") {
    try {
      heap.removeEventProperty(eventName);
    } catch (error) {
      console.error(error);
    }
  },
  setTrack(trackName, options = {}) {
    try {
      heap.track(trackName, options);
    } catch (error) {
      console.error(error);
    }
  },
  addUserProperties(options = {}) {
    try {
      heap.addUserProperties(options);
    } catch (error) {
      console.error(error);
    }
  },
  getSessionId() {
    try {
      return heap.getSessionId();
    } catch (error) {
      console.error(error);
    }
  },
};
export default Heap;
