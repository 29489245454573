import { floor, isNumber, round } from "lodash-es";

export const namespaced = true;
import Vue from "vue";
import {
  EDITOR_STATUS,
  PROCESSING_POPUP_TYPE,
  REVIEW_POPUP_TYPE,
  TERMS_STATUS,
  MULTI_EDITOR_STATUS,
} from "@/constants/editorStatus.js";
import { fetchGroups } from "@/server/api-server";
import { isAllowedKeytermsLanguage, generateAllMatchedKeyterms } from "@/js/workflow/keyterms.js";
import { convertCurrentTimeIntoWidth } from "@/js/common/zoomUtils";

export const state = {
  groups: [],
  workflow: {},
  isDataBeingProcessed: false,
  processingPopupType: PROCESSING_POPUP_TYPE.CLOSE,
  reviewPopupType: REVIEW_POPUP_TYPE.WAITING_INITIAL,
  videoKey: "",
  hasDataBeingEdited: false,
  isRefreshWorkflow: false,
  showToSegmentationPopup: false,
  showCreatingSteps: false,
  transcription: [],
  isWorkflowKeytermsLoaded: false,
  isTranscriptionHighlightKeyterms: false,
  transcriptionHighlightOneKeytermValue: "",
  workflowKeyterms: [],

  // common player info
  currentTime: 0,
  videoDuration: 0,
  videoSigned360: "",
  // multi-editor state
  unites: [{ width: 100, time: 1 / MULTI_EDITOR_STATUS.DEFAULT_FPS, unit: "1frame" }],
  unitIndex: 0,
  selectedIndex: -1,
  imageMaps: {},
  cacheImageMaps: {},
  splitsArray: [],
  deleteArray: [],
  redoArray: [],
  logArray: [],
};

export const mutations = {
  STORE_GROUPS(state, payload) {
    state.groups = payload;
  },
  STORE_WORKFLOW(state, payload) {
    state.workflow = payload;
  },
  SET_DATA_IS_EDITED(state) {
    state.isDataBeingProcessed = true;
  },
  SET_DATA_IS_UNEDITED(state) {
    state.isDataBeingProcessed = false;
  },
  SET_PROCESSING_POPUP_TYPE(state, payload = PROCESSING_POPUP_TYPE.CLOSE) {
    state.processingPopupType = payload;
  },
  SET_REVIEW_POPUP_TYPE(state, payload = REVIEW_POPUP_TYPE.CLOSE) {
    state.reviewPopupType = payload;
  },
  SET_IMAGE_MAPS(state, imageMaps) {
    state.imageMaps = imageMaps;
  },
  SET_CURRENT_TIME(state, currentTime) {
    state.currentTime = currentTime;
  },
  SET_SPLITS_ARRAY(state, time) {
    const splitTime = floor(time, 2);
    if (state.splitsArray.find((n) => n === splitTime)) {
      return;
    }
    state.splitsArray.push(splitTime);
    state.splitsArray.sort((a, b) => +a - +b);
    state.redoArray = [];
    state.logArray.push({ time: splitTime, type: "split" });
  },
  SET_DELETE_ARRAY(state, { getters }) {
    if (state.selectedIndex === -1) return;
    const deleteTime = state.splitsArray[state.selectedIndex];
    let time = 0;

    if (state.selectedIndex !== 0) {
      const { endTime } = getters.getter_clips_items[state.selectedIndex - 1];
      time = endTime;
    }
    state.currentTime = time;
    state.deleteArray.push(deleteTime);
    state.selectedIndex = -1;
    state.redoArray = [];
    state.logArray.push({ time: deleteTime, type: "delete" });
  },
  SET_SELECTED_INDEX(state, selectedIndex) {
    state.selectedIndex = selectedIndex;
  },
  MUTATE_WORKFLOW_DATA(state, { key, value }) {
    if (key in state.workflow) {
      state.workflow[key] = value;
    } else {
      Vue.set(state.workflow, key, value);
    }
  },
  REFRESH_VIDEO_KEY(state) {
    state.videoKey = Math.random().toString(16).slice(2);
  },
  UPDATE_SAVEABLE_STATE(state, { hasDataBeingEdited }) {
    state.hasDataBeingEdited = hasDataBeingEdited;
  },
  TOGGLE_REFRESH_WORKFLOW(state, payload = false) {
    state.isRefreshWorkflow = payload;
  },
  TOGGLE_TO_SEGMENTATION_POPUP(state, payload = false) {
    state.showToSegmentationPopup = payload;
  },
  TOGGLE_TO_BUILDER_MAIN_POPUP(state, payload = false) {
    state.showCreatingSteps = payload;
  },
  CLEAR_WORKFLOW(state) {
    state.workflow = {};
  },
  CLEAR_VIDEO_EDITING_INFO(state) {
    state.currentTime = 0;
    state.videoDuration = 0;
    state.videoSigned360 = "";
    state.unitIndex = 0;
    state.imageMaps = {};
    state.cacheImageMaps = {};
    state.splitsArray = [];
    state.deleteArray = [];
    state.redoArray = [];
    state.logArray = [];
  },
  CLEAR_MULTI_EDITOR_ARRAY(state) {
    state.deleteArray = [];
    state.splitsArray = [];
    state.redoArray = [];
    state.logArray = [];
  },
  RESET_SETTING(state) {
    state.isDataBeingProcessed = false;
    state.hasDataBeingEdited = false;
    state.isRefreshWorkflow = false;
    state.showToSegmentationPopup = false;
    state.showCreatingSteps = false;
    state.processingPopupType = PROCESSING_POPUP_TYPE.CLOSE;
    state.reviewPopupType = REVIEW_POPUP_TYPE.WAITING_INITIAL;
    state.isWorkflowKeytermsLoaded = false;
    state.isTranscriptionHighlightKeyterms = false;
    state.transcriptionHighlightOneKeytermValue = "";
  },
  RESET_VIDEO_EDITING_INFO(state) {
    state.currentTime = 0;
    state.selectedIndex = -1;
    state.splitsArray = [];
    state.deleteArray = [];
    state.redoArray = [];
    state.logArray = [];
  },
  MUTATE_TRANSCRIPTION(state, transcription = []) {
    state.transcription = transcription;
  },
  STORE_WORKFLOW_KEYTERMS(state, payload = []) {
    state.workflowKeyterms = payload;
  },
  CLEAR_WORKFLOW_KEYTERMS(state) {
    state.workflowKeyterms = [];
  },
  TOGGLE_WORKFLOW_KEYTERMS_LOADED(state, payload = false) {
    state.isWorkflowKeytermsLoaded = payload;
  },
  TOGGLE_TRANSCRIPTION_HIGHLIGHT_KEYTERMS(state, payload = false) {
    state.isTranscriptionHighlightKeyterms = payload;
  },
  SET_TRANSCRIPTION_HIGHLIGHT_ONE_KEYTERM_VALUE(state, payload = "") {
    const term = payload ? payload.toLowerCase() : "";
    state.transcriptionHighlightOneKeytermValue = term;
  },
  CHANGE_UNIT_INDEX(state, index) {
    state.unitIndex = index;
  },
  STORE_VIDEO_INFO(state, payload) {
    state.videoDuration = payload.videoDuration;
    state.videoSigned360 = payload.videoSigned360;
  },
  UN_DO(state) {
    const { type, time } = state.logArray.pop();
    switch (type) {
      case "split":
        state.splitsArray = state.splitsArray.filter((t) => t !== time);
        break;
      case "delete":
        state.deleteArray = state.deleteArray.filter((t) => t !== time);
    }
    state.redoArray.push({ type, time });
  },
  RE_DO(state) {
    const { time, type } = state.redoArray.pop();
    switch (type) {
      case "split":
        state.splitsArray.push(time);
        state.splitsArray.sort((a, b) => +a - +b);
        break;
      case "delete":
        state.deleteArray.push(time);
    }
    state.logArray.push({ time, type });
  },
  CACHE_IMAGE_MAPS(state, payload) {
    const { blobUrl, imageMapUrl } = payload;
    state.cacheImageMaps[imageMapUrl] = blobUrl;
  },
  MUTATE_UNITES(state, unites) {
    state.unites = unites;
  },
};

export const getters = {
  group_dictionary: (state) => state.groups.reduce((result, { id, name }) => ({ ...result, [id]: name }), {}),
  getters_get_workspace_by_id: (state) => (id) => state.groups.find((group) => group.id === id),
  is_workflow_running_re_transcript: (state) =>
    EDITOR_STATUS.PROCESSING_RE_TRANSCRIPTION === state.workflow.editorStatus,
  is_workflow_processing_in_editor: (state) =>
    [
      EDITOR_STATUS.PROCESSING_CUT,
      EDITOR_STATUS.PROCESSING_ERASE,
      EDITOR_STATUS.PROCESSING_PATCH_TRANSCRIPTION,
      EDITOR_STATUS.PROCESSING_ROTATE,
      EDITOR_STATUS.PROCESSING_TRIM,
      EDITOR_STATUS.PROCESSING_VOICEOVER,
    ].includes(state.workflow.editorStatus),
  is_workflow_transcoding: (state) => state.workflow.blocked === "transcoding",
  is_workflow_edited: (state) =>
    state.workflow.video && state.workflow.originalVideo && state.workflow.video !== state.workflow.originalVideo.video,
  is_workflow_running_transcript: (state) => state.workflow.speechStatus === "running transcript",
  is_show_popup: (_, getters) => getters.is_workflow_transcoding || getters.is_workflow_running_transcript,
  is_refresh_workflow: (state) => state.isRefreshWorkflow,
  is_workflow_running_segmentation: (state) =>
    ["scheduled", "running"].includes(state.workflow.stepsStatus) &&
    state.workflow.editorStatus === "processing segmentation",
  getters_get_plain_transcription_text: (state) =>
    state.transcription.reduce((prev, cur) => prev + cur.sentence + "\n", ""),
  is_allowed_workflow_keyterms: (state) => isAllowedKeytermsLanguage(state.workflow.languageCode),
  is_workflow_keyterms_ready: (state) => state.isWorkflowKeytermsLoaded && state.workflow.termsStatus,
  is_workflow_termsStatus_in_process: (state) =>
    state.workflow.termsStatus === TERMS_STATUS.IN_PROGRESS || state.workflow.termsStatus === TERMS_STATUS.REPROCESS,
  is_transcription_highlight_keyterms: (state) => state.isTranscriptionHighlightKeyterms,
  transcription_highlight_one_keyterm_value: (state) => state.transcriptionHighlightOneKeytermValue,
  workflow_all_matched_keyterms: (state) => generateAllMatchedKeyterms(state.workflowKeyterms),
  getters_current_unit: (state) => state.unites[state.unitIndex],
  getter_cut_current_time: (state, getters) => {
    if (state.deleteArray.length === 0) return state.currentTime;
    let shouldRemoveTime = 0;
    getters.getter_clips_items.forEach(({ isDeleted, endTime, deleteDuration }) => {
      shouldRemoveTime += isDeleted && endTime <= state.currentTime ? deleteDuration : 0;
    });
    return state.currentTime - shouldRemoveTime;
  },
  getter_cut_video_duration: (state, getters) => {
    if (state.deleteArray.length === 0) return state.videoDuration;
    const totalDeletedTime = getters.getter_clips_items.reduce((total, { isDeleted, deleteDuration }) => {
      return isDeleted ? total + deleteDuration : total;
    }, 0);
    return state.videoDuration - totalDeletedTime;
  },
  getter_image_maps_by_second: (state) => (second) => state.imageMaps[second],
  getter_cache_image_by_image_maps_url: (state) => (imageMapUrl) => state.cacheImageMaps[imageMapUrl],
  getter_clips_items: (state) => {
    let deleteDistance = 0;
    let deleteDuration = 0;
    let accumulatorDeleteTime = 0;
    return state.splitsArray.reduce((accumulator, currentValue, index) => {
      const deleteIndex = state.deleteArray.findIndex((number) => number === currentValue);
      if (deleteIndex !== -1) {
        const endTime = state.deleteArray[deleteIndex];
        const startTime = state.splitsArray[index - 1] || 0;
        deleteDuration = endTime - startTime;
        deleteDistance += convertCurrentTimeIntoWidth(deleteDuration);
        accumulatorDeleteTime += deleteDuration;
      }
      return accumulator.concat({
        startTime: index === 0 ? 0 : accumulator[index - 1].endTime,
        endTime: currentValue,
        isDeleted: deleteIndex !== -1,
        deleteDistance,
        deleteDuration,
        accumulatorDeleteTime,
      });
    }, []);
  },
};

export const actions = {
  fetchEditableGroups: async ({ commit, dispatch }) => {
    const { ok, data } = await fetchGroups({ type: "edit" });
    if (ok) {
      const workspaceIds = data.items.map(({ id }) => id);
      dispatch("workspaceStates/fetchWorkflowCountByWorkspaceIds", workspaceIds, { root: true });
      commit("STORE_GROUPS", data.items);
    }
  },
  storeEditorWorkflow: async ({ commit }, workflow_from_db) => {
    const defaultColumn = {
      editorStatus: "",
    };
    commit("STORE_WORKFLOW", { ...defaultColumn, ...workflow_from_db });
  },
  storeImageMaps({ commit }, imageMap) {
    commit("SET_IMAGE_MAPS", imageMap);
  },
  storeCurrentTime({ commit }, currentTime) {
    commit("SET_CURRENT_TIME", currentTime);
  },
  storeSplitArray({ commit, state }, time) {
    if (isNumber(time)) {
      state.splitsArray.push(time);
      return;
    }
    commit("SET_SPLITS_ARRAY", floor(state.currentTime, 2));
  },
  storeDeleteArray({ commit, getters }) {
    commit("SET_DELETE_ARRAY", { getters });
  },
  storeSelectedIndex({ commit }, index) {
    commit("SET_SELECTED_INDEX", index);
  },
  clearMultiEditorArray({ commit }) {
    commit("CLEAR_MULTI_EDITOR_ARRAY");
  },
  clearPrevWorkflow: ({ commit }) => {
    commit("CLEAR_WORKFLOW");
    commit("RESET_SETTING");
    commit("REFRESH_VIDEO_KEY");
    commit("MUTATE_TRANSCRIPTION");
    commit("CLEAR_WORKFLOW_KEYTERMS");
    commit("CLEAR_VIDEO_EDITING_INFO");
  },
  storeWorkflowTranscription({ commit }, transcription) {
    commit("MUTATE_TRANSCRIPTION", transcription);
  },
  storeEditorWorkflowKeyterms: ({ commit }, workflow) => {
    commit("STORE_WORKFLOW_KEYTERMS", workflow.terms);
    commit("TOGGLE_WORKFLOW_KEYTERMS_LOADED", true);
  },
  storeProcessingPopupType({ commit }, type) {
    commit("SET_PROCESSING_POPUP_TYPE", type);
  },
  storeWorkflowData({ commit }, payload) {
    commit("MUTATE_WORKFLOW_DATA", payload);
  },
  changeKeytermHighlight: ({ commit }, { term = "", isHighlightOn = false }) => {
    commit("TOGGLE_TRANSCRIPTION_HIGHLIGHT_KEYTERMS", isHighlightOn);
    commit("SET_TRANSCRIPTION_HIGHLIGHT_ONE_KEYTERM_VALUE", term);
  },
  changeUnitIndex({ commit }, index) {
    commit("CHANGE_UNIT_INDEX", index);
  },
  toggleRefreshWorkflow({ commit }, isShouldRefresh) {
    commit("TOGGLE_REFRESH_WORKFLOW", isShouldRefresh);
  },
  storeVideoInfo({ commit }, payload) {
    commit("STORE_VIDEO_INFO", payload);
  },
  resetVideoEditingInfo({ commit }) {
    commit("RESET_VIDEO_EDITING_INFO");
  },
  undoCut({ commit }) {
    commit("UN_DO");
  },
  redoCut({ commit }) {
    commit("RE_DO");
  },
  cacheImageMap({ commit }, { imageMapUrl, blobUrl }) {
    commit("CACHE_IMAGE_MAPS", { imageMapUrl, blobUrl });
  },
  dynamicAddUniteByVideoDuration({ commit }, sourceDuration) {
    const duration = typeof sourceDuration !== "number" ? +sourceDuration : sourceDuration;
    if (isNaN(duration)) {
      console.error(`sourceDuration (${sourceDuration}) is invalid. `);
      return;
    }
    const limitations = [
      { maximumDuration: 2400, time: 600, unit: "1hr", splitNumbers: 6, intervalNumber: 10 },
      { maximumDuration: 1800, time: 600, unit: "1hr", splitNumbers: 4, intervalNumber: 10 },
      { maximumDuration: 1200, time: 300, unit: "5min", splitNumbers: 6, intervalNumber: 5 },
      { maximumDuration: 900, time: 300, unit: "5min", splitNumbers: 4, intervalNumber: 5 },
      { maximumDuration: 540, time: 180, unit: "3min", splitNumbers: 5, intervalNumber: 3 },
      { maximumDuration: 360, time: 120, unit: "2min", splitNumbers: 5, intervalNumber: 2 },
      { maximumDuration: 240, time: 60, unit: "1min", splitNumbers: 6, intervalNumber: 2 },
      { maximumDuration: 180, time: 60, unit: "1min", splitNumbers: 4, intervalNumber: 6 },
      { maximumDuration: 120, time: 30, unit: "30s", splitNumbers: 6, intervalNumber: 2 },
      { maximumDuration: 90, time: 30, unit: "30s", splitNumbers: 4, intervalNumber: 3 },
      { maximumDuration: 60, time: 20, unit: "20s", splitNumbers: 5, intervalNumber: 2 },
      { maximumDuration: 40, time: 10, unit: "10s", splitNumbers: 6, intervalNumber: 10 },
      { maximumDuration: 30, time: 10, unit: "10s", splitNumbers: 5, intervalNumber: 10 },
    ];
    const padding = 64;
    const reservedSpace = 40; // prevent last unit would overlay
    const unites = limitations
      .reduce((accumulator, currentValue, index) => {
        const { maximumDuration, time, splitNumbers, intervalNumber } = currentValue;
        if (maximumDuration > duration && limitations.length - 1 !== index) return accumulator;
        const width = round((window.innerWidth - padding - reservedSpace) / splitNumbers);
        return accumulator.concat({ width, time, unit: `${time}s`, intervalNumber });
      }, [])
      .concat([{ width: 100, time: 1 / MULTI_EDITOR_STATUS.DEFAULT_FPS, unit: "1frame" }]);

    commit("MUTATE_UNITES", unites);
  },
};
