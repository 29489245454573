<template>
  <button
    class="DReactBtn text-no-wrap"
    @touchstart.stop.prevent="ontouchstartMainBtn"
    @touchend.stop.prevent="ontouchendMainBtn"
    @click.stop.prevent="onclickMainReactBtn"
    @mouseover="showReactionsTooltip"
    v-click-outside="handleClickOutside"
  >
    <!-- Player Fullscreen (desktop and mobile) in ReactionsInPlayer -->
    <div
      v-if="isMobileFullscreen || !getter_is_window_mode"
      ref="reactButton"
      class="DReactBtn__iconFullscreen"
      :class="{
        'DReactBtn__iconFullscreen--desktop': !getter_is_window_mode,
        'DReactBtn__iconFullscreen--active': currentUserReaction,
      }"
    >
      <div
        v-if="currentUserReaction"
        class="DReactBtn__iconFullscreen--selected"
        :class="{ 'DReactBtn__iconFullscreen--selectedDesktop': !getter_is_window_mode }"
      >
        <img :src="selectedReaction.icon" :alt="selectedReaction.name" />
      </div>
      <icon-base
        v-else
        color="#FFFFFF"
        class="DReactBtn__iconFullscreen--default"
        :class="{ 'DReactBtn__iconFullscreen--defaultDesktop': !getter_is_window_mode }"
      >
        <d-icon-thumbs-up />
      </icon-base>
    </div>

    <!-- Player window mode (desktop and mobile) in WorkflowPlayerMain -->
    <section v-else class="DReactBtn text-no-wrap">
      <div ref="reactButton" class="DReactBtn__icon" :class="{ 'DReactBtn__icon--active': currentUserReaction }">
        <div v-if="currentUserReaction" class="DReactBtn__icon--selected">
          <img :src="selectedReaction.icon" :alt="selectedReaction.name" />
        </div>
        <icon-base v-else color="#FFFFFF" class="DReactBtn__icon--default">
          <d-icon-thumbs-up />
        </icon-base>
      </div>
      <span class="DReactBtn__text" :class="{ 'dBlue--text': currentUserReaction }">{{ $t("player.react") }}</span>
    </section>

    <!-- Tooltip with all possible reactions  -->
    <div
      ref="reactionsTooltip"
      class="DReactBtn__tooltip"
      :class="{
        'DReactBtn__tooltip--show': showTooltip,
      }"
      :style="tooltipStyle"
      @click.stop
      @mouseleave="showTooltip = false"
    >
      <v-layout row no-wrap>
        <v-flex v-for="reaction in reactionSet" :key="reaction.id" xs3>
          <button
            class="DReactBtn__btn"
            @touchstart.stop.prevent
            @touchend.stop.prevent="ontouchendReactBtn(reaction)"
            @click.stop.prevent="clickReactionIcon(reaction)"
          >
            <img :src="reaction.icon" :alt="reaction.name" />
            <div class="DReactBtn__btn--btnName">{{ getReactionName(reaction.name) }}</div>
          </button>
        </v-flex>
      </v-layout>
      <div class="DReactBtn__arrow" :style="arrowStyle"></div>
    </div>
  </button>
</template>

<script>
import IconBase from "@/components/IconBase";
import DIconThumbsUp from "@/components/icons/player/DIconThumbsUp.vue";
import clickOutsideDirective from "@/directives/click-outside-directive.js";
import { getReactionDisplayName } from "@/js/reactions/reactions.js";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "DReactBtn",
  components: { IconBase, DIconThumbsUp },
  props: {
    workflowId: String,
    isMobileDevice: Boolean,
    isMobileFullscreen: Boolean,
  },
  directives: {
    "click-outside": clickOutsideDirective,
  },
  created() {
    this.$nextTick(() => this.setTooltipPosition());
  },
  data() {
    return {
      isLoading: false,
      startTime: null,
      endTime: null,
      pressTime: 500,
      showTooltip: false,
      tooltip: {
        top: null,
        left: null,
        postionCenter: true,
      },
      arrow: {
        left: null,
      },
    };
  },
  methods: {
    ...mapActions("workflowReactions", ["updateReactionByWorkflowId", "deleteReactionByWorkflowId"]),
    ontouchstartMainBtn() {
      this.startTime = new Date().getTime();
    },
    ontouchendMainBtn() {
      this.endTime = new Date().getTime();
      const duration = this.endTime - this.startTime;
      if (duration < this.pressTime) {
        // Short press
        this.onclickMainReactBtn();
      } else {
        // Long press
        this.showReactionsTooltip();
      }
    },
    onclickMainReactBtn() {
      const currentReaction = this.currentUserReaction;
      if (currentReaction) {
        this.onclickReactBtn({ reactionId: currentReaction });
      } else {
        const thumbsUpId = this.reactionSet[0].id;
        this.onclickReactBtn({ reactionId: thumbsUpId });
      }
    },
    ontouchendReactBtn(reaction) {
      this.onclickReactBtn({ reactionId: reaction.id });
      this.showTooltip = false;
    },
    async onclickReactBtn({ reactionId }) {
      if (this.isLoading) return;
      this.isLoading = true;
      if (this.currentUserReaction === reactionId) {
        await this.deleteReactionByWorkflowId({
          workflowId: this.workflowId,
        });
      } else {
        await this.updateReactionByWorkflowId({
          workflowId: this.workflowId,
          reactionId,
          vueInstanceRef: this,
        });
      }
      this.isLoading = false;
    },
    clickReactionIcon(reaction) {
      if (this.isMobileDevice) return;
      this.onclickReactBtn({ reactionId: reaction.id });
      this.showTooltip = false;
    },
    getReactionName(name) {
      return getReactionDisplayName({ name, vueInstanceRef: this });
    },
    setTooltipPosition() {
      const tooltipEle = this.$refs.reactionsTooltip;
      const btn = this.$refs.reactButton;
      if (tooltipEle && btn) {
        const arrowWidth = 8;
        const btnPosition = btn.getBoundingClientRect();
        this.tooltip.top = btnPosition.top - tooltipEle.clientHeight - 12; // 12 the margin between tooltop and button
        this.tooltip.postionCenter = btnPosition.left > tooltipEle.clientWidth / 2;
        if (this.tooltip.postionCenter) {
          this.tooltip.left = btnPosition.left - tooltipEle.clientWidth / 2 + btn.clientWidth / 2;
          this.arrow.left = tooltipEle.clientWidth / 2 - arrowWidth;
        } else {
          this.tooltip.left = btnPosition.left - btn.clientWidth / 2;
          this.arrow.left = btn.clientWidth / 2 + arrowWidth;
        }
        const isOpenedSideMenu = this.getter_is_adjusted_position_by_opened_side_menu(this.$route.name);
        if (isOpenedSideMenu) {
          const SIDE_BAR_WIDTH = 252; // css --side-bar-width: 252px
          this.tooltip.left = this.tooltip.left - SIDE_BAR_WIDTH;
        }
      }
    },
    showReactionsTooltip() {
      this.setTooltipPosition();
      this.showTooltip = true;
    },
    handleClickOutside() {
      this.showTooltip = false;
    },
  },
  computed: {
    ...mapState("workflowReactions", ["reactionSet", "currentUserReaction"]),
    ...mapGetters("workflowPlayer", ["getter_is_window_mode"]),
    ...mapGetters("global", ["getter_is_adjusted_position_by_opened_side_menu"]),
    selectedReaction() {
      return this.reactionSet.find((reaction) => reaction.id === this.currentUserReaction);
    },
    tooltipStyle() {
      return {
        top: this.tooltip.top + "px",
        left: this.tooltip.left + "px",
      };
    },
    arrowStyle() {
      return {
        bottom: "-6px",
        left: this.arrow.left + "px",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$tooltipWidth: 254px;
$iconSize: 40px;
$iconMobile: 32px;
$smallPlayerMaxWidth: 1263px;
.DReactBtn {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 0;
  &__icon {
    height: $iconSize;
    width: $iconSize;
    min-width: $iconSize;
    border-radius: 50%;
    background: #1d1e21;
    border: 1px solid #1d1e21;
    @media screen and (max-width: 599px) {
      height: $iconMobile;
      width: $iconMobile;
      min-width: $iconMobile;
      background: #28292e;
    }
    &--active {
      background: #28292e !important;
      border: 1px solid #33353a !important;
    }
    &--default {
      height: 20px;
      width: 20px;
      margin: 9px 0 0 2px;
      @media screen and (max-width: 599px) {
        height: 16px;
        width: 16px;
        margin: 7px 0 0 1px;
      }
    }
    &--selected {
      padding-top: 7px;
      img {
        height: 24px;
      }
      @media screen and (max-width: 599px) {
        padding-top: 6px;
        img {
          height: 18px;
        }
      }
    }
  }
  &__iconFullscreen {
    height: $iconMobile;
    width: $iconMobile;
    min-width: $iconMobile;
    padding-top: 2px;
    background: transparent;
    border-radius: 50%;
    &--desktop {
      height: $iconSize;
      width: $iconSize;
      min-width: $iconSize;
      @media screen and (max-width: $smallPlayerMaxWidth) {
        height: $iconMobile;
        width: $iconMobile;
        min-width: $iconMobile;
      }
    }
    &--active {
      background-color: #28292e;
      border: 1px solid #33353a;
    }
    &--default {
      height: 24px;
      width: 24px;
    }
    &--defaultDesktop {
      height: 28px;
      width: 28px;
      margin: 3px 0 0 2px;
      @media screen and (max-width: $smallPlayerMaxWidth) {
        height: 20px;
        width: 20px;
        margin: 2px 0 0 1px;
      }
    }
    &--selected {
      padding-top: 4px;
      img {
        height: 18px;
      }
    }
    &--selectedDesktop {
      img {
        height: 24px;
        @media screen and (max-width: $smallPlayerMaxWidth) {
          height: 18px;
        }
      }
    }
  }
  &__text {
    margin-left: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    @media screen and (max-width: 599px) {
      font-size: 14px;
    }
  }
  &__tooltip {
    z-index: 99;
    display: inline-block;
    position: fixed;
    width: $tooltipWidth;
    height: 56px;
    align-items: center;
    background: #28292e;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 4px #00000034;
    border-radius: 28px;
    padding: 12px 15px 0 15px;
    cursor: default;
    opacity: 0;
    pointer-events: none;
    &--show {
      opacity: 1;
      transition: 0.3s;
      pointer-events: auto;
    }
    @media screen and (max-width: 599px) {
      height: 48px;
      padding: 0;
      padding: 8px 0 0 0;
    }
  }
  &__arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #28292e transparent transparent transparent;
  }
  &__btn {
    position: relative;
    height: 32px;
    width: 32px;
    z-index: 1;
    &--btnName {
      position: absolute;
      transform: translateX(calc(-50% + 16px));
      top: -38px;
      height: 22px;
      padding: 4px 8px;
      background: #ffffff;
      border-radius: 11px;
      color: #121314;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s;
    }
    img {
      position: absolute;
      transform: translate(-50%, -50%);
      height: 32px;
    }
    img:hover {
      transition: 0.3s;
      height: 48px;
      @media screen and (max-width: 599px) {
        height: 36px;
      }
    }
    &:hover {
      .DReactBtn__btn--btnName {
        transition: 0.3s;
        opacity: 1;
      }
    }
  }
  &:hover {
    .DReactBtn__icon {
      background: #28292e;
      border: 1px solid #28292e;
    }
  }
}
</style>
