<template>
  <div class="analytics-num-card" :class="{ 'num-card-max4': max4 }">
    <div
      class="white-square"
      @click="$emit('clickCard')"
      :class="{ 'white-16-9': max4, 'isbtn-white-square app-no-select': isBtn, active: selected }"
    >
      <div class="content-div">
        <div class="center-div">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" role="presentation" class="svg-icon">
            <g>
              <slot />
            </g>
          </svg>
          <div v-if="isLoading" class="num-text" :class="{ 'num-text-16-9': max4 }">
            <v-progress-circular :size="24" :width="2" indeterminate color="grey lighten-2"></v-progress-circular>
          </div>
          <p v-else class="num-text" :class="{ 'num-text-16-9': max4 }">
            <span v-if="isTime">{{ time }}</span>
            <span v-else>{{ formatNumber(num) }}</span>
          </p>

          <p class="name-text">
            <slot name="text" v-if="$slots.text" />
            <span v-else>{{ text }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
export default {
  name: "DAnalyticsNumCard",
  props: {
    isBtn: Boolean,
    isLoading: Boolean,
    num: {
      type: Number,
      default: 0,
    },
    text: String,
    numType: String,
    max4: Boolean,
    isTime: Boolean,
    time: String,
    selected: Boolean,
  },
  mounted() {},
  mixins: [],
  data() {
    return {
      isProgressing: true,
    };
  },
  components: {
    IconBase,
  },
  methods: {
    formatNumber(num) {
      // if (this.numType == "time") {
      //   return num;
      // } else {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // }
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.analytics-num-card {
  position: relative;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  box-sizing: border-box;
  width: 20%;
  max-width: 360px;
  padding: 0px 8px 16px 8px;
}
.num-card-max4 {
  width: 25% !important;
  max-width: none !important;
}
.white-square {
  position: relative;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
  /* box-shadow: 0 0 4px 0 rgba(0,0,0,0.15); */
  width: 100%;
  padding-bottom: 100%;
}
.white-16-9 {
  width: 100%;
  /* padding-top: 56.25%; */
  /* 16:9 */
  height: 0px;
  padding-bottom: 56.25%;
}
.isbtn-white-square {
  cursor: pointer;
  pointer-events: auto;
}
.content-div {
  position: absolute;
  height: 100%;
  width: 100%;
}
.center-div {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.svg-icon {
  position: relative;
  height: 48px;
  width: 48px;
  fill: #c4c4c4;
  opacity: 0.5;
}
.num-text {
  position: relative;
  width: 100%;
  height: 56px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 56px;
  text-align: center;
  margin: 24px 0 6px 0;
}
.num-text-16-9 {
  margin: 4px 0 6px 0 !important;
}
.name-text {
  position: relative;
  width: 100%;
  height: 24px;
  color: #919191;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 0;
}
@media screen and (max-width: 599px) {
  .analytics-num-card {
    width: 33.3%;
  }
  .num-card-max4 {
    width: 50% !important;
  }
  .num-text {
    height: 28px;
    font-size: 24px;
    line-height: 28px;
    margin: 2px 0 0px 0;
  }
  .name-text {
    height: 12px;
    font-size: 12px;
    line-height: 12px;
  }
  .svg-icon {
    height: 24px;
    width: 24px;
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .analytics-num-card {
    width: 25%;
  }
  .num-card-max4 {
    width: 33.3% !important;
  }
  .num-text {
    height: 30px;
    font-size: 28px;
    line-height: 30px;
    margin: 2px 0 2px 0;
  }
  .name-text {
    height: 16px;
    font-size: 14px;
    line-height: 16px;
  }
  .svg-icon {
    height: 24px;
    width: 24px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1263px) {
  .analytics-num-card {
    width: 20%;
  }
  .num-card-max4 {
    width: 25% !important;
  }
  .num-text {
    height: 34px;
    font-size: 32px;
    line-height: 34px;
    margin: 6px 0 2px 0;
  }
  .name-text {
    height: 16px;
    font-size: 14px;
    line-height: 16px;
  }
  .svg-icon {
    height: 32px;
    width: 32px;
  }
}
/* @media screen and (min-width: 1264px) and (max-width: 1903px) {
} */
.active {
  cursor: default;
  pointer-events: none;
}
.active,
.isbtn-white-square:hover {
  border: 1px solid #4689f3;
  background-color: #4689f3;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
  transition: 0.3s;
  .svg-icon {
    fill: rgba(255, 255, 255, 0.5);
    transition: 0.3s;
  }
  .num-text {
    color: #fff;
  }
  .name-text {
    color: #fff;
  }
}
</style>
