var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"attachment"},[_c('div',{staticClass:"attachment__header"},[_c('div',{staticClass:"attachment__header--left"},[_vm._v(_vm._s(_vm.$t("all.attachments"))+" ("+_vm._s(_vm.attachmentsNumbers)+")")])]),_vm._v(" "),_c('div',{staticClass:"attachment__body"},_vm._l((_vm.attachments),function(ref){
var title = ref.title;
var buttonText = ref.buttonText;
var type = ref.type;
var data = ref.data;
var isLoading = ref.isLoading;
return _c('div',{key:type,staticClass:"attachment__block"},[_c('div',{staticClass:"attachment__block--title"},[_vm._v(_vm._s(title))]),_vm._v(" "),_c('div',_vm._g({ref:type,refInFor:true,staticClass:"attachment__block--body",class:[type],attrs:{"attachment-type":type}},_vm.v_on_attachment_block_body),[(type === _vm.ADDON_TYPE.DIAGRAM)?_vm._l((data),function(imgURL,diagramIndex){return _c('div',{key:imgURL,ref:"diagram",refInFor:true,attrs:{"diagram-index":diagramIndex,"draggable":!_vm.isPublished && _vm.diagramConfig.deleteDiagramIndex === -1},on:{"dragstart":function($event){_vm.handleDragStart($event, diagramIndex, type)}}},[_c('d-image',{staticClass:"image",attrs:{"aspect-ratio":16 / 9,"src":imgURL,"contain":""},on:{"click":function($event){_vm.diagramConfig.isShowDiagramPopup = true;
                  _vm.diagramConfig.selectedDialogPath = imgURL;}}}),_vm._v(" "),_c('button',{staticClass:"deleteDiagram",on:{"click":function($event){_vm.diagramConfig.deleteDiagramIndex = diagramIndex}}},[_c('icon-base',{attrs:{"width":"26","height":"24"}},[_c('d-icon-circle-trash')],1)],1),_vm._v(" "),_c('div',{staticClass:"confirmOverlay",class:{ active: _vm.diagramConfig.deleteDiagramIndex === diagramIndex }},[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.$t('editor.areYouSureDelete'))}}),_vm._v(" "),_c('div',{staticClass:"mb-auto"},[_c('button',{staticClass:"mr-2",on:{"click":function($event){_vm.diagramConfig.deleteDiagramIndex = -1}}},[_vm._v(_vm._s(_vm.$t("all.no")))]),_vm._v(" "),_c('button',{on:{"click":function($event){_vm.handleDeleteAttachment(type, diagramIndex);
                      _vm.diagramConfig.deleteDiagramIndex = -1;}}},[_vm._v("\r\n                    "+_vm._s(_vm.$t("all.yes"))+"\r\n                  ")])])])],1)}):(type === _vm.ADDON_TYPE.LINKS)?_vm._l((data),function(ref,linkIndex){
                      var link = ref.link;
                      var label = ref.label;
return _c('div',{key:link + label + linkIndex,attrs:{"links-index":linkIndex,"draggable":!_vm.isPublished && _vm.linksConfig.deleteLinkIndex === -1},on:{"dragstart":function($event){_vm.handleDragStart($event, linkIndex, type)}}},[_c('d-icon-link',{staticClass:"icon"}),_vm._v(" "),_c('div',{staticClass:"text",on:{"click":function($event){_vm.openNewTab(link)}}},[_c('p',{staticClass:"mb-0 text--link"},[_vm._v(_vm._s(link))]),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(label))])]),_vm._v(" "),_c('div',{staticClass:"action"},[_c('button',{on:{"click":function($event){_vm.openLinkPopup('edit', linkIndex)}}},[_c('icon-base',{staticClass:"action--pen",attrs:{"width":"18","height":"18","color":"white"}},[_c('d-icon-pen')],1)],1),_vm._v(" "),_c('button',{on:{"click":function($event){_vm.linksConfig.deleteLinkIndex = linkIndex}}},[_c('icon-base',{staticClass:"mr-3 ml-1",attrs:{"width":"26","height":"24"}},[_c('d-icon-circle-trash')],1)],1)]),_vm._v(" "),_c('div',{staticClass:"confirmOverlay",class:{ active: _vm.linksConfig.deleteLinkIndex === linkIndex }},[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.$t('editor.areYouSureDelete'))}}),_vm._v(" "),_c('button',{staticClass:"mx-2",on:{"click":function($event){_vm.linksConfig.deleteLinkIndex = -1}}},[_vm._v(_vm._s(_vm.$t("all.no")))]),_vm._v(" "),_c('button',{on:{"click":function($event){_vm.handleDeleteAttachment(type, linkIndex);
                    _vm.linksConfig.deleteLinkIndex = -1;}}},[_vm._v("\r\n                  "+_vm._s(_vm.$t("all.yes"))+"\r\n                ")])])],1)}):(type === _vm.ADDON_TYPE.PDF)?_vm._l((data),function(ref,pdfIndex){
                    var name = ref.name;
                    var size = ref.size;
                    var link = ref.link;
return _c('div',{key:name + size + link + pdfIndex,attrs:{"pdf-index":pdfIndex,"draggable":!_vm.isPublished && _vm.PDFConfig.deleteIndex === -1},on:{"dragstart":function($event){_vm.handleDragStart($event, pdfIndex, type)}}},[_c('div',{on:{"click":function($event){_vm.PDFConfig.isShowPopup = true;
                  _vm.PDFConfig.pdfLink = link;}}},[_c('d-icon-p-d-f',{staticClass:"icon"}),_vm._v(" "),_c('div',{staticClass:"name"},[_vm._v(_vm._s(name))]),_vm._v(" "),_c('div',{staticClass:"size"},[_vm._v(_vm._s(_vm.formatBytes(size)))])],1),_vm._v(" "),_c('button',{staticClass:"deletePDF",on:{"!click":function($event){_vm.PDFConfig.deleteIndex = pdfIndex}}},[_c('icon-base',{attrs:{"width":"26","height":"24"}},[_c('d-icon-circle-trash')],1)],1),_vm._v(" "),_c('div',{staticClass:"confirmOverlay",class:{ active: _vm.PDFConfig.deleteIndex === pdfIndex }},[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.$t('editor.areYouSureDelete'))}}),_vm._v(" "),_c('div',{staticClass:"mb-auto"},[_c('button',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();_vm.PDFConfig.deleteIndex = -1}}},[_vm._v(_vm._s(_vm.$t("all.no")))]),_vm._v(" "),_c('button',{on:{"click":function($event){$event.stopPropagation();_vm.handleDeleteAttachment(type, pdfIndex);
                      _vm.PDFConfig.deleteIndex = -1;}}},[_vm._v("\r\n                    "+_vm._s(_vm.$t("all.yes"))+"\r\n                  ")])])])])}):_vm._e()],2),_vm._v(" "),_c('button',{staticClass:"attachment__block--action",attrs:{"disabled":isLoading},on:{"click":function($event){_vm.handleClickAdd(type)}}},[_vm._v("\r\n          + "+_vm._s(buttonText)+"\r\n          "),(isLoading)?_c('v-progress-linear',{staticClass:"attachment__block--action-progress",attrs:{"indeterminate":"","color":"primary","height":"2"}}):_vm._e()],1)])})),_vm._v(" "),_c('v-dialog',{model:{value:(_vm.diagramConfig.isShowDiagramPopup),callback:function ($$v) {_vm.$set(_vm.diagramConfig, "isShowDiagramPopup", $$v)},expression:"diagramConfig.isShowDiagramPopup"}},[_c('button',{staticClass:"popup-close",on:{"click":function($event){_vm.diagramConfig.isShowDiagramPopup = false}}},[_c('icon-base',[_c('d-icon-close2')],1)],1),_vm._v(" "),_c('div',{staticClass:"darken-layer-popup",on:{"click":function($event){_vm.diagramConfig.isShowDiagramPopup = false}}}),_vm._v(" "),_c('div',{staticClass:"popup-div"},[_c('d-image',{key:_vm.diagramConfig.selectedDialogPath,staticClass:"popup-img",attrs:{"aspect-ratio":16 / 9,"src":_vm.diagramConfig.selectedDialogPath,"contain":""}})],1)]),_vm._v(" "),_c('input',{ref:"inputFile",attrs:{"type":"file","accept":_vm.inputFileAccept,"hidden":"","multiple":""},on:{"change":_vm.handleFileUpload}}),_vm._v(" "),(_vm.linksConfig.isShowPopupToEditLink)?_c('d-builder-link-popup',{attrs:{"showPopup":_vm.linksConfig.isShowPopupToEditLink,"popupType":_vm.linksConfig.popupType,"selectedLink":_vm.linksConfig.selectedLink,"editIdx":_vm.linksConfig.editIdx},on:{"click-add-btn":_vm.handleLinkChange,"click-edit-btn":_vm.handleLinkChange,"close-popup":function($event){_vm.linksConfig.selectedLink = {};
        _vm.linksConfig.isShowPopupToEditLink = false;},"trackLinkInputBlurByHeap":_vm.trackLinkInputBlurByHeap}}):_vm._e(),_vm._v(" "),_c('d-p-d-f-popup',{attrs:{"p-d-f-link":_vm.PDFConfig.pdfLink},on:{"cleanPDFLink":function($event){_vm.PDFConfig.pdfLink = ''}},model:{value:(_vm.PDFConfig.isShowPopup),callback:function ($$v) {_vm.$set(_vm.PDFConfig, "isShowPopup", $$v)},expression:"PDFConfig.isShowPopup"}}),_vm._v(" "),_c('d-alert',{attrs:{"type":_vm.errorConfig.type,"message":_vm.errorConfig.message},model:{value:(_vm.errorConfig.isShow),callback:function ($$v) {_vm.$set(_vm.errorConfig, "isShow", $$v)},expression:"errorConfig.isShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }