<template>
  <d-player-setting-options
    :options="options"
    valueAttr="code"
    labelAttr="name"
    :defaultValue="subtitlesChoice"
    @change-value="changeSubtitle($event)"
  />
</template>

<script>
import DPlayerSettingOptions from "@/components/DWorkflowPlayer/setting/DPlayerSettingOptions.vue";
import {
  getUniversalLanguageFullName,
  sortLanguageCodeByFullName,
  getLanguageOptionList,
} from "@/js/workflow/language.js";

export default {
  name: "DPlayerSettingSubtitles",
  components: { DPlayerSettingOptions },
  props: {
    isUserSetSubtitleToNull: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    currentStep: {
      type: Number,
    },
    subtitlesChoice: {
      type: String,
    },
  },
  computed: {
    currentSubtitle() {
      return this.steps[this.currentStep].subtitles;
    },
    options() {
      const dictionaryMap = getUniversalLanguageFullName(this.$dictionary);
      const langCodeList = this.currentSubtitle.map(({ language }) => language);
      const sortedLangCodeList = sortLanguageCodeByFullName(langCodeList, dictionaryMap)
      const stepOptions = getLanguageOptionList(sortedLangCodeList, dictionaryMap);
      return [this.OFF_OPTION, ...stepOptions];
    },
  },
  data() {
    return {
      OFF_OPTION: {
        code: null,
        name: this.$t("player.off"),
      },
    };
  },
  methods: {
    changeSubtitle({ value }) {
      const params = { isUserSetSubtitleToNull: value === null, subtitlesChoice: value };
      this.$emit("change-subtitles", params);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>