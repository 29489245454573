// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import firebase from "firebase";
// import 'firebase/firestore'
import store from "./store";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import environment from "../env.js";
import axios from "axios";
import auth from "../auth.js";
import Analytics from "./js/analytics/analytics";
import VEscapeHtml from "@/js/xss/v-escape-html-plugin.js";
import VSyncPlayerTracking from "@/js/player-tracking/sync-player-tracking-plugin.js";
import "@/assets/css/variables.css";

const env = environment.env;
const analyticsType = environment.analyticsType;

//Heap
// window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
const analyticsConfig = environment[analyticsType];
Analytics.initialize(analyticsType, analyticsConfig);
Vue.prototype.$firebaseProxyHost = environment.firebaseProxyHost;
const useFirebaseProxy = environment.backendType == "ali" ? true : false;

Vue.use(VEscapeHtml);
Vue.use(VSyncPlayerTracking);

import VueI18n from "vue-i18n";

let locale = environment.backendType == "ali" ? "zh" : "en";
if (localStorage.getItem("locale")) {
  locale = localStorage.getItem("locale");
}
Vue.prototype.$i18nLocales = ["en", "zh", "es-es", "fr-fr", "pt-br", "ru-ru", "th-th", "ja-jp", "ko-kr"];
Vue.prototype.$i18nUiLocales = {
  en: "English",
  zh: "中文",
  "es-es": "Español",
  "ja-jp": "日本語",
  "de-de": "Deutsche",
  "pt-br": "Português",
  "fr-fr": "Français",
  "th-th": "ไทย",
  "ru-ru": "Pусский",
  "ko-kr": "한국어",
};
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "en",
  escapeParameterHtml: true,
  messages: {
    zh: require("./common/lang/zh"),
    en: require("./common/lang/en"),
    "ja-jp": require("./common/lang/ja-jp"),
    "pt-br": require("./common/lang/pt-br"),
    "fr-fr": require("./common/lang/fr-fr"),
    "es-es": require("./common/lang/es-es"),
    "th-th": require("./common/lang/th-th"),
    "ru-ru": require("./common/lang/ru-ru"),
    "ko-kr": require("./common/lang/ko-kr"),
  },
});

//dictionary
const dictionaryURL =
  environment.backendType == "ali"
    ? "https://public.deephow.net/dh-dictionary.json"
    : "https://storage.googleapis.com/deephow-public/dh-dictionary.json";

axios
  .get(dictionaryURL)
  .then((data) => {
    Vue.prototype.$dictionary = data.data;
  })
  .catch(console.error);

//settings
const settingsURL =
  environment.backendType == "ali"
    ? "https://public.deephow.net/dh-settings.json"
    : "https://storage.googleapis.com/deephow-public/dh-settings.json";

axios
  .get(settingsURL)
  .then((data) => {
    Vue.prototype.$settings = data.data;
  })
  .catch(console.error);
Vue.prototype.$settingsURL = settingsURL;

// Vue.use(Vuetify)
Vue.use(Vuetify, {
  theme: {
    primary: "#4689f4",
    secondary: "#191919",
    accent: "#888888",
    error: "#888888",
    dBlue: "#4689f4",
    dDarkGrey: "#454545",
    dGrey: "#4A4A4A",
    dRed: "#e03535",
    dWhite: "#FBFBFB",
    dDarkBg: "#1E1F22",
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});

Vue.config.productionTip = false;

// if(window.location.hostname.includes("dev")){
// Vue.prototype.$enableText2Speech = true;
// }

if (environment.backendType == "ali") {
  Vue.prototype.$firebase = null;
  Vue.prototype.$db = null;
} else {
  firebase.initializeApp(environment.firebaseAPI[env]);
  const db = firebase.firestore();
  const settings = { experimentalForceLongPolling: true, merge: true };
  db.settings(settings);

  /* Bind firebase to Vue instance */
  Vue.prototype.$firebase = firebase;
  Vue.prototype.$db = db;
}

Vue.prototype.$env = env;
Vue.prototype.$baseUrl = window.location.origin;

Vue.prototype.$backendType = environment.backendType;
Vue.prototype.$databaseType = environment.databaseType;

window.service = Vue.prototype.$service = environment.service[env];
Vue.prototype.$download =
  environment.backendType == "ali" ? environment.download[env].china : environment.download[env];
Vue.prototype.$collectionVersioning = environment.collectionVersioning[env];

const needChangeEndpointKeys = [
  "language",
  "video",
  "player",
  "search",
  "admin",
  "sso",
  "sign",
  "openCV",
  "relatedWords",
  "analytics",
  "viewsLikes",
  "qrcode",
  "recommend",
  "workflow",
  "dataExporter",
];
if (environment.databaseType == "mongoDB") {
  for (const serviceUrlKey in Vue.prototype.$service) {
    if (needChangeEndpointKeys.includes(serviceUrlKey)) {
      if (Vue.prototype.$service.china) {
        Vue.prototype.$service[serviceUrlKey] = Vue.prototype.$service.china[serviceUrlKey];
      }
    }
  }
} else if (useFirebaseProxy) {
  // let service through proxy to ensure will not block by china
  for (const serviceUrlKey in Vue.prototype.$service) {
    if (needChangeEndpointKeys.includes(serviceUrlKey)) {
      Vue.prototype.$service[serviceUrlKey] = Vue.prototype.$service[serviceUrlKey].replace(
        "://",
        "://" + Vue.prototype.$firebaseProxyHost + ":2096/"
      );
    }
  }
}

Vue.prototype.$cachedAvatars = {}; // cache avatars

const eventbus = new Vue();
export default eventbus;

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })
let vueInitialized = false;
auth().onAuthStateChanged((user) => {
  if (user) {
    //TODO: get and set user locale and settings before initVue
    initVue(true);

    auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {})
      .catch(function (error) {
        // Handle error
      });
  } else {
    initVue(false);
  }
});

function initVue(authenticated) {
  if (vueInitialized) {
    return;
  }
  vueInitialized = true;
  new Vue({
    i18n,
    router,
    created() {},
    el: "#app",
    store,
    render: (h) => h(App),
  });
}
