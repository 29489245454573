export function escapeHtml(rawText) {
  if(!rawText){
    return rawText;
  }

  return rawText
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&apos;");
}
