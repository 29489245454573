<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="466px">
    <v-card class="reviewResultPopup">
      <v-card-text class="reviewResultPopup__body">
        <div class="reviewResultPopup__body--icon">
          <icon-base color="#0ef28f" width="63" height="63">
            <d-icon-done />
          </icon-base>
        </div>
        <div class="reviewResultPopup__body--title">{{ $t("alert.success") }}</div>
        <div class="reviewResultPopup__body--desc">{{ $t("builder.alertSendToReviewSuccess") }}</div>
      </v-card-text>
      <v-card-actions>
        <d-back-to-studio-workspace-btn :gen2BtnText="$t('new.goToContentManger')" :workspaceId="workspaceId" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DBackToStudioWorkspaceBtn from "@/components/DEditorBuilder/DBackToStudioWorkspaceBtn.vue";
import DIconDone from "@/components/icons/DIconDone.vue";
import IconBase from "@/components/IconBase.vue";
import { mapGetters } from "vuex";

export default {
  name: "DBuilderReviewerSuccessPopup",
  components: {
    DBackToStudioWorkspaceBtn,
    DIconDone,
    IconBase,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    workspaceId: {
      type: String,
      default: () => "",
      required: false,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters(["getter_is_gen2"]),
  },
};
</script>

<style scoped lang="scss">
.reviewResultPopup {
  --bg-color: #1e1f22;
  --primary-color: #4689f3;
  --body-height: 60vh;
  width: 100%;
  min-height: 416px;
  padding: 48px;
  &.v-card {
    border-radius: 4px;
    background: var(--bg-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    &--icon {
      width: 107px;
      height: 107px;
      border: 3px solid rgba(56, 57, 64, 0.5);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--title {
      margin-top: 24px;
      font-size: 20px;
      color: white;
    }
    &--desc {
      font-size: 16px;
      color: #8d909f;
    }
  }
}
</style>
