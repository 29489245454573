<template>
  <div class="DWorkspaceGroupBreadcrumb" :class="[size]">
    <div
      v-if="!hideArrow"
      class="DWorkspaceGroupBreadcrumb__text DWorkspaceGroupBreadcrumb__text--back"
      @click="clickBack()"
    >
      <icon-base>
        <d-icon-arrow-back />
      </icon-base>
    </div>
    <div
      v-if="isShowOrganization"
      class="DWorkspaceGroupBreadcrumb__text"
      :class="{ organization: size === 'large' }"
      @click="clickOrganization"
    >
      <icon-base v-if="size === 'large'">
        <d-icon-organization />
      </icon-base>
      <span
        class="DWorkspaceGroupBreadcrumb__text"
        :class="{ 'DWorkspaceGroupBreadcrumb__text--clickable': isOrganizationClickable }"
        >{{ $t("all.organization") }}</span
      >
    </div>
    <span v-if="isShowOrganization && workspaceGroupName" class="DWorkspaceGroupBreadcrumb__slash">/</span>
    <div v-if="workspaceGroupName" class="DWorkspaceGroupBreadcrumb__text">
      <icon-base v-if="size === 'large'">
        <d-icon-workspace-group />
      </icon-base>
      <span
        :class="{ 'DWorkspaceGroupBreadcrumb__text--clickable': isWorkspaceGroupClickable }"
        @click="clickWorkspaceGroup"
      >
        {{ workspaceGroupName }}
      </span>
    </div>
    <span v-if="workspaceGroupName && workspaceName" class="DWorkspaceGroupBreadcrumb__slash">/</span>
    <div v-if="workspaceName" class="DWorkspaceGroupBreadcrumb__text">
      <span class="DWorkspaceGroupBreadcrumb__text"> {{ workspaceName }}</span>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconOrganization from "@/components/icons/DIconOrganization.vue";
import DIconWorkspaceGroup from "@/components/icons/DIconWorkspaceGroup.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";

export default {
  name: "DWorkspaceGroupBreadcrumb",
  components: {
    IconBase,
    DIconOrganization,
    DIconWorkspaceGroup,
    DIconArrowBack,
  },
  props: {
    size: {
      type: String,
      default: "medium", // small, large
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    isShowOrganization: {
      type: Boolean,
      default: false,
    },
    isOrganizationClickable: {
      type: Boolean,
      default: false,
    },
    organizationPath: {
      type: String,
      default: "",
    },
    isWorkspaceGroupClickable: {
      type: Boolean,
      default: false,
    },
    workspaceGroupName: {
      type: String,
      default: "",
    },
    workspaceGroupPath: {
      type: String,
      default: "",
    },
    workspaceName: {
      type: String,
      default: "",
    },
    toPath: {
      type: String,
      default: "",
    },
  },
  methods: {
    clickBack() {
      if (this.toPath) {
        this.$router.push(this.toPath);
      }
    },
    clickOrganization() {
      if (this.organizationPath && this.isOrganizationClickable) {
        this.$router.push(this.organizationPath);
      }
    },
    clickWorkspaceGroup() {
      if (this.workspaceGroupPath && this.isWorkspaceGroupClickable) {
        this.$router.push(this.workspaceGroupPath);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.DWorkspaceGroupBreadcrumb {
  &.small {
    --icon-size: 16px;
    --icon-margin-right: 8px;
    --icon-margin-bottom: -3px;
    --title-size: 16px;
    --title-font-weight: 400;
    --title-color: #9397a6;
  }
  &.large {
    --icon-size: 26px;
    --icon-margin-right: 12px;
    --icon-margin-bottom: -5px;
    --title-size: 24px;
    --title-font-weight: 600;
    --title-color: #454545;
  }

  height: var(--icon-size);
  width: 100%;
  display: flex;
  align-items: center;

  &__text {
    font-size: var(--title-size);
    font-weight: var(--title-font-weight);
    color: var(--title-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    svg {
      margin-inline-end: var(--icon-margin-right);
      margin-bottom: var(--icon-margin-bottom);
      height: var(--icon-size);
      width: var(--icon-size);
      color: #c4c4c4;
    }
    &.organization {
      
      svg {
        color: #4a8cf1;
      }
    }
    &--back {
      cursor: pointer;
    }
    &--clickable {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &__slash {
    display: inline-block;
    margin: 0px 4px;
    color: var(--title-color);
  }
}
</style>
