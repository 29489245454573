<template>
  <v-menu
    :content-class="`DDropdown ${dark ? 'DDropdown--dark' : ''}`"
    :disabled="disabled"
    transition="slide-y-transition"
    offset-y
  >
    <div slot="activator" class="DDropdown__btn text-xs-left">
      <v-text-field :dark="dark" ref="dropdownText" v-model="currentText" :rules="[rules.required]" readonly>
        <template slot="label">
          <span class="DDropdown__btnText">{{ label }}</span>
        </template>
      </v-text-field>
      <v-icon small color="#8d909f" class="DDropdown__btnIcon pt-2">keyboard_arrow_down</v-icon>
    </div>
    <template v-if="isWorkspaceOption">
      <d-workspaces-drop-menu
        v-model="currentOption"
        :groupList="options"
        :dark="dark"
        @input="handleWorkspaceMenuEvent($event)"
      />
    </template>
    <template v-else>
      <div class="DDropdown__container">
        <template v-if="searchable">
          <div class="DDropdown__search" @click.stop>
            <div class="DDropdown__search--input">
              <div class="DDropdown__search--icon">
                <icon-base color="#9397A6" width="16" height="16">
                  <d-icon-search />
                </icon-base>
              </div>
              <input
                ref="searchInput"
                :placeholder="searchPlaceholder"
                v-model="searchInput"
                :autotest="searchAutoTest"
              />
              <button v-if="searchInput" class="DDropdown__search--close" @click="searchInput = ''">
                <icon-base width="14" height="14" color="#9397A6">
                  <d-icon-close-2 />
                </icon-base>
              </button>
            </div>
          </div>
          <div v-if="optionItems.length === 0" class="DDropdown__search--noResult text-no-wrap text-truncate">
            - {{ $t("admin.noResultsFound") }} -
          </div>
        </template>
        <div class="DDropdown__options" :class="{ zoomOptionsDropdown: customOptionsStyle }">
          <div
            v-for="option in optionItems"
            :key="option[optionKey]"
            @click="handleOptionSelected(option)"
            class="DDropdown__option"
            :class="{
              'DDropdown__option--selected': currentOption === option[optionKey],
              zoomOptionsDropdown: customOptionsStyle,
            }"
          >
            <slot name="option" :option="option">
              {{ option[optionNameKey] }}
            </slot>
          </div>
        </div>
      </div>
    </template>
  </v-menu>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DWorkspacesDropMenu from "@/components/DSkills/DWorkspacesDropMenu.vue";

export default {
  name: "DDropdown",
  components: {
    DWorkspacesDropMenu,
  },
  props: {
    dark: {
      type: Boolean,
      default: () => true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    searchable: {
      type: Boolean,
      default: () => false,
    },
    searchAutoTest: {
      type: String,
      default: () => "",
    },
    searchPlaceholder: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    defaultOptionVal: {
      type: [String, Number],
    },
    optionKey: {
      type: String,
      require: true,
    },
    optionNameKey: {
      type: String,
      require: true,
    },
    isWorkspaceOption: {
      type: Boolean,
      default: () => false,
    },
    customOptionsStyle: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    IconBase,
    DIconSearch,
    DIconClose2,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
      },
      currentText: "",
      currentOption: null,
      searchInput: "",
    };
  },
  methods: {
    handleOptionSelected(option) {
      this.currentText = option[this.optionNameKey];
      this.currentOption = option[this.optionKey];
      this.$emit("choose-option", option);
    },
    handleWorkspaceMenuEvent(workspaceId = "") {
      const matchOption = this.options.find((option) => option[this.optionKey] === workspaceId);
      if (matchOption) {
        this.handleOptionSelected(matchOption);
      }
    },
  },
  created() {
    /** set default value */
    const matchOption = this.options.find((option) => option[this.optionKey] === this.defaultOptionVal);
    this.currentText = matchOption ? matchOption[this.optionNameKey] : "";
    this.currentOption = this.defaultOptionVal;
  },
  computed: {
    optionItems() {
      if (!this.searchable) {
        return this.options;
      }
      return this.options.filter((option) =>
        option[this.optionNameKey].toLowerCase().includes(this.searchInput.toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.DDropdown {
  --items-bg: #ffffff;
  --input-border-color: #dadada;
  --option-hover-bg: #ececec;
  --text-color: #121314;
  --no-result-text-color: #919191;
  &--dark {
    --items-bg: #2c2d32;
    --input-border-color: #8d909f;
    --option-hover-bg: #ffffff1c;
    --text-color: #ffffff;
  }
  &__btn {
    position: relative;
    width: 100%;
    left: 0;
    height: 46px;
  }
  &__btnText {
    color: #8d909f;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
  }
  &__btnIcon {
    position: absolute;
    right: 0;
    top: 20px;
  }
  &__container {
    background: var(--items-bg);
    max-width: 100%;
    position: relative;
  }
  &__options {
    max-width: 420px;
    max-height: 200px;
    min-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 16px 16px;
    &.zoomOptionsDropdown {
      min-height: auto;
      padding: 0;
    }
  }
  &__option {
    font-size: 14px;
    padding: 0 16px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    color: var(--text-color);
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    text-align: left;
    &:hover {
      background: var(--option-hover-bg);
    }
    &--selected {
      color: #4689f4;
    }
    &.zoomOptionsDropdown {
      border-radius: 0;
    }
  }

  &__search {
    width: 100%;
    height: 72px;
    padding: 16px;
    &--icon {
      position: absolute;
      left: 16px;
      top: 12px;
    }
    &--close {
      position: absolute;
      right: 16px;
      top: 12px;
    }
    &--input {
      position: relative;
      height: 40px;
      border: 1px solid var(--input-border-color);
      border-radius: 20px;
      input {
        position: absolute;
        left: 42px;
        top: 0;
        height: 100%;
        width: calc(100% - 84px);
        font-size: 14px;
        line-height: 16px;
        color: var(--text-color);
        padding-top: 1px;
      }
      input:focus {
        outline-width: 0;
        outline: 0;
      }
      input::placeholder {
        color: var(--no-result-text-color);
        font-size: 14px;
        line-height: 16px;
        font-style: italic;
      }
    }
    &--noResult {
      text-align: left;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 16px;
      color: var(--no-result-text-color);
    }
  }
}
</style>
