<template>
  <div class="searchInput">
    <icon-base class="searchInput__search-icon" iconColor="#52545d">
      <d-icon-search />
    </icon-base>
    <input type="text" ref="searchInput" @keyup.enter="handleSearch" />
    <div
      v-if="isSearchMode"
      @click.self="handleCloseSarch"
      class="searchInput__close"
    />
  </div>
</template>
<script>
import IconBase from "@/components/IconBase.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";

export default {
  name: "DSearchInput",
  components: {
    IconBase,
    DIconSearch,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSearch(e) {
      this.$emit("input", e.target.value);
    },
    handleCloseSarch() {
      this.$emit("input", "");
      this.$refs.searchInput.value = "";
    },
  },
  computed: {
    isSearchMode() {
      return this.value !== "";
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler(value) {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.value = value;
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.searchInput {
  --text-color: #9397a6;
  --border-color: #52545d;
  position: relative;
  &__search-icon {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  &__close {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    &::before,
    &::after {
      --deg: 45deg;
      content: "";
      display: block;
      position: absolute;
      width: 45%;
      height: 1px;
      background-color: currentColor;
      top: 50%;
      left: 50%;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(var(--deg));
    }

    &::after {
      --deg: -45deg;
    }
  }

  input {
    color: var(--text-color);
    width: 100%;
    padding: 16px;
    border-radius: 80px;
    text-indent: 35px;
    font-size: 16px;
    transition: all 0.2s ease-in;
    border: 1px solid var(--border-color);
    &:focus {
      outline: none;
    }
  }
}
</style>
