<template>
  <div class="admin-super-create" v-show="!createHidden">
    <v-icon
      medium
      v-show="!successAdd"
      class="close-btn"
      @click="$emit('closeAdminSuperCreate'), resetFormOrg()"
    >close</v-icon>

    <!-- Success Add -->
    <div v-show="successAdd">
      <v-card flat class="form-card transparent" color="white">
        <img width="70px" height="70px" src="../img/check_circle.png" />
        <p class="subheading font-weight-medium ma-0">Congratulations!</p>
        <p class="subheading font-weight-light ma-0">New Organization was successfully created!</p>
        <v-btn
          round
          depressed
          small
          class="mt-4 px-3 body-2 subheading font-weight-bold white--text text-capitalize"
          color="#4689f4"
          @click="resetFormOrg(), $emit('createUserSuccess')"
        >Done</v-btn>
      </v-card>
    </div>

    <v-layout justify-center>
      <v-flex xs10 sm6 md4>
        <!-- Organization Form -->
        <div v-show="formType=='Organization' && !successAdd">
          <v-card flat ref="formOrganization" class="form-card transparent">
            <h1 class="title-text title text-xs-center">Organization</h1>
            <v-card-text>
              <v-text-field
                ref="organizationName"
                v-model="organizationName"
                :rules="[rules.required]"
                label="Organization Name *"
                required
              ></v-text-field>
              <v-autocomplete
                ref="planType"
                v-model="planType"
                :rules="[rules.required]"
                :items="planTypes"
                label="Plan Type *"
                required
                item-text="planName"
                item-value="planIdx"
              ></v-autocomplete>
              <!-- planType -->
              <v-text-field
                ref="adminName"
                v-model="adminName"
                :rules="[rules.required]"
                label="Organization Admin Name *"
                required
              ></v-text-field>
              <v-text-field
                ref="adminEmail"
                v-model="adminEmail"
                :rules="[rules.email]"
                label="Organization Admin Email"
                required
              ></v-text-field>
              <!-- Phone number -->
              <v-layout row>
                <p class="caption ma-0">Organization Admin Phone</p>
              </v-layout>
              <v-layout row>
                <v-flex xs5>
                  <v-autocomplete
                    ref="selectedCountry"
                    v-model="selectedCountry"
                    :items="countryCodes"
                    label="Country code"
                    :placeholder="' '"
                    prefix="+"
                    @change="getPhoneMask(), getAdminPhone()"
                    class="pr-2"
                    lazy-validation
                  >
                    <template slot="selection" slot-scope="{ item }">{{item.dialCode}}</template>
                    <template slot="prepend-inner">
                      <span class="pt-1 text-uppercase grey--text">{{selectedCountry.iso2}}</span>
                    </template>
                    <template slot="item" slot-scope="{ item }">
                      <v-list-tile-content>
                        <p class="mt-2">{{item.name}}&nbsp;&nbsp;&nbsp;+{{item.dialCode}}</p>
                      </v-list-tile-content>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs7>
                  <v-text-field
                    label="Phone number"
                    :placeholder="placeholderPhone"
                    ref="enteredPhone"
                    v-model="enteredPhone"
                    :mask="maskPhone"
                    :rules="[]"
                    @change="getAdminPhone()"
                    lazy-validation
                    :disabled="noCountryCode()"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <p class="text-xs-left red--text">{{errorPhone}}</p>
        
              <!-- Password -->
              <v-text-field
                ref="adminPassword"
                v-model="adminPassword"
                :append-icon="show1 ? 'visibility' : 'visibility_off'"
                autocomplete="chrome-off"
                label="Admin Password *"
                :rules="[rules.min, rules.required]"
                hint="At least 8 characters"
                counter
                @click:append="show1 = !show1"
              ></v-text-field>

              <p class="text-xs-left red--text">{{errorMessages}}</p>
            </v-card-text>
          </v-card>
          <v-card-actions class="form-btn">
            <v-spacer></v-spacer>
            <v-btn
              outline
              round
              small
              class="px-4 mr-2 body-2 subheading font-weight-bold text-capitalize"
              color="#4689f4"
              @click="resetFormOrg()"
            >Cancel</v-btn>
            <v-btn
              round
              depressed
              small
              class="px-3 body-2 subheading font-weight-bold white--text text-capitalize"
              color="#4689f4"
              @click="submitOrg()"
            >
              Create Organization
              <v-progress-circular
                :size="16"
                :width="2"
                indeterminate
                color="grey lighten-2"
                class="ml-2"
                v-show="isProgressing"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import allCountries from "../js/countryCode.js";
import MixinUser from "./MixinUser.vue";

export default {
  name: "AdminSuperCreate",
  props: {
    createHidden: {
      type: Boolean
    },
    userType: {
      type: String
    },
    formType: {
      type: String
    }
  },
  mixins: [MixinUser],
  data() {
    return {
      //Form Organization
      organizationName: null,
      planTypes: [
        {
          planIdx: "1",
          planName: "plan 1"
        },
        {
          planIdx: "2",
          planName: "plan 2"
        },
        {
          planIdx: "3",
          planName: "plan 3"
        }
      ],
      planType: "",
      adminName: null,
      adminEmail: null,
      adminPhone: null,
      adminPassword: null,

      //From basic
      successAdd: false,
      formHasErrors: false,
      errorMessages: "",
      rules: {
        required: value => !!value || "Required.",
        min: v => (v && v.length >= 8) || "Min 8 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      countryCodes: allCountries,
      selectedCountry: allCountries[0],
      maskPhone: "phone",
      placeholderPhone: "(000) 000 - 0000",
      enteredPhone: "",
      errorPhone: "",
      show1: false,
      password: "Password",
      isProgressing: false
    };
  },
  components: {},
  created() {},
  mounted() {},
  updated() {},
  computed: {
    formOrganization() {
      return {
        organizationName: this.organizationName,
        planType: this.planType,
        adminName: this.adminName,
        adminEmail: this.adminEmail,
        adminPassword: this.adminPassword
      };
    }
  },
  watch: {},

  methods: {
    getAdminPhone() {
      this.adminPhone = "+" + this.selectedCountry.dialCode + this.enteredPhone;
    },
    noCountryCode() {
      const noCountryCode =
        Object.keys(this.selectedCountry).length === 0 &&
        this.selectedCountry.constructor === Object;
      return noCountryCode;
    },
    clearIncompletePhone() {
      if (this.enteredPhone == "" || this.noCountryCode()) {
        this.restPhone();
      }
    },
    getPhoneMask() {
      this.enteredPhone = "";
      switch (this.selectedCountry.iso2) {
        case "us": // United States
          this.maskPhone = "phone";
          this.placeholderPhone = "(###) ### - ####";
          break;
        case "de": //Germany
          this.maskPhone = "#### #### ####";
          this.placeholderPhone = " ";
          break;
        case "br": //Brazil
          this.maskPhone = "## ##### - ####";
          this.placeholderPhone = "## ##### - ####";
          break;
        default:
          //Other countries
          this.maskPhone = "#### #### ####";
          this.placeholderPhone = " ";
      }
    },
    resetFormBasic() {
      this.errorMessages = "";
      this.formHasErrors = false;
      this.successAdd = false;
      this.restPhone();
    },
    restPhone() {
      this.errorPhone = "";
      this.selectedCountry = {};
      this.placeholderPhone = " ";
      this.enteredPhone = "";
      this.adminPhone = "";
    },
    phoneError() {
      switch (this.errorMessages) {
        case "Invalid format.":
          this.errorPhone = "Invalid phone format.";
          this.errorMessages = "";
          break;
        case "TOO_SHORT":
          this.errorPhone = "The phone number is too short.";
          this.errorMessages = "";
          break;
        case "TOO_LONG":
          this.errorPhone = "The phone number is too long.";
          this.errorMessages = "";
          break;
        case "The user with the provided phone number already exists.":
          this.errorPhone =
            "The user with the provided phone number already exists.";
          this.errorMessages = "";
          break;
        default:
          this.errorPhone = "";
      }
    },
    resetFormOrg() {
      this.isProgressing = false;
      Object.keys(this.formOrganization).forEach(f => {
        this.$refs[f].reset();
      });
      this.resetFormBasic();
    },
    submitOrg() {
      this.clearIncompletePhone();
      this.formHasErrors = false;
      Object.keys(this.formOrganization).forEach(f => {
        if (!this.formOrganization[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
      if (!this.formHasErrors) {
        this.isProgressing = true;
        this.submitAddOrg();
      }
    },
    async submitAddOrg() {
      const self = this;
      const idToken = await this.getIdToken();
      const data = {
        email: this.adminEmail,
        password: this.adminPassword,
        displayName: this.adminName,
        organizationName: this.organizationName,
        requestUid: this.$user.uid,
        idToken: idToken
      };
      axios
        .post(self.$service.admin + "admin", {
          token: "rG5kXk0CDbhgF4RBlNoV",
          action: "add",
          type: "new-organization",
          data: data
        })
        .then(function(response) {
          self.errorMessages = response.data;
          self.successAdd = true;
        })
        .catch(function(error) {
          self.errorMessages = error.response.data;
          self.phoneError();
          console.log(error);
          self.isProgressing = false;
        });
    }
  }
};
</script>

<style scoped>
.admin-super-create {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;
  overflow-y: scroll;
  pointer-events: auto;
}

.close-btn {
  position: absolute;
  top: 80px;
  right: 10%;
  pointer-events: auto;
}
.form-card {
  padding-top: 80px;
  pointer-events: auto;
}
.title-text {
  color: #333333;
}
@media screen and (min-width: 40em) {
  .form-btn {
    padding-top: 0;
    padding-bottom: 80px;
    margin: 0;
    position: absolute;
    margin-top: 20px;
    right: 10%;
  }
}
</style>
