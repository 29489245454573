<template>
  <div class="PlayAnnotation">
    <canvas
      id="PlayAnnotation__canvas"
      ref="PlayAnnotation__canvas"
      class="PlayAnnotation__canvas"
      :width="videoData.clientWidth + 'px'"
      :height="videoData.clientHeight + 'px'"
      :style="{opacity: +!isHideCanvas}"
    ></canvas>
  </div>
</template>

  <script>
import { AnnotationFabric } from "@/js/annotation/AnnotationFabric";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash-es";

export default {
  name: "PlayerAnnotation",
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    videoData: {
      type: Object,
      default: () => ({
        clientWidth: 1920,
        clientHeight: 944,
        currentStepIndex: 0,
        currentVideoTime: 0,
      }),
    },
    isHideCanvas: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      AnnotationFabric: null,
    };
  },
  methods: {
    ...mapActions("annotation", ["storeMultipleAnnotation"]),
    // ...mapActions("annotation", ["fetchMultipleAnnotation"]),
    loadAnnotationData(annotations, currentStepIndex) {
      if (
        Array.isArray(annotations) &&
        annotations[currentStepIndex] &&
        this.AnnotationFabric
      ) {
        this.$emit("update:isHideCanvas", true);
        this.AnnotationFabric.loadData(annotations[currentStepIndex].elements);
      }
    },
    initializeFabric(annotation, currentStepIndex) {
      this.AnnotationFabric = new AnnotationFabric({
        canvasId: "PlayAnnotation__canvas",
        sourceCanvas: this.$refs["PlayAnnotation__canvas"],
        isHideCanvas: this.isHideCanvas,
        isPlayMode: true,
      });
      this.loadAnnotationData(annotation, currentStepIndex);
    },
  },
  computed: {
    ...mapState("annotation", ["annotations"]),
    currentStepId() {
      return this.workflow.steps[this.videoData.currentStepIndex];
    }
  },
  watch: {
    "workflow.annotations": {
      immediate: true,
      handler(annotations) {
        if (Array.isArray(annotations) && this.workflow.steps) {
          this.storeMultipleAnnotation({ annotations: annotations.slice(), stepIds: this.workflow.steps });
          
        }
      },
    },
    // TODO: old verison of get Annotations, will remove when new player is stable
    // "workflow.steps": {
    //   immediate: true,
    //   handler(steps) {
    //     if (Array.isArray(steps) && this.$route.name !== "PlayerPublic" && this.$route.name !== "PlayerFullscreenPublic") {
    //       this.fetchMultipleAnnotation(steps);
    //     }
    //   },
    // },
    annotations(annotations) {
      this.loadAnnotationData(annotations, this.videoData.currentStepIndex);
    },
    "videoData.currentStepIndex"(currentStepIndex) {
      this.loadAnnotationData(this.annotations, currentStepIndex);
    },
    "videoData.clientWidth": debounce(function() {
      const { currentVideoTime, clientHeight, clientWidth, currentStepIndex } = this.videoData;
      if (!this.AnnotationFabric) {
        this.initializeFabric(this.annotations, currentStepIndex);
      }
      this.AnnotationFabric.handleResize({ width: clientWidth, height: clientHeight });
      this.AnnotationFabric.loadData(this.annotations[currentStepIndex].elements);
      this.AnnotationFabric.detectIsAnnotationShowUp(
        currentVideoTime,
        this.currentStepId
      );
    }, 500),
    "videoData.currentVideoTime"(currentVideoTime) {
      if (this.AnnotationFabric) {
        this.AnnotationFabric.detectIsAnnotationShowUp(
          currentVideoTime,
          this.currentStepId
        );
        this.$emit("update:isHideCanvas", false);
      }
    },
  },
};
</script>

<style scoped>
.PlayAnnotation {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
}

.PlayAnnotation ::v-deep .canvas-container {
  margin: 0 auto;
}

.PlayAnnotation__canvas {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
