<template>
  <div v-if="showEnterSearch" class="search-msn no-results-box">
    <div class="fullscreen-center">
      <icon-base class="mb-2" width="90" height="90">
        <d-icon-enter-search />
      </icon-base>
      <p class="no-results-found">
        {{$t('explorer.searchStartMsg1')}}
        <!-- What are you looking for? -->
      </p>
      <p class="please-try-searchin">
        ({{$t('explorer.searchStartMsg2')}})
        <!-- (Use the search box above) -->
      </p>
    </div>
  </div>
</template>
<script>
import IconBase from "../IconBase.vue";
import DIconEnterSearch from "../icons/colored/DIconEnterSearch.vue";
export default {
  name: "DNoResult",
  props: {
    showEnterSearch: {
      type: Boolean,
    },
  },
  components: {
    IconBase,
    DIconEnterSearch,
  },
};
</script>

<style scoped>
.search-msn {
  animation: delay 0.7s;
  animation-iteration-count: 1;
  /* Safari and Chrome: */
  -webkit-animation: delay 1\.7s;
  -webkit-animation-iteration-count: 1;
}
@keyframes delay {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes delay {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.no-results-box {
  position: relative;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.no-results-found {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  margin: 0;
}
.please-try-searchin {
  color: #8d909f;
  font-size: 16px;
  text-align: center;
}
</style>