import environment from "./env.js";
import firebase from "firebase";
import authApi from "./src/auth/authApi.js";

const authType = environment.authType;
let authChoice;

const firebaseAuth = function () {
  const features = {
    onAuthStateChanged: function (callback) {
      return firebase.auth().onAuthStateChanged(callback);
    },
    currentUser: {
      getIdToken: function (bool) {
        return firebase.auth().currentUser.getIdToken(bool);
      },
      displayName: "",
      email: "",
      uid: "",
    },
    signOut: function () {
      return firebase.auth().signOut();
    },
    signInWithRedirect: function (provider) {
      return firebase.auth().signInWithRedirect(provider);
    },
    signInWithPopup: function (ssoConfig) {
      const idp = ssoConfig.gcpIdp;
      let provider;

      if (idp == "microsoft") {
        provider = new firebase.auth.OAuthProvider("microsoft.com");
      } else if (idp == "google") {
        provider = new firebase.auth.GoogleAuthProvider();
      } else {
        provider = new firebase.auth.OAuthProvider(idp);
      }

      const customParams = ssoConfig.customParams;
      const scopes = ssoConfig.scopes;

      if (scopes) {
        for (let i = 0; i < scopes.length; i++) {
          provider.addScope(scopes[i]);
        }
      }

      if (customParams) {
        provider.setCustomParameters(customParams);
      }

      console.log(provider);

      return firebase.auth().signInWithPopup(provider);
    },
    signInWithCustomToken: function (token) {
      return firebase.auth().signInWithCustomToken(token);
    },
    signInWithEmailAndPassword: function (email, password) {
      return firebase.auth().signInWithEmailAndPassword(email, password);
    },
  };
  // init currentUser data when they're ready
  if (firebase.auth() && firebase.auth().currentUser) {
    features.currentUser.displayName = firebase.auth().currentUser.displayName;
    features.currentUser.email = firebase.auth().currentUser.email;
    features.currentUser.uid = firebase.auth().currentUser.uid;
  }
  return features;
};

const authingAuth = function () {
  const features = {
    onAuthStateChanged: function (callback) {
      return authApi.onAuthStateChanged(callback);
    },
    currentUser: authApi.currentUser,
    signOut: function () {
      return authApi.logout();
    },
    signInWithRedirect: function (provider) {
      return console.error("Not Implement");
    },
    signInWithPopup: function (ssoConfig) {
      let protocol = ssoConfig.protocol;
      let identifier = ssoConfig.identifier;
      return authApi.loginWithPopup(protocol, identifier);
    },
    signInWithCustomToken: function (token) {
      return console.error("Not Implement");
    },
    signInWithEmailAndPassword: function (email, password) {
      return authApi.loginByEmail(email, password).then((signinInfo) => {
        return {
          user: signinInfo,
        };
      });
    },
  };
  // init currentUser data when they're ready
  authApi.getCurrentUser().then((user) => {
    if (user) {
      features.currentUser.displayName = user.displayName;
      features.currentUser.email = user.email;
      features.currentUser.uid = user.uid;
    }
  });

  return features;
};

if (authType == "authing") {
  authChoice = authingAuth;
} else {
  authChoice = firebaseAuth;
}

export default authChoice;
