<template>
  <section class="user-avatar" :class="{ 'user-avatar-mobile': isMobileDevice }">
    <button class="dh-user-avatar" v-if="displayName" @click="$emit('open-user-profile')">
      <d-avatar
        v-if="userProfile[0]"
        :size="size"
        :userId="userProfile[0].id"
        :key="userProfile[0].changeCount"
        :border="border"
      />
    </button>
  </section>
</template>

<script>
import DAvatar from "@/components/ui_components/DAvatar.vue";

export default {
  name: "UserAvatar",
  props: {
    isMobileDevice: {
      type: Boolean,
    },
    border: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 36,
    },
  },
  created() {},
  mounted() {},
  updated() {},
  data() {
    return {};
  },
  watch: {},
  components: {
    DAvatar,
  },
  // mixins: [MixinUser],
  methods: {
    displayName() {
      if (this.userProfile[0]) {
        if (this.userProfile[0].displayName) {
          return this.userProfile[0].displayName;
        } else {
          return this.userProfile[0].firstName + " " + this.userProfile[0].lastName;
        }
      }
    },
  },
  computed: {
    userProfile() {
      //get user profile from vuex
      return this.$store.state.userProfile;
    },
  },
};
</script>

<style scoped>
.user-avatar {
  position: relative;
  height: 40px;
  width: 40px;
}
.user-avatar-mobile {
  height: 32px;
  width: 32px;
  margin-right: -6px;
  margin-bottom: -2px;
}
.dh-user-avatar {
  position: relative;
  padding: 0 !important;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
