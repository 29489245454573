<template lang="html">
  <section class="mixin-analytics"></section>
</template>

<script>
import axios from "axios";
import MixinUser from "./MixinUser.vue";
import MixinDB from "./MixinDB.vue";
import { csv } from "d3-fetch";

export default {
  name: "mixin-analytics",
  props: [],
  mixins: [MixinUser, MixinDB],
  mounted() {},
  data() {
    return {
      userLikesTracker: {},
    };
  },
  methods: {
    getManySkillPlaylistProgress(organization, playlistId, users) {
      return this.getAnalytics(`skills/${playlistId}/users-progress`, {
        organization: organization,
        users: users,
      });
    },
    getSkillPlaylistProgress(organization, uid) {
      return this.getAnalytics(`skills/assigned`, {
        organization: organization,
        user: uid,
      });
    },
    getSkillWorkflowProgress(organization, uid, playlistId) {
      return this.getAnalytics(`skills/${playlistId}/parts`, {
        organization: organization,
        user: uid,
      });
    },
    async syncIndexedDb() {
      this.$syncPlayerTracking.forceSyncAllTrackingInfoToServer();
    },
    getWorkflowLikes(workflowId) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        self
          .getDocumentByQuery("likes", [
            {
              field: "workflowId",
              comparison: "==",
              value: workflowId,
            },
            {
              field: "like",
              comparison: "==",
              value: true,
            },
            {
              field: "organization",
              comparison: "==",
              value: self.$organization,
            },
          ])
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {});
      });
    },
    //this gets views likes from redis cache
    getViewsLikes(workflows) {
      const self = this;
      const start = Date.now();
      return new Promise(async function (resolve, reject) {
        axios
          .post(self.$service.viewsLikes + "data", {
            token: await self.getIdToken(),
            workflowsId: workflows,
            organization: self.$organization,
          })
          .then(function (response) {
            resolve(response.data.results);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    setView(workflowId, activities) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        const data = {
          token: await self.getIdToken(),
          workflowId: workflowId,
          organization: self.$organization,
        };
        if (activities) {
          data["activities"] = activities;
        }
        axios
          .post(self.$service.viewsLikes + "view", data)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    setLike(workflow, like = true) {
      const self = this;
      self.$store.commit("setUserLikes", { data: [workflow.id], like: like });
      return new Promise(async function (resolve, reject) {
        axios
          .post(self.$service.viewsLikes + "like", {
            token: await self.getIdToken(),
            workflowId: workflow.id,
            organization: self.$organization,
            like: like,
          })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    unlikeWorkflow(workflow) {
      const self = this;
      //block clicking again until backend returns result
      if (this.userLikesTracker[workflow.id]) {
        return;
      }
      this.userLikesTracker[workflow.id] = "dislike";
      if (this.userLikes.includes(workflow.id)) {
        this.setLike(workflow, false).then(() => {
          this.userLikesTracker[workflow.id] = null;
        });
      }
    },
    likeWorkflow(workflow) {
      const self = this;
      //block clicking again until backend returns result
      if (this.userLikesTracker[workflow.id]) {
        return;
      }
      this.userLikesTracker[workflow.id] = "like";
      if (!this.userLikes.includes(workflow.id)) {
        this.setLike(workflow).then(() => {
          this.userLikesTracker[workflow.id] = null;
        });
      }
    },
    readCSVToJSON(file) {
      return new Promise(function (resolve, reject) {
        csv(file)
          .then(function (data) {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAnalytics(endpoint, body) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        const idToken = await self.getIdToken();
        axios
          .post(self.$service.analytics + endpoint, body, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDataExporter(endpoint, body) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        const idToken = await self.getIdToken();
        //console.log(self.$service.dataExporter + endpoint, body);
        axios
          .post(self.$service.dataExporter + endpoint, body, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            //console.log("response.data", response.data);
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getGroupUserStats(organization, groupId) {
      return this.getAnalytics(`workspaces/${groupId}/users`, {
        organization: organization,
      });
    },
    getOneWorkflowStats(organization, workflowId) {
      return this.getAnalytics(`workflows/${workflowId}/users`, {
        organization: organization,
      });
    },
    getOneWOrkflowOverview(organization, workflowId) {
      return this.getAnalytics(`workflows/${workflowId}/overview`, {
        organization: organization,
      });
    },
    getOneUserOverview(organization, uid) {
      return this.getAnalytics(`users/${uid}/overview`, {
        organization: organization,
      });
    },
    getGroupsStats(organization, groups) {
      return this.getAnalytics("workspaces/list", {
        organization: organization,
        workspaces: groups,
      });
    },
    getTopGroups(organization) {
      return this.getAnalytics("workspaces/top", {
        organization: organization,
      });
    },
    getOrgOverview(organization) {
      return this.getAnalytics("organizations/overview", {
        organization: organization,
      });
    },
    getOrgEngagement(organization) {
      return this.getAnalytics("organizations/engagement", {
        organization: organization,
      });
    },
    getUserOverview(organization) {
      return this.getAnalytics("users/overview", {
        organization: organization,
      });
    },
    getUserStats(organization, users) {
      return this.getAnalytics("users/list", {
        organization: organization,
        users: users,
      });
    },
    getUserList({ apiQueryPage, organization, workspace: group, role: userRole }) {
      const workspace = group === "All" ? "" : group;
      const role = userRole === "All" ? "" : userRole;

      apiQueryPage++;

      return this.getAnalytics(`users/list-filter?page=${apiQueryPage}&size=200`, { organization, workspace, role });
    },
    getTopUsers(organization) {
      return this.getAnalytics("users/top", { organization: organization });
    },
    getWorkflowOverview(organization) {
      return this.getAnalytics("workflows/overview", {
        organization: organization,
      });
    },
    getWorkflowStats(organization, workflows) {
      return this.getAnalytics("workflows/list", {
        organization: organization,
        workflows: workflows,
      });
    },
    getUserListExport(organization, workspaceId) {
      return this.getAnalytics("users/list/export", {
        organization: organization,
        workspace: workspaceId,
      });
    },
    getWorkflowListExport(organization, workspaceId) {
      return this.getAnalytics("workflows/list/export", {
        organization: organization,
        workspace: workspaceId,
      });
    },
    getSkillsUsersExport(organization) {
      return this.getDataExporter("skills/users", {
        organization_firestore_id: organization,
      });
    },
    getMinuteSecond(sec) {
      if (!sec || sec === Infinity) return "0:00:00";
      const hours = Math.floor(sec / 3600);
      sec %= 3600;
      const minutes = Math.floor(sec / 60);
      const seconds = Math.floor(sec - minutes * 60);
      const padding = minutes < 10 ? "0" : "";
      const paddingSec = seconds < 10 ? "0" : "";
      return minutes + ":" + paddingSec + seconds;
    },
    getHourMinuteSecond(sec) {
      if (!sec || sec === Infinity) return "0:00:00";
      const hours = Math.floor(sec / 3600);
      sec %= 3600;
      const minutes = Math.floor(sec / 60);
      const seconds = Math.floor(sec - minutes * 60);
      const padding = minutes < 10 ? "0" : "";
      const paddingSec = seconds < 10 ? "0" : "";
      return hours + ":" + padding + minutes + ":" + paddingSec + seconds;
    },
    getHourMinute(sec) {
      if (!sec || sec === Infinity) return "0:00";
      const hours = Math.floor(sec / 3600);
      sec %= 3600;
      const minutes = Math.floor(sec / 60);
      const padding = minutes < 10 ? "0" : "";
      return hours + ":" + padding + minutes;
    },
    getHours(sec) {
      return sec ? Math.round(sec / 3600) : "N/A";
    },
    getNameInitials(name) {
      if (name.length > 0) {
        let initials = "";
        const nameArray = name.split(" ");
        initials = nameArray[0].substring(0, 1).toUpperCase();
        if (nameArray.length > 1) {
          initials += nameArray[nameArray.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      }
    },
    downloadTableAsCsvFile(data, fileName) {
      const universalBOM = "\ufeff";
      // const testText = "简体繁體อักษรไทย한국어Español";
      let csvContent = universalBOM;
      data.forEach(function (infoArray, index) {
        // Building the CSV from the Data two-dimensional array
        // Each column is separated by "," and new line "\n" for next row
        const dataString = infoArray.join(",");
        csvContent += index < data.length ? dataString + "\n" : dataString;
      });
      const a = document.createElement("a");
      let blob = new Blob(
        [
          new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
          csvContent,
        ],
        { type: "text/csv; charset=utf-8" }
      );
      a.href = URL.createObjectURL(blob);
      // a.href ="data:text/csv; charset=utf-8," + encodeURIComponent(csvContent); // only this mime type is supported
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      //////////
      // The download function takes a CSV string, the filename and mimeType as parameters
      // Scroll/look down at the bottom of this snippet to see how download is called
      /////////////////
      // const download = function (content, fileName, mimeType) {
      //     const a = document.createElement("a");
      //     mimeType = mimeType || "application/octet-stream";
      //     if (navigator.msSaveBlob) {
      //     // IE10
      //     navigator.msSaveBlob(
      //         new Blob([content], {
      //         type: mimeType,
      //         }),
      //         fileName
      //     );
      //     } else if (URL && "download" in a) {
      //     //html5 A[download]
      //     a.href = URL.createObjectURL(
      //         new Blob([content], {
      //         type: mimeType,
      //         })
      //     );
      //     a.setAttribute("download", fileName);
      //     document.body.appendChild(a);
      //     a.click();
      //     document.body.removeChild(a);
      //     } else {
      //     location.href =
      //         "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      //     }
      // };
      // download(csvContent, fileName, "text/csv;encoding:utf-8");
    },
    downloadFile(signedUrl, fileName) {
      const a = document.createElement("a");
      a.href = signedUrl;
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  computed: {},
};
</script>
