<template>
  <div class="d-group-menu" :class="{ 'd-group-menu-mobile': isMobileDevice }">
    <div class="close-div" @click="$emit('close-menu')"></div>

    <!-- serach-div-mobile -->
    <div v-if="isMobileDevice" class="serach-div-mobile">
      <div class="search-box-mobile">
        <input
          class="px-2 input-text-mobile"
          :placeholder="$t('admin.searchForWorkspaces')"
          v-model="searchInput"
          autotest="search-search-for-workspace"
        />
        <div class="search-icon-mobile">
          <icon-base color="#9397A6" width="20" height="20">
            <d-icon-search />
          </icon-base>
        </div>
      </div>
    </div>

    <!-- desktop -->
    <div v-else class="serach-div" :class="{ 'serach-div-light': light }">
      <button class="back-btn" @click="$emit('close-menu')">
        <icon-base color="#9397A6" width="24" height="24">
          <d-icon-arrow-back />
        </icon-base>
      </button>
      <div class="search-box" :class="{ 'search-box-light': light, 'search-box-mobile': isMobileDevice }">
        <input
          class="px-2 input-text"
          :class="{ 'input-text-light': light }"
          :placeholder="$t('admin.searchForWorkspaces')"
          v-model="searchInput"
          autotest="search-search-for-workspace"
        />
        <div class="search-icon">
          <icon-base color="#9397A6" width="24" height="24">
            <d-icon-search />
          </icon-base>
        </div>
      </div>
    </div>

    <div class="menu-list" :class="{ 'menu-list-light': light, 'menu-list-mobile': isMobileDevice }">
      <div
        v-for="(group, i) in filterGroupList"
        :key="i"
        class="item-div"
        :class="{ 'current-list-item': currentGroupId == group.id }"
      >
        <div
          class="list-item-text"
          :class="{ 'list-item-text-mobile': isMobileDevice, 'list-item-text-light': light }"
          @click="$emit('update-current-group', group), trackGroup(group.id)"
        >
          <div
            class="private-icon"
            :class="{ 'private-icon-mobile': isMobileDevice }"
            autotest="search-search-workspace-private-icon"
          >
            <icon-base v-if="group.private" color="#9397A6" width="20" height="20">
              <d-icon-lock />
            </icon-base>
          </div>
          <div class="group-name" autotest="search-select-workspace-name">
            <template v-if="group.id && group.id !== 'All' && isShowWorkspaceGroupName">
              <span class="workspace-group-name">{{ getWorkspaceGroupName(group.id) }}</span>
              <span> - </span>
            </template>
            <span>{{ group.name }}</span>
          </div>
          <div class="group-name-icon">
            <span v-if="group.workspaceCount >= 0" class="group-number ml-2" autotest="search-search-workspace-number"
              >({{ group.workspaceCount }})</span
            >
            <span v-if="isNavigator" class="group-number ml-2" autotest="search-search-workspace-number"
              >({{ getters_workspace_published_number(group.id) }})</span
            >
            <icon-base
              v-if="currentGroupId === group.id"
              class="ml-3"
              color="#4689f3"
              height="24"
              width="24"
              autotest="search-select-workspace-check"
            >
              <d-icon-done />
            </icon-base>
          </div>
        </div>
      </div>
      <div
        v-if="filterGroupList.length == 0"
        class="no-result-text"
        :class="{ 'no-result-text-mobile': isMobileDevice, 'no-result-text-light': light }"
      >
        - {{ $t("admin.noResultsFound") }} -
      </div>
      <div class="padding-last-tiem"></div>
    </div>
    <div v-if="!isMobileDevice" class="darken-top" :class="{ 'darken-top-light': light }"></div>
    <div class="darken-bottom" :class="{ 'darken-bottom-light': light }"></div>
  </div>
</template>
<script>
import IconBase from "../IconBase.vue";
import DIconNoResult from "../icons/colored/DIconNoResult.vue";
import DIconDone from "../icons/DIconDone.vue";
import DIconSearch from "../icons/DIconSearch.vue";
import DIconArrowBack from "../icons/DIconArrowBack.vue";
import DIconLock from "../icons/DIconLock.vue";
import MixinAnalytics from "@/components/MixinAnalytics.vue";
import Analytics from "../../js/analytics/analytics";
import { IS_SUPPORT_WORKSPACE_GROUP } from "@/constants/studio-version";
import { mapGetters } from "vuex";

export default {
  name: "DGroupMenu",
  props: {
    labelText: String,
    currentGroupId: String,
    groupDictionary: Object,
    userGroupList: Array,
    isMobileDevice: Boolean,
    isSearch: Boolean,
    light: Boolean,
  },
  components: {
    IconBase,
    DIconNoResult,
    DIconDone,
    DIconSearch,
    DIconArrowBack,
    DIconLock,
  },
  mixins: [MixinAnalytics],
  data() {
    return {
      isSupportWorkspaceGroup: IS_SUPPORT_WORKSPACE_GROUP,
      showMenu: false,
      searchInput: "",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters("navigator", ["getters_workspace_published_number"]),
    ...mapGetters("workspaceGroups", [
      "getter_get_view_workspace_group_by_workspace_id",
      "getter_get_edit_workspace_group_by_workspace_id",
      "getter_get_admin_workspace_group_by_workspace_id",
    ]),
    isStudio() {
      return this.$route.name === "StudioWorkspace";
    },
    isNavigator() {
      return this.$route.name === "NavigatorSearch";
    },
    isSkillsBuilder() {
      const skillsBuilderRoutes = ["SkillsBuilder", "SkillsBuilderUsers"];
      return skillsBuilderRoutes.indexOf(this.$route.name) >= 0;
    },
    filterGroupList() {
      return this.userGroupList.filter((group) => {
        if (group.name.toLowerCase().includes(this.searchInput.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
    },
    isShowWorkspaceGroupName() {
      return this.isSupportWorkspaceGroup;
    },
  },
  methods: {
    trackGroup(groupId) {
      Analytics.setUserGroup(groupId);
    },
    getWorkspaceGroupName(id) {
      const isCanViewList = this.isNavigator || this.isSkillsBuilder;
      let workspaceGroup = isCanViewList
        ? this.getter_get_view_workspace_group_by_workspace_id(id)
        : this.getter_get_edit_workspace_group_by_workspace_id(id);
      if (!workspaceGroup) {
        workspaceGroup = this.getter_get_admin_workspace_group_by_workspace_id(id);
      }
      return (workspaceGroup && workspaceGroup.name) || "";
    },
  },
};
</script>

<style scoped>
.d-group-menu {
  overflow: hidden;
}
.d-group-menu-mobile {
  position: fixed;
  top: 66px;
  left: 0;
  height: calc(100% - 66px);
  width: 100%;
  background-color: #0c0c0e;
}
/* mobile */
.serach-div-mobile {
  position: relative;
  height: 64px;
  width: 100%;
  padding: 16px 30px 0 30px;
}
.search-box-mobile {
  position: relative;
  border-radius: 30px;
  height: 40px;
  background-color: #1e1f22;
  overflow: hidden;
}

.search-icon-mobile {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  padding: 11px 0 0 18px;
}
.input-text-mobile {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: calc(100% - 40px);
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  padding-top: 2px;
}
.input-text-mobile:focus {
  outline-width: 0;
  outline: 0;
}
.input-text-mobile::placeholder {
  opacity: 0.3;
  color: #e4e4e4;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}
/* desktop */
.serach-div {
  position: relative;
  height: 88px;
  width: 100%;
  padding: 30px 28px 0 100px;
  background-color: rgba(12, 12, 14, 1);
}
.serach-div-light {
  background-color: rgba(251, 251, 251, 0.95);
}
.back-btn {
  position: absolute;
  left: 30px;
  height: 48px;
  width: 48px;
  padding-top: 6px;
}
.search-box {
  position: relative;
  max-width: 480px;
  border-radius: 30px;
  height: 48px;
  padding-top: 2px;
  background-color: #1e1f22;
  overflow: hidden;
}
.search-box-light {
  background-color: #eaeaea;
}
.search-icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  padding: 12px 0 0 20px;
}
.input-text {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: calc(100% - 50px);
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 1px;
}
.input-text:focus {
  outline-width: 0;
  outline: 0;
}
.input-text::placeholder {
  opacity: 0.3;
  color: #e4e4e4;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.input-text-light {
  color: #4a4a4a !important;
}
.input-text-light::placeholder {
  color: #4a4a4a;
}
.menu-list {
  position: relative;
  width: 100%;
  text-align: left;
  overflow: auto;
  text-align: left;
  height: calc(100% - 90px);
  padding-top: 24px;
  background-color: #0c0c0eee;
}
.menu-list-light {
  background-color: #f8f8f8ee;
}
.menu-list:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.menu-list-light:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.menu-list-mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px 0 0 16px;
}
.item-div {
  position: relative;
  width: 100%;
}
.list-item-text {
  color: #e4e4e4;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 1.1;
  cursor: pointer;
  padding: 0px 32px 0px 32px;
  margin-bottom: 36px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.group-name {
  display: inline-block;
  max-width: calc(100% - 88px);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
  white-space: pre;
}
.workspace-group-name {
  margin-bottom: -5px;
  display: inline-block;
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-item-text:hover {
  font-weight: 600;
}
.list-item-text-mobile {
  font-size: 24px;
  letter-spacing: 0;
  line-height: 26px;
  padding: 0px 20px 0px 20px;
  margin-bottom: 34px;
}
.list-item-text-light {
  color: #4a4a4a;
}
.group-name-icon {
  position: relative;
  display: inline-block;
}
.no-result-text {
  position: relative;
  color: #ffffff;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 36px;
  padding: 10px 32px 18px 32px;
  opacity: 0.15;
}
.no-result-text-mobile {
  font-size: 18px;
  line-height: 24px;
  padding: 0px 16px;
  text-align: center;
  margin-top: 24px;
}
.no-result-text-light {
  color: #4a4a4a;
}
.current-list-item {
  font-weight: 600;
}
.padding-last-tiem {
  position: relative;
  width: 100%;
  height: 124px;
}
.darken-top {
  position: absolute;
  top: 88px;
  left: 0;
  height: 32px;
  width: calc(100% - 8px);
  background: linear-gradient(0deg, transparent 0%, #0c0c0e 95%);
  pointer-events: none;
}
.darken-top-light {
  background: linear-gradient(0deg, transparent 0%, #fbfbfb 95%);
}
.darken-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 200px;
  width: calc(100% - 8px);
  background: linear-gradient(180deg, rgba(12, 12, 14, 0) 0%, #0c0c0e 95%);
  pointer-events: none;
}
.darken-bottom-light {
  background: linear-gradient(180deg, transparent 0%, #fbfbfb 95%);
}
.close-div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.group-number {
  color: #9397a6;
  font-weight: 400;
}
.private-icon {
  min-width: 68px;
  padding-left: 26px;
}
.private-icon-mobile {
  min-width: 30px;
  padding-left: 0;
}
</style>
