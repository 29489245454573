import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import store from "../store/index";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].admin
    : environment.service[environment.env]["china"].admin;
}

const adminServer = axios.create({
  baseURL: getBaseUrl(),
});
adminServer.interceptors.request.use(
  async config => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  error => Promise.resolve(error)
);

const apiWrapper = apiWrapperGenerator(adminServer);

const fetchWorkspace = async (params = { only_likes: false, keyword: "" }) => {
  try {
    let url = new URL(getBaseUrl() + "v1/groups");
    if (Object.keys(params).length) {
      for (const key in params) {
        if (params.hasOwnProperty(key) && params[key]) {
          url.searchParams.set(key, params[key]);
        }
      }
    }
    const { data } = await adminServer.get("v1/groups" + url.search);
    if (data.success) {
      if (!("keyword" in params)) {
        await store.dispatch("navigator/storeOnlyLikeWorkspaces", data.items);
      } else if (!("only_likes" in params)) {
        await store.dispatch("navigator/storeOnlyKeywordsWorkspaces", data.items);
      } else {
        await store.dispatch("navigator/storeWorkspaceData", data.items);
      }
      return data;
    } else {
      console.error(data.message);
    }
  } catch (e) {
    console.log(e);
  }
};

const fetchWorkspaceByWorkspaceId = async (workspaceId = "") => {
  try {
    const { data } = await adminServer.get(`v1/groups/${workspaceId}`);
    if (data.success) {
      return data;
    } else {
      console.error(data.message);
    }
  } catch (e) {
    console.log(e);
  }
};

const fetchOrganizationLogo = organizationId =>
  apiWrapper("get", `/v1/organization-settings/${organizationId}/logo`);

export { fetchOrganizationLogo, fetchWorkspace, fetchWorkspaceByWorkspaceId };

export default adminServer;
