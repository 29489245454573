import { round } from "lodash-es";
import store from "@/store";
export const convertWidthIntoTime = (offsetLeftDistance) => {
  const { time, width } = store.getters["editor/getters_current_unit"];
  return round(time * (offsetLeftDistance / width), 2);
};

export const convertCurrentTimeIntoWidth = (targetTime) => {
  return convertTimeIntoWidth({ targetTime, currentUnit: store.getters["editor/getters_current_unit"] });
};

const convertTimeIntoWidth = ({ targetTime, currentUnit }) => {
  const { time, width } = currentUnit;
  return width * (targetTime / time);
};

export const calculateFullWidthUnit = ({ videoDuration }) => {
  if (!videoDuration) return;
  const padding = 64;
  const targetWidth = window.innerWidth - padding;
  let index = 1;
  let videoWidth = 0;

  while (index < store.state.editor.unites.length) {
    videoWidth = convertTimeIntoWidth({
      targetTime: videoDuration,
      currentUnit: store.state.editor.unites[index],
    });
    if (videoWidth < targetWidth) {
      index++;
    } else break;
  }
  store.dispatch("editor/changeUnitIndex", index - 1);
};
