<template>
  <section class="user-invite-create">
    <v-card width="466" color="#FFFFFF" class="invite-box fullscreen-center">
      <button class="close-btn" @click="$emit('closeCreateBusiness')" :autotest="getAutotest('close')">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="title-div">
        <icon-base width="120" height="120">
          <d-icon-business />
        </icon-base>
        <div v-if="showForm == 'create-business'">
          <div class="title-text">{{ $t("admin.createBusiness") }}</div>
        </div>
        <div v-if="showForm == 'edit-business'">
          <div class="title-text" autotest="edit-business-name-display">{{ formData.name }}</div>
        </div>
      </div>

      <v-form v-model="formValid" autocomplete="off" :style="{ margin: '48px 24px 36px 24px' }">
        <v-text-field
          autofocus
          v-model="businessName"
          class="create-input"
          :label="$t('admin.businessName') + '*'"
          :rules="[rules.required, rules.noAllSpace, rules.businessNameAllowed]"
          autocomplete="chrome-off"
          @input="madeChanges = true"
          @keydown.enter="$event.preventDefault()"
          :autotest="getAutotest('name-input')"
        ></v-text-field>
      </v-form>
      <v-card-actions class="btn-div">
        <v-spacer></v-spacer>
        <v-btn
          v-if="showForm == 'create-business'"
          depressed
          round
          class="body-2 text-capitalize white--text btn-width"
          color="#4689f4"
          :disabled="!formValid"
          @click="createBusiness()"
          autotest="create-business-create-btn"
          >{{ $t("all.create") }}</v-btn
        >

        <div v-if="showForm == 'edit-business'">
          <v-btn
            depressed
            round
            class="body-2 text-capitalize white--text btn-width"
            color="#4689f4"
            :disabled="disabled"
            @click="updateBusinessName()"
            autotest="edit-business-save-btn"
            >{{ $t("all.save") }}</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
    <!-- Business already in the system. -->
    <d-alert v-model="isExistingBusiness" type="alert" :message="$t('admin.alerBusinessAlreadyInSystem')"></d-alert>

    <!-- Failed to add business. -->
    <d-alert v-model="isAddedFailed" type="alert" :message="$t('admin.alerFailedAddBusiness')"></d-alert>

    <!-- Failed to edit business. -->
    <d-alert v-model="isEditFailed" type="alert" :message="$t('admin.alertFailedEditBusiness')"></d-alert>

    <div class="user-invite-close" @click="$emit('closeCreateBusiness')"></div>
  </section>
</template>

<script>
import MixinDB from "./MixinDB.vue";
import MixinUser from "./MixinUser.vue";
import IconBase from "./IconBase.vue";
import DIconBusiness from "./icons/colored/DIconBusiness.vue";
import DIconClose2 from "./icons/DIconClose2.vue";
import DAlert from "./ui_components/DAlert.vue";
import { checkAdminCommonCharacters } from "@/js/character-validator/character-validator.js";

export default {
  name: "AdminFormBusinessCreateEdit",
  props: {
    formData: Object,
    showForm: String,
    businessData: Array,
  },
  data() {
    return {
      businessName: "",
      // groups: "",
      formValid: false,
      madeChanges: false,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        businessNameAllowed: () => !this.businessNameNotAllowed || this.$t("rules.invalidName"),
      },
      testTest: "",
      isExistingBusiness: false,
      isAddedFailed: false,
      isEditFailed: false,
      businessId: "",
    };
  },
  computed: {
    disabled() {
      return !this.madeChanges || !this.formValid || this.businessName == this.formData.name;
    },
    businessNameNotAllowed() {
      return checkAdminCommonCharacters(this.businessName);
    },
  },
  mixins: [MixinDB, MixinUser],
  components: {
    IconBase,
    DIconBusiness,
    DIconClose2,
    DAlert,
  },
  created() {
    this.setFormForEditor();
  },
  mounted() {},
  methods: {
    getAutotest(tag) {
      if (this.showForm === "edit-business") {
        switch (tag) {
          case "close":
            return "edit-business-popup-close-btn";
          case "name-input":
            return "edit-business-name-input";
          default:
            return "";
        }
      }
      if (this.showForm === "create-business") {
        switch (tag) {
          case "close":
            return "create-business-popup-close-btn";
          case "name-input":
            return "create-business-name-input";
          default:
            return "";
        }
      }
    },
    addBusiness() {
      const self = this;
      return new Promise(function (resolve, reject) {
        const id = self.generateDocId("businesses");
        const data = {
          id: id,
          name: self.businessName,
          organization: self.$organization,
        };
        self
          .addDocument("businesses", id, data)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject();
          });
      });
    },
    setFormForEditor() {
      if (this.showForm == "edit-business" && this.formData) {
        this.businessId = this.formData.id;
        this.businessName = this.formData.name;
      }
    },
    checkExistingBusiness() {
      const existingData = this.businessData.find(
        (item) => item.name.toLowerCase() === this.businessName.toLowerCase()
      );
      return existingData != null && existingData.id != this.formData.id;
    },
    createBusiness() {
      if (this.checkExistingBusiness()) {
        this.isExistingBusiness = true;
        return;
      }
      if (!this.isExistingBusiness) {
        this.addBusiness()
          .then(() => {
            this.$emit("addBusinessSuccess");
            this.$emit("reloadBusinessData");
            this.$emit("closeCreateBusiness");
          })
          .catch((err) => {
            // alert("failed to create business");
            this.isAddedFailed = true;
          });
      }
    },
    updateBusinessName() {
      if (this.checkExistingBusiness()) {
        this.isExistingBusiness = true;
        return;
      }
      this.updateDocument("businesses", this.businessId, {
        name: this.businessName,
      })
        .then(() => {
          this.$emit("editBusinessSuccess");
          this.$emit("reloadBusinessData");
          this.$emit("closeCreateBusiness");
        })
        .catch((err) => {
          // alert("failed to create business");
          this.isEditFailed = true;
        });
    },
  },
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.invite-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px 24px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 24px;
  margin-bottom: 24px;
}
.avatar-placeholder {
  position: relative;
  height: 120px;
  width: 120px;
  background-color: #8d909f;
  color: white;
  font-size: 48px;
  padding-top: 24px;
  border-radius: 60px;
  margin: 0 auto;
}
.user-icon {
  position: absolute;
  top: 7px;
  left: 7px;
}
.title-text {
  color: #52545d;
  font-family: Roboto;
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 4px;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0 28px 0 28px;
}
.subtitle-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
}

.btn-width {
  width: 120px;
}
.btn-div {
  padding: 0;
}
.user-invite-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
</style>
