import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";

const annotationServer = axios.create({
  baseURL:
    environment.backendType === "firebase"
      ? environment.service[environment.env].deephowApiServer
      : environment.service[environment.env]["china"].deephowApiServer,
});

annotationServer.interceptors.request.use(
  async (config) => {
    config.headers[
      "x-user-token"
    ] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

export const postCreateOrUpdateAnnotation = async (annotations) => {
  try {
    const { data } = await annotationServer.post(
      `/v1/annotations/batch-upsert`,
      {
        annotations,
      }
    );
    return { ok: true, data };
  } catch (error) {
    console.log(error);
    return { ok: false, error: error.message };
  }
};

export const postFetchMultipleAnnotations = async (stepIds = []) => {
  try {
    const { data } = await annotationServer.post(
      `/v1/annotations/get-by-steps`,
      { stepIds }
    );
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

export default annotationServer;
