<template>
  <div class="d-admin-asign-users">
    <div class="scroll-all-div">
      <div class="close-bg" @click="$emit('close-share')"></div>
      <div class="popup-div content-center">
        <div class="top-div">
          <div class="top-tabs">
            <d-tabs v-model="currentTab" tabWidth="58" :tabNames="tabNames" light height="48"></d-tabs>
          </div>
          <div class="count-selected-users" autotest="team-add-user-popup-selected-user-count">
            {{ $tc("admin.usersSelected", selectedUsers.length) }}
          </div>
          <button class="close-btn" @click="$emit('close-share')" autotest="team-add-user-popup-close-btn">
            <icon-base color="#8D909F" width="24" height="24">
              <d-icon-close2 />
            </icon-base>
          </button>
        </div>
        <div class="mid-div">
          <div class="mid-scroll-div">
            <div class="search-div">
              <v-text-field
                ref="searchField"
                solo
                flat
                background-color="transparent"
                single-line
                hide-details
                v-model="searchInput"
                class="search-text"
                :placeholder="$t('builder.searchForCategoryName', { name: groupName })"
                autocomplete="chrome-off"
                @input="debounceHandleFindUser"
                autotest="team-add-user-popup-search-bar"
              >
                <template slot="prepend-inner">
                  <icon-base color="#3F3F3F" width="24" height="24" class="mr-3" opacity="0.5">
                    <d-icon-search />
                  </icon-base>
                </template>
              </v-text-field>
            </div>
            <div v-if="users.length > 0 && !allInTeam" class="select-all-btn">
              <button v-if="isSelectedAll" @click="removeAllUsers" autotest="team-add-user-popup-remove-all-btn">
                <span>{{ $t("admin.removeAll") }}</span>
              </button>
              <button v-else @click="selectAllUsers" autotest="team-add-user-popup-select-all-btn">
                <span>{{ $t("admin.selectAll") }}</span>
              </button>
            </div>
            <div ref="userList" class="user-list-style all-user-list" @scroll="onScroll">
              <v-list light dense two-line>
                <div v-if="isSearchingUsers && searchInput.length > 0" class="text-xs-center">
                  <v-progress-circular
                    size="22"
                    width="2"
                    color="dBlue"
                    indeterminate
                    class="mr-2 ml-1 mb-1"
                  ></v-progress-circular>
                  <span class="user-list-email">{{ $t("admin.searchingforUsers") }}</span>
                </div>
                <div
                  v-if="!isSearchingUsers && foundUsers.length == 0 && searchInput.length > 0"
                  class="text-xs-center"
                >
                  <span class="user-list-email">- {{ $t("admin.noResultsFound") }} -</span>
                </div>
                <!-- assignHistory -->
                <!-- <div v-if="!isSearchingUsers&&foundUsers.length==0">
                  <v-list-tile
                    avatar
                    v-for="(user, idx)  in assignHistory"
                    :key="'shared'+idx"
                    class="dropdown-list-item app-no-select"
                    :class="{'added-users':isInAddedArray(excludedUsers, user)}"
                    @click="clickUser(user)"
                  >
                    <v-list-tile-avatar size="40">
                      <d-avatar 
                        :size="40" 
                        :userId="user.id" 
                      />
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <span class="text-capitalize user-list-name">{{user.displayName}}</span>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <span class="user-list-email">{{showIdOrEmail(user)}}</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <button>
                        <icon-base v-if="isInArray(selectedUsers, user)" width="32" height="32">
                          <d-icon-circle-checked-green />
                        </icon-base>
                        <icon-base v-else width="32" height="32" class="add-icon">
                          <d-icon-circle-add />
                        </icon-base>
                      </button>
                    </v-list-tile-action>
                  </v-list-tile>
                </div>-->

                <!-- found users || all users -->
                <div v-for="(user, idx) in users" :key="idx">
                  <v-list-tile
                    avatar
                    class="dropdown-list-item app-no-select"
                    :class="{ 'added-users': isInArray(excludedUsers, user) }"
                    @click="clickUser(user)"
                  >
                    <v-list-tile-avatar size="40">
                      <d-avatar v-if="user.email || user.employeeId" :size="40" :userId="user.id" />
                      <icon-base v-else width="40" height="40">
                        <d-icon-team-avatar />
                      </icon-base>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <span class="text-capitalize user-list-name" autotest="team-add-user-popup-user-name-display">{{
                          user.displayName
                        }}</span>
                      </v-list-tile-title>
                      <v-list-tile-sub-title autotest="team-add-user-popup-user-name-email-display">
                        <span v-if="user.email || user.employeeId" class="user-list-email">{{
                          showIdOrEmail(user)
                        }}</span>
                        <span v-if="user.usersNum != null" class="user-list-email">{{
                          $tc("all.countUsers", user.usersNum)
                        }}</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <button autotest="team-add-user-popup-user-select-btn">
                        <icon-base v-if="isInArray(selectedUsers, user)" width="32" height="32">
                          <d-icon-circle-checked-green />
                        </icon-base>
                        <icon-base v-else width="32" height="32" class="add-icon">
                          <d-icon-circle-add />
                        </icon-base>
                      </button>
                    </v-list-tile-action>
                  </v-list-tile>
                </div>
              </v-list>
            </div>
          </div>

          <!-- selected-user -->
          <div class="mid-scroll-div selected-scroll-div pa-2">
            <div ref="selectedUserDiv" class="selected-user-list user-list-style">
              <v-list light dense two-line>
                <div v-for="(user, idx) in selectedUsers" :key="idx" ref="singleUser">
                  <v-list-tile avatar class="dropdown-list-item app-no-select" @click="clickUser(user)">
                    <v-list-tile-avatar size="40">
                      <d-avatar v-if="user.email || user.employeeId" :size="40" :userId="user.id" />
                      <icon-base v-else width="40" height="40">
                        <d-icon-team-avatar />
                      </icon-base>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <span
                          class="text-capitalize user-list-name"
                          autotest="team-add-user-popup-selected-user-name-display"
                          >{{ user.displayName }}</span
                        >
                      </v-list-tile-title>
                      <v-list-tile-sub-title autotest="team-add-user-popup-selected-user-email-display">
                        <span v-if="user.email || user.employeeId" class="user-list-email">{{
                          showIdOrEmail(user)
                        }}</span>
                        <span v-if="user.usersNum != null" class="user-list-email">{{
                          $tc("all.countUsers", user.usersNum)
                        }}</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <button autotest="team-add-user-popup-selected-user-remove-btn">
                        <icon-base color="#8D909F" width="16" height="16" class="delete-user-icon">
                          <d-icon-close2 />
                        </icon-base>
                      </button>
                    </v-list-tile-action>
                  </v-list-tile>
                </div>
              </v-list>
            </div>
          </div>
        </div>
        <div class="bottom-div center-horizontally">
          <v-spacer></v-spacer>
          <v-btn
            round
            outline
            class="body-2 no-text-transform btn-width"
            color="#4689F3"
            @click="$emit('close-share')"
            autotest="team-add-user-popup-cancel-btn"
            >{{ $t("all.cancel") }}</v-btn
          >
          <v-btn
            depressed
            round
            class="body-2 white--text no-text-transform btn-width"
            color="#4689F3"
            :disabled="selectedUsers.length == 0 || reachedMaxUsers"
            @click="addUsers"
            autotest="team-add-user-popup-assign-btn"
            >{{ $t("skills.assign") }}</v-btn
          >
          <div v-if="reachedMaxUsers" class="max-users-msg">{{ $t("admin.max1000UsersPerTeam") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DTabs from "@/components/ui_components/DTabs.vue";
import DIconUser from "@/components/icons/colored/DIconUser.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconCircleAdd from "@/components/icons/DIconCircleAdd.vue";
import DIconCircleCheckedGreen from "@/components/icons/colored/DIconCircleCheckedGreen.vue";
import DIconArrowUp from "@/components/icons/DIconArrowUp.vue";
import DIconAddUser from "@/components/icons/colored/DIconAddUser.vue";
import DIconTeamAvatar from "@/components/icons/colored/DIconTeamAvatar.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import MixinDB from "@/components/MixinDB.vue";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import { debounce } from "lodash-es";

export default {
  name: "AdminWorkspaceTeamUserPopup",
  props: {
    excludedUsers: Array,
    groupId: String,
    groupDictionary: Object,
  },
  mounted() {
    this.$nextTick(() => this.$refs.searchField.focus());
  },
  created() {
    this.tabNames = [this.$t("all.users")];
    const clearUsers = true;
    this.getMoreUsers(clearUsers);
  },
  mixins: [MixinAdmin, MixinDB],
  data() {
    return {
      currentTab: 0,
      tabNames: [],
      isSuccess: false,
      searchInput: "",
      foundUsers: [],
      selectedUsers: [],
      autoGrowHack: false,
      scrollTop: 0,
      maxScrollTop: 0,
      isSearchingUsers: false,
      showTooltip: false,
      assignHistory: [],
      showOneLine: true,
      overflowNum: 0,
      showMoreUsers: false,
      userGroupList: [],
      maxUsersNum: 1000,
    };
  },
  components: {
    IconBase,
    DTabs,
    DIconUser,
    DIconSearch,
    DIconClose2,
    DIconCircleAdd,
    DIconCircleCheckedGreen,
    DIconArrowUp,
    DIconAddUser,
    DIconTeamAvatar,
    DAvatar,
  },
  methods: {
    showIdOrEmail(user) {
      if (user.employeeId) {
        return user.employeeId;
      } else {
        return user.email;
      }
    },
    clickUser(user) {
      const idx = this.selectedUsers.findIndex((item) => item.id === user.id);
      if (idx === -1) {
        return this.selectedUsers.unshift(user);
      }
      this.selectedUsers.splice(idx, 1);
    },
    selectAllUsers() {
      this.selectedUsers.unshift(
        ...this.users.filter((user) => {
          return ![...this.excludedUsers, ...this.selectedUsers].some((item) => item.id === user.id);
        })
      );
    },
    removeAllUsers() {
      this.selectedUsers.forEach((user) => {
        const idx = this.users.findIndex((item) => item.id === user.id);
        if (idx !== -1) {
          this.users.splice(idx, 1);
        }
      });
    },
    onScroll() {
      if (this.$refs.userList) {
        this.scrollTop = this.$refs.userList.scrollTop;
        this.maxScrollTop = this.$refs.userList.scrollHeight - this.$refs.userList.clientHeight - 1;
        if (this.scrollTop >= this.maxScrollTop) {
          this.getMoreUsers();
          this.maxScrollTop = this.$refs.userList.scrollHeight - this.$refs.userList.clientHeight;
        }
      }
    },
    getMoreUsers(clearUsers) {
      const options = { organization: this.$organization, group: this.groupId };
      if (clearUsers) {
        options.clearUsers = true;
      }
      this.$store.dispatch("getGroupUsers", options);
      this.$store.subscribe(
        function (mutation, state) {
          if (mutation.type == "getGroupUsersEnd") {
            //loading finished
          }
        }.bind(this)
      );
    },
    debounceHandleFindUser: debounce(async function () {
      await this.handleFindUser();
    }, 500),
    handleFindUser() {
      const searchInput = this.searchInput;
      this.isSearchingUsers = true;
      this.$refs.userList.scrollTop = 0;
      this.findUser(searchInput, this.groupId, false)
        .then((response) => {
          const query = JSON.parse(response.config.data).text;
          if (searchInput == query) {
            const results = response.data.results;
            const fResults = this.formatUserSearchResults(results);
            if (!fResults.length) {
              this.noSearchResults = true;
            } else {
              this.noSearchResults = false;
            }
            this.foundUsers = fResults.splice(0, 100);
            this.isSearchingUsers = false;
          }
        })
        .catch((err) => {});
    },
    addUsers() {
      this.$emit("add-users", this.selectedUsers);
      this.$emit("close-share");
    },
    saveAssignHistory() {
      this.selectedUsers.forEach((user) => {
        const idx = this.assignHistory.findIndex((item) => item.id === user.id);
        if (idx === -1) {
          this.assignHistory.unshift(user);
          return (this.assignHistory = this.assignHistory.slice(0, 10));
        }
        this.assignHistory.unshift(this.assignHistory.splice(idx, 1)[0]);
      });
      localStorage.setItem("assignHistory" + this.$user.uid, JSON.stringify(this.assignHistory));
    },
    isInArray(arr, user) {
      return arr.some((item) => item.id === user.id);
    },
  },
  computed: {
    isSelectedAll() {
      // Using reverse logic in this code to avoid iterating over every element, any unselected user in the array, returning the result immediately.
      return !this.users.some((user) => {
        return ![...this.excludedUsers, ...this.selectedUsers].some((item) => item.id === user.id);
      });
    },
    groupName() {
      if (this.groupDictionary && this.groupDictionary[this.groupId]) {
        let name = this.groupDictionary[this.groupId];
        return name;
      } else {
        return "";
      }
    },
    users() {
      const shareAccountEmailRegex = new RegExp(
        `${this.$clientKey}.shared.(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@deephow.ai$`
      );
      const isNotSharedAccount = (user) => !shareAccountEmailRegex.test(user.email);
      if (this.searchInput) {
        return this.foundUsers.filter(isNotSharedAccount);
      } else {
        return this.$store.state.groupUsers.filter(isNotSharedAccount);
      }
    },
    reachedMaxUsers() {
      if (this.excludedUsers.length + this.selectedUsers.length > this.maxUsersNum) {
        return true;
      }
      return false;
    },
    allInTeam() {
      // Using reverse logic in this code to avoid iterating over every element, any unselected user in the array, returning the result immediately.
      return !this.users.some((user) => {
        return !this.excludedUsers.some((item) => item.id === user.id);
      });
    },
  },
};
</script>

<style scoped>
.d-admin-asign-users {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
}
.d-admin-asign-users:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.scroll-all-div {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 806px;
  min-width: 1002px;
}
.popup-div {
  position: relative;
  height: 766px;
  width: 962px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}
.content-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
.top-div {
  position: relative;
  border-bottom: 1px solid #dcdcdc;
  height: 80px;
  width: 100%;
  overflow: hidden;
}
.top-tabs {
  position: absolute;
  top: 1px;
  padding-top: 32px;
  left: 14px;
}
.mid-div {
  position: absolute;
  top: 80px;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 164px);
}
.bottom-div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  height: 84px;
}
.mid-scroll-div {
  position: relative;
  width: 50%;
  height: 100%;
}
.count-selected-users {
  position: absolute;
  left: 50%;
  bottom: 14px;
  color: #52545d;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}
.close-btn {
  position: absolute;
  top: 28px;
  right: 20px;
  z-index: 1;
}
.share-title {
  color: #52545d;
  font-family: Roboto;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
}
.search-div {
  position: relative;
  height: 48px;
  border-radius: 36px;
  border: 1px solid #dcdcdc;
  padding: 0px;
  margin: 16px;
}
.search-text ::v-deep .v-text-field__slot input {
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  line-height: 26px;
}
.search-text ::v-deep .v-text-field__slot input::placeholder {
  opacity: 0.5;
  color: #919191;
  font-family: Roboto;
  font-size: 20px;
  line-height: 26px;
}
.selected-users {
  position: absolute;
  top: 294px;
  left: 22px;
  text-align: left;
  overflow: hidden;
  padding: 0 44px 0 0;
  width: calc(100% - 30px);
  opacity: 0;
  height: 30px;
  transition: 0.3s;
  pointer-events: none;
}
.show-one-line {
  opacity: 1;
  height: 30px;
  transition: 0.3s;
  pointer-events: auto;
}
.show-all-lines {
  height: auto;
  max-height: 90px;
  overflow: auto;
  transition: 0.3s;
  pointer-events: auto;
}
.show-all-lines::-webkit-scrollbar {
  width: 3px;
}
.show-all-lines::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #4a4a4a15;
}
.show-all-lines::-webkit-scrollbar-thumb {
  background-color: #9b9b9b75;
  border-radius: 2px;
}
.selected-single-user {
  position: relative;
  background-color: #1314151a;
  margin-right: 4px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  border-radius: 12px;
  height: 24px;
  display: inline-block;
  margin-bottom: 6px;
  padding-left: 8px;
}
.delete-user-btn {
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 12px;
}
.delete-user-icon {
  margin-bottom: -1px;
}
.select-all-btn {
  position: absolute;
  height: 24px;
  right: 27px;
  top: 76px;
  color: #4a90e2;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
  cursor: pointer;
  z-index: 1;
}
.all-user-list {
  position: absolute;
  height: calc(100% - 110px);
  width: calc(100% - 16px);
  top: 102px;
  left: 8px;
}

.user-list-style {
  overflow-y: scroll;
  overflow-x: hidden;
  transition: 0.3s;
}
.user-list-style::-webkit-scrollbar {
  width: 3px;
}
.user-list-style::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #4a4a4a15 !important;
}
.user-list-style::-webkit-scrollbar-thumb {
  background-color: #9b9b9b75;
  border-radius: 2px;
}
.selected-scroll-div {
  background-color: #f9f9f9;
}
.selected-user-list {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.user-list-name {
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
.user-list-email {
  color: #8d909f;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}
.no-text-transform {
  text-transform: none !important;
}
.user-list-style ::v-deep .theme--light.v-list {
  background-color: transparent !important;
}
.dropdown-list-item {
  margin-bottom: 12px;
}
.dropdown-list-item:hover {
  background-color: #9b9b9b1c !important;
}
.added-users {
  opacity: 0.4;
  pointer-events: none !important;
}
.add-icon {
  color: #52545d;
  opacity: 0.5;
}
.add-icon:hover {
  opacity: 1;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.btn-width {
  width: 160px;
}
.max-users-msg {
  position: absolute;
  text-align: right;
  right: 8px;
  bottom: 4px;
  font-size: 10px;
  color: grey;
}
.close-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>
