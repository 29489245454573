<template>
  <section class="user-invite-create" @click="hideFindUser()">
    <v-card width="466" color="#FFFFFF" class="invite-box fullscreen-center">
      <button class="close-btn" @click="$emit('closeAdminInvite')">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>
      <!-- Add User Form -->
      <div v-if="addedUserSuccess == 'not-yet'">
        <div class="title-div">
          <icon-base width="120" height="120">
            <d-icon-add-user />
          </icon-base>
          <div class="title-text">{{ $t("admin.addUserToWorkspace") }}</div>
          <!-- <v-btn
            depressed
            round
            class="body-2 text-capitalize white--text btn-width"
            color="#4689f4"
            @click="selectFile()"
          >CSV</v-btn> -->
          <input hidden type="file" accept=".csv" ref="fileUploader" @change="handleFileUpload()" />
        </div>
        <d-tabs v-model="currentTab" tabWidth="144" :tabNames="tabNames" light height="64"></d-tabs>

        <v-form v-model="formValid" autocomplete="off" :style="{ margin: '36px 24px 24px 24px' }">
          <div class="name-input-div">
            <v-text-field
              ref="firstField"
              v-model="fullName"
              class="create-input"
              :label="$t('admin.fullName') + '*'"
              :rules="[rules.required, rules.noAllSpace]"
              autocomplete="chrome-off"
              @mouseover="mouseOverName = true"
              @mouseleave="mouseOverName = false"
              @keyup.delete="handleFindUserDelete()"
              @keyup="debounceHandleFindUser($event)"
              @blur="hideFoundUsers()"
            ></v-text-field>

            <v-list v-show="showFoundUsers && fullName.length > 0" class="dropdown-list-scroll">
              <v-list-tile v-if="isSearchingUsers">
                <v-list-tile-title>
                  <v-progress-circular
                    size="22"
                    width="2"
                    color="dBlue"
                    indeterminate
                    class="mr-2 ml-1 mb-1"
                  ></v-progress-circular>
                  <span class="grey--text">{{ $t("admin.searchingforUsers") }}</span>
                </v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                avatar
                v-for="(user, i) in foundUsers"
                :key="i"
                class="dropdown-list-item"
                @click="clickFindUser(user)"
              >
                <v-list-tile-avatar size="40">
                  <d-avatar :size="40" :userId="user.id" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title class="text-capitalize">{{ user.displayName }}</v-list-tile-title>
                  <v-list-tile-sub-title class="grey--text">{{ getUserEmailFormat(user) }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile v-if="!isSearchingUsers && foundUsers.length == 0">
                <v-list-tile-title>
                  <span class="grey--text">{{ $t("admin.noResultsFound") }}</span>
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </div>
          <v-text-field
            v-if="currentTab == 0"
            v-model="email"
            class="create-input"
            :label="$t('all.email') + '*'"
            :rules="[rules.required, rules.noWhiteSpace, rules.emailAllowed, rules.max100]"
            autocomplete="chrome-off"
            @keydown.native="noSpace($event)"
            @input="toLowerCase()"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="employeeId"
            class="create-input"
            :label="$t('all.employeeId') + '*'"
            :rules="[rules.invalidSharedAccount, rules.required, rules.validID, rules.noWhiteSpace, rules.max100]"
            autocomplete="chrome-off"
            @keydown.native="noSpace($event)"
            @input="toLowerCase()"
          ></v-text-field>
          <v-select
            :items="roleOptions"
            v-model="role"
            dense
            :label="$t('all.role') + '*'"
            :rules="[rules.required]"
            :disabled="currentTab == 1"
            class="text-capitalize role-select"
          >
            <template slot="selection" slot-scope="props">
              <span class="text-capitalize">{{ roleDictionary[props.item] }}</span>
            </template>
            <template slot="item" slot-scope="props">
              <span class="text-capitalize">{{ roleDictionary[props.item] }}</span>
            </template>
          </v-select>
        </v-form>
        <v-card-actions class="btn-div">
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentTab == 0"
            depressed
            round
            class="body-2 text-capitalize white--text btn-width"
            color="#4689f4"
            :disabled="!formValid || justClicked"
            @click="inviteUser()"
            >{{ $t("all.add") }}</v-btn
          >
          <v-btn
            v-else
            depressed
            round
            class="body-2 text-capitalize white--text btn-width"
            color="#4689f4"
            :disabled="!formValid || justClicked"
            @click="createNoEmailUser()"
            >{{ $t("all.create") }}</v-btn
          >
        </v-card-actions>
      </div>
      <!-- Add User Success -->
      <div v-else>
        <div class="title-div">
          <d-icon-add-user-success width="120" height="120" />
          <div v-if="addedUserSuccess == 'has-email'">
            <div class="title-text">{{ $t("admin.popupTitleNewUserInvited") }}</div>
            <div class="subtitle-text lower-lineheight">
              <span class="text-capitalize">{{ fullName }}</span>
              ({{ email }})
              <span v-html="$t('admin.popupMsgNewUserInvitedOrg')"></span>
              <!-- was invited. <br>After validating the account he/she will have access to this Workspace. -->
            </div>
          </div>

          <div v-if="addedUserSuccess == 'no-email'">
            <div class="title-text">{{ $t("admin.popupTitleNewUserCreated") }}</div>
            <div class="subtitle-text">
              <span class="text-capitalize">{{ fullName }}</span>
              ({{ employeeId }})
              <br />
              {{ $t("admin.popupMsgExistingUserInvited") }}
              <!-- was added to this Workspace. -->
            </div>
          </div>

          <div v-if="addedUserSuccess == 'added-existing'">
            <div class="title-text">{{ $t("admin.popupTitleExistingUserInvited") }}</div>
            <div class="subtitle-text">
              <span class="text-capitalize">{{ fullName }}</span>
              ({{ email }}{{ employeeId }})
              <br />
              {{ $t("admin.popupMsgExistingUserInvited") }}
              <!-- was added to this Workspace. -->
            </div>
          </div>
        </div>
        <v-card-actions class="btn-div">
          <v-layout xs12 mt-4>
            <v-flex xs6 mr-2>
              <v-btn
                outline
                depressed
                round
                block
                class="body-2 no-text-transform"
                color="#4689f4"
                @click="addAnoteruser()"
                >{{ $t("admin.popupBtnAddAnotherUser") }}</v-btn
              >
            </v-flex>
            <v-flex xs6 ml-2>
              <v-btn
                depressed
                round
                block
                class="body-2 white--text no-text-transform"
                color="#4689F3"
                @click="$emit('closeAdminInvite')"
                >{{ $t("builder.done") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card-actions>
      </div>
    </v-card>
    <d-alert v-model="isExistingUser" type="alert" :message="alertMessage"></d-alert>
    <d-alert v-model="isPendingInvitation" type="alert" :message="$t('admin.alertPendingInvitation')"></d-alert>
    <d-alert v-model="alert.show" :type="alert.type" :message="alert.alertText"></d-alert>
  </section>
</template>

<script>
import MixinDB from "./MixinDB.vue";
import MixinUser from "./MixinUser.vue";
import MixinAdmin from "./MixinAdmin.vue";
import MixinAnalytics from "./MixinAnalytics.vue";
import IconBase from "./IconBase.vue";
import DIconClose2 from "./icons/DIconClose2.vue";
import DTabs from "./ui_components/DTabs.vue";
import DIconAddUser from "./icons/colored/DIconAddUser.vue";
import DIconAddUserSuccess from "./icons/colored/DIconAddUserSuccess.vue";
import DAlert from "./ui_components/DAlert.vue";
import axios from "axios";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import { addUserToWorkspace } from "@/js/user-role/user-role.js";
import { debounce } from "lodash-es";
import { checkEmailCharacters } from "@/js/character-validator/character-validator.js";
import { mapGetters } from "vuex";

export default {
  name: "AdminFormAddUser",
  props: {
    showForm: String,
    groupName: String,
    groupId: String,
    groupLocale: String,
    allRoleOptions: Array,
    showAllGroups: Boolean,
  },
  watch: {
    currentTab: function () {
      if (this.currentTab == 0) {
        // for email user
        this.role = "viewer";
        this.roleOptions = this.allRoleOptions;
        this.employeeId = "";
      } else {
        // for id user
        this.role = "viewer";
        this.roleOptions = ["viewer"];
        this.email = "";
      }
      this.$nextTick(() => this.$refs.firstField.focus());
    },
  },
  data() {
    return {
      fullName: "",
      email: "",
      employeeId: "",
      role: "viewer",
      showPassword: false,
      groupOptions: [],
      group: "",
      groupAdded: {},
      roleOptions: [],
      formValid: false,
      rules: {
        max100: (v) => (v && v.length <= 100) || this.$t("rules.max100"),
        min: (v) => (v && v.length >= 6) || this.$t("rules.min6"),
        validID: (value) => {
          const pattern = /^(?!.*@).*$/;
          const startWithShared = /^shared\..*/;
          return (!startWithShared.test(value.toLowerCase()) && pattern.test(value)) || this.$t("auth.alertInvalidId");
        },
        noWhiteSpace: (value) => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        required: (value) => !!value || this.$t("rules.required"),
        emailAllowed: () => !this.emailNotAllowed || this.$t("rules.invalidEmail"),
        invalidSharedAccount: (value) => {
          return !value.toLowerCase().endsWith(".shared") || this.$t("rules.invalidEmployeeID");
        },
      },
      currentTab: 0,
      tabNames: [this.$t("admin.companyEmail")],
      addedUserSuccess: "not-yet",
      existingUserName: "",
      roleDictionary: {
        viewer: this.$t("all.viewer"),
        publisher: this.$t("all.publisher"),
        "admin-group": this.$t("all.workspaceAdmin"),
        "admin-organization": this.$t("all.organizationAdmin"),
      },
      isExistingUser: false,
      alertMessage: "",
      isPendingInvitation: false,
      foundUsers: [],
      showFoundUsers: false,
      isSearchingUsers: false,
      mouseOverName: false,
      currentFoundUsers: [],
      justClicked: false,
      alert: {
        alertText: "",
        type: "",
        show: false,
      },
    };
  },
  computed: {
    ...mapGetters("permission", ["getter_has_employee_id_login"]),
    emailNotAllowed() {
      return checkEmailCharacters(this.email);
    },
  },
  mixins: [MixinDB, MixinUser, MixinAdmin, MixinAnalytics],
  components: {
    IconBase,
    DIconClose2,
    DTabs,
    DIconAddUser,
    DIconAddUserSuccess,
    DAlert,
    DAvatar,
  },
  created() {
    this.roleOptions = this.allRoleOptions;
  },
  mounted() {
    if (this.getter_has_employee_id_login) {
      this.tabNames.push(this.$t("admin.employeeIdOnly"));
    }
    this.$nextTick(() => this.$refs.firstField.focus());
  },
  methods: {
    hideFoundUsers() {
      setTimeout(() => {
        this.showFoundUsers = false;
      }, 500);
    },
    toLowerCase() {
      this.email = this.email.toLowerCase();
      this.employeeId = this.employeeId.toLowerCase();
    },
    getNameInitials(name) {
      if (name.length > 0) {
        let initials = "";
        const nameArray = name.split(" ");
        initials = nameArray[0].substring(0, 1).toUpperCase();
        if (nameArray.length > 1) {
          initials += nameArray[nameArray.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      }
    },
    handleFindUserDelete() {
      this.currentFoundUsers.length = 0;
    },
    hideFindUser() {
      if (!this.mouseOverName) {
        this.showFoundUsers = false;
      }
    },
    clickFindUser(user) {
      if (user.employeeId) {
        this.employeeId = user.employeeId;
        this.email = "";
        this.currentTab = 1;
      } else {
        this.email = user.email;
        this.employeeId = "";
        this.currentTab = 0;
      }
      this.fullName = user.displayName;
      this.showFoundUsers = false;
    },
    debounceHandleFindUser: debounce(async function (event) {
      await this.handleFindUser(event);
    }, 500),
    handleFindUser(event) {
      if ((event.key.length === 1 && event.code != "Space") || event.key === "Backspace" || event.key === "Delete") {
        this.showFoundUsers = true;
        this.isSearchingUsers = true;
        if (this.fullName) {
          this.findUser(this.fullName)
            .then((response) => {
              const results = response.data.results;
              const query = JSON.parse(response.config.data).text;
              if (this.fullName == query) {
                const fResults = this.formatUserSearchResults(results);
                this.foundUsers = fResults.splice(0, 20);
                this.isSearchingUsers = false;
                this.currentFoundUsers = results;
              }
            })
            .catch((err) => {
              this.isSearchingUsers = false;
            });
        }
      }
    },
    async importUsers(users) {
      self = this;
      const hadPendingInvites = [];
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        const userExist = await self.checkUserAlreadyInOrg(user.email).catch((err) => {
          console.error(`failed to check if user already in org ${user.email}`);
        });
        if (userExist) {
          //add user to the workspace directly
          const data = {
            email: user.email,
            groupId: self.groupId,
            role: user.role,
          };
          await self
            .addUserToGroup(data)
            .then((data) => {
              console.log(`successfully added user to workspace: ${user.email}`);
            })
            .catch((err) => {
              console.error(`failed to add existing user to workspace: ${user.email}`);
            });
        } else {
          const userHasInvitation = await self.checkUserHasPendingInvitation(user.email).catch((err) => {
            console.error(`failed to check if user has pending invitation: ${user.email}`);
          });

          if (userHasInvitation) {
            //TODO: add workspace to existing invitation
            hadPendingInvites.push(user.email);
            console.log(`user already has a pending invitation: ${user.email}`);
          } else {
            //send new invitation
            await self.addUserInvitation(user.name, user.email, user.role, user.baseUrl).catch((err) => {
              console.log(`failed to inivite user: ${user.email}`);
              console.log(user);
            });
            console.log(`successfully inivite user: ${user.email}`);
          }
        }
      }

      alert("sucessfully imported users.");
    },
    validateImportedUsers(users) {
      const roles = ["admin-organization", "admin-group", "publisher", "viewer"];
      for (let user of users) {
        if (user.name && user.email && roles.includes(user.role) && user.baseUrl) {
          // console.log(user);
        } else {
          return false;
          break;
        }
      }
      return true;
    },
    selectFile() {
      this.$refs.fileUploader.click();
    },
    handleFileUpload() {
      const self = this;
      const file = this.$refs.fileUploader.files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const url = reader.result;
        self
          .readCSVToJSON(url)
          .then((data) => {
            // self.batchCreateNoEmailUsers(data);
            // debugger
            const status = self.validateImportedUsers(data);
            if (!status) {
              throw "Invalid data format. The CSV file might be missing name, email or role. Please check the file again.";
            } else {
              self.importUsers(data);
            }
          })
          .catch((err) => {
            alert(err);
            self.$refs.fileUploader.value = "";
          });
      };
      reader.readAsDataURL(file);
    },
    noSpace(event) {
      if (event.key === " ") {
        event.preventDefault();
      }
    },
    addAnoteruser() {
      this.resetForm();
      this.addedUserSuccess = "not-yet";
    },
    resetForm() {
      this.fullName = "";
      this.email = "";
      this.employeeId = "";
    },
    async batchCreateNoEmailUsers(users) {
      const errorTrack = {};
      const total = users.length;
      let successCounter = 0;
      const start = Date.now();
      let progress = 0;
      while (users.length > 0) {
        console.log(`user import progress: ${progress}`);
        const user = users.pop();
        //init error tracking
        if (!errorTrack[user.id]) {
          errorTrack[user.id] = 0;
        }
        try {
          //await to avoid overloading the admin server
          const success = await this.createOneNoEmailUser(user.id, user.name, this.groupId);
          if (success) {
            successCounter++;
            progress = successCounter / total;
          }
        } catch (err) {
          //retry each user up to 3 times
          if (errorTrack[user.id] < 3) {
            users.push(user);
            errorTrack[user.id]++;
          } else {
            //TODO: We should provide a csv file for users that could not be added
            console.error(`employee ${user.id} could not be added.`);
          }
        }
      }
      const totalTime = (Date.now() - start) / 1000;
      alert(`total: ${total}; success: ${successCounter}; process completed in ${totalTime} seconds`);
    },
    async createOneNoEmailUser(employeeId, name, groupId) {
      const self = this;
      return new Promise(
        async function (resolve, reject) {
          const idToken = await this.getIdToken();
          const data = {
            organization: this.$organization,
            employeeId: employeeId,
            clientKey: this.$clientKey,
            displayName: name,
            groups: [groupId],
            role: "viewer",
            idToken: idToken,
          };
          axios
            .post(self.$service.admin + "admin", {
              token: "rG5kXk0CDbhgF4RBlNoV",
              action: "add",
              type: "new-user-passwordless",
              data: data,
            })
            .then(function (response) {
              console.log(`employee ${employeeId} created successfully.`);
              resolve(true);
            })
            .catch((err) => {
              console.log(`failed to create employee ${employeeId}.`);
              reject();
            });
        }.bind(this)
      );
    },
    async createNoEmailUser() {
      this.justClicked = true;
      setTimeout(() => {
        this.justClicked = false;
      }, 2000);
      const self = this;
      const user = await self.getNoEmailUser(self.employeeId);
      if (user && self.checkUserAlreadyInWorkspace(user)) {
        self.existingUserName = user.displayName;
        self.isExistingUser = true;
        self.alertMessage = self.$t("admin.alertUserIsAlreadyInWorkspace", { info: user.employeeId });
        return;
      }

      self
        .addPasswordlessUser(
          self.employeeId,
          self.role,
          self.fullName,
          [self.groupId],
          self.groupLocale ? self.groupLocale : self.$i18n.locale
        )
        .then((res) => {
          const msg = res.data;
          //get userId to save in store
          self
            .getNoEmailUser(self.employeeId)
            .then((user) => {
              self.$store.dispatch("updateUser", {
                user: user,
                action: "add",
                level: "group",
              });
              if (msg.includes("User already exists")) {
                self.addedUserSuccess = "added-existing";
              } else {
                self.addedUserSuccess = "no-email";
              }
              self.$emit("updateUserCount", "add", "active");
              self.$emit("update-found-user");
            })
            .catch((err) => {
              console.warn(err);
            });
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    checkUserAlreadyInOrg(email) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByFieldValue("users", "email", email)
          .then((data) => {
            if (data.length > 0) {
              self.existingUserName = data[0].displayName;
              resolve(data[0]);
              // reject("user already registered in the system.");
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    checkUserAlreadyInWorkspace(user) {
      if (user.groups.includes(this.groupId)) {
        return true;
      }
      return false;
    },
    checkUserHasPendingInvitation(email) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByFieldValue("invitations", "email", email)
          .then((data) => {
            data.forEach((invitation) => {
              if (invitation.status == "expired") {
                resolve(true);
                return;
              }
              //skip "finished invitation", you could delete a user and then invite right away
              if (invitation.status != "finished") {
                let timeSinceLastInvitation = "";

                if (self.$backendType == "ali") {
                  timeSinceLastInvitation = (Math.floor(new Date().getTime() / 1000) - invitation.inviteDate) / 60 / 60;
                } else {
                  timeSinceLastInvitation = (Date.now() - invitation.inviteDate.toMillis()) / 1000 / 60 / 60;
                }

                if (timeSinceLastInvitation < 72) {
                  resolve(true);
                  // reject("this user already has a pending invitation.");
                  return;
                }
              }
            });
            resolve(false);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async checkUserCanBeInvited(email) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        if (!email) {
          reject("email is missing.");
          return;
        }

        const userExist = await self.checkUserAlreadyInOrg(email).catch((err) => {
          reject(err);
          return;
        });
        if (userExist) {
          resolve(false);
          return;
        }

        const userHasInvitation = await self.checkUserHasPendingInvitation(email).catch((err) => {
          reject(err);
          return;
        });

        if (userHasInvitation) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    },
    getNoEmailUser(employeeId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        //use email to find user
        const email = self.getEmailOfPasswordlessUser(employeeId);
        self
          .getDocumentByFieldValue("users", "email", email)
          .then((data) => {
            if (data.length > 0) {
              resolve(data[0]);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addUserToGroup(data) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .sendAdminRequest("add", "group-user", data)
          .then((response) => {
            data.displayName = self.fullName;
            data.roles = {
              [data.groupId]: data.role,
            };
            self.$store.dispatch("updateUser", {
              user: data,
              action: "add",
              level: "group",
            });
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addUserInvitation(name, email, role, baseUrl) {
      if (!baseUrl) {
        baseUrl = this.$baseUrl;
      }
      return new Promise(
        async function (resolve, reject) {
          const id = this.generateDocId("invitations");
          const data = {
            id: id,
            baseUrl: baseUrl,
            languageCode: this.groupLocale ? this.groupLocale : this.$i18n.locale, //use group locale to send invitatioin
            displayName: name,
            email: email,
            organization: this.$organization,
            status: "pending",
            inviteDate: await this.getServerTimestamp(),
            invitedBy: {
              displayName: this.auth().currentUser.displayName,
              email: this.auth().currentUser.email,
              uid: this.auth().currentUser.uid,
            },
            groups: [this.groupId],
            role: role,
          };
          this.addDocument("invitations", id, data)
            .then(() => {
              console.log("user successfully invited.");
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        }.bind(this)
      );
    },
    async handleAddGroupUser({ email, role, displayName, groupId, languageCode }) {
      try {
        const response = this.addGroupUser({ email, role, displayName, groupId, languageCode });
        return response;
      } catch (err) {
        const msg = err.response.data;
        if (msg.includes("Organization admin cannot be assigned to a workspace")) {
          this.alert.alertText = this.$t("admin.alertOrganizationAdminAssignedFailed");
          this.alert.type = "error";
          this.alert.show = true;
        } else if (
          msg.includes("An invitation has already been sent to the user. Please wait for the user to accept.")
        ) {
          this.isPendingInvitation = true;
        } else {
          console.warn(msg);
        }
      }
    },
    async inviteUser() {
      this.justClicked = true;

      const newUserData = {
        email: this.email,
        role: this.role,
        displayName: this.fullName,
        groupId: this.groupId,
      };
      const alertFn = ({ type, msg, params }) => {
        if (msg === "admin.alertUserIsAlreadyInWorkspace") {
          this.isExistingUser = true;
          this.alertMessage = this.$t("admin.alertUserIsAlreadyInWorkspace", params);
        } else if (msg === "admin.alertPendingInvitation") {
          this.isPendingInvitation = true;
        } else if (type === "error" || type === "alert") {
          this.alert.alertText = this.$t(msg);
          this.alert.type = type;
          this.alert.show = true;
        }
        this.justClicked = false;
      };
      const result = await addUserToWorkspace({
        tableLevel: "group",
        groupLocale: this.groupLocale,
        mixinDbRef: this,
        mixinUserRef: this,
        vueInstanceRef: this,
        newUserData,
        alertFn,
      });
      if (result && result.successStatus) {
        this.addedUserSuccess = result.successStatus;
        const userStatus = result.isUserDisabled ? "active" : "inactive";
        this.$emit("updateUserCount", "add", userStatus);
        this.$emit("update-found-user");
      }

      this.justClicked = false;
    },
  },
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.invite-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px 24px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 24px;
  margin-bottom: 24px;
}

.avatar-initials {
  position: relative;
  text-align: center;
  font-size: 18px;
}
.user-icon {
  position: absolute;
  top: 7px;
  left: 7px;
}
.title-text {
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 20px;
  line-height: 30px;
}
.subtitle-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
}
.lower-lineheight {
  line-height: 24px;
}
.name-input-div {
  position: relative;
  z-index: 1;
}
.dropdown-list-scroll {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  position: absolute;
  top: 45px;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.35);
}
.dropdown-list-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6 !important;
}
.dropdown-list-item:hover {
  background-color: #f5f5f5 !important;
  cursor: pointer;
}
.btn-width {
  width: 120px;
}
.btn-div {
  padding: 0;
}
.no-text-transform {
  text-transform: none !important;
}
.search-msg-text {
  color: #52545d;
}
</style>
