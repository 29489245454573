<template>
  <div class="filter-menu" :class="{ 'dark-bg': dark, 'block-width': block }">
    <div class="filter-search-div" :class="{ 'dark-search-div': dark }">
      <icon-base color="#52545D" width="16" height="16" class="filter-search-icon">
        <d-icon-search />
      </icon-base>
      <v-text-field
        flat
        :dark="dark"
        v-model="inputText"
        label="Find"
        single-line
        hide-details
        class="filter-search-text"
        :class="{ 'dark-search-text': dark }"
        color="primary"
        background-color="transparent"
        :placeholder="placeholder || $t('admin.searchForItems')"
        solo
        @input="$emit('input', inputText)"
      ></v-text-field>
    </div>
    <div class="filter-dropdown-list-scroll" :class="{ 'block-width': block }">
      <div v-if="filteredMenuItems.length === 0" class="no-result-text" :class="{ 'dark-no-result': dark }">
        {{ inputText.length === 0 ? $t("player.loading") : $t("analytics.msgNoSearchResults") }}
      </div>
      <div
        v-for="item in filteredMenuItems"
        :key="item.id"
        class="filter-list-row center-horizontally"
        :class="{ 'dark-row': dark }"
        @click="debounceClickItem(item)"
      >
        <div class="list-text text-no-wrap text-truncate" :class="{ 'dark-text': dark }">{{ item.name }}</div>
        <v-spacer></v-spacer>
        <button class="list-icon">
          <icon-base v-if="selectedItems.includes(item.id)" color="#4689F3" width="18" height="18">
            <d-icon-circle-checked />
          </icon-base>
          <div v-else class="circle-unchecked" :class="{ 'dark-circle': dark }"></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconSearch from "../icons/DIconSearch.vue";
import DIconCircleChecked from "../icons/DIconCircleChecked.vue";
import { debounce } from "lodash-es";

export default {
  name: "DCategorySelector",
  props: {
    value: String, //for v-model value\
    filteredMenuItems: Array,
    selectedItems: Array,
    dark: Boolean,
    block: Boolean,
    placeholder: String,
  },
  components: {
    IconBase,
    DIconSearch,
    DIconCircleChecked,
  },
  watch: {
    value: function () {
      if (this.value === "") {
        this.inputText = this.value;
      }
    },
  },
  created() {},
  updated() {},
  data: function () {
    return {
      inputText: "",
    };
  },
  methods: {
    debounceClickItem: debounce(function (item) {
      this.onclickItem(item);
    }, 500),
    onclickItem(item) {
      const payload = { item };
      if (this.selectedItems.includes(item.id)) {
        this.$emit("remove-category-item", payload);
      } else {
        this.$emit("on-click-category-item", payload);
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.filter-menu {
  position: relative;
  padding: 0;
  background-color: #fff;
  max-width: 264px;
}
.dark-bg {
  background-color: #323338 !important;
}
.filter-search-div {
  position: relative;
  height: 36px;
  border-bottom: 1px solid #dadada;
  overflow: hidden;
}
.dark-search-div {
  border-bottom: 1px solid #51525b;
}
.filter-search-icon {
  position: absolute;
  top: 12px;
  left: 12px;
}
.filter-search-text {
  position: absolute;
  right: 0;
  top: -4px;
  width: calc(100% - 24px);
  height: 36px;
}
.filter-search-text ::v-deep .v-text-field__slot input {
  color: #52545d;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}
.filter-search-text ::v-deep .v-text-field__slot input::placeholder {
  font-weight: 400;
  color: #52545d;
  opacity: 0.3;
}
.dark-search-text ::v-deep .v-text-field__slot input {
  color: #ffffff !important;
}
.dark-search-text ::v-deep .v-text-field__slot input::placeholder {
  color: #ffffff;
}
.filter-dropdown-list-scroll {
  position: relative;
  width: 264px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
  padding: 8px 0;
}
.filter-dropdown-list-scroll::-webkit-scrollbar {
  width: 4px;
}
.filter-dropdown-list-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.block-width {
  width: 100%;
  max-width: unset;
}
.filter-list-row {
  position: relative;
  width: 100%;
  height: 32px;
  padding: 0 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filter-list-row:hover {
  background-color: #f0f0f080;
}
.dark-row:hover {
  background-color: #52545d7c;
}
.no-result-text {
  position: relative;
  color: #52545d;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  opacity: 0.6;
  padding: 8px 0;
  text-align: center;
}
.dark-no-result {
  color: #fff;
}
.list-text {
  position: relative;
  color: #52545d;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
}
.dark-text {
  color: #fff;
}
.list-icon {
  height: 18px;
  width: 18px;
}
.circle-unchecked {
  height: 18px;
  width: 18px;
  border: 1px solid #dedede;
  border-radius: 9px;
}
.filter-list-row:hover .circle-unchecked {
  border: 1px solid #52545d;
}
.dark-circle {
  border: 1px solid #51525b;
}
.filter-list-row:hover .dark-circle {
  border: 1px solid #fff;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
