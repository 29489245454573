export const WORKFLOW_ACTION = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  DUPLICATED: "DUPLICATED",
  MOVE: "MOVE",
  TO_UNPUBLISHED: "TO_UNPUBLISHED",
};

export const EDITOR_STATUS = {
  COMPLETED_SEGMENTATION: "completed segmentation",
  COMPLETED_TRANSCRIPTION: "completed transcription",
  COMPLETED_BUILDER_MAIN: "completed buildermain",
  DEFAULT: "",
  PROCESSING_CUT: "processing cut",
  PROCESSING_ERASE: "processing erase",
  PROCESSING_PATCH_TRANSCRIPTION: "processing patch transcription",
  PROCESSING_ROTATE: "processing rotate",
  PROCESSING_SEGMENTATION: "processing segmentation",
  PROCESSING_BUILDER_MAIN: "processing buildermain",
  PROCESSING_TRANSCRIPTION: "processing transcription",
  PROCESSING_RE_TRANSCRIPTION: "processing re-transcription",
  PROCESSING_TRIM: "processing trim",
  PROCESSING_VOICEOVER: "processing voice over",
};

export const EDITOR_TYPE = {
  CUT: "CUT",
  ROTATE: "ROTATE",
  VOICEOVER: "VOICEOVER",
};

export const PROCESSING_POPUP_TYPE = {
  CLOSE: "",
  REQUEST_CUT: "REQUEST_CUT",
  REQUEST_ERASE: "REQUEST_ERASE",
  REQUEST_RESET: "REQUEST_RESET",
  REQUEST_ROTATE: "REQUEST_ROTATE",
  REQUEST_TRIM: "REQUEST_TRIM",
  REQUEST_VOICEOVER: "REQUEST_VOICEOVER",
};

export const REVIEW_POPUP_TYPE = {
  CLOSE: "",
  WAITING_INITIAL: "WAITING_INITIAL",
  REQUEST_PATCH_TRANSCRIPTION: "REQUEST_PATCH_TRANSCRIPTION",
};

export const RECORD_STATUS = {
  WAITING: "WAITING",
  COUNTDOWN: "COUNTDOWN",
  PAUSE: "PAUSE",
  RECORDING: "RECORDING",
  FINISH: "FINISH",
};

export const TERMS_STATUS = {
  IN_PROGRESS: "inProgress",
  REPROCESS: "reprocess",
  EXTRACTED: "extracted",
  EDITED: "edited",
};

export const MULTI_EDITOR_STATUS = {
  DEFAULT_FPS: 30,
};
