import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";
import envConfig from "../../env";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].deephowApiServer
    : environment.service[environment.env]["china"].deephowApiServer;
}

// private player
const playerServer = axios.create({
  baseURL: getBaseUrl(),
});
playerServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);
const playerWrapper = apiWrapperGenerator(playerServer);

export const fetchWorkflowPlayerData = ({ workflowId, withAddons }) =>
  playerWrapper("get", `/v1/player/workflow?workflowId=${workflowId}&withAddons=${withAddons}`);

// public player
const publicPlayerServer = axios.create({
  baseURL: getBaseUrl(),
});
const devServerToken =
  "3mJ1lNjGEu0oBIwxzr!8Yq0rE/gJMGa=T47GkE27XLuZ=kGzEVosYOvKcBa1b=-Ko8JWWQvDH=!n-aSDKv3Qm37apN6ZQs8PydwVxOsYA6480qPeN5T-V-XxFrDv41Rt";
const prodServerToken =
  "mPQDQNcliYwWUEdBeBXbfG?Jqnbt5p7dW1RLe/qDNGzvnoWoqIpAFm5Ug-zNANFYjupS0lDm34xfxJnHPZ=pfcBOjQqqj9VEfnIbc-QOZfge2p7kH6dp5V09605ttNIlBM!t5wdMQGoh=9GhQH=6Po?za7rs!ENwvIU/eS80-V3eKg6YYnzqk5CD7CI7BKmiC42Uh2X6tvmY?vvIhMIgRZA/pSqBKrcNMjRGUuBHZU6Kiwjl8KNH!4R0x34NIqmS";
publicPlayerServer.interceptors.request.use(
  (config) => {
    config.headers["x-server-token"] = envConfig.env === "prod" ? prodServerToken : devServerToken;
    return config;
  },
  (error) => Promise.resolve(error)
);
const publicPlayerWrapper = apiWrapperGenerator(publicPlayerServer);

export const fetchPublicWorkflowPlayerData = ({ workflowId, withAddons }) =>
  publicPlayerWrapper("get", `/v1/player/workflow?workflowId=${workflowId}&withAddons=${withAddons}`);
