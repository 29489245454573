export const getStepVideoMetadata = (step = { id: "", video: "", videos: null }, resolution = 720) => {
  const metadata = {
    type: "step",
    rez: step.videos && step.videos[resolution] ? resolution : "hls",
    id: step.id,
    notTranscodedVideo: step.video,
  };
  return metadata;
}
export const fetchVttFile = async (subtitle = { language: "", source: "" }) => {
  try {
    if (!subtitle.language || !subtitle.source) throw "language or source is null!";

    const response = await fetch(subtitle.source);
    const data = await response.blob();
    return { ok: true, data };
  } catch (errorMessage) {
    console.error("error in fetchVttFile")
    return { ok: false, errorMessage }
  }
}
export const fetchVideoBlobByUrl = async (url = "") => {
  try {
    if (!url) throw "video url is null!"

    const response = await fetch(url);
    const data = await response.blob();
    return { ok: true, data };
  } catch (errorMessage) {
    console.error("error in fetchVideoBlobByUrl")
    return { ok: false, errorMessage }
  }
}
export const getWorkflowTotalDurationTime = (steps = []) => {
  const totalDuration = steps.reduce((acc, { duration = 0 }) => {
    acc += duration;
    return acc;
  }, 0);
  const minute = Math.floor(totalDuration / 60);
  const second = Math.floor(totalDuration % 60);
  return { minute, second }
}