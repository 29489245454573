<template>
  <div class="d-tabs" :style="{height: height+'px'}">
    <div v-if="!autoWidth" class="div-inner">
      <button
        v-for="(tabName, idx)  in tabNames"
        :key="idx"
        class="tab-btn"
        :style="{width: tabWidth+'px'}"
        :class="{'tab-btn-active': value==idx}"
        @click="$emit('input', idx)"
      >
        <span class="tab-text text-no-wrap" :class="{'light-text': light}">
          <slot name="tabName" :item="tabName" :index="idx">
            <!-- fallback content -->
            {{tabName}}
          </slot>
        </span>
      </button>
      <div class="blue-bar" :style="{width: tabWidth+'px', left: (tabWidth*1+16)*value+'px'}"></div>
    </div>
    <div v-else class="div-inner">
      <button
        ref="btn"
        v-for="(tabName, idx)  in tabNames"
        :key="idx"
        class="tab-btn padding-width"
        :class="{'tab-btn-active': value==idx}"
        @click="$emit('input', idx)"
        :autotest="getAutotestAttribute(idx)"
      >
        <span class="tab-text text-no-wrap" :class="{'light-text': light}">
          <slot name="tabName" :item="tabName" :index="idx">
            <!-- fallback content -->
            {{tabName}}
          </slot>
        </span>
      </button>
      <div class="blue-bar" :style="{width: buleBarWidth+'px', left: buleBarLeft+'px'}"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DTabs",
  props: {
    value: Number, //for v-model value
    tabNames: Array,
    tabWidth: String,
    light: Boolean,
    height: String,
    autoWidth: Boolean,
    autotest: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    value: function() {
      this.setBlueBar();
    }
  },
  mounted() {
    this.setBlueBar();
  },
  data() {
    return {
      buleBarWidth: 0,
      buleBarLeft: -8
    };
  },
  methods: {
    setBlueBar() {
      if (this.autoWidth) {
        this.buleBarLeft = this.$refs.btn[this.value].offsetLeft;
        this.buleBarWidth = this.$refs.btn[this.value].offsetWidth;
      }
    },
    getAutotestAttribute(idx) {
      if (this.autotest.length === 0){
        return "";
      }
      return this.autotest[idx];
    }
  },
  computed: {}
};
</script>

<style scoped>
.d-tabs {
  position: relative;
  display: inline-flex;
  height: 70px;
}
.div-inner{
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
}
.tab-btn {
  position: relative;
  height: 100%;
  opacity: 0.7;
  margin-right: 16px;
}
.padding-width {
  padding: 0 8px;
}
.tab-text {
  text-align: center;
  height: 24px;
  font-size: 16px;
  padding-top: 2px;
  color: white;
  font-family: Roboto;
}

.light-text {
  color: #52545d;
}
.tab-btn-active {
  opacity: 1;
  transition: 0.2s;
}
.tab-btn:hover {
  opacity: 1;
  transition: 0.2s;
}
.blue-bar {
  position: absolute;
  bottom: 0;
  height: 100%;
  border-bottom: 0;
  border-bottom: 4px solid #4689f3;
  transition: 0.3s;
}
</style>