<template>
  <section>
    <admin-setting-permission-item
      :allow="getter_workflow_comments_enabled"
      :title="$t('admin.workflowComments')"
      :subtitle="$t('admin.allowsWorkflowCommentsMsg')"
      autotest="workflow-comments-toggle"
      @click-switch="showConfirmation = true"
    />

    <d-change-permission-confirmation
      v-if="showConfirmation"
      :title="$t('admin.workflowComments')"
      :content="
        getter_workflow_comments_enabled
          ? $t('admin.disableWorkflowCommentsMsg')
          : $t('admin.enableWorkflowCommentsMsg')
      "
      :style="drawerOpenPopupStyle"
      @close-popup="showConfirmation = false"
      @change-permission="updateWorkflowComments"
    ></d-change-permission-confirmation>
  </section>
</template>

<script>
import AdminSettingPermissionItem from "@/components/DAdmin/Settings/AdminSettingPermissionItem.vue";
import DChangePermissionConfirmation from "@/components/ui_components/DChangePermissionConfirmation.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "AdminSettingWorkflowComments",
  props: {
    drawerOpenPopupStyle: Object,
  },
  components: {
    AdminSettingPermissionItem,
    DChangePermissionConfirmation,
  },
  watch: {},
  created() {},
  mounted() {},
  data() {
    return {
      showConfirmation: false,
    };
  },
  computed: {
    ...mapGetters("permission", ["getter_workflow_comments_enabled"]),
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    ...mapActions("permission", ["updateWorkflowCommentsSettings"]),
    async updateWorkflowComments() {
      this.showConfirmation = false;
      const toEnable = !this.getter_workflow_comments_enabled;
      const updateData = {
        customizedPermissions: {
          enableComments: toEnable,
        },
      };

      try {
        await this.updateWorkflowCommentsSettings({ organization: this.$organization, updateData });

        const message = toEnable
          ? this.$t("admin.alertEnableWorkflowCommentsSuccess")
          : this.$t("admin.alertDisableWorkflowCommentsSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } catch (error) {
        const message = toEnable
          ? this.$t("admin.alertEnableWorkflowCommentsFailed")
          : this.$t("admin.alertDisableWorkflowCommentsFailed");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
