module.exports = {
  ad: {
    highlights: "亮点",
    highlightsIntro: "在这里，你可以快速了解新发布的功能，就像这个简介。同时，Stephanie也会提醒并帮助你跟踪正在进行的技能培训。",
    recommendations: "推荐",
    recommendationsIntro: "Stephanie会根据你的观看历史推荐工作流程。她会从你和她的每一次互动中学习!",
    favoritesIntro: "有没想过，如果所有你最常用的工作流程，都在那等着你，那该有多棒？！现在你有一个特定的地方可以随时查看他们!",
    categoriesIntro: "那么多工作流程，那么少时间……一个点击，就只显示你想看的类别，不是很棒吗？现在你可以了! :)",
    loginMsgTitle: "新功能更新!",
    loginMsg: "现在你可以轻松地搜索工作空间。此外，试试我们新的内容推荐功能，可以帮你更好地利用你的DeepHow账户。"
  },
  all: {
    success: "成功",
    player: "播放器",
    editor: "编辑器",
    admin: "管理",
    analytics: "统计分析",
    skills: "技能",
    skill: "技能",
    unpublished: "未发布",
    published: "已发布",
    signOut: "登出",
    step: "步骤",
    steps: "步骤",
    numStep: "{count}步骤",
    transcription: "文字转录",
    no: "不",
    yes: "是",
    delete: "删除",
    diagram: "图片",
    all: "全部",
    discard: "放弃",
    save: "保存",
    next: "下一步",
    process: "处理",
    cancel: "取消",
    close: "关闭",
    allUsers: "全部用户",
    users: "用户",
    user: "用户",
    numUsers: "个用户",
    numUser: "个用户",
    countUsers: "{count}个用户",
    workspaces: "工作空间",
    workspace: "工作空间",
    workspaceGroup: "工作空间组",
    workflows: "工作流程",
    workflow: "工作流程",
    organization: "组织机构",
    business: "业务",
    create: "新建",
    name: "名称",
    namePerson: "姓名",
    email: "邮箱",
    employeeId: "员工ID",
    role: "角色",
    organizationAdmin: "组织机构管理员",
    workspaceAdmin: "工作空间管理员",
    publisher: "发布者",
    viewer: "浏览者",
    organizationAdmins: "组织机构管理员",
    workspaceAdmins: "工作空间管理员",
    publishers: "发布者",
    viewers: "浏览者",
    add: "添加",
    attachments: "附件",
    private: "私有",
    public: "公开",
    content: "内容",
    noRecordFound: "暂无数据",
    loadingRecords: "正在加载数据...",
    teams: "团队",
    loadedAllRecords: "全部数据已加载",
    language: "语言",
    interfaceLanguage: "界面语言",
    privacyPolicy: "隐私政策",
    personalInfo: "个人信息",
    noAccessToAdminTools: "这位用户目前没有权限使用管理工具。",
    team: "团队",
    unselect: "取消选择",
    select: "选择",
    applied: "已选",
    changePhoto: "更换头像",
    removePhoto: "移除头像",
    profilePhoto: "头像图片",
    takeOrUpload: "拍摄或上传一张头像",
    useCamera: "拍攝头像",
    uploadPhoto: "上传头像",
    editPhoto: "编辑头像",
    retake: "重拍",
    takePhoto: "拍摄照片",
    countWorkspaces: "个工作空间",
    whatsNew: "新版变化",
    howToUse: "如何使用",
    defaultInterfaceLanguage: "默认界面语言",
    newVersionSnackbarText: "新版DeepHow已上线。",
    refresh: "刷新",
    unpublishedList: "未发布",
    publishedList: "已发布",
    userAgreement: "用户协议",
    numAttachments: "0个附件 | 1个附件 | {count}个附件",
    attachment: "附件",
    knowledgeBase: "DeepHow知识库",
    changeName: "修改用户名",
    changePassword: "修改密码",
    openInApp: "在Deephow Navigator中打开",
    userNewToDeepHow: "你是第一次使用DeepHow吗?",
    downloadTheApp: "下载应用程序",
    continueToUseBrowser: "继续使用浏览器",
    contentManager: "内容管理",
    gen2Forbidden: "Gen2 Light用户无法在当前域名登入",
    confirm: "确定",
    myWorkspaceGroups: "我的工作空间组",
    otherWorkspaceGroups: "其他工作空间组",
    searchResult: "搜索结果",
    noResultsFound: "未找到结果",
    browseWorkspace: "搜索工作空间...",
    version: "版本",
  },
  password: {
    createYourAccount: "创建你的账户",
    checkNameChoosePassword: "检查你的姓名并选择密码",
    createAccount: "创建账户",
    createNewPassword: "设置新密码",
    selectNewPassword: "为你的账户选择一个新密码",
    newPassword: "新密码",
    confirmNewPassword: "确认新密码",
    passwordMustMatch: "新密码与确认密码不一致",
    confirm: "确定",
    passwordCreatedMsg: "你的新密码已设置成功，<br>页面将跳转请稍等...",
    goToDeepHowPlayer: "进入DeepHow播放器",
    failedResetPassword: "重置密码失败。你的账户已经重置或者存在网络问题。",
    alertSetPasswordFailed: "设置密码失败。你的账户已经重置或者存在网络问题。",
    linkExpired: "链接已过期",
    passwordExpired: "你的重设密码链接已过期。",
    inviteExpired: "你的邀请链接已过期",
    resetPasswordMsg: "点击下方按钮获取一封新邮件重设密码。",
    // added 09/08/2022
    alertFailedResetPassword: "重置密码失败。",
    alertSuccessfullyResetPassword: "你的新密码已设置成功，请重新登录，页面将跳转请稍等... 或者点击下方按钮。",
    newPasswordNotTheSame: "新密码不可与旧的相同。",
    alertCurrentPasswordIncorrect: "当前密码错误",
    alertTooManyAttempts: "多次验证密码失败，请稍后再试。",
  },
  analytics: {
    overview: "概览",
    engagement: "参与度",
    activeUsers: "激活的用户",
    workflowsCreated: "创建的工作流程",
    workflowsPublished: "发布的工作流程",
    workflowViews: "工作流程观看次数",
    timeCreated: "创建时长",
    timePublished: "发布时长",
    timeViewed: "观看时长",
    searchWorkspace: "搜索工作空间...",
    mostUsers: "用户最多",
    mostWorkflowsCreated: "创建的工作流程最多",
    numberOfUsers: "用户数量",
    organizationOverview: "组织机构概览",
    numWorkflowsIn: "个工作流程于",
    searchWorkflow: "搜索工作流程...",
    createdBy: "作者",
    usersIn: "用户于",
    searchUser: "搜索用户...",
    watchTime: "观看时长",
    workflowsViewed: "观看的工作流程",
    percentViewed: "观看百分比",
    dateViewed: "观看时间",
    watchWorkflow: "播放工作流程",
    backToUsers: "返回用户列表",
    backToWorkflows: "返回工作流程列表",
    likes: "点赞",
    avgTimeViewed: "平均观看时长",
    numberofViews: "观看次数",
    workspacesPerPage: "每页工作空间数",
    usersPerPage: "每页用户数",
    workflowsPerPage: "每页工作流程数",
    exportTable: "导出表格",
    TopViewers: "浏览者排行榜",
    TopPublishers: "发布者排行榜",
    totalViews: "总观看次数",
    msgNoDataYet: "此表格还没有数据",
    msgNoSearchResults: "没有找到符合的数据",
    hms: "(时:分:秒)",
    viewHistory: "观看历史",
    views: "观看次数",
    totalViewingTime: "总观看时间",
    usersViewedThisWorkflow: "个用户观看了这个工作流程",
    date: "时间",
    // added 2022/07/11
    dashboards: "数据面板",
    reactions: "反馈",
    userReaction: "用户反馈",
    viewAll: "查看全部",
    favorites: "收藏",
  },
  admin: {
    status: "状态",
    active: "激活",
    inactive: "未激活",
    invited: "已邀请",
    invitationExpired: "邀请过期",
    couldNotInvited: "无法邀请",
    hintSearchUsers: "搜索用户...",
    hintSearchWorkspaces: "搜索工作空间...",
    hintSearchBusiness: "搜索业务...",
    alertSuccessfullyInviteUser: "邀请用户成功。",
    alertFailedInviteUser: "邀请用户失败。",
    alertSuccessfullyDeletedUser: "删除用户成功。",
    alertFailedDeleteUser: "删除用户失败。",
    alertSuccessfullyCreatedWorkspace: "新建工作空间成功。",
    alertSuccessfullyEditedWorkspace: "编辑工作空间成功。",
    alertSuccessfullyCreatedBusiness: "新建业务成功。",
    alertSuccessfullyEditedBusiness: "编辑业务成功。",
    alertUserInvitationSuccessfullyResent: "发送用户邀请邮件成功。",
    alertFailedResendUserInvitation: "发送用户邀请邮件失败。",
    alertUserInvitationSuccessfullyDelete: "删除用户邀请成功。",
    alertFailedDeleteUserInvitation: "删除用户邀请失败。",
    resendInvitation: "重发邀请邮件",
    popupMsgResendInvitation: "你确定要重发邀请邮件给",
    popupBtnSend: "是，发送",
    deleteInvitation: "删除邀请",
    popupMsgDeleteInvitation: "你确定要删除邀请",
    addUser: "添加用户",
    msgNoMembersYet: "这个组织机构还没有添加用户。",
    msgNoRecordsAllUsers: "在所有用户中没有找到符合的记录。",
    createWorkspace: "新建工作空间",
    msgNoWorkspacesYet: "这个组织机构还没有创建工作空间.",
    msgNoRecordsWorkspaces: "在工作空间中没有找到符合的记录。",
    msgNoRecordsWorkspacesGroupAdmin: "这个账号还没有工作空间。",
    createBusiness: "新建业务",
    msgNoBusinessYet: "这个组织机构还没有创建业务.",
    msgNoRecordsBusiness: "在业务中没有找到符合的记录。",
    usersIn: "用户于",
    tableMsgNoUsersYet: "这个工作空间还没有添加成员。",
    tableMsgNoRecordsThisWorkspace: "工作空间中没有找到符合的记录",
    memberAt: "属于",
    alerBusinessAlreadyInSystem: "业务已经存在在系统里。",
    alerFailedAddBusiness: "添加业务失败。",
    alertFailedEditBusiness: "编辑业务失败。",
    alertWorkspaceAlreadyInSystem: "工作空间已经存在在系统里。",
    aletFailedCreateWorkspace: "添加工作空间失败。",
    alertFailedEditWorkspace: "编辑工作空间失败。",
    resendAllInvites: "重发所有邀请",
    resendAllInvitesMsg: "你将发送新的邀请邮件给所有邀请未验证和过期的用户，你确定吗？",
    yesSendInvites: "是的，发送邀请",
    businessesPerPage: "每页业务数",
    //add org user
    popupTitleAddOrgUser: "添加组织机构管理员",
    invite: "邀请",
    popupTitleNewUserInvited: "新用户邀请成功!",
    popupMsgNewUserInvitedOrg: "已被邀请。<br>验证账户后，他就可以登入这个组织机构。",
    popupTitleExistingUserInvited: "已存在的用户添加成功!",
    popupMsgExistingUserInvitedOrg: "已添加到这个组织机构。",
    popupBtnAddAnotherUser: "添加另一个用户",
    alertFailedCheckPendingInvitations: "查找用户邀请状态失败。",
    alertFailedAddExistingUserAsOrganizationAdmin: "将现有用户设为组织机构管理员失败。",
    alertAddExistingUserAsOrganizationAdmin: "已将现有用户设为组织机构管理员。",
    alertUserAlreadyOrganizationAdmin: "此用户已被设为组织机构管理员。",
    alertFailedCheckUserAlreadyInSystem: "查找用户是否已加入系统失败。",
    //Add user to workspace
    addUserToWorkspace: "添加用户到工作空间",
    companyEmail: "公司邮箱",
    employeeIdOnly: "只有员工ID",
    fullName: "姓名",
    workspaceName: "工作空间名称",
    businessName: "业务名称",
    popupMsgNewUserInvited: "已被邀请.<br>验证账户后，此用户就可以登入这个工作空间。",
    popupMsgExistingUserInvited: "已添加到这个工作空间。",
    popupTitleNewUserCreated: "新建用户成功!",
    popupTitleUserAlreadyinSystem: "用户已存在在系统里",
    alertUserIsAlreadyInWorkspace: "这个用户({info})已存在在这个工作空间中。",
    alertPendingInvitation: "此用户未激活邀请邮件，请等用户接受邀请后再做此操作。",
    searchingforUsers: "正在查找用户...",
    noResultsFound: "未找到结果",
    // Change User Role
    popupTitleChangeUserRole: "改变用户角色",
    popupMsgChangeUser: "你确定要把<b>{name}</b>的角色从<b>{oldRole}</b>改为<b>{newRole}</b>吗?",
    popupBtnChange: "是，保存",
    alertUpdatedUserSettings: "成功更新用户的设置。",
    // Remove User
    popupTitleRemoveUser: "移出工作空间",
    popupMsgRemoveUserFromWorkspace: "你确定要把<b>{name}</b>移出这个工作空间吗?",
    popupBtnRemove: "是，移出",
    alertRemoveUser: "成功将用户移出工作空间",
    // Delete User
    popupTitleDeleteUser: "把用户从系统删除",
    popupMsgDeleteUserFromAllWorkspaces: "如果这么做，你将会丢失关于这个用户<b>{name}</b>的所有数据。他将从所有工作空间中移出，并取消被设置的角色。",
    popupBtnDelete: "是，删除",
    tooltipEditUser: "编辑用户",
    tooltipResendInvite: "重发邀请",
    tooltipCancelInvite: "删除邀请",
    tooltipRemoveUser: "移除用户",
    // settings
    settings: "设置",
    location: "地址",
    hireDate: "入职时间",
    department: "部门",
    position: "职位",
    site: "工作地点",
    userId: "用户ID",
    ssoMapping: "用户映射",
    ssoMappingNoData: "还没有数据用于映射<br />你需要通过FTP上传请联系技术支持。",
    attributes: "属性",
    oneAttributeToMap: "你只能选择一个属性来将用户映射到工作空间里",
    selectAttribute: "选择属性",
    changeAttribute: "改变属性",
    discardChanges: "撤消变更",
    applyChanges: "应用变更",
    applyChangesMsg: "你将应用如下工作空间的变更，<br>你确定吗？",
    mappingValuesUpdated: "映射属性值已变更",
    mappingValuesUpdatedSubtitle: "如要成功更新映射，你需要点击应用变更。你可以在所有工作空间调完成整后再执行这步。",
    values: "属性值",
    clickPlusToAddValues: "点击加号图标添加属性值",
    selectValues: "选择属性值",
    searchForValues: "搜索属性值…",
    assign: "设置",
    assignValues: "设置属性值",
    addUsersBtn: "是，添加用户",
    YesChangeBtn: "是，改变",
    numMore: "另外{count}个",
    removeValues: "移除属性值",
    remove: "移除",
    changeAttributeMessage: "你确定你要改变属性吗？你将会丢失所有这个属性已设置的映射。",
    discardChangesMessage: "你将会丢失上次保存后做的所有更改。<br>你确定要这样做吗？",
    discardChangesBtn: "是，撤消变更",
    applyChangesBtn: "是，应用变更",
    alertDiscardedSuccess: "成功撤销映射变更",
    alertAppliedSuccess: "成功应用映射变更",
    alertAppliedFailed: "应用映射变更失败",
    //permissions
    permissions: "用户权限",
    products: "DeepHow产品",
    roleAccessToProduct: "在此定义，各个用户角色可以访问哪些产品",
    analytics: "统计分析",
    skillsManager: "技能管理",
    skillsPlayer: "技能播放器",
    workflowCapture: "工作流程拍摄",
    workflowEditor: "工作流程编辑器",
    organizationAdmin: "组织机构管理员",
    groupAdmin: "工作空间管理员",
    publisher: "发布者",
    viewer: "浏览者",
    rolesPermission: "角色权限",
    rolesPermissionMessage: "你确定要改变这个产品的用户权限设置吗？改变将会立刻生效。",
    inviteUser: "邀请用户",
    workflowSharing: "分享工作流程",
    allowUserstoSharePublicLinks: "允许所有用户分享公共链接",
    changeWorkflowSharingExplanation: "如果关闭此分享公共链接选项，用户将只能通过登录平台访来问所有公共工作流程",
    allowWorkflowSharingMessage: "你确定要允许用户分享公共链接吗？",
    preventWorkflowSharingMessage: "你确定要阻止用户分享公共链接吗？改变将会立刻生效。",
    workflowDownload: "下载工作流程",
    allowUsersToDownloadWorkflows: "允许所有用户下载工作流程",
    allowDownloadWorkflowsMessage: "你确定要允许用户下载工作流程吗？",
    preventDownloadWorkflowsMessage: "你确定要阻止用户下载工作流程吗？",
    autoLogout: "自动登出",
    autoLogoutAfterSelectedTime: "用户将会在所选的时间之后自动登出系统。",
    numMinutes: "{count}分钟",
    changeLogoutTimeMessage: "你确定将自动登出时间改为{num}分钟吗？",
    enableAutoLogoutMessage: "你确定要打开自动登出吗？",
    disableAutoLogoutMessage: "你确定要关闭自动登出吗？",
    changesMadeImmediately: "改变将会立刻生效。",
    sessionIsAboutToExpire: "你已经一段时间没有活动了。",
    youWillBeLoggedOutNumSeconds: "你将在{count}秒以后自动登出。",
    continueSession: "保持登入",
    //system languages
    languages: "语言",
    systemLanguage: "系统语言",
    searchForWorkspaces: "搜索工作空间…",
    workspacesPerPage: "每页工作空间数",
    workspace: "工作空间",
    workspaceLanguage: "语言",
    changeLanguage: "更换语言",
    popupMsgChangeLanguage: "更换语言会影响此工作空间内的所有用户，你确定要更换吗？",
    alertLanguageSuccessfullyChanged: "更换语言成功",
    alertFailedChangeLanguage: "更换语言失败",
    // Categories
    categories: "分类",
    alertCategoriesNotSaved: "分类尚未存储，请再次尝试",
    noCategoryResult: "没有分类的搜寻结果",
    noCategoriesAddedYet: "尚未有分类被加入",
    categoriesAssignment: "分类分配",
    categoryPlaceholder: "搜寻分类和子分类...",
    previouslyUsed: "先前新增",
    allCategories: "所有分类",
    workflowCategories: "工作流程分类",
    searchForCategories: "搜索分类…",
    categoryItems: "项目名称",
    categoryItemsNum: "项目数量",
    createCategory: "新建分类",
    editCategory: "编辑分类",
    categoryName: "分类名称",
    noCategoriesCreatedYet: "还没有创建分类",
    noItemsCreatedYet: "还没有创建项目",
    categoriesPerPage: "每页分类数",
    itemsPerPage: "每页项目数",
    alertCategoryAlreadyInSystem: "分类已经存在在系统里。",
    alerFailedAddCategory: "创建分类失败。",
    alertFailedEditCategory: "编辑分类失败",
    deleteCategory: "删除分类",
    popupMsgDeleteCategory: "你确定你要删除分类",
    itemsIn: "项目于",
    searchForItems: "搜索项目…",
    createItem: "创建项目",
    editItem: "编辑项目",
    deleteItem: "删除项目",
    itemName: "项目名称",
    alertItemAlreadyInSystem: "项目已经存在在系统里。",
    alertFailedAddItem: "新建项目失败。",
    alertSuccessAddItem: "新建项目成功",
    alertFailedEditItem: "编辑项目失败",
    alertSuccessEditItem: "编辑项目成功",
    //Teams
    teamsPerPage: "每页团队数",
    max1000UsersPerTeam: "*每个团队最多1000名用户",
    usersSelected: "{count}个已选用户",
    selectAll: "全选",
    removeAll: "取消全选",
    createTeam: "新建团队",
    editTeam: "编辑团队",
    teamName: "团队名称",
    teamsIn: "团队于",
    searchForTeams: "搜索团队…",
    searchForUsers: "搜索用户...",
    noUsersAddedYet: "还没有用户被添加到团队中。",
    dateAdded: "添加日期",
    alertTeamAlreadyInSystem: "这个团队已存在在系统中。",
    alertFailedEditTeam: "编辑团队失败",
    deleteTeam: "删除团队",
    popupMsgDeleteTeam: "你将要删除团队<b>{name}</b>。你确定要这样做吗？",
    noTeamsCreatedYet: "还没有创建团队",
    popupMsgDeleteItem: "你确定你要删除项目",
    removeUser: "移除用户",
    popupMsgRemoveUser: "你将要把用户<b>{name}</b>从团队中移除。你确定要这样做吗？",
    alertErrorGettingWorkspaceList: "获取工作空间列表失败。",
    alertSuccessfullyCreatedTeam: "成功新建团队。",
    alertFailedCreatedTeam: "新建团队失败。",
    alertSuccessfullyUpdatedTeam: "成功编辑团队。",
    alertSuccessfullyRemovedUserFromTeam: "成功将用户移出团队。",
    alertFailedRemovedUserFromTeam: "将用户移出团队失败。",
    alertSuccessfullyAddedUserToTeam: "成功将用户添加到团队。",
    alertFailedAddedUserToTeam: "将用户添加到团队失败。",
    alertWarningAddedUserToTeamAlready: "用户已被添加到团队中。",
    // added 2022/02/03
    alertAllInvitationResentSuccess: "重发全部用户邀请邮件成功",
    alertAllInvitationResentFailed: "重发用户邀请邮件失败",
    alertCheckUserInSystemFailed: "检查用户是否已在系统中失败。",
    alertOrganizationAdminAssignedFailed: "组织机构管理员无法被加入单个工作空间。",
    // added 2022/02/07
    alertFailedDeleteItem: "删除项目失败。",
    alertSuccessfullyDeleteItem: "删除项目成功。",
    alertCategoryDeleteFailed: "分类删除失败。",
    alertSuccessfullyDeletedCategory: "分类删除成功。",
    // added 2022/02/08
    upgradeToOrgAdmin: "升级到组织机构管理员",
    yesUpgrade: "是的，升级",
    popupMsgUpgradeToOrgAdmin: "你确定要把<b>{name}</b>升级吗? 组织机构管理员有完全的权限来配置你组织的设置。",
    alertFailedUpgradeToOrg: "升级到组织机构管理员失败",
    alertSuccessfullyUpgradeToOrg: "升级到组织机构管理员成功",
    // TODO added 2022/02/17
    userActive: "激活的用户?",
    profile: "个人资料",
    // TODO: added 2022/02/09 settings > Master Password
    masterPassword: "主密码",
    enableMasterPassword: "开启主密码",
    disableMasterPassword: "取消主密码",
    resetMasterPassword: "重设主密码",
    employeeIDAccess: "员工ID登录",
    employeeIDAccessMsg:"如果你开启这项功能，使用员工ID登录的用户将须输入以下密码来登录DeepHow",
    disableMasterPasswordMsg:"如果你关闭这项功能，使用员工ID登录的用户将不需要密码来登录DeepHow",
    resetMasterPasswordMsg:"使用员工ID登录的用户将须输入新密码来登录DeepHow",
    insertPassword: "输入密码",
    alertEnablePasswordFailed: "开启主密码失败",
    alertEnablePasswordSuccess: "开启主密码成功",
    alertDisablePasswordFailed: "取消主密码失败",
    alertDisablePasswordSuccess: "取消主密码成功",
    savePasswordMsg: "下方输入的密码被保存成功后，主密码才会正式开启。",
    resetPasswordMsg: "输入新的主密码并保存",
    currentPassword: "当前密码",
    yesEnable: "是的，开启",
    yesDisable: "是的，取消",
    downgradeOrgAdmin: "降级组织机构管理员",
    popupMsgDowngradeOrgAdmin: "你确定要把<b>{name}</b>降级吗? 此用户在其所属的全部工作空间中，都会被降级成你所选择的角色。",
    selectRole: "选择此用户的角色",
    yesDowngrade: "是的，降级",
    alertFailedDowngradeToOrg: "降级到{userRole}失败",
    alertSuccessfullyDowngradeToOrg: "降级到{userRole}成功",
    lastActivity: "上次登入",
    coverColor: "封面颜色",
    defaultLanguage: "默认语言",
    blue: "蓝色",
    red: "红色",
    green: "绿色",
    yellow: "黄色",
    purple: "紫色",
    belongsToWorkspaces: "属于工作空间",
    alertFailedSaveChanges: "保存修改失败。",
    // TODO: added 04/02
    alertFailedRemoveUser: "将用户移出工作空间失败。",
    // TODO: added 0608
    privateOrnot: "私有?",
    privateWorkspaceInfo: "只有加入这个工作空间的用户才能看到这个工作空间的内容。",
    noPermission: "你没有权限查看。",
    noPermissionContact: "你试图访问的页面已被限制访问。<br>如果你觉得这是一个错误，请联系你的管理员。",
    privateCannotChanged: "工作空间被创建以后，不可以修改私有设置。",
    // Shared Account
    sharedAccount: "共享账户",
    addManually: "手动添加",
    addASharedAccount:"添加共享账户",
    addASharedAccountMsg: "共享账户不能用于个人观看工作流程，它们只能用于员工ID用户在DeepHow中自己创建新帐户，还可以用于跟踪共享设备中的员工ID用户...",
    accountName: "账户名称",
    alertFailedAddShared: "添加共享账户失败",
    alertSuccessfullyCreatedShared: "成功新建共享账户",
    alertSuccessfullyAddExistingShared: "成功添加已存在的共享账户",
    accountId: "账户ID",
    addSharedAccount:"添加共享账户",
    sharedAccountId:"共享账户ID",
    unselectedTeam: "取消选择团队",
    // TODO: added 2022/06/22
    workflowReview: "工作流程审核",
    workflowReviewDescription: "如果启用此选项，工作空间管理员将能够选择他们的工作空间是否需要工作流程审核",
    preventReviewWorkflowsMessage: "你确定要关闭工作流程审核吗？",
    allowReviewWorkflowsMessage: "你确定要允许用户查看工作流程吗？",
    // TODO: added 2022/06/29
    addAsReviewer: "添加为审阅者",
    removeAsReviewer: "删除该审阅者",
    addAsReviewerSuccessMessage: "成功添加 {user} 为审阅者。",
    addAsReviewerFailedMessage: "未能将 {user} 添加为审阅者。",
    removeAsReviewerSuccessMessage: "成功删除 {user} 作为审阅者。",
    removeAsReviewerFailedMessage: "无法将 {user} 移除为审阅者。",
    // Shared Account Admin settings 07/27
    allowsSharedAccountMsg: "允许组织和工作空间管理员创建共享帐户。用户可以在共享设备上自己注册员工ID账户。",
    disableSharedAccountMsg: "你确定要关闭共享账户功能吗？<br>修改将会立刻生效。",
    enableSharedAccountMsg: "你确定要开启共享账户功能吗？<br>修改将会立刻生效。",
    alertEnableSharedAccountFailed: "开启共享账户功能失败。",
    alertEnableSharedAccountSuccess: "开启共享账户功能成功。",
    alertDisableSharedAccountFailed: "关闭共享账户功能失败。",
    alertDisableSharedAccountSuccess: "关闭共享账户功能成功。",
    // QR Code & Watermark
    qrCodeAndWatermark: "二维码与水印",
    insertYourBrand: "嵌入你的品牌",
    uploadYourLogo: "上传你的Logo",
    uploadingYourLogo: "正在上传你的Logo",
    logoSuccessfullyUploaded: "上传Logo成功",
    errorInUpload: "上传Logo错误",
    errorInUploadMsg: "文件格式不支持，请选择这些格式的图片：.PNG",
    uploadLogoMsg: "拖放一个<b>.PNG</b>大小在<b>3 MB</b>以内的图片，或者<span>浏览</span>本地文件上传。",
    uploadLogoReplaceMsg: "拖放或<span>浏览</span>来选择替换的图片，或者<span id='DUploadImage_removeLogo'>移除</span>当前图片。",
    simulation: "预览效果",
    simulationMsg: "如下是你的Logo在二维码与视频水印中的呈现效果",
    workflowTitle: "工作流程标题",
    logoAlreadyUploaded: "已上传Logo",
    alertLogoSuccessfullyRemoved: "移除Logo成功。",
    alertLogoFailedRemoved: "移除Logo失败。",
    alertUserInOtherOrg: "此用户账号已存在于另一个组织中。",
    // added 10/13 delete workspace
    deleteWorkspace: "删除工作空间",
    popupMsgDeleteWorkspace: "你确定要删除这个工作空间吗（{name}）？",
    alertDeleteGroupSuccess: "删除工作空间成功。",
    alertDeleteGroupFailedWorkflows: "这个工作空间中有未移除的工作流程，所以你不能删除它。",
    alertDeleteGroupFailedSkills: "这个工作空间中有未移除的技能，所以你不能删除它。",
    alertDeleteGroupFailedTeams: "这个工作空间中有未移除的团队，所以你不能删除它。",
    // added 11/3 delete team
    alertDeleteTeamSuccess: "删除团队成功",
    alertDeleteTeamFailedSkills: "这个团队已被添加到一个或多个技能中，所以你不能删除它。",
    alertDeleteTeamFailed: "删除团队失败。",
    // Change Profile Name
    changeProfileName: "更改配置文件名称",
    allowsChangeProfileNameMsg: "允许用户在个人资料上更改其名称。",
    disableChangeProfileNameMsg: "你确定要禁用更改配置文件名称吗？ <br>将立即进行更改。",
    enableChangeProfileNameMsg: "你确定要启用更改配置文件名称吗？ <br>将立即进行更改。",
    alertEnableChangeProfileNameSuccess: "成功启用了更改配置文件名称。",
    alertEnableChangeProfileNameFailed: "无法启用更改配置文件名称。",
    alertDisableChangeProfileNameSuccess: "成功禁用更改配置文件名称。",
    alertDisableChangeProfileNameFailed: "无法禁用更改配置文件名称。",
    // Workflow Comments
    workflowComments: "工作流程的评论",
    allowsWorkflowCommentsMsg: "允许用户在发布的工作流程中评论。",
    disableWorkflowCommentsMsg: "你确定要禁用工作流程中的评论功能吗？ <br>修改将会立刻生效。",
    enableWorkflowCommentsMsg: "你确定要启用工作流程评论功能吗？ <br>修改将会立刻生效。",
    alertEnableWorkflowCommentsSuccess: "开启工作流程评论功能成功。",
    alertEnableWorkflowCommentsFailed: "启用工作流程评论功能失败。",
    alertDisableWorkflowCommentsSuccess: "禁用工作流程评论功能成功。",
    alertDisableWorkflowCommentsFailed: "禁用工作流程评论功能失败。",
    // Workspace Groups
    workspaceGroupsNum: "个工作空间组 | 个工作空间组 | 个工作空间组",
    searchWorkspaceGroups: "搜索工作空间组...",
    noWorkspaceGroupsYet: "尚未创建工作空间组",
    createWorkspaceGroup: "创建工作空间组",
    editWorkspaceGroup: "编辑工作空间组",
    workspaceGroupName: "工作空间组名称",
    editWorkspace: "编辑工作空间",
    userNum: "个用户",
    teamNum: "个团队",
    defaultLanguage: "默认语言",
    deleteWorkspaceGroup: "删除工作空间组",
    alertCreateWorkspaceGroupSuccess: "成功创建工作空间组。",
    alertWorkspaceGroupNameAlreadyExists: "工作空间组名称已存在。",
    alertEditWorkspaceGroupSuccess: "成功编辑工作空间组。",
    alertEditWorkspaceGroupFailed: "编辑工作空间组失败。",
    alertDeleteWorkspaceGroupSuccess: "成功删除工作空间组。",
    alertDeleteWorkspaceGroupFailed: "删除工作空间组失败。",
    alertWorkspaceAssignedDeleteGroupFailed: "该工作空间组中有分配的工作空间，因此无法删除。",
    deleteWorkspaceGroupMessage: "您确定要删除此工作空间组（<b>{name}</b>）吗？",
    alertWorkspaceNameAlreadyExists: "工作空间名称已存在。",
  },
  builder: {
    publish: "发布",
    reviewTranscription: "审阅转录",
    viewer: "浏览器",
    links: "链接",
    trim: "剪辑",
    translation: "翻译",
    share: "分享",
    addDiagramMsg: "点击或拖拽添加图片",
    onlyImageAccepted: "只支持图片格式文件",
    unsupportedImageFormats: "图片格式不支持：svg, tiff, xbm",
    addDiagram: "添加图片",
    start: "开始",
    end: "结束",
    resetToOriginal: "恢复至原始状态",
    trimStartMsg: "选择你想要视频开始和结束的位置",
    trimProcessingMsg: "正在处理工作流程时，无法使用剪辑。请稍后再回来",
    cannotTrimLessThan3s: "无法剪辑短于3秒的视频",
    trimVideo: "剪辑视频",
    trimStep: "剪辑步骤",
    editWorkflow: "编辑工作流程",
    popupBtnEdit: "是，编辑",
    popupBtnPublish: "是，发布",
    popupBtnUnpublish: "是，取消发布",
    popupMsgTrimConfirmation: "确定要剪辑吗？",
    popupMsgTrimWithSaveConfirmation: "你的工作流程中有未保存的内容，我们会为你保存这些内容。",
    popupBtnTrim: "是，剪辑",
    popupMsgTranscoding: "正在转码",
    popupMsgTrimBackLater: "转码完成后，你可以继续编辑工作流程",
    popupMsgTrimSuccess: "你的步骤片段剪辑成功！",
    done: "完成",
    publishWorkflow: "发布工作流程",
    popupMsgEditWorkflow: "如果进入编辑模式，你的工作流程将自动取消发布，确定要继续吗？",
    popupMsgUnpublishWorkflow: "你的工作流程将取消发布，确定要这样做吗？",
    alertSaveFailed: "工作流程保存失败",
    alertTrimResetSuccess: "步骤恢复成功。",
    alertTrimResetFailed: "步骤恢复失败。",
    alertTrimCanNotReset: "这个步骤无法被恢复。",
    resetStep: "恢复步骤",
    popupMsgResetStep: "你确定要恢复吗? 添加到此步骤的图片将会丢失。",
    popupBtnReset: "是，恢复",
    hintSearchBuilder: "搜索工作流程和关键字...",
    stepsSelected: "选中",
    clear: "清除",
    insert: "插入",
    reset: "重置",
    resetWorkflow: "重置工作流程",
    resetWorkflowAlert: "你将会丢失之前的操作并重新开始，你确定要继续吗?",
    resetWorkflowKeepCopy: "保留旧有工作流程",
    // Transcript / Translation
    closeTranscript: "关闭审阅转录",
    popupMsgCloseTranscript: "你的字幕中有尚未保存的更改。 如果继续，<b>所有更改</b>即将丢失。你确定吗？",
    stepNoTranscript: "此步骤没有字幕",
    originalTranscript: "原始字幕",
    addLanguageTooltip: "点击为你的视频添加更多语言字幕",
    closeTranslation: "关闭翻译",
    popupMsgCloseTranslation: "你的翻译内容尚未保存。 如果继续，<b>所有更改</b>即将丢失。你确定吗？",
    switchTranslation: "切换翻译",
    popupBtnNoSwitch: "不，继续切换",
    popupBtnYesSwitch: "是，保存后切换",
    createAudio: "生成语音",
    addNewLanguageTooltip: "点击此处给视频添加一个新语言",
    popupMsgCreateAudio: "你确定要为工作流创生成翻译语音吗?我们强烈建议你只生成一次，因为Stephanie会生成你所有的翻译。",
    popupBtnYesCreate: "是，生成",
    alertAudioBeingCreated: "翻译语音正在生成",
    alertTranscriptionSaved: "字幕保存成功",
    alertTranscriptionSaveFailed: "字幕保存失败",
    alertTranslationsSaved: "翻译保存成功",
    alertTranslationsSaveFailed: "翻译保存失败",
    alertAudioCreated: "翻译语音已生成",
    alertAudioFailed: "翻译语音生成失败",
    alertNoAudioGenerated: "没有生成语音，因为翻译没有更新",
    alertErrorCreateTranscription: "生成文字转录出错",
    //Share workflow
    shareWorkflow: "分享工作流程",
    fullWorkflow: "全部工作流程",
    specificSteps: "指定步骤",
    chooseShareType: "选择分享方式",
    qrCode: "二维码",
    link: "链接",
    embedLink: "嵌入式链接",
    privateWorkflow: "私有工作流程",
    publicWorkflow: "公开工作流程",
    close: "关闭",
    saveQRCode: "保存二维码",
    download: "下载",
    copyLink: "复制链接",
    copied: "已复制",
    downloading: "正在下载",
    videoPrivacy: "视频隐私",
    popupMsgVideoPrivacy: "当你公开工作流程时，即使没有DeepHow帐户，任何人都可以访问。 你确定吗？",
    includeSubtitles: "有字幕",
    noSubtitles: "无字幕",
    selectLanguage: "选择字幕语言",
    workflowReady: "工作流程已准备好下载!",
    prepareToDownload: "生成下载文件",
    processingDownloadVideoMsg: "请不要关闭此页面!<br />几分钟内就可以生成下载文件。",
    processingWorkflow: "正在生成工作流程...",
    openAnotherTabMsg: "在等候过程中，你可以打开一个新标签页来浏览DeepHow",
    openNewTab: "打开新标签页",
    alertErrorPreparingDownload: "生成下载文件发生了错误，请再次尝试，或者联系DeepHow获得协助。",
    hide: "隐藏",
    addNewLink: "添加新链接",
    clickToAddLinks: "点击为此步骤添加链接",
    addALink: "添加一个链接",
    editLink: "编辑链接",
    addLinkAddress: "请输入要添加到此步骤的链接",
    addLinkLable: "你想添加一个标签吗?",
    clickOpenOfficialDocument: "例如：点击查看官方文档",
    //settings /categories
    settings: "设置",
    categories: "分类",
    noCategories: "你还没有创建分类",
    selectCategoriesMessage: "选择至少一个分类，以便用户可以更轻松地过滤整个内容",
    closeSettings: "关闭设置",
    popupMsgCloseSettings: "你有尚未保存的更改。 如果继续，<b>所有更改</b>即将丢失。你确定吗？",
    workflowCategories: "工作流程分类",
    popupBtnAssignCategoriesAndPublish: "设置分类并发布",
    popupBtnSkipAndPublish: "忽略这个步骤并继续发布",
    popupMsgworkflowCategories: "发布之前，为什么不为工作流程设置一些分类，以便用户可以更轻松地找到它。",
    alertCategoriesSaved: "分类保存成功。",
    alertCategoriesSaveFailed: "分类保存失败。",
    searchForCategoryName: "在{name}中搜索...",
    loadingCategories: "正在加载分类...",
    alertFailedDownloadVideo: "视频下载失败",
    // Workflow Cover
    workflowCover: "工作流程封面",
    selectFromTheVideo: "从视频中选择",
    browseForAFile: "从本地选择",
    dropFileMessage: "拖拽添加图片或点击由本地选择",
    selectedImage: "选择的图片",
    noImageSelected: "未选择图片",
    alertSuccessfullyUpdatedCover: "工作流程封面更新成功。",
    alertFailedUpdatedCover: "工作流程封面更新失败。",
    selectAnotherImage: "选择另一张图片",
    alertErrorStepLostTranscription: "你有还未被翻译的步骤，请翻译以后再下载。",
    annotation: "注释",
    allNotes: "所有注释",
    noNotesAddedYet: "尚未添加注释",
    popupMsgEnterTrim: "你的步骤已存在注释。如果你想截取视频，这些注释将被清除。",
    popupMsgEnterErase: "你的步骤已存在注释。如果你想删除视频，这些注释将被清除。",
    popupMsgEnterAnnotation: "在添加任何注释之前，请确保已先操作截取或删除视频。",
    workingOn: "正在处理",
    eraseStep: "删除步骤",
    popupMsgEraseSuccess: "你的步骤片段删除成功！",
    popupBtnYesErase: "是，删除",
    popupMsgEraseConfirmation: "确定要删除吗？",
    eraseProcessingMsg: "正在处理工作流程时，无法使用删除。请稍后再回来",
    translateOnlyTitlesBtn: "翻译标题和步骤名称",
    untitled: "暂无标题",
    hintNoEmptyTitle: "标题不能为空",
    popupMsgSwitchTranslation: "你有未保存的改动在<b>{lang}</b>翻译中。你想在切换翻译之前保存改动吗？",
    alertNoEmptyContent: "标题和句子不能为空，请输入后再保存。",
    PDF: "PDF",
    PDFSizeLimit: "PDF 文件大小限制为 50 MB",
    addPDF: "添加 PDF",
    onlyPDFAccepted: "只支持 PDF 格式文件",
    preview: "预览",
    // Send to reviewer
    sendToReviewer: "提交审核",
    alertTitleSelectReviewers: "选择用户来审核你的工作流程",
    alertSendToReviewSuccess: "工作流程已成功提交审核。",
    alertSendToReviewFailed: "提交审核失败",
    subtitlesSize: "字幕大小",
    small: "小",
    medium:"中",
    big: "大",
    yourVideoReadyToDownload: "你的视频可以下载了。",
    yourVideoWasSuccessfullyDownloaded: "你的工作流程已成功下载。",
    notAvailable: "无法使用",
    downloadFileFormat: "格式",
    selectDownloadWorkflowQuality: "选择视频质量",
    downloadSizeLimitedMsg: "下载{resolution}的SCORM支持长达{duration}分钟的工作流程。",
    workflowTotalDurationMsg: "工作流程总持续时间: {minute} 分 {second} 秒",
    // Export Compliance Wire
    export: "导出",
    code: "代码",
    organization: "组织",
    approvalDate: "批准日期",
    processing: "处理中",
    workflowShouldBePublic: "工作流应该是公开的。",
    alertExportComplianceWireSuccess: "成功导出控制文件。代码：{code}",
    alertExportComplianceWireExists: "控制文件已存在。代码：{code}",
    alertExportComplianceWireFailed: "导出控制文件时出现问题。",
    confirmedPublished: "你确定要發布工作流程嗎？<br> 如果你發布後，每个人都可以观看此工作流程。",
    versionDescription: "版本 {num} 描述 (非必填):",
    versionIsDuplicated: "版本重复",
  },
  editor: {
    closeWorkflow: "关闭工作流程",
    popupMsgCloseWorkflow: "你的工作流程中有尚未保存的更改。 如果继续，<b>所有更改</b>即将丢失。你确定吗？",
    popupBtnClose: "是，关闭",
    processWorkflow: "处理工作流程",
    popupMsgProcessWorkflow: "好极了！ 看上去你已经完成了审阅，并且希望Stephanie继续处理。一旦继续，你将<b>无法返回</b>此审阅页面，你准备好了吗？",
    popupBtnProcess: "是，继续",
    alertIsSaving: "正在保存。",
    alertNoEmptySentence: "句子不能为空，请输入或删除句子后再保存。",
    alertIsSaved: "修改已经保存。",
    alertWasUnpublished: "工作流程已成功取消发布。",
    alertWasPublished: "工作流程已成功发布。",
    alertNoMoreSteps: "这里不能再添加新步骤了。",
    alertNoEmptyTitle: "标题不能为空，请输入标题后再保存。",
    scrollToVoice: "定位到当前字幕",
    noAudioMsg: "在此工作流程中我们没有找到可以转录成文字的语音...",
    youWillDeleteStep: "你将删除步骤",
    areYouSure: "你确定吗?",
    areYouSureDelete: "你确定要删除吗?",
    replace: "替换",
    keyword: "关键字",
    find: "查找",
    replaceWith: "替换为",
    replaceAll: "全部替换",
    replaceAllMsg: "将会全部替换",
    of: "/",
    msgToSteps: "正在分析你的工作流程，<br>请稍等...",
    msgToEditor1: "发布之前让我们来审阅工作流程",
    msgToEditor2: "别担心，我会引导你进行下一步操作",
    msgToBuilder1: "马上就好，再等一下",
    msgToBuilder2: "我正在为你的工作流程创建步骤",
    newStep: "新步骤",
    hintNoEmptySentence: "句子不能为空",
    hintNoEmptyTitle: "(标题不能为空)",
    alertNoTranscription: "这个工作流程没有文字转录",
    alertEmptyingTranscription: "警告：不支持文字转录结果为空的视频",
    alertStepCorrupted: "步骤{count}是损坏的，请在进一步处理前删除它。",
    // Editor 2.0
    trim: "截取",
    erase: "删除",
    voiceOver: "配音",
    videoEditing: "视频编辑",
    segmentation: "划分步骤",
    popupMsgRestToOriginal: "你确定你要把视频恢复至原始状态吗？你将丢失已保存的所有内容。",
    trimHintMessage: "使用两侧的箭头来选择你想截取的部分",
    eraseHintMessage: "使用两侧的箭头来选择你想删除的部分",
    voiceOverHintMessage: "使用两侧的箭头来选择你想配音的部分",
    voiceOverStart: "录音",
    voiceOverStartHint: "点击这里开始录音…",
    voiceOverSave: "保存录音",
    voiceOverCancel: "放弃",
    voiceOverCancelPopupMsg: "你确定你要删除你的录音吗？",
    voiceOverPause: "暂停",
    voiceOverResume: "继续",
    unableToGetAudioInput: "没有检测到录音设备",
    restart: "重新开始",
    editVideo: "编辑视频",
    popupRequestTranscription: "工作流程尚未有字幕，请产生新的字幕",
    popupMsgEditVideoWarning: "编辑视频可能会导致字幕改变，你确定要编辑吗？",
    popupMsgReTranscriptionWarning: "提醒：如果你使用编辑视频功能（像是：录音/截取/删除)，你将无法切换转录语言。",
    popupMsgReTranscriptionWarningMultiEditing: "提醒：如果你使用编辑视频功能（像是：剪辑/录音)，你将无法切换转录语言。",
    rotate: "旋转",
    rotateLeft: "往左旋转",
    rotateRight: "往右旋转",
    goBack: "返回",
    goBackToEditorHome: "返回编辑器列表",
    zoom: "缩放",
    // transcription new features
    transcriptionCopySuccess: "文字转录复制到你的剪贴簿",
    keyTerms: "关键字",
    AILabs: "AI 实验室",
    copyTranscription: "复制文字转录",
    changeLanguage: "更换语言",
    copy: "复制",
    changeLanguageTitle: "请为你的文字转录选择新语言",
    changeLanguageSubTitle: "Stephanie将会重新处理你的文字转录",
    reProcessingYourVideo: "Stephanie正在重新处理你的视频...",
    goBackToEditor: "你可以在这里等待或返回编辑器继续工作！",
    // keyterms: added on 08/09/2022
    addTerm: "添加关键字",
    removeTerm: "删除关键字",
    termsFound: "找到<span>0</span>个关键字 | 找到<span>1</span>个关键字 | 找到<span>{count}</span>个关键字",
    loadingTerms: "加载关键字",
    termsInfo: "在工作流程中搜索特定内容时，关键字可能非常有用。",
    // Editor 3.0
    cut: "剪辑",
    tabsWarningMessages: "警告！您所做的修改尚未保存。如果离开此页面,您的修改将会丢失。",
    versionHistory: "版本历史",
    seeMore:"观看更多...",
    seeLess:"观看更少...",
    workflowCreated:"工作流程",
    createdBy: "由{name}创建",
    publishedBy: "由{name}发布",
    // Version History add by andy 01/08/2023
    lastUpdateBy: "最后更新為{name}",
    emptyTitle: "还没有版本！",
    emptyContent: "此工作流程尚未发布，首次发布后，你将开始在此处看到版本。"
  },
  player: {
    loadingVideo: "正在加载视频...",
    loadingLanguage: "正在加载语音...",
    previousStep: "上一步",
    nextStep: "下一步",
    audio: "声音",
    subtitles: "字幕",
    autoPlay: "自动播放",
    off: "关",
    on: "开",
    quality: "画质",
    speed: "播放速度",
    normal: "正常",
    rotatePhone: "<span class='primary--text'>旋转</span>屏幕以获取<br>更好的体验",
    alertTranscodingFailed: "工作流程转码失败，请联系DeepHow获得协助。",
    alertTranscodingStillProgress: "这个工作流程仍在转码中，请稍等。",
    settings: "设置",
    openInBrowser: "在浏览器中打开",
    goToHome: "回到主页",
    thisWorkflowWasUnpublished: "工作流程未发布",
    thisWorkflowWasDeleted: "工作流程已删除",
    talkToSupervisor: "如果你认为这个工作流程应为发布状态，请与你的主管沟通",
    selectWorkspace: "选择工作空间",
    // player 2.0:
    home: "主页",
    myWorkflows: "我的工作流程",
    recommendedForYou: "为你推荐",
    favorites: "收藏",
    welcomeToDeepHowNavigator: "欢迎来到DeepHow导航器",
    numNewSkillsTrainings: "<span>有{count}个技能</span><br>指派了给你。",
    clickForTips: "点击此处查看温馨提示。",
    learnHow: "查看提示",
    goToSkills: "进入技能",
    at: "在",
    youAreIn: "正在浏览",
    loadingRecommendations: "正在加载推荐...",
    loadingFavoriteWorkflows: "正在加载收藏的工作流程...",
    noFavoriteWorkflows: "你还没有添加内容到收藏",
    canFindSavedItemsHere: "你可以在此找到你所有的收藏的内容",
    removeWorkflowFromFavorites: "你确定你要把这个工作流程从收藏中移除吗？",
    yesGoAhead: "是的，移除",
    thisWorkflowIsNotAvailable: "工作流程不可用",
    dismiss: "不再显示",
    navigator: "导航器",
    howToUseNavigator: "如何使用<br><b>DeepHow导航器</b>",
    checkTheNewFeatures: "查看新功能。",
    unknownUser: "未知用户",
    okCloseBtn: "好的，关闭",
    emptyRecommendWorkflowTitle: "内容在哪里？",
    emptyRecommendWorkflowDescription: "别担心！只要有新的工作流程创建。我就会开始给你推荐内容。期待你早点回来！",
    thisWorkflowDoesNotExist:"此工作流程不存在。",
    // navigator
    resume: "上次看到",
    myWorkspaces: "我的工作空间",
    otherWorkspaces: "其他工作空间",
    loading: "正在加载...",
    loadingfavoriteWorkspaces: "正在加载收藏的工作空间...",
    removeWorkspaceFromFavorites: "你确定你要把这个工作空间从收藏中移除吗？",
    YouAreViewing: "正在浏览",
    allWorkspaces: "全部工作空间",
    removeFromFavorites:"从收藏中移除",
    favoriteThisVideo:"收藏这个视频",
    shareThisVideo: "分享此视频",
    viewAll: "查看全部",
    openFile: "打开文件",
    suggestedKeyterms: "推荐关键词",
    // workflow player added 10/10/2022
    posted: "发布于",
    views: "{count}次观看",
    saveFavorite: "收藏",
    react: "反馈",
    numColleaguesReact: "{count}个同事已反馈",
    youAndNumColleaguesReact: "<span>你</span>和{count}个同事已反馈",
    youReacted: "<span>你</span>已反馈",
    userReaction: "用户反馈",
    fullscreen: "全屏",
    exitFullscreen: "退出全屏",
    // 1/23/2023 Comments
    comments: "评论",
    deleteComment: "删除评论",
    deleteCommentMsg: "你确定你要删除这条评论吗？<br>此操作不可撤销。",
    edit: "编辑",
    updating: "更新中...",
    changesSaved: "已保存",
    noComments: "还没有评论...",
    makeFirstComment: "来添加第一条评论吧。",
    comment: "评论",
    writeAComment: "输入评论...",
    edited: "已编辑",
    emojiRecent: "经常使用",
    emojiSmileys: "笑脸表情和符号",
    emojiPeople: "人员和动作",
    emojiNature: "动物和自然",
    emojiFoods: "食物和饮料",
    emojiActivity: "活动",
    emojiPlaces: "旅游和地点",
    emojiObjects: "物品",
    emojiSymbols: "符号",
    emojiFlags: "旗帜",
    emojiCustom: "自定义",
    back: "返回",
    feedback: "建议",
    leaveAFeedback: "留下一个建议",
    leaveAFeedbackMsg: "告诉我们任何问题，意见反馈，甚至建设性的批评。",
    typeYourMessageHere: "在这里输入你的信息",
    submit: "提交",
    thankYou: "谢谢",
    feedbackSuccessMsg: "你的建议已发送成功。",
    alertFeedbackFailedMsg: "你的建议发送失败，请再次尝试。",
    diagrams: "图片",
    pdfFiles: "PDF文件",
    theaterMode: "剧场模式",
    exitTheaterMode: "退出剧场模式",
    // TODO: 09/08/2023
    enableWorkflowRepeat: "启用工作流程重复",
    enableStepRepeat: "启用步骤重复",
    disableRepeat: "禁用重复",
  },
  profile: {
    // added 12/09/2021
    addPositionLocation: "添加职位和工作地点",
    noWorkflowsCreated: "尚未创建工作流。",
    comeBackLater: "晚一点回来。",
    createdWorkflows: "创建的工作流程",
    profileOwnerNoContentMsg: "<span>你好，{name}！这是你的新的个人资料页面。</span> <br>将来你可以和所有人分享知识，请稍等！",
    profileColleguesNoContentMsg: "<span>这是{name}的个人资料页面.</span> <br>他还不能发布内容，但是不久的将来他便可以！",
    editProfile: "编辑个人资料",
    personalInformation: "个人信息",
    personalInformationMsg: "访问你的个人资料的用户将会看到以下内容。",
    photo: "头像",
    position: "职位",
    locationRegion: "工作地点/区域",
    whatYouDo: "让人们知道您在这个组织中做什么。",
    whereYouBased: "让人们知道您所在的工作地点。",
    accountManagement: "帐户管理",
    accountManagementMsg: "以下信息是私人的，不会出现在你的公开个人资料中。",
    identifyMyself: "我的性别",
    yourPassword: "你的密码",
    nonDeclared: "未选择（默认）",
    male: "男性",
    female: "女性",
    nonBinary: "非二元性别",
    viewProfile: "查看个人资料",
    alertFailedToUpdateProfile: "更新档案失败。",
  },
  explorer: {
    search: "查找",
    in: "",
    howTo: "查找知识...",
    didYouMean: "相关词条",
    searchInTitle: "标题",
    searchInContent: "内容",
    searchInVideo: "Stephanie建议",
    title: "标题",
    searchStartMsg1: "你在找什么？",
    searchStartMsg2: "请使用上面的搜索框",
    sortBy: "排序",
    lastUpdated: "最近更新",
    alphabetically: "按名称",
    workflowsIn: "工作流程于",
    tagNew: "新建",
    tagProcessing: "正在处理",
    msgProcessing: "Stephanie正在处理你的工作流...<br/>请稍等 : )",
    tagFailed: "失败",
    msgFailed: "啊哦，处理视频时候出错了，<br/>我们稍后再试...",
    msgVideoLimitExceeded: "视频超过了DeepHow支持的长度。它将在两个或更多的工作流程中自动重新上传。",
    msgVideoExceededMaximum: "视频超过了DeepHow支持的长度。它将自动被划分成两个以上的工作流程。",
    tagTranscoding: "正在转码",
    msgTranscoding: "Stephanie正在转码你的工作流...<br/>请稍等 : )",
    deleteWorkflow: "删除工作流程",
    popupBtnDelete: "是，删除",
    popupMsgDeleteWorkflow: "你确定要删除此工作流程吗？ 你将丢失与此工作流程相关的所有步骤和数据。",
    noUnpublishedWorkflows: "你还没有未发布的工作流程...",
    noPublishedWorkflows: "你还没有已发布的工作流程...",
    startCreatingWorkflow: "让我们开始创建工作流程吧!",
    startPublishingWorkflow: "让我们开始发布工作流程吧!",
    noSearchResult: "未找到结果…",
    trySearchAgain: "请重试",
    searchingForWorkflows: "正在查找工作流程...",
    secondsAgo: "{count}秒前",
    minutesAgo: "{count}分钟前",
    hoursAgo: "{count}小时前",
    daysAgo: "{count}天前",
    monthsAgo: "{count}个月前",
    yearsAgo: "{count}年前",
    sAgo: "{count}秒前",
    minAgo: "{count}分钟前",
    hAgo: "{count}小时前",
    dAgo: "{count}天前",
    mAgo: "{count}个月前",
    yAgo: "{count}年前",
    renameWorkflow: "重命名工作流程",
    moveWorkflowTo: "移动工作流程到",
    duplicateWorkflow: "复制工作流程",
    unpublishWorkflow: "取消发布",
    shareKnowhow: "分享工作流程",
    or: "或者",
    clickHere: "点击此处",
    andCopyMsg: "可复制链接用于邮件分享",
    successfulSharing: "分享成功",
    send: "发送",
    typeHereAMessage: "在这里输入留言",
    wellDone: "很棒",
    numMore: "另外{count}人",
    moveWorkflow: "移动工作流程",
    currentWorkspace: "当前工作空间",
    whereToMsg: "你想把这个工作流程移到？",
    move: "移动",
    alertSuccessfullyMoved: "成功移动工作流程",
    alertFailedMoved: "移动工作流程失败",
    noNotificationsMsg: "你目前没有收到消息",
    betaSearch: "请留意Stephanie建议的仍是bata版本...",
    relevantSteps: "相关步骤:",
    relevance: "相关",
    resultsFor: "{num}个搜索结果",
    resultFor: "{num}个搜索结果",
    byPublisher: "发布者",
    alertFailedShareWorkflow: "分享工作流程失败。",
  },
  new: {
    newWorkflow: "新工作流程",
    uploadVideo: "上传视频",
    recordScreen: "录制屏幕",
    replaceVideoSubtitle: "拖拽或点击来替换视频",
    recordScreenSubtitle: "Stephanie已经准备好录制你的屏幕",
    workflowName: "工作流程名称",
    workflowLanguage: "工作流程语言",
    addToWorkspace: "添加到",
    upload: "上传",
    startRecording: "开始录制",
    cancel: "取消",
    wrongUploadFile: "不支持该文件格式。仅支持:",
    limitFileDuration: "可支持的最长视频时间为{duration}分钟，使用Windows app版本DeepHow上传工具可以上传更大的文件",
    limitFileDurationGen2: "可支持的最长视频时间为{duration}分钟",
    uploadingVideo: "正在上传视频",
    uploadingVideoSubtitle: "请稍等，Stephanie正在上传你的视频",
    renderingVideo: "正在渲染/保存视频",
    renderingVideoSubtitle: "请稍等，Stephanie正在渲染你的视频",
    almostDone: "马上就好！",
    uploadingStep1: "2步中的第1步，分析工作流",
    uploadingStep2: "正在上传视频...",
    successSubtitle: "你的工作流已经成功上传",
    goToEditor: "转到编辑器",
    goToContentManger: "转到内容管理",
    uploadingAlert: "离开或关闭浏览器标签页会导致上传错误",
    recordingScreen: "正在录屏",
    support15Min: "请注意可支持的最长屏幕录制时间为<b>15分钟</b>",
    hereWeGo: "准备开始!",
    remember15Min: "请记得你有15分钟",
    windowsAppAvailable: "Windows app<br>现已上线",
    windowsAppAvailableNews: "Windows app版本DeepHow上传工具现已上线. 你可以上传更大的文件和进行屏幕录制。",
    downloadNow: "点击下载！",
    deepHowWindowsApp: "DeepHow Windows App",
    downloadAndInstalltheWindowsApp: "下载并安装Windows App",
    notAllowedToInstall: "如果你无法安装上方的版本，",
    downloadThisVersion: "下载此版本",
    and: "并且",
    watchThisVideo: "<span>观看解说视频</span>。",
    // Added on 2022/01/19
    abiAuthorizationLabel: "本人确认在此视频中的所有人都已签署了<span>照片/视频录制授权</span>并且在我的国家特定发行。",
    abiPrivacyLabel: "本人同意我已阅读并理解ABInbev<span>照片/视频录制隐私声明。</span>",
    // Added on 2022/06/15
    importFromZoom: "从Zoom导入",
    importFromZoomSteps: "第 {step} 步，共 {totalStep}",
    selectVideosFrom: "选择视频，從",
    videoSelected: "<span>0</span> 个视频已选择 | <span>1</span> 个视频已选择 | <span>{count}</span> 个视频已选择",
    workflowInfo: "工作流程信息",
    newImport: "新的导入",
    orBackToEditor: "或返回<span>编辑器</span>",
    importFromZoomSuccessMsg: "你的视频将很快开始上传，可能需要一些时间才能进行编辑。",
    zoomFailedUploadMsg: "上传Zoom视频失败",
    zoomPeriodLastMonth: "上个月",
    zoomPeriodPastMonth: "过去{n}个月",
    loadingZoomRecordings: "正在加载视频",
    zoomNoRecordingsInfo: "未找到所选时段的录音。",
    yourVideoExceedTwoHours: "您的视频超过 2 小时故无法上传。",
    yourVideoExceed3GSize: "您的视频超过 3 GB 故无法上传。",
    yourVideoExceedTwoHoursAnd3GSize: "您的视频超过 2 小时或 3GB，故无法上传。",
    videoUploadWarningMessage: "点击或将视频拖拽至此。仅支持MP4或WebM格式，最大支持单个文件不超过 3 GB 或超过 2 小时。",
    videoUploadWarningMessageGen2: "点击或将视频拖拽至此。仅支持MP4或WebM格式，最大支持单个文件不超过40分钟",
  },
  auth: {
    aboutDeephow: "关于DeepHow",
    authTitle: "首个针对技能培训的人工智能解决方案。",
    authIntro: "DeepHow是一个人工智能技术赋能的交互式操作视频学习平台，弥合了制造，服务和维修领域的技能鸿沟。",
    allRightReserved: "保留所有权利",
    logIn: "登录",
    logOut: "登出",
    logInSubtitle: "请输入你的详细信息以登录",
    logInSubtitleSso: "请点击下面按钮登录",
    emailOrId: "电子邮件或员工ID",
    hello: "你好, ",
    passwordSubtitle: "请输入你的密码",
    password: "密码",
    forgotPassword: "忘记密码",
    forgotPasswordSubtitle: "请输入你的电子邮件，以便我们向你发送链接来创建新密码",
    email: "邮箱",
    resetPassword: "重置密码",
    alertResetSuccess: "请检查你的电子邮箱收件箱",
    alertResetFailed: "恢复密码失败。",
    alertInvalidId: "无效员工ID。",
    successMsg: "请查看你的电子邮箱，并点击链接为你的帐户设置一个新密码。",
    logInWithSSO: "使用SSO登录",
    loggingInWithSSO: "正在使用SSO登录...",
    logInWithGoogle: "使用Google账户登录",
    logInWithMicrosoft: "使用Microsoft账户登录",
    loadingSso: "正在加载SSO登录...",
    about: "关于",
    // 12/17/2021 iframe
    loginToYourAccount: "登录你的账户",
    pleaseInputYourPassword: "请输入你的密码",
    backToLogin: "回到登录页",
    // added 03/02/2022
    wrongPassword: "密码错误。",
    // Shared Account
    sharedDevice: "共享设备",
    loginExistingAccount: "用现有帐户登入",
    createNewAccount: "新建一个账户",
    noUsersMsg: "目前还没有用户登录此共享设备...",
    insertIDMsg: "请输入你的员工ID",
    createIdUserMsg: "马上就好！<br>请在下方输入你的信息。",
    confirmEmployeeID: "确认员工ID",
    welcomeToDeepHow: "欢迎来到DeepHow，<b>{name}</b>",
    confirmWorkspaceTeamMsg: "请确认你的工作空间和团队。",
    LetsGetStarted: "让我们开始吧！",
    // added 05/26/2022
    useSsoLogin: "你的组织没有启用邮箱/密码的登录，请使用SSO登录。",
    // added 02/17/2023
    cookieAlert: "我接受<a href='{pdfUrl}' target='_blank'>隐私政策</a>中详细说明的cookie。",
    useCookie: "我们使用Cookies",
    useCookieContext: "我们使用cookie来确保使用DeepHow拥有很棒的体验！",
    privacyPolicy: "隐私政策",
    acceptCookies: "接受Cookies"
  },
  rules: {
    required: "必填",
    invalidEmail: "无效电子邮箱",
    noSpace: "不能加入空格",
    validPassword: "密码至少6个字符，包含数字，大小写字母",
    maxPassword: "最多16分字符",
    mustMatch: "密码必须一致",
    max25: "最多25个字符",
    max90: "最多90个字符",
    max100: "最多100个字符",
    max140: "最多140个字符",
    numMaxCharacters: "最多{count}个字符",
    min6: "最少6个字符",
    noWhiteSpace: "不能用空格作为标题",
    noAllSpace: "不能用空格作为名称。",
    invalidEmployeeID: "无效员工ID",
    invalidUrl: "无效的URL",
    mustMatchId: "员工ID必须一致",
    canNotEnterSharedAccount: "不能输入共享账户ID",
    validPasswordMin10: "密码至少10个字符，包含数字，特殊字符，大小写字母",
    invalidName: "无效的名称。",
  },
  alert: {
    success: "成功",
    info: "提示",
    alert: "警告",
    error: "错误",
    dontShowThisMsgAgain: "不再提示此消息",
    unexpectedError: "发生非预期错误，请再次尝试，或者联系DeepHow获得协助。",
  },
  skills: {
    skillsMatrix: "技能矩阵",
    createSkill: "新建技能",
    skillsCreated: "创建的技能",
    skillsPublished: "发布的技能",
    skillsIn: "技能于",
    hintSearchSkills: "搜索技能…",
    lastUpdated: "最近更新",
    skillsPerPage: "每页技能数",
    usersAssigned: "分配的用户",
    status: "状态",
    createdBy: "创建者",
    skillsSelected: "个选中的技能",
    updatedBy: "更新者",
    deleteSkill: "删除技能",
    popupMsgDeleteSkill: "你确定要删除这个技能吗?",
    deleteSkills: "删除技能",
    popupMsgDeleteSkills: "你确定要删除这些技能吗?",
    clearAll: "重选",
    clearAllFilters: "清除全部筛选",
    clearFilter: "清除筛选",
    delete: "删除",
    youAreViewing: "你正在浏览",
    numWorkflows: "{count}个工作流程",
    searchIn: "搜索", //08.03.2021
    loadingSkillMatrix: "正在加载技能矩阵...",
    createSkillSubtitle: "请输入标题并选择要上传的工作空间",
    skillName: "技能标题",
    create: "新建",
    editSkill: "编辑技能",
    popupMsgEditSkill: "如果进入编辑模式，你的技能将自动取消发布，确定要继续吗?",
    publishSkill: "发布技能",
    popupMsgPublishSkill: "你将要发布你的技能，用户将可以看到它，确定要这样做吗？",
    alertFailedSaveSkill: "保存技能失败",
    alertSkillSuccessfullyPublished: "你的技能已成功发布",
    alertSkillSuccessfullyUnpublished: "你的技能已成功取消发布",
    alertSkillSuccessfullySaved: "你的技能已成功保存",
    alertFailedSavedSkill: "保存技能失败",
    alertFailedPublishSkill: "发布技能失败",
    alertSkillSuccessfullyDeleted: "你的技能已成功删除",
    alertSkillFailedDeleted: "删除技能失败",
    content: "内容",
    addContent: "添加内容",
    workflowDeleted: "工作流程已删除",
    workflowsSelected: "个选中的工作流程",
    selectAll: "全选",
    remove: "移除",
    noContentMsg: "这个技能还没有添加内容",
    assignUsers: "添加用户",
    noUserMsg: "你还没有添加用户到这个培训中",
    numUsers: "<b>{count}</b> 个用户",
    assignedOn: "添加于",
    certifiedBy: "认证",
    progress: "进度",
    aletUserSuccessfullyRemoved: "用户已被成功移除",
    removeUser: "移除用户",
    popupMsgRemoveUserFromSkill: "你确定要把<b>{name}</b>从这个技能中移除吗？",
    assign: "添加",
    successfulAssigned: "添加成功",
    AddedToSkill: "已添加",
    loadingSkills: "正在加载技能...",
    searchingForSkills: "正在搜索技能...",
    toDo: "待完成",
    completed: "已完成",
    assignedBy: "分配人",
    min: "分钟",
    nextPart: "下一部分",
    noSkillsAssignedMsg: "你还没有待完成的技能",
    noSkillsAssignedMsg2: "有新消息时我们会通知你!",
    noCompletedSkillsMsg: "你还没有已完成的技能",
    noCompletedSkillsMsg2: "让我们开始学习技能吧!",
    noDataSkillsMatrix: "技能矩阵暂无数据...",
    certificate: "合格认证",
    certificateMsg: "用户完成技能学习后会得到一个合格证书。",
    selectCertificateMsg: "选择可以认证这项技能的用户：",
    validateSkill: "认证技能",
    validateSkillMsg: "你确定要为<b>{name}</b>认证技能{skill}吗?<br />你将无法撤销认证。",
    yesValidate: "是，认证",
    noValidate: "返回",
    certifier: "认证人",
    signaturesOn: "于",
    teamProgress: "团队进度",
    openTeam: "打开团队",
    removeCertificate: "移除认证",
    removeCertificateMsg: "你将移除全部可以认证这项技能的用户，你确定吗？",
    yesRemove: "是，移除",
    mustAddUserMsg: "* 如果没有添加用户，认证不会被激活。",
    skillCover: "技能封面",
    alertSuccessfullyUpdatedSkillCover: "技能封面已成功更新。",
    alertFailedUpdatedSkillCover: "技能封面更新失败。",
    closeSkill: "关闭技能",
    allWorkflowsDeleted: "此技能中所有工作流程已被删除。",
    waitingForValidation: "等待认证",
    validatedBy: "认证人:",
    skillWasUnpublished: "这个技能已取消发布",
    skillWasUnpublishedMsg: "当这个技能再次发布时我会通知你。",
    notAssignedToSkill: "这个技能没有被分配给你",
    notAssignedToSkillMsg: "抱歉，只有被分配了这个技能的用户才可以查看内容。",
    remindPendingUsers: "提醒未完成的用户",
    alertSuccessRemindPendingUsers: "提醒未完成的用户成功",
    alertFailedRemindPendingUsers: "提醒未完成的用户失败",
    alertNoPendingUsers: "未找到未完成的用户",
    numVideo: "0个视频 | 1个视频 | {count}个视频",
    // Skill Deadline
    deadline: "截止日期",
    deadlineMsg: "为用户设置一个完成此技能的学习的统一期限。在截止日期之后，未完成的用户将会收到通知。",
    deadlineForThisSkill: "开启此技能的截止日期功能",
    certificateForThisSkill: "开启此技能的认证功能",
    deadlineStartsMsg: "截止倒计时从这个用户被添加到这个技能开始计算。",
    selectAPeriod: "选择一个期限",
    finished: "完成",
    editDeadline: "修改截止日期",
    editDeadlineMsg: "截止日期被修改后，之前的截止日期将不再有效，新期限将适用于该技能的所有用户。<br><br>你确定要继续修改吗?",
    alertSuccessNewDeadline: "所有用户的截止日期已更新。",
    alertSuccessRemoveDeadline: "此技能一成功关闭截止日期功能。",
    alertFailedDeadline: "更新截止日期失败。",
    dueToday: "今天截止",
    turnOffDeadlineMsg: "截止日期被移除后，之前设置的截止日期会被删除，再次开启时会重设所有用户的截止日期。<br><br>你确定要继续修改吗?"
  },
  time: {
    numDays: "0天 | 1天 | {count}天",
    numWeeks: "0周 | 1周 | {count}周",
    numMonths: "0个月 | 1个月 | {count}个月",
    numDaysLeft: "剩余0天 | 剩余1天 | 剩余{count}天",
    numDaysDelayed: "过期0天 | 过期1天 | 过期{count}天",
  },
  notification: {
    sharedWorkflow: "分享了工作流程",
    assignedNewSkill: "分享了技能",
    sentAnnouncement: "发送了公告",
    needValidation: "需要你的认证",
    noNotificationsMsg: "你目前没有收到消息",
    justNow: "刚刚",
    updatedSkill: "更新了技能",
    unpublishedSkill: "取消发布了技能",
    sentAReminder: "提醒你要完成这个技能学习。开始观看后，他可以查看你的学习进度",
    assignedNewSkillDue: "提醒你要完成这个技能学习。你有<span>{time}</span>时间来完成它。",
    delayedSkillMsg: "你有一个<span>过期的技能</span>。开始观看以赶上进度！",
    daysLeftMsg: "你仅剩<span>{time}</span>时间来完成这个技能的学习。请加快速度，这样你就不会延迟了！",
    askToReviewWorkflow: "请你审核这个工作流程。",
    reactedWorkflowMsg: "<span>{user}</span>反馈了你的工作流程。",
    reactedWorkflowMsgTwoUsers: "<span>{user1}和{user2}</span>反馈了你的工作流程。",
    reactedWorkflowMsgMoreUsers: "<span>{user1}，{user2}和其他{count}人</span>反馈了你的工作流程。",
  },
  mediaPicker: {
    insertFullWorkflow: "插入整个工作流程",
    selectWorkflowStep: "选择工作流程步骤",
    stepSelected: "个选中的步骤"
  },
  zoomIntegration: {
    settingsTitle: "Zoom设置",
    settingsSubtitle: "一体化",
    deleteCopy: "删除Zoom Cloud中录制的视频副本",
    goToDeephow: "进入DeepHow",
    landingSuccess: "<b>DeepHow Know-How Video App</b>安装成功",
    landingError: "安装<b>DeepHow Know-How Video App</b>的时候发生了错误。请联系<a href = 'mailto: service@deephow.com'>service@deephow.com</a>获取帮助。",
    landingWarning: "你的组织未启用Zoom一体化设置。",
  },
  reactions: {
    likeIt: "赞",
    greatContent: "内容优质",
    wellPresented: "表述清晰",
    niceProduction: "制作精良",
  },
  maven: {
    // TODO: maven chat added on 03/15/2023
    mavenChat: "Chat",
    searchInWorkspace: "Search in {workspace}",
    showReferences: "Show references",
    hideReferences: "Hide references",
    helloMessage: "Hello {userName},",
    assistMessage: "How can I assist you today?",
    askMeAnything: "Ask me anything...",
    tryOtherQuestionMessage: "You can try asking other question.",
    audioIsReady: "Please recording your audio clip in English...",
    audioLimitationTime: "The audio limitation time is 3 mins.",
    clearChatHistory: "Clear chat history",
    newLineHint: "new line: shift + Enter",
    // TODO: maven map added on 04/14/2023
    map: "Map",
    resetMap: "reset map",
    filterWorkspaces: "filter workspaces",
    showQuestions: "show questions",
    hideQuestions: "hide questions",
    showClusters: "show clusters",
    hideClusters: "hide clusters",
    question: "Question",
    loadingMap: "Loading map...",
    clearChatHistoryConfirmation: "Clearing the chat history will start a new chat. Are you sure?",
    clearConfirm: "Yes, clear",
  },
  $vuetify: {
    dataIterator: {
      rowsPerPageText: "每页记录数：",
      rowsPerPageAll: "所有",
      pageText: "{0}-{1} 共 {2} 条",
      noResultsText: "没有找到匹配记录",
      nextPage: "下一页",
      prevPage: "上一页"
    },
    dataTable: {
      rowsPerPageText: "每页行数："
    },
    noDataText: "无可用数据",
    carousel: {
      prev: "上一个",
      next: "下一个"
    }
  }
};
