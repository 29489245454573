export function getNumberWithZero({ num }) {
  if (num < 10) {
    return `0${num}`;
  }
  return String(num);
}

export function getPagingNumber({ current, total }) {
  const currentNum = getNumberWithZero({ num: current });
  const totalNum = getNumberWithZero({ num: total });
  return `${currentNum} / <span>${totalNum}</span>`;
}

export function formatBytes({ bytes, decimals = 2 }) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getLimitFileName({ nameLength, fileName }) {
  if (fileName.length <= nameLength) {
    return fileName;
  } else {
    return fileName.substring(0, nameLength - 8) + "..." + fileName.substring(fileName.length - 8, fileName.length);
  }
}

export function getFormattedDisplayDate({ timestamp }) {
  if (!timestamp) {
    return "-";
  }
  let seconds = 0;
  const isNum = isNumber(timestamp);
  if (isNum) {
    // unix timestamp: like 1666749413
    seconds = timestamp;
  } else if (Object.keys(timestamp).length === 0) {
    // {}
    return "-";
  } else {
    // { "_seconds": 1671675639, "_nanoseconds": 20000000 } { "seconds": 1671675639, "nanoseconds": 20000000 } { "seconds": 1671675639 }
    seconds = timestamp._seconds || timestamp.seconds;
  }
  const enrolledDate = new Date(seconds * 1000).toLocaleDateString();
  return enrolledDate;
}

function isNumber(num) {
  if (num !== null && num !== "" && num !== undefined) {
    return !isNaN(num);
  }
  return false;
}

export function getDisplayEmailOrId({ userProfile }) {
  if (!userProfile || Object.keys(userProfile).length === 0) return;
  if (userProfile.passwordless) {
    return userProfile.employeeId;
  }
  let emailOrId = userProfile.email;
  const emailName = emailOrId.slice(emailOrId.indexOf("@") + 1);
  if (emailName === "deephow.ai") {
    const employeeId = emailOrId.slice(emailOrId.indexOf(".") + 1, emailOrId.indexOf("@"));
    return employeeId;
  }
  return emailOrId;
}

export function getFormattedVideoDuration({ videoDuration }) {
  let sec = videoDuration;
  if (!sec || sec === Infinity) return "0:00";
  let minutes = Math.floor(sec / 60);
  let seconds = sec - minutes * 60;
  let padding = seconds < 10 ? "0" : "";
  return minutes + ":" + padding + Math.floor(seconds);
}
