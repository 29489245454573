<template lang="html">
  <section class="mixin-delete">
  </section>
</template>

<script>
//import { db } from "../main";
import MixinDB from "./MixinDB.vue";
import { deleteWorkflow } from "../server/api-server";
export default {
  name: "mixin-delete",
  props: [],
  mixins: [MixinDB],
  mounted() {},
  data() {
    return {};
  },
  methods: {
    // deleteImageMap(id){
    //   const self = this;
    //   const toDeleteList = [];
    //   return new Promise(function(resolve, reject) {
    //     if(id){
    //       self.$db.collection("imagemap").doc(id).get()
    //         .then(function(doc) {
    //           if (doc.exists) {
    //             const imageMap = doc.data().imageMap;
    //             //delete all the images in imageMap
    //             for(const image in imageMap){
    //               toDeleteList.push(self.deleteFile(imageMap[image]))
    //             }
    //             debugger
    //             Promise.all(toDeleteList).then(()=> {
    //               console.log("imageMap deleted");
    //             }).catch(err=>{
    //               console.error("imageMap delete error ");
    //             });
    //           } else {
    //             reject("No such document for imageMap!");
    //           }
    //         })
    //         .catch(function(error) {
    //           console.error("Error getting document for imageMap:", error);
    //           reject(error);
    //         });
    //     }else{
    //       console.log("no imageMap to delete");
    //       resolve();
    //     }
    //   });
    // },
    deleteFile(url) {
      const self = this;
      return new Promise(function(resolve, reject) {
        const fileRef = self.$firebase.storage().refFromURL(url);
        fileRef
          .delete()
          .then(function() {
            resolve();
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    // deleteDocument(collection, document){
    //   return new Promise(function(resolve, reject){
    //     db.collection(collection).doc(document).delete().then(function() {
    //         console.log("Document successfully deleted!");
    //         resolve();
    //     }).catch(function(error) {
    //         console.error("Error removing document: ", error);
    //         reject(error);
    //     });
    //   })
    // },
    deleteSteps(steps) {
      const self = this;
      const stepsToDelete = [];
      return new Promise(function(resolve, reject) {
        if (steps) {
          steps.forEach(step => {
            stepsToDelete.push(self.deleteDocument("steps", step));
          });
          Promise.all(stepsToDelete)
            .then(() => {
              console.log("steps deleted");
              resolve();
            })
            .catch(error => {
              console.error("steps delete error");
              console.error(error);
            });
        } else {
          console.log("no step to delete");
          resolve();
        }
      });
    },
    // deleteAddon(workflowId){
    //   const self = this;
    //   const addonsToDelete = [];
    //   const imagesToDelete = [];
    //   const documentsToDelete = [];
    //   return new Promise(function(resolve, reject){
    //     self.$db.collection("addons")
    //       .where("workflowId", "==", workflowId)
    //       .get().then(function(querySnapshot) {
    //         querySnapshot.forEach(function(doc) {
    //           addonsToDelete.push(doc.data());
    //         });
    //           addonsToDelete.forEach(addon=>{
    //           //delete the image
    //           Object.values(addon.img).forEach(image=>{
    //             imagesToDelete.push(self.deleteFile(image));
    //           });
    //           //delete the addon document
    //           documentsToDelete.push(self.deleteDocument("addons", addon.id));
    //           debugger
    //           Promise.all(imagesToDelete.concat(documentsToDelete)).then(()=>{
    //             console.log("addon deleted");
    //             resolve();
    //           }).catch(error=>{
    //             console.error("addon delete error");
    //             console.error(error);
    //             reject(error);
    //           })
    //         })
    //       })
    //       .catch(function(error) {
    //           console.log("Error getting documents: ", error);
    //       });
    //   })
    // },
    deleteForeignLanguage(workflow) {
      const self = this;
      const audioToDelete = [];
      const textToDelete = [];
      return new Promise(function(resolve, reject) {
        if (workflow.foreignLanguageAudio) {
          Object.values(workflow.foreignLanguageAudio).forEach(file => {
            audioToDelete.push(self.deleteFile(file));
          });
        }
        if (workflow.foreignLanguageText) {
          Object.values(workflow.foreignLanguageText).forEach(file => {
            textToDelete.push(self.deleteFile(file));
          });
        }
        Promise.all(audioToDelete.concat(textToDelete))
          .then(() => {
            console.log("deleted foreign language");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async deletePlaylist(playlist) {
      //TODO: user multiple batch writes if there are more than 498 users. Batchwrites limit is 500
      const writes = [];
      const userSkills = await this.getDocumentByQuery("userSkills", [
        {
          field: "playlistId",
          comparison: "==",
          value: playlist.id,
        },
      ]);
      userSkills.forEach(skill => {
        writes.push({
          type: "delete",
          collection: "userSkills",
          id: skill.id,
        });
      });
      writes.push({
        type: "set",
        collection: "playlists_delete",
        id: playlist.id,
        data: playlist,
      });
      writes.push({
        type: "delete",
        collection: "playlists",
        id: playlist.id,
      });
      const teamSkills = await this.getDocumentByQuery("teamSkills", [
        {
          field: "playlistId",
          comparison: "==",
          value: playlist.id,
        },
      ]);
      teamSkills.forEach(skill => {
        console.log(skill.id);
        writes.push({
          type: "delete",
          collection: "teamSkills",
          id: skill.id,
        });
      });
      this.batchWrites(writes)
        .then(() => {
          console.log("playlist deleted");
        })
        .catch(err => {
          console.log(err);
        });
    },
    async deleteWorkflow(workflowId) {
      try {
        const { ok, errorMessage } = await deleteWorkflow(workflowId);
        if (!ok) {
          console.log(errorMessage);
          return ok;
        }
        const findIndex = this.$store.state.workflows.findIndex(workflow => workflow.id === workflowId);
        if (findIndex) {
          this.$store.state.workflows.splice(findIndex, 1);
        }
        return ok;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
