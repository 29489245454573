// Admin

// Check character for Workspaces, Organization names, Teams, Categories, User attributes (User Imports - int web).
export function checkAdminCommonCharacters(input) {
  const reg = /[\[\];<>\\/?=:",]/;
  return reg.test(input);
}

export function checkDisplayNameCharacters(input) {
  const reg = /[;\[\]<>()\\/?&=:",]/;
  return reg.test(input);
}

export function checkEmailCharacters(input) {
  const reg =
    /^(([^<>()[\]\\/?&=.,;:\s@"]+(\.[^<>()[\]\\/?&=.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !reg.test(input);
}
