export async function getWorkspaceColorConfig({ mixinDbRef }) {
  const config = await mixinDbRef.getDocument("configurations", "workspaceColors");
  return config;
}

export const getWorkspaceColorCss = (selectedColor, workspaceColors) => {
  const color = workspaceColors[selectedColor];
  const setting =
    "linear-gradient(" +
    color.direction +
    "deg, #" +
    color.color1 +
    " " +
    color.stop1 +
    "%, #" +
    color.color2 +
    " " +
    color.stop2 +
    "%)";
  return setting;
};
