import { getOrganizationSettings } from "@/server/organization-settings-server.js";

export async function isEnableMasterPassword({ clientKey }) {
  const settings =
    (await getOrganizationSettings({
      clientKey,
    })) || {};

  return {
    isEnabled: !!settings.employeeIdMPEnabled,
    hasUnknownError: Object.keys(settings).length === 0,
  };
}
