var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"annotationTool"},[(_vm.supportLine)?[_vm._l((Object.entries(_vm.lineWidthMap)),function(ref){
var widthKey = ref[0];
var item = ref[1];
return _c('div',{key:widthKey,class:[
          'annotationTool__style',
          {
            'annotationTool__style--active': item.value === _vm.config.strokeWidth,
          } ],on:{"click":function($event){_vm.handleLineWidthChange(item.value)}}},[_c(item.icon,{tag:"component"})],1)}),_vm._v(" "),_c('div',{staticClass:"annotationTool__hr"}),_vm._v(" "),_c('div',{staticClass:"annotationTool__color-block",on:{"click":function($event){_vm.handleSwitchColorPicker($event, 'strokeColor')}}},[_c('div',{staticClass:"annotationTool__color",style:({background: _vm.config[_vm.ANNOTATION_DB_FABRIC_KEY_MAP.strokeColor]})}),_vm._v(" "),_c('v-icon',{staticClass:"annotationTool__color-arrow",attrs:{"color":"#9B9B9B","size":14}},[_vm._v("keyboard_arrow_down")])],1)]:(_vm.supportText)?[_vm._l((Object.values(_vm.textStyleMap)),function(item){return _c('div',{key:item.key,class:[
          'annotationTool__style',
          {
            'annotationTool__style--active': item.value === _vm.config[item.key],
          } ],on:{"click":function($event){_vm.handleTextStyle(item.key, item.value)}}},[_c(item.icon,{tag:"component"})],1)}),_vm._v(" "),_c('div',{staticClass:"annotationTool__hr"}),_vm._v(" "),_c('div',{staticClass:"annotationTool__style",on:{"click":function($event){_vm.handleSwitchColorPicker($event, 'fill')}}},[_c('d-icon-text-color'),_vm._v(" "),_c('div',{staticClass:"annotationTool__text-color-style",style:({background: _vm.config.fill})})],1),_vm._v(" "),_c('div',{staticClass:"annotationTool__style",on:{"click":function($event){_vm.handleSwitchColorPicker($event, 'backgroundColor')}}},[_c('d-icon-text-bg-color'),_vm._v(" "),_c('div',{staticClass:"annotationTool__text-color-style",style:({background: _vm.config.backgroundColor})})],1)]:_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpenColorPicker),expression:"isOpenColorPicker"}],staticClass:"annotationTool__colors-picker",style:(_vm.colorPickerStyle)},[(_vm.colorKey === 'backgroundColor')?_c('div',{staticClass:"annotationTool__color annotationTool__color--transparent",on:{"click":function($event){_vm.handleColorChange('')}}}):_vm._e(),_vm._v(" "),_vm._l((_vm.colors),function(color){return _c('div',{key:color,staticClass:"annotationTool__color",style:({background: color}),on:{"click":function($event){_vm.handleColorChange(color)}}})})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }