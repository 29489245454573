<template>
  <div class="d-admin-permissions">
    <div class="tab-title-row center-horizontally">
      <button class="mr-3 mt-1" @click="$emit('close-category')" autotest="back-to-categories-btn">
        <icon-base color="#52545D" height="24" width="24">
          <d-icon-arrow-back />
        </icon-base>
      </button>
      <span class="text-no-wrap content-title text-style text-truncate" autotest="item-in-category-display"
        >{{ $t("admin.itemsIn") }} {{ showCategoryData.name }}</span
      >
      <v-spacer></v-spacer>
      <div class="create-btn">
        <v-btn
          round
          color="#4689f4"
          class="white--text elevation-0 body-2 text-capitalize btn-width mx-0"
          @click="showPopupType = 'create'"
          autotest="create-item-btn"
          >{{ $t("admin.createItem") }}</v-btn
        >
      </div>
    </div>

    <div class="table-div">
      <d-data-table
        add-search
        local-search
        initial-sort
        :headers="tableHeader"
        :items="itemsData"
        :loading="isLoading"
        :placeholder="$t('admin.searchForItems')"
        searchAutotest="category-item-search-bar"
      >
        <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
        <template slot="headerCell" slot-scope="props">
          <span class="head-text">{{ props.header.text }}</span>
        </template>
        <template slot="items" slot-scope="props">
          <td class="text-xs-left text-capitalize" :style="{ width: '30%' }" autotest="category-item-name-display">
            {{ props.item.name }}
          </td>
          <td class="text-xs-right text-no-wrap">
            <button
              class="mr-3 table-btn"
              @click="(editItemData = props.item), (showPopupType = 'edit')"
              autotest="category-item-edit-btn"
            >
              <icon-base height="22" width="22">
                <d-icon-pen />
              </icon-base>
            </button>
            <button
              class="table-btn"
              @click="(editItemData = props.item), (showDeleteItem = true)"
              autotest="category-item-delete-btn"
            >
              <icon-base height="22" width="22">
                <d-icon-trash />
              </icon-base>
            </button>
          </td>
        </template>
        <template slot="no-data">
          <span class="text-xs-center">{{ $t("admin.noItemsCreatedYet") }}</span>
        </template>
        <template slot="no-results">
          <span class="text-xs-center">{{ $t("analytics.msgNoSearchResults") }}</span>
        </template>
      </d-data-table>
    </div>

    <admin-setting-item-popup
      v-if="showPopupType === 'create' || showPopupType === 'edit'"
      :style="drawerOpenPopupStyle"
      :showPopupType="showPopupType"
      :itemsData="itemsData"
      :editItemData="editItemData"
      @update-subcategory="updateSubCategory($event)"
      @add-subcategory="addSubCategory($event)"
      @close-popup="(showPopupType = ''), (editItemData = {})"
    ></admin-setting-item-popup>

    <d-confirmation-popup
      v-model="showDeleteItem"
      light
      :title="$t('admin.deleteItem')"
      :cancelBtnName="$t('all.cancel')"
      :actionBtnName="$t('all.delete')"
      :content="$t('admin.popupMsgDeleteItem') + $escapeHtml(editItemData.name) + '?'"
      @clickActionBtn="deleteItem(editItemData.id)"
      :autotest="{
        close: 'delete-item-popup-close-btn',
        action: 'delete-item-popup-delete-btn',
        cancel: 'delete-item-popup-cancel-btn',
      }"
    ></d-confirmation-popup>

    <d-alert v-model="alert.isSuccess" type="success" :message="alert.alertText"></d-alert>
    <d-alert v-model="alert.isFailed" type="error" :message="alert.alertText"></d-alert>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
import DIconDone from "@/components/icons/DIconDone.vue";
import DIconTrash from "@/components/icons/DIconTrash.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import AdminSettingItemPopup from "@/components/DAdmin/AdminSettingItemPopup.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import { deleteSubCategory } from "../../server/delete-category-server.js";

export default {
  name: "AdminSubCategory",
  props: {
    showCategoryData: Object,
    drawerOpenPopupStyle: Object,
  },
  watch: {},
  mixins: [MixinUser, MixinDB],
  mounted() {
    this.getSubCategories();
  },
  data() {
    return {
      searchInput: "",
      tableHeader: [
        {
          text: this.$t("admin.categoryItems"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "category-item-sort",
        },
        {
          text: "",
          sortable: false,
        },
      ],
      itemsData: [],
      showPopupType: "",
      isLoading: false,
      editItemData: {},
      showDeleteItem: false,
      alert: {
        isSuccess: false,
        isFailed: false,
        alertText: "",
      },
    };
  },
  components: {
    IconBase,
    DIconArrowBack,
    DIconDone,
    DIconTrash,
    DIconPen,
    AdminSettingItemPopup,
    DConfirmationPopup,
    DAlert,
    DDataTable,
  },
  methods: {
    getSubCategories() {
      const categoryId = this.showCategoryData.id;
      this.isLoading = true;
      this.fetchSubCategories(categoryId).then((data) => {
        this.itemsData = data;
        this.isLoading = false;
      });
    },
    fetchSubCategories(categoryId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        const comparisons = [
          {
            field: "organization",
            comparison: "==",
            value: self.$organization,
          },
          { field: "category", comparison: "==", value: categoryId },
        ];
        self
          .getDocumentByQuery("subcategories", comparisons, [
            {
              type: "limit",
              value: 1000,
            },
          ])
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addSubCategory(subCategory) {
      const self = this;
      const categoryId = self.showCategoryData.id;
      return new Promise(function (resolve, reject) {
        const subCategoryId = self.generateDocId("subcategories");
        const subCategoryData = {
          organization: self.$organization,
          id: subCategoryId,
          name: subCategory.name,
          category: categoryId,
        };
        const subCategoryWrite = {
          type: "set",
          collection: "subcategories",
          id: subCategoryId,
          data: subCategoryData,
        };
        const categoryWrite1 = {
          type: "arrayUnion",
          collection: "categories",
          id: categoryId,
          field: "subcategories",
          value: subCategoryId,
        };
        const categoryWrite2 = {
          type: "counter",
          collection: "categories",
          id: categoryId,
          field: "counter",
          value: "+1",
        };
        self
          .batchWrites([subCategoryWrite, categoryWrite1, categoryWrite2])
          .then(() => {
            self.itemsData.splice(0, 0, subCategoryData);
            self.$emit("update-category", {
              id: self.showCategoryData.id,
              counter: (self.showCategoryData.counter += 1),
            });
            self.alert.alertText = self.$t("admin.alertSuccessAddItem");
            self.alert.isSuccess = true;
            resolve();
          })
          .catch((err) => {
            self.alert.alertText = self.$t("admin.alertFailedAddItem");
            self.alert.isFailed = true;
            reject();
          });
      });
    },
    updateSubCategory(update) {
      if (update.saveToDb) {
        this.updateDocument("subcategories", update.id, update.saveToDb)
          .then(() => {
            // console.log("subcategories successfully updated to db")
            this.alert.alertText = this.$t("admin.alertSuccessEditItem");
            this.alert.isSuccess = true;
          })
          .catch((err) => {
            // alert("error updating subcategory.")
            this.alert.alertText = this.$t("admin.alertFailedEditItem");
            this.alert.isFailed = true;
          });
      }
      for (let i = 0; i < this.itemsData.length; i++) {
        if (this.itemsData[i].id == update.id) {
          this.itemsData.splice(i, 1, { ...this.itemsData[i], ...update });
        }
      }
    },
    hideSubCategoryRow({ id }) {
      const targetRowIndex = this.itemsData.findIndex((subCategory) => subCategory.id === id);
      const noDataCanDelete = targetRowIndex < 0;
      if (noDataCanDelete) {
        return;
      }

      this.itemsData.splice(targetRowIndex, 1);
    },
    showSucessMessage({ text }) {
      this.alert.isFailed = false;
      this.alert.isSuccess = true;

      this.alert.alertText = text;
    },
    showFailedMessage({ text }) {
      this.alert.isFailed = true;
      this.alert.isSuccess = false;

      this.alert.alertText = text;
    },
    deleteItem(id) {
      deleteSubCategory({
        id,
      })
        .then(() => {
          this.showSucessMessage({
            text: this.$t("admin.alertSuccessfullyDeletedItem"),
          });
          this.hideSubCategoryRow({
            id,
          });
          this.$emit("update-category", {
            id: this.showCategoryData.id,
            counter: (this.showCategoryData.counter -= 1),
          });
        })
        .catch(() => {
          this.showFailedMessage({
            text: this.$t("admin.alertFailedDeleteItem"),
          });
        });
    },
  },
  computed: {},
};
</script>

<style scoped>
.d-admin-permissions {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  text-align: left;
  z-index: 0;
  margin-top: -4px;
}
.tab-title-row {
  position: relative;
  width: 100%;
  height: 40px;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table-div {
  position: absolute;
  top: 86px;
  right: 32px;
  height: calc(100% - 240px);
  width: calc(100% - 64px);
  overflow: auto;
}
.table-div:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.text-style {
  font-family: Roboto;
  letter-spacing: 0;
}
.content-title {
  color: #3f3f3f;
  font-size: 32px;
  line-height: 32px;
  width: 100%;
}

.head-text {
  text-transform: uppercase;
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
.table-btn {
  color: #78767a;
}
.table-btn:hover {
  color: #2d2e32;
}
.create-btn {
  position: relative;
}
.btn-width {
  width: 140px;
  text-transform: none !important;
}
</style>
