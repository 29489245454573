// docs: https://axios-http.com/docs/api_intro
// axios.get(url[, config])
// axios.delete(url[, config])
// axios.head(url[, config])
// axios.options(url[, config])
// axios.post(url[, data[, config]])
// axios.put(url[, data[, config]])
// axios.patch(url[, data[, config]])

export const apiWrapperGenerator =
  (apiServer) =>
  async (method, url, ...args) => {
    try {
      const { data, data: { success, message } = {} } = await apiServer[method](url, ...args);
      if (success) {
        return { ok: success, data };
      } else {
        return { ok: success, errorMessage: message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, errorMessage: error.message };
    }
  };

export const analyticsApiWrapperGenerator =
  (apiServer) =>
  async (method, url, ...args) => {
    try {
      const { data } = await apiServer[method](url, ...args);
      return { ok: true, data };
    } catch (error) {
      const errorMessage = error.response ? error.response.data.messages.join("/") : error.message;
      return { ok: false, errorMessage: errorMessage };
    }
  };
