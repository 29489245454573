import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { analyticsApiWrapperGenerator } from "./lib";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].analytics
    : environment.service[environment.env]["china"].analytics;
}

const analyticsServer = axios.create({
  baseURL: getBaseUrl(),
});

analyticsServer.interceptors.request.use(
  async (config) => {
    const idToken = await authChoice().currentUser.getIdToken();
    config.headers["Authorization"] = `Bearer ${idToken}`;
    return config;
  },
  (error) => Promise.resolve(error)
);

const apiWrapper = analyticsApiWrapperGenerator(analyticsServer);

export const fetchWorkspaceStates = (params) => apiWrapper("post", "/workspaces/list", params);

export default analyticsServer;
