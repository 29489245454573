<template>
  <div class="DWorkspacesDropMenu" :class="{'DWorkspacesDropMenu__dark':dark}">
    <div class="DWorkspacesDropMenu__search" @click.stop>
      <div class="DWorkspacesDropMenu__search--input">
        <div class="DWorkspacesDropMenu__search--icon">
          <icon-base color="#9397A6" width="16" height="16">
            <d-icon-search />
          </icon-base>
        </div>
        <input
          ref="searchInput"
          :placeholder="$t('admin.searchForWorkspaces')"
          v-model="searchInput"
          autotest="search-search-for-workspace"
        />
        <button
          v-if="searchInput"
          class="DWorkspacesDropMenu__search--close"
          @click="searchInput=''"
        >
          <icon-base width="14" height="14" color="#9397A6">
            <d-icon-close-2 />
          </icon-base>
        </button>
      </div>
    </div>
    <div class="DWorkspacesDropMenu__list">
      <div
        v-for="group in filterGroupList"
        :key="group.id"
        class="DWorkspacesDropMenu__list--item text-truncate"
        :class="{'DWorkspacesDropMenu__list--currentItem': value===group.id,
                 'DWorkspacesDropMenu__list--hideAll': group.id.toLowerCase()==='all'}"
        @click="$emit('input', group.id)"
      >
        <icon-base
          v-if="isGroupsContainedPrivate"
          color="#8d909f"
          width="16"
          height="16"
          class="DWorkspacesDropMenu__list--lockIcon"
        >
          <d-icon-lock v-if="group.private" />
        </icon-base>
        {{group.name}}
      </div>
      <div
        v-if="filterGroupList.length === 0"
        class="DWorkspacesDropMenu__list--noResult text-no-wrap text-truncate"
      >- {{$t('admin.noResultsFound')}} -</div>
    </div>
  </div>
</template>
<script>
import IconBase from "@/components/IconBase.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconLock from "@/components/icons/DIconLock.vue";

export default {
  name: "DWorkspacesDropMenu",
  props: {
    value: String, //for v-model value
    dark: Boolean,
    groupList: {
      type: Array,
    },
  },
  components: {
    IconBase,
    DIconSearch,
    DIconClose2,
    DIconLock,
  },
  data() {
    return {
      searchInput: "",
    };
  },
  mounted() {
    this.$nextTick(() => this.$refs.searchInput.focus());
  },
  computed: {
    isGroupsContainedPrivate() {
      return this.groupList.some((group) => group.private);
    },
    filterGroupList() {
      return this.groupList.filter(group => {
        if (group.name.toLowerCase().includes(this.searchInput.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.DWorkspacesDropMenu {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 288px;
  background: #ffffff;
  &__search {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    padding: 16px;
    &--icon {
      position: absolute;
      left: 16px;
      top: 12px;
    }
    &--close {
      position: absolute;
      right: 16px;
      top: 12px;
    }
    &--input {
      position: relative;
      height: 40px;
      border: 1px solid #dadada;
      border-radius: 20px;
      input {
        position: absolute;
        left: 42px;
        top: 0;
        height: 100%;
        width: calc(100% - 84px);
        font-size: 14px;
        line-height: 16px;
        color: #121314;
        padding-top: 1px;
      }
      input:focus {
        outline-width: 0;
        outline: 0;
      }
      input::placeholder {
        color: #919191;
        font-size: 14px;
        line-height: 16px;
        font-style: italic;
      }
    }
  }
  &__list {
    position: absolute;
    top: 72px;
    max-width: 100%;
    width: 100%;
    height: 216px;
    padding: 0 16px 16px 16px;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    &--item {
      position: relative;
      width: 100%;
      height: 40px;
      padding: 12px 16px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      color: #121314;
    }
    &--item:hover {
      background: #ececec;
    }
    &--currentItem {
      color: #4689f3;
      .DWorkspacesDropMenu__list--lockIcon {
        color: #4689f3;
      }
    }
    &--hideAll {
      display: none;
    }
    &--noResult {
      padding: 12px 16px;
      font-size: 14px;
      line-height: 16px;
      color: #919191;
    }
    &--lockIcon {
      margin: 0 2px -2px -1px;
    }
  }
  &__list:hover::-webkit-scrollbar-thumb {
    background-color: rgb(168, 168, 168);
  }
  &__dark {
    background-color: #2c2d32;
    .DWorkspacesDropMenu__search--input {
      border: 1px solid #8d909f;
    }
    .DWorkspacesDropMenu__search--input input {
      color: #ffffff;
    }
    .DWorkspacesDropMenu__list--item {
      color: #ffffff;
    }
    .DWorkspacesDropMenu__list--item:hover {
      background: #ffffff1c;
    }
    .DWorkspacesDropMenu__list--currentItem {
      color: #4689f3;
    }
  }
}
</style>
