<template>
  <div class="d-explorer-search" :class="{ 'mobile-mode': isMobileDevice, 'search-div-bg': isSearchMode }">
    <div
      class="search-felx-div"
      :class="{
        'player-btn-new': !isEditor,
        'new-search-felx-lg': isSearchMode,
        'search-felx-div-mobile': !isSearchMode && isMobileDevice,
        'new-search-felx-lg-mobile': isSearchMode && isMobileDevice,
      }"
    >
      <!-- open search button -->
      <div class="serach-btn-div">
        <button class="mr-2 dh-open-search" :class="{ 'serach-icon-lg': isSearchMode }" @click="$emit('openSearch')">
          <icon-base>
            <d-icon-search />
          </icon-base>
        </button>
        <div class="serach-btn" :class="{ 'serach-btn-lg': isSearchMode, 'serach-btn-lg-mobile': isMobileDevice }">
          <span v-if="isSearchMode && !isEditor" class="text-no-wrap">{{ $t("explorer.search") }}&nbsp;</span>
          <span v-if="isSearchMode && !isSkill" v-html="$t('explorer.in')" class="text-no-wrap"></span>
        </div>
      </div>

      <!-- current group button -->
      <div v-if="isSearchMode && !isSkill" class="group-btn">
        <button class="group-list-btn" @click="showGroupList = true" autotest="search-change-workspace">
          <div class="group-list-name text-truncate" :class="{ 'group-list-name-mobile': isMobileDevice }">
            <span class="workspace-group-name">{{ getWorkspaceGroupNameById }}</span>
            <span>{{ getWorkspaceGroupNameById ? "/" : "" }}</span>
            <span>{{ getWorkspaceNameById }}</span>
          </div>
          <v-icon small color="#8D909F" class="ml-1">keyboard_arrow_down</v-icon>
        </button>
      </div>

      <!-- select group list -->
      <d-group-menu
        v-if="showGroupList"
        class="d-group-menu"
        :class="{ 'd-group-menu-mobile': isMobileDevice }"
        :labelText="$t('explorer.search')"
        :currentGroupId="currentGroupId"
        :groupDictionary="groupDictionary"
        :userGroupList="userGroupList"
        :isMobileDevice="isMobileDevice"
        :isSearch="true"
        @close-menu="showGroupList = false"
        @update-current-group="(showGroupList = false), clickGroup($event)"
      ></d-group-menu>

      <!-- search input -->
      <div class="search-div" :class="{ 'show-search-input': isSearchMode }">
        <input
          ref="searchInput"
          class="search-input"
          :class="{ 'search-input-mobile': isMobileDevice }"
          :placeholder="$t('explorer.howTo')"
          :disabled="isLoadingSearch"
          v-model="inputText"
          @input="$emit('input', inputText), $emit('getSuggestions', inputText)"
          @keyup.enter="doSearch(inputText)"
          autotest="search-workflows-input"
        />
        <div class="suggestions-dropdown" :class="{ 'hide-suggestions': !didYouMean }">
          <v-list
            v-if="didYouMean"
            dense
            dark
            @mouseover="$emit('mouseoverSearch')"
            @mouseleave="$emit('mouseleaveSearch')"
          >
            <v-list-tile @click="handlerSuggestions" class="dropdown-list-item">
              <v-list-tile-title class="body-1 text-no-wrap text-capitalize">
                <span class="primary--text">{{ $t("explorer.didYouMean") }}:</span>
                {{ didYouMean }}
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </div>
      </div>
      <v-spacer></v-spacer>
      <button
        class="dh-close-search"
        :class="{ 'close-btn-lg': isSearchMode }"
        @click="closeSearch()"
        autotest="search-close-button"
      >
        <icon-base width="100%" height="100%">
          <d-icon-close-light />
        </icon-base>
      </button>
    </div>
  </div>
</template>
<script>
import IconBase from "../IconBase.vue";
import DIconSearch from "../icons/DIconSearch.vue";
import DIconCloseLight from "../icons/player/DIconCloseLight.vue";
import DGroupMenu from "../ui_components/DGroupMenu.vue";
import { mapGetters } from "vuex";

export default {
  name: "DExplorerSearch",
  props: {
    value: String, //for v-model value,
    isMobileDevice: Boolean,
    isSearchMode: Boolean,
    didYouMean: String,
    userGroupList: Array,
    userCurrentGroup: String,
    sortOptions: Array,
    isEditor: Boolean,
    currentGroupId: String,
    groupDictionary: Object,
    isSkill: Boolean,
    isLoadingSearch: Boolean,
  },
  components: {
    IconBase,
    DIconSearch,
    DIconCloseLight,
    DGroupMenu,
  },
  watch: {
    isSearchMode: function () {
      if (this.isSearchMode) {
        this.$nextTick(() => this.$refs.searchInput.focus());
      }
    },
    isLoadingSearch: function () {
      if (!this.isLoadingSearch) {
        this.$nextTick(() => this.$refs.searchInput.focus());
      }
    },
  },
  data: function () {
    return {
      inputText: "",
      testTest: false,
      showGroupList: false,
    };
  },
  methods: {
    clickGroup(group) {
      this.$emit("updateCurrentGroup", group);
      this.showGroupList = false;
    },
    closeSearch() {
      this.inputText = "";
      this.$emit("input", this.inputText);
      this.$emit("getSuggestions", this.inputText);
      this.$emit("closeSearch");
    },
    doSearch(inputText) {
      if (this.isLoadingSearch || inputText.length == 0) return;
      // if(this.isLoadingSearch) return;
      this.$emit("getFullTextSearch", inputText);
    },
    handlerSuggestions() {
      this.$emit("clickSearchSuggestion", this.didYouMean);
    },
  },
  computed: {
    ...mapGetters("workspaceGroups", [
      "getter_get_view_workspace_group_by_workspace_id",
      "getter_get_edit_workspace_group_by_workspace_id",
      "getter_get_admin_workspace_group_by_workspace_id",
    ]),
    isSkillsBuilder() {
      const skillsBuilderRoutes = ["SkillsBuilder", "SkillsBuilderUsers"];
      return skillsBuilderRoutes.indexOf(this.$route.name) >= 0;
    },
    getWorkspaceGroupNameById() {
      const workspaceGroup = this.isSkillsBuilder
        ? this.getter_get_view_workspace_group_by_workspace_id(this.currentGroupId)
        : this.getter_get_edit_workspace_group_by_workspace_id(this.currentGroupId);
      return workspaceGroup?.name || "";
    },
    getWorkspaceNameById() {
      if (this.currentGroupId === "All") return this.$t("all.all");
      const groupName = this.groupDictionary[this.currentGroupId];
      return groupName || "-";
    },
  },
  mounted() {},
};
</script>

<style scoped>
.d-explorer-search {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  z-index: 12;
  pointer-events: none;
}
.mobile-mode {
  height: 56px !important;
}
.search-div-bg {
  background-color: #2c2d32;
  transition: 0.3s;
  pointer-events: auto;
}
.search-felx-div {
  position: absolute;
  height: 100%;
  top: 0;
  right: 240px;
  width: 60px;
  padding-left: 18px;
  transition: 0.3s;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.editor-search-div {
  right: 240px;
  width: 60px;
  padding-left: 18px;
}
.player-btn-new {
  width: 70px;
  padding-left: 20px;
  right: 146px;
}
.search-felx-div-mobile {
  width: 40px;
  right: 90px;
  padding-left: 8px;
}
.new-search-felx-lg {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  transition: 0.6s;
  overflow: visible;
  padding: 0 30px;
}
.new-search-felx-lg-mobile {
  padding: 0 16px;
  top: 0;
  right: 0;
}
.serach-btn-div {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: auto;
  color: #c4c4c4;
}
.serach-btn-div:hover {
  color: white !important;
  fill: white !important;
}
.serach-btn {
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}
.serach-btn-lg {
  height: 24px;
  font-size: 20px;
  line-height: 24px;
  color: #8d909f !important;
  display: flex;
}
.serach-btn-lg-mobile {
  font-size: 16px;
}
.dh-open-search {
  height: 24px;
  width: 24px;
  transition: 0.3s;
}
.serach-icon-lg {
  transition: 0.6s;
  color: #8d909f !important;
  pointer-events: none;
  cursor: none;
}

.group-btn {
  position: relative;
  height: 24px;
  width: auto;
}
.group-list-btn {
  position: relative;
  height: 100%;
  transition: 0.6s;
  margin-top: 1px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: auto;
  font-size: 20px;
  line-height: 24px;
}

.group-list-name {
  position: relative;
  height: 100%;
  max-width: 180px;
  line-height: 24px;
  height: 24px;
  color: white;
}
.group-list-name-mobile {
  max-width: 80px;
  padding-top: 2px;
  line-height: 24px;
  font-size: 16px;
}
.show-group-list {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 200px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 24px;
  pointer-events: auto;
  transition: 0.4s;
}
.show-group-list:hover::-webkit-scrollbar-thumb {
  background-color: #1b1b1b !important;
}
.hide-group-list {
  opacity: 0;
  height: 30px;
  top: 0;
  pointer-events: none;
  transition: 0.4s;
}

.list-menu {
  display: block;
  position: relative;
  pointer-events: auto;
}
.search-div {
  position: relative;
  height: 24px;
  width: 0;
  margin-left: 12px;
  text-align: left;
  opacity: 0;
  transition: 0.3s;
}

.show-search-input {
  width: calc(100% - 210px);
  opacity: 1;
  transition: 0.6s;
  transition-delay: 0.3s;
}
.search-input {
  position: relative;
  width: 100%;
  color: #ffffff;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  height: 24px;
}
.search-input:focus {
  outline-width: 0;
  outline: 0;
}
.search-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8d909f;
  opacity: 1; /* Firefox */
  font-size: 20px;
  line-height: 24px;
}
.search-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8d909f;
  font-size: 20px;
  line-height: 24px;
}
.search-input-mobile {
  font-size: 16px;
}
.search-input-mobile::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 16px;
}
.search-input-mobile:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 16px;
}
.suggestions-dropdown {
  z-index: 99;
  opacity: 1;
  transition: 0.3s;
  position: relative;
  width: auto;
  left: 0;
}
.hide-suggestions {
  height: 25px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.dropdown-list-item:hover {
  background-color: #52545d !important;
}
.current-list-item {
  color: #4689f3;
}
.v-list {
  background: #2c2d32 !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5) !important;
  color: white;
  position: absolute;
}
.dh-close-search {
  position: relative;
  height: 12px;
  width: 12px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.close-btn-lg {
  position: relative;
  height: 22px;
  width: 22px;
  opacity: 1;
  transition: 0.6s;
  pointer-events: auto;
  color: #8d909f;
}
.close-btn-lg:hover {
  color: white;
  transition: 0.3s;
}
.toolbar-line {
  position: absolute;
  border-right: 1px solid #292a2c;
  border-left: 1px solid #3c3c3f;
  height: 80%;
  width: 0;
  right: 0;
}
.d-group-menu {
  position: fixed;
  top: 70px;
  left: 0;
  height: calc(100% - 70px);
  width: 100%;
  z-index: 999;
}
.d-group-menu-mobile {
  top: 56px;
  height: calc(100% - 58px);
}
.workspace-group-name {
  margin-bottom: -4px;
  display: inline-block;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
