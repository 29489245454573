<template>
  <div class="builder-transcription">
    <v-toolbar dark color="#2C2D32" height="70px" class="pl-2 pt-0">
      <button class="tool-bar-icon" analytics="close-transcription" @click="closeTranslation()">
        <icon-base>
          <d-icon-close2 />
        </icon-base>
      </button>
      <v-toolbar-title class="white--text title font-weight-regular text-capitalize">{{
        $t("builder.reviewTranscription")
      }}</v-toolbar-title>
      <div class="publish-tag">
        <span v-if="workflow.published" class="publish-tag-text">
          <span class="green-dot"></span>
          {{ $t("all.published") }}
        </span>
        <span v-else class="publish-tag-text">
          <span class="red-dot"></span>
          {{ $t("all.unpublished") }}
        </span>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        round
        outline
        color="#4689f4"
        class="body-2 text-capitalize btn-width"
        :disabled="!madeChanges"
        analytics="save-transcription"
        @click="clickSaveBtn()"
        >{{ $t("all.save") }}</v-btn
      >
    </v-toolbar>

    <!-- transcription -->
    <div class="original-headline">
      <h4 class="white--text text-xs-left headline head-line">
        {{ $t("all.transcription") }}
      </h4>
    </div>

    <v-progress-linear
      v-if="!this.sourceSentencesArray.length"
      class="progress-linear"
      indeterminate
      color="grey"
      height="2"
    ></v-progress-linear>

    <div class="transcription-wrapper" ref="transcriptionWrapper" @scroll="showScrollBtnOrNot()">
      <div v-for="(stepSentences, index) in sourceSentencesArray" :key="index" class="step-wrap">
        <div class="step-num text-uppercase">{{ $t("all.step") }} {{ index + 1 }}</div>
        <div v-if="stepSentences.length == 0" class="textarea-wrap">
          <div class="no-transcript-placehold noselect">({{ $t("builder.stepNoTranscript") }})</div>
        </div>
        <div
          v-for="(sentence, idx) in stepSentences"
          :key="idx"
          :ref="'originalText' + index"
          class="textarea-wrap"
          @click="clickSentence(idx, index)"
        >
          <v-textarea
            dark
            solo
            flat
            hide-details
            auto-grow
            rows="1"
            :key="autoGrowHack + idx"
            v-model.lazy="sentence.sentence"
            :placeholder="'(' + $t('editor.hintNoEmptySentence') + ')'"
            color="red"
            background-color="transparent"
            class="my-text-style pa-0 ma-0"
            :class="{
              'sentence-active ::v-deep textarea': index == currentStepIdx && idx == currentSentenceIdx,
              'sentence-empty ::v-deep textarea': sentence.sentence == '',
              'right-to-left': workflow.languageCode == 'he-il',
            }"
            @change="setMatomoOnChangeTrack"
            @input="madeChanges = true"
          ></v-textarea>
        </div>
      </div>
    </div>

    <div class="scroll-to-voice" :class="{ 'show-scroll-btn': showScrollBtn }">
      <v-btn
        round
        block
        small
        class="body-2 white--text text-capitalize no-transform"
        color="#4689f4"
        @click="scrollToVoice()"
      >
        {{ $t("editor.scrollToVoice") }}
      </v-btn>
    </div>

    <!-- video -->
    <div class="video-wraper">
      <d-video-secure-multistep
        ref="workflowPlayer"
        isBuilderTranscription
        :steps="steps"
        :workflow="workflow"
        preload="auto"
        @get-current-sentence="getCurrentSentence($event)"
      >
      </d-video-secure-multistep>
    </div>

    <!-- Saving alert -->
    <!--alert: Saving is in progress. -->
    <d-alert v-model="isSaving" type="info" manualClose :message="$t('editor.alertIsSaving')"></d-alert>

    <d-alert v-model="isNotSuccess" type="error" :message="alertText"></d-alert>
    <d-alert v-if="!isSaving" v-model="isSuccess" type="success" :message="alertText"></d-alert>

    <!--alert: Sentences cannot be empty. Please enter the sentences, then save again. -->
    <d-alert v-model="emptySentenceAlert" type="error" :message="$t('editor.alertNoEmptySentence')"></d-alert>

    <!-- close editor confirmation dialog -->
    <d-confirmation-popup
      v-model="closeConfirmation"
      :title="$t('builder.closeTranscript')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('editor.popupBtnClose')"
      :content="$t('builder.popupMsgCloseTranscript')"
      @clickActionBtn="cancelChanges()"
    ></d-confirmation-popup>
  </div>
</template>

<script>
import MixinDB from "./MixinDB.vue";
import axios from "axios";
import DAlert from "./ui_components/DAlert.vue";
import IconBase from "./IconBase.vue";
import DIconClose2 from "./icons/DIconClose2.vue";
import DVideoSecureMultistep from "@/components/ui_components/DVideoSecureMultistep.vue";
import DConfirmationPopup from "./DPopup/DConfirmationPopup.vue";
import { getTranslationLanguageCode } from "@/js/workflow/checkLanguage.js";
import Analytics from "@/js/analytics/analytics";

export default {
  name: "BuilderTranscription",
  watch: {
    steps: function () {
      //TODO: when user add new steps or move steps around; we need to update the transcriptions;
      //      However, we should avoid getting all transcription from db if it is already locally available.
      const self = this;
      this.getAllTranscriptions(this.sourceLanguage).then(() => {
        self.updateSourceSentencesArray();
      });

      this.gotEndTime = false;
      this.getAllStepEndTime();
    },
    isSavingBuilder: function () {
      this.isSaving = this.isSavingBuilder;
    },
    isSuccessBuilder: function () {
      this.isSuccess = this.isSuccessBuilder;
    },
    isNotSuccessBuilder: function () {
      this.isNotSuccess = this.isNotSuccessBuilder;
    },
    alertTextBuilder: function () {
      this.alertText = this.alertTextBuilder;
    },
    showTranscription: function () {
      if (this.showTranscription) {
        setTimeout(() => {
          this.forceUpdateTextareaHeight();
        }, 100);
      }
    },
  },
  props: {
    steps: {
      type: Array,
    },
    workflow: {
      type: Object,
    },
    showTranscription: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
    },
    alertTextBuilder: {
      type: String,
    },
    isSavingBuilder: {
      type: Boolean,
    },
    isSuccessBuilder: {
      type: Boolean,
    },
    isNotSuccessBuilder: {
      type: Boolean,
    },
  },
  mounted() {
    const self = this;
    this.sourceLanguage = this.workflow.languageCode;
    this.getAllTranscriptions(this.sourceLanguage).then(() => {
      self.updateSourceSentencesArray();
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.forceUpdateTextareaHeight);
    });
  },
  created() {},
  components: {
    DAlert,
    IconBase,
    DIconClose2,
    DConfirmationPopup,
    DVideoSecureMultistep,
  },
  mixins: [MixinDB],
  updated() {},
  data() {
    return {
      transcriptions: {},
      sourceLanguage: "",
      sourceSentencesArray: [],
      madeChanges: false,
      closeConfirmation: false,
      transcriptionSentences: {}, //keeps track the sentences for each transcription
      isSaving: false,
      isSuccess: false,
      isNotSuccess: false,
      alertText: "",
      transcriptionStorage: {}, //stores all previously downloaded transcriptions
      autoGrowHack: "a",
      currentStep: 0,
      currentStepIdx: 0,
      currentSentenceIdx: 0,
      allPlayedStepDuration: [],
      allStepEndTime: [],
      gotEndTime: false,
      emptySentenceAlert: false,
      showScrollBtn: false,
    };
  },
  methods: {
    ////// video //////
    setTimeBySentence(stepIdx, sentenceIdx) {
      this.getAllStepEndTime();
      const currentTime = this.sourceSentencesArray[stepIdx][sentenceIdx].startTime;
      const videoCurrentTime = currentTime + this.allPlayedStepDuration[stepIdx];
      this.$refs.workflowPlayer.setVideoTimeFromParent(videoCurrentTime);
    },
    getCurrentSentence(event) {
      this.currentStep = event.currentStep;
      this.currentStepIdx = this.currentStep;
      const currentTime = event.newCurrentTime;
      if (this.sourceSentencesArray[this.currentStep]) {
        for (let i = 0; i < this.sourceSentencesArray[this.currentStep].length; i++) {
          if (currentTime <= this.sourceSentencesArray[this.currentStep][i].endTime) {
            this.currentSentenceIdx = i;
            this.showScrollBtnOrNot();
            break;
          }
        }
      }
    },

    getAllStepEndTime() {
      if (!this.gotEndTime) {
        this.allPlayedStepDuration = [];
        this.allStepEndTime = [];
        let playedStepDuration = 0;
        let videoEndTime = 0;
        for (let index = 0; index < this.steps.length; index++) {
          videoEndTime = videoEndTime + this.steps[index].duration;
          this.allStepEndTime.push(videoEndTime);
          if (index == 0) {
            this.allPlayedStepDuration.push(playedStepDuration);
          } else {
            playedStepDuration = playedStepDuration + this.steps[index - 1].duration;
            this.allPlayedStepDuration.push(playedStepDuration);
          }
        }
        this.gotEndTime = true;
      }
    },
    ////// Sentence //////
    showScrollBtnOrNot() {
      const wrapperScrollTop = this.$refs.transcriptionWrapper.scrollTop;
      const ref = "originalText" + this.currentStepIdx;
      const targetOffsetTop = this.$refs[ref][this.currentSentenceIdx].offsetTop;

      const wrapperHeight = this.$refs.transcriptionWrapper.offsetHeight;
      const targetHeight = this.$refs[ref][this.currentSentenceIdx].offsetHeight;
      if (
        targetOffsetTop + targetHeight / 2 < wrapperScrollTop ||
        targetOffsetTop + targetHeight / 2 > wrapperHeight + wrapperScrollTop
      ) {
        this.showScrollBtn = true;
      } else {
        this.showScrollBtn = false;
      }
    },
    scrollToVoice() {
      this.scrollToSentence("transcriptionWrapper", "originalText" + this.currentStepIdx, this.currentSentenceIdx);
    },
    clickSentence(idx, index) {
      const stepIdx = index;
      const sentenceIdx = idx;
      if (stepIdx != this.currentStepIdx || sentenceIdx != this.currentSentenceIdx) {
        this.setTimeBySentence(stepIdx, sentenceIdx);
      }
    },
    forceUpdateTextareaHeight() {
      if (this.autoGrowHack == "a") {
        this.autoGrowHack = "b";
      } else {
        this.autoGrowHack = "a";
      }
      this.showScrollBtnOrNot();
    },
    scrollToSentence(part, ref, idx) {
      if (this.$refs[ref][idx]) {
        const offsetTop = this.$refs[ref][idx].offsetTop;
        const scrollTop = offsetTop - this.$refs[part].offsetHeight / 2 + this.$refs[ref][idx].offsetHeight / 2;
        const container = this.$refs[part];
        container.scrollTo({
          top: scrollTop,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    closeTranslation() {
      if (!this.madeChanges) {
        this.$emit("closeTranslation");
      } else {
        this.closeConfirmation = true;
      }
    },
    cancelChanges() {
      this.closeConfirmation = false;
      this.madeChanges = false;
      this.$emit("closeTranslation");
    },

    clickSaveBtn() {
      //stop the saving if has empty sentence
      if (!this.hasEmptySentence()) {
        this.saveChanges();
      }
    },
    hasEmptySentence() {
      for (let i = 0; i < this.sourceSentencesArray.length; i++) {
        for (let k = 0; k < this.sourceSentencesArray[i].length; k++) {
          if (this.sourceSentencesArray[i][k].sentence == "") {
            this.emptySentenceAlert = true;
            return true;
            break;
          }
        }
      }
      return false;
    },
    saveChanges() {
      const language = this.sourceLanguage;
      const self = this;
      self.isSaving = true;
      const promises = [];
      const batchWrites = [];
      //update transcription
      for (let i = 0; i < this.transcriptions[language].length; i++) {
        self.transcriptions[language][i].sentences = JSON.stringify(this.sourceSentencesArray[i]);
        // debugger;
        if (self.transcriptions[language][i].id) {
          const write = {
            type: "update",
            collection: "transcriptions",
            id: self.transcriptions[language][i].id,
            data: self.transcriptions[language][i],
          };
          batchWrites.push(write);
        }
      }
      //update subtitles
      for (let i = 0; i < self.steps.length; i++) {
        promises.push(self.createSubtitles(language, self.steps[i].id));
      }
      Promise.all(promises).then((all) => {
        batchWrites.push(...all);
        self
          .batchWrites(batchWrites)
          .then(() => {
            self.isSaving = false;
            self.madeChanges = false;
            self.alertText = this.$t("builder.alertTranscriptionSaved");
            // "Transcription successfully saved.";
            self.isSuccess = true;
            self.$emit("reloadTranscription");
            setTimeout(() => {
              self.isSuccess = false;
            }, 3000);
          })
          .catch((err) => {
            console.log(err);
            self.isSaving = false;
            self.alertText = this.$t("builder.alertTranscriptionSaveFailed");
            self.isNotSuccess = true;
          });
      });
    },
    async createSubtitles(language, stepId) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        if (language && stepId) {
          const translationId = self.generateDocId("translations");
          const data = {
            id: translationId,
            languageCode: language,
            source: language,
            level: "STEP",
            organization: self.$store.state.userProfile[0].organization,
            stepId: stepId,
            status: "scheduled",
            subtitlesOnly: true,
            backendType: self.$backendType,
            errorCode: 0,
            timestamp: await self.getServerTimestamp(),
          };

          const write = {
            type: "set",
            collection: "translations",
            id: translationId,
            data: data,
          };
          resolve(write);
        } else {
          reject("missing language or stepId");
        }
      });
    },
    updateSourceSentencesArray() {
      this.sourceSentencesArray = [];
      const transcriptions = this.transcriptions[this.sourceLanguage];
      for (let i = 0; i < transcriptions.length; i++) {
        this.sourceSentencesArray.push(this.getSentences(transcriptions[i]));
      }
    },
    getSentences(transcription) {
      if (transcription.sentences) {
        return JSON.parse(transcription.sentences);
      } else {
        return [];
      }
    },
    async getAllTranscriptions(languageCode) {
      if (!languageCode) return;
      const self = this;
      return new Promise(
        async function (resolve, reject) {
          const transcriptionArray = [];
          for (let i = 0; i < this.steps.length; i++) {
            let transcriptionId = this.steps[i].transcriptions[languageCode];
            if (transcriptionId && self.transcriptionStorage[transcriptionId]) {
              //load from storage if it's already available
              const transcription = self.transcriptionStorage[transcriptionId];
              transcriptionArray.push(transcription);
              console.log("got transcription from storage.");
            } else {
              if (!transcriptionId) {
                //create translation/transcription if it's not already available
                if (self.sourceSentencesArray[i]) {
                  if (self.sourceSentencesArray[i].length > 0) {
                    //skip if the step has no sentence
                    const transcription = await self
                      .createTranscription(self.steps[i].id, languageCode, self.sourceSentencesArray[i])
                      .catch((err) => {
                        reject(err);
                      });
                    if (!transcription.sentences.length) {
                      transcription.sentences = "N/A";
                    }
                    transcriptionArray.push(transcription);
                    self.transcriptionStorage[transcription.id] = transcription;
                  }
                } else {
                  console.log("source step has no sentences.");
                  transcriptionArray.push([]);
                }
              } else {
                const transcription = await self.getTranscription(transcriptionId).catch((err) => {
                  reject(err);
                });
                transcriptionArray.push(transcription);
                self.transcriptionStorage[transcription.id] = transcription;
              }
            }
          }
          this.transcriptions[languageCode] = transcriptionArray;
          resolve();
        }.bind(this)
      );
    },
    storeStepTranscription(stepId, languageCode, sentences) {
      const self = this;
      const transcription = JSON.stringify(sentences);
      return new Promise(function (resolve, reject) {
        const id = self.generateDocId("transcriptions");
        const data = {
          id: id,
          language: languageCode,
          sentences: transcription,
          stepId: stepId,
        };
        self
          .addDocument("transcriptions", id, data)
          .then(() => {
            self
              .updateMap("steps", stepId, { language: languageCode, id: id })
              .then(() => {
                //update local steps with the new transcription id; this is needed for creating audio
                for (let i = 0; i < self.steps.length; i++) {
                  if (self.steps[i].id == stepId) {
                    self.steps[i].transcriptions[languageCode] = id;
                  }
                }
                console.log("transcriptions updated");
                resolve(data);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createTranscription(stepId, languageCode, sentences) {
      const self = this;
      let cleanSentences = [];
      //send only sentences, remove words
      sentences.forEach((sentence) => {
        cleanSentences.push({
          sentence: sentence.sentence,
          startTime: sentence.startTime,
          endTime: sentence.endTime,
        });
      });
      console.log("post request made for " + stepId + " " + languageCode);
      return new Promise(function (resolve, reject) {
        const data = {
          token: "tA43EmOIsu4vhiJ9kkjj",
          mode: "sentence",
          sentences: JSON.stringify(cleanSentences),
          source: getTranslationLanguageCode(self.workflow.languageCode),
          target: getTranslationLanguageCode(languageCode),
          backendType: self.$backendType,
          organization: self.$store.state.userProfile[0].organization,
        };
        axios
          .post(self.$service.language + "translate", data)
          .then(function (response) {
            const translatedSentences = response.data;
            self
              .storeStepTranscription(stepId, languageCode, translatedSentences)
              .then((transcription) => {
                resolve(transcription);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch(function (error) {
            console.log("createTranscription error: " + error);
            self.isNotSuccess = true;
            self.alertText = this.$t("builder.alertErrorCreateTranscription");
            // "error creating translation.";
            reject(error);
          });
      });
    },
    getTranscription(id) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocument("transcriptions", id)
          .then((doc) => {
            resolve(doc);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setMatomoOnChangeTrack() {
      Analytics.setInputChangeTrack({
        category: "BuilderMain",
        action: "Edit Transcription",
        name: "Edit Transcription in BuilderMain",
      });
    },
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener("resize", this.forceUpdateTextareaHeight);
  },
};
</script>

<style scoped>
/* for toolbar */
.builder-transcription {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0c0c0e;
}
.tool-bar-icon {
  color: #52545d;
  height: 28px;
  width: 28px;
  padding: 3px;
  margin-right: 18px;
}
.tool-bar-icon:hover {
  color: #ffffff;
}
.publish-tag {
  min-width: 104px;
  height: 24px;
  margin: 0 20px;
  background-color: #202125;
  padding: 1px 11px;
  border-radius: 16px;
}
.publish-tag-text {
  font-size: 12px;
  line-height: 12px;
}
.red-dot {
  height: 7px;
  width: 7px;
  background-color: #e03535;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
.green-dot {
  height: 7px;
  width: 7px;
  background-color: #0ef28f;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
.btn-width {
  width: 120px;
}

/* for transcription */
.transcription-wrapper {
  position: fixed;
  top: 180px;
  left: 0;
  width: 50%;
  height: calc(100% - 180px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px;
}
.transcription-wrapper:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.original-headline {
  position: fixed;
  top: 70px;
  height: 110px;
  left: 0;
  width: 50%;
  z-index: -1;
  border-bottom: 1px dashed #5e5e5e;
  padding-top: 20px;
}
.progress-linear {
  position: fixed;
  top: 166px;
  left: 0;
  width: 50%;
  height: 0;
}
.head-line {
  padding: 24px 0 20px 30px;
}
.step-wrap {
  border-bottom: 1px dashed #5e5e5e;
  padding-bottom: 14px;
  padding-right: 30px;
}
.step-num {
  width: 100px;
  position: absolute;
  font-size: 11px;
  text-align: left;
  color: #949494;
  font-weight: 600;
  padding-top: 19px;
  padding-left: 30px;
}
.textarea-wrap {
  position: relative;
  left: 100px;
  margin: 0;
  width: calc(100% - 100px);
}
.no-transcript-placehold {
  font-size: 20px;
  line-height: 28px;
  color: #454546;
  text-align: left;
  padding: 12px 0px 2px 12px;
  pointer-events: none;
}
.my-text-style ::v-deep .v-text-field__slot textarea {
  padding: 0;
  font-size: 20px;
  line-height: 28px;
  overflow-y: auto;
}
.my-text-style ::v-deep textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.4; /* Firefox */
}
.my-text-style ::v-deep textarea {
  background-color: transparent;
}
.sentence-active ::v-deep textarea {
  background-color: #454546;
  caret-color: #4689f3;
}
.sentence-empty ::v-deep textarea {
  color: #ffffff !important;
  background-color: #454546;
  border-bottom: #e03535 2px solid !important;
}
.right-to-left ::v-deep textarea {
  direction: rtl;
}
.scroll-to-voice {
  position: fixed;
  bottom: 22px;
  left: calc(25% - 70px);
  min-width: 130px;
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
}
.show-scroll-btn {
  opacity: 1;
  transition: 0.3s;
  pointer-events: auto;
}
/* for video */
.video-wraper {
  position: fixed;
  top: 70px;
  right: 0;
  width: 50%;
  height: calc(100% - 70px);
  padding-bottom: 100px;
  background-color: #1e1f22;
  padding: 80px 5% 50px 5%;
}

.v-menu__content {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.no-transform {
  text-transform: none !important;
}
</style>
