<template>
  <section class="admin-group-users">
    <div row wrap class="tab-title-row">
      <p class="tab-title text-truncate">
        <span v-if="searchWorkspaceGroupAdmin.length > 0">{{ $t("analytics.searchUser") }}</span>
        <span v-else autotest="workspace-count">
          <b>
            <span v-if="currentStatus == 'active'">{{ totalActive }}</span>
            <span v-if="currentStatus == 'inactive'">{{ totalInactive }}</span>
          </b>
          Admins
        </span>
      </p>
      <v-spacer></v-spacer>
      <v-btn
        round
        depressed
        class="text-capitalize white--text body-2 create-btn"
        color="dBlue"
        @click="showForm = 'create-workspace-group-admin'"
        autotest="workspace-group-add-admin-btn"
        >{{ $t("admin.addUser") }}</v-btn
      >
    </div>
    <div class="table-search-row">
      <div>
        <d-table-search
          v-model="searchWorkspaceGroupAdmin"
          :placeholder="$t('analytics.searchUser')"
          @input-search="debounceHandleFindUser($event)"
          autotest="workspace-group-admin-search-bar"
        >
          <div v-if="searchWorkspaceGroupAdmin.length == 0" class="filter-div">
            <user-status-filter
              autotest="workspace-user-status-filter"
              :statusDictionary="statusDictionary"
              :filterStatus="filterStatus"
              :currentStatus="currentStatus"
              @changeCurrentStatus="changeCurrentStatus"
            ></user-status-filter>
          </div>
        </d-table-search>
      </div>
    </div>
    <div v-show="currentStatus == 'active'" class="table-div" @click="hideUserCard()">
      <d-data-table
        padding-bottom
        infinite-scroll
        :loading="isLoading.usersData || isSearchingUsers"
        :headers="userTableHeaders"
        :items="activeUsersData"
        :noSearchResults="noSearchResults && searchWorkspaceGroupAdmin.length > 0"
        :loadedAllData="activeUsersData.length == totalActive"
        :showUserCard="viewUserEmail.length > 0"
        @load-more="getMoreUsers()"
        class="d-data-table"
      >
        <template slot="no-data">
          <span>This Workspace Group does not have members yet.</span>
        </template>
        <template slot="no-results">
          <span>{{ $t("admin.tableMsgNoRecordsThisWorkspace") }}</span>
        </template>
        <template slot="items" slot-scope="props">
          <td class="text-xs-left text-capitalize text-no-wrap">
            <div class="avatar-div">
              <d-avatar :size="32" :userId="props.item.id" />
              <div
                v-if="
                  isReviewer({
                    enableWorkflowReview,
                    workspaceGroupId,
                    reviewGroups: props.item.reviewGroups || [],
                    roles: props.item.roles,
                  })
                "
                class="avatar-icon reviewer-icon"
              >
                <icon-base width="18" height="18" viewBox="0 0 20 20" color="white">
                  <d-icon-reviewer />
                </icon-base>
              </div>
              <div v-else-if="props.item.role === 'admin-organization'" class="avatar-icon org-admin-icon">
                <icon-base width="14" height="14" color="white">
                  <d-icon-org-admin />
                </icon-base>
              </div>
              <div v-if="props.item.shared" class="avatar-icon shared-icon">
                <icon-base width="14" height="14" color="white">
                  <d-icon-shared-user />
                </icon-base>
              </div>
            </div>
            <d-tooltip-show-all
              :text="props.item.displayName"
              class="ml-3"
              autotest="workspace-user-name-display"
            ></d-tooltip-show-all>
          </td>
          <td class="text-xs-left" autotest="workspace-user-email-display">
            <span v-if="props.item.passwordless">-</span>
            <span v-else>{{ props.item.email }}</span>
          </td>
          <td class="text-xs-left">
            <d-tooltip-show-all
              :text="props.item.employeeId || '-'"
              autotest="workspace-user-employee-id-display"
            ></d-tooltip-show-all>
          </td>
          <td class="text-xs-left" autotest="workspace-last-activity-display">{{ props.item.lastActivityString }}</td>
          <td class="text-xs-left" autotest="workspace-user-role-dropdown">
            <button v-if="props.item.role == 'admin-organization'" class="role-btn text-capitalize disable-role-btn">
              {{ roleDictionary[props.item.role] }}
              <v-icon small class="dropdown-arrow">keyboard_arrow_down</v-icon>
            </button>
            <button
              v-else-if="
                props.item.id == currentUserId ||
                props.item.passwordless ||
                (props.item.role == 'admin-organization' && userRole == 'admin-group') ||
                props.item.invitation ||
                props.item.role == 'admin-organization'
              "
              class="role-btn text-capitalize disable-role-btn"
            >
              {{ getCurrentRole(props.item) }}
              <v-icon small class="dropdown-arrow">keyboard_arrow_down</v-icon>
            </button>
            <v-menu v-else transition="slide-y-transition" offset-y>
              <button slot="activator" class="role-btn text-capitalize btn-hover">
                {{ getCurrentRole(props.item) }}
                <v-icon small class="dropdown-arrow">keyboard_arrow_down</v-icon>
              </button>
              <v-list dense>
                <v-list-tile
                  v-for="(role, i) in roleOptions"
                  :key="i"
                  class="dropdown-list-item"
                  @click="clickChangeUserRole(role, props.item)"
                >
                  <v-list-tile-title class="body-1 text-capitalize">{{ roleDictionary[role] }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </td>
          <td class="text-xs-left text-capitalize text-no-wrap" autotest="workspace-user-status-display">
            {{ statusDictionary[props.item.status] }}
          </td>
          <td class="text-xs-right btns-td">
            <div class="tooltip-div">
              <button
                v-if="hasCustomUserAttributes"
                class="view-button btn-hover"
                :class="{ 'hide-view-btn': props.item.passwordless }"
                @mouseover="(mouseoverViewUserCard = true), showUserCard(props.item.email)"
                @mouseleave="mouseoverViewUserCard = false"
                @click="clickViewUserCard(props.item.email)"
              >
                <icon-base width="22" height="22" opacity="0.8">
                  <d-icon-preview />
                </icon-base>
                <d-admin-user-card
                  v-if="viewUserEmail == props.item.email && props.item.email != ''"
                  :userData="viewUserData"
                  :customUserAttributes="customUserAttributes"
                ></d-admin-user-card>
              </button>
              <d-tooltip v-if="!props.item.invitation" :text="$t('admin.tooltipEditUser')" class="tooltip-btn mr-3">
                <div
                  class="btn-tooltip-icon btn-hover"
                  :class="{ 'hide-editor-btn': props.item.status === 'could-not-be-invited' }"
                  @click="setUserEditor(props.item)"
                  autotest="workspace-user-edit-btn"
                >
                  <icon-base width="22" height="22">
                    <d-icon-pen />
                  </icon-base>
                </div>
              </d-tooltip>
              <d-tooltip
                v-if="!props.item.invitation && !isDefault"
                :text="$t('admin.tooltipRemoveUser')"
                class="tooltip-btn"
              >
                <button
                  class="btn-tooltip-icon btn-hover"
                  @click="clickRemoveUser(props.item)"
                  autotest="workspace-user-remove-btn"
                >
                  <icon-base width="22" height="22">
                    <d-icon-remove />
                  </icon-base>
                </button>
              </d-tooltip>
              <d-tooltip
                v-if="props.item.status == 'invited' || props.item.status == 'invitation-expired'"
                :text="$t('admin.tooltipResendInvite')"
                class="tooltip-btn mr-3"
              >
                <button
                  class="btn-tooltip-icon btn-hover"
                  @click="setResendInvitation(props.item)"
                  autotest="resend-invite-btn"
                >
                  <icon-base width="22" height="22">
                    <d-icon-resend />
                  </icon-base>
                </button>
              </d-tooltip>
              <d-tooltip
                v-if="props.item.status == 'invited' || props.item.status == 'invitation-expired'"
                :text="$t('admin.tooltipCancelInvite')"
                class="cancel-invite-btn"
              >
                <button
                  class="btn-tooltip-icon btn-hover"
                  @click="setDeleteInvitation(props.item)"
                  autotest="cancel-invite-btn"
                >
                  <icon-base width="18" height="18">
                    <d-icon-close2 />
                  </icon-base>
                </button>
              </d-tooltip>
              <v-menu v-if="showMoreBtn(props.item)" transition="slide-y-transition" bottom left offset-y class="ml-3">
                <button slot="activator" class="btn-tooltip-icon btn-hover" autotest="workspace-user-more-action-btn">
                  <icon-base width="20" height="20">
                    <d-icon-more />
                  </icon-base>
                </button>
                <v-list dense light>
                  <!-- Add or remove as reviewer -->
                  <template v-if="canBeReviewer({ enableWorkflowReview, roles: props.item.roles, workspaceGroupId })">
                    <v-list-tile
                      v-if="canAddAsReviewer({ workspaceGroupId, userData: props.item })"
                      @click="changeReviewerStatus({ isAdd: true, userData: props.item })"
                      autotest="add-as-reviewer-btn"
                    >
                      <v-list-tile-title class="body-1">{{ $t("admin.addAsReviewer") }}</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile
                      v-else
                      @click="changeReviewerStatus({ isAdd: false, userData: props.item })"
                      autotest="remove-as-reviewer-btn"
                    >
                      <v-list-tile-title class="body-1">{{ $t("admin.removeAsReviewer") }}</v-list-tile-title>
                    </v-list-tile>
                  </template>
                  <!-- Upgrade or downgrade to org admin -->
                  <template v-if="canUpgradeOrDowngradeUserToOrgAdmin">
                    <v-list-tile
                      v-if="canUpgradeToOrgAdmin(props.item)"
                      @click="showUpgradeOrgPopup(props.item)"
                      autotest="upgrade-org-admin-btn"
                    >
                      <v-list-tile-title class="body-1">{{ $t("admin.upgradeToOrgAdmin") }}</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile
                      v-if="canDowngradeOrgAdmin(props.item)"
                      @click="showDowngradeOrgPopup(props.item)"
                      autotest="downgrade-org-admin-btn"
                    >
                      <v-list-tile-title class="body-1">{{ $t("admin.downgradeOrgAdmin") }}</v-list-tile-title>
                    </v-list-tile>
                  </template>
                </v-list>
              </v-menu>
            </div>
          </td>
        </template>
      </d-data-table>
    </div>
    <div v-show="currentStatus == 'inactive'" class="table-div" @click="hideUserCard()">
      <d-data-table
        padding-bottom
        infinite-scroll
        :loading="isLoading.inactiveUsersData"
        :loadedAllData="inactiveUsersData.length == totalInactive"
        :headers="userTableHeaders"
        :items="inactiveUsersData"
        :noSearchResults="false"
        @load-more="getMoreInactiveUsers()"
        class="d-data-table"
      >
        <template slot="no-data">
          <span>{{ $t("admin.tableMsgNoUsersYet") }}</span>
        </template>
        <template slot="no-results">
          <span>{{ $t("admin.tableMsgNoRecordsThisWorkspace") }}</span>
        </template>
        <template slot="items" slot-scope="props">
          <td class="text-xs-left text-capitalize text-no-wrap">
            <div class="avatar-div">
              <d-avatar :size="32" :userId="props.item.id" />
              <div
                v-if="
                  isReviewer({
                    enableWorkflowReview,
                    workspaceGroupId,
                    reviewGroups: props.item.reviewGroups || [],
                    roles: props.item.roles,
                  })
                "
                class="avatar-icon reviewer-icon"
              >
                <icon-base width="18" height="18" viewBox="0 0 20 20" color="white">
                  <d-icon-reviewer />
                </icon-base>
              </div>
              <div v-else-if="props.item.role === 'admin-organization'" class="avatar-icon org-admin-icon">
                <icon-base width="14" height="14" color="white">
                  <d-icon-org-admin />
                </icon-base>
              </div>
              <div v-if="props.item.shared" class="avatar-icon shared-icon">
                <icon-base width="14" height="14" color="white">
                  <d-icon-shared-user />
                </icon-base>
              </div>
            </div>
            <d-tooltip-show-all
              :text="props.item.displayName"
              class="ml-3"
              autotest="workspace-user-name-display"
            ></d-tooltip-show-all>
          </td>
          <td class="text-xs-left" autotest="workspace-user-email-display">
            <span v-if="props.item.passwordless">-</span>
            <span v-else>{{ props.item.email }}</span>
          </td>
          <td class="text-xs-left">
            <d-tooltip-show-all
              :text="props.item.employeeId || '-'"
              autotest="workspace-user-employee-id-display"
            ></d-tooltip-show-all>
          </td>
          <td class="text-xs-left" autotest="workspace-last-activity-display">{{ props.item.lastActivityString }}</td>
          <td class="text-xs-left" autotest="workspace-user-role-dropdown">
            <button v-if="props.item.role == 'admin-organization'" class="role-btn text-capitalize disable-role-btn">
              {{ roleDictionary[props.item.role] }}
              <v-icon small class="dropdown-arrow">keyboard_arrow_down</v-icon>
            </button>
            <button
              v-else-if="
                props.item.id == currentUserId ||
                props.item.passwordless ||
                (props.item.role == 'admin-organization' && userRole == 'admin-group') ||
                props.item.invitation ||
                props.item.role == 'admin-organization'
              "
              class="role-btn text-capitalize disable-role-btn"
            >
              {{ getCurrentRole(props.item) }}
              <v-icon small class="dropdown-arrow">keyboard_arrow_down</v-icon>
            </button>
            <v-menu v-else transition="slide-y-transition" offset-y>
              <button slot="activator" class="role-btn text-capitalize btn-hover">
                {{ getCurrentRole(props.item) }}
                <v-icon small class="dropdown-arrow">keyboard_arrow_down</v-icon>
              </button>
              <v-list dense>
                <v-list-tile
                  v-for="(role, i) in roleOptions"
                  :key="i"
                  class="dropdown-list-item"
                  @click="clickChangeUserRole(role, props.item)"
                >
                  <v-list-tile-title class="body-1 text-capitalize">{{ roleDictionary[role] }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </td>
          <td class="text-xs-left text-capitalize text-no-wrap" autotest="workspace-user-status-display">
            {{ statusDictionary[props.item.status] }}
          </td>
          <td class="text-xs-right btns-td">
            <div class="tooltip-div">
              <button
                v-if="hasCustomUserAttributes"
                class="view-button btn-hover"
                :class="{ 'hide-view-btn': props.item.passwordless }"
                @mouseover="(mouseoverViewUserCard = true), showUserCard(props.item.email)"
                @mouseleave="mouseoverViewUserCard = false"
                @click="clickViewUserCard(props.item.email)"
              >
                <icon-base width="22" height="22" opacity="0.8">
                  <d-icon-preview />
                </icon-base>
                <d-admin-user-card
                  v-if="viewUserEmail == props.item.email && props.item.email != ''"
                  :userData="viewUserData"
                  :customUserAttributes="customUserAttributes"
                ></d-admin-user-card>
              </button>
              <d-tooltip v-if="!props.item.invitation" :text="$t('admin.tooltipEditUser')" class="tooltip-btn mr-3">
                <div
                  class="btn-tooltip-icon btn-hover"
                  :class="{ 'hide-editor-btn': props.item.status === 'could-not-be-invited' }"
                  @click="setUserEditor(props.item)"
                  autotest="workspace-user-edit-btn"
                >
                  <icon-base width="22" height="22">
                    <d-icon-pen />
                  </icon-base>
                </div>
              </d-tooltip>
              <d-tooltip
                v-if="!props.item.invitation && !isDefault"
                :text="$t('admin.tooltipRemoveUser')"
                class="tooltip-btn"
              >
                <button
                  class="btn-tooltip-icon btn-hover"
                  @click="clickRemoveUser(props.item)"
                  autotest="workspace-user-remove-btn"
                >
                  <icon-base width="22" height="22">
                    <d-icon-remove />
                  </icon-base>
                </button>
              </d-tooltip>
              <d-tooltip
                v-if="props.item.status == 'invited' || props.item.status == 'invitation-expired'"
                :text="$t('admin.tooltipResendInvite')"
                class="tooltip-btn mr-3"
              >
                <button
                  class="btn-tooltip-icon btn-hover"
                  @click="setResendInvitation(props.item)"
                  autotest="resend-invite-btn"
                >
                  <icon-base width="22" height="22">
                    <d-icon-resend />
                  </icon-base>
                </button>
              </d-tooltip>
              <d-tooltip
                v-if="props.item.status == 'invited' || props.item.status == 'invitation-expired'"
                :text="$t('admin.tooltipCancelInvite')"
                class="cancel-invite-btn"
              >
                <button
                  class="btn-tooltip-icon btn-hover"
                  @click="setDeleteInvitation(props.item)"
                  autotest="cancel-invite-btn"
                >
                  <icon-base width="18" height="18">
                    <d-icon-close2 />
                  </icon-base>
                </button>
              </d-tooltip>
            </div>
          </td>
        </template>
      </d-data-table>
    </div>

    <!-- components for popups-->
    <admin-workspace-group-user-popup
      v-if="showForm == 'create-workspace-group-admin'"
      :showForm="showForm"
      :workspaceGroupName="workspaceGroupName"
      :workspaceGroupId="workspaceGroupId"
      :workspaceGroupLocale="workspaceGroupLocale"
      :allRoleOptions="roleOptions"
      @closeAdminInvite="showForm = ''"
      @reloadUserData="getUsersData(), $emit('reloadUserData')"
      @updateUserCount="updateUserCount"
      @update-found-user="updateFoundUser($event, 'add')"
    ></admin-workspace-group-user-popup>

    <admin-form-user-editor
      v-if="showForm == 'user-editor'"
      :parent="'admin-workspace-group-users'"
      :formData="formData"
      :roleOptions="roleOptions"
      :userRole="userRole"
      :workspaceGroupId="workspaceGroupId"
      :workspaceGroupDictionary="workspaceGroupDictionary"
      showReviewerIcon
      @closeUserEditor="showForm = ''"
      @update-found-user="updateFoundUser($event, 'update')"
    ></admin-form-user-editor>

    <d-change-user-role-confirmation
      v-if="showForm == 'change-user-role'"
      :oldRole="formData.roles[workspaceGroupId]"
      :newRole="newRole"
      :formData="formData"
      :roleDictionary="roleDictionary"
      @closePopup="showForm = ''"
      @changeRole="changeRole"
    ></d-change-user-role-confirmation>

    <upgrade-to-org-admin-confirmation
      v-if="showForm === 'upgrade-to-org'"
      :formData="formData"
      @update-success="updateTolatestUserdataFromDb"
      @closePopup="(showForm = ''), (formData = {})"
    ></upgrade-to-org-admin-confirmation>

    <downgrade-org-admin-confirmation
      v-if="showForm === 'downgrade-org'"
      :formData="formData"
      @update-success="updateTolatestUserdataFromDb"
      @closePopup="(showForm = ''), (formData = {})"
    ></downgrade-org-admin-confirmation>

    <!-- Successfully updated user settings. -->
    <d-alert v-model="editUserSuccess" type="success" :message="$t('admin.alertUpdatedUserSettings')"></d-alert>

    <d-remove-user-confirmation
      v-if="showForm == 'remove-workspace-group-admin'"
      :showForm="showForm"
      :formData="formData"
      @closePopup="showForm = ''"
      @removeWorkspaceGroupAdmin="removeWorkspaceGroupAdmin"
    ></d-remove-user-confirmation>

    <!-- Successfully removed admin from workspace-group -->
    <d-alert v-model="removeUserSuccess" type="success" :message="$t('admin.alertRemoveUser')"></d-alert>
    <d-alert v-model="removeUserFailed" type="error" :message="$t('admin.alertFailedRemoveUser')"></d-alert>
  </section>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import MixinAnalytics from "@/components/MixinAnalytics.vue";
import IconBase from "@/components/IconBase.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconRemove from "@/components/icons/DIconRemove.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DTooltip from "@/components/ui_components/DTooltip.vue";
import DIconResend from "@/components/icons/DIconResend.vue";
import AdminFormUserEditor from "@/components/DAdmin/AdminFormUserEditor.vue";
import DChangeUserRoleConfirmation from "@/components/ui_components/DChangeUserRoleConfirmation.vue";
import DRemoveUserConfirmation from "@/components/ui_components/DRemoveUserConfirmation.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import DIconPreview from "@/components/icons/DIconPreview.vue";
import DIconOrgAdmin from "@/components/icons/DIconOrgAdmin.vue";
import DIconSharedUser from "@/components/icons/DIconSharedUser.vue";
import DIconMore from "@/components/icons/DIconMore.vue";
import DIconReviewer from "@/components/icons/DIconReviewer.vue";
import DAdminUserCard from "@/components/ui_components/DAdminUserCard.vue";
import DTableSearch from "@/components/ui_components/DTableSearch.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import DTooltipShowAll from "@/components/ui_components/DTooltipShowAll.vue";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import AdminWorkspaceGroupUserPopup from "@/components/DAdmin/workspaceGroup/AdminWorkspaceGroupUserPopup.vue";
import UpgradeToOrgAdminConfirmation from "@/components/DAdmin/confirmations/UpgradeToOrgAdminConfirmation.vue";
import DowngradeOrgAdminConfirmation from "@/components/DAdmin/confirmations/DowngradeOrgAdminConfirmation.vue";
import AdminSharedAccountPopup from "@/components/DAdmin/AdminSharedAccountPopup.vue";
import UserStatusFilter from "@/components/UserStatusFilter.vue";
import {
  canUpgradeToOrgAdmin,
  canDowngradeOrgAdmin,
  updateVuexUserDataWhenRemovedUserToWorkspace,
  formatUserDataToDisplayOnDataTableWhenRemoveSuccess,
  isReviewer,
  canBeReviewer,
  canAddAsReviewer,
} from "@/js/user-role/user-role.js";
import { appendMissingUserDataFromDB, updateTolatestUserdataFromDb } from "@/js/user/user.js";
import { getBaseDataForUserDataTable, USER_DATA_TABLE_TYPE } from "@/js/user-data-table/user-data-table.js";
import { debounce } from "lodash-es";

export default {
  name: "AdminWorkspaceGroupAdmins",
  mixins: [MixinDB, MixinUser, MixinAdmin, MixinAnalytics],
  components: {
    IconBase,
    DIconSearch,
    DIconRemove,
    DIconPen,
    DIconResend,
    DIconClose2,
    DTooltip,
    AdminFormUserEditor,
    DChangeUserRoleConfirmation,
    DRemoveUserConfirmation,
    DConfirmationPopup,
    DAlert,
    DIconPreview,
    DIconOrgAdmin,
    DIconSharedUser,
    DIconMore,
    DIconReviewer,
    DAdminUserCard,
    DTableSearch,
    DDataTable,
    DTooltipShowAll,
    DAvatar,
    AdminWorkspaceGroupUserPopup,
    UpgradeToOrgAdminConfirmation,
    DowngradeOrgAdminConfirmation,
    AdminSharedAccountPopup,
    UserStatusFilter,
  },
  watch: {
    viewUserEmail: function () {
      if (this.viewUserEmail) {
        this.viewUserData = {};
        this.getViewUserData();
      }
    },
  },
  props: {
    workspaceGroupName: String,
    workspaceGroupId: String,
    workspaceGroupLocale: String,
    userRole: String,
    workspaceGroupDictionary: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showForm: "",
      searchWorkspaceGroupAdmin: "",
      roleOptions: [],
      formData: {},
      newRole: "",
      userTableHeaders: [
        {
          text: this.$t("all.namePerson"),
          align: "left",
          sortable: true,
          value: "displayName",
          autotest: "workspace-user-name-sort",
        },
        {
          text: this.$t("all.email"),
          align: "left",
          sortable: true,
          value: "email",
          autotest: "workspace-user-email-sort",
        },
        {
          text: this.$t("all.employeeId"),
          align: "left",
          sortable: true,
          value: "employeeId",
          autotest: "workspace-user-employee-id-sort",
        },
        {
          text: this.$t("admin.lastActivity"),
          align: "left",
          sortable: true,
          value: "lastActivityTimestamp",
          autotest: "last-activity-sort",
        },
        {
          text: this.$t("all.role"),
          align: "left",
          sortable: false,
          value: "currentRoleNew",
          autotest: "workspace-user-role-sort",
        },
        {
          text: this.$t("admin.status"),
          align: "left",
          sortable: true,
          value: "status",
          autotest: "workspace-user-status-sort",
        },
        {
          align: "left",
          sortable: false,
          value: "",
        },
      ],
      editUserSuccess: false,
      removeUserSuccess: false,
      removeUserFailed: false,
      currentUserId: "",
      roleDictionary: {
        viewer: this.$t("all.viewer"),
        publisher: this.$t("all.publisher"),
        "admin-group": this.$t("all.workspaceAdmin"),
        "admin-organization": this.$t("all.organizationAdmin"),
      },
      isDefault: false,
      isLoading: {
        usersData: false,
        inactiveUsersData: false,
      },
      statusDictionary: {
        active: this.$t("admin.active"),
        inactive: this.$t("admin.inactive"),
      },
      viewUserId: "",
      viewUserEmail: "",
      viewUserData: {},
      currentStatus: "active",
      filterStatus: ["active", "inactive"],
      isSearchingUsers: false,
      foundUsers: [],
      getUsersFirstTime: false,
      noSearchResults: false,
      totalActive: 0,
      totalInactive: 0,
      isRemovingUser: false,
      enableWorkflowReview: false,
      reviewerAlert: {
        show: false,
        type: "success",
        message: "",
      },
    };
  },
  computed: {
    activeUsersData() {
      if (this.searchWorkspaceGroupAdmin) {
        return this.foundUsers;
      } else {
        return this.$store.state.groupUsers;
      }
    },
    inactiveUsersData() {
      if (this.searchWorkspaceGroupAdmin) {
        return this.foundUsers;
      } else {
        return this.$store.state.groupInactiveUsers;
      }
    },
    hasCustomUserAttributes() {
      try {
        return this.$clientSettings.userMappings.customUserAttributes ? true : false;
      } catch (err) {
        return false;
      }
    },
    customUserAttributes() {
      try {
        return this.$clientSettings.userMappings.customUserAttributes;
      } catch (err) {
        return [];
      }
    },
    canUpgradeOrDowngradeUserToOrgAdmin() {
      const isAllowedRole = this.userRole === "admin-organization";
      return isAllowedRole;
    },
  },
  created() {
    this.getRoleOptions();
    this.currentUserId = this.$store.state.userProfile[0].id;
    if (this.workspaceGroupId == this.$defaultGroup) {
      this.isDefault = true;
    }

    if (!this.getUsersFirstTime) {
      this.getMoreUsers();
      this.getMoreInactiveUsers();
      this.getUsersFirstTime = false;
    }

    /** get permission to check enableWorkflowReview */
    const { enableWorkflowReview = false } = this.getCustomizedPermissions();
    this.enableWorkflowReview = enableWorkflowReview;
  },
  methods: {
    /** reviewer function */
    isReviewer,
    canBeReviewer,
    canAddAsReviewer,
    async changeReviewerStatus({ isAdd = true, userData = { email, reviewGroups: [] } }) {
      const data = {
        workspaceGroupId: this.workspaceGroupId,
        email: userData.email,
        enabled: isAdd, // false is remove
      };
      const i18nParam = { user: userData.email };
      try {
        /** request api to update */
        const { data: resultMsg } = await this.sendAdminRequest("change", "group-reviewer-user", data);
        const isSuccess = resultMsg.includes("successfully assign/dismiss user");
        if (!isSuccess) {
          // jump to catch block
          throw new Error("update reviewer error");
        }

        /** if request successfully, then update vuex data */
        const addGroup = (arr) => {
          return [...arr, this.workspaceGroupId];
        };
        const removeGroup = (arr) => {
          return arr.filter((workspaceGroupId) => workspaceGroupId !== this.workspaceGroupId);
        };
        const isToRemoveUser = false; // true is remove user
        const oldReviewGroups = userData.reviewGroups || [];
        const reviewGroups = isAdd ? addGroup(oldReviewGroups) : removeGroup(oldReviewGroups);
        this.$store.dispatch("updateUser", {
          user: userData,
          update: {
            reviewGroups,
            disabled: isToRemoveUser,
          },
          action: "update",
        });
        this.reviewerAlert = {
          show: true,
          type: "success",
          message: this.$t(
            isAdd ? "admin.addAsReviewerSuccessMessage" : "admin.removeAsReviewerSuccessMessage",
            i18nParam
          ),
        };
      } catch (e) {
        console.error(e);
        this.reviewerAlert = {
          show: true,
          type: "error",
          message: this.$t(
            isAdd ? "admin.addAsReviewerFailedMessage" : "admin.removeAsReviewerSuccessMessage",
            i18nParam
          ),
        };
      }
    },
    /** other function */
    async updateTolatestUserdataFromDb({ targetUserData }) {
      await updateTolatestUserdataFromDb({
        mixinDbRef: this,
        userDataRef: targetUserData,
      });
      /** after update, targetUserData value have been changed, detail see updateTolatestUserdataFromDb in user.js*/
      /** update vuex data */
      this.$store.dispatch("updateUser", {
        user: targetUserData,
        update: targetUserData,
        action: "update",
      });
    },
    canUpgradeToOrgAdmin(userData) {
      return canUpgradeToOrgAdmin({
        userData,
        vueInstanceRef: this,
      });
    },
    canDowngradeOrgAdmin(userData) {
      return canDowngradeOrgAdmin({
        userData,
        vueInstanceRef: this,
      });
    },
    showMoreBtn(userData) {
      const isActiveUser = userData.status === "active";
      const isEmployeeIDUser = !!userData.passwordless;
      const hasFeature =
        this.canBeReviewer({
          enableWorkflowReview: this.enableWorkflowReview,
          roles: userData.roles,
          workspaceGroupId: this.workspaceGroupId,
        }) || this.canUpgradeOrDowngradeUserToOrgAdmin;
      return isActiveUser && !isEmployeeIDUser && hasFeature;
    },
    getCurrentRole(user) {
      if (user.roles && user.roles[this.workspaceGroupId]) {
        return this.roleDictionary[user.roles[this.workspaceGroupId]];
      } else {
        return this.roleDictionary[user.role];
      }
    },
    clickViewUserCard(email) {
      if (this.viewUserEmail == email) {
        this.viewUserEmail = "";
        clearTimeout(this.delayTimeout);
        this.showCardDelay = true;
        this.delayTimeout = setTimeout(() => {
          this.showCardDelay = false;
        }, 500);
      } else {
        this.viewUserEmail = email;
      }
    },
    showUserCard(email) {
      if (!this.showCardDelay) {
        this.viewUserEmail = email;
      }
    },
    hideUserCard() {
      if (!this.mouseoverViewUserCard && this.viewUserEmail.length > 0) {
        this.viewUserEmail = "";
        clearTimeout(this.delayTimeout);
        this.showCardDelay = true;
        this.delayTimeout = setTimeout(() => {
          this.showCardDelay = false;
        }, 500);
      }
    },
    debounceHandleFindUser: debounce(async function (searchInput) {
      await this.handleFindUser(searchInput);
    }, 500),
    async handleFindUser(searchInput) {
      this.isSearchingUsers = true;
      try {
        const response = await this.findUser(searchInput, this.workspaceGroupId, false);
        const query = JSON.parse(response.config.data).text;
        if (searchInput == query) {
          const results = response.data.results;
          const fResults = this.formatUserSearchResults(results);
          if (!fResults.length) {
            this.noSearchResults = true;
          } else {
            this.noSearchResults = false;
          }

          const foundUsers = fResults.splice(0, 100);
          if (foundUsers.length) {
            await appendMissingUserDataFromDB({
              mixinDbRef: this,
              userListRef: foundUsers,
            });

            foundUsers.forEach((user) => {
              Object.assign(
                user,
                getBaseDataForUserDataTable({
                  user,
                  dataTableType: user.invitation ? USER_DATA_TABLE_TYPE.INVITED : this.currentStatus,
                })
              );
            });
          }

          this.foundUsers = foundUsers;
          this.isSearchingUsers = false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    getMoreInactiveUsers() {
      this.isLoading.inactiveUsersData = true;
      this.$store.dispatch("getGroupInactiveUsers", {
        organization: this.$organization,
        group: this.workspaceGroupId,
      });
      this.$store.subscribe(
        function (mutation, state) {
          if (mutation.type == "getGroupInativeUsersEnd") {
            this.isLoading.inactiveUsersData = false;
          }
        }.bind(this)
      );
    },
    getMoreUsers() {
      this.isLoading.usersData = true;
      this.$store.dispatch("getGroupUsers", {
        organization: this.$organization,
        group: this.workspaceGroupId,
      });
      this.$store.subscribe(
        function (mutation, state) {
          if (mutation.type == "getGroupUsersEnd") {
            this.isLoading.usersData = false;
          }
        }.bind(this)
      );
    },
    getViewUserData() {
      this.getDocument("userImports", this.viewUserEmail)
        .then((data) => {
          // this.$set(this.viewUserData, 'employeeId', data.employeeId)
          this.getUidByEmail(this.viewUserEmail).then((userId) => {
            let userData = {
              id: userId,
              ...data,
            };
            this.viewUserData = userData;
          });
        })
        .catch((e) => {
          console.warn("viewUserEmail may not exists", this.viewUserEmail);
          // we can show avatar without data
          this.getUidByEmail(this.viewUserEmail).then((userId) => {
            let userData = {
              id: userId,
            };
            this.viewUserData = userData;
          });
        });
    },
    getRoleOptions() {
      if (this.userRole == "admin-group") {
        this.roleOptions = ["viewer", "publisher", "admin-group"];
      }
      if (this.userRole == "admin-organization") {
        this.roleOptions = [
          "viewer",
          "publisher",
          "admin-group",
          // "admin-organization"
        ];
      }
    },
    clickChangeUserRole(newRole, user) {
      if (user.roles[this.workspaceGroupId] != newRole) {
        this.formData = user;
        this.newRole = newRole;
        this.showForm = "change-user-role";
      }
    },
    clickRemoveUser(user) {
      this.formData = user;
      this.showForm = "remove-workspace-group-admin";
    },
    setUserEditor(user) {
      this.formData = user;
      this.showForm = "user-editor";
    },
    changeRole() {
      /** if new role is viewer, need to remove as reviewer */
      const oldReviewGroups = this.formData.reviewGroups || [];
      const newReviewGroups = oldReviewGroups.filter((group) => {
        return group !== this.workspaceGroupId ? true : this.newRole !== "viewer";
      });
      /** set params */
      const self = this;
      const data = {
        email: this.formData.email,
        role: this.newRole,
        workspaceGroupId: this.workspaceGroupId,
        reviewGroups: newReviewGroups,
      };

      self
        .sendAdminRequest("change", "user-group-role-change", data)
        .then((response) => {
          const roles = self.formData.roles;
          roles[self.workspaceGroupId] = self.newRole;
          self.$store.dispatch("updateUser", {
            user: self.formData,
            update: { roles: roles, currentRole: self.newRole, reviewGroups: newReviewGroups },
            action: "update",
          });
          self.formData = {};
          self.showForm = "";
          self.editUserSuccess = true;
        })
        .catch((err) => {
          alert("failed to change role.");
        });
    },
    removeWorkspaceGroupAdmin() {
      if (this.isRemovingUser) {
        return;
      }

      const self = this;
      const data = {
        email: this.formData.email,
        workspaceGroupId: this.workspaceGroupId,
      };
      if (this.formData.invitation) {
        alert("Invitations currently cannot be manually removed. They'll be automatically removed when expired.");
        return;
      }
      this.isRemovingUser = true;
      self
        .sendAdminRequest("delete", "group-user", data)
        .then((response) => {
          updateTolatestUserdataFromDb({
            mixinDbRef: self,
            userDataRef: self.formData,
          });
          formatUserDataToDisplayOnDataTableWhenRemoveSuccess({
            vueInstanceRef: self,
            userRef: self.formData,
            workspaceGroupId: self.workspaceGroupId,
          });
          updateVuexUserDataWhenRemovedUserToWorkspace({
            vueInstanceRef: self,
            user: self.formData,
          });
          self.updateFoundUser({ user: self.formData }, "remove");
          if (self.formData.disabled) {
            self.updateUserCount("remove", "inactive");
          } else {
            self.updateUserCount("remove", "active");
          }
          self.formData = {};
          self.showForm = "";
          self.removeUserSuccess = true;
        })
        .catch((err) => {
          self.removeUserFailed = true;
        })
        .finally(() => {
          this.isRemovingUser = false;
        });
    },
    updateUserCount(action, userStatus) {
      switch (userStatus) {
        case "active":
          if (action == "add") this.totalActive++;
          if (action == "remove") this.totalActive--;
          break;
        case "inactive":
          if (action == "add") this.totalInactive++;
          if (action == "remove") this.totalInactive--;
          break;
        case "invited":
          if (action == "add") this.totalInvited++;
          if (action == "remove") this.totalInvited--;
          break;
        default:
      }
    },
    updateFoundUser(update, type) {
      if (this.searchWorkspaceGroupAdmin.length > 0) {
        if (type == "add") {
          this.searchWorkspaceGroupAdmin = "";
          this.foundUsers = [];
        } else if (type == "remove") {
          const index = this.foundUsers.findIndex((foundUser) => foundUser.id == update.user.id);
          if (index > -1) this.foundUsers.splice(index, 1);
        } else {
          //update
          let updateUser = this.foundUsers.find((foundUser) => foundUser.id == update.user.id);
          if (updateUser) {
            for (const key in update.update) {
              updateUser[key] = update.update[key];
            }
          }
          this.foundUsers.splice();
        }
      }
    },
    showUpgradeOrgPopup(user) {
      this.formData = user;
      this.showForm = "upgrade-to-org";
    },
    showDowngradeOrgPopup(user) {
      this.formData = user;
      this.showForm = "downgrade-org";
    },
    changeCurrentStatus(status) {
      this.currentStatus = status;
    },
  },
};
</script>
<style scoped>
.admin-group-users {
  position: fixed;
  left: 0;
  height: calc(100% - 138px);
  width: 100%;
  padding: 0;
  background-color: #fbfbfb;
}
.tab-title-row {
  position: relative;
  display: flex;
  width: 100%;
  height: 86px;
  padding: 26px 28px 0 28px;
}
.back-btn {
  padding: 12px 10px 10px 0;
  height: 44px;
  margin-left: -6px;
}
.tab-title {
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  text-align: left;
  line-height: 32px;
  height: 36px;
  margin-top: 8px;
}
.create-btn {
  min-width: 140px;
  text-transform: none !important;
}
.avatar-td {
  width: 32px;
}
.btns-td {
  min-width: 180px;
  width: 180px;
  z-index: 999 !important;
}
.tooltip-div {
  display: flex;
  text-align: right;
}

.avatar-placeholder {
  height: 32px;
  width: 32px;
  background-color: #8d909f;
  color: white;
  text-align: center;
  font-size: 12px;
  padding-top: 7px;
  border-radius: 16px;
}
.table-editor-btn {
  padding-top: 8px;
  color: #78767a;
  margin-right: 8px;
}
.hide-editor-btn {
  opacity: 0;
  pointer-events: none;
}
.disable-role-btn {
  pointer-events: none;
  opacity: 0.5;
}
.role-btn {
  position: relative;
  background-color: white;
  min-height: 34px;
  width: 174px;
  text-align: left;
  padding: 6px 8px;
  border: 1px solid #dadada;
  line-height: 20px;
}
.dropdown-arrow {
  position: absolute;
  right: 8px;
  color: #78767a;
  top: calc(50% - 8px);
}
.dropdown-list-item {
  color: #78767a;
}
.dropdown-list-item:hover {
  background-color: #f5f5f5 !important;
}
.view-button {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-right: 18px;
  color: #78767a;
}
.hide-view-btn {
  opacity: 0.3;
  opacity: 0;
  pointer-events: none;
}
.tooltip-btn {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
}
.btn-tooltip-icon {
  color: #78767a;
  opacity: 0.8;
}
.cancel-invite-btn {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  padding: 2px;
}
.btn-hover:hover {
  color: #2d2e32;
  cursor: pointer;
}
.role-btn:hover .dropdown-arrow {
  color: #2d2e32;
}
/* new table */
.table-search-row {
  position: relative;
  width: 100%;
  padding: 0 28px;
}
.table-div {
  position: fixed;
  left: 0;
  width: 100%;
  padding: 0 28px;
  height: calc(100% - 304px);
}
.filter-div {
  position: relative;
}
.avatar-div {
  position: relative;
  display: inline-block;
  height: 32px;
  width: 32px;
}
.avatar-icon {
  position: absolute;
  right: -10px;
  bottom: -4px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  padding: 3px;
}
.reviewer-icon {
  padding: 1.5px 0 0 1px;
  background: #4552ae;
}
.org-admin-icon {
  background-color: #ffbf18;
}
.shared-icon {
  background-color: #a9a9a9;
}
@media screen and (max-width: 959px) {
  .tab-title-row {
    padding: 26px 20px 0 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1903px) {
  .tab-title-row {
    padding: 26px 28px 0 28px;
  }
}
</style>
