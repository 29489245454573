import OSS from 'ali-oss'
import axios from "axios";

const requestTimeout = 180 * 1000; // 3mins in ms;
const instanceTimeout = 1 * 60 * 60 * 1000 ; // one hour
const idTokenClientCheckMap = {};
const initialTaskQueue = [];

let aliEndpoint = null;
let adminRequireUserData = null;

// https://help.aliyun.com/document_detail/93744.html?spm=a2c4g.11186623.2.14.66e013396LmGFw#concept-tdn-n2k-xdb
// https://blog.csdn.net/yygApplication/article/details/106381370
// https://blog.csdn.net/yangkangv/article/details/88663167?utm_medium=distribute.pc_relevant_t0.none-task-blog-BlogCommendFromMachineLearnPai2-1.edu_weight&depth_1-utm_source=distribute.pc_relevant_t0.none-task-blog-BlogCommendFromMachineLearnPai2-1.edu_weight
// https://help.aliyun.com/document_detail/100624.html?spm=a2c4g.11186623.2.12.155479f8PMobJ1#concept-xzh-nzk-2gb
// https://developer.aliyun.com/article/670233
// https://www.alibabacloud.com/help/zh/doc-detail/31935.htm

const setUserStsData = function(userData, retry = 3){
    retry--;
    if(!userData || !userData.idToken){
        console.warn("adminRequireUserData is not valid" , userData);
        return;
    }
    adminRequireUserData = userData;
    console.log("adminRequireUserData is been set " , adminRequireUserData);
    //pre trigger for caching client
    ossClientOperation()
        .then((currentOssclient) =>{
            console.log("ossClientOperation current oss client " , currentOssclient);
            let index = 0;
            for(const queuedTask of initialTaskQueue){
                ossClientOperation().then(queuedTask.resolve).catch(queuedTask.reject);
                index++;
            }
            initialTaskQueue.splice(0,initialTaskQueue.length); // clean after queue task done
        })
        .catch((error) => {
            console.error(error);
            if(retry > 0){
                setUserStsData(userData, retry);
            }
        });
}

const ossClientOperation = function() {
    return new Promise((resolve,reject) => {
        if(!adminRequireUserData || !adminRequireUserData.idToken){
            initialTaskQueue.push({resolve,reject});
            return;
        }
        const idToken = adminRequireUserData.idToken;
        const now = new Date().getTime();
        if(idTokenClientCheckMap[idToken] != null 
            && idTokenClientCheckMap[idToken].ossClient != null
            && now - idTokenClientCheckMap[idToken].timestamp < Math.round(instanceTimeout * 0.5) ){
            //use previous cached client
            try{
                resolve(idTokenClientCheckMap[idToken].ossClient);
                return;
            }catch(error){
                console.error(error);
                //ignore , continue process to get new refresh sts client
            }        

        } else if(idTokenClientCheckMap[idToken] != null && idTokenClientCheckMap[idToken].requesting){
            idTokenClientCheckMap[idToken].requestingQueue.push({resolve,reject});
            return;
        }        

        idTokenClientCheckMap[idToken] = {
            requesting : true,
            requestingQueue:[]
        };
        //get sts token data from admin server         
        const stsServerUrl = window.service.admin + "secret/ali/sts/token";
        axios
            .post(stsServerUrl, {
                token: "rG5kXk0CDbhgF4RBlNoV",
                data: adminRequireUserData
            })
            .then(function(response) {
                let enpointConfig ={};
                if(response.data.accelerateEndpoint){
                    enpointConfig ={ endpoint : response.data.accelerateEndpoint };
                } else if(response.data.endpoint){
                    enpointConfig ={ endpoint : response.data.endpoint };
                } else if(response.data.proxy){
                    enpointConfig ={ endpoint : response.data.proxy + response.data.region + ".aliyuncs.com" };
                }      
                aliEndpoint = enpointConfig.endpoint;
                
                const ossClient = new OSS({  // https://developer.aliyun.com/mirror/npm/package/ali-oss                        
                    accessKeyId : response.data.sts.Credentials.AccessKeyId,
                    accessKeySecret : response.data.sts.Credentials.AccessKeySecret,
                    stsToken: response.data.sts.Credentials.SecurityToken,
                    region : response.data.ossBucketRegion,
                    bucket : response.data.ossBucketName,
                    secure : true,
                    timeout: instanceTimeout,
                    ...enpointConfig
                });
                const toExecuteQueue = idTokenClientCheckMap[idToken].requestingQueue;

                idTokenClientCheckMap[idToken] = {
                    timestamp: now,
                    ossClient: ossClient,
                    requesting : false,
                    requestingQueue:[]
                };
                resolve(ossClient);
                for(const execQ of toExecuteQueue){
                    execQ.resolve(ossClient);
                }
                toExecuteQueue.splice(0,toExecuteQueue.length); // clean after queue task done
            })
            .catch(function(error) {            
                console.error(error);
                reject(error);
            });
            
    });    
};

const uploadFile = function(folder, file, metadata, progressFn) {
    return new Promise(async function (resolve, reject) {
        try {
            let maxRetryCount = 3;
            let retryCount = 0;
            let uploadResult = {result: null};
            while (retryCount <= maxRetryCount && uploadResult.result == null) {
                uploadResult = await OssFileUpload(folder, file, metadata, progressFn, retryCount);
                retryCount++;
            }

            if (uploadResult.result == null) {
                reject(uploadResult.error);
                return;
            }

            resolve(uploadResult);
        } catch (error) {
            reject(error);
        }
    });
}

const OssFileUpload = async function(folder, file, metadata, progressFn, retry) {
    const objectKey = folder + file.name;
    return new Promise((resolve, reject) => {
        ossClientOperation()
            .then((ossClient) => {
                ossClient.multipartUpload(
                        objectKey, 
                        file, 
                        {
                            parallel: 3,
                            partSize: 2 * 1024 * 1024, //2 * 1mb
                            timeout: requestTimeout,
                            metadata, 
                            meta : metadata.contentType ? {} :{mime : metadata.contentType }, 
                            progress: progressFn                        
                        }
                    ).then(result => {
                        resolve({
                            result: result,
                            code : 0,
                            objectKey : objectKey,
                            url : getOssFileUrl(ossClient.options.bucket, ossClient.options.region, objectKey),
                            msg : "ok"
                        });
                    }).catch(err => {
                        if (err.code === 'ConnectionTimeoutError') {
                            console.warn(err.code + ". Retrying...retry=" + retry);
                            resolve({
                                result: null, 
                                error: {error: err, code: -1, url: "", objectKey: "", msg: "error"}
                            });
                        } else {
                            console.error('error',err);
                            reject({error: err, code: -1, url: "", objectKey: "", msg: "error"});
                        }
                    });
            })
            .catch((err) => {
                console.error('error',err);
                reject({error: err, code:-1, url : "", objectKey : "", msg : "error"});
            });
    });
};
    
const getOssFileUrl = function(bucket, region, objectKey) {        
    if(!objectKey) {
        return new Error("object key is required");
    }

    return "https://" + bucket + "." + aliEndpoint + "/" + objectKey;
}

export default {
    setUserStsData: setUserStsData,
    uploadFile : uploadFile
};