<template>
  <v-dialog :dark="!light" v-model="showPopup" persistent max-width="460px">
    <v-card :color="light ? 'dWhite' : 'dDarkBg'" class="pa-0 text-xs-left">
      <v-card-title class="title font-weight-regular grey--text">
        {{ title }}
        <v-spacer></v-spacer>
        <button v-if="isAllowCancel" @click="$emit('input', false)" :autotest="autotest.close">
          <v-icon small color="grey">close</v-icon>
        </button>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h3 class="font-weight-regular pb-3" v-html="content"></h3>
      </v-card-text>
      <v-card-actions class="px-3 pb-4 d-flex jusitfy-space-between">
        <div>
          <v-checkbox
            v-if="isAllowDontShowMsgAgain"
            v-model="dontShowMsgAgain"
            :label="$t('alert.dontShowThisMsgAgain')"
          />
        </div>
        <div class="text-right">
          <v-btn
            v-if="isAllowCancel"
            round
            outline
            class="caption mr-2 text-capitalize"
            :color="light ? 'grey' : 'white'"
            @click="$emit('input', false)"
            :autotest="autotest.cancel"
            :analytics="analytics.cancel"
            >{{ cancelBtnName }}</v-btn
          >
          <v-btn
            v-if="actionBtnName"
            round
            depressed
            class="caption white--text text-capitalize"
            :color="redActionBtn ? '#E03535' : '#4689f4'"
            @click="onActionClick"
            :autotest="autotest.action"
            :analytics="analytics.action"
            >{{ actionBtnName }}</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
    <div v-if="isAllowCancel" class="click-to-close" @click="$emit('input', false)"></div>
  </v-dialog>
</template>
<script>
export default {
  name: "DConfirmationPopup",
  props: {
    isAllowCancel: {
      type: Boolean,
      default: true,
    },
    isAllowDontShowMsgAgain: {
      type: Boolean,
      default: false,
    },
    value: Boolean, //for v-model value
    title: String,
    content: String,
    light: Boolean,
    cancelBtnName: String,
    actionBtnName: String,
    redActionBtn: Boolean,
    workflowId: String,
    autotest: {
      type: Object,
      default: () => ({
        close: "popup-close-btn",
        action: "popup-yes-btn",
        cancel: "popup-cancel-btn",
      }),
    },
    analytics: {
      type: Object,
      default: () => ({
        action: "",
        cancel: "",
      }),
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.showPopup = value;
      },
    },
    showPopup: {
      immediate: false,
      handler(showPopup) {
        this.$emit("input", showPopup);
        // reset data
        if (showPopup === false) {
          this.dontShowMsgAgain = false;
        }
      },
    },
  },
  mounted() {},
  data() {
    return {
      dontShowMsgAgain: false,
      showPopup: false,
    };
  },
  methods: {
    onActionClick() {
      const dontShowMsgAgain = this.dontShowMsgAgain;
      this.$emit("input", false);
      this.$emit("clickActionBtn", { dontShowMsgAgain });
    },
  },
};
</script>

<style scoped>
.click-to-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.text-right {
  text-align: right;
}
</style>
