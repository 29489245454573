<template >
  <div class="d-tooltip">
    <div 
      class="tooltip-div"   
      :class="{'multi-lines':textWrap, 'firest-row':firstRow}" 
    >
      <p 
        class="tooltip-text ma-0" 
        :class="{'text-no-wrap':!textWrap }" 
      >
        <slot name="content">
          <!-- fallback content -->
          {{text}}
        </slot>
      </p>
      <div class="arrow-down" :class="{'firest-row-arrow':firstRow}"></div>
    </div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: "DTooltip",
  props: {
    text: String,
    textWrap: {
      type: Boolean,
      default: false
    },
    firstRow:{
      type: Boolean,
      default: false
    }
  },
  mounted() {},
  mixins: [],
  data() {
    return {};
  },
  components: {},
  methods: {},
  computed: {},
};
</script>

<style scoped>
.d-tooltip {
  position: relative;
  height: 100%;
}
.tooltip-div {
  position: absolute;
  background-color: #2c2d32;
  box-shadow: 0 0 4px 0 rgba(62, 58, 58, 0.35);
  z-index: 9;
  opacity: 0;
  pointer-events: none;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
  top: -8px;
  padding: 6px 8px;
  transition: 0.3s;
}
.firest-row{
  transform: translateY(100%) translateX(-50%);
  top: unset;
  bottom: -8px;
}
.tooltip-text {
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}
.arrow-down {
  position: absolute;
  bottom: -6px;
  left: calc(50% - 6px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: #2c2d32 transparent transparent transparent;
}
.firest-row-arrow{
  bottom: unset;
  top: -6px;
  transform: rotate(180deg); 
}
.d-tooltip:hover .tooltip-div {
  opacity: 1;
  transition: 0.3s;
}
.multi-lines{
  width: 160px;
}
</style>
