import { getOrganizationSettings, updateOrganizationSettings } from "@/server/organization-settings-server.js";

export const namespaced = true;

export const state = {
  nameChangeEnabled: true,
  workflowCommentsEnabled: false,
  zoomIntegrationEnabled: false,
  scormDownloadEnabled: false,
  ulIntegrationEnabled: false,
  publicSharingEnabled: true,
  hasEmployeeIdLogin: false,
  hasSharedAccountLogin: false,
};

export const getters = {
  getter_name_change_enabled(state) {
    return state.nameChangeEnabled;
  },
  getter_workflow_comments_enabled(state) {
    return state.workflowCommentsEnabled;
  },
  getter_scorm_download_enabled(state) {
    return state.scormDownloadEnabled;
  },
  getter_zoom_integration_enabled(state) {
    return state.zoomIntegrationEnabled;
  },
  getter_ul_integration_enabled(state) {
    return state.ulIntegrationEnabled;
  },
  getter_public_sharing_enabled(state) {
    return state.publicSharingEnabled;
  },
  getter_has_employee_id_login(state) {
    return state.hasEmployeeIdLogin;
  },
  getter_shared_account_enabled(state) {
    return state.hasSharedAccountLogin && state.hasEmployeeIdLogin;
  },
};

export const mutations = {
  SET_NAME_CHANGE_SETTING(state, enabled) {
    state.nameChangeEnabled = enabled;
  },
  SET_WORKFLOW_COMMENTS_SETTING(state, enabled) {
    state.workflowCommentsEnabled = enabled;
  },
  SET_ZOOM_INTEGRATION_SETTING(state, enabled) {
    state.zoomIntegrationEnabled = enabled;
  },
  SET_SCORM_DOWNLOAD_SETTING(state, enabled) {
    state.scormDownloadEnabled = enabled;
  },
  SET_UL_INTEGRATION_SETTING(state, enabled) {
    state.ulIntegrationEnabled = enabled;
  },
  SET_PUBLIC_SHARING_SETTING(state, enabled) {
    state.publicSharingEnabled = enabled;
  },
  SET_HAS_EMPLOYEE_ID_LOGIN(state, enabled) {
    state.hasEmployeeIdLogin = enabled;
  },
  SET_HAS_SHARED_ACCOUNT_LOGIN(state, enabled) {
    state.hasSharedAccountLogin = enabled;
  },
};

export const actions = {
  async getOrgPermissionsSettings({ commit }, organization) {
    const settings = await getOrganizationSettings(organization);
    const { userPermissions, customizedPermissions, customFeatures } = settings;

    // User permissions settings.
    // Set `nameChangeEnabled` default to `true`.
    const changeNameEnabled = userPermissions?.allowNameChange ?? true;
    commit("SET_NAME_CHANGE_SETTING", changeNameEnabled);

    // Customized permissions settings.
    const workflowCommentsEnabled = customizedPermissions?.enableComments ?? false;
    commit("SET_WORKFLOW_COMMENTS_SETTING", workflowCommentsEnabled);

    const hasSharedAccountLogin = settings.sharedAccountLogin ?? false;
    commit("SET_HAS_SHARED_ACCOUNT_LOGIN", hasSharedAccountLogin);

    const hasEmployeeIdLogin = settings.employeeIdLogin ?? false;
    commit("SET_HAS_EMPLOYEE_ID_LOGIN", hasEmployeeIdLogin);

    // Custom features settings.
    const zoomIntegrationEnabled = customFeatures?.enableZoomIntegration ?? false;
    commit("SET_ZOOM_INTEGRATION_SETTING", zoomIntegrationEnabled);

    const scormDownloadEnabled = customFeatures?.enableSCORM ?? false;
    commit("SET_SCORM_DOWNLOAD_SETTING", scormDownloadEnabled);

    const ulIntegrationEnabled = customFeatures?.enableUlIntegration ?? false;
    commit("SET_UL_INTEGRATION_SETTING", ulIntegrationEnabled);

    // Set `publicSharingEnabled` default to `true`.
    const publicSharingEnabled = customFeatures?.publicSharing ?? true;
    commit("SET_PUBLIC_SHARING_SETTING", publicSharingEnabled);
  },
  async updateNameChangeSettings({ commit }, { organization, updateData }) {
    await updateOrganizationSettings({ organization, updateData });
    commit("SET_NAME_CHANGE_SETTING", !state.nameChangeEnabled);
  },
  async updateWorkflowCommentsSettings({ commit }, { organization, updateData }) {
    await updateOrganizationSettings({ organization, updateData });
    commit("SET_WORKFLOW_COMMENTS_SETTING", !state.workflowCommentsEnabled);
  },
  setHasEmployeeIdLogin({ commit }, enabled = false) {
    commit("SET_HAS_EMPLOYEE_ID_LOGIN", enabled);
  },
};
