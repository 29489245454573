import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

const videoServer = axios.create({
  baseURL:
    environment.backendType === "firebase"
      ? environment.service[environment.env].video
      : environment.service[environment.env]["china"].video,
});

videoServer.interceptors.request.use(
  async config => {
    config.headers["x-user-token"] = await getIdToken();
    return config;
  },
  error => Promise.resolve(error)
);

const apiWrapper = apiWrapperGenerator(videoServer);

export const createConversion = payload => apiWrapper("post", "/v1/conversions", payload);

export async function getIdToken() {
  return authChoice().currentUser.getIdToken();
}

export async function postStepEraseVideo({ workflowId, stepId, startTime, endTime }) {
  try {
    const { data } = await videoServer.post(`/v1/conversions`, {
      workflowId,
      stepId,
      startTime,
      endTime,
      type: "erase-step",
    });
    return { ...data, ok: data.success };
  } catch (error) {
    return { ok: false, error, message: error.message };
  }
}

export async function postStepTrimVideo({ workflowId, stepId, startTime, endTime }) {
  try {
    const { data } = await videoServer.post(`/v1/conversions`, {
      workflowId,
      stepId,
      startTime,
      endTime,
      type: "trim-step",
    });
    return { ...data, ok: data.success };
  } catch (error) {
    return { ok: false, error, message: error.message };
  }
}

export default videoServer;
