function insertScript({ apiHost, siteId, containerId }, _paq) {
  const t = new Date().getTime();
  insert(`${apiHost}js/${containerId}.js?t=${t}`);
}
function insert(src) {
  const d = document;
  const g = d.createElement("script");
  const s = d.getElementsByTagName("script")[0];
  g.async = true;
  g.src = src;
  s.parentNode.insertBefore(g, s);
}
const Matomo = {
  initialize(payload) {
    const _paq = (window._paq = window._paq || []);
    const _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    insertScript(payload, _paq);
  },
  setUserUid(uid) {
    try {
      _paq.push(["setUserId", uid]);
      _mtm.push({ uid });
    } catch (error) {
      console.error(error);
    }
  },
  setTrack(payload) {
    try {
      const { category, action, name, params } = payload;
      if (params) {
        return _paq.push(["trackEvent", category, action, name || "", "", params]);
      }
      _paq.push(["trackEvent", category, action, name || ""]);
    } catch (error) {
      console.error(error);
    }
  },
};
export default Matomo;
