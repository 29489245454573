<template>
  <v-dialog v-model="showPopup" width="466px">
    <v-card color="dWhite" class="popup-box">
      <button class="close-btn" @click="$emit('input', false)" :autotest="autotest.close">
        <icon-base color="#8D909F" width="20" height="20">
          <d-icon-close-light />
        </icon-base>
      </button>

      <div class="content-div">
        <slot name="icon" />
        <div>
          <div class="title-text">
            <slot name="title">
              <!-- fallback content -->
              {{title}}
            </slot>
          </div>
          <div class="content-text">
            <slot name="content">
              <!-- fallback content -->
              {{content}}
            </slot>
          </div>
        </div>
      </div>
      <v-layout xs12>
        <v-flex xs6 mr-2>
          <v-btn
            outline
            depressed
            round
            block
            class="body-2 text-capitalize"
            color="#4689f4"
            @click="$emit('input', false)"
            :autotest="autotest.cancel"
          >{{cancelBtn}}</v-btn>
        </v-flex>
        <v-flex xs6 ml-2>
          <v-btn
            depressed
            round
            block
            class="body-2 text-capitalize white--text action-btn"
            :color="actionBtnColor"
            @click="$emit('click-action-btn'), $emit('input', false)"
            :autotest="autotest.action"
          >{{actionBtn}}</v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <div class="popup-close" @click="$emit('input', false)"></div>
  </v-dialog>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconCloseLight from "../icons/player/DIconCloseLight.vue";

export default {
  name: "DConfirmPopup",
  props: {
    value: Boolean, //for v-model value
    title: String,
    content: String,
    cancelBtn: String,
    actionBtn: String,
    actionBtnColor: {
      default: "#E03535",
      type: String,
    },
    autotest: {
      type: Object,
      default: () => ({
        close: "popup-close-btn",
        action: "popup-yes-btn",
        cancel: "popup-cancel-btn",
      }),
    },
  },
  components: {
    IconBase,
    DIconCloseLight,
  },
  watch: {
    value: function() {
      this.showPopup = this.value;
    },
    showPopup(showPopup) {
      this.$emit("input", showPopup);
    },
  },
  mounted() {},
  data() {
    return {
      showPopup: false,
    };
  },
};
</script>
<style scoped>
.close-btn {
  position: absolute;
  right: 28px;
  top: 28px;
}
.popup-box {
  padding: 48px;
}
.content-div {
  position: relative;
  width: 100%;
}
.title-text {
  color: #52545d;
  font-family: Roboto;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 4px;
}
.content-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  margin-bottom: 64px;
}
.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
</style>
