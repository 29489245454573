<template>
  <section class="user-invite-create">
    <v-card width="466" color="#FFFFFF" class="popup-box fullscreen-center">
      <button class="close-btn" @click="$emit('close-popup')">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="title-div">
        <d-icon-mapping-discard class="mb-2" />
        <div>
          <div class="popup-title mb-2">{{$t('admin.discardChanges')}}</div>
          <div class="comfirmation-text" v-html="$t('admin.discardChangesMessage')"></div>
          <v-card-actions class="btn-div">
            <v-layout xs12 mt-4>
              <v-flex xs6 mr-2>
                <v-btn
                  outline
                  depressed
                  round
                  block
                  class="body-2 no-text-transform"
                  color="#4689f4"
                  @click="$emit('close-popup')"
                >{{$t('all.no')}}</v-btn>
              </v-flex>
              <v-flex xs6 ml-2>
                <v-btn
                  depressed
                  round
                  block
                  class="body-2 white--text no-text-transform"
                  color="#4689F3"
                  @click="$emit('discard-changes')"
                >{{$t('admin.discardChangesBtn')}}</v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </div>
      </div>
    </v-card>

    <div class="popup-close" @click="$emit('close-popup')"></div>
  </section>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconMappingDiscard from "../icons/colored/DIconMappingDiscard.vue";
import DIconClose2 from "../icons/DIconClose2.vue";

export default {
  name: "DDiscardMappingChangesConfirmation",
  props: {},
  components: {
    IconBase,
    DIconMappingDiscard,
    DIconClose2,
  },
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.popup-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 42px 20px 0 20px;
}
.popup-title {
  color: #52545d;
  font-family: Roboto;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: 16px;
}
.comfirmation-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.no-text-transform {
  text-transform: none !important;
}
.btn-div {
  margin-top: 32px;
}
</style>