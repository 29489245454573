import Vue from "vue";
export const namespaced = true;
export const state = {
  teams: [],
  users: [],
};
const sortAlphabetically = (a, b) => {
  if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
    return -1;
  }
  return 1;
};
export const mutations = {
  SET_TEAMS(state, payload = []) {
    const teams = payload.sort(sortAlphabetically);
    state.teams = teams;
  },
  CLEAR_TEAMS(state) {
    state.teams = [];
  },
  REMOVE_TEAMS_BY_TEAM_ID(state, payload = { teamId }) {
    const index = state.teams.findIndex(team => team.teamId === payload.teamId);
    if (index === -1) {
      console.warn("Not found mapping team");
      return;
    }
    state.teams.splice(index, 1);
  },
  ADD_NEW_TEAM(state, payload = []) {
    state.teams.push(payload);
  },
  SET_USERS(state, payload = []) {
    const users = payload.sort(sortAlphabetically);
    state.users = users;
  },
  CLEAR_USERS(state) {
    state.users = []
  },
  UPDATE_USER_BY_INDEX(state, payload = { index, updateData: {} }) {
    if (payload.index === -1) {
      console.warn(`Not found mapping user index - ${payload.index}`);
      return;
    }
    const newData = { ...state.users[payload.index], ...payload.updateData }
    Vue.set(state.users, payload.index, newData);
  },
  ADD_NEW_USER(state, payload = []) {
    state.users.push(payload);
  },
  REMOVE_USER_BY_ID(state, payload = { id }) {
    const index = state.users.findIndex(user => user.id === payload.id);
    if (index === -1) {
      console.warn("Not found mapping user");
      return;
    }
    state.users.splice(index, 1);
  }
};

export const getters = {
  skills_users_and_teams: state => state.teams.concat(state.users),
  skills_total_users_nums: state => {
    const totalTeamsUsersNum = state.teams.reduce((acc, team) => {
      acc += team.totalUsers;
      return acc;
    }, 0);
    return totalTeamsUsersNum + state.users.length;
  }
};

export const actions = {
  resetSkillsUserSetting: ({ commit }) => {
    commit("CLEAR_TEAMS");
    commit("CLEAR_USERS");
  },
};
