<template>
  <div class="ControlsIframe">
    <div class="ControlsIframe__shadow--up"></div>
    <div class="ControlsIframe__shadow--down"></div>

    <!-- top title -->
    <div class="ControlsIframe__topBar">
      <div>
        {{ workflowTitle }}
      </div>
      <div class="ControlsIframe__topBar--stepTitle">{{ stepNum }}. {{ stepTitle }}</div>
    </div>

    <!-- center buttons -->
    <div class="ControlsIframe__center" @click.stop>
      <button class="ControlsIframe__center--btn" @click="$emit('play-back')">
        <icon-base class="ControlsIframe__center--btnRotate">
          <d-icon-player-fast-forward />
        </icon-base>
      </button>

      <button class="ControlsIframe__center--btn" @click="$emit('play-previous-step')">
        <icon-base class="ControlsIframe__center--btnRotate" viewBox="0 0 10 14">
          <d-icon-player-next />
        </icon-base>
      </button>
      <button class="ControlsIframe__center--playBtnLg" @click="onclickPlayOrPause">
        <icon-base color="white">
          <component :is="playing ? 'd-icon-player-pause-2' : 'd-icon-player-play-2'" />
        </icon-base>
      </button>

      <button class="ControlsIframe__center--btn" @click="$emit('play-next-step')">
        <icon-base viewBox="0 0 10 14">
          <d-icon-player-next />
        </icon-base>
      </button>
      <button class="ControlsIframe__center--btn" @click="$emit('play-forward')">
        <icon-base>
          <d-icon-player-fast-forward />
        </icon-base>
      </button>
    </div>

    <!-- bottom controls -->
    <div class="ControlsIframe__bottom" @click.stop>
      <div v-html="videoTime" class="ControlsIframe__bottom--timeText"></div>
      <div class="ControlsIframe__bottom--btns">
        <controls-repeat-button class="ControlsIframe__bottom--btn" size="medium" />
        <button class="ControlsIframe__bottom--btn" @click="$emit('click-mute')">
          <icon-base>
            <component :is="isMuted ? 'd-icon-player-mute' : 'd-icon-player-volume'" />
          </icon-base>
        </button>
        <v-tooltip top color="#2C2D32" z-index="101">
          <button slot="activator" class="ControlsIframe__bottom--btn" @click="$emit('change-fullscreen')">
            <icon-base>
              <d-icon-player-fullscreen />
            </icon-base>
          </button>
          <span> {{ $t("player.fullscreen") }} (F)</span>
        </v-tooltip>
      </div>
      <div class="ControlsIframe__bottom--timeWrapper">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconPlayerPlay2 from "@/components/icons/player/DIconPlayerPlay2.vue";
import DIconPlayerPause2 from "@/components/icons/player/DIconPlayerPause2.vue";
import DIconPlayerNext from "@/components/icons/player/DIconPlayerNext.vue";
import DIconPlayerFastForward from "@/components/icons/player/DIconPlayerFastForward.vue";
import DIconPlayerFullscreen from "@/components/icons/player/DIconPlayerFullscreen.vue";
import DIconPlayerVolume from "@/components/icons/player/DIconPlayerVolume.vue";
import DIconPlayerMute from "@/components/icons/player/DIconPlayerMute.vue";
import ControlsRepeatButton from "@/components/DWorkflowPlayer/controls/ControlsRepeatButton.vue";
import { getDisplayTitle } from "@/js/video-player/title-display.js";
import { getFormattedVideoDuration, getNumberWithZero } from "@/js/common/formatText.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ControlsIframe",
  props: {
    playing: Boolean,
    isMuted: Boolean,
    currentStep: Number,
    currentVideoTime: Number,
    subtitlesChoice: String,
  },
  components: {
    IconBase,
    DIconPlayerPlay2,
    DIconPlayerPause2,
    DIconPlayerNext,
    DIconPlayerFastForward,
    DIconPlayerFullscreen,
    DIconPlayerVolume,
    DIconPlayerMute,
    ControlsRepeatButton,
  },
  methods: {
    onclickPlayOrPause() {
      if (this.playing) {
        this.$emit("pause-video");
        return;
      }
      this.$emit("play-video");
    },
  },
  computed: {
    ...mapGetters("workflowPlayer", ["getters_steps"]),
    ...mapState("workflowPlayer", ["workflowData"]),
    videoTime() {
      const current = getFormattedVideoDuration({ videoDuration: this.currentVideoTime });
      const total = getFormattedVideoDuration({ videoDuration: this.getters_steps[this.currentStep].duration });
      return `${current} / ${total}`;
    },
    workflowTitle() {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      return getDisplayTitle(this.workflowData, displayLang);
    },
    stepNum() {
      return getNumberWithZero({ num: this.currentStep + 1 });
    },
    stepTitle() {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      const step = this.getters_steps[this.currentStep] || {};
      return getDisplayTitle(step, displayLang);
    },
  },
};
</script>

<style lang="scss" scoped>
.ControlsIframe {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &__shadow {
    &--up {
      position: absolute;
      top: 0;
      left: 0;
      height: 60%;
      width: 100%;
      background: linear-gradient(180deg, rgba(12, 12, 14, 1) 0%, rgba(12, 12, 14, 0) 80%);
    }
    &--down {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60%;
      width: 100%;
      background: linear-gradient(0deg, rgba(12, 12, 14, 1) 0%, rgba(12, 12, 14, 0) 80%);
    }
  }
  &__topBar {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 10px 16px;
    color: #8d909f;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    text-align: left;
    &--stepTitle {
      color: #ffffff;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__center {
    position: relative;
    display: flex;
    align-items: center;
    justify-items: center;
    &--btnRotate {
      transform: rotateY(180deg);
    }
    &--btn {
      pointer-events: auto;
      color: #ffffff;
      margin: 0 2.5vw;
      svg {
        height: 30px;
        width: 30px;
      }
    }
    &--playBtnLg {
      pointer-events: auto;
      margin: 0 2.5vw;
      svg {
        height: 48px;
        width: 48px;
      }
    }
  }
  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    &--timeText {
      position: absolute;
      left: 16px;
      bottom: 34px;
      color: #8d909f;
      font-size: 12px;
      line-height: 14px;
    }
    &--btns {
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px 16px 0 0;
    }
    &--btn {
      margin: 0 0 0 24px;
      color: #ffffff;
      pointer-events: auto;
      svg {
        height: 24px;
        width: 24px;
      }
    }
    &--timeWrapper {
      position: absolute;
      width: 100%;
      height: 22px;
      bottom: 4px;
      left: 0;
      pointer-events: auto;
    }
  }
}
</style>