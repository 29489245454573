<template>
  <div class="admin-organization">
    <div class="workspaceMain__title">
      <d-workspace-group-breadcrumb
        :workspaceGroupName="workspaceGroup.name"
        :workspaceName="workspaceDataset.currentWorkspace.name"
        :toPath="redirectToWorkspaceGroup"
        organizationPath="/admin/main"
        :isShowOrganization="isShowOrganization"
        :isOrganizationClickable="isShowOrganization"
        :workspaceGroupPath="redirectToWorkspaceGroup"
        isWorkspaceGroupClickable
        size="small"
        mode="light"
      />
      <span class="workspaceMain__workspace">{{ workspaceDataset.currentWorkspace.name }}</span>
      <div class="workspaceMain__tabs">
        <d-tabs v-model="currentTab" autoWidth :tabNames="tabNames" light height="48" :autotest="autotestTabs"></d-tabs>
      </div>
    </div>

    <admin-workspace-users
      v-if="showPart === 'users'"
      :key="workspaceId"
      :drawerOpenPopupStyle="drawerOpenPopupStyle"
      :workspaceId="workspaceId"
      :workspaceDictionary="workspaceDataset.dictionary"
      :currentWorkspace="workspaceDataset.currentWorkspace"
    ></admin-workspace-users>

    <admin-workspace-team-main
      v-else-if="showPart === 'teams'"
      :drawerOpenPopupStyle="drawerOpenPopupStyle"
      :workspaceId="workspaceId"
      :workspaceDictionary="workspaceDataset.dictionary"
      :currentWorkspace="workspaceDataset.currentWorkspace"
    >
    </admin-workspace-team-main>
  </div>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import DTabs from "@/components/ui_components/DTabs.vue";
import DWorkspaceGroupBreadcrumb from "@/components/ui_components/DWorkspaceGroupBreadcrumb.vue";
import AdminWorkspaceUsers from "@/components/DAdmin/workspaceGroup/AdminWorkspaceUsers.vue";
import AdminWorkspaceTeamMain from "@/components/DAdmin/workspaceGroup/AdminWorkspaceTeamMain.vue";
import { mapGetters } from "vuex";

export default {
  name: "AdminWorkspaceMain",
  components: {
    DTabs,
    DWorkspaceGroupBreadcrumb,
    AdminWorkspaceUsers,
    AdminWorkspaceTeamMain,
  },
  mixins: [MixinDB],
  props: {
    drawerOpenPopupStyle: Object,
  },
  data() {
    return {
      workspaces: [],
      currentTab: 0,
      showPart: "users",
      tabNames: [this.$t("all.users"), this.$t("all.teams")],
      autotestTabs: ["users-tab", "teams-tab"],
    };
  },
  computed: {
    ...mapGetters("workspaceGroups", ["getter_get_admin_workspace_group_by_workspace_id"]),
    workspaceId() {
      return this.$route.params.workspaceId;
    },
    workspaceGroup() {
      return this.getter_get_admin_workspace_group_by_workspace_id(this.workspaceId) || {};
    },
    workspaceDataset() {
      const dictionary = {};
      let currentWorkspace = {};
      this.workspaces.forEach((workspace) => {
        this.workspaceId === workspace.id ? (currentWorkspace = workspace) : null;
        dictionary[workspace.id] = workspace.name;
      });
      return { dictionary, currentWorkspace };
    },
    redirectToWorkspaceGroup() {
      return `/admin/workspaceGroup/${this.workspaceGroup.id}/workspaces`;
    },
    isShowOrganization() {
      return this.$role === "admin-organization" || this.$role === "admin-super";
    },
  },
  mounted() {
    this.setCurrentTab();
    this.getWorkspaces();
  },
  methods: {
    setCurrentTab() {
      const { tab } = this.$route.params;
      this.currentTab = tab === "teams" ? 1 : 0;
    },
    async getWorkspaces() {
      this.workspaces = await this.getDocumentByFieldValue("groups", "organization", this.$organization);
    },
  },
  watch: {
    currentTab: function () {
      if (Number(this.currentTab) === 0) {
        this.showPart = "users";
        return this.$router.push({ name: "AdminWorkspaceUsers", params: { tab: "users" } });
      }

      this.showPart = "teams";
      this.$router.push({ name: "AdminWorkspaceTeams", params: { tab: "teams" } });
    },
    $route: function (to, from) {
      // When the user clicks on the side menu's Workspace, we need to update the currentTab.
      if (from.params.tab === this.showPart) {
        this.setCurrentTab();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.workspaceMain {
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;
  &__title {
    height: 214px;
    padding: 44px 28px 0 28px;
    text-align: left;
  }
  &__workspace {
    display: inline-block;
    margin-top: 30px;
    color: #3d3f45;
    font-size: 24px;
    font-weight: 600;
  }
  &__tabs {
    margin-top: 30px;
  }
}
.admin-organization {
  position: fixed;
  height: calc(100% - 70px);
  width: 100%;
  padding: 0;
  background-color: #fbfbfb;
}
.top-tabs {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 28px;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  text-align: left;
  min-width: 600px;
}
.tab-div {
  position: fixed;
  height: calc(100% - 134px);
  width: 100%;
}
.tab-title-row {
  position: relative;
  height: 90px;
  width: 100%;
  display: flex;
  padding: 30px 28px 0 28px;
}
.tab-title {
  height: 44px;
  width: 145px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 44px;
}
.search-div {
  height: 44px;
  width: auto;
  display: flex;
  padding-top: 8px;
}
.search-icon {
  height: 32px;
  padding: 4px;
  margin-right: 4px;
}
.serach-form {
  width: 0px;
  transition: 0.3s;
  overflow: hidden;
  padding: 0 16px 0 0;
}
.show-search-input {
  width: 180px;
  transition: 0.3s;
  padding: 0 24px 0 0;
}
.serach-input {
  margin: 0;
  padding: 0;
}

.create-btn {
  min-width: 140px;
  text-transform: none !important;
}

.avatar-td {
  width: 32px;
}
.new-btns-td {
  text-align: left;
  min-width: 172px;
  padding: 0 24px;
  height: 100%;
  position: relative;
}
.new-btns-td-div {
  display: flex;
}
.btns-td {
  min-width: 104px;
  display: flex;
}
.avatar-placeholder {
  height: 32px;
  width: 32px;
  background-color: #8d909f;
  color: white;
  text-align: center;
  font-size: 12px;
  padding-top: 7px;
  border-radius: 16px;
}
.view-button {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-top: 13px;
  margin-right: 20px;
  margin-left: 20px;
  color: #78767a;
}
.tooltip-btn {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-top: 14px;
}
.view-button-new {
  position: relative;
  height: 22px;
  width: 22px;
  color: #78767a;
  margin-right: 20px;
}
.hide-view-btn {
  opacity: 0.3;
  opacity: 0;
  pointer-events: none;
}
.tooltip-btn-new {
  position: relative;
  height: 22px;
  width: 22px;
  margin-right: 20px;
}
.cancel-invite-btn-new {
  position: relative;
  height: 18px;
  width: 18px;
}
.cancel-invite-btn {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-top: 14px;
  padding: 2px;
}
.tooltip-btn-btn {
  color: #78767a;
  opacity: 0.8;
}
.table-editor-btn {
  padding-top: 8px;
  color: #78767a;
  margin-right: 8px;
}
.table-delete-btn {
  padding-top: 8px;
  color: #78767a;
}
.table-delete-btn:hover {
  opacity: 1;
}
.btn-hover:hover {
  color: #2d2e32;
  cursor: pointer;
}
.group-name-hover:hover {
  color: #2d2e32;
  cursor: pointer;
  font-weight: 500;
}
.admin-setting-div {
  position: relative;
  height: calc(100% - 64px);
  width: 100%;
}
/* //// d-data-table ///// */
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.tab-title-new {
  height: 44px;
  width: 145px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 44px;
}
.table-div {
  position: fixed;
  top: 294px;
  left: 0;
  width: 100%;
  height: calc(100% - 374px);
  padding: 0 28px;
}
.table-search-row {
  position: relative;
  width: 100%;
  padding: 0 28px;
}
.d-data-table {
  position: relative;
  height: 100%;
  width: 100%;
}
.filter-div {
  position: relative;
}
.avatar-div {
  position: relative;
  display: inline-block;
  height: 32px;
  width: 32px;
}
.org-admin-icon {
  position: absolute;
  right: -10px;
  bottom: -4px;
  height: 20px;
  width: 20px;
  background-color: #ffbf18;
  border-radius: 10px;
  padding: 3px;
}
.shared-icon {
  background-color: #a9a9a9;
}
.table-wrap {
  position: fixed;
  top: 224px;
  left: 0;
  width: 100%;
  height: calc(100% - 232px);
  padding: 0 28px;
}
.table-wrap:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.private-btn {
  display: inline-block;
  height: 32px;
  min-width: 100px;
  padding: 6px 8px 0 8px;
  border: 1px solid #e7e7e7;
  border-radius: 16px;
  background-color: #ffffff;
  color: #3f3f3f;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
}
.private-btn-dark {
  border: 1px solid #e7e7e7;
  border-radius: 19px;
  background-color: #52545d;
  color: #ffffff;
}
.private-icon {
  margin: 0 2px -2px 4px;
}
@media screen and (max-width: 959px) {
  .top-tabs {
    padding: 0 20px;
  }
  .tab-title-row {
    padding: 30px 20px 0 20px;
  }
  .table-div {
    padding: 0 20px;
  }
  .table-search-row {
    padding: 0 20px;
  }
  .table-wrap {
    padding: 0 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1903px) {
  .top-tabs {
    padding: 0 28px;
  }
  .tab-title-row {
    padding: 30px 28px 0 28px;
  }
}
</style>
