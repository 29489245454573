import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";

const stepServer = axios.create({
  baseURL:
    environment.backendType === "firebase"
      ? environment.service[environment.env].deephowApiServer
      : environment.service[environment.env]["china"].deephowApiServer,
});

stepServer.interceptors.request.use(
  async (config) => {
    config.headers[
      "x-user-token"
    ] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

export async function postStepResetVideo({ workflow_id, step_id }) {
  try {
    const {
      data,
    } = await stepServer.post(
      `/v1/workflows/${workflow_id}/steps/${step_id}/reset`,
      { workflow_id, step_id }
    );
    return { ...data, ok: data.success };
  } catch (error) {
    console.log(error);
    return { ok: false, error, message: error.message };
  }
}

export default stepServer;
