<template lang="html">

  <section class="mixin-skills">
  </section>

</template>

<script lang="js">
import MixinDB from "./MixinDB.vue";
  export default  {
    name: 'mixin-skills',
    props: [],
    mixins: [MixinDB],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      getAllPlaylists(lists){
        const self = this;
        return new Promise(function(resolve, reject){
          const promises = []
          lists.forEach(id => {
            promises.push(self.getPlaylist(id).catch(err=>{}));
          });
          Promise.all(promises).then(all=>{
            resolve(all);
          }).catch(err=>{
            reject();
          })
        })
      },
      getPlaylist(id){
        const self = this;
        return new Promise(function(resolve, reject){
          self.getDocument("playlists", id).then(doc=>{
            resolve(doc);
          }).catch(err=>{
            reject();
          })
        })
      },      
      fetchUsers(playlistId) {
        const self = this;
        return new Promise(function (resolve, reject) {
          self
            .getDocumentByQuery("userSkills", [
              {
                field: "organization",
                comparison: "==",
                value: self.$organization,
              },
              {
                field: "playlistId",
                comparison: "==",
                value: playlistId,
              },
            ])
            .then((data) => {
              resolve(data);
            });
        });
      },
      fetchTeams(playlistId) {
        const self = this;
        return new Promise(function (resolve, reject) {
          self
            .getDocumentByQuery("teamSkills", [
              {
                field: "organization",
                comparison: "==",
                value: self.$organization,
              },
              {
                field: "playlistId",
                comparison: "==",
                value: playlistId,
              },
            ])
            .then((data) => {
              resolve(data);
            });
        });
      },             
      getUserSkillTracking(user, playlist){
        const self = this;
        return new Promise(function(resolve, reject){
          const promises = [];
          playlist.list.forEach(workflow => {
            promises.push(self.getUserWorkflowTracking(user, workflow))
          });
          Promise.all(promises).then(all=>{
            resolve(all)
          }).catch(err=>{
            reject(err);
          })          
        })        
      },
      getUserWorkflowTracking(user, workflow){
        const self = this;
        return new Promise(function(resolve, reject){
          self
            .getDocumentByQuery("tracking", [
              {
                field: "uid",
                comparison: "==",
                value: user.uid,
              },
              {
                field: "workflowId",
                comparison: "==",
                value: workflow.id,
              },
              {
                field: "organization",
                comparison: "==",
                value: self.$organization,
              },
            ])
            .then((data) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        })
      },
      getTeamSkills() {        
        const options = {
          organization: this.$organization,
          teams: this.$userProfile.teams,
        };
        
        this.$store.dispatch("getTeamSkills", options);
      },
      getUserSkills() {        
        const options = {
          organization: this.$organization,
          uid: this.$user.uid,
        };
        this.$store.dispatch("getUserSkills", options);
      },

    },
    computed: {

    }
}
</script>

<style scoped>
  .mixin-skills {

  }
</style>
