import AdminOrganization from "@/components/AdminOrganization";
import AdminGroup from "@/components/AdminGroup";
import AdminMain from "@/components/AdminMain";

const AdminRoute = [
  {
    path: "/admingroup",
    name: "AdminGroup",
    component: AdminGroup,
    meta: { auth: true },
  },
  {
    path: "/adminorganization",
    name: "AdminOrganization",
    component: AdminOrganization,
    meta: { auth: true },
  },
  {
    path: "/admin/:tab",
    name: "AdminMain",
    component: () => import("@/components/AdminMain"),
    meta: { auth: true },
  },
  {
    path: "/admin/workspaceGroup/:workspaceGroupId",
    name: "AdminWorkspaceGroup",
    component: () => import("@/components/AdminMain"),
    meta: { auth: true },
  },
  {
    path: "/admin/workspaceGroup/:workspaceGroupId/:tab",
    name: "AdminWorkspaceGroupWorkspaces",
    component: () => import("@/components/AdminMain"),
    meta: { auth: true },
  },
  // TODO: enable this when we enter phase 2.

  // {
  //   path: "/admin/workspaceGroup/:workspaceGroupId/:tab",
  //   name: "AdminWorkspaceGroupAdmins",
  //   component: () => import("@/components/AdminMain"),
  //   meta: { auth: true },
  // },
  {
    path: "/admin/workspace/:workspaceId/:tab",
    name: "AdminWorkspaceUsers",
    component: () => import("@/components/AdminMain"),
    meta: { auth: true },
  },
  {
    path: "/admin/workspace/:workspaceId/:tab",
    name: "AdminWorkspaceTeams",
    component: () => import("@/components/AdminMain"),
    meta: { auth: true },
  },
  {
    path: "/admin/workspaces",
    name: "AdminWorkspaces",
    component: () => import("@/components/AdminMain"),
    meta: { auth: true },
  },
  {
    path: "/admin/teams",
    name: "AdminTeams",
    component: () => import("@/components/AdminMain"),
    meta: { auth: true },
  },
  {
    path: "/admin/allUsers",
    name: "AdminAllUsers",
    component: () => import("@/components/AdminMain"),
    meta: { auth: true },
  },
];

export default AdminRoute;
