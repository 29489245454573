<template>
  <div class="AttachmentLinkCard" @click="openNewTag">
    <div v-if="title" class="AttachmentLinkCard__title">{{ title }}</div>
    <div>{{ link }}</div>
  </div>
</template>

<script>
export default {
  name: "AttachmentLinkCard",
  props: {
    link: String,
    title: String,
  },
  methods: {
    openNewTag() {
      const win = window.open(this.link, "_blank");
      win.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.AttachmentLinkCard {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #28292e;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #919191;
  word-wrap: normal;
  word-break: break-all;
  text-align: left;
  cursor: pointer;
  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin-bottom: 4px;
  }
}
</style>