<template>
  <div class="d-icon-processing-bar-flex">
    <svg
      version="1.1"
      :width="width"
      :height="height"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0"
        y="0"
        width="32%"
        height="100%"
        ry="50%"
        :fill="light ? lightGrey : darkGrey"
      >
        <animate
          attributeName="fill"
          dur="3s"
          :values="light ? lightAnimation : darkAnimation"
          repeatCount="indefinite"
          begin="0"
        ></animate>
      </rect>
      <rect
        x="34%"
        y="0"
        width="32%"
        height="100%"
        ry="50%"
        :fill="light ? lightGrey : darkGrey"
      >
        <animate
          attributeName="fill"
          dur="3s"
          :values="light ? lightAnimation : darkAnimation"
          repeatCount="indefinite"
          begin="0.5"
        ></animate>
      </rect>
      <rect
        x="68%"
        y="0"
        width="32%"
        height="100%"
        ry="50%"
        :fill="light ? lightGrey : darkGrey"
      >
        <animate
          attributeName="fill"
          dur="3s"
          :values="light ? lightAnimation : darkAnimation"
          repeatCount="indefinite"
          begin="1"
        ></animate>
      </rect>
    </svg>
  </div>
</template>

<script>
export default {
  name: "DIconProcessingBarFlex",
  props: {
    height: {
      type: String,
      default: "8px",
    },
    width: {
      type: String,
      default: "240px",
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      darkGrey: "#17181a",
      lightGrey: "#b6b6b6",
      darkAnimation: "#17181a;#17181a;#17181a;#4689f3;#4689f3;#4689f3",
      lightAnimation: "#b6b6b6;#b6b6b6;#b6b6b6;#4689f3;#4689f3;#4689f3",
    };
  },
};
</script>
<style scoped>
.d-icon-processing-bar-flex {
  position: relative;
}
</style>