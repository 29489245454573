export function covertDbTimestampToString({ timestampSeconds }) {
  return {
    timestamp: timestampSeconds,
    toDateString: () => {
      const date = timestampSeconds ? new Date(timestampSeconds * 1000) : null;
      if (!date) {
        return "";
      }
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
  };
}
