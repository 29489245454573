import environment from "../../../env";
import firebase from "firebase";
import Analytics from "@/js/analytics/analytics";
import { postFetchMultipleAnnotations, postCreateOrUpdateAnnotation } from "../../server/annotation-server";
import { ANNOTATION_TYPE } from "@/constants/heapEvents.js";

export const namespaced = true;

const getGoogleFirestore = function () {
  return environment.backendType !== "ali" ? firebase.firestore() : null;
};

export const state = {
  annotations: [],
};

export const mutations = {
  MUTATE_ANNOTATIONS(state, annotations = []) {
    state.annotations = annotations.map(({ id, stepId, elements }) => ({
      id,
      stepId,
      elements,
    }));
  },
  ADD_ANNOTATIONS(state, { annotation, stepId }) {
    const index = state.annotations.findIndex((annotation) => annotation.stepId === stepId);
    if (index !== -1) {
      state.annotations[index].elements.push(annotation);

      const type = ANNOTATION_TYPE[annotation.type];
      Analytics.setTrack(`Web - Builder - Click - Add Annotation ${type}`, null, {
        category: "BuilderMain",
        action: "Edit Video",
        name: `Add Annotation ${type}`,
      });
    }
  },
  DELETE_ANNOTATION(state, { deletedIndex, annotationId, stepId }) {
    const targetIndex = state.annotations.findIndex((annotation) => annotation.stepId === stepId);
    const index = deletedIndex
      ? deletedIndex
      : state.annotations[targetIndex].elements.findIndex(({ id }) => id === annotationId);
    if (index !== -1 && targetIndex !== -1) {
      state.annotations[targetIndex].elements.splice(index, 1);
    }
  },
  DELETE_ANNOTATION_BY_STEP_ID(state, stepId) {
    if (stepId == null) {
      console.error(`This delete id (${stepId}) is invalid`);
      return;
    }
    state.annotations = state.annotations.filter((annotation) => annotation.stepId !== stepId);
  },
  MUTATE_ANNOTATION_PROPERTY(state, { stepId, key, value, id }) {
    const index = state.annotations.findIndex((annotation) => annotation.stepId === stepId);
    if (index !== -1) {
      const targetAnnotation = state.annotations[index];
      const elementIndex = targetAnnotation.elements.findIndex((element) => element.id === id);
      if (elementIndex !== -1) {
        targetAnnotation.elements[elementIndex][key] = value;
      }
    }
  },
};

export const getters = {
  getters_get_annotation_by_id: (state) => (stepId) => {
    return state.annotations.find((annotation) => annotation.stepId === stepId);
  },
};

export const actions = {
  storeMultipleAnnotation({ commit }, { annotations, stepIds }) {
    commit("MUTATE_ANNOTATIONS", formatAnnotation(annotations, stepIds));
  },
  async fetchMultipleAnnotation({ commit }, stepIds) {
    const { data, ok, error } = await postFetchMultipleAnnotations(stepIds);
    if (ok) {
      commit("MUTATE_ANNOTATIONS", formatAnnotation(data.items, stepIds));
    } else {
      console.error(error);
    }
  },
  async updateAndFetchAnnotation({ commit, state }) {
    state.annotations.forEach((annotation) => {
      annotation.elements.forEach((element) => {
        if (typeof element.endTime !== "number") element.endTime = +element.endTime;
        if (typeof element.startTime !== "number") element.startTime = +element.startTime;
      });
    });
    const { data, ok } = await postCreateOrUpdateAnnotation(state.annotations);
    if (ok) {
      commit("MUTATE_ANNOTATIONS", data.items);
    }
  },
  async addNewAnnotationByStepIds({ commit, state }, stepIds) {
    const { data, ok, error } = await postFetchMultipleAnnotations(stepIds);
    if (ok) {
      stepIds.forEach((stepId) => {
        let annotation = {
          stepId,
          elements: [],
        };
        const index = data.items.findIndex((item) => item.stepId === stepId);
        if (index !== -1) {
          const { id, stepId, elements } = data.items[index];
          annotation = { id, stepId, elements };
        }
        state.annotations.push(annotation);
      });
    } else {
      console.error(error);
    }
  },
  deleteAnnotationByStepId({ commit }, stepId) {
    commit("DELETE_ANNOTATION_BY_STEP_ID", stepId);
  },
};

const formatAnnotation = (items, stepIds) => {
  // TODO: old verison of get Annotations, will remove when new player is stable
  // stepIds.forEach((stepId) => {
  //   if (!items.find((item) => item.stepId === stepId)) {
  //     items.push({
  //       stepId,
  //       elements: [],
  //     });
  //   }
  // });
  // return items;
  stepIds.forEach((stepId) => {
    if (!items.find((item) => item.stepId === stepId)) {
      items.push({
        stepId,
        elements: [],
      });
    }
  });
  const indexById = new Map(stepIds.map((stepId, index) => [stepId, index]));
  items.sort((a, b) => indexById.get(a.stepId) - indexById.get(b.stepId));
  return items;
};
