module.exports = {
  ad: {
    highlights: "Destacados",
    highlightsIntro: "Aquí siempre encontrará una vista rápida de los lanzamientos de nuevas prestaciones, como esta. Además, Stephanie le recordará y ayudará a realizar un seguimiento de su capacitación de habilidades en curso.",
    recommendations: "Recomendaciones",
    recommendationsIntro: "Stephanie recomendará flujos de trabajo en función de sus actividades realizadas. ¡Aprenderá a cada interacción que tenga con ella!",
    favoritesIntro: "¿Alguna vez se preguntó qué increíble sería tener todos los flujos de trabajo que más necesita, justo allí esperándole? ¡Ahora hay un lugar especial para encontrarlos listos para seguir!",
    categoriesIntro: "Tantos flujos de trabajo, tan poco tiempo ... ¿No sería bueno simplemente hacer clic y ver las categorías relevantes para usted? ¡Ahora es posible! :)",
    loginMsgTitle: "Nueva actualización de funcionalidades!",
    loginMsg: "Ahora puede buscar fácilmente un espacio de trabajo. Además, eche un vistazo a las recomendaciones de contenido diseñadas para ayudarlo a utilizar mejor su cuenta de DeepHow."
  },
  all: {
    success: "Éxito",
    player: "Reproductor",
    editor: "Editor",
    admin: "Administración",
    analytics: "Análisis",
    skills: "Habilidades",
    skill: "Habilidad",
    unpublished: "No publicado",
    published: "Publicado",
    signOut: "Salir",
    step: "paso",
    steps: "pasos",
    numStep: "0 pasos | 1 paso | {count} pasos",
    transcription: "Transcripción",
    no: "No",
    yes: "Si",
    delete: "Eliminar",
    diagram: "diagrama",
    all: "Todos",
    discard: "descartar",
    save: "Guardar",
    next: "Siguiente",
    process: "Procesar",
    cancel: "Cancelar",
    close: "Cerrar",
    allUsers: "Todos los usuarios",
    users: "Usuarios",
    user: "usuario",
    numUsers: " usuarios",
    numUser: " usuario",
    countUsers: "0 usuario | 1 usuario | {count} Usuarios",
    workspaces: "Espacios de trabajo",
    workspace: "Espacio de trabajo",
    workspaceGroup: "Grupo de Espacios de Trabajo",
    workflows: "Flujos de trabajo",
    workflow: "Flujo de trabajo",
    organization: "Organización",
    business: "Negocio",
    create: "crear",
    name: "Nombre",
    namePerson: "Nombre",
    email: "Correo electrónico",
    employeeId: "ID de empleado",
    role: "rol",
    organizationAdmin: "Administrador de la organización",
    workspaceAdmin: "Administrador del espacio de trabajo",
    publisher: "Editor",
    viewer: "Espectador",
    organizationAdmins: "Administradores de la organización",
    workspaceAdmins: "Administradores de espacios de trabajo",
    publishers: "Editores",
    viewers: "Espectadores",
    add: "Agregar",
    attachments: "archivos adjuntos",
    private: "privado",
    public: "público",
    content: "Contenido",
    noRecordFound: "No se encontraron registros",
    loadingRecords: "Cargando registros ...",
    teams: "Equipos",
    loadedAllRecords: "Todos los registros cargados",
    language: "Idioma ",
    interfaceLanguage: "Idioma de interfaz",
    personalInfo: "Informacion Personal",
    privacyPolicy: "Política de privacidad",
    team: "Equipo",
    unselect: "Deseleccionar",
    select: "Seleccionar",
    applied: "Aplicado",
    noAccessToAdminTools: "Esta cuenta de usuario no tiene acceso a las herramientas de administración.",
    changePhoto: "Cambiar foto",
    removePhoto: "Remover foto",
    profilePhoto: "Foto de perfil",
    takeOrUpload: "Tomar o subir una foto",
    useCamera: "Usar la cámara",
    uploadPhoto: "Subir una foto",
    editPhoto: "Editar foto",
    retake: "Volver a tomar",
    takePhoto: "Tomar una foto",
    countWorkspaces: "Espacios de trabajo | Espacio de trabajo | Espacios de trabajo",
    whatsNew: "¿Qué hay de nuevo?",
    howToUse: "Cómo utilizar",
    defaultInterfaceLanguage: "Idioma de interfaz predeterminado",
    newVersionSnackbarText: "Hay nueva versión de DeepHow disponible.",
    refresh: "Actualizar",
    unpublishedList: "No publicados",
    publishedList: "Publicados",
    userAgreement: "Acuerdo del Usuario",
    numAttachments: "0 Adjunto | 1 Adjunto | {count} Archivos adjuntos",
    attachment: "Adjunto",
    knowledgeBase: "Base de conocimiento de DeepHow",
    changeName: "Cambiar nombre",
    changePassword: "Cambia la contraseña",
    openInApp: "Abrir en Deephow Navigator",
    userNewToDeepHow: "¿Es nuevo en Deephow?",
    downloadTheApp: "Descargar la aplicación",
    continueToUseBrowser: "Continúe para usar el navegador",
    contentManager: "Gestor de contenidos",
    gen2Forbidden: "Como usuario de Gen2 Light, no puede iniciar sesión en este momento.",
    confirm: "Confirmar",
    myWorkspaceGroups: "Mis grupos de espacios de trabajo",
    otherWorkspaceGroups: "Otros grupos de espacios de trabajo",
    searchResult: "Resultados de búsqueda",
    noResultsFound: "No se han encontrado resultados.",
    browseWorkspace: "Explorar espacio de trabajo...",
    version: "versión",
  },
  password: {
    createYourAccount: "Cree su cuenta",
    checkNameChoosePassword: "Revise su nombre y elija una contraseña",
    createAccount: "Crear cuenta",
    createNewPassword: "Crear nueva contraseña",
    selectNewPassword: "Seleccione una nueva contraseña para su cuenta",
    newPassword: "Nueva contraseña",
    confirmNewPassword: "Confirmar nueva contraseña",
    passwordMustMatch: "Contraseña debe coincidir con.",
    confirm: "Confirmar",
    passwordCreatedMsg: "Se creó su nueva contraseña, por favor, espere mientras lo redirigimos ...",
    goToDeepHowPlayer: "Ir al reproductor de DeepHow",
    failedResetPassword: "Error al restablecer la contraseña. Su cuenta ya fue creada o hay un problema de red.",
    alertSetPasswordFailed: "Error al establecer la contraseña. Su cuenta ya fue creada o hay un problema de red.",
    linkExpired: "Este enlace ha caducado",
    passwordExpired: "El enlace para restablecer su contraseña ha caducado.",
    inviteExpired: "Su invitación ha caducado.",
    resetPasswordMsg: "Haga clic en el botón de abajo para solicitar un correo electrónico para restablecer su contraseña.",
    // added 09/08/2022
    alertFailedResetPassword: "No se pudo restablecer la contraseña.",
    alertSuccessfullyResetPassword: "Contraseña restablecida con éxito, por favor espere mientras lo redirigimos para iniciar sesión nuevamente... <br><br> o haga clic en el botón a continuación.",
    newPasswordNotTheSame: "La nueva contraseña no debe ser la misma que la anterior.",
    alertCurrentPasswordIncorrect: "Contraseña actual incorrecta",
    alertTooManyAttempts: "Demasiados intentos para validar la contraseña actual. Reintente más tarde.",
  },
  analytics: {
    overview: "Resumen",
    engagement: "Engagement",
    activeUsers: "Usuarios activos",
    workflowsCreated: "Flujos de trabajo creados",
    workflowsPublished: "Flujos de trabajo publicados",
    workflowViews: "Vistas de flujo de trabajo",
    timeCreated: "Tiempo de creación",
    timePublished: "Tiempo publicado",
    timeViewed: "Tiempo visualización",
    searchWorkspace: "Buscar espacios de trabajo...",
    mostUsers: "Mayoría de usuarios",
    mostWorkflowsCreated: "Mayoría de flujos de trabajo creados",
    numberOfUsers: "Número de usuarios",
    organizationOverview: "Resumen de la organización",
    numWorkflowsIn: "Flujos de trabajo en",
    searchWorkflow: "Búsqueda de flujos de trabajo...",
    createdBy: "Creado por",
    usersIn: "Usuarios en",
    searchUser: "Búsqueda de usuarios...",
    watchTime: "Tiempo de Visualización",
    workflowsViewed: "Flujos de trabajo vistos",
    percentViewed: "% Vistos",
    dateViewed: "Fecha Visualización",
    watchWorkflow: "Ver el flujo de trabajo",
    backToUsers: "Volver a usuarios",
    backToWorkflows: "Volver a flujos de trabajo",
    likes: "Likes",
    avgTimeViewed: "Tiempo de visualización promedio",
    numberofViews: "Número de vistas",
    workspacesPerPage: "Espacios de trabajo por página",
    usersPerPage: "Usuarios por página",
    workflowsPerPage: "Flujos de trabajo por página",
    exportTable: "Exportar tabla",
    Top10Viewers: "Top espectadores",
    Top10Publishers: "Top editores",
    totalViews: "Vistas totales",
    msgNoDataYet: "Datos no disponibles",
    msgNoSearchResults: "No se encontraron registros coincidentes",
    hms: "(H:M:S)",
    viewHistory: "Ver historial",
    views: "Visualizaciones",
    totalViewingTime: "Tiempo de visualización total",
    usersViewedThisWorkflow: "Los usuarios vieron este flujo de trabajo.",
    date: "Fecha",
    // added 2022/07/11
    dashboards: "Tableros de Control",
    reactions: "Reacciones",
    userReaction: "Reacción de Usuario",
    viewAll: "Ver todos",
    favorites: "Favoritos",
  },
  admin: {
    status: "Estado",
    active: "Activo",
    inactive: "Inactivo",
    invited: "invitado",
    invitationExpired: "Invitación expirada",
    couldNotInvited: "No podría ser invitado",
    hintSearchUsers: "Buscar usuarios ...",
    hintSearchWorkspaces: "Buscar espacio de trabajo ...",
    hintSearchBusiness: "Buscar negocio ...",
    alertSuccessfullyInviteUser: "Invitó con éxito al usuario.",
    alertFailedInviteUser: "Error al invitar al usuario.",
    alertSuccessfullyDeletedUser: "Usuario borrado con éxito.",
    alertFailedDeleteUser: "Error al eliminar el usuario.",
    alertSuccessfullyCreatedWorkspace: "Espacio de trabajo creado con éxito.",
    alertSuccessfullyEditedWorkspace: "Espacio de trabajo editado con éxito.",
    alertSuccessfullyCreatedBusiness: "Negocio creado con éxito.",
    alertSuccessfullyEditedBusiness: "Negocio editado con éxito.",
    alertUserInvitationSuccessfullyResent: "Invitación reenviada con éxito al usuario.",
    alertFailedResendUserInvitation: "Error al reenviar la invitación al usuario.",
    alertUserInvitationSuccessfullyDelete: "Invitación eliminada con éxito.",
    alertFailedDeleteUserInvitation: "Error al eliminar la invitación.",
    resendInvitation: "Reenviar invitacíon",
    popupMsgResendInvitation: "¿Está seguro de que desea reenviar la invitación a",
    popupBtnSend: "Enviar",
    deleteInvitation: "Eliminar invitación",
    popupMsgDeleteInvitation: "¿Está seguro de que quiere eliminar la invitación a",
    addUser: "Agregar usuario",
    msgNoMembersYet: "Esta organización aún no tiene miembros.",
    msgNoRecordsAllUsers: "No se encontraron registros coincidentes en todos los usuarios.",
    createWorkspace: "Crear espacio de trabajo",
    msgNoWorkspacesYet: "Esta organización aún no tiene espacios de trabajo.",
    msgNoRecordsWorkspaces: "No hay registros correspondientes en espacios de trabajo.",
    msgNoRecordsWorkspacesGroupAdmin: "Esta cuenta aún no tiene espacios de trabajo.",
    createBusiness: "Crear negocio",
    msgNoBusinessYet: "Esta organización aún no tiene negocios.",
    msgNoRecordsBusiness: "No se encontraron registros coincidentes en los negocios.",
    usersIn: "Usuarios en ",
    tableMsgNoUsersYet: "Este espacio de trabajo aún no tiene miembros.",
    tableMsgNoRecordsThisWorkspace: "No se encontraron registros de coincidencia en este área de trabajo.",
    memberAt: "Miembro de",
    alerBusinessAlreadyInSystem: "Negocio ya existe en el sistema.",
    alerFailedAddBusiness: "Error al añadir negocio.",
    alertFailedEditBusiness: "Error al editar negocio.",
    alertWorkspaceAlreadyInSystem: "Este nombre de espacio de trabajo ya está en el sistema.",
    aletFailedCreateWorkspace: "Error al crear el espacio de trabajo.",
    alertFailedEditWorkspace: "Error al editar el espacio de trabajo.",
    resendAllInvites: "Reenviar todas las invitaciones",
    resendAllInvitesMsg: "Reenviará todas las invitaciones pendientes y vencidas. ¿Está seguro?",
    yesSendInvites: "Sí, envíar invitaciones",
    businessesPerPage: "Negocios por página",
    //add org user
    popupTitleAddOrgUser: "Añadir usuario administrador de la organización",
    invite: "Invitar",
    popupTitleNewUserInvited: "¡Nuevo usuario invitado!",
    popupMsgNewUserInvitedOrg: "Fue invitado. <br> Después de validar la cuenta, tendrá acceso a esta organización.",
    popupTitleExistingUserInvited: "Usuario existente agregado!",
    popupMsgExistingUserInvitedOrg: "Fue agregado a esta organización.",
    popupBtnAddAnotherUser: "Añadir otro usuario",
    alertFailedCheckPendingInvitations: "Error al verificar si el usuario tiene invitaciones pendientes.",
    alertFailedAddExistingUserAsOrganizationAdmin: "Error al agregar al usuario existente como administrador de la organización",
    alertAddExistingUserAsOrganizationAdmin: "Agregue al usuario existente como administrador de la organización.",
    alertUserAlreadyOrganizationAdmin: "Usuario ya en el sistema como administrador de la organización.",
    alertFailedCheckUserAlreadyInSystem: "Error al verificar si el usuario ya está en el sistema.",
    //Add user to workspace
    addUserToWorkspace: "Añadir usuario al espacio de trabajo",
    companyEmail: "E-mail de la empresa",
    employeeIdOnly: "Solamente ID de empleado",
    fullName: "Nombre completo",
    workspaceName: "Nombre del espacio de trabajo",
    businessName: "Nombre del Negocio",
    popupMsgNewUserInvited: "Fue invitado. <br> Después de validar la cuenta, tendrá acceso a este espacio de trabajo.",
    popupMsgExistingUserInvited: "Fue agregado a este espacio de trabajo.",
    popupTitleNewUserCreated: "Nuevo usuario creado!",
    popupTitleUserAlreadyinSystem: "Usuario ya en el sistema",
    alertUserIsAlreadyInWorkspace: "Este usuario ({info}) Ya existe en este espacio de trabajo.",
    alertPendingInvitation: "El usuario tiene una invitación pendiente. Por favor, espere a que el usuario acepte la invitación actual.",
    searchingforUsers: "Buscando usuarios...",
    noResultsFound: "No se han encontrado resultados.",
    // Change User Role
    popupTitleChangeUserRole: "Cambiar el rol del usuario",
    popupMsgChangeUser: "¿Está seguro de que desea cambiar el rol de <b>{name}</b> de <b>{oldRole}</b> a <b>{newRole}</b>?",
    popupBtnChange: "Si",
    alertUpdatedUserSettings: "Configuración de usuario actualizada con éxito.",
    // Remove User
    popupTitleRemoveUser: "Eliminar usuario del espacio de trabajo",
    popupMsgRemoveUserFromWorkspace: "¿Está seguro de que desea eliminar <b>{name}</b> del Espacio de trabajo?",
    popupBtnRemove: "Si, eliminar.",
    alertRemoveUser: "Usuario eliminado con éxito del espacio de trabajo.",
    // Delete User
    popupTitleDeleteUser: "Eliminar usuario del sistema",
    popupMsgDeleteUserFromAllWorkspaces: "Si lo hace, perderá todos los registros relacionados con este usuario <b>{name}</b> y se eliminará de todos los espacios de trabajo y roles asignados.",
    popupBtnDelete: "Si eliminar",
    tooltipEditUser: "Editar usuario",
    tooltipResendInvite: "Reenviar invitación",
    tooltipCancelInvite: "Cancelar Invitación",
    tooltipRemoveUser: "Eliminar usuario",
    // settings
    settings: "Ajustes",
    location: "localización",
    hireDate: "fecha de contratación",
    department: "Departamento",
    position: "posición",
    site: "sitio",
    userId: "ID de usuario",
    ssoMapping: "Mapeo de SSO",
    ssoMappingNoData: "Todavía no hay datos para el mapeo. <br /> Debe cargar el CSV a través del FTP, comuníquese con el área de soporte.",
    attributes: "Atributos",
    oneAttributeToMap: "Solo puede seleccionar un atributo para mapear a los usuarios a un espacio de trabajo",
    selectAttribute: "Seleccione el atributo",
    changeAttribute: "Cambiar atributo",
    discardChanges: "Descartar los cambios",
    applyChanges: "Aplicar cambios",
    applyChangesMsg: "Va a modificar los siguientes espacios de trabajo, ¿está seguro?",
    mappingValuesUpdated: "Valores de mapeo actualizados",
    mappingValuesUpdatedSubtitle: "Para actualizar el mapeo, debe aplicar los cambios. Puede hacerlo al terminar de editar todos los espacios de trabajo.",
    values: "Valores",
    clickPlusToAddValues: "Haga clic en el icono MAS para agregar valores",
    selectValues: "Seleccione valores",
    searchForValues: "Búsqueda de valores ...",
    assign: "Asignar",
    assignValues: "Asignar valores",
    addUsersBtn: "Si, agregar usuarios",
    YesChangeBtn: "Si",
    numMore: "{count} más",
    removeValues: "Eliminar los valores",
    remove: "Eliminar",
    changeAttributeMessage: "¿Está seguro de que desea cambiar el atributo? <br/> Perderá todo el mapeo actual para este atributo.",
    discardChangesMessage: "Va a perder todos los cambios que hizo desde su último guardado, ¿está seguro?",
    discardChangesBtn: "Si, descartar los cambios.",
    applyChangesBtn: "Si, aplicar cambios.",
    alertDiscardedSuccess: "Mapeo descartado con éxito.",
    alertAppliedSuccess: "Mapeo aplicado con éxito.",
    alertAppliedFailed: "Error al aplicar el mapeo.",
    //permissions
    permissions: "Permisos",
    products: "Productos DeepHow",
    roleAccessToProduct: "Defina a continuación qué roles de usuario podrán acceder a cada producto.",
    analytics: "Análisis",
    skillsManager: "Gestión de habilidades",
    skillsPlayer: "Reproducción de habilidades",
    workflowCapture: "Captura de flujo de trabajo",
    workflowEditor: "Editor de flujo de trabajo",
    organizationAdmin: "Administrador de la organización",
    groupAdmin: "Administrador del espacio de trabajo",
    publisher: "Editores",
    viewer: "Espectadores",
    rolesPermission: "Permisos del rol",
    rolesPermissionMessage: "¿Está seguro de que desea cambiar los permisos del rol para este producto? <br/> Los cambios se realizarán de inmediato.",
    inviteUser: "Invitar usuarios",
    workflowSharing: "Compartir flujos de trabajo",
    allowUserstoSharePublicLinks: "Permitir a los usuarios compartir enlaces públicos.",
    changeWorkflowSharingExplanation: "Al desactivar la opción para compartir enlaces públicos, solo los usuarios que se registren en la plataforma podrán acceder a los flujos de trabajo públicos.",
    allowWorkflowSharingMessage: "¿Está seguro de que desea activar la opción para compartir enlaces públicos?",
    preventWorkflowSharingMessage: "¿Está seguro de que desea desactivar la opción para compartir enlaces públicos? Los cambios se realizarán de inmediato.",
    workflowDownload: "Descarga de flujos de trabajo",
    allowUsersToDownloadWorkflows: "Permitir a los usuarios descargar flujos de trabajo.",
    allowDownloadWorkflowsMessage: "¿Está seguro de que desea permitir que los usuarios descarguen flujos de trabajo?",
    preventDownloadWorkflowsMessage: "¿Está seguro de que desea evitar que los usuarios descarguen flujos de trabajo?",
    autoLogout: "Fin de sesión automático",
    autoLogoutAfterSelectedTime: "La sesión finalizará automáticamente después de la hora seleccionada.",
    numMinutes: "0 minutos | 1 minuto | {count} minutos",
    changeLogoutTimeMessage: "¿Está seguro de que desea cambiar el tiempo de fin de sesión automáticamente a {num} minutos?",
    enableAutoLogoutMessage: "¿Está seguro de que desea habilitar el cierre de sesión automático?",
    disableAutoLogoutMessage: "¿Está seguro de que desea deshabilitar el cierre de sesión automático?",
    changesMadeImmediately: "Los cambios se realizarán de inmediato.",
    sessionIsAboutToExpire: "Su sesión está a punto de expirar.",
    youWillBeLoggedOutNumSeconds: "Se desconectará en 0 segundos | Se te desconectará en {count} segundos",
    continueSession: "Continuar la sesión",
    //system languages
    languages: "Idiomas",
    systemLanguage: "Lenguaje del sistema",
    searchForWorkspaces: "Búsqueda de espacios de trabajo ...",
    workspacesPerPage: "Espacios de trabajo por página",
    workspace: "Espacio de trabajo",
    workspaceLanguage: "Idioma",
    changeLanguage: "Cambiar idioma",
    popupMsgChangeLanguage: "Cambiar el lenguaje afectará a todos los usuarios dentro de este flujo de trabajo, ¿está seguro de que desea cambiar?",
    alertLanguageSuccessfullyChanged: "idioma cambiado con éxito.",
    alertFailedChangeLanguage: "Error al cambiar el idioma",
    // Categories
    categories: "Categorías",
    alertCategoriesNotSaved: "Categorías no guardadas, reintente más tarde.",
    noCategoryResult: "Sin resultados de búsqueda de categorías",
    noCategoriesAddedYet: "Aún no se han agregado categorías",
    categoriesAssignment: "Asignación de Categorías",
    categoryPlaceholder: "Buscar categorías y subcategorías ...",
    previouslyUsed: "Previamente usado",
    allCategories: "Todas las Categorias",
    workflowCategories: "Categorías de flujo de trabajo",
    searchForCategories: "Buscar categorías ...",
    categoryItems: "ítems",
    categoryItemsNum: "ítems",
    createCategory: "Crear categoría",
    editCategory: "Editar categoria",
    categoryName: "Nombre",
    noCategoriesCreatedYet: "Aún no se han creado categorías.",
    noItemsCreatedYet: "Aún no se han creado artículos.",
    categoriesPerPage: "Categorías por página",
    itemsPerPage: "ítems por página",
    alertCategoryAlreadyInSystem: "Categoría ya existe en el sistema.",
    alertFailedAddCategory: "Error al añadir categoría.",
    alertFailedEditCategory: "Error al editar la categoría.",
    deleteCategory: "Eliminar categoría",
    popupMsgDeleteCategory: "¿Está seguro de que desea eliminar la categoría?",
    itemsIn: "Items en",
    searchForItems: "Buscar ítems ...",
    createItem: "Crear un ítem",
    editItem: "Editar ítem",
    deleteItem: "Eliminar ítem",
    itemName: "Nombre",
    alertItemAlreadyInSystem: "El ítem ya existe en el sistema.",
    alertFailedAddItem: "Error al añadir el ítem.",
    alertSuccessAddItem: "Item creado con éxito.",
    alertFailedEditItem: "Error al editar el ítems.",
    alertSuccessEditItem: "Item editado con éxito.",
    //Teams
    teamsPerPage: "Equipos por página",
    max1000UsersPerTeam: "* Máximo 1000 usuarios por equipo.",
    usersSelected: "0 usuarios seleccionado | 1 usuario seleccionado | {Count} usuarios seleccionados",
    selectAll: "Seleccionar todo",
    removeAll: "Eliminar todo",
    createTeam: "Crear equipo",
    editTeam: "Editar equipo",
    teamName: "nombre",
    teamsIn: "Equipos en",
    searchForTeams: "Buscar equipos ...",
    searchForUsers: "Buscar usuarios ...",
    noUsersAddedYet: "No hay usuarios en su equipo todavía.",
    dateAdded: "Fecha Alta",
    alertTeamAlreadyInSystem: "Equipo ya existe en el sistema.",
    alertFailedEditTeam: "Error al editar el equipo.",
    deleteTeam: "Eliminar equipo",
    popupMsgDeleteTeam: "Eliminará el equipo <b>{name}</ b>. ¿Está seguro de que quiere continuar?",
    noTeamsCreatedYet: "Aún no hay equipos creados.",
    popupMsgDeleteItem: "¿Está seguro de que quiere eliminar el ítem?",
    removeUser: "Eliminar usuario",
    popupMsgRemoveUser: "Eliminará a <b>{name}</ b> de este equipo. ¿Está seguro de que quiere continuar?",
    alertErrorGettingWorkspaceList: "Error al obtener la lista de espacios de trabajo.",
    alertSuccessfullyCreatedTeam: "Equipo creado con éxito",
    alertFailedCreatedTeam: "Error al crear el equipo.",
    alertSuccessfullyUpdatedTeam: "Equipo actualizado con éxito.",
    alertSuccessfullyRemovedUserFromTeam: "Usuario eliminado con éxito del equipo.",
    alertFailedRemovedUserFromTeam: "Error al eliminar al usuario del equipo.",
    alertSuccessfullyAddedUserToTeam: "Usuario añadido con éxito al equipo.",
    alertFailedAddedUserToTeam: "Error al agregar el usuario al equipo.",
    alertWarningAddedUserToTeamAlready: "El usuario ya ha sido agregado al equipo.",
    //added 2022/02/03
    alertAllInvitationResentSuccess: "Todas las invitaciones se reenviaron con éxito.",
    alertAllInvitationResentFailed: "Error al reenviar las invitaciones.",
    alertCheckUserInSystemFailed: "Error al verificar si el usuario ya está en el sistema.",
    alertOrganizationAdminAssignedFailed: "El administrador de la organización no se puede asignar a un espacio de trabajo",
    //added 2022/02/07
    alertFailedDeleteItem: "Error al eliminar el ítem.",
    alertSuccessfullyDeletedItem: "Ïtem eliminado con éxito.",
    alertCategoryDeleteFailed: "Error al eliminar la categoría.",
    alertSuccessfullyDeletedCategory: "Categoría eliminada con éxito.",
    //added 2022/02/08
    upgradeToOrgAdmin: "Actualizar a Administrador de la Organización.",
    yesUpgrade: "Sí, actualizar",
    popupMsgUpgradeToOrgAdmin: "¿Está seguro de que desea actualizar <b>{name}</b>? Los usuarios Administradores tienen acceso completo para configurar su organización y mucho más ...",
    alertFailedUpgradeToOrg: "Error al actualizar a Administrador de la Organización.",
    alertSuccessfullyUpgradeToOrg: "Actualizado con éxito a Administrador de la Organización.",
    // TODO added 2022/02/17
    userActive: "¿Usuario activo?",
    profile: "Perfil",
    // TODO: added 2022/02/09 settings > Master Password
    masterPassword: "Contraseña maestra",
    enableMasterPassword: "Habilitar la contraseña maestra",
    disableMasterPassword: "Deshabilitar la contraseña maestra",
    resetMasterPassword: "Restablecer la contraseña maestra",
    employeeIDAccess: "Acceso con ID de empleado",
    employeeIDAccessMsg:"Si activa esta función, los usuarios que acceden con ID de empleado deberán ingresar esta contraseña para acceder a DeepHow.",
    disableMasterPasswordMsg:"Si desactiva esta función, los usuarios que acceden con ID de empleado no necesitarán ingresar una contraseña para acceder a DeepHow.",
    resetMasterPasswordMsg:"Los usuarios que solo acceden con ID de empleado deberán usar esta nueva contraseña para acceder a DeepHow.",
    insertPassword: "Insertar contraseña",
    alertEnablePasswordFailed: "Error al habilitar la contraseña maestra.",
    alertEnablePasswordSuccess: "Se habilitó con éxito la contraseña maestra.",
    alertDisablePasswordFailed: "Error al deshabilitar la contraseña maestra.",
    alertDisablePasswordSuccess: "Contraseña maestra deshabilitada con éxito.",
    savePasswordMsg: "Esta función no se habilitará hasta que su contraseña se guarde con éxito.",
    resetPasswordMsg: "Ingrese una nueva contraseña maestra y guarde los cambios.",
    currentPassword: "Contraseña actual",
    yesEnable: "Si, habilitar",
    yesDisable: "Si, deshabilitar",
    downgradeOrgAdmin: "Cambiar rol de Admin Org.",
    popupMsgDowngradeOrgAdmin: "¿Está seguro de que desea bajar de categoria a <b>{name}</b>? Será actualizado al rol que selecciona en todos los espacios de trabajo a los que pertenece.",
    selectRole: "Seleccione el rol para el usuario",
    yesDowngrade: "Si, actualizar rol",
    alertFailedDowngradeToOrg: "No se pudo cambiar el rol a {userRole}",
    alertSuccessfullyDowngradeToOrg: "cambió con éxito el rol a {userRole}",
    lastActivity: "Último acceso",
    coverColor: "Color de la portada",
    defaultLanguage: "Idioma predeterminado",
    blue: "Azul",
    red: "Rojo",
    green: "Verde",
    yellow: "Amarillo",
    purple: "Púrpura",
    belongsToWorkspaces: "Pertenece a espacios de trabajo.",
    alertFailedSaveChanges: "Error al guardar los cambios.",
    // TODO: added 04/02
    alertFailedRemoveUser: "Error al eliminar el usuario.",
    // TODO: added 0608
    privateOrnot: "¿privado?",
    privateWorkspaceInfo: "Este espacio de trabajo solo será visto por los miembros asignados a él ...",
    noPermission: "No tiene permisos para ver esto.",
    noPermissionContact: "La página a la que intenta acceder tiene acceso restringido. <br> Si cree que esto es un error, comuníquese con su administrador.",
    privateCannotChanged: "Private setting cannot be changed after the workspace is created.",
    // Shared Account
    sharedAccount: "Cuenta compartida",
    addManually: "Agregar manualmente",
    addASharedAccount:"Agregar una cuenta compartida",
    addASharedAccountMsg: "Las cuentas compartidas no se pueden usar para ver flujos de trabajo individualmente, solo se usan para que los usuarios con ID de empleado puedan crear cuentas en DeepHow y también para realizar un seguimiento de cada usuario en dispositivos compartidos ...",
    accountName: "Nombre de la cuenta",
    alertFailedAddShared: "No se pudo agregar la cuenta compartida",
    alertSuccessfullyCreatedShared: "Cuenta compartida creada con éxito",
    alertSuccessfullyAddExistingShared: "Se agregó con éxito la cuenta compartida existente",
    accountId: "ID de la cuenta",
    addSharedAccount: "Agregar cuenta compartida",
    sharedAccountId: "ID de cuenta compartida",
    unselectedTeam: "equipo no seleccionado",
    // TODO: added 2022/06/22
    workflowReview: "Revisión de flujo de trabajo",
    workflowReviewDescription: "Si esta opción está encendida, los administradores del espacio de trabajo podrán elegir si sus espacios de trabajo requieren una revisión de flujo de trabajo o no",
    preventReviewWorkflowsMessage: "¿Está seguro de que desea evitar que los usuarios revisen los flujos de trabajo?",
    allowReviewWorkflowsMessage: "¿Estás seguro de que quieres permitir a los usuarios revisar los flujos de trabajo?",
    // TODO: added 2022/06/29
    addAsReviewer: "Agregar como revisor",
    removeAsReviewer: "Eliminar como revisor",
    addAsReviewerSuccessMessage: "Agregue {user} como revisor con éxito.",
    addAsReviewerFailedMessage: "No se pudo agregar {user} como revisor.",
    removeAsReviewerSuccessMessage: "Eliminar {user} como revisor con éxito.",
    removeAsReviewerFailedMessage: "No se pudo eliminar {user} como revisor.",
    // Shared Account Admin settings 07/27
    allowsSharedAccountMsg: "Permitir a los administradores de la organización y de los espacios de trabajo crear cuentas compartidas. Los usuarios podrán auto-registrarse utilizando sus cuentas de ID de empleado en un dispositivo compartido.",
    disableSharedAccountMsg: "¿Estás seguro de que quiere deshabilitar la cuenta compartida? <br> Los cambios se aplicarán de inmediato.",
    enableSharedAccountMsg: "¿Estás seguro de que quiere habilitar una cuenta compartida? <br> Los cambios se aplicarán de inmediato.",
    alertEnableSharedAccountFailed: "No se pudo habilitar la cuenta compartida.",
    alertEnableSharedAccountSuccess: "Cuenta compartida habilitada con éxito.",
    alertDisableSharedAccountFailed: "No se pudo deshabilitar la cuenta compartida.",
    alertDisableSharedAccountSuccess: "Cuenta compartida deshabilitada con éxito.",
    // QR Code & Watermark
    qrCodeAndWatermark: "Código QR y marca de agua",
    insertYourBrand: "Inserte su marca",
    uploadYourLogo: "Sube su logotipo",
    uploadingYourLogo: "Subiendo su logotipo",
    logoSuccessfullyUploaded: "Logotipo cargado con éxito",
    errorInUpload: "Error en su carga",
    errorInUploadMsg: "Tipo de archivo no compatible. Seleccione el siguiente tipo de archivo: .png",
    uploadLogoMsg: "Arrastre y suelte un archivo <b>.png</b> con un máximo de <b>3 MB</b> o <span>busque</span> desde su computadora.",
    uploadLogoReplaceMsg: "Arrastre o <span>busque</span> para reemplazar con una imagen desde su computadora o <span id = 'DUploadImage_removeLogo'>eliminar</span> la imagen actual.",
    simulation: "Simulación",
    simulationMsg: "Vea a continuación cómo se presentará su logotipo en la marca de agua de los videos y en los códigos QR.",
    workflowTitle: "Título del flujo de trabajo",
    logoAlreadyUploaded: "Logotipo ya cargado",
    alertLogoSuccessfullyRemoved: "Logotipo eliminado con éxito.",
    alertLogoFailedRemoved: "No se pudo eliminar el logotipo.",
    alertUserInOtherOrg: "Este usuario ya existe en otra organización.",
    // added 10/13 delete workspace
    deleteWorkspace: "Eliminar el Espacio de Trabajo",
    popupMsgDeleteWorkspace: "¿Estás seguro de que quiere eliminar este espacio de trabajo ({name})?",
    alertDeleteGroupSuccess: "Eliminó con éxito el espacio de trabajo.",
    alertDeleteGroupFailedWorkflows: "Hay Flujos de Trabajo asignados a este Espacio de Trabajo, no es posible eliminarlo.",
    alertDeleteGroupFailedSkills: "Hay Habilidades asignadas a este espacio de trabajo, no es posible eliminarlo.",
    alertDeleteGroupFailedTeams: "Hay Equipos asignados a este espacio de trabajo, no es posible eliminarlo.",
    // added 11/3 delete team
    alertDeleteTeamSuccess: "Equipo eliminado con éxito.",
    alertDeleteTeamFailedSkills: "Este equipo no puede eliminar, debido a que existen habilidades asignadas.",
    alertDeleteTeamFailed: "No se pudo eliminar el equipo.",
    // Change Profile Name
    changeProfileName: "Cambiar el Nombre de Perfil",
    allowsChangeProfileNameMsg: "Los usuarios pueden cambiar el nombre en sus perfiles.",
    disableChangeProfileNameMsg: "¿Está seguro de que quiere deshabilitar el cambio de nombre en el perfil? <br> Los cambios se realizarán de inmediato.",
    enableChangeProfileNameMsg: "¿Está seguro de que quiere habilitar el cambio de nombre en el perfil? <br> Los cambios se realizarán de inmediato.",
    alertEnableChangeProfileNameSuccess: "Cambio de nombre en el perfil habilitado con éxito.",
    alertEnableChangeProfileNameFailed: "No se pudo habilitar el cambio de nombre en el perfil.",
    alertDisableChangeProfileNameSuccess: "Cambio de nombre en el perfil deshabilitado con éxito.",
    alertDisableChangeProfileNameFailed: "No se pudo deshabilitar el cambio de nombre en el perfil.",
    // Workflow Comments
    workflowComments: "Comentarios del flujo de trabajo",
    allowsWorkflowCommentsMsg: "Los usuarios pueden dejar comentarios sobre flujos de trabajo publicados.",
    disableWorkflowCommentsMsg: "¿Está seguro de que quiere deshabilitar los comentarios sobre flujos de trabajo? <br> Los cambios se realizarán de inmediato.",
    enableWorkflowCommentsMsg: "¿Está seguro de que quiere habilitar los comentarios sobre flujos de trabajo? <br> Los cambios se realizarán de inmediato.",
    alertEnableWorkflowCommentsSuccess: "Comentarios sobre flujos de trabajo habilitados con éxito.",
    alertEnableWorkflowCommentsFailed: "No se pudo habilitar los comentarios sobre flujos de trabajo.",
    alertDisableWorkflowCommentsSuccess: "Comentarios sobre flujos de trabajo deshabilitados con éxito.",
    alertDisableWorkflowCommentsFailed: "No se pudo deshabilitar los comentarios sobre flujos de trabajo.",
    // Workspace Groups
    workspaceGroupsNum: "Grupo de espaço de trabalho | grupo de espaço de trabalho | grupos de espaço de trabalho",
    searchWorkspaceGroups: "Pesquisar por grupos de trabalho...",
    noWorkspaceGroupsYet: "Nenhum grupo de espaço de trabalho ainda criado.",
    createWorkspaceGroup: "Crie grupo de espaço de trabalho",
    editWorkspaceGroup: "Editar grupo de espaço de trabalho",
    workspaceGroupName: "Nome do grupo de espaço de trabalho",
    editWorkspace: "Editar Espacio de Trabajo",
    userNum: "usuario | usuario | Usuarios",
    teamNum: "Equipo | Equipo | Equipos",
    defaultLanguage: "Idioma predeterminado",
    deleteWorkspaceGroup: "Excluir grupo de espaço de trabalho",
    alertCreateWorkspaceGroupSuccess: "Criou com sucesso o grupo de espaço de trabalho.",
    alertWorkspaceGroupNameAlreadyExists: "Este nombre del Grupo de Espacios de Trabajo ya está en el sistema.",
    alertEditWorkspaceGroupSuccess: "Grupo de espaço de trabalho editado com sucesso.",
    alertEditWorkspaceGroupFailed: "Falhou em editar o grupo de espaço de trabalho.",
    alertDeleteWorkspaceGroupSuccess: "Excluiu com sucesso este grupo de espaço de trabalho.",
    alertDeleteWorkspaceGroupFailed: "Falha ao excluir este grupo de espaço de trabalho.",
    alertWorkspaceAssignedDeleteGroupFailed: "Existem espaços de trabalho atribuídos a este grupo de espaço de trabalho, logo você não podea excluí -lo.",
    deleteWorkspaceGroupMessage: "Tem certeza de que deseja excluir este grupo de espaço de trabalho (<b>{name}</b>)?",
    alertWorkspaceNameAlreadyExists: "El nombre del Espacio de Trabajo ya existe.",
  },
  builder: {
    publish: "Publicar",
    reviewTranscription: "Revisar transcripción",
    viewer: "Reproductor",
    links: "Enlaces",
    trim: "Recortar",
    translation: "Traducción",
    share: "Compartir",
    addDiagramMsg: "Arrastre o haga clic para agregar diagramas.",
    onlyImageAccepted: "Solo se aceptan archivos de imagen",
    unsupportedImageFormats: "Formatos de imagen no compatibles: svg, tiff, xbm",
    addDiagram: "Añadir diagrama",
    start: "Comienzo",
    end: "Fin",
    resetToOriginal: "Restablecer original",
    trimStartMsg: "Elija dónde quiere que el video comience y termine.",
    trimProcessingMsg: "El recorte no está disponible mientras el flujo de trabajo está en procesamiento. <br> por favor vuelva más tarde",
    cannotTrimLessThan3s: "No puedes recortar videos más cortos que 3 segundos.",
    trimVideo: "Recortar video",
    trimStep: "Recortar paso",
    editWorkflow: "Editar flujo de trabajo",
    popupBtnEdit: "Si, editar",
    popupBtnPublish: "Si publicar",
    popupBtnUnpublish: "No publicar",
    popupMsgTrimConfirmation: "¿Está seguro de que quiere recortar?",
    popupMsgTrimWithSaveConfirmation: "Hay cambios no guardados en su flujo de trabajo. Guardaremos los cambios por usted.",
    popupBtnTrim: "Si",
    popupMsgTranscoding: "La conversión está en proceso.",
    popupMsgTrimBackLater: "Puede reanudar la edición del flujo de trabajo después de que se complete la conversión.",
    popupMsgTrimSuccess: "Su paso fue recortado con éxito!",
    done: "Listo",
    publishWorkflow: "Publicar el flujo de trabajo",
    popupMsgEditWorkflow: "Si ingresa al modo de edición, su flujo de trabajo será despublicado automáticamente. ¿Está seguro de que desea continuar?",
    popupMsgUnpublishWorkflow: "Va a despublicar su flujo de trabajo, ¿Está seguro de que desea continuar?",
    alertSaveFailed: "Error al guardar el flujo de trabajo.",
    alertTrimResetSuccess: "El paso se restableció con éxito.",
    alertTrimResetFailed: "Error al restablecer el paso.",
    alertTrimCanNotReset: "Este paso no se puede restablecer.",
    resetStep: "Restablecer paso",
    popupMsgResetStep: "¿Está seguro que quiere restablecer el paso? Los diagramas agregados a este paso se perderán.",
    popupBtnReset: "Si restablecer",
    hintSearchBuilder: "Búsqueda de flujos de trabajo y palabras clave ...",
    stepsSelected: "seleccionado",
    clear: "Deseleccionar",
    insert: "Insertar",
    reset: "Reiniciar",
    resetWorkflow: "Restablecer flujo de trabajo",
    resetWorkflowAlert: "Perderá todo el trabajo realizado en este flujo de trabajo y comenzará desde el principio nuevamente, ¿estás seguro de que quieres continuar?",
    resetWorkflowKeepCopy: "Mantenga una copia de este flujo de trabajo",
    // Transcript / Translation
    closeTranscript: "Cerrar Transcripción",
    popupMsgCloseTranscript: "Usted tiene cambios no guardados en su transcripción. Si continúa, <b>perderá todos los cambios</ b>. ¿Está seguro?",
    stepNoTranscript: "Paso sin transcripción",
    originalTranscript: "Transcripción original",
    addLanguageTooltip: "Haga clic aquí para agregar nuevos idiomas a su video",
    closeTranslation: "Cerrar traducción",
    popupMsgCloseTranslation: "Usted tiene cambios no guardados en su traducción. Si continúa, <b>perderá todos los cambios</ b>. ¿Está seguro?",
    switchTranslation: "Cambiar traducción",
    popupBtnNoSwitch: "No, cambiar",
    popupBtnYesSwitch: "Si, guardar y cambiar",
    createAudio: "Crear audio",
    addNewLanguageTooltip: "Haga clic aquí para agregar un nuevo idioma a su video",
    popupMsgCreateAudio: "¿Está seguro de que desea crear las traducciones de audio para su flujo de trabajo? Le recomendamos hacerlo solo una vez, ya que Stephanie procesará su transcripción por completo.",
    popupBtnYesCreate: "Si, crear",
    alertAudioBeingCreated: "El audio de la traducción se está creando.",
    alertTranscriptionSaved: "Transcripción guardada con éxito.",
    alertTranscriptionSaveFailed: "Error al guardar la transcripción.",
    alertTranslationsSaved: "Traducciones guardadas con éxito.",
    alertTranslationsSaveFailed: "Error al guardar traducciones.",
    alertAudioCreated: "Audio creado con éxito.",
    alertAudioFailed: "Error al crear el audio de la traducción.",
    alertNoAudioGenerated: "No se generó audio porque no hay actualización de la traducción.",
    alertErrorCreateTranscription: "Error al crear transcripción.",
    //Share workflow
    shareWorkflow: "Compartir flujo de trabajo",
    fullWorkflow: "Flujo de trabajo completo",
    specificSteps: "Pasos específicos",
    chooseShareType: "Elija como compartir",
    qrCode: "Código QR",
    link: "Enlace",
    embedLink: "Enlace embebido",
    privateWorkflow: "Flujo de trabajo privado",
    publicWorkflow: "Flujo de trabajo público",
    close: "Cerrar",
    saveQRCode: "Guardar código QR",
    download: "Descargar",
    copyLink: "Copiar link",
    copied: "Copiado",
    downloading: "Descargando",
    videoPrivacy: "Privacidad del video",
    popupMsgVideoPrivacy: "Cuando convierte su flujo de trabajo a público, cualquier persona puede tener acceso, incluso sin una cuenta de DeepHow. ¿Está seguro?",
    includeSubtitles: "Incluir subtítulos",
    noSubtitles: "Sin subtitulos",
    selectLanguage: "Seleccione el idioma",
    workflowReady: "Flujo de trabajo listo!",
    prepareToDownload: "Preparar para descargar",
    processingDownloadVideoMsg: "¡No cierre esta página! <br /> En pocos minutos, su archivo estará listo para descargar.",
    processingWorkflow: "Procesando flujo de trabajo ...",
    openAnotherTabMsg: "Mientras espera, puede navegar en DeepHow en otra pestaña",
    openNewTab: "Abrir nueva pestaña",
    alertErrorPreparingDownload: "Ocurrió un error al preparar su descarga. Por favor, intente nuevamente o póngase en contacto con DeepHow para obtener ayuda.",
    hide: "Ocultar",
    addNewLink: "Añadir nuevo enlace",
    clickToAddLinks: "Haga clic para agregar enlaces a este paso",
    addALink: "Añadir un enlace",
    editLink: "Editar enlace",
    addLinkAddress: "A continuación, ingrese la dirección para agregar un enlace a este paso.",
    addLinkLable: "¿Quiere agregar una etiqueta?",
    clickOpenOfficialDocument: "Haga clic para abrir el documento oficial.",
    //settings /categories
    settings: "Ajustes",
    categories: "Categorías",
    noCategories: "No se ha creado ninguna categoría.",
    selectCategoriesMessage: "Seleccione al menos una categoría, para que los usuarios puedan filtrar el contenido.",
    closeSettings: "Cerrar ajustes",
    popupMsgCloseSettings: "Usted tiene cambios no guardados. Si continúa, <b>perderá todos los cambios</b>. ¿Está seguro?",
    workflowCategories: "Categorías del flujo de trabajo",
    popupBtnAssignCategoriesAndPublish: "Asignar categorías y publicar",
    popupBtnSkipAndPublish: "Saltar este paso y publicar de todos modos",
    popupMsgworkflowCategories: "Antes de publicar, ¿desea seleccionar algunas categorías para su flujo de trabajo, para que los usuarios puedan encontrarlo más fácilmente?",
    alertCategoriesSaved: "Categorías guardadas con éxito.",
    alertCategoriesSaveFailed: "Error al guardar categorías.",
    searchForCategoryName: "Buscar {name} ...",
    loadingCategories: "Cargando categorías ...",
    alertFailedDownloadVideo: "No se pudo descargar el video",
    // Workflow Cover
    workflowCover: "Portada de flujo de trabajo",
    selectFromTheVideo: "Seleccione desde el video",
    browseForAFile: "Buscar un archivo",
    dropFileMessage: "Arrastre el archivo o haga clic aquí para buscarlo.",
    selectedImage: "Imagen seleccionada",
    noImageSelected: "No se ha seleccionado una imagen",
    alertSuccessfullyUpdatedCover: "Portada del flujo de trabajo actualizada con éxito.",
    alertFailedUpdatedCover: "Error al actualizar la portada del flujo de trabajo.",
    selectAnotherImage: "Seleccione otra imagen",
    alertErrorStepLostTranscription: "Existen pasos que no se han traducido. Por favor, tradúzcalos antes de descargar su flujo de trabajo.",
    annotation: "Anotación",
    allNotes: "Todas las notas",
    noNotesAddedYet: "No se han agregado notas todavía.",
    popupMsgEnterTrim: "Su paso tiene anotaciones. Si desea recortar el video, estas anotaciones se perderán.",
    popupMsgEnterErase: "Su paso tiene anotaciones. Si desea borrar el video, estas anotaciones se perderán.",
    popupMsgEnterAnnotation: "Asegúrese de recortar o borrar el video antes de agregar anotaciones.",
    workingOn: "Trabajando en",
    eraseStep: "Eliminar paso",
    popupMsgEraseSuccess: "¡Su paso fue eliminado con éxito!",
    popupBtnYesErase: "Si, eliminar",
    popupMsgEraseConfirmation: "Estás seguro que quiere eliminar?",
    eraseProcessingMsg: "No es posible eliminar mientras el procesamiento del flujo de trabajo está en progreso. <br> Por favor intente más tarde",
    translateOnlyTitlesBtn: "Traducir títulos y nombres de pasos",
    untitled: "Sin título",
    hintNoEmptyTitle: "El título no puede estar vacío",
    popupMsgSwitchTranslation: "Tiene cambios sin salvar en su traducción a <b>{lang}</b>. ¿Le gustaría guardarlos antes de cambiar?",
    alertNoEmptyContent: "Los títulos u oraciones no pueden estar vacías. Ingrese el contenido.",
    PDF: "Pdf",
    PDFSizeLimit: "El tamaño del archivo PDF está limitado a 50 MB",
    addPDF: "Agregar PDF",
    onlyPDFAccepted: "Solo se aceptan archivos PDF",
    preview: "Vista previa",
    // Send to reviewer
    sendToReviewer: "Enviar al revisor",
    alertTitleSelectReviewers: "Seleccione los usuarios para revisar su flujo de trabajo",
    alertSendToReviewSuccess: "Su flujo de trabajo fue enviado con éxito para revisión.",
    alertSendToReviewFailed: "No se pudo enviar para revisión",
    subtitlesSize: "Tamaño de subtítulos",
    small: "Pequeño",
    medium:"Mediano",
    big: "Grande",
    yourVideoReadyToDownload: "Su video esta listo para descargar.",
    yourVideoWasSuccessfullyDownloaded: "Su Flujo de Trabajo se ha descargado con éxito.",
    notAvailable: "no disponible",
    downloadFileFormat: "formato",
    selectDownloadWorkflowQuality: "Seleccione la calidad del video",
    downloadSizeLimitedMsg: "La descarga de SCORM en {resolution} admite flujos de trabajo de hasta {duration} minutos.",
    workflowTotalDurationMsg: "Duración total del flujo de trabajo: {minute} min {second} seg.",
    // Export Compliance Wire
    export: "Exportar",
    code: "Código",
    organization: "Organización",
    approvalDate: "Fecha de aprobación",
    processing: "Procesando",
    workflowShouldBePublic: "El flujo de trabajo debe ser público.",
    alertExportComplianceWireSuccess: "El documento de control se ha exportado con éxito. Código: {code}",
    alertExportComplianceWireExists: "El documento de control ya existe. Código: {code}",
    alertExportComplianceWireFailed: "Algo salió mal al exportar el documento de control.",
    confirmedPublished: "¿Está seguro de que quiere publicar este flujo de trabajo? <br> Si procede, todos podrán verlo.",
    versionDescription: "Versión {num} Descripción (opcional):",
    versionIsDuplicated: "la versión está duplicada",
  },
  editor: {
    closeWorkflow: "Cerrar flujo de trabajo",
    popupMsgCloseWorkflow: "Usted tiene cambios no guardados en su flujo de trabajo. Si continúa, <b>perderá todos los cambios</ b>. ¿Está seguro?",
    popupBtnClose: "Si",
    processWorkflow: "Flujo de trabajo de proceso",
    popupMsgProcessWorkflow: "¡Excelente! Parece que terminó su revisión y quiere que Stephanie lo procese. <br><br><b> No podrá volver a</ b> a esta pantalla de revisión. ¿Está listo?",
    popupBtnProcess: "Si, procesar",
    alertIsSaving: "El guardado está en progreso.",
    alertNoEmptySentence: "Las oraciones no pueden estar vacías. Por favor ingrese o elimine las oraciones, luego vuelva a guardar.",
    alertIsSaved: "Se guardaron tus cambios.",
    alertWasUnpublished: "Su flujo de trabajo fue despublicado con éxito.",
    alertWasPublished: "Su flujo de trabajo fue publicado con éxito.",
    alertNoMoreSteps: "No puedes agregar más pasos aquí.",
    alertNoEmptyTitle: "El título no puede estar vacío. Por favor ingrese el título, luego vuelve a guardar.",
    scrollToVoice: "Desplácese hasta la voz",
    noAudioMsg: "No pudimos encontrar ningún audio para transcribir en su flujo de trabajo ...",
    youWillDeleteStep: "Eliminará el paso",
    areYouSure: "¿Está seguro?",
    areYouSureDelete: "¿Está seguro de que usted desea eliminar?",
    replace: "Reemplazar",
    keyword: "Palabra clave",
    find: "Encontrar",
    replaceWith: "Reemplazar con",
    replaceAll: "Reemplaza todo",
    replaceAllMsg: "Reemplazará todas estas palabras.",
    of: "de",
    msgToSteps: "Analizando el flujo de trabajo ... <br>Por favor, espere ...",
    msgToEditor1: "Ahora vamos a revisar el flujo de trabajo antes de publicar.",
    msgToEditor2: "Lo guiaré en cada paso, no hay de que preocuparse.",
    msgToBuilder1: "En unos segundos habré finalizado.",
    msgToBuilder2: "Estoy creando los pasos de su flujo de trabajo.",
    newStep: "Nuevo paso",
    hintNoEmptySentence: "Las oraciones no pueden estar vacías",
    hintNoEmptyTitle: "(El título no puede estar vacío)",
    alertNoTranscription: "No hay transcripción para el flujo de trabajo.",
    alertEmptyingTranscription: "¡ADVERTENCIA! Está eliminando la transcripción. No puede hacerlo",
    alertStepCorrupted: "El paso {count} está dañado. Por favor elimínelo antes de procesar",
    // Editor 2.0
    trim: "Recortar",
    erase: "Eliminar",
    voiceOver: "Voz en Off",
    videoEditing: "Edición de Video",
    segmentation: "Segmentación",
    popupMsgRestToOriginal: "Esta seguro que desea volver al video original? Todos los cambios se perderán.",
    trimHintMessage: "Use las flechas para seleccionar el area que desea recortar",
    eraseHintMessage: "Use las flechas para seleccionar el area que desea eliminar",
    voiceOverHintMessage: "Seleccione el área en la que desea agregar voz en off...",
    voiceOverStartHint: "Presione aquí para iniciar su grabación...",
    voiceOverStart: "Grabar",
    voiceOverSave: "Guardar registro",
    voiceOverCancel: "Descartar",
    voiceOverCancelPopupMsg: "Esta seguro que desea eliminar su grabación?",
    voiceOverPause: "Pausar",
    voiceOverResume: "Reanudar",
    unableToGetAudioInput: "No se ha detectado un dispositivo de ingreso de audio",
    restart: "reiniciar",
    editVideo: "Editar video",
    popupRequestTranscription: "No se puede encontrar la transcripción para el flujo de trabajo. Vamos a crear una nueva.",
    popupMsgEditVideoWarning: "Todos los cambios realizados en la transcripción se perderán si edita su video, ¿está seguro de que desea editar?",
    popupMsgReTranscriptionWarning: "Nota: Si ha realizado alguna edición al video (como Recortar/Eliminar/Voz en Off), no podrá usar la función de re-transcripción.",
    popupMsgReTranscriptionWarningMultiEditing: "Nota: Si ha realizado alguna acción de edición de video (como cortar/voz en off), no podrá usar la función de re-transcripción.",
    rotate: "Girar",
    rotateLeft: "Girar a la izquierda",
    rotateRight: "Girar a la derecha",
    goBack: "atrás",
    goBackToEditorHome: "Volver al Editor",
    zoom: "zoom",
    // transcription new features
    transcriptionCopySuccess: "Transcripción copiada a su portapapeles",
    keyTerms: "Términos clave",
    AILabs: "AI Labs",
    copyTranscription: "Copiar transcripción",
    changeLanguage: "Cambiar idioma",
    copy: "Copiar",
    changeLanguageTitle: "Seleccione el nuevo idioma para su transcripción",
    changeLanguageSubTitle: "Stephanie necesitará reprocesar toda su transcripción.",
    reProcessingYourVideo: "Stephanie está reprocesando su video ...",
    goBackToEditor: "¡Puedes esperar aquí o volver al editor para seguir trabajando!",
    // keyterms: added on 08/09/2022
    addTerm: "Agregar término",
    removeTerm: "Eliminar el término",
    termsFound: "<span>0</span> término encontrado | <span>1</span> términos encontrados",
    loadingTerms: "Términos de carga",
    termsInfo: "Los términos pueden ser muy útiles al buscar contenidos específicos en flujos de trabajo.",
    // Editor 3.0
    cut: "Cortar",
    tabsWarningMessages: "¡Advertencia! Si sale de esta página sin guardar, todos los cambios que realizó se perderán.",
    versionHistory: "Historial de versiones",
    seeMore:"ver más...",
    seeLess:"ver menos...",
    workflowCreated:"Flujo de trabajo creado",
    createdBy: "Creado por {name}",
    publishedBy: "Publicado por {name}",
    lastUpdateBy: "Última actualización de {name}",
    // Version History add by andy 01/08/2023
    emptyTitle: "No versions yet!",
    emptyContent: "This Workflow has not been published yet, as soon as you publish for the first time you will start seeing the versions here."
  },
  player: {
    loadingVideo: "Cargando video...",
    loadingLanguage: "Cargando idioma ...",
    previousStep: "Paso anterior",
    nextStep: "Próximo paso",
    audio: "Audio",
    subtitles: "Subtítulos",
    autoPlay: "Auto-reproducción",
    off: "Desactivar",
    on: "Activar",
    quality: "Calidad",
    speed: "Velocidad",
    normal: "Normal",
    rotatePhone: "<span class='primary--text'> girar </span> su teléfono para obtener una <br>mejor experiencia",
    alertTranscodingFailed: "La conversión ded video ha fallado. Por favor, póngase en contacto con DeepHow para obtener más ayuda.",
    alertTranscodingStillProgress: "La conversión del video todavía está en progreso. Por favor regrese más tarde.",
    settings: "Ajustes",
    openInBrowser: "Abrir en el navegador",
    goToHome: "Ir al Inicio",
    thisWorkflowWasUnpublished: "El flujo de trabajo fue despublicado.",
    thisWorkflowWasDeleted: "El flujo de trabajo fue eliminado",
    talkToSupervisor: "Si usted cree que este flujo de trabajo debería publicarse, hable con su supervisor",
    selectWorkspace: "Seleccione Espacio de trabajo",
    // player 2.0:
    home: "Inicio",
    myWorkflows: "Mis flujos de trabajo",
    recommendedForYou: "Recomendados para usted",
    favorites: "Favoritos",
    welcomeToDeepHowNavigator: "Bienvenido al Navegador DeepHow",
    numNewSkillsTrainings: "<span> 0 entrenamientos </span> <br> asignados. | <span> 1 entrenamiento </span> <br> asignado a usted. | <span> {count} entrenamientos </span> <br> asignados a usted.",
    clickForTips: "Haga clic aquí para algunos consejos.",
    learnHow: "Aprenda cómo",
    goToSkills: "Ir a habilidades",
    at: "en ",
    youAreIn: "Usted está en",
    loadingRecommendations: "Cargando recomendaciones ...",
    loadingFavoriteWorkflows: "Cargando flujos de trabajo favoritos ...",
    noFavoriteWorkflows: "Aún no tiene ítems en sus favoritos.",
    canFindSavedItemsHere: "Aquí puede encontrar todos tus ítems guardados.",
    removeWorkflowFromFavorites: "¿Está seguro de que desea eliminar este flujo de trabajo de sus favoritos?",
    yesGoAhead: "Si, adelante",
    thisWorkflowIsNotAvailable: "El flujo de trabajo no está disponible",
    dismiss: "Descartar",
    navigator: "Navegador",
    howToUseNavigator: "Cómo utilizar el nuevo <br><b>Navegador DeepHow</b>",
    checkTheNewFeatures: "Chequear las nuevas funciones.",
    unknownUser: "Usuario desconocido",
    okCloseBtn: "Ok, cerrar.",
    emptyRecommendWorkflowTitle: "Hey! Donde está el contenido?",
    emptyRecommendWorkflowDescription: "¡No se preocupe! Tan pronto como se creen los flujos de trabajo, le recomendaré algunos. ¡Vuelva pronto!",
    thisWorkflowDoesNotExist: "Este flujo de trabajo no existe.",
    // navigator
    resume: "Continuar",
    myWorkspaces: "Mis espacios de trabajo",
    otherWorkspaces: "Otros espacios de trabajo",
    loading: "Cargando...",
    loadingfavoriteWorkspaces: "Cargando Espacios de Trabajo favoritos...",
    removeWorkspaceFromFavorites: "¿Está seguro de que desea eliminar este Espacio de Trabajo de sus favoritos?",
    YouAreViewing: "Está viendo",
    allWorkspaces: "Todos los espacios de trabajo",
    removeFromFavorites: "Quitar de favoritos",
    favoriteThisVideo: "Marcar este video como favorito",
    shareThisVideo: "Compartir este video",
    viewAll: "Ver todos",
    openFile: "Abrir documento",
    suggestedKeyterms: "Términos clave sugeridos",
    // workflow player added 10/10/2022
    posted: "Publicado",
    views: "0 Vistas | 1 Vista | {count} Vistas",
    saveFavorite: "Guardar",
    react: "Reaccionar",
    numColleaguesReact: "0 colegas reaccionaron | 1 colega reaccionó | {count} colegas reaccionaron",
    youAndNumColleaguesReact: "<span>Usted</span> y 0 colegas reaccionaron| <span>Usted</span> y 1 colega reaccionaron | <span>Usted</span> y {count} colegas reaccionaron",
    youReacted: "<span>Usted</span> reaccionaron",
    userReaction: "Reacción de Usuario",
    fullscreen: "Pantalla completa",
    exitFullscreen: "Salir de pantalla completa",
    // 1/23/2023 Comments
    comments: "Comentario",
    deleteComment: "Eliminar comentario",
    deleteCommentMsg: "¿Estás seguro de que quiere eliminar este comentario? <br> No se puede volver atrás.",
    edit: "Editar",
    updating: "actualizando...",
    changesSaved: "Cambios guardados.",
    noComments: "Aún sin comentarios…",
    makeFirstComment: "Haga el primer comentario a continuación",
    comment: "comentar",
    writeAComment: "Escriba un comentario...",
    edited: "editado",
    emojiRecent: "Usado frecuentemente",
    emojiSmileys: "Smileys & Emoticon",
    emojiPeople: "Gente y cuerpo",
    emojiNature: "Animales y naturaleza",
    emojiFoods: "Comida y bebida",
    emojiActivity: "Actividad",
    emojiPlaces: "Viajes y lugares",
    emojiObjects: "Objetos",
    emojiSymbols: "Símbolo",
    emojiFlags: "Banderas",
    emojiCustom: "Personalizado",
    back: "atrás",
    feedback: "Comentario",
    leaveAFeedback: "Deje un comentario",
    leaveAFeedbackMsg: "Cuéntenos sobre cualquier problema, deje una sugerencia o incluso una crítica constructiva.",
    typeYourMessageHere: "Escriba su mensaje aquí",
    submit: "Enviar",
    thankYou: "Gracias.",
    feedbackSuccessMsg: "Sus comentarios han sido enviados con éxito.",
    alertFeedbackFailedMsg: "No se pudo enviar sus comentarios. Por favor, inténtelo de nuevo.",
    diagrams: "diagramas",
    pdfFiles: "Archivos PDF",
    theaterMode: "Modo teatral",
    exitTheaterMode: "Salir de modo teatro",
    // TODO: 09/08/2023
    enableWorkflowRepeat: "Habilitar la repetición del flujo de trabajo",
    enableStepRepeat: "Habilitar repetición del paso",
    disableRepeat: "Deshabilitar repetir",
  },
  profile: {
    // added 12/09/2021
    addPositionLocation: "Agregar posición y ubicación",
    noWorkflowsCreated: "Todavía no se han creado flujos de trabajo.",
    comeBackLater: "Vuelva mas tarde.",
    createdWorkflows: "Flujos de trabajo creados",
    profileOwnerNoContentMsg: "<span>Hola, {name}! Aquí está su nueva página de perfil.</span> <br> En el futuro, podrá compartir conocimiento con todos. ¡Espere!",
    profileColleguesNoContentMsg: "<span>Esta es la página de perfil de {name}.</span> <br> Todavía no puede publicar contenido, ¡pero en el futuro cercano lo hará!",
    editProfile: "Editar Perfil",
    personalInformation: "Informacion personal",
    personalInformationMsg: "Las personas que visitan su perfil verán la siguiente información.",
    photo: "foto",
    position: "Posición",
    locationRegion: "Ubicación/ región",
    whatYouDo: "Deje que la gente sepa lo que hace en esta organización.",
    whereYouBased: "Hágale saber a la gente dónde se encuentra.",
    accountManagement: "Administración de cuentas",
    accountManagementMsg: "Esta información es privada y no aparecerá en su perfil público.",
    identifyMyself: "Me identifico como",
    yourPassword: "Su contraseña",
    nonDeclared: "No declarado (predeterminado)",
    male: "Hombre",
    female: "Mujer",
    nonBinary: "No binario",
    viewProfile: "Ver Perfil",
    alertFailedToUpdateProfile: "No se pudo actualizar su perfil."
  },
  explorer: {
    search: "Buscar",
    in: "en&nbsp;",
    howTo: "Cómo...",
    didYouMean: "Quería decir",
    searchInTitle: "En el título",
    searchInContent: "En el contenido",
    searchInVideo: "Sugerencias de Stephanie",
    title: "Título",
    searchStartMsg1: "¿Qué está buscando?",
    searchStartMsg2: "Utilice el cuadro de búsqueda de arriba",
    sortBy: "Ordenar por",
    lastUpdated: "Última actualización",
    alphabetically: "Alfabéticamente",
    workflowsIn: "Flujos de trabajo en",
    tagNew: "Nuevo",
    tagProcessing: "Procesando",
    msgProcessing: "Stephanie está procesando su flujo de trabajo ... <br/> Por favor, espere :)",
    tagFailed: "Fallo",
    msgFailed: "Algo salió mal al procesar la transcripción o el video. Volveremos a intentarlo pronto ...",
    msgVideoLimitExceeded: "El video superó la duración admitida por DeepHow. Se volverá a cargar automáticamente en dos o más flujos de trabajo.",
    msgVideoExceededMaximum: "El video excede la longitud máxima de flujo de trabajo soportada por DeepHow. Se dividirá automáticamente en dos o más flujos de trabajo",
    tagTranscoding: "Transcodificación",
    msgTranscoding: "Stephanie está codificando su flujo de trabajo ... <br/> Por favor, espere :)",
    deleteWorkflow: "Eliminar flujo de trabajo",
    popupBtnDelete: "Si eliminar",
    popupMsgDeleteWorkflow: "¿Está seguro de que desea eliminar este flujo de trabajo? Perderá todos los pasos y datos relacionados.",
    noUnpublishedWorkflows: "Aún no tiene flujos de trabajo sin publicar ...'",
    noPublishedWorkflows: "Aún no tiene flujos de trabajo publicados ...'",
    startCreatingWorkflow: "¡Empecemos a crear un flujo de trabajo!",
    startPublishingWorkflow: "¡Comencemos la publicación del flujo de trabajo!",
    noSearchResult: "No se han encontrado resultados ...",
    trySearchAgain: "Por favor, intente una nueva busqueda",
    searchingForWorkflows: "Buscando flujos de trabajo ...",
    secondsAgo: "Hace 0 segundos | Hace 1 segundo | Hace {count} segundos",
    minutesAgo: "Hace 0 minutos | Hace 1 minuto | Hace {count} minutos",
    hoursAgo: "Hace 0 horas | Hace 1 hora | Hace {count} horas",
    daysAgo: "Hace 0 dias | Hace 1 día | Hace {count} días",
    monthsAgo: "Hace 0 meses | Hace 1 mes | Hace {count} meses",
    yearsAgo: "Hace 0 años | Hace 1 año | Hace {count} años",
    sAgo: "{count} s",
    minAgo: "{count} min",
    hAgo: "{count} h",
    dAgo: "{count} d",
    mAgo: "{cuenta} m",
    yAgo: "{count} a",
    renameWorkflow: "Cambiar nombre del flujo de trabajo",
    moveWorkflowTo: "Mover el flujo de trabajo a",
    duplicateWorkflow: "Flujo de trabajo duplicado",
    unpublishWorkflow: "Despublicar flujo de trabajo",
    shareKnowhow: "Compartir conocimientos",
    or: "O",
    clickHere: "haga clic aquí",
    andCopyMsg: "y copie el enlace URL para enviar por correo electrónico",
    successfulSharing: "Compartido con éxito.",
    send: "Enviar",
    typeHereAMessage: "Escriba aquí un mensaje",
    wellDone: "Bien hecho",
    numMore: "{count} Más",
    moveWorkflow: "Mover el flujo de trabajo",
    currentWorkspace: "Espacio de trabajo actual",
    whereToMsg: "¿A dónde quiere mover este flujo de trabajo?",
    move: "Mover",
    alertSuccessfullyMoved: "Movió con éxito el flujo de trabajo.",
    alertFailedMoved: "Error al mover el flujo de trabajo.",
    noNotificationsMsg: "actualmente no tiene notificaciones",
    betaSearch: "Tenga en cuenta que la sugerencia de Stephanie todavía está en beta ...",
    relevantSteps: "Pasos relevantes:",
    relevance: "Relevancia",
    resultsFor: "{num} resultados para <b>&ldquo;{input}&rdquo;</b>",
    resultFor: "{num} resultado para <b>&ldquo;{input}&rdquo;</b>",
    byPublisher: "Por Editor",
    alertFailedShareWorkflow: "No se pudo compartir el flujo de trabajo.",
  },
  new: {
    newWorkflow: "Nuevo flujo de trabajo",
    uploadVideo: "Subir video",
    recordScreen: "Capturar pantalla",
    replaceVideoSubtitle: "Arrastre o haga clic para reemplazar su video.",
    recordScreenSubtitle: "Stephanie está lista para comenzar a grabar su pantalla.",
    workflowName: "Nombre del flujo de trabajo",
    workflowLanguage: "Idioma de flujo de trabajo",
    addToWorkspace: "Añadir a espacio de trabajo",
    upload: "Subir",
    startRecording: "Iniciar grabación",
    cancel: "Cancelar",
    wrongUploadFile: "Tipo de archivo no admitido. Por favor, seleccione el siguiente tipo de archivo:",
    limitFileDuration: "La duración del archivo supera los {duration} MIN. Por favor, utilice la aplicación DeepHow Uploader para Windows",
    limitFileDurationGen2: "La duración del archivo supera los {duration} MIN.",
    uploadingVideo: "Cargar video",
    uploadingVideoSubtitle: "Por favor, espere mientras Stephanie sube su video.",
    renderingVideo: "Procesando / Guardando Video",
    renderingVideoSubtitle: "Por favor, espere mientras Stephanie procesa su video.",
    almostDone: "¡Casi terminamos!",
    uploadingStep1: "Paso 1 de 2 - Analizando el flujo de trabajo ...",
    uploadingStep2: "Subiendo el video ...",
    successSubtitle: "Su flujo de trabajo fue cargado exitosamente",
    goToEditor: "Ir al editor",
    goToContentManger: "Ir al administrador de contenido",
    uploadingAlert: "Cambiar o cerrar la pestaña del navegador puede causar errores de carga.",
    recordingScreen: "Pantalla de grabación",
    support15Min: "Tenga en cuenta que la captura de la pantalla admite hasta <b>15 min</ b>.",
    hereWeGo: "¡Aquí vamos!",
    remember15Min: "Recuerde que tiene 15 min.",
    windowsAppAvailable: "La aplicación de Windows <br> ya está disponible.",
    windowsAppAvailableNews: "La aplicación DeepHow Uploader ya está disponible para Windows. Ahora puede cargar archivos más grandes y también grabar la pantalla.",
    downloadNow: "¡Descargar ahora!",
    deepHowWindowsApp: "aplicación DeepHow para Windows",
    downloadAndInstalltheWindowsApp: " Descarga e instala la aplicación Windows.",
    notAllowedToInstall: "Si no tiene permitido instalar la aplicación",
    downloadThisVersion: " descargue esta versión",
    and: " y ",
    watchThisVideo: "<span> mire este video </span> sobre cómo usarla.",
    // Added on 2022/01/19
    abiAuthorizationLabel: "Por la presente, reconozco que todos los individuos en este video han firmado la autorización para <span> fotografía / grabación de video </span> y de publicacion específica de mi país.",
    abiPrivacyLabel: "Por la presente declaro que he leído y entendido la <span>Declaración de Privacidad de fotografía / grabación de video</span> de ABinBev.",
    // Added on 2022/06/15
    importFromZoom: "Importar desde Zoom",
    importFromZoomSteps: "Paso {step} de {totalStep}",
    selectVideosFrom: "Seleccionar videos",
    videoSelected: "<span> 0 </span> video seleccionado | <span> 1 </span> video seleccionado | <span> {count} </span> videos seleccionados",
    workflowInfo: "Información del flujo de trabajo",
    newImport: "Nueva importación",
    orBackToEditor: "o de vuelta al <span>Editor</span>",
    importFromZoomSuccessMsg: "Sus videos comenzarán a subir pronto, puede pasar un tiempo antes de que estén disponibles para la edición.",
    zoomFailedUploadMsg: "No se pudo subir el video de Zoom",
    zoomPeriodLastMonth: "el mes pasado",
    zoomPeriodPastMonth: "{n} últimos meses",
    loadingZoomRecordings: "Cargando videos",
    zoomNoRecordingsInfo: "No se encontraron grabaciones para el período seleccionado.",
    yourVideoExceedTwoHours: "Su video supera las 2 horas y no se puede cargar.",
    yourVideoExceed3GSize: "Su video supera los 3 GB y no se puede cargar.",
    yourVideoExceedTwoHoursAnd3GSize: "Su video supera las 2 horas, 3GB y no se puede cargar.",
    videoUploadWarningMessage: "Arrastre o haga clic para importar su video (solo MP4 o WebM). El tamaño máximo es de 3 GB y hasta 2 horas.",
    videoUploadWarningMessageGen2: "Arrastre o haga clic para importar su video (solo MP4 o WebM) máximo. hasta 40 minutos.",
  },
  auth: {
    aboutDeephow: "Acerca de DeepHow",
    authTitle: "Primera solución de IA para la capacitación de oficios calificados.",
    authIntro: "DeepHow cierra la brecha de habilidades en la manufactura, el servicio y la reparación a través de una plataforma de aprendizaje de IA basada en videos interactivos.",
    allRightReserved: "Todos los derechos reservados",
    logIn: "Iniciar sesión",
    logOut: "Cerrar sesión",
    logInSubtitle: "Por favor, ingrese sus datos para iniciar sesión.",
    logInSubtitleSso: "Por favor, haga clic a continuación para iniciar sesión.",
    emailOrId: "Correo electrónico o ID de empleado",
    hello: "Hola,",
    passwordSubtitle: "Por favor, ingrese su contraseña.",
    password: "Contraseña",
    forgotPassword: "Olvidó la contraseña",
    forgotPasswordSubtitle: "Ingrese su correo electrónico, le enviaremos un enlace para crear una nueva contraseña.",
    email: "Correo electrónico",
    resetPassword: "Restablecer la contraseña",
    alertResetSuccess: "Por favor revise su bandeja de entrada de correo electrónico.",
    alertResetFailed: "Error al recuperar la contraseña.",
    alertInvalidId: "ID de empleado no válido.",
    successMsg: "Revise su correo electrónico y haga clic en el enlace para crear una nueva contraseña para su cuenta.",
    logInWithSSO: "Inicia sesión con SSO",
    loggingInWithSSO: "Iniciar sesión con SSO ...",
    logInWithGoogle: "Iniciar sesión con Google",
    logInWithMicrosoft: "Iniciar sesión con Microsoft",
    loadingSso: "Cargando SSO ...",
    about: "Acerca de",
    // 12/17/2021 iframe
    loginToYourAccount: "<b>Contectarse</b>a su cuenta",
    pleaseInputYourPassword: "Por favor ingrese su contraseña.",
    backToLogin: "Volver al log in.",
    // added 03/02/2022
    wrongPassword: "Contraseña incorrecta.",
    // Shared Account
    sharedDevice: "Dispositivo compartido",
    loginExistingAccount: "Iniciar sesión con una cuenta existente",
    createNewAccount: "Crea una cuenta nueva",
    noUsersMsg: "Ningún usuario ha iniciado sesión en este dispositivo compartido...",
    insertIDMsg: "Inserte su ID de empleado",
    createIdUserMsg: "Estamos <b>casi allí</b>! <br>Por favor complete sus datos a continuación.",
    confirmEmployeeID: "Confirmar ID de empleado",
    welcomeToDeepHow: "Bienvenido a DeepHow, <b>{name}</b>",
    confirmWorkspaceTeamMsg: "¡Confirme su espacio de trabajo y seleccione su equipo!",
    LetsGetStarted: "¡Empecemos!",
    // added 05/26/2022
    useSsoLogin: "El inicio de sesión de correo electrónico/contraseña no está habilitado para su organización. Inicie sesión con SSO.",
    // added 02/17/2023
    cookieAlert: "Acepto el uso de cookies como se detalla en nuestro <a href='{pdfUrl}' target='_blank'> Política de privacidad </a>.",
    useCookie: "¡Usamos cookies!",
    useCookieContext: "¡Usamos cookies para asegurarnos de que tendrá una experiencia increíble con DeepHow!",
    privacyPolicy: "Política de privacidad",
    acceptCookies: "Acepto galletas"
  },
  rules: {
    required: "Requerido.",
    invalidEmail: "Email inválido.",
    noSpace: "No se pueden agregar espacios.",
    validPassword: "La contraseña debe tener al menos 6 caracteres, incluir número, y letras mayúsculas y minúsculas.",
    maxPassword: "Máximo 16 caracteres.",
    mustMatch: "Contraseña debe coincidir con.",
    max25: "Máximo 25 caracteres.",
    max90: "Máximo 90 caracteres.",
    max100: "Máximo 100 caracteres.",
    max140: "Máximo 140 caracteres.",
    numMaxCharacters: "Máximo 0 carácter. | Máximo 1 carácter. | Máximo {count} caracteres.",
    min6: "Mínimo 6 carácteres.",
    noWhiteSpace: "Ingrese el texto como título.",
    noAllSpace: "No se pueden usar espacios como nombre.",
    invalidEmployeeID: "ID de empleado no válido.",
    invalidUrl: "URL invalida",
    mustMatchId: "El ID de empleado debe coincidir.",
    canNotEnterSharedAccount: "No puede ingresar el ID de una cuenta compartida.",
    validPasswordMin10: "La contraseña debe ser de al menos 10 caracteres, incluir número, carácter especial, letra inferior y superior.",
    invalidName: "Nombre inválido.",
  },
  alert: {
    success: "Éxito",
    info: "Información",
    alert: "Alerta",
    error: "Error",
    dontShowThisMsgAgain: "No mostrar este mensaje nuevamente",
    unexpectedError: "Se produjo un error inesperado. Vuelva a intentarlo o comuníquese con DeepHow para obtener ayuda.",
  },
  skills: {
    skillsMatrix: "Matriz de habilidades",
    createSkill: "Crear habilidad",
    skillsCreated: "Habilidades creadas",
    skillsPublished: "Habilidades publicadas",
    skillsIn: "Habilidades en",
    hintSearchSkills: "Buscar habilidades ...",
    lastUpdated: "Última actualización",
    skillsPerPage: "Habilidades por página",
    usersAssigned: "Usuarios asignados",
    status: "Estado",
    createdBy: "Por",
    skillsSelected: "habilidades seleccionadas | habilidad seleccionada | habilidades seleccionadas",
    updatedBy: "Por",
    deleteSkill: "Eliminar habilidad",
    popupMsgDeleteSkill: "¿Está seguro de que quiere eliminar esta habilidad?",
    deleteSkills: "Eliminar habilidades",
    popupMsgDeleteSkills: "¿Está seguro de que quiere eliminar estas habilidades?",
    clearAll: "Limpiar todo",
    clearAllFilters: "Limpar todos los filtros",
    clearFilter: "Limpiar filtro",
    delete: "Eliminar",
    youAreViewing: "Está viendo",
    numWorkflows: "0 flujos de trabajo | 1 flujo de trabajo | {count} flujos de trabajo",
    searchIn: "Buscar en",
    loadingSkillMatrix: "Cargando matriz de habilidades ...",
    createSkillSubtitle: "Seleccione un nombre y dónde desea cargarlo.",
    skillName: "Nombre de la habilidad",
    create: "Crear",
    editSkill: "Editar habilidad",
    popupMsgEditSkill: "Si entra en el modo de edición, su habilidad será despublicada automáticamente, ¿está seguro de que desea continuar?",
    publishSkill: "Publicar habilidad",
    popupMsgPublishSkill: "Va a publicar la habilidad y será visible para los usuarios, ¿está seguro de que desea continuar?",
    alertFailedSaveSkill: "Error al guardar habilidad",
    alertSkillSuccessfullyPublished: "Su habilidad fue publicada con éxito.",
    alertSkillSuccessfullyUnpublished: "Su habilidad ya no está publicada.",
    alertSkillSuccessfullySaved: "Su habilidad fue guardada con éxito",
    alertFailedSavedSkill: "Error al guardar habilidad",
    alertFailedPublishSkill: "Error al publicar habilidad",
    alertSkillSuccessfullyDeleted: "Su habilidad fue eliminada con éxito",
    alertSkillFailedDeleted: "Error al eliminar habilidad",
    content: "Contenido",
    addContent: "Agregar contenido",
    workflowDeleted: "Flujo de trabajo borrado",
    workflowsSelected: "flujos de trabajo seleccionados | flujo de trabajo seleccionado | flujos de trabajo seleccionados",
    selectAll: "Seleccionar todo",
    remove: "Eliminar",
    noContentMsg: "Aún no hay contenido en la habilidad.",
    assignUsers: "Asignar usuarios",
    noUserMsg: "Aún no hay usuarios asignados a su entrenamiento.",
    numUsers: "<b>0</b> usuarios | <b>1</b> usuario | <b>{count}</b> usuarios",
    assignedOn: "Asignado en",
    certifiedBy: "Certificado por",
    progress: "Progreso",
    aletUserSuccessfullyRemoved: "El usuario fue eliminado con éxito.",
    removeUser: "Eliminar usuario",
    popupMsgRemoveUserFromSkill: "¿Está seguro de que desea eliminar a <b>{name}</b> de esta habilidad",
    assign: "Asignar",
    successfulAssigned: "Asignado con éxito",
    AddedToSkill: "Añadido a la habilidad.",
    loadingSkills: "Cargando habilidades ...",
    searchingForSkills: "Buscando habilidades ...",
    toDo: "Pendientes",
    completed: "Completados",
    assignedBy: "Asignado por",
    min: " minuto",
    nextPart: "Siguiente",
    noSkillsAssignedMsg: "Aún no tiene habilidades asignadas.",
    noSkillsAssignedMsg2: "Le avisaremos cuando tenga algo nuevo!",
    noCompletedSkillsMsg: "Aún no tiene habilidade completadas.",
    noCompletedSkillsMsg2: "¡Comencemos con el aprendizaje de habilidades!",
    noDataSkillsMatrix: "Aún no tiene datos en la matriz de habilidades ...",
    certificate: "Certificado",
    certificateMsg: "Los usuarios obtendrán un certificado al finalizar esta habilidad con éxito.",
    selectCertificateMsg: "Seleccione a los usuarios que validarán el certificado:",
    validateSkill: "Validar habilidad",
    validateSkillMsg: "¿Está seguro de que desea validar a <b>{name}</b> en la habilidad {skill}? <br/> No podrá deshacer esto.",
    yesValidate: "Si validar",
    noValidate: "No",
    certifier: "Certificador",
    signaturesOn: "sobre",
    teamProgress: "Progreso del equipo",
    openTeam: "Ver equipo",
    removeCertificate: "Eliminar el certificado",
    removeCertificateMsg: "Eliminará a todos los usuarios que puedan validar el certificado, ¿está seguro?",
    yesRemove: "Sí, eliminar.",
    mustAddUserMsg: "* El certificado no se activará si no se agregan usuarios.",
    skillCover: "Portada de Habilidades",
    alertSuccessfullyUpdatedSkillCover: "La portada de la habilidad fue actualizada con éxito.",
    alertFailedUpdatedSkillCover: "Error al actualizar la portada de la habilidad.",
    closeSkill: "Cerrar habilidad",
    allWorkflowsDeleted: "Todos los flujos de trabajo se eliminan en esta habilidad.",
    waitingForValidation: "Esperando validación",
    validatedBy: "Validado por:",
    skillWasUnpublished: "La habilidad fue despublicada.",
    skillWasUnpublishedMsg: "Le haré saber cuándo esta habilidad esté disponible de nuevo. Por ahora, todo esta bien!",
    notAssignedToSkill: "No está asignado a esta habilidad.",
    notAssignedToSkillMsg: "Lo sentimos, esta página solo está disponible para los usuarios que han sido asignados a esta habilidad.",
    remindPendingUsers: "Recordar a los usuarios pendientes",
    alertSuccessRemindPendingUsers: "Recordó con éxito a los usuarios pendientes.",
    alertFailedRemindPendingUsers: "Error al recordar a los usuarios pendientes.",
    alertNoPendingUsers: "No se han encontrado usuarios pendientes.",
    numVideo: "0 videos | 1 video | {count} videos",
    // Skill Deadline
    deadline: "Fecha límite",
    deadlineMsg: "Establezca un período de tiempo global para que los usuarios terminen de ver esta habilidad. Después de la fecha límite, los usuarios pendientes serán notificados ...",
    deadlineForThisSkill: "Active la fecha límite para esta habilidad.",
    certificateForThisSkill: "Active el certificado para esta habilidad.",
    deadlineStartsMsg: "El período comienza a contar teniendo en cuenta la fecha en que se asignó el usuario.",
    selectAPeriod: "Seleccione un período",
    finished: "Finalizado",
    editDeadline: "Editar fecha límite",
    editDeadlineMsg: "Al editar la fecha límite, la anterior ya no será válida, y el nuevo período se aplicará a todos los usuarios para esta habilidad. <br> <br> ¿Estás seguro de que desea continuar?",
    alertSuccessNewDeadline: "La nueva fecha límite se ha aplicado para todos los usuarios.",
    alertSuccessRemoveDeadline: "La fecha límite de la habilidad se eliminó con éxito.",
    alertFailedDeadline: "No se pudo actualizar la fecha límite de la habilidad.",
    dueToday: "Vence hoy",
    turnOffDeadlineMsg: "Al apagar la fecha límite, los datos anteriores se perderán. La próxima vez que esté activada, restablecerá todas las fechas de inicio del usuario. <br> <br> ¿Estás seguro de que quieres continuar?"
  },
  time: {
    numDays: "0 días | 1 día | {count} días",
    numWeeks: "0 semana | 1 semana | {count} semanas",
    numMonths: "0 mes | 1 mes | {count} meses",
    numDaysLeft: "0 días restantes | Falta 1 día | {count} días restantes",
    numDaysDelayed: "0 días retrasado | 1 días retrasado | {count} días retrasados",
  },
  notification: {
    sharedWorkflow: "compartió un flujo de trabajo",
    assignedNewSkill: "compartió una habilidad",
    sentAnnouncement: "enviado un anuncio",
    needValidation: "necesita su validación",
    noNotificationsMsg: "actualmente no tiene notificaciones",
    justNow:"Justo ahora",
    updatedSkill: "actualizado la habilidad",
    unpublishedSkill: "despublicado la habilidad",
    sentAReminder: "le envió un recordatorio sobre esta habilidad. Comience a ver para que pueda rastrear su progreso.",
    assignedNewSkillDue: "le asignó una nueva habilidad. Tiene <span>{time}</span> para terminar de verla.",
    delayedSkillMsg: "Tiene una <span>habilidad retrasada</span>. ¡Comience a mirar ahora para seguir en camino!",
    daysLeftMsg: "Solo le quedan <span>{time}</span> para terminar de ver esta habilidad. ¡Dese prisa para no retrasarse!",
    askToReviewWorkflow: "solicitó la revisión de un flujo de trabajo.",
    reactedWorkflowMsg: "<span>{user}</span> reaccionó a su flujo de trabajo.",
    reactedWorkflowMsgTwoUsers: "<span>{user1} y {user2}</span> reaccionaron a su flujo de trabajo.",
    reactedWorkflowMsgMoreUsers: "<span>{user1}, {user2} y {count} otros</span> reaccionaron a su flujo de trabajo.",
  },
  mediaPicker: {
    insertFullWorkflow: "Insertar el flujo de trabajo completo",
    selectWorkflowStep: "Seleccione el paso de flujo de trabajo",
    stepSelected: " Paso seleccionado"
  },
  zoomIntegration: {
    settingsTitle: "Configuraciones de Zoom",
    settingsSubtitle: "Integración",
    deleteCopy: "Eliminar la copia de la grabación del video de Zoom Cloud",
    goToDeephow: "Ir a Deephow",
    landingSuccess: "<b>DeepHow Know-How Video App</b> instalada con éxito.",
    landingError: "Se produjo un error al instalar <b>DeepHow Know-How Video App</b>. Póngase en contacto con <a href = 'mailto: service@deephow.com'>service@deephow.com</a> para obtener soporte.",
    landingWarning: "La integración con Zoom no está habilitada para su organización.",
  },
  reactions: {
    likeIt: "Me gusta",
    greatContent: "Excelente contenido",
    wellPresented: "Bien presentado",
    niceProduction: "Buena producción",
  },
  maven: {
    // TODO: maven chat added on 03/15/2023
    mavenChat: "Chat",
    searchInWorkspace: "Search in {workspace}",
    showReferences: "Show references",
    hideReferences: "Hide references",
    helloMessage: "Hello {userName},",
    assistMessage: "How can I assist you today?",
    askMeAnything: "Ask me anything...",
    tryOtherQuestionMessage: "You can try asking other question.",
    audioIsReady: "Please recording your audio clip in English...",
    audioLimitationTime: "The audio limitation time is 3 mins.",
    clearChatHistory: "Clear chat history",
    newLineHint: "new line: shift + Enter",
    // TODO: maven map added on 04/14/2023
    map: "Map",
    resetMap: "reset map",
    filterWorkspaces: "filter workspaces",
    showQuestions: "show questions",
    hideQuestions: "hide questions",
    showClusters: "show clusters",
    hideClusters: "hide clusters",
    question: "Question",
    loadingMap: "Loading map...",
    clearChatHistoryConfirmation: "Clearing the chat history will start a new chat. Are you sure?",
    clearConfirm: "Yes, clear",
  },
  $vuetify: {
    dataIterator: {
      rowsPerPageText: "Elementos por página:",
      rowsPerPageAll: "Todos",
      pageText: "{0}-{1} de {2}",
      noResultsText: "No se encontraron registros coincidentes",
      nextPage: "Siguiente página",
      prevPage: "Pagina anterior"
    },
    dataTable: {
      rowsPerPageText: "Filas por página:"
    },
    noDataText: "Datos no disponibles",
    carousel: {
      prev: "Anterior",
      next: "Siguiente"
    }
  }
};
