<template>
  <div class="admin-organization">
    <div class="adminOrganization__title">
      <d-workspace-group-breadcrumb hideArrow size="large" isShowOrganization />
      <div class="adminOrganization__tabs">
        <d-tabs
          v-model="currentTab"
          autoWidth
          :tabNames="tabNames"
          light
          height="48"
          :autotest="autotestTabs"
          :class="{ 'adminOrganization__tabs-focus-settings': showPart === 'settings' }"
          @input="clickTab"
        >
          <template slot="tabName" slot-scope="{ item, index }">
            <!-- TODO: Refactor `component` for `AdminSettingsMenu` of tabs. -->
            <component
              v-if="index === tabNames.length - 1"
              :is="item"
              :settingPage="settingPage"
              @setSettingPage="setSettingPage"
              class="adminOrganization__tabs__settingsMenu"
            ></component>
            <span v-else>{{ item }}</span>
          </template>
        </d-tabs>
      </div>
    </div>

    <!-- All Users table -->
    <div v-if="showPart === 'allUsers'" class="tab-div">
      <div class="tab-title-row">
        <p class="tab-title-new text-no-wrap" autotest="users-count">
          <span v-if="searchUser.length > 0">{{ $t("analytics.searchUser") }}</span>
          <span v-else>
            <b v-if="currentStatus == 'active'">{{ totalActive }}</b>
            <b v-if="currentStatus == 'inactive'">{{ totalInactive }}</b>
            {{ $t("all.users") }}
          </span>
        </p>
        <v-spacer></v-spacer>
        <v-btn
          round
          depressed
          class="text-capitalize white--text body-2 create-btn"
          color="dBlue"
          @click="showForm = 'invite-user'"
          autotest="invite-user-btn"
          >{{ $t("admin.addUser") }}</v-btn
        >
      </div>

      <div class="table-search-row">
        <div>
          <d-table-search
            v-model="searchUser"
            :placeholder="$t('analytics.searchUser')"
            @input-search="debounceHandleFindUser($event)"
            autotest="user-search-bar"
          >
            <div v-if="searchUser.length == 0" class="filter-div">
              <user-status-filter
                autotest="users-status-filter"
                :statusDictionary="statusDictionary"
                :filterStatus="filterStatus"
                :currentStatus="currentStatus"
                @changeCurrentStatus="changeCurrentStatus"
              ></user-status-filter>
            </div>
          </d-table-search>
        </div>
      </div>

      <!-- active users table -->
      <div v-show="currentStatus === 'active'" class="table-div" @click="viewUserEmail = ''">
        <d-data-table
          padding-bottom
          infinite-scroll
          :loading="isLoading.usersData || isSearchingUsers"
          :headers="userTableHeaders"
          :items="activeUsersData"
          :noSearchResults="noSearchResults && searchUser.length > 0"
          :loadedAllData="activeUsersData.length === totalActive"
          :showUserCard="viewUserEmail.length > 0"
          @load-more="getMoreUsers"
          class="d-data-table"
        >
          <template slot="no-data" v-if="!this.searchUser">
            <span>{{ $t("admin.msgNoMembersYet") }}</span>
          </template>
          <template slot="items" slot-scope="props">
            <td class="text-xs-left text-capitalize text-no-wrap">
              <div class="avatar-div">
                <d-avatar :size="32" :userId="props.item.id" />
                <div v-if="props.item.role === 'admin-organization'" class="org-admin-icon">
                  <icon-base width="14" height="14" color="white">
                    <d-icon-org-admin />
                  </icon-base>
                </div>
                <div v-if="props.item.shared" class="org-admin-icon shared-icon">
                  <icon-base width="14" height="14" color="white">
                    <d-icon-shared-user />
                  </icon-base>
                </div>
              </div>
              <d-tooltip-show-all
                :text="props.item.displayName"
                class="ml-3"
                autotest="username-display"
              ></d-tooltip-show-all>
            </td>
            <td class="text-xs-left" autotest="user-email-display">
              <span v-if="props.item.passwordless">-</span>
              <span v-else>{{ props.item.email }}</span>
            </td>
            <td class="text-xs-left" autotest="employee-id-display">
              <d-tooltip-show-all :text="props.item.employeeId || '-'"></d-tooltip-show-all>
            </td>
            <td class="text-xs-left" autotest="last-activity-display">{{ props.item.lastActivityString }}</td>
            <td class="text-xs-left text-capitalize text-no-wrap" autotest="user-status-display">
              {{ statusDictionary[props.item.status] }}
            </td>
            <td class="new-btns-td">
              <div class="new-btns-td-div center-horizontally">
                <div
                  v-if="hasCustomUserAttributes"
                  class="view-button-new btn-hover"
                  :class="{ 'hide-view-btn': props.item.passwordless }"
                  @mouseover="mouseoverViewUserCard(props.item.email)"
                  @mouseleave="enabledToShowUserCard = true"
                  @click.stop="clickViewUserCard(props.item.email)"
                >
                  <icon-base width="22" height="22" opacity="0.8">
                    <d-icon-preview />
                  </icon-base>
                  <div @click="enabledToShowUserCard = true">
                    <d-admin-user-card
                      v-if="viewUserEmail === props.item.email && props.item.email !== ''"
                      :userData="props.item.customAttributes"
                      :customUserAttributes="customUserAttributes"
                    ></d-admin-user-card>
                  </div>
                </div>
                <d-tooltip v-if="!props.item.invitation" :text="$t('admin.tooltipEditUser')" class="tooltip-btn-new">
                  <button @click="setUserEditor(props.item)" class="tooltip-btn-btn btn-hover" autotest="edit-user-btn">
                    <icon-base width="22" height="22">
                      <d-icon-pen />
                    </icon-base>
                  </button>
                </d-tooltip>
                <d-tooltip
                  v-if="props.item.status == 'invited' || props.item.status == 'invitation-expired'"
                  :text="$t('admin.tooltipResendInvite')"
                  class="tooltip-btn-new"
                >
                  <button
                    class="tooltip-btn-btn btn-hover"
                    @click="setResendInvitation(props.item)"
                    autotest="resend-invite-btn"
                  >
                    <icon-base width="22" height="22">
                      <d-icon-resend />
                    </icon-base>
                  </button>
                </d-tooltip>
                <d-tooltip
                  v-if="props.item.status == 'invited' || props.item.status == 'invitation-expired'"
                  :text="$t('admin.tooltipCancelInvite')"
                  class="cancel-invite-btn-new"
                >
                  <button
                    class="tooltip-btn-btn btn-hover"
                    @click="setDeleteInvitation(props.item)"
                    autotest="cancel-invite-btn"
                  >
                    <icon-base width="18" height="18">
                      <d-icon-close2 />
                    </icon-base>
                  </button>
                </d-tooltip>
                <v-menu v-if="showMoreBtn(props.item)" transition="slide-y-transition" bottom left offset-y>
                  <button slot="activator" class="tooltip-btn-btn btn-hover" autotest="more-action-btn">
                    <icon-base width="20" height="20">
                      <d-icon-more />
                    </icon-base>
                  </button>
                  <v-list dense light>
                    <v-list-tile
                      v-if="canUpgradeToOrgAdmin(props.item)"
                      @click="showUpgradeOrgPopup(props.item)"
                      autotest="upgrade-org-admin-btn"
                    >
                      <v-list-tile-title class="body-1">{{ $t("admin.upgradeToOrgAdmin") }}</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile
                      v-if="canDowngradeOrgAdmin(props.item)"
                      @click="showDowngradeOrgPopup(props.item)"
                      autotest="downgrade-org-admin-btn"
                    >
                      <v-list-tile-title class="body-1">{{ $t("admin.downgradeOrgAdmin") }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </template>
        </d-data-table>
      </div>

      <!-- Inactive users table -->
      <div v-show="currentStatus === 'inactive'" class="table-div" @click="viewUserEmail = ''">
        <d-data-table
          padding-bottom
          infinite-scroll
          :loading="false"
          :headers="userTableHeaders"
          :items="inactiveUsersData"
          :noSearchResults="false"
          :loadedAllData="false"
          :showUserCard="viewUserEmail.length > 0"
          @load-more="getMoreInactiveUsers"
          class="d-data-table"
        >
          <template slot="no-data" v-if="!this.searchUser">
            <span>{{ $t("admin.msgNoMembersYet") }}</span>
          </template>
          <template slot="items" slot-scope="props">
            <td class="text-xs-left text-capitalize text-no-wrap">
              <div class="avatar-div">
                <d-avatar :size="32" :userId="props.item.id" />
                <div v-if="props.item.role === 'admin-organization'" class="org-admin-icon">
                  <icon-base width="14" height="14" color="white">
                    <d-icon-org-admin />
                  </icon-base>
                </div>
                <div v-if="props.item.shared" class="org-admin-icon shared-icon">
                  <icon-base width="14" height="14" color="white">
                    <d-icon-shared-user />
                  </icon-base>
                </div>
                <!-- DIconSharedUser -->
              </div>
              <d-tooltip-show-all
                :text="props.item.displayName"
                class="ml-3"
                autotest="username-display"
              ></d-tooltip-show-all>
            </td>
            <td class="text-xs-left" autotest="user-email-display">
              <span v-if="props.item.passwordless">-</span>
              <span v-else>{{ props.item.email }}</span>
            </td>
            <td class="text-xs-left" autotest="employee-id-display">
              <d-tooltip-show-all :text="props.item.employeeId || '-'"></d-tooltip-show-all>
            </td>
            <td class="text-xs-left" autotest="last-activity-display">{{ props.item.lastActivityString }}</td>
            <td class="text-xs-left text-capitalize text-no-wrap" autotest="user-status-display">
              {{ statusDictionary[props.item.status] }}
            </td>
            <td class="new-btns-td">
              <div class="new-btns-td-div center-horizontally">
                <div
                  v-if="hasCustomUserAttributes"
                  class="view-button-new btn-hover"
                  :class="{ 'hide-view-btn': props.item.passwordless }"
                  @mouseover="mouseoverViewUserCard(props.item.email)"
                  @mouseleave="enabledToShowUserCard = true"
                  @click.stop="clickViewUserCard(props.item.email)"
                >
                  <icon-base width="22" height="22" opacity="0.8">
                    <d-icon-preview />
                  </icon-base>
                  <div @click="enabledToShowUserCard = true">
                    <d-admin-user-card
                      v-if="viewUserEmail === props.item.email && props.item.email !== ''"
                      :userData="props.item.customAttributes"
                      :customUserAttributes="customUserAttributes"
                    ></d-admin-user-card>
                  </div>
                </div>
                <d-tooltip v-if="!props.item.invitation" :text="$t('admin.tooltipEditUser')" class="tooltip-btn-new">
                  <button @click="setUserEditor(props.item)" class="tooltip-btn-btn btn-hover" autotest="edit-user-btn">
                    <icon-base width="22" height="22">
                      <d-icon-pen />
                    </icon-base>
                  </button>
                </d-tooltip>
                <d-tooltip
                  v-if="props.item.status == 'invited' || props.item.status == 'invitation-expired'"
                  :text="$t('admin.tooltipResendInvite')"
                  class="tooltip-btn-new"
                >
                  <button
                    class="tooltip-btn-btn btn-hover"
                    @click="setResendInvitation(props.item)"
                    autotest="resend-invite-btn"
                  >
                    <icon-base width="22" height="22">
                      <d-icon-resend />
                    </icon-base>
                  </button>
                </d-tooltip>
                <d-tooltip
                  v-if="props.item.status == 'invited' || props.item.status == 'invitation-expired'"
                  :text="$t('admin.tooltipCancelInvite')"
                  class="cancel-invite-btn-new"
                >
                  <button
                    class="tooltip-btn-btn btn-hover"
                    @click="setDeleteInvitation(props.item)"
                    autotest="cancel-invite-btn"
                  >
                    <icon-base width="18" height="18">
                      <d-icon-close2 />
                    </icon-base>
                  </button>
                </d-tooltip>
              </div>
            </td>
          </template>
        </d-data-table>
      </div>
    </div>

    <!-- Workspace groups table -->
    <div v-if="$access.teams && showPart === 'workspaceGroups'" class="admin-setting-div">
      <admin-workspace-groups
        v-if="!isDisplayingWorkspaceGroup"
        :drawerOpenPopupStyle="drawerOpenPopupStyle"
      ></admin-workspace-groups>

      <admin-workspace-group
        v-if="isDisplayingWorkspaceGroup"
        :drawerOpenPopupStyle="drawerOpenPopupStyle"
        :groupDictionary="groupDictionary"
        @close-single-team="closeSingleTeam()"
      ></admin-workspace-group>
    </div>

    <!-- workspaces table -->
    <div v-if="$access.teams && showPart === 'workspaces' && isGroupsDataReady" class="admin-setting-div">
      <admin-workspace-group-workspaces
        :drawerOpenPopupStyle="drawerOpenPopupStyle"
        :workspaceList="workspaceList"
        isOnOrganizationLevel
      />
    </div>

    <!-- Teams table -->
    <div v-if="$access.teams && showPart === 'teams' && isGroupsDataReady" class="admin-setting-div">
      <admin-workspace-teams
        :drawerOpenPopupStyle="drawerOpenPopupStyle"
        :workspaceDictionary="groupDictionary"
        :workspacesForOldVersion="groupsData"
        isOnOrganizationLevel
      />
    </div>

    <!-- Business table -->
    <div v-if="showPart === 'business'" class="tab-div">
      <div class="tab-title-row">
        <p class="tab-title text-no-wrap" autotest="business-count">
          <b>{{ businessData.length }}</b>
          {{ $t("all.business") }}
        </p>
        <v-spacer></v-spacer>
        <v-btn
          round
          depressed
          class="text-capitalize white--text body-2 create-btn"
          color="dBlue"
          @click="showForm = 'create-business'"
          autotest="create-business-btn"
          >{{ $t("admin.createBusiness") }}</v-btn
        >
      </div>
      <div class="table-wrap">
        <d-data-table
          add-search
          local-search
          initial-sort
          :headers="businessTableHeaders"
          :loading="isLoading.businessData || isLoading.groupsData"
          :items="isLoading.businessData || isLoading.groupsData ? [] : businessTableData"
          :placeholder="$t('admin.hintSearchBusiness')"
          searchAutotest="business-search-bar"
        >
          <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
          <template slot="no-data">
            <span>
              <!-- This Organization does not have Business yet. -->
              {{ $t("admin.msgNoBusinessYet") }}
            </span>
          </template>
          <template slot="no-results">
            <span>
              <!-- No matching records found in Business. -->
              {{ $t("admin.msgNoRecordsBusiness") }}
            </span>
          </template>
          <template slot="items" slot-scope="props">
            <td class="text-xs-left text-capitalize" autotest="business-name-display">{{ props.item.name }}</td>
            <td
              v-if="isSupportWorkspaceGroup"
              class="text-xs-left text-capitalize"
              autotest="workspace-group-name-display"
            >
              {{ props.item.workspaceGroupName }}
            </td>
            <td class="text-xs-left text-capitalize" autotest="workspace-name-display">
              {{ props.item.workspaceName }}
            </td>
            <td class="text-xs-right">
              <button
                class="table-editor-btn btn-hover"
                @click="setEditorFormData(props.item), (showForm = 'edit-business')"
                autotest="business-edit-btn"
              >
                <icon-base width="22" height="22">
                  <d-icon-pen />
                </icon-base>
              </button>
            </td>
          </template>
        </d-data-table>
      </div>
    </div>

    <template v-if="showPart === 'settings'">
      <admin-categories
        v-if="$access.categoriesCreation && settingPage === 'categories'"
        :drawerOpenPopupStyle="drawerOpenPopupStyle"
      ></admin-categories>

      <admin-languages v-if="$access.languages && settingPage === 'languages'"></admin-languages>

      <d-admin-permissions
        v-if="$access.permissions && settingPage === 'permissions'"
        :drawerOpenPopupStyle="drawerOpenPopupStyle"
      ></d-admin-permissions>

      <admin-master-password
        v-if="getter_has_employee_id_login && settingPage === 'master-password'"
        :drawerOpenPopupStyle="drawerOpenPopupStyle"
      ></admin-master-password>

      <d-admin-sso-mapping
        v-if="$access.ssoMapping && settingPage === 'sso-mapping'"
        :drawerOpenPopupStyle="drawerOpenPopupStyle"
      ></d-admin-sso-mapping>

      <admin-company-logo v-if="$access.companyLogo && settingPage === 'company-logo'" />
    </template>

    <!-- components for all users-->
    <admin-add-user-popup
      v-if="showForm === 'invite-user'"
      :style="drawerOpenPopupStyle"
      :showForm="showForm"
      :groupDictionary="groupDictionary"
      :groupsData="groupsData"
      @reloadUserData="reloadUserData()"
      @addUserData="addUserData($event)"
      @closeAdminInvite="showForm = ''"
      @invitedFailed="isInvitedFailed = true"
      @success-add-user="totalActive += 1"
    ></admin-add-user-popup>

    <admin-form-user-editor
      v-if="showForm === 'user-editor'"
      :style="drawerOpenPopupStyle"
      :parent="'admin-organization'"
      :formData="formData"
      :roleOptions="allRoleOptions"
      :userRole="userRole"
      :groupId="groupId"
      :groupDictionary="groupDictionary"
      @closeUserEditor="showForm = ''"
      @update-found-user="updateFoundUser($event, 'update')"
    ></admin-form-user-editor>

    <upgrade-to-org-admin-confirmation
      v-if="showForm === 'upgrade-to-org'"
      :style="drawerOpenPopupStyle"
      :formData="formData"
      @update-success="updateTolatestUserdataFromDb"
      @closePopup="(showForm = ''), (formData = {})"
    ></upgrade-to-org-admin-confirmation>

    <downgrade-org-admin-confirmation
      v-if="showForm === 'downgrade-org'"
      :style="drawerOpenPopupStyle"
      :formData="formData"
      @update-success="updateTolatestUserdataFromDb"
      @closePopup="(showForm = ''), (formData = {})"
    ></downgrade-org-admin-confirmation>

    <d-alert v-model="isInvitedFailed" type="alert" :message="$t('admin.alertFailedInviteUser')">
      <!-- Failed to invite user -->
    </d-alert>

    <d-alert v-model="isDeletedSuccess" type="success" :message="$t('admin.alertSuccessfullyInviteUser')">
      <!-- Successfully deleted the user. -->
    </d-alert>

    <d-alert v-model="isDeletedFailed" type="alert" :message="$t('admin.alertFailedDeleteUser')">
      <!-- Failed to delete user. -->
    </d-alert>

    <!-- components for business-->
    <admin-form-business-create-edit
      v-if="showForm === 'create-business' || showForm == 'edit-business'"
      :style="drawerOpenPopupStyle"
      :formData="formData"
      :showForm="showForm"
      :businessData="businessData"
      @closeCreateBusiness="(showForm = ''), (formData = {})"
      @reloadBusinessData="reloadBusinessData()"
      @addBusinessSuccess="addBusinessSuccess = true"
      @editBusinessSuccess="editBusinessSuccess = true"
    ></admin-form-business-create-edit>
    <d-alert
      v-model="addBusinessSuccess"
      type="success"
      :message="$t('admin.alertSuccessfullyCreatedBusiness')"
    ></d-alert>

    <d-alert
      v-model="editBusinessSuccess"
      type="success"
      :message="$t('admin.alertSuccessfullyEditedBusiness')"
    ></d-alert>

    <!-- delete Invite Confirmation -->
    <d-confirmation-popup
      v-model="deleteInviteConfirmation"
      light
      :title="$t('admin.deleteInvitation')"
      :cancelBtnName="$t('all.cancel')"
      :actionBtnName="$t('admin.popupBtnDelete')"
      :content="$t('admin.popupMsgDeleteInvitation') + formData.email + '?'"
      :autotest="{
        close: 'delete-invitation-popup-close-btn',
        action: 'delete-invitation-popup-yes-btn',
        cancel: 'delete-invitation-popup-cancel-btn',
      }"
      @clickActionBtn="deleteInvite()"
    ></d-confirmation-popup>

    <!-- resend invitation confirmation dialog -->
    <d-confirmation-popup
      v-model="resetSendInvitation"
      light
      :title="$t('admin.resendInvitation')"
      :cancelBtnName="$t('all.cancel')"
      :actionBtnName="$t('admin.popupBtnSend')"
      :content="$t('admin.popupMsgResendInvitation') + formData.email + '?'"
      :autotest="{
        close: 'resend-invitation-popup-close-btn',
        action: 'resend-invitation-popup-yes-btn',
        cancel: 'resend-invitation-popup-cancel-btn',
      }"
      @clickActionBtn="resendInvitation()"
    ></d-confirmation-popup>
    <d-alert
      v-model="resetSendInvitationAlert.isSuccess"
      type="success"
      :message="$t('admin.alertUserInvitationSuccessfullyResent')"
    ></d-alert>
    <d-alert
      v-model="resetSendInvitationAlert.isFailed"
      type="error"
      :message="$t('admin.alertFailedResendUserInvitation')"
    ></d-alert>
    <d-alert
      v-model="resendAllAlert.isSuccess"
      type="success"
      :message="$t('admin.alertAllInvitationResentSuccess')"
    ></d-alert>
    <d-alert
      v-model="resendAllAlert.isFailed"
      type="error"
      :message="$t('admin.alertAllInvitationResentFailed')"
    ></d-alert>
    <!-- delete Invitation Alert -->
    <d-alert
      v-model="deleteInvitationAlert.isSuccess"
      type="success"
      :message="$t('admin.alertUserInvitationSuccessfullyDelete')"
    ></d-alert>
    <d-alert
      v-model="deleteInvitationAlert.isFailed"
      type="error"
      :message="$t('admin.alertFailedDeleteUserInvitation')"
    ></d-alert>
  </div>
</template>

<script>
// TODO: Organize and trim script section.

import Vue from "vue";
import AdminAddUserPopup from "@/components/DAdmin/AdminAddUserPopup.vue";
import AdminFormUserEditor from "@/components/DAdmin/AdminFormUserEditor.vue";
import AdminGroupUsers from "@/components/AdminGroupUsers.vue";
import AdminWorkspacePopup from "@/components/DAdmin/AdminWorkspacePopup.vue";
import AdminFormBusinessCreateEdit from "@/components/AdminFormBusinessCreateEdit.vue";
import MixinDB from "./MixinDB.vue";
import MixinUser from "./MixinUser.vue";
import MixinAdmin from "./MixinAdmin.vue";
import MixinAnalytics from "./MixinAnalytics.vue";

import IconBase from "./IconBase.vue";
import DIconSearch from "./icons/DIconSearch.vue";
import DIconTrash from "./icons/DIconTrash.vue";
import DIconLock from "./icons/DIconLock.vue";
import DIconLockOpen from "./icons/DIconLockOpen.vue";
import DTabs from "./ui_components/DTabs.vue";
import DAlert from "./ui_components/DAlert.vue";
import DIconPen from "./icons/DIconPen.vue";
import DIconResend from "./icons/DIconResend.vue";
import DIconClose2 from "./icons/DIconClose2.vue";
import DIconPreview from "./icons/DIconPreview.vue";
import DIconOrgAdmin from "./icons/DIconOrgAdmin.vue";
import DIconSharedUser from "./icons/DIconSharedUser.vue";
import DIconMore from "./icons/DIconMore.vue";
import DConfirmationPopup from "./DPopup/DConfirmationPopup.vue";
import DTooltip from "./ui_components/DTooltip.vue";
import DAdminSettings from "./ui_components/DAdminSettings.vue";

import DWorkspaceGroupBreadcrumb from "@/components/ui_components/DWorkspaceGroupBreadcrumb.vue";
import AdminWorkspaceGroups from "@/components/DAdmin/workspaceGroup/AdminWorkspaceGroups.vue";
import AdminWorkspaceGroup from "@/components/DAdmin/workspaceGroup/AdminWorkspaceGroup.vue";
import AdminSettingsMenu from "@/components/DAdmin/Settings/AdminSettingsMenu.vue";
import DAdminPermissions from "@/components/ui_components/DAdminPermissions.vue";
import AdminCategories from "@/components/DAdmin/AdminCategories.vue";
import AdminLanguages from "@/components/DAdmin/AdminLanguages.vue";
import AdminMasterPassword from "@/components/DAdmin/Settings/AdminMasterPassword.vue";
import AdminCompanyLogo from "@/components/DAdmin/Settings/CompanyLogo/AdminCompanyLogo.vue";
import DAdminSsoMapping from "@/components/ui_components/DAdminSsoMapping.vue";

import AdminWorkspaceTeams from "@/components/DAdmin/workspaceGroup/AdminWorkspaceTeams.vue";
import AdminTeamUsers from "./DAdmin/AdminTeamUsers.vue";
import DAdminUserCard from "./ui_components/DAdminUserCard.vue";
import DDataTable from "./ui_components/DDataTable.vue";
import DTableSearch from "./ui_components/DTableSearch.vue";
import DTooltipShowAll from "./ui_components/DTooltipShowAll.vue";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import UpgradeToOrgAdminConfirmation from "./DAdmin/confirmations/UpgradeToOrgAdminConfirmation.vue";
import DowngradeOrgAdminConfirmation from "./DAdmin/confirmations/DowngradeOrgAdminConfirmation.vue";
import UserStatusFilter from "@/components/UserStatusFilter.vue";
import AdminWorkspaceGroupWorkspaces from "@/components/DAdmin/workspaceGroup/AdminWorkspaceGroupWorkspaces.vue";
import { canUpgradeToOrgAdmin, canDowngradeOrgAdmin, showMoreBtnAdminUserList } from "@/js/user-role/user-role.js";
import { appendMissingUserDataFromDB, updateTolatestUserdataFromDb } from "@/js/user/user.js";
import { getBaseDataForUserDataTable, USER_DATA_TABLE_TYPE } from "@/js/user-data-table/user-data-table.js";
import { deleteWorkspace } from "@/server/delete-workspace-server.js";
import { debounce } from "lodash-es";
import { mapGetters } from "vuex";
import { IS_SUPPORT_WORKSPACE_GROUP } from "@/constants/studio-version";

export default {
  name: "AdminOrganization",
  watch: {
    groupsData: function (groupsData) {
      this.checkPrivateColumn(groupsData);
    },
    businessDictionary: function () {
      this.reloadGroupBusinessName();
    },
  },
  props: {
    userRole: String,
    drawerOpenPopupStyle: Object,
  },
  data() {
    return {
      settingPage: "",
      //all tabs
      currentTab: 0,
      tabNames: [],
      autotestTabs: [],
      showPart: "allUsers",
      showSreachInput: false,
      mouseoverSearch: false,
      showForm: "",
      formData: {},
      allRoleOptions: ["viewer", "publisher", "admin-group", "admin-organization"],
      //all users
      searchUser: "",
      userTableHeaders: [
        {
          text: this.$t("all.namePerson"),
          align: "left",
          sortable: true,
          value: "displayName",
          autotest: "name-sort",
        },
        {
          text: this.$t("all.email"),
          align: "left",
          sortable: true,
          value: "email",
          autotest: "email-sort",
        },
        {
          text: this.$t("all.employeeId"),
          align: "left",
          sortable: true,
          value: "employeeId",
          autotest: "employee-id-sort",
        },
        {
          text: this.$t("admin.lastActivity"),
          align: "left",
          sortable: true,
          value: "lastActivityTimestamp",
          autotest: "last-activity-sort",
        },
        {
          text: this.$t("admin.status"),
          align: "left",
          sortable: true,
          value: "status",
          autotest: "status-sort",
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "",
        },
      ],
      usersData: [],
      groupUsersData: {},
      myUsers: [],
      deleteUserContent: "",
      invitedUsers: [],
      toDeleteId: "",
      toDeleteEmail: "",
      isInvitedFailed: false,
      isDeletedSuccess: false,
      isDeletedFailed: false,
      //group
      searchGroup: "",
      groupTableHeaders: [
        {
          text: this.$t("all.name"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "name-sort",
        },
        {
          text: this.$t("analytics.numberOfUsers"),
          align: "left",
          sortable: true,
          value: "numberOfUsers",
          autotest: "number-of-users-sort",
        },
        {
          text: this.$t("all.business"),
          align: "left",
          sortable: true,
          value: "businessName",
          autotest: "business-sort",
        },
        {
          align: "right",
          sortable: false,
          value: "",
        },
      ],
      groupsData: [],
      showGroupUsers: false,
      groupName: "",
      isGroupCreateSuccess: false,
      isGroupEditSuccess: false,
      groupDictionary: {},
      showDeleteGroupPopup: false,
      deleteGroupMsg: "",
      canNotDeleteGroup: false,
      deleteGroupSuccess: false,
      //business
      searchBusiness: "",
      businessData: [],
      groupId: "",
      businessDictionary: {},
      addBusinessSuccess: false,
      editBusinessSuccess: false,
      isLoading: {
        usersData: false,
        invitationData: false,
        groupsData: false,
        businessData: false,
      },
      resetSendInvitation: false,
      resetSendInvitationAlert: {
        isSuccess: false,
        isFailed: false,
      },
      resendAllAlert: {
        isSuccess: false,
        isFailed: false,
      },
      statusDictionary: {
        active: this.$t("admin.active"),
        inactive: this.$t("admin.inactive"),
        invited: this.$t("admin.invited"),
        "invitation-expired": this.$t("admin.invitationExpired"),
        "could-not-be-invited": this.$t("admin.couldNotInvited"),
      },
      deleteInviteConfirmation: false,
      deleteInvitationAlert: {
        isSuccess: false,
        isFailed: false,
      },
      viewUserData: {},
      filterStatus: ["active", "inactive"],
      currentStatus: "active",
      isSearchingUsers: false,
      foundUsers: [],
      totalUsers: 0,
      isDisplayingWorkspaceGroup: false,
      noSearchResults: false,
      searchInput: "",
      delayTimeout: null,
      showCardDelay: false,
      totalActive: 0,
      totalInactive: 0,
      showPrivateColumn: false,
      viewUserEmail: "",
      enabledToShowUserCard: true,
      isGroupsDataReady: false,
      isSupportWorkspaceGroup: IS_SUPPORT_WORKSPACE_GROUP,
    };
  },
  components: {
    DWorkspaceGroupBreadcrumb,
    AdminAddUserPopup,
    AdminFormUserEditor,
    AdminGroupUsers,
    AdminWorkspacePopup,
    AdminFormBusinessCreateEdit,
    IconBase,
    DIconSearch,
    DIconTrash,
    DIconLock,
    DIconLockOpen,
    DTabs,
    DAlert,
    DIconPen,
    DIconResend,
    DIconClose2,
    DIconPreview,
    DIconOrgAdmin,
    DIconSharedUser,
    DIconMore,
    DConfirmationPopup,
    DTooltip,
    DAdminSettings,
    AdminWorkspaceGroups,
    AdminWorkspaceGroup,
    AdminSettingsMenu,
    DAdminPermissions,
    AdminCategories,
    AdminLanguages,
    AdminMasterPassword,
    AdminCompanyLogo,
    DAdminSsoMapping,

    AdminWorkspaceTeams,
    AdminTeamUsers,
    DAdminUserCard,
    DDataTable,
    DTableSearch,
    DTooltipShowAll,
    DAvatar,
    UpgradeToOrgAdminConfirmation,
    DowngradeOrgAdminConfirmation,
    UserStatusFilter,
    AdminWorkspaceGroupWorkspaces,
  },
  mixins: [MixinDB, MixinUser, MixinAdmin, MixinAnalytics],
  created() {
    const self = this;
    this.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getUserProfileFromVuex();
        // this.getMyAdminData(); //get data for a specific admin level

        self.setCurrentTab();

        this.getBusinessData().then(() => {
          return this.getGroupsData()
            .then(() => this.updateGroupsUsersCount(this.groupsData))
            .then((groups) => {
              self.$set(this.groupsData, groups);
            });
        });

        this.getOrgOverview(this.$organization).then((data) => {
          this.totalActive = data.users.active;
          this.totalInactive = data.users.inactive;
        });
      }
    });
    this.setCustomFeatures();
    if (!this.activeUsersData.length) {
      this.getMoreUsers();
    }
    if (!this.inactiveUsersData.length) {
      this.getMoreInactiveUsers();
    }
  },
  mounted() {
    this.setDefaultSettingPage();
  },
  computed: {
    ...mapGetters(["getter_custom_attributes_with_id_users", "getter_custom_attributes_with_id_inactive_users"]),
    ...mapGetters("permission", ["getter_has_employee_id_login"]),
    ...mapGetters("workspaceGroups", [
      "getter_admin_workspace_groups",
      "getter_get_admin_workspace_group_by_workspace_id",
    ]),
    activeUsersData() {
      if (this.searchUser) {
        return this.foundUsers;
      }
      return this.getter_custom_attributes_with_id_users;
    },
    inactiveUsersData() {
      if (this.searchUser) {
        return this.foundUsers;
      }
      return this.getter_custom_attributes_with_id_inactive_users;
    },
    hasCustomUserAttributes() {
      try {
        return this.$clientSettings.userMappings.customUserAttributes ? true : false;
      } catch (err) {
        return false;
      }
    },
    customUserAttributes() {
      try {
        return this.$clientSettings.userMappings.customUserAttributes;
      } catch (err) {
        return [];
      }
    },
    userProfile() {
      //get user profile from vuex
      return this.$store.state.userProfile;
    },
    mockTeamUserData() {
      return [];
    },
    workspaceList() {
      return this.getter_admin_workspace_groups
        .reduce((acc, workspaceGroup) => {
          acc = acc.concat(workspaceGroup.workspaces || []);
          return acc;
        }, [])
        .map((workspace) => {
          const numberOfUsers = this.groupsData.find(({ id }) => id === workspace.id)?.numberOfUsers || 0;
          return {
            ...workspace,
            numberOfUsers,
          };
        });
    },
    businessTableHeaders() {
      const workspaceGroupHeader = this.isSupportWorkspaceGroup
        ? [
            {
              text: this.$t("all.workspaceGroup"),
              align: "left",
              sortable: true,
              value: "workspaceGroupName",
              autotest: "workspace-group-name-sort",
            },
          ]
        : [];
      return [
        {
          text: this.$t("all.name"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "business-name-sort",
        },
        ...workspaceGroupHeader,
        {
          text: this.$t("all.workspace"),
          align: "left",
          sortable: true,
          value: "workspaceName",
          autotest: "workspace-name-sort",
        },
        {
          align: "right",
          sortable: false,
          value: "",
        },
      ];
    },
    businessTableData() {
      const workspaceWithBusinsess = this.groupsData.filter((workspace) => workspace.business);
      const businessesNoWorkspaces = this.businessData.reduce((acc, business) => {
        const workspace = workspaceWithBusinsess.find((workspace) => workspace.business === business.id);
        if (!workspace) {
          acc.push({
            ...business,
            workspaceName: "",
            workspaceGroupName: "",
          });
        }
        return acc;
      }, []);
      const businessesWithWorkspaces = this.groupsData.reduce((acc, workspace) => {
        const business = this.businessData.find((business) => business.id === workspace.business);
        const workspaceGroupName = this.getter_get_admin_workspace_group_by_workspace_id(workspace.id)?.name;
        if (business) {
          acc.push({
            ...business,
            workspaceName: workspace.name,
            workspaceGroupName,
          });
        }
        return acc;
      }, []);
      const data = businessesNoWorkspaces.concat(businessesWithWorkspaces);
      return data.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    setDefaultSettingPage() {
      this.settingPage = this.$access.categoriesCreation ? "categories" : "permissions";
    },
    setSettingPage(settingPage) {
      this.$router.push("/admin/settings");
      this.showPart = "settings";
      this.settingPage = settingPage;
    },
    checkPrivateColumn(groupsData) {
      const addedPrivate = this.groupTableHeaders.find((group) => group.value === "private");
      const hasPrivate = groupsData.find((group) => group.private === true);
      if (hasPrivate && !addedPrivate) {
        this.showPrivateColumn = true;
        const header = {
          text: this.$t("all.private"),
          align: "left",
          sortable: true,
          value: "private",
          autotest: "private-sort",
        };
        this.groupTableHeaders.splice(3, 0, header);
      } else if (!hasPrivate && addedPrivate) {
        this.showPrivateColumn = false;
        this.groupTableHeaders.splice(4, 1);
      } else if (hasPrivate) {
        this.showPrivateColumn = true;
      }
    },
    updateFoundUser(update, type) {
      if (this.searchUser) {
        if (type === "update") {
          let updateUser = this.foundUsers.find((foundUser) => foundUser.id == update.user.id);
          if (updateUser) {
            for (const key in update.update) {
              updateUser[key] = update.update[key];
            }
          }
          this.foundUsers.splice();
        }
      }
    },
    updateTolatestUserdataFromDb({ targetUserData }) {
      updateTolatestUserdataFromDb({
        mixinDbRef: this,
        userDataRef: targetUserData,
      });
    },
    canUpgradeToOrgAdmin(userData) {
      return canUpgradeToOrgAdmin({
        userData,
        vueInstanceRef: this,
      });
    },
    canDowngradeOrgAdmin(userData) {
      return canDowngradeOrgAdmin({
        userData,
        vueInstanceRef: this,
      });
    },
    showMoreBtn(userData) {
      return showMoreBtnAdminUserList({
        userData,
        vueInstanceRef: this,
      });
    },
    getAllInvites() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByQuery("invitations", [
            {
              field: "organization",
              comparison: "==",
              value: self.$organization,
            },
            {
              field: "status",
              comparison: "in",
              value: ["invited", "expired"],
            },
          ])
          .then((data) => {
            resolve(data);
          });
      });
    },
    mouseoverViewUserCard(email) {
      if (!this.enabledToShowUserCard) return;
      this.viewUserEmail = email;
      this.enabledToShowUserCard = false;
    },
    clickViewUserCard(email) {
      this.viewUserEmail = this.viewUserEmail === email ? "" : email;
    },
    debounceHandleFindUser: debounce(async function (searchInput) {
      await this.handleFindUser(searchInput);
    }, 500),
    async handleFindUser(searchInput) {
      if (searchInput.length === 0) {
        return;
      }
      this.noSearchResults = false;
      this.isSearchingUsers = true;
      try {
        const response = await this.findUser(searchInput, null, false);
        const query = JSON.parse(response.config.data).text;
        if (searchInput == query) {
          const results = response.data.results;
          const fResults = this.formatUserSearchResults(results);
          if (!fResults.length) {
            this.noSearchResults = true;
          } else {
            this.noSearchResults = false;
          }

          const foundUsers = fResults.splice(0, 100);
          if (foundUsers.length) {
            await appendMissingUserDataFromDB({
              mixinDbRef: this,
              userListRef: foundUsers,
            });

            foundUsers.forEach((user) => {
              Object.assign(
                user,
                getBaseDataForUserDataTable({
                  user,
                  dataTableType: user.invitation ? USER_DATA_TABLE_TYPE.INVITED : this.currentStatus,
                })
              );
            });
          }
          this.foundUsers = foundUsers;
          this.isSearchingUsers = false;
        }
      } catch (e) {
        console.error(e);
      }
    },
    getMoreInactiveUsers() {
      this.isLoading.inactiveUsersData = true;
      this.$store.dispatch("getInactiveUsers", {
        organization: this.$organization,
      });
      this.$store.subscribe(
        function (mutation, state) {
          if (mutation.type == "getInactiveUsersEnd") {
            this.isLoading.inactiveUsersData = false;
          }
        }.bind(this)
      );
    },
    getMoreUsers() {
      this.isLoading.usersData = true;
      this.$store.dispatch("getUsers", { organization: this.$organization });
      this.$store.subscribe(
        function (mutation, state) {
          if (mutation.type == "getUsersEnd") {
            this.isLoading.usersData = false;
          }
        }.bind(this)
      );
    },
    setCustomFeatures() {
      if (this.$access.teams) {
        const workspaceGroupTab = this.isSupportWorkspaceGroup ? [this.$t("all.workspaceGroup")] : [];
        this.tabNames = [
          ...workspaceGroupTab,
          this.$t("all.workspaces"),
          this.$t("all.teams"),
          this.$t("all.users"),
          this.$t("all.business"),
        ];
        const workspaceGroupTest = this.isSupportWorkspaceGroup ? ["workspace-groups-tab"] : [];
        this.autotestTabs = [...workspaceGroupTest, "workspaces-tab", "teams-tab", "all-users-tab", "business-tab"];
      } else {
        this.tabNames = [this.$t("all.allUsers"), this.$t("all.business")];
        this.autotestTabs = ["all-users-tab", "business-tab"];
      }
      this.tabNames.push(AdminSettingsMenu);
      this.autotestTabs.push("settings-tab");
    },
    deleteInvite() {
      const invite = this.formData;
      this.deleteUserInvite(invite)
        .then(() => {
          this.$store.dispatch("updateInvitation", {
            invite: invite,
            action: "remove",
          });
          this.deleteInvitationAlert.isSuccess = true;
        })
        .catch((err) => {
          this.deleteInvitationAlert.isFailed = true;
        });
    },
    setDeleteInvitation(user) {
      this.formData = user;
      this.deleteInviteConfirmation = true;
    },
    resendInvitation() {
      const invite = this.formData;
      invite.status = "invited";
      this.updateDocument("invitations", this.formData.id, {
        status: "pending",
      })
        .then(() => {
          this.$store.dispatch("updateInvitation", {
            invite: invite,
            action: "update",
          });
          this.resetSendInvitationAlert.isSuccess = true;
        })
        .catch((err) => {
          this.resetSendInvitationAlert.isFailed = true;
        });
    },
    setResendInvitation(user) {
      this.formData = user;
      this.resetSendInvitation = true;
    },
    closeGroupUsers() {
      const self = this;
      this.showGroupUsers = false;
      this.$router.push("/admin/workspaces");
      // update group.numberOfUsers
      const groupIndex = this.groupsData.findIndex((x) => x.id === this.groupId);
      const groupData = this.groupsData[groupIndex];
      this.updateGroupUsersCount(groupData)
        .then((group) => {
          self.$set(self.groupsData, groupIndex, group);
        })
        .catch((err) => console.log(err));
    },
    getTabAndRouteInfo(checkByIndex, params = { tabIndex, tabValue }) {
      const tabByIsSupportWorkspaceGroup = this.isSupportWorkspaceGroup ? ["main", "workspaces"] : ["main"];
      const tabList = this.$access.teams
        ? [...tabByIsSupportWorkspaceGroup, "teams", "allUsers", "business", "settings"]
        : ["main", "business", "settings"];
      let tabIndex;
      if (checkByIndex) {
        const value = tabList[params.tabIndex];
        tabIndex = !value ? 0 : params.tabIndex;
      } else {
        const index = tabList.indexOf(params.tabValue);
        tabIndex = index === -1 ? 0 : index;
      }
      const tabValue = tabList[tabIndex];
      const firstTabByIsSupportWorkspaceGroup = this.isSupportWorkspaceGroup ? "workspaceGroups" : "workspaces";
      let showPart;
      if (tabValue === "main") showPart = this.$access.teams ? firstTabByIsSupportWorkspaceGroup : "allUsers";
      else showPart = tabValue;
      return {
        tabIndex,
        tabValue,
        showPart,
        routeSubPathName: tabValue,
      };
    },
    clickTab(tabIndex) {
      this.searchUser = "";
      this.searchBusiness = "";
      this.isDisplayingWorkspaceGroup = false;
      this.showGroupUsers = false;
      const info = this.getTabAndRouteInfo(true, { tabIndex });
      this.showPart = info.showPart;
      this.$router.push(`/admin/${info.routeSubPathName}`);
    },
    setCurrentTab() {
      const tabValue = this.$route.params.tab;
      const id = this.$route.params.id;
      if (id && tabValue === "main") {
        this.currentTab = 1;
        this.isDisplayingWorkspaceGroup = this.isSupportWorkspaceGroup;
        this.clickTab(this.currentTab);
        return;
      }
      const info = this.getTabAndRouteInfo(false, { tabValue });
      this.currentTab = info.tabIndex;
      this.clickTab(this.currentTab);
    },
    addUserData(data) {
      const self = this;
      this.groupUsersData[data.groupId].push(data);
      const newCopy = JSON.stringify(this.groupUsersData);
      //Vue.$set doesn't work in here for whatever reason. must use new hard copy to rerender
      self.groupUsersData = Object.assign({}, JSON.parse(newCopy));
      this.$refs.AdminGroupUsers.$forceUpdate();
    },
    getBusinessName(id) {
      return this.businessDictionary[id] || "-";
    },
    reloadGroupBusinessName() {
      this.isLoading.groupsData = true;
      this.groupsData.forEach((group) => {
        group.businessName = this.getBusinessName(group.business);
      });
      this.isLoading.groupsData = false;
    },
    getGroupsData() {
      const self = this;
      this.isGroupsDataReady = false;
      return new Promise(function (resolve, reject) {
        self.isLoading.groupsData = true;
        self.fetchGroups().then((data) => {
          // add businessName to groupsData
          data.forEach((group) => {
            group.businessName = self.getBusinessName(group.business);
          });
          data.forEach((group) => {
            self.groupDictionary[group.id] = group.name;
          });
          self.updateGroupsUsersCount(data).then((groups_data_width_num_of_user) => {
            self.groupsData = groups_data_width_num_of_user;
            self.isLoading.groupsData = false;
            self.isGroupsDataReady = true;
            resolve();
          });
        });
      });
    },
    fetchGroups() {
      const self = this;
      const userId = self.$userProfile.id;
      return new Promise(function (resolve, reject) {
        self.getDocumentByFieldValue("groups", "organization", self.$organization).then((data) => {
          resolve(data);
        });
      });
    },
    openDeleteGroupConfirmation(item) {
      this.canNotDeleteGroup = false;
      this.setEditorFormData(item);
      this.deleteGroupMsg = this.$t("admin.popupMsgDeleteWorkspace", { name: this.formData.name });
      this.showDeleteGroupPopup = true;
    },
    async onclickDeleteGroup() {
      const groupData = {
        organization: this.$organization,
        groupId: this.formData.id,
      };
      const { ok, data, errorMessage } = await deleteWorkspace({ groupData });
      if (ok && data === "successfully removed group.") {
        this.deleteGroupSuccess = true;
        this.getGroupsData();
      } else {
        this.canNotDeleteGroup = true;
        if (errorMessage.includes("workflows assigned")) {
          this.deleteGroupMsg = this.$t("admin.alertDeleteGroupFailedWorkflows");
        } else if (errorMessage.includes("playlists assigned")) {
          this.deleteGroupMsg = this.$t("admin.alertDeleteGroupFailedSkills");
        } else if (errorMessage.includes("teams assigned")) {
          this.deleteGroupMsg = this.$t("admin.alertDeleteGroupFailedTeams");
        } else {
          this.deleteGroupMsg = errorMessage || data;
        }
        this.showDeleteGroupPopup = true;
      }
    },
    reloadBusinessData() {
      this.businessData = [];
      this.isLoading.businessData = true;
      this.getBusinessData();
    },
    getBusinessData() {
      this.isLoading.businessData = true;
      return this.fetchBusinesses().then((data) => {
        this.businessData = data;
        this.isLoading.businessData = false;
      });
    },
    fetchBusinesses() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByQuery("businesses", [
            {
              field: "organization",
              comparison: "==",
              value: self.$organization,
            },
          ])
          .then((data) => {
            data.forEach((business) => {
              self.businessDictionary[business.id] = business.name;
            });
            self.businessDictionary = Object.assign({}, self.businessDictionary, {});
            resolve(data);
          });
      });
    },
    getNameInitials(name) {
      if (name && name.length > 0) {
        let initials = "";
        const nameArray = name.split(" ");
        initials = nameArray[0].substring(0, 1).toUpperCase();
        if (nameArray.length > 1) {
          initials += nameArray[nameArray.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      }
    },
    deleteBusiness() {
      this.showForm = "";
    },
    clickGroupName(group) {
      this.groupName = group.name;
      this.groupId = group.id;
      this.groupLocale = group.locale;
      this.showGroupUsers = true;
      this.$router.push("/admin/workspaces/" + group.id);
    },
    reloadUserData() {
      this.usersData = [];
      this.isLoading.usersData = true;
    },
    setUserEditor(user) {
      this.formData = {};
      this.formData = user;
      this.showForm = "user-editor";
    },
    setEditorFormData(data) {
      this.formData = {};
      this.formData = data;
    },
    getUserProfileFromVuex() {
      Vue.prototype.$user = this.auth().currentUser;
      this.$store.dispatch("getUserProfile", this.$user);
    },
    enterWorkspaceGroup(workspaceGroup) {
      this.$router.push({ name: "AdminWorkspaceGroup", params: workspaceGroup });
      this.isDisplayingWorkspaceGroup = true;
    },
    closeSingleTeam() {
      this.$router.push("/admin/teams");
      this.isDisplayingWorkspaceGroup = false;
    },
    showUpgradeOrgPopup(user) {
      this.formData = user;
      this.showForm = "upgrade-to-org";
    },
    showDowngradeOrgPopup(user) {
      this.formData = user;
      this.showForm = "downgrade-org";
    },
    changeCurrentStatus(status) {
      this.currentStatus = status;
    },
  },
};
</script>

<style scoped lang="scss">
.adminOrganization {
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;

  &__title {
    height: 148px;
    padding: 44px 28px 0 28px;
    text-align: left;
  }

  &__tabs {
    margin-top: 30px;

    // TODO: Refactor with `AdminSettingsMenu` of tabs.
    &-focus-settings ::v-deep .blue-bar {
      height: 0 !important;
    }

    &__settingsMenu {
      position: relative;
      height: 100%;
      display: flex;
    }
  }
}
.admin-organization {
  position: fixed;
  height: calc(100% - 70px);
  width: 100%;
  padding: 0;
  background-color: #fbfbfb;
}
.top-tabs {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 28px;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  text-align: left;
  min-width: 600px;
}
.tab-div {
  position: fixed;
  height: calc(100% - 134px);
  width: 100%;
}
.tab-title-row {
  position: relative;
  height: 90px;
  width: 100%;
  display: flex;
  padding: 30px 28px 0 28px;
}
.tab-title {
  height: 44px;
  width: 145px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 44px;
}
.search-div {
  height: 44px;
  width: auto;
  display: flex;
  padding-top: 8px;
}
.search-icon {
  height: 32px;
  padding: 4px;
  margin-right: 4px;
}
.serach-form {
  width: 0px;
  transition: 0.3s;
  overflow: hidden;
  padding: 0 16px 0 0;
}
.show-search-input {
  width: 180px;
  transition: 0.3s;
  padding: 0 24px 0 0;
}
.serach-input {
  margin: 0;
  padding: 0;
}

.create-btn {
  min-width: 140px;
  text-transform: none !important;
}

.avatar-td {
  width: 32px;
}
.new-btns-td {
  text-align: left;
  min-width: 172px;
  padding: 0 24px;
  height: 100%;
  position: relative;
}
.new-btns-td-div {
  display: flex;
}
.btns-td {
  min-width: 104px;
  display: flex;
}
.avatar-placeholder {
  height: 32px;
  width: 32px;
  background-color: #8d909f;
  color: white;
  text-align: center;
  font-size: 12px;
  padding-top: 7px;
  border-radius: 16px;
}
.view-button {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-top: 13px;
  margin-right: 20px;
  margin-left: 20px;
  color: #78767a;
}
.tooltip-btn {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-top: 14px;
}
.view-button-new {
  position: relative;
  height: 22px;
  width: 22px;
  color: #78767a;
  margin-right: 20px;
}
.hide-view-btn {
  opacity: 0.3;
  opacity: 0;
  pointer-events: none;
}
.tooltip-btn-new {
  position: relative;
  height: 22px;
  width: 22px;
  margin-right: 20px;
}
.cancel-invite-btn-new {
  position: relative;
  height: 18px;
  width: 18px;
}
.cancel-invite-btn {
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-top: 14px;
  padding: 2px;
}
.tooltip-btn-btn {
  color: #78767a;
  opacity: 0.8;
}
.table-editor-btn {
  padding-top: 8px;
  color: #78767a;
  margin-right: 8px;
}
.table-delete-btn {
  padding-top: 8px;
  color: #78767a;
}
.table-delete-btn:hover {
  opacity: 1;
}
.btn-hover:hover {
  color: #2d2e32;
  cursor: pointer;
}
.group-name-hover:hover {
  color: #2d2e32;
  cursor: pointer;
  font-weight: 500;
}
.admin-setting-div {
  position: relative;
  height: calc(100% - 64px);
  width: 100%;
}
/* //// d-data-table ///// */
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.tab-title-new {
  height: 44px;
  width: 145px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 44px;
}
.table-div {
  position: fixed;
  left: 0;
  width: 100%;
  height: calc(100% - 384px);
  padding: 0 28px;
}
.table-search-row {
  position: relative;
  width: 100%;
  padding: 0 28px;
}
.d-data-table {
  position: relative;
  height: 100%;
  width: 100%;
}
.filter-div {
  position: relative;
}
.avatar-div {
  position: relative;
  display: inline-block;
  height: 32px;
  width: 32px;
}
.org-admin-icon {
  position: absolute;
  right: -10px;
  bottom: -4px;
  height: 20px;
  width: 20px;
  background-color: #ffbf18;
  border-radius: 10px;
  padding: 3px;
}
.shared-icon {
  background-color: #a9a9a9;
}
.table-wrap {
  position: fixed;
  left: 0;
  width: 100%;
  height: calc(100% - 314px);
  padding: 0 28px;
}
.table-wrap:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.private-btn {
  display: inline-block;
  height: 32px;
  min-width: 100px;
  padding: 6px 8px 0 8px;
  border: 1px solid #e7e7e7;
  border-radius: 16px;
  background-color: #ffffff;
  color: #3f3f3f;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
}
.private-btn-dark {
  border: 1px solid #e7e7e7;
  border-radius: 19px;
  background-color: #52545d;
  color: #ffffff;
}
.private-icon {
  margin: 0 2px -2px 4px;
}
@media screen and (max-width: 959px) {
  .top-tabs {
    padding: 0 20px;
  }
  .tab-title-row {
    padding: 30px 20px 0 20px;
  }
  .table-div {
    padding: 0 20px;
  }
  .table-search-row {
    padding: 0 20px;
  }
  .table-wrap {
    padding: 0 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1903px) {
  .top-tabs {
    padding: 0 28px;
  }
  .tab-title-row {
    padding: 30px 28px 0 28px;
  }
}
</style>
