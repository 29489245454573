<template>
  <section>
    <audio ref="audioPlayer" :src="currentAudio" @canplay="onCanplay()" hidden></audio>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "PlayerProcessedLanguage",
  watch: {
    //get the audio based on video playback time
    videoCurrentTime: function() {
      if (this.language != this.steps[this.currentStep].languageCode) {
        this.$emit("muteVideoEvent");
        this.getCurrentAudio();
      }
    },
    //set language when the prop changes
    // language:function(){
    //   if(this.language == "default"){
    //     this.audioPlayer.muted = true;
    //     this.$emit("unmuteVideoEvent");
    //   }else{
    //     this.$emit("muteVideoEvent");
    //     this.setAudioPlayer(this.language);
    //   }
    // },
    language: function() {
      if (this.language != this.steps[this.currentStep].languageCode) {
        this.audioPlayer.pause();
        //this make sure to reload current sentence
        this.currentAudioIndex = -1;
      }
    },
    //sync audio playing with video playing
    isPlaying: function() {
      if (this.isPlaying && this.audioPlayer && this.audioArrayReady) {
        this.audioPlayer.play();
      } else {
        this.audioPlayer.pause();
      }
    },
    //update the audio player when the current step changes
    currentStep: function() {
      this.audioPlayer.muted = true;
      this.setAudioPlayer(this.language);
    }
  },
  props: {
    videoCurrentTime: {
      type: Number
    },
    steps: {
      type: Array
    },
    isPlaying: {
      type: Boolean
    },
    currentStep: {
      type: Number
    },
    language: {
      type: String
    }
  },
  mounted() {
    this.audioPlayer = this.$refs.audioPlayer;
  },
  data() {
    return {
      audioPlayer: null, //reference to the audio player
      currentAudioIndex: -1,
      currentAudio: "",
      audioArray: [], //stores the audio file in base64
      audioArrayReady: false,
    };
  },
  methods: {
    onCanplay() {
      if (this.isPlaying) {
        this.audioPlayer.play();
      } else {
        this.audioPlayer.pause();
      }
    },
    getCurrentAudio() {
      const self = this;
      if (this.audioArrayReady) {
        for (let i = 0; i < this.audioArray.length; i++) {
          if (i + 1 < this.audioArray.length) {
            if (
              this.videoCurrentTime <
                parseFloat(this.audioArray[i + 1].startTime) &&
              this.videoCurrentTime > parseFloat(this.audioArray[i].startTime)
            ) {
              if (this.currentAudioIndex != i) {
                  //let new audio play only when src is null (browser url)
                  if(this.audioPlayer.src == window.location.href){
                    // this make sure each sentence is only played once
                    this.currentAudioIndex = i;
                    this.currentAudio = "data:audio/ogg;base64," + (this.audioArray[i].audio);
                    this.audioPlayer.src = this.currentAudio;
                    console.log("new audio")
                    this.audioPlayer.onended = function(i){
                      this.audioPlayer.src = "";
                    }.bind(this);
                    this.audioPlayer.oncanplay = function(){
                      this.audioPlayer.play();
                    }.bind(this);
                  }
              }
            }
          }
        }
      }
    },
    async setAudioPlayer(language) {
      return; // temporary disable this function
      this.getAudioArray(language)
        .then(audioArray => {
          this.audioPlayer.muted = false;
          this.audioArray = audioArray;
          this.audioArrayReady = true;
          this.getCurrentAudio();
          this.$emit("finishLoading");
        })
        .catch((err) => console.log(err));
    },
    getAudioArray(language) {
      if (!this.steps[this.currentStep].foreignLanguageAudio) throw new Error("step without foreignLanguageAudio")

      const self = this;
      const audioSource = this.steps[this.currentStep].foreignLanguageAudio[
        language
      ];
      return new Promise((resolve, reject) => {
        axios
          .get(audioSource)
          .then(function(response) {
            console.log("finished getting audio.");
            resolve(response.data);
          })
          .catch(function(error) {
            reject(error);
            console.log(error);
          });
      });
    }
  },
  computed: {}
};
</script>

<style scoped>
</style>
