import axios from "axios";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  // NOTICE: this API is not support china version, so the value is null
  return environment.backendType === "firebase"
    ? environment.service[environment.env].summarizationServer
    : environment.service[environment.env]["china"].summarizationServer;
};

const summarizationServer = axios.create({
  baseURL: getBaseUrl(),
});

summarizationServer.interceptors.request.use(
  async config => {
    return config;
  },
  error => Promise.resolve(error)
);
const apiWrapper = apiWrapperGenerator(summarizationServer);

export const postGetWorkflowKeypoints = (payload = {
  workflow_id: "",
  oid: "",
  token: "",
}) => apiWrapper("post", `/workflow/keypoints`, payload);
