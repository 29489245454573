import axios from "axios";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  // NOTICE: this API is not support china version, so the value is null
  return environment.backendType === "firebase"
    ? environment.service[environment.env].chatServer
    : environment.service[environment.env]["china"].chatServer;
};

const chatServer = axios.create({
  baseURL: getBaseUrl(),
});

chatServer.interceptors.request.use(
  async config => {
    return config;
  },
  error => Promise.resolve(error)
);
const apiWrapper = apiWrapperGenerator(chatServer);

export const postChatQuery = (payload = {
  query: "",
  oid: "",
  token: "",
  previousMessages: [],
  group: "",
  topkRetriever: 5,
  userId: "",
  sessionId: "",
  previousAnswerId: "",
}) => apiWrapper("post", `/chat/query`, payload);

export const postChatLike = (payload = {
  answerId: "",
  oid: "",
  token: "",
  userId: "",
  like: false,
}) => apiWrapper("post", `/chat/like`, payload);

export const postChatReferences = (payload = {
  oid: "",
  token: "",
  answer: "",
  references: [],
}) => apiWrapper("post", `/chat/references`, payload);
