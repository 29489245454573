import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import Analytics from "../js/analytics/analytics";

const searchServer = axios.create({
  baseURL:
    environment.backendType === "firebase"
      ? environment.service[environment.env].search
      : environment.service[environment.env]["china"].search,
});

searchServer.interceptors.request.use(
  async (config) => {
    config.data.sessionId = Analytics.getSessionId();
    return config;
  },
  (error) => Promise.resolve(error)
);

export const postSearch = async ({ payload, path }) => {
  if (!payload.token) {
    payload.token = await authChoice().currentUser.getIdToken();
  }
  const { status, data, statusText } = await searchServer.post(path, payload);
  if (status === 200) {
    return { ok: true, data };
  } else {
    return {
      ok: false,
      errorMessage: `statusText: ${statusText}, status: ${status}`,
    };
  }
};

export default searchServer;
