import Vue from "vue";
import axios from "axios";
import authChoice from "../../auth";
export async function getIdToken() {
  return authChoice().currentUser.getIdToken();
}

const SINGED_REGEX = /token=|Signature=|sign-playlist/;

export const checkIsSignedUrl = (url = "") => SINGED_REGEX.test(url);

export function getSignedURL({ src }) {
  return new Promise(async function (resolve, reject) {
    const token = await getIdToken();
    if (!token) {
      reject("faield to get token");
      return;
    }
    const data = { token: token, url: src };
    //TODO: BASE64 IS AN EXPERIMENTAL FEATURE FOR WHEN GCP ACCESS IS BLOCKED
    // data["base64"] = true;
    axios
      .post(Vue.prototype.$service.sign + "sign", data)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  });
}

export async function getSignedUrlByRez(type, id, rez) {
  try {
    const token = await getIdToken();
    if (!token) {
      throw "faield to get token";
      return;
    }
    const response = await axios({
      method: "post",
      url: Vue.prototype.$service.sign + "access",
      headers: {
        "Content-Type": "application/json",
        "x-dh-message-type": "VideoSignedURL",
      },
      data: { token, request: { type, id, rez } },
    });
    const { videoURL } = response.data;
    return { ok: videoURL !== "", url: videoURL };
  } catch (error) {
    console.log(error);
    return { ok: false, url: "", error };
  }
}

export async function getBatchSignedURLs({ data, isPublic }) {
  try {
    let token = "";
    if (!isPublic) {
      token = await getIdToken();
      if (!token) {
        throw "faield to get token";
      }
    }
    const response = await axios({
      method: "post",
      url: Vue.prototype.$service.sign + "sign/batch",
      headers: {
        "Content-Type": "application/json",
      },
      data: { token: token, data },
    });
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, error };
  }
}

export async function getPublicWorkflowSignedURLs({ workflowId, imageMaps }) {
  try {
    const data = { workflowId, imageMaps };
    const response = await axios({
      method: "post",
      url: Vue.prototype.$service.sign + "access/public-workflow/image-map",
      data,
    });
    return { ok: true, data: response.data.data };
  } catch (error) {
    console.log(error);
    return { ok: false, data: "", error };
  }
}
