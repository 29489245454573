<template lang="html">

  <!-- <section class="mixin-upload">
    <h1>mixin-upload Component</h1>
  </section> -->

</template>

<script lang="js">
  import AliOss from '../js/AliOss';

  export default  {
    name: 'mixin-upload',
    props: [],
    mounted() {

    },
    data() {
      return {
        aliOss: AliOss
      }
    },
    methods: {
      uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
          c
        ) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
      },      
      uploadString(path, str) {
        const firebase = this.$firebase;
        let self = this;
        const fileName = this.uuidv4();
        return new Promise(function(resolve, reject) {
          var storageRef = self.$firebase.storage().ref();
          var uploadTask = storageRef.child(path + fileName).putString(str);

          // Register three observers:
          // 1. 'state_changed' observer, called any time the state changes
          // 2. Error observer, called on failure
          // 3. Completion observer, called on successful completion
          uploadTask.on(
            "state_changed",
            function(snapshot) {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
              }
            },
            function(error) {
              // Handle unsuccessful uploads
            },
            function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function(downloadURL) {
                  resolve(downloadURL);
                });
            }
          );
        });
      },      
      uploadToFirebase(folder, file, metadata) {
        const self = this;
        let firebase = this.$firebase;
        // Get a reference to the storage service, which is used to create references in your storage bucket
        var storage = firebase.storage();

        // Create a storage reference from our storage service
        var storageRef = storage.ref();
        return new Promise(function(resolve, reject) {
          var uploadTask = storageRef
            .child(folder + file.name)
            .put(file, metadata);

          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function(snapshot) {
              var progress = (
                (snapshot.bytesTransferred / snapshot.totalBytes) *
                100
              ).toFixed(2);
              // self.progressValue = progress;
              self.message = "uploading... " + progress + "%";
              console.log(
                folder + file.name + " Upload is " + progress + "% done"
              );
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log(folder + file.name + " Upload is paused");
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log(folder + file.name + " Upload is running");
                  break;
              }
            },
            function(error) {
              reject(error.code);
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case "storage/unauthorized":
                  // User doesn't have permission to access the object
                  break;

                case "storage/canceled":
                  // User canceled the upload
                  break;

                case "storage/unknown":
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            function() {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function(downloadURL) {
                  // self.props.setVideoURL(downloadURL);
                  console.log(folder + file.name + " available at", downloadURL);
                  self.message = "";
                  self.progressCircular = false; 
                  resolve(downloadURL);
                });
            }
          );
        });
      },
      uploadToAliOss(folder, file, metadata) {
        const self = this;
        //let firebase = this.$firebase;
        // Get a reference to the storage service, which is used to create references in your storage bucket
        //var storage = firebase.storage();
        const aliOss = this.aliOss;
        
        self.time = new Date().getTime();

        // Create a storage reference from our storage service
        // var storageRef = storage.ref();
        return new Promise(function(resolve, reject) {
          // var uploadTask = storageRef
          //   .child(folder + file.name)
          //   .put(file, metadata);

          aliOss.uploadFile(
            folder, 
            file,
            metadata,
            (percentage ,checkpoint , res ) => {
                let bytesTransferred = !checkpoint? 0 : checkpoint.partSize * checkpoint.doneParts.length;
                let totalBytes = !checkpoint? file.size : checkpoint.fileSize;
                if (
                  (Math.round(bytesTransferred / 100) * 100) %
                    self.numByteSamples ==
                    0 &&
                  bytesTransferred > self.numByteSamples
                ) {
                  self.changeInBytes = bytesTransferred - self.bytes;
                  self.bytes = bytesTransferred;
                  self.uploadTime = new Date().getTime() - self.time;
                  self.time = new Date().getTime();
                }
                var progress = Math.round(percentage * 100);

                if (folder.includes("videos")) {
                  // upload file progress = 50% progressBarValue
                  if (self.processImageMapLocally) {
                    if (self.progressBarValue > 50) {
                      self.progressBarValue = progress * 0.5 + 50;
                    }
                  } else {
                    self.progressBarValue = parseFloat(progress);
                  }
                  // if (self.progressBarValue == 100) {
                  //   self.stepNum = 3;
                  // }
                  if (self.uploadTime < 5000) {
                    var connection = navigator.connection;
                    var uploadSpeed = connection.downlink;
                    self.estimatedUploadTime = (
                      (totalBytes - bytesTransferred) /
                      (60 * 125000 * uploadSpeed)
                    ).toFixed(0);
                  } else {
                    self.estimatedUploadTime = (
                      (totalBytes - bytesTransferred) /
                      (60 * (self.changeInBytes / (self.uploadTime / 1000)))
                    ).toFixed(0);
                  }
                }
                console.log(
                  folder + file.name + " Upload is " + progress + "% done"
                );
                
            }
          ).then((result) => {
              // Upload completed successfully, now we can get the download URL
              const downloadURL = result.url;
              resolve(downloadURL);
            }
          ).catch((error) => {
              console.error(error);
              reject(error.code);            
            }
          );

        });
      }
    },
    computed: {

    }
}
</script>

<style scoped>
  .mixin-upload {

  }
</style>
