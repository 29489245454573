export function getDisplayTitle(content, displayLang) {
  const translatedTitle = getTranslatedTitle(content, displayLang);
  const originalTitle = content.title;
  return translatedTitle || originalTitle;
}

export function isTranslatedTitleDisplayed(content, displayLang) {
  const translatedTitle = getTranslatedTitle(content, displayLang);
  return !!translatedTitle;
}

function getTranslatedTitle(content, displayLang) {
  const titleTranslations = content.titleTranslations || {};
  const noTranslations = Object.keys(titleTranslations).length === 0;
  if (noTranslations) return "";

  const languages = convertDisplayLang(displayLang);
  for (const language of languages) {
    if (titleTranslations.hasOwnProperty(language)) {
      return titleTranslations[language];
    }
  }
  return "";
}

function convertDisplayLang(displayLang) {
  // Language code for Interface and translations may differ
  // i18n Interface language code: "en", "zh", "es-es", "fr-fr", "pt-br", "ru-ru", "th-th", "ja-jp", "ko-kr"
  const LANGUAGE_CODE_MAPPING = {
    en: ["en", "en-us"],
    "es-es": ["es-es", "es-mx"],
    "fr-fr": ["fr-fr", "fr-ca"],
    "pt-br": ["pt-br", "pt-pt"],
  };
  return LANGUAGE_CODE_MAPPING[displayLang] || [displayLang];
}
