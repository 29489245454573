<template>
  <section class="user-invite-create">
    <div class="user-invite-content fullscreen-center">
      <deephow-logo width="190" height="40" />
      <v-card dark color="#17181A" class="invite-box">
        <p class="create-account-title">{{$t('password.createYourAccount')}}</p>
        <p class="create-account-subtitle">{{$t('password.checkNameChoosePassword')}}</p>
        <v-form autocomplete="off" v-model="formValid" :style="{'margin':'56px 0 32px 0'}">
          <v-text-field
            v-model="fullName"
            class="create-input"
            :label="$t('admin.fullName')"
            autocomplete="new-password"
            :rules="[rules.required, rules.noAllSpace]"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :label="$t('auth.password')"
            class="create-input"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="[rules.validPassword, rules.noWhiteSpace, rules.max]"
            @click:append="showPassword = !showPassword"
            autocomplete="new-password"
          ></v-text-field>
        </v-form>
        <v-btn
          depressed
          round
          block
          class="body-2 text-capitalize white--text no-transform"
          color="#4689f4"
          :disabled="!formValid"
          @click="setNameAndPassword()"
        >{{$t('password.createAccount')}}</v-btn>
      </v-card>
    </div>
    <div class="language-btn">
      <user-language-menu
        userInviteCreate
        :isMobileDevice="false"
        :isTabletDevice="false"
        @change-lang-url="changeLangUrl()"
      ></user-language-menu>
    </div>
  </section>
</template>

<script>
import DeephowLogo from "./icons/logo/DeephowLogo.vue";
import axios from "axios";
import MixinUser from "./MixinUser.vue";
import UserLanguageMenu from "@/components/DUser/UserLanguageMenu";

export default {
  name: "UserInviteCreate",
  props: {
    isMobileDevice: Boolean,
    isTabletDevice: Boolean,
  },
  data() {
    return {
      fullName: "",
      password: "",
      showPassword: false,
      email: "",
      token: "",
      rules: {
        noWhiteSpace: value => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        noAllSpace: value => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        required: value => !!value || this.$t("rules.required"),
        max: v => (v && v.length <= 25) || this.$t("rules.max25"),
        min: v => (v && v.length >= 6) || this.$t("rules.min6"),
        validPassword: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_+=[\]{}|\\,.?:"';<>/-])[^]{10,}$/;
          return (
            pattern.test(value) || this.$t("rules.validPasswordMin10")
            // "Password needs to be at least 10 characters, include lower and upper case letter, number, and special character."
          );
        },
      },
      formValid: false,
      language: "",
    };
  },
  components: {
    DeephowLogo,
    UserLanguageMenu,
  },
  mixins: [MixinUser],
  created() {
    this.token = this.$route.params.token;
    this.fullName = this.$route.params.name;
    if (this.$route.params.lang) {
      this.$i18n.locale = this.$route.params.lang;
    }
    this.setAuthState();
  },
  mounted() {},
  methods: {
    changeLangUrl() {
      const newUrl =
        "/userinvitecreate/" + this.$route.params.token + "/" + this.$route.params.name + "/" + this.$i18n.locale;
      this.$router.push(newUrl);
    },
    setAuthState() {
      this.auth().onAuthStateChanged(user => {
        if (user) {
          this.$router.push("/navigator/home");
        }
      });
    },
    setNameAndPassword() {
      const self = this;
      const data = {
        token: this.token,
        name: this.fullName,
        password: this.password,
      };
      axios
        .post(self.$service.admin + "invite/setuser", data)
        .then(function(response) {
          const email = response.data.email;
          self.signInWithEmail(email, self.password);
        })
        .catch(err => {
          alert(self.$t("password.alertSetPasswordFailed"));
          self.$router.push("/auth");
        });
    },
  },
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #0c0c0e;
}
.user-invite-content {
  max-width: 472px;
  margin: 0 auto;
}

.invite-box {
  position: relative;
  margin-top: 36px;
  padding: 48px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.create-account-title {
  color: #ffffff;
  font-family: Roboto;
  font-size: 32px;
  line-height: 44px;
  margin: 0;
}
.create-account-subtitle {
  height: 30px;
  color: #8d909f;
  font-family: Roboto;
  font-size: 20px;
  line-height: 30px;
}
/* for fixing Chrome auto-filling bug of Vuetify */
.create-input ::v-deep input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #17181a inset !important;
}
.create-input ::v-deep input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.no-transform {
  text-transform: none !important;
}
.language-btn {
  position: absolute;
  top: 32px;
  right: 48px;
}
@media (max-width: 436px) {
  .user-invite-create {
    overflow: auto;
    padding: 0 12px 36px 12px;
  }
  .user-invite-content {
    margin-top: 100px;
  }
  .language-btn {
    right: 12px;
  }
}
@media screen and (min-width: 437px) {
  .fullscreen-center {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
</style>
