<template>
  <section class="builder-share" @click="hideDropdown()" @mouseover="hideDropdown()">
    <div class="scroll-div">
      <div v-if="downloadStatus != 'processing'" class="close-bg" @click="$emit('closeBuilderShare')"></div>
      <div class="share-workflow-wrapper fullscreen-center">
        <div class="window-title-div">
          <p class="window-title">{{ $t("builder.shareWorkflow") }}</p>
          <button class="window-close" @click="$emit('closeBuilderShare')">
            <icon-base color="#8D909F" height="16" width="16">
              <d-icon-close-2 />
            </icon-base>
          </button>
        </div>
        <div
          class="share-videos-div"
          :class="{ 'disable-for-download': showShareType === 'download' && downloadVideoInfo.isDownloadMode }"
        >
          <!-- share options -->
          <div class="share-options">
            <button class="share-btn" @click="setShareWorkflow(true)">
              <icon-base v-if="shareFullWorkflow" width="20" height="20">
                <d-icon-circle-checked />
              </icon-base>
              <div v-else class="unchecked-circle"></div>
              <span class="share-btn-text">{{ $t("builder.fullWorkflow") }}</span>
            </button>

            <button class="share-btn" @click="setShareWorkflow(false)">
              <icon-base v-if="!shareFullWorkflow" width="20" height="20">
                <d-icon-circle-checked />
              </icon-base>
              <div v-else class="unchecked-circle"></div>
              <span class="share-btn-text">{{ $t("builder.specificSteps") }}</span>
            </button>
          </div>

          <!-- Full Workflow -->
          <div v-if="shareFullWorkflow" class="option-wrapper">
            <d-video-secure-multistep
              ref="fullVideo"
              :steps="steps"
              :workflow="workflow"
              preload="auto"
              controls="simple"
              :videoMaxHeight="fullWorkflowMaxHeight"
            >
            </d-video-secure-multistep>
          </div>

          <!-- Specific Steps -->
          <div v-else class="option-wrapper specific-steps">
            <v-layout row wrap>
              <v-flex v-for="(step, idx) in steps" :key="idx" xs6 sm6 md6 lg4 xl3 d-flex>
                <div class="step-card mx-2 mb-3" :class="{ 'step-card-current': currentCheckedIdx == idx }">
                  <div class="video-container">
                    <d-video-secure
                      ref="videoPlayer"
                      :poster="steps[idx].poster"
                      :signedSrc="signedVideoSource(idx)"
                      class="step-video-player"
                      preload="metadata"
                      webkit-playsinline
                      playsinline
                      @ended="isPlaying = false"
                    ></d-video-secure>
                    <div
                      class="step-video-player video-btn-cover"
                      :class="{ 'video-btn-show': currentCheckedIdx == idx }"
                    >
                      <button class="video-btn-check" @click="clickSpecificStep(idx)">
                        <icon-base v-if="currentCheckedIdx == idx" width="20" height="20">
                          <d-icon-circle-checked />
                        </icon-base>
                        <icon-base v-else width="20" height="20">
                          <d-icon-circle-unchecked />
                        </icon-base>
                      </button>
                      <div class="fullscreen-center pt-3">
                        <button v-if="currentPlayerIdx != idx" @click="playOrPauseVideo(idx)">
                          <icon-base color="white" width="60" height="60" viewBox="0 0 24 24">
                            <d-icon-play />
                          </icon-base>
                        </button>
                        <button v-else @click="playOrPauseVideo(idx)">
                          <icon-base v-if="!isPlaying" color="white" width="60" height="60" viewBox="0 0 24 24">
                            <d-icon-play />
                          </icon-base>
                          <icon-base
                            v-else
                            color="white"
                            width="60"
                            height="60"
                            viewBox="0 0 24 24"
                            :class="{ 'hide-when-play': isPlaying }"
                          >
                            <d-icon-pause />
                          </icon-base>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="step-info-div">
                    <p class="step-title">
                      <span class="step-num">{{ getStepNum(idx) }}.</span>
                      {{ step.title }}
                    </p>
                    <div class="result-time">{{ getTimeFormat(step.duration) }}</div>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </div>
        </div>

        <div class="share-btns-div">
          <div class="share-links-div">
            <!-- share btns row -->
            <div
              class="share-btns-row"
              :style="{
                '--tabs-numbers': exportOptionsEnabled ? 4 : 3,
              }"
            >
              <button
                class="share-type-btn mr-1"
                :class="{
                  'share-type-btn-active': showShareType === 'qr-code',
                  'share-type-btn-disable': !shareFullWorkflow,
                }"
                @click="showShareType = 'qr-code'"
              >
                <icon-base width="16" height="16" class="type-icon">
                  <d-icon-q-r-code />
                </icon-base>
                <span class="text-md-left">{{ $t("builder.qrCode") }}</span>
              </button>
              <button
                class="share-type-btn mr-1"
                :class="{
                  'share-type-btn-active': showShareType === 'link',
                  'share-type-btn-disable': !shareFullWorkflow,
                }"
                @click="showShareType = 'link'"
              >
                <icon-base width="16" height="16" class="type-icon">
                  <d-icon-link />
                </icon-base>
                <span class="text-md-left">{{ $t("builder.link") }}</span>
              </button>
              <button
                class="share-type-btn mr-1"
                :class="{ 'share-type-btn-active': showShareType === 'embed' }"
                @click="showShareType = 'embed'"
              >
                <icon-base width="18" height="18" class="type-icon">
                  <d-icon-embed-code />
                </icon-base>
                <span class="text-md-left">{{ $t("builder.embedLink") }}</span>
              </button>
              <button
                v-if="exportOptionsEnabled"
                class="share-type-btn"
                :class="{ 'share-type-btn-active': showShareType === 'download' }"
                @click="showDownloadShareType"
              >
                <!-- , 'share-type-btn-disable':shareFullWorkflow -->
                <icon-base width="18" height="18" class="type-icon">
                  <d-icon-export />
                </icon-base>
                <span class="text-md-left">{{ $t("builder.export") }}</span>
              </button>
            </div>

            <!-- check circles -->
            <div v-if="showShareType !== 'download'" class="share-btns-row mt-4">
              <button
                v-if="!customizedPermissions.disablePublicLinks"
                class="share-btn"
                @click="deephowUsersOnly = true"
              >
                <icon-base v-if="deephowUsersOnly" width="20" height="20">
                  <d-icon-circle-checked />
                </icon-base>
                <div v-else class="unchecked-circle"></div>
                <span class="share-btn-text">{{ $t("builder.privateWorkflow") }}</span>
              </button>

              <button
                v-if="!customizedPermissions.disablePublicLinks && !getter_is_gen2"
                class="share-btn"
                @click="showPrivacyPopup = true"
              >
                <icon-base v-if="!deephowUsersOnly" width="20" height="20">
                  <d-icon-circle-checked />
                </icon-base>
                <div v-else class="unchecked-circle"></div>
                <span class="share-btn-text">{{ $t("builder.publicWorkflow") }}</span>
              </button>
            </div>

            <template v-if="showShareType === 'download' && shareFullWorkflow">
              <div class="share-btns-row mt-4">
                <label class="download-label">{{ `${$t("builder.downloadFileFormat")}:` }}</label>
                <button v-if="exportMp4Enabled" class="share-btn" @click="formatType = 'mp4'">
                  <icon-base v-if="formatType === 'mp4'" width="20" height="20">
                    <d-icon-circle-checked />
                  </icon-base>
                  <div v-else class="unchecked-circle"></div>
                  <span class="share-btn-text">MP4</span>
                </button>

                <button v-if="getter_scorm_download_enabled" class="share-btn" @click="formatType = 'scorm'">
                  <icon-base v-if="formatType === 'scorm'" width="20" height="20">
                    <d-icon-circle-checked />
                  </icon-base>
                  <div v-else class="unchecked-circle"></div>
                  <span class="share-btn-text">SCORM</span>
                </button>

                <button v-if="getter_ul_integration_enabled" @click="formatType = 'complianceWire'" class="share-btn">
                  <icon-base v-if="formatType === 'complianceWire'" width="20" height="20">
                    <d-icon-circle-checked />
                  </icon-base>
                  <div v-else class="unchecked-circle"></div>
                  <span class="share-btn-text">ComplianceWire</span>
                </button>
              </div>
            </template>

            <!-- share details-->
            <!-- link and embed link -->
            <div v-if="showShareType === 'link' || showShareType === 'embed'" class="share-link-box" ref="copyLink">
              {{ copyCode }}
            </div>

            <!-- download -->
            <keep-alive>
              <template v-if="showShareType === 'download'">
                <d-share-download
                  v-if="formatType === 'mp4'"
                  :class="{ 'disable-for-download': showShareType === 'download' && downloadVideoInfo.isDownloadMode }"
                  @fetchDownloadVideoConfig="fetchDownloadVideoConfig"
                  v-bind="v_bind_download_config"
                />

                <d-share-scorm-download
                  v-else-if="formatType === 'scorm' && shareFullWorkflow"
                  :steps="steps"
                  :selectedResolution="selectedSCORMResolution"
                  @change-resolution="selectedSCORMResolution = $event"
                />

                <d-share-complianceWire-export
                  v-else-if="formatType === 'complianceWire'"
                  :code="code"
                  :workflowId="workflow.id"
                  @setComplianceWireExportStatus="complianceWireExportStatus = 'loading'"
                  @setComplianceWireProcessingResult="setComplianceWireProcessingResult"
                  @setCode="setCode"
                  @setUlOrganization="setUlOrganization"
                  @setApprovalDate="setApprovalDate"
                />
              </template>
            </keep-alive>

            <!-- qr-code -->
            <div
              class="qr-code-box"
              :class="{ 'show-qr-code-box': showShareType == 'qr-code' && shareFullWorkflow }"
              v-if="showShareType === 'qr-code'"
            >
              <div class="qrcodeCapture" ref="qrcodeCapture">
                <d-q-r-code-template
                  :isPrivate="deephowUsersOnly || customizedPermissions.disablePublicLinks"
                  :redirectLink="shareRedirectLink"
                  :title="workflow.title"
                  :logo="logo"
                />
              </div>
            </div>
          </div>
          <!-- bottom-btns -->
          <div class="bottom-btns-div">
            <v-card-actions class="pt-1 px-4">
              <v-spacer></v-spacer>
              <v-btn
                round
                outline
                class="caption text-capitalize btn-width"
                color="#4689f4"
                @click="$emit('closeBuilderShare')"
                >{{ $t("all.close") }}</v-btn
              >
              <template v-if="showShareType === 'download'">
                <v-btn
                  v-if="formatType === 'mp4'"
                  round
                  depressed
                  dark
                  class="caption white--text ml-3 no-transform"
                  color="#4689f4"
                  :disabled="
                    ['processing', 'ready'].includes(downloadStatus) ||
                    isWaitingAPIResponse ||
                    (hasSubtitles && !isAllowedLanguageDownload(selectedLanguage))
                  "
                  @click="prepareDownload()"
                  >{{ $t("builder.prepareToDownload") }}</v-btn
                >

                <v-btn
                  v-if="
                    formatType === 'scorm' &&
                    selectedSCORMResolution &&
                    isAllowedDownloadSCORM(steps, selectedSCORMResolution)
                  "
                  round
                  depressed
                  dark
                  class="caption white--text ml-3 no-transform"
                  color="#4689f4"
                  :disabled="['processing', 'ready'].includes(downloadSCORMStatus)"
                  @click="prepareDownloadSCORM(workflow, steps, selectedSCORMResolution)"
                  >{{ $t("builder.download") }}</v-btn
                >

                <v-btn
                  v-if="formatType === 'complianceWire'"
                  @click="exportComplianceWireData"
                  :disabled="!!complianceWireExportStatus"
                  round
                  depressed
                  dark
                  class="caption white--text ml-3 no-transform"
                  color="#4689f4"
                  >{{ $t("builder.export") }}</v-btn
                >
              </template>
              <v-btn
                v-if="showShareType === 'qr-code'"
                round
                depressed
                class="caption text-capitalize white--text ml-3 btn-width"
                color="#4689f4"
                :disabled="!shareRedirectLink"
                @click="saveQRCode()"
                >{{ $t("builder.saveQRCode") }}</v-btn
              >
              <v-tooltip
                v-if="['link', 'embed'].includes(showShareType)"
                light
                v-model="showTooltip"
                :disabled="!showTooltip"
                top
                color="#2C2D32"
              >
                <v-btn
                  round
                  depressed
                  slot="activator"
                  class="caption text-capitalize white--text btn-width ml-3"
                  color="#4689f4"
                  @click="(showTooltip = !showTooltip), copyToClipboard(copyCode)"
                  :disabled="copyCode == ''"
                  >{{ $t("builder.copyLink") }}</v-btn
                >
                <span>{{ $t("builder.copied") }}!</span>
              </v-tooltip>
            </v-card-actions>
          </div>
        </div>
      </div>
    </div>

    <!--alert: "An error occured while preparing your download. Please try again or contact DeepHow for assistance." -->
    <d-alert
      v-model="showErrorAlert"
      manualClose
      type="error"
      :message="$t('builder.alertErrorPreparingDownload')"
      class="alert-box"
    ></d-alert>
    <d-alert
      v-model="isDownloadSuccess"
      type="success"
      :message="$t('builder.yourVideoWasSuccessfullyDownloaded')"
      :style="alertStyle"
    />

    <!-- Video privacy confirmation dialog -->
    <v-dialog dark v-model="showPrivacyPopup" max-width="460px">
      <v-card color="#1E1F22" class="pa-0 text-xs-left">
        <v-card-title class="title font-weight-regular grey--text">
          {{ $t("builder.videoPrivacy") }}
          <v-spacer></v-spacer>
          <v-icon small color="grey" @click="showPrivacyPopup = false">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <h3 class="font-weight-regular pb-3">
            {{ $t("builder.popupMsgVideoPrivacy") }}
            <!-- When you turn your workflow public, anyone can have access, even without a DeepHow account. Are you sure? -->
          </h3>
        </v-card-text>
        <v-card-actions class="pr-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn round outline class="caption text-capitalize mr-2" color="white" @click="showPrivacyPopup = false">{{
            $t("all.no")
          }}</v-btn>
          <v-btn
            round
            depressed
            class="caption white--text no-transform"
            color="#4689f4"
            @click="(showPrivacyPopup = false), (deephowUsersOnly = false)"
            >{{ $t("all.yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <d-share-scorm-download-popup
      v-if="downloadSCORMStatus === 'processing'"
      v-extend-idle:builderShareDownloadSCORM.mixinIdleRef="this"
      :videoInfo="{ title: getSCORMFileName(workflow.title) }"
      fileFormat=".zip"
      :description="$t('builder.processingDownloadVideoMsg')"
      :title="$t('builder.processingWorkflow')"
      :processingDescription="$t('builder.openAnotherTabMsg')"
      :processingBtnText="$t('builder.openNewTab')"
      @click-processing-btn="openNewTag()"
    />
    <!-- it will popup if download fail -->
    <DConfirmationPopup
      v-model="showDownloadVideoFailPopup"
      :title="$t('builder.alertFailedDownloadVideo')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('builder.prepareToDownload')"
      :content="$t('builder.alertErrorPreparingDownload')"
      @clickActionBtn="retryPrepareVideo"
    />
    <d-confirmation-popup-sm
      v-model="showStepLostTranscriptionPopup"
      :actionBtnName="$t('all.close')"
      :content="$t('builder.alertErrorStepLostTranscription')"
      @clickActionBtn="closeStepLostTranscriptionPopup"
    />

    <d-share-download-alert
      v-if="showShareType === 'download' && downloadStatus !== 'no-video'"
      v-bind="v_bind_download_alert"
      @download-video="downloadProcessedVideo"
    />
    <DConfirmationPopup
      v-model="showDownloadSCORMFailPopup"
      :title="$t('builder.alertFailedDownloadVideo')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('builder.prepareToDownload')"
      :content="$t('builder.alertErrorPreparingDownload')"
      @clickActionBtn="prepareDownloadSCORM(workflow, steps, selectedSCORMResolution)"
    />

    <d-share-compliance-wire-export-notification
      v-if="complianceWireExportStatus"
      :status="complianceWireExportStatus"
      :code="code"
    />

    <d-alert
      v-model="showComplianceWireNotification"
      :type="complianceWireProcessResult.type"
      :message="complianceWireProcessResult.message"
      :style="alertStyle"
    />
  </section>
</template>

<script>
import { fetchOrganizationLogo } from "../server/admin-server";
import IconBase from "./IconBase.vue";
import DIconClose2 from "./icons/DIconClose2.vue";
import DIconCircleUnchecked from "./icons/DIconCircleUnchecked.vue";
import DIconCircleChecked from "./icons/DIconCircleChecked.vue";
import DIconPlay from "./icons/DIconPlay.vue";
import DIconPause from "./icons/DIconPause.vue";
import DIconArrowForward from "./icons/DIconArrowForward.vue";
import DIconArrowBack from "./icons/DIconArrowBack.vue";
import DIconCircleLock from "./icons/DIconCircleLock.vue";
import DIconThreePeople from "./icons/DIconThreePeople.vue";
import DIconEmbedCode from "./icons/DIconEmbedCode.vue";
import DIconLink from "./icons/DIconLink.vue";
import DIconQRCode from "./icons/DIconQRCode.vue";
import DIconExport from "./icons/DIconExport.vue";
import DQRCodeTemplate from "./ui_components/DQRCodeTemplate.vue";
import DIconProcessingBarFlex from "./icons/animated/DIconProcessingBarFlex.vue";
import DIconVideoFile from "./icons/DIconVideoFile.vue";
import DConfirmationPopup from "./DPopup/DConfirmationPopup.vue";
import DAlert from "./ui_components/DAlert.vue";
import MixinDB from "./MixinDB.vue";
import MixinVideo from "./MixinVideo.vue";
import { setTimeout } from "timers";
import DVideoSecure from "./ui_components/DVideoSecure.vue";
import DVideoSecureMultistep from "@/components/ui_components/DVideoSecureMultistep.vue";
import DConfirmationPopupSm from "./ui_components/DConfirmationPopupSm.vue";
import html2canvas from "html2canvas";
import DExtendIdle from "@/directives/DExtendIdle";
import MixinIdle from "@/components/MixinIdle.vue";
import { CONVERSION_STATUS } from "@/constants/workflowStatus";
import { DOWNLOAD_VIDEO_TYPE } from "@/constants/downloadVideoStatus";
import { createConversion } from "../server/video-server";
import DShareDownload from "@/components/DBuilder/BuilderShare/DShareDownload.vue";
import DShareDownloadAlert from "@/components/DBuilder/BuilderShare/DShareDownloadAlert.vue";
import { isAllowedLanguageDownload } from "@/js/workflow/language.js";
import DShareScormDownload from "@/components/DBuilder/BuilderShare/DShareScormDownload.vue";
import DShareScormDownloadPopup from "@/components/DBuilder/BuilderShare/DShareScormDownloadPopup.vue";
import DShareComplianceWireExport from "@/components/DBuilder/BuilderShare/DShareComplianceWireExport.vue";
import DShareComplianceWireExportNotification from "@/components/DBuilder/BuilderShare/DShareComplianceWireExportNotification.vue";
import { getStepVideoMetadata, fetchVideoBlobByUrl, fetchVttFile } from "@/js/workflow-scorm/video.js";
import {
  makeCourseHtmlFile,
  makeImsmanifestXmlFile,
  fetchSCORMSchemas,
  getSCORMFileName,
  makeSCORMZip,
  downloadSCORMZip,
  isAllowedDownloadSCORM,
} from "@/js/workflow-scorm/course.js";
import { getSignedUrlByRez } from "@/server/sign-server.js";
import { exportComplianceWire } from "@/server/ul-integration-server.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BuilderShare",
  watch: {
    shareFullWorkflow: function () {
      this.isPlaying = false;
      if (!this.shareFullWorkflow) {
        if (!this.downloadVideoInfo.isDownloadMode) {
          this.currentCheckedIdx = 0;
        }
        if (this.showShareType == "qr-code" || this.showShareType == "link") {
          this.showShareType = "embed";
        }
      }
      this.changePrivacy();
    },
    currentCheckedIdx: function () {
      this.getStepLink();
    },
    deephowUsersOnly: function () {
      this.changePrivacy();
    },
    showShareType: function () {
      this.changeShareType();
    },
    hasSubtitles: function (hasSubtitles) {
      if (!hasSubtitles) {
        this.selectedLanguage = "";
      }
    },
  },
  props: {
    steps: {
      type: Array,
    },
    workflow: {
      type: Object,
    },
    videoWidthViewer: {
      type: Number,
    },
    translationList: {
      type: Array,
    },
    downloadVideoInfo: {
      type: Object,
    },
  },
  mixins: [MixinDB, MixinVideo, MixinIdle],
  directives: {
    "extend-idle": DExtendIdle,
  },
  data() {
    return {
      shareRedirectLink: "",
      logo: "",
      shareFullWorkflow: true,
      isPlaying: false,
      currentPlayerIdx: 0,
      currentCheckedIdx: 0,
      formatType: "mp4",
      copyCode: "",
      showTooltip: false,
      currentStep: 0,
      deephowUsersOnly: true,
      showDropdown: false,
      showShareType: "qr-code",
      mouseoverDropdown: false,
      showPrivacyPopup: false,
      videoLink: "",
      // processingDownload: false,
      redirectId: "",
      hasSubtitles: true,
      selectedLanguage: "",
      downloadStatus: "no-video", // no-video, processing, ready
      downloadUrl: "",
      downloadProgress: 0,
      downloading: false,
      showErrorAlert: false,
      downloadingVideoTitle: "",
      previousDownload: true,
      customizedPermissions: null,
      showDownloadVideoFailPopup: false,
      showStepLostTranscriptionPopup: false,
      fullWorkflowMaxHeight: 412,
      // TODO: PD-320 temporarily remove big subtitle,
      downloadSubtitlesFontSize: this.$backendType === "ali" ? "medium" : "small", //small, medium, big,
      isDownloadSuccess: false,
      downloadSCORMStatus: "no-scorm", // no-scorm, processing, ready
      showDownloadSCORMFailPopup: false,
      selectedSCORMResolution: 0, // options are 720, 1080
      isWaitingAPIResponse: false,
      complianceWireExportStatus: "",
      showComplianceWireNotification: false,
      complianceWireProcessResult: {},
      code: `DH_${this.workflow.id}`,
      selectedUlOrganization: "",
      approvalDate: new Date().toISOString().substring(0, 10),
    };
  },
  components: {
    IconBase,
    DIconClose2,
    DIconCircleUnchecked,
    DIconCircleChecked,
    DIconPlay,
    DIconPause,
    DIconArrowForward,
    DIconArrowBack,
    DIconCircleLock,
    DIconThreePeople,
    DIconEmbedCode,
    DIconLink,
    DIconQRCode,
    DIconExport,
    DQRCodeTemplate,
    DIconProcessingBarFlex,
    DIconVideoFile,
    DAlert,
    DVideoSecure,
    DConfirmationPopup,
    DConfirmationPopupSm,
    DVideoSecureMultistep,
    DShareDownload,
    DShareDownloadAlert,
    DShareScormDownload,
    DShareScormDownloadPopup,
    DShareComplianceWireExport,
    DShareComplianceWireExportNotification,
  },
  methods: {
    ...mapActions("permission", ["getOrgPermissionsSettings"]),
    closeStepLostTranscriptionPopup() {
      this.showStepLostTranscriptionPopup = false;
    },
    setShareWorkflow(isFullWorkflow) {
      this.shareFullWorkflow = isFullWorkflow;

      if (!isFullWorkflow) {
        this.formatType = "mp4";
      }
    },
    async retryPrepareVideo() {
      try {
        this.showDownloadVideoFailPopup = false;
        const result = await this.prepareDownload();
        if (!result.ok) {
          this.resetPrepareDownloadStatus();
          await this.clearDownloadRecord();
        }
      } catch (e) {
        console.log(e);
      }
    },
    openNewTag() {
      const url = window.location.origin + "/editorexplorer";
      const win = window.open(url, "_blank");
      win.focus();
    },
    resetPrepareDownloadStatus() {
      const videoInfo = {
        isDownloadMode: false,
        isFullWorkflow: true,
        stepIdx: -1,
        title: "",
        hasSubtitles: false,
        language: "",
      };
      this.$emit("setDownloadVideoInfo", videoInfo);
      this.downloadStatus = "no-video";
    },
    setPreviousDownloadVideoInfo(previousJob, workflowStepIds) {
      const { type, downloadVideo = {}, onlyOneStep } = previousJob;
      const isOldDownloadType = type === "merge"; // new type is download, it's different structure.
      const { stepIds = [] } = downloadVideo;
      const { isFullWorkflow, selectedStepId, hasSubtitles, language, fontSize } = isOldDownloadType
        ? {
            isFullWorkflow: !onlyOneStep,
            selectedStepId: onlyOneStep,
            hasSubtitles: previousJob.hasSubtitles,
            language: previousJob.language,
            fontSize: previousJob.fontSize,
          }
        : {
            isFullWorkflow: stepIds.length === 0,
            selectedStepId: stepIds[0],
            hasSubtitles: downloadVideo.hasSubtitles,
            language: downloadVideo.language,
            fontSize: downloadVideo.fontSize,
          };
      const stepIdx = isFullWorkflow ? -1 : workflowStepIds.findIndex((stepId) => stepId === selectedStepId);
      this.hasSubtitles = hasSubtitles;
      this.selectedLanguage = language;
      this.shareFullWorkflow = isFullWorkflow;
      this.currentCheckedIdx = stepIdx;
      this.downloadSubtitlesFontSize = fontSize;
      this.downloadingVideoTitle = this.getDownloadFileName() + ".mp4";
      const videoInfo = {
        isDownloadMode: true,
        isFullWorkflow,
        stepIdx,
        title: this.getDownloadFileName(),
        hasSubtitles,
        language,
      };
      this.$emit("setDownloadVideoInfo", videoInfo);
    },
    getSteps(stepsArray) {
      const self = this;
      return new Promise(function (resolve, reject) {
        const promises = [];
        stepsArray.forEach((step) => {
          promises.push(self.getDocument("steps", step));
        });
        Promise.all(promises).then((steps) => {
          resolve(steps);
        });
      });
    },
    getS3URL(url) {
      url = url.slice(8);
      const s3 = "s3://" + url.slice(0, url.indexOf("s3") - 1) + url.slice(url.indexOf("/"));
      return s3;
    },
    async getConversionRequestsByWorkflowId(workflowId) {
      try {
        const data = await this.getDocumentByQuery("conversions", [
          {
            field: "type",
            comparison: "in",
            value: ["download", "merge"],
          },
          {
            field: "workflowId",
            comparison: "==",
            value: workflowId,
          },
        ]);
        return data;
      } catch (e) {
        alert("failed to check existing merge requests.");
        return [];
      }
    },
    getProcessingJob(conversionRequests) {
      const processingStatus = [
        CONVERSION_STATUS.RUNNING,
        CONVERSION_STATUS.JOB_SCHEDULED,
        CONVERSION_STATUS.TRANSCODING,
      ];
      return conversionRequests.find((conversion) => processingStatus.includes(conversion.status));
    },
    getPreparedJob(conversionRequests, downloadUrl) {
      return conversionRequests.find(
        (conversion) => conversion.status === CONVERSION_STATUS.FINISHED && conversion.download.url === downloadUrl
      );
    },
    async getPreviousDownload() {
      const conversionRequests = await this.getConversionRequestsByWorkflowId(this.workflow.id);
      const processingJob = this.getProcessingJob(conversionRequests);
      if (processingJob) {
        this.downloadStatus = "processing";
        this.setPreviousDownloadVideoInfo(processingJob, this.workflow.steps);
        this.getDownloadReadyListener();
        this.getConversionRequestListener(processingJob.id);
        return;
      }
      if (this.previousDownload && this.workflow.download) {
        const downloadUrl = this.workflow.download.url;
        const preparedJob = this.getPreparedJob(conversionRequests, downloadUrl);
        this.downloadStatus = "ready";
        this.downloadUrl = downloadUrl;
        this.setPreviousDownloadVideoInfo(preparedJob, this.workflow.steps);
      }
    },
    getDownloadReadyListener() {
      const self = this;
      const listener = this.getDocumentListener("workflows", this.workflow.id, function (doc) {
        const data = doc.data();
        if (self.$backendType === "ali") self.$emit("updateWorkflow", data);
        if (data.download && data.download.languageCode == self.selectedLanguage) {
          //download is available
          self.downloadStatus = "ready";
          self.downloadUrl = data.download.url;
          listener();
          // self.downloadProcessedVideo();
        }
      });
    },
    getConversionRequestListener(id) {
      const self = this;
      const listener = this.getDocumentListener("conversions", id, function (doc) {
        const data = doc.data();
        if (data.errorCode) {
          self.showErrorAlert = true;
          self.resetPrepareDownloadStatus();
          listener();
          return;
        }
        if (data.status === CONVERSION_STATUS.FINISHED) {
          listener();
          return;
        }
      });
    },
    async createDownloadJob(workflow) {
      const customPayload = this.shareFullWorkflow ? {} : { stepIds: [workflow.steps[this.currentCheckedIdx]] };

      const payload = {
        workflowId: workflow.id,
        language: this.selectedLanguage,
        hasSubtitles: this.hasSubtitles,
        fontSize: this.downloadSubtitlesFontSize,
        hasAnnotation: true,
        type: "download",
        ...customPayload,
      };

      const { ok, data, errorMessage } = await createConversion(payload);
      if (!ok) {
        this.isWaitingAPIResponse = false;
        this.downloadStatus = "no-video";
        alert("failed to prepare for download.");
        return { ok: false, id: null, error: errorMessage };
      }
      return { ok: true, id: data.item.id };
    },
    clearDownloadRecord() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .updateDocument("workflows", self.workflow.id, { download: null })
          .then(() => {
            self.workflow.download = null;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadProcessedVideo() {
      const self = this;
      this.downloading = true;
      this.downloadingVideoTitle = this.getDownloadFileName() + ".mp4";
      const callback = function (event) {
        self.downloadProgress = (event.loaded / event.total) * 100;
      };
      this.downloadBlobVideo(this.downloadUrl, this.getDownloadFileName(), ".mp4", "video/mp4", callback)
        .then(() => {
          self.resetPrepareDownloadStatus();
          self.downloading = false;
          this.selectedLanguage = "";
          self.downloadProgress = 0;
          self.downloadingVideoTitle = "";
          self.previousDownload = false;
          self.clearDownloadRecord();
          this.isDownloadSuccess = true;
          // TODO: PD-320 temporarily remove big subtitle,
          this.downloadSubtitlesFontSize = this.$backendType === "ali" ? "medium" : "small";
        })
        .catch(() => {
          self.downloading = false;
          self.downloadProgress = 0;
          self.showDownloadVideoFailPopup = true;
        });
    },
    clickSpecificStep(idx) {
      this.currentCheckedIdx = idx;
      this.selectedLanguage = "";
      this.downloadStatus = "no-video";
    },
    fetchDownloadVideoConfig(config) {
      this.hasSubtitles = !["off", ""].includes(config[DOWNLOAD_VIDEO_TYPE.SUBTITLE]);
      this.downloadSubtitlesFontSize = config[DOWNLOAD_VIDEO_TYPE.FONT_SIZE];
      if (this.hasSubtitles) {
        this.changeSubtitleLanguage(config[DOWNLOAD_VIDEO_TYPE.SUBTITLE]);
      }
    },
    checkAllStepsHasTranscriptionByLanguage(language) {
      const checkSteps = this.shareFullWorkflow ? this.steps : [this.steps[this.currentCheckedIdx]];
      const lostTranscriptionStep = checkSteps.find((step) => !step.transcriptions[language]);
      return lostTranscriptionStep ? false : true;
    },
    changeSubtitleLanguage(language) {
      const hasTranscription = this.checkAllStepsHasTranscriptionByLanguage(language);
      if (!hasTranscription) {
        this.showStepLostTranscriptionPopup = true;
        return;
      }
      this.selectedLanguage = language;
    },
    async prepareDownload() {
      this.isWaitingAPIResponse = true;
      this.downloadStatus = "processing";
      const result = await this.createDownloadJob(this.workflow);
      if (result.ok) {
        this.isWaitingAPIResponse = false;
        this.downloadingVideoTitle = this.getDownloadFileName() + ".mp4";
        const videoInfo = {
          isDownloadMode: true,
          isFullWorkflow: this.shareFullWorkflow,
          stepIdx: this.currentCheckedIdx,
          title: this.getDownloadFileName(),
          hasSubtitles: this.hasSubtitles,
          language: this.selectedLanguage,
        };
        this.$emit("setDownloadVideoInfo", videoInfo);
        this.getDownloadReadyListener();
        this.getConversionRequestListener(result.id);
      }
      return result;
    },
    getDownloadFileName() {
      let fileName = "";
      if (this.shareFullWorkflow) {
        if (this.hasSubtitles) {
          fileName = this.workflow.title.replace(/\s+/g, "_") + "(" + this.selectedLanguage + ")";
        } else {
          fileName = this.workflow.title.replace(/\s+/g, "_");
        }
      } else {
        const stepNum = this.currentCheckedIdx + 1;
        const currentStep = this.steps[this.currentCheckedIdx];
        const stepTitle = currentStep ? currentStep.title.replace(/\s+/g, "_") : "";
        if (this.hasSubtitles) {
          fileName =
            this.workflow.title.replace(/\s+/g, "_") +
            "_" +
            stepNum +
            "_" +
            stepTitle +
            "(" +
            this.selectedLanguage +
            ")";
        } else {
          fileName = this.workflow.title.replace(/\s+/g, "_") + "_" + stepNum + "_" + stepTitle;
        }
      }
      return fileName;
    },
    // downloadVideo() {
    //   if (!this.steps[this.currentCheckedIdx].videos) {
    //     alert(
    //       "Video cannot be downloaded while transcoding is in progress. Please try again later."
    //     );
    //   } else {
    //     const self  = this
    //     self.processingDownload=true;
    //     const rezOptions = Object.keys(
    //       this.steps[this.currentCheckedIdx].videos
    //     );
    //     const highestRez = rezOptions[rezOptions.length - 1];
    //     const downloadLink = this.steps[this.currentCheckedIdx].videos[
    //       highestRez
    //     ];
    //     const stepId = this.steps[this.currentCheckedIdx].id;
    //     var x = new XMLHttpRequest();
    //     x.open("GET", downloadLink, true);
    //     x.responseType = "blob";
    //     x.onload = function(e) {
    //       self.processingDownload=false;
    //       Downloadjs(e.target.response, stepId + ".mp4", "video/mp4");
    //     };
    //     x.onprogress = function (event) {
    //       self.downloadProgress = event.loaded/event.total;
    //     };
    //     x.send();
    //   }
    // },
    makeWorkflowPublic(isPublic) {
      const promises = [];
      return new Promise(
        function (resolve, reject) {
          if (this.redirectId || this.workflow.redirect) {
            const id = this.redirectId || this.workflow.redirect;
            promises.push(
              this.updateDocument("redirect", id, {
                public: isPublic,
              })
            );
          }
          promises.push(
            this.updateDocument("workflows", this.workflow.id, {
              public: isPublic,
            })
          );
          Promise.all(promises)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        }.bind(this)
      );
    },
    hideDropdown() {
      if (!this.mouseoverDropdown) {
        this.showDropdown = false;
      }
    },
    getStepNum(index) {
      index++;
      if (index < 10) {
        return "0" + index.toString();
      } else {
        return index.toString();
      }
    },
    playOrPauseFullVideo() {
      this.isPlaying = !this.isPlaying;
      const videoPlayer = this.$refs.fullVideo;
      if (this.isPlaying) {
        videoPlayer.play();
      } else {
        videoPlayer.pause();
      }
    },
    changePrivacy() {
      if (this.shareFullWorkflow) {
        this.getFullWorkflowLink();
      } else {
        this.getStepLink();
      }
    },
    getFullWorkflowLink() {
      this.videoLink = "";
      this.copyCode = "";
      if (this.deephowUsersOnly) {
        this.makeWorkflowPublic(false)
          .then(() => {
            this.videoLink = window.location.origin + "/playermain/" + this.workflow.id;
            this.changeShareType();
          })
          .catch((err) => {
            this.copyCode = "Failed to make the workflow prviate. Error: " + err;
          });
      } else {
        if (this.customizedPermissions.disablePublicLinks) {
          this.videoLink = window.location.origin + "/playermain/" + this.workflow.id;
          this.changeShareType();
          return;
        }

        this.makeWorkflowPublic(true)
          .then(() => {
            this.videoLink = window.location.origin + "/p/" + this.workflow.id;
            this.changeShareType();
          })
          .catch((err) => {
            this.copyCode = "Failed to make the workflow public. Error: " + err;
          });
      }
    },
    async getOrganizationLogo() {
      const { ok, data } = await fetchOrganizationLogo(this.$organization);
      if (!ok) return;

      const logo = data.item.logo ? data.item.logo.url : "";
      if (!logo) return;
      // transfer to dataUrl prevent cors domain download issue.
      const logoBlob = await fetch(logo).then((r) => r.blob());
      const logoDataURL = await this.blobToDataURL(logoBlob);
      this.logo = logoDataURL;
    },
    blobToDataURL(blob) {
      return new Promise(async (resolve) => {
        const fr = new FileReader();
        fr.onload = function (e) {
          resolve(e.target.result);
        };
        fr.readAsDataURL(blob);
      });
    },
    getStepLink() {
      if (this.deephowUsersOnly) {
        this.makeWorkflowPublic(false)
          .then(() => {
            this.videoLink =
              window.location.origin + "/playermain/" + this.workflow.id + "/" + this.steps[this.currentCheckedIdx].id;
            this.changeShareType();
          })
          .catch((err) => {
            this.copyCode = "Failed to make the workflow prviate. Error: " + err;
          });
      } else {
        if (this.customizedPermissions.disablePublicLinks) {
          this.videoLink =
            window.location.origin + "/playermain/" + this.workflow.id + "/" + this.steps[this.currentCheckedIdx].id;
          this.changeShareType();
          return;
        }

        this.makeWorkflowPublic(true)
          .then(() => {
            this.videoLink =
              window.location.origin + "/p/" + this.workflow.id + "/" + this.steps[this.currentCheckedIdx].id;
            this.changeShareType();
          })
          .catch((err) => {
            this.copyCode = "Failed to make the workflow public. Error: " + err;
          });
      }
    },
    changeShareType() {
      if (this.showShareType == "link") {
        this.copyCode = this.videoLink;
        return;
      }
      if (this.showShareType == "embed") {
        this.copyCode =
          '<iframe width="435" height="245" src="' +
          this.videoLink +
          '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        return;
      }
      if (this.showShareType == "qr-code") {
        this.getQRCode();
        return;
      }
    },
    getTimeFormat(time) {
      time = parseFloat(time).toFixed(0);
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;
      const hours = Math.floor(time / 3600);
      time = time - hours * 3600;
      function str_pad_left(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
      }
      const finalTime = str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
      return finalTime;
    },
    playOrPauseVideo(idx) {
      const previousPlayerIdx = this.currentPlayerIdx;
      this.currentPlayerIdx = idx;
      if (this.isPlaying) {
        if (this.currentPlayerIdx == previousPlayerIdx) {
          this.isPlaying = false;
          this.$refs.videoPlayer[idx].pause();
        } else {
          this.$refs.videoPlayer[previousPlayerIdx].pause();
          this.$refs.videoPlayer[idx].play();
        }
      } else if (this.$refs.videoPlayer[idx]) {
        this.$refs.videoPlayer[idx].play();
        this.isPlaying = true;
      }
    },
    copyToClipboard(str) {
      // Create new element
      const el = document.createElement("textarea");
      // Set value (string to be copied)
      el.value = str;
      // Set non-editable to avoid focus and move outside of view
      el.setAttribute("readonly", "");
      el.style = { position: "absolute", left: "-9999px" };
      document.body.appendChild(el);
      // Select text inside element
      el.select();
      // Copy text to clipboard
      document.execCommand("copy");
      // Remove temporary element
      document.body.removeChild(el);
    },
    signedVideoSource(stepIdx) {
      let idx = this.currentStep;

      if (stepIdx) {
        idx = stepIdx;
      }

      const metadata = {
        type: "step",
        rez: "hls",
        id: this.steps[idx].id,
        notTranscodedVideo: this.steps[idx].video,
      };

      if (this.steps[idx].videos) {
        const rez = Object.keys(this.steps[idx].videos)[0];
        metadata.rez = rez;
      }

      return metadata;
    },
    async getRedirectlink() {
      if (this.workflow.redirect) {
        return `${this.$service.qrcode}${this.workflow.redirect}`;
      }
      const link = await this.createRedirectLink();
      return link;
    },
    createRedirectLink(isPublic) {
      const self = this;
      return new Promise(function (resolve, reject) {
        const promises = [];
        const redirectId = self.generateDocId("redirect");
        self.redirectId = redirectId;
        const redirectLink = self.$service.qrcode + redirectId;
        const data = {
          id: redirectId,
          target: "workflow",
          workflowId: self.workflow.id,
          organization: self.$organization,
          backendType: self.$backendType,
        };
        if (isPublic) {
          data["public"] = true;
        }
        promises.push(self.addDocument("redirect", redirectId, data));
        promises.push(
          self.updateDocument("workflows", self.workflow.id, {
            redirect: redirectId,
          })
        );
        Promise.all(promises)
          .then(() => {
            resolve(redirectLink);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getQRCode() {
      this.shareRedirectLink = await this.getRedirectlink();
    },
    async saveQRCode() {
      const canvas = await html2canvas(this.$refs.qrcodeCapture, { useCORS: true });
      const imgURI = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      const svgTitle = this.workflow.title;
      this.downloadBlobVideo(imgURI, svgTitle, ".png", "image/png");
      return;
    },
    isAllowedLanguageDownload,
    /** Download SCORM */
    getSCORMFileName,
    isAllowedDownloadSCORM,
    async prepareDownloadSCORM(workflow = { title: "" }, steps = [], resolution = 720) {
      const stepsFile = [];
      const subtitleLabelMap = this.$dictionary.universal.languageCode;
      this.showDownloadSCORMFailPopup = false;
      try {
        this.downloadSCORMStatus = "processing";
        for (let i = 0; i < steps.length; i++) {
          const step = steps[i];

          // fetch step video
          const metadata = getStepVideoMetadata(step, resolution);
          const reponse_url = await getSignedUrlByRez(metadata.type, metadata.id, metadata.rez);
          if (!reponse_url.ok) {
            throw reponse_url.error;
          }

          const response_video = await fetchVideoBlobByUrl(reponse_url.url);
          if (!response_video.ok) {
            throw `Failed to fetch video file in step - ${step.id}`;
          }

          // fetch all vtt(transcription) files with different languages of step
          const subtitles = [];
          for (let j = 0; step.subtitles && j < step.subtitles.length; j++) {
            const subtitle = step.subtitles[j];
            const response_subtitle = await fetchVttFile(subtitle);
            if (!response_subtitle.ok) {
              console.error(`Failed to fetch ${subtitle.language} vtt in Step${i + 1}`);
            }
            subtitles.push({
              languageCode: subtitle.language,
              data: response_subtitle.data,
            });
          }

          // make index.html of step
          const response_html = makeCourseHtmlFile({ step, stepIndex: i, subtitleLabelMap });
          if (!response_html.ok) {
            throw `Failed to make index.html in step - ${step.id}`;
          }

          stepsFile.push({
            video: response_video.data,
            html: response_html.data,
            subtitles,
          });
        }

        // fetch schemas definition files what SCORM needed
        const response_schemas = await fetchSCORMSchemas();
        if (!response_schemas.ok) {
          throw response_schemas.errorMessage;
        }
        const response_xml = makeImsmanifestXmlFile(workflow, steps);
        if (!response_xml.ok) {
          throw response_xml.errorMessage;
        }

        // make SCORM package(.zip) and download it
        const response_zip = await makeSCORMZip(workflow, response_schemas.data, response_xml.data, stepsFile);
        if (!response_zip.ok) {
          throw response_zip.errorMessage;
        }
        this.downloadSCORMStatus = "ready";
        const response_download = await downloadSCORMZip(workflow, response_zip.data);
        if (!response_download.ok) {
          throw response_download.errorMessage;
        }
      } catch (errorMessage) {
        this.showDownloadSCORMFailPopup = true;
        console.error(errorMessage);
      } finally {
        this.downloadSCORMStatus = "no-scorm";
        this.selectedSCORMResolution = null;
      }
    },
    setCode(code) {
      this.code = code;
    },
    setUlOrganization(ulOrganization) {
      this.selectedUlOrganization = ulOrganization;
    },
    setApprovalDate(date) {
      this.approvalDate = date;
    },
    async exportComplianceWireData() {
      const payload = {
        workflowId: this.workflow.id,
        organization: this.$organization,
        ulOrganization: this.selectedUlOrganization,
        approvalDate: this.approvalDate,
        ...(this.code ? { code: this.code } : {}),
      };

      this.complianceWireExportStatus = "processing";
      const code = this.code || this.workflow.id;

      try {
        await exportComplianceWire(payload);
        const message = this.$t("builder.alertExportComplianceWireSuccess", { code });
        this.setComplianceWireProcessingResult({ type: "success", message });
      } catch (error) {
        const response = error.response.data;

        // TODO: update fixed error messages by error code.
        // handle particular error messages temporarily.
        const message =
          response && response.includes("exists")
            ? this.$t("builder.alertExportComplianceWireExists", { code })
            : response && response.includes("published")
            ? response + "."
            : this.$t("builder.alertExportComplianceWireFailed");
        this.setComplianceWireProcessingResult({ type: "error", message });
      }
    },
    setComplianceWireProcessingResult(result) {
      this.complianceWireExportStatus = "";
      if (!result) return;

      this.complianceWireProcessResult = result;
      this.showComplianceWireNotification = true;
    },
    setFormatType() {
      this.formatType = this.exportMp4Enabled
        ? "mp4"
        : this.getter_scorm_download_enabled
        ? "scorm"
        : this.getter_ul_integration_enabled
        ? "complianceWire"
        : "mp4";
    },
    showDownloadShareType() {
      this.showShareType = "download";
      this.exportMp4Enabled && this.getPreviousDownload();
    },
  },
  created() {
    this.deephowUsersOnly = !this.workflow.public;
    this.customizedPermissions = this.getCustomizedPermissions();
  },
  async mounted() {
    this.getFullWorkflowLink();
    this.getOrganizationLogo();
    await this.getOrgPermissionsSettings({ organization: this.$organization });
    this.setFormatType();
  },
  computed: {
    ...mapGetters(["getter_is_gen2"]),
    ...mapGetters("permission", [
      "getter_public_sharing_enabled",
      "getter_ul_integration_enabled",
      "getter_scorm_download_enabled",
    ]),
    exportOptionsEnabled() {
      if (this.getter_is_gen2) return false;
      return this.exportMp4Enabled || this.getter_ul_integration_enabled || this.getter_scorm_download_enabled;
    },
    exportMp4Enabled() {
      return this.getter_public_sharing_enabled && !this.customizedPermissions.disableDownloads;
    },
    languageFullName() {
      let locale = this.$i18n.locale;
      if (locale == "en") locale = "en-us";
      return this.$dictionary[locale].languageCode;
    },
    languageList() {
      if (this.translationList) {
        return Object.keys(this.workflow.transcriptions).concat(this.translationList);
      } else {
        return Object.keys(this.workflow.transcriptions);
      }
    },
    videoSource() {
      if (this.steps[this.currentStep].videos) {
        const rez = Object.keys(this.steps[this.currentStep].videos)[0];
        return this.steps[this.currentStep].videos[rez];
      } else {
        return this.steps[this.currentStep].video;
      }
    },
    v_bind_download_config() {
      return {
        downloadSubtitlesFontSize: this.downloadSubtitlesFontSize,
        languageList: this.languageList,
        selectedLanguage: this.selectedLanguage,
      };
    },
    v_bind_download_alert() {
      return {
        downloadStatus: this.downloadStatus,
        downloadingVideoTitle: this.downloadingVideoTitle,
        downloading: this.downloading,
        downloadProgress: this.downloadProgress,
      };
    },
    alertStyle() {
      return {
        bottom: "32px",
        top: "initial",
        right: "32px",
        width: "350px",
      };
    },
  },
};
</script>

<style scoped>
.builder-share {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  overflow: auto;
}
.builder-share:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.scroll-div {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 120px 48px;
  min-width: 1120px;
  min-height: 560px;
}
@media screen and (max-width: 1024px) {
  .scroll-div {
    padding: 120px 24px;
  }
}
.close-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.share-workflow-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 976px;
  min-height: 650px;
  max-height: 760px;
  margin: 0 auto;
  background-color: #1e1f22;
}
.window-title-div {
  position: relative;
  height: 52px;
  width: 100%;
  border-bottom: 1px solid #303030;
}
.window-title {
  position: absolute;
  top: 8px;
  left: 24px;
  height: 32px;
  color: #8d909f;
  font-size: 24px;
}
.window-close {
  position: absolute;
  top: 18px;
  right: 24px;
}
.share-videos-div {
  position: absolute;
  top: 52px;
  left: 0;
  height: calc(100% - 52px);
  width: calc(100% - 620px);
  min-width: 380px;
}
.share-options {
  position: relative;
  height: 90px;
  width: 100%;
  display: flex;
  padding: 48px 48px 0 48px;
}
.share-btn {
  height: 22px;
  margin-right: 32px;
  display: flex;
}
.unchecked-circle {
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border: 1px solid #323338;
  border-radius: 10px;
}
.share-btn:hover .unchecked-circle {
  border: 1px solid #8d909f;
}
.share-btn-text {
  height: 19px;
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  margin: auto 0 auto 11px;
  text-align: left;
}
.download-btn {
  margin: auto 0;
}
.option-wrapper {
  position: absolute;
  top: 90px;
  left: 0;
  height: calc(100% - 90px);
  width: 100%;
  padding: 0 48px;
  border-right: 1px solid #303030;
}
.video-player {
  object-fit: contain;
  height: 100%;
  max-width: 100%;
  background-color: #0c0c0e;
}
.video-btn {
  visibility: hidden;
}
.specific-steps {
  overflow-y: scroll;
  padding: 0 32px 40px 40px;
}
.specific-steps:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.step-card {
  background-color: #323338;
  opacity: 0.5;
}
.step-card-current {
  opacity: 1;
}
.step-card:hover {
  opacity: 1;
  transition: 0.3s;
}
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 */
  height: 0px;
  position: relative;
  background-color: #0d0d0d;
}
.step-video-player {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.video-btn-cover {
  visibility: hidden;
}
.video-container:hover .video-btn-cover {
  visibility: visible;
}
.video-btn-show {
  visibility: visible;
}
.video-btn-check {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.hide-when-play {
  opacity: 0;
}
.hide-when-play:hover {
  opacity: 1;
}
.step-info-div {
  position: relative;
  height: 70px;
  width: 100%;
}
.step-title {
  height: 44px;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.step-num {
  font-weight: 700;
}
.result-time {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #8d909f;
  font-size: 12px;
  line-height: 14px;
  padding: 0 0 8px 8px;
}
.share-btns-div {
  position: absolute;
  top: 52px;
  right: 0;
  height: calc(100% - 52px);
  width: 600px;
  padding: 48px 20px 72px 20px;
}
.share-links-div {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.share-btns-lable {
  height: 42px;
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
}
.share-btns-row {
  --active-index: 2;
  position: relative;
  display: flex;
}
.share-btns-row:has(.share-type-btn):before {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: calc(100% / var(--tabs-numbers) * var(--active-index));
  height: 3px;
  background-color: #4689f3;
  width: calc(100% / var(--tabs-numbers));
  transition: left 0.3s;
}
.share-btns-row:has(.share-type-btn-active:nth-child(1)):before {
  --active-index: 0;
}
.share-btns-row:has(.share-type-btn-active:nth-child(2)):before {
  --active-index: 1;
}
.share-btns-row:has(.share-type-btn-active:nth-child(3)):before {
  --active-index: 2;
}
.share-btns-row:has(.share-type-btn-active:nth-child(4)):before {
  --active-index: 3;
}
.share-type-btn {
  box-sizing: border-box;
  width: 130px;
  font-family: Roboto;
  font-size: 16px;
  color: #8d909f;
  padding: 6px 2px 4px 2px;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.share-type-btn:hover {
  color: #ffffff;
  opacity: 1;
}
.share-type-btn-active {
  font-weight: 500;
  color: #ffffff;
  pointer-events: auto;
  opacity: 1;
}
.share-type-btn-disable {
  opacity: 0.3;
  pointer-events: none;
}
.type-icon {
  margin-right: 8px;
  min-width: 18px;
}
.share-qrcode-box {
  position: relative;
  padding: 36px;
}
.share-link-box {
  position: relative;
  max-height: calc(100% - 124px);
  padding: 16px;
  background-color: #17181a;
  border: 1px solid #323338;
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  line-height: 18px;
  word-wrap: break-word;
  word-break: normal;
  overflow: auto;
  text-align: left;
  margin-top: 24px;
}
.qr-code-box {
  position: relative;
  margin-top: 24px;
  opacity: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.show-qr-code-box {
  opacity: 1;
  transition: 0.5s;
}
.qrcodeCapture {
  width: fit-content;
}

.share-link-box:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.download-div {
  position: absolute;
  height: calc(100% - 160px);
  width: 100%;
  top: 148px;
  z-index: 3;
  margin-top: 24px;
}
.download-div-subtitles {
  position: relative;
  height: 100%;
  width: 100%;
}

.subTitleMenu {
  margin-bottom: 24px;
  width: 100%;
}
.download-title {
  color: #ffffff;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.download-subtitle {
  color: #8d909f;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 0 48px;
}
.file-icon {
  margin-bottom: -2px;
  margin-right: 6px;
}
.placeholder-bar {
  position: relative;
  margin: 0px auto 24px auto;
}
.bottom-btns-div {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 64px;
  width: 100%;
}
.btn-width {
  width: 120px;
}
.v-list {
  background: #2c2d32 !important;
  color: white;
  max-height: 95vh;
  overflow-y: scroll;
}
.active-item {
  color: #4689f3;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.no-transform {
  text-transform: none;
}

/* //// downloading prepared video/// */
.downloading-popup {
  pointer-events: none;
  position: fixed;
  top: 74px;
  right: 20px;
  width: 454px;
  text-align: left;
  z-index: 10;
  transition: 0.3s;
}
.downloading-popup-share {
  top: 4px;
  transition: 0.3s;
}
.downloading-one-video {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  pointer-events: none;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #2c2d32;
  opacity: 0;
  transition: 0.3s;
}
.show-downloading-video {
  height: 70px;
  pointer-events: auto;
  opacity: 1;
  margin-bottom: 4px;
  transition: 0.3s;
}
.download-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
  padding-left: 12px;
  padding-top: 4px;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.download-text {
  position: relative;
  top: 0;
  padding: 16px 16px 0 56px;
}
.download-popup-title {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin: 0;
}
.download-message {
  color: #8d909f;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
}

.progress-num {
  font-size: 8px;
}

.disable-for-download {
  pointer-events: none !important;
  opacity: 0.3 !important;
}
.processing-full-div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: #1e1f22;
}
.processing-full-text {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 48px;
}
.processing-full-btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 114px;
}
.process-btn-text {
  height: 16px;
  color: #8d909f;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 4px;
}
.new-tab-btn {
  width: 374px;
}
.download-label {
  margin-right: auto;
  font-size: 14px;
  text-transform: uppercase;
  color: #8d909f;
}
</style>
