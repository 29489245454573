<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0664 7.01887V8.72453H0V7.01887H15.0664ZM8.37863 0V16H6.56703V0H8.37863Z" fill="currentColor"/>
</svg>
</template>
<script>
export default {
  name: "DIconPlus",
};
</script>
