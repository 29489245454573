<template>
  <svg width="29" height="22" viewBox="0 0 29 22" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M20.6115 10.9485C20.6115 14.2957 17.8772 16.9828 14.5772 16.9828C11.2301 16.9828 8.5429 14.2957 8.5429 10.9485C8.5429 7.6485 11.2301 4.9142 14.5772 4.9142C17.8772 4.9142 20.6115 7.6485 20.6115 10.9485ZM14.5772 6.42278C12.0315 6.42278 10.0515 8.44994 10.0515 10.9485C10.0515 13.4471 12.0315 15.4743 14.5772 15.4743C17.0758 15.4743 19.103 13.4471 19.103 10.9485C19.103 8.44994 17.0758 6.42278 14.5772 6.42278ZM23.6287 4.20706C25.8444 6.23421 27.3059 8.68565 28.013 10.3828C28.1544 10.7599 28.1544 11.1842 28.013 11.5614C27.3059 13.2114 25.8444 15.6628 23.6287 17.7371C21.413 19.8114 18.3487 21.5086 14.5772 21.5086C10.7586 21.5086 7.69432 19.8114 5.4786 17.7371C3.26287 15.6628 1.80143 13.2114 1.09429 11.5614C0.952857 11.1842 0.952857 10.7599 1.09429 10.3828C1.80143 8.68565 3.26287 6.23421 5.4786 4.20706C7.69432 2.13276 10.7586 0.388463 14.5772 0.388463C18.3487 0.388463 21.413 2.13276 23.6287 4.20706ZM2.50858 10.9485C3.12144 12.4571 4.48859 14.72 6.51574 16.6057C8.5429 18.4914 11.2301 20 14.5772 20C17.8772 20 20.5644 18.4914 22.5915 16.6057C24.6187 14.72 25.9859 12.4571 26.6459 10.9485C25.9859 9.43994 24.6187 7.17707 22.5915 5.29135C20.5644 3.40562 17.8772 1.89704 14.5772 1.89704C11.2301 1.89704 8.5429 3.40562 6.51574 5.29135C4.48859 7.17707 3.12144 9.43994 2.50858 10.9485Z" />
  </svg>
</template>

<script>
export default {
  name: "DIconPreviewVideo"
};
</script>

<style scoped>

</style>
