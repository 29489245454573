import Heap from "./heap";
import Matomo from "./matomo";
import environment from "../../../env";

const anaLyticsMap = {
  heap: Heap,
  matomo: Matomo,
};

let currentAnalytics = null;
const Analytics = {
  initialize(type, options = {}) {
    currentAnalytics = anaLyticsMap[type];
    currentAnalytics.initialize(options);
  },
  setUserUid(uid) {
    currentAnalytics.setUserUid(uid);
  },
  setUserOrganization(organizationId) {
    // TODO: add setUserOrganization to currentAnalytics
  },
  setUserGroup(groupId) {
    // TODO: add setUserGroup to currentAnalytics
  },
  setEnteredEditorSteps(workflowId, matomoParams) {
    currentAnalytics.setTrack(matomoParams);
  },
  setEnteredEditorTranscription(workflowId, matomoParams) {
    currentAnalytics.setTrack(matomoParams);
  },
  setEventProperties(events = {}) {
    // TODO: add setEventProperties to currentAnalytics
  },
  removeEventProperties(eventName = "") {
    // TODO: add removeEventProperties to currentAnalytics
  },
  setTrack(params) {
    currentAnalytics.setTrack(params);
  },
  addUserProperties(options = {}) {
    // TODO: add addUserProperties to currentAnalytics
  },
  getSessionId() {
    // TODO: add getSessionId to currentAnalytics
  },
  setInputChangeTrack(options = {}) {
    return currentAnalytics.setTrack(options);
  },
};
export default Analytics;
