<template>
<svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.71875 14.25C8.71875 14.7422 8.33203 15.0938 7.875 15.0938C7.38281 15.0938 7.03125 14.7422 7.03125 14.25V12C7.03125 11.543 7.38281 11.1562 7.875 11.1562C8.33203 11.1562 8.71875 11.543 8.71875 12V14.25ZM7.875 0.75C10.3359 0.75 12.375 2.78906 12.375 5.25V7.5H13.5C14.7305 7.5 15.75 8.51953 15.75 9.75V16.5C15.75 17.7656 14.7305 18.75 13.5 18.75H2.25C0.984375 18.75 0 17.7656 0 16.5V9.75C0 8.51953 0.984375 7.5 2.25 7.5H3.375V5.25C3.375 2.78906 5.37891 0.75 7.875 0.75ZM7.875 2.4375C6.29297 2.4375 5.0625 3.70312 5.0625 5.25V7.5H10.6875V5.25C10.6875 3.70312 9.42188 2.4375 7.875 2.4375ZM2.25 9.1875C1.93359 9.1875 1.6875 9.46875 1.6875 9.75V16.5C1.6875 16.8164 1.93359 17.0625 2.25 17.0625H13.5C13.7812 17.0625 14.0625 16.8164 14.0625 16.5V9.75C14.0625 9.46875 13.7812 9.1875 13.5 9.1875H2.25Z" fill="#9397A6"/>
</svg>
</template>

<script>
export default {
  name: "DIconLock",
};
</script>
