<template>
  <section class="d-admin-setting">
    <div row wrap class="tab-title-row">
      <div class="tab-title text-no-wrap" autotest="team-count">
        <p class="tab-title text-truncate">
          <span autotest="workspace-count">
            <b>{{ teams.length }}</b>
            {{ $tc("admin.teamNum", teams.length) }}
          </span>
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        round
        color="#4689f4"
        class="white--text elevation-0 body-2 text-capitalize btn-width mt-1"
        @click="showPopupType = 'create'"
        autotest="create-team-btn"
        >{{ $t("admin.createTeam") }}</v-btn
      >
    </div>

    <div class="table-wrap" :class="{ 'table-wrap--organization': isOnOrganizationLevel }">
      <d-data-table
        add-search
        local-search
        initial-sort
        :headers="tableHeader"
        :items="teams"
        :loading="isLoading"
        :placeholder="$t('admin.searchForTeams')"
        searchAutotest="team-search-bar"
      >
        <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
        <template slot="headerCell" slot-scope="props">
          <span class="head-text">{{ props.header.text }}</span>
        </template>

        <template slot="items" slot-scope="props">
          <td class="text-xs-left" :style="{ width: '30%' }">
            <span
              @click="enterTeam(props.item)"
              :class="{ 'team-name-hover': !isOnOrganizationLevel }"
              autotest="team-name-display"
              >{{ props.item.name }}</span
            >
          </td>
          <td class="text-xs-left" :style="{ width: '20%' }" autotest="team-users-display">
            {{ props.item.totalUsers }}
          </td>
          <td class="text-xs-left" :style="{ width: '20%' }" autotest="team-workspace-group-display">
            {{ props.item.workspaceGroupName }}
          </td>
          <td class="text-xs-left" :style="{ width: '30%' }" autotest="team-workspace-display">
            {{ workspaceDictionary[props.item.group] }}
          </td>

          <td class="text-xs-right text-no-wrap">
            <button class="mr-3 table-btn" @click="openEditTeamPopup(props.item)" autotest="edit-team-btn">
              <icon-base height="22" width="22">
                <d-icon-pen />
              </icon-base>
            </button>

            <button class="table-btn" @click="openDeleteGroupConfirmation(props.item)" autotest="delete-team-btn">
              <icon-base height="22" width="22">
                <d-icon-trash />
              </icon-base>
            </button>
          </td>
        </template>
        <template slot="no-data">
          <span>{{ $t("admin.noTeamsCreatedYet") }}</span>
        </template>
        <template slot="no-results">
          <span>{{ $t("analytics.msgNoSearchResults") }}</span>
        </template>
      </d-data-table>
    </div>

    <admin-workspace-team-popup
      v-if="showPopupType === 'create' || showPopupType === 'edit'"
      :style="drawerOpenPopupStyle"
      :showPopupType="showPopupType"
      :teams="teams"
      :editedTeam="editedTeam"
      :workspaceId="workspaceId"
      :workspaceName="currentWorkspace ? currentWorkspace.name : null"
      :workspaceDictionary="workspaceDictionary"
      :hasWorkspaceOptions="isOnOrganizationLevel && showPopupType === 'create'"
      :workspacesForOldVersion="workspacesForOldVersion"
      @addTeam="addTeam($event)"
      @editTeam="editTeam($event)"
      @closePopup="closePopup"
    ></admin-workspace-team-popup>

    <d-confirmation-popup
      v-model="showDeleteTeam"
      light
      redActionBtn
      :title="$t('admin.deleteTeam')"
      :cancelBtnName="$t('all.cancel')"
      :actionBtnName="disabledDeleteWorkspace ? '' : $t('skills.delete')"
      :content="deleteWorkspaceMessage"
      :autotest="{
        close: 'delete-team-close-btn',
        action: 'delete-team-popup-yes-btn',
        cancel: 'delete-team-popup-cancel-btn',
      }"
      @clickActionBtn="removeTeam"
    ></d-confirmation-popup>
  </section>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import IconBase from "@/components/IconBase.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import DIconTrash from "@/components/icons/DIconTrash.vue";
import DGroupMenu from "@/components/ui_components/DGroupMenu.vue";
import AdminWorkspaceTeamPopup from "@/components/DAdmin/workspaceGroup/AdminWorkspaceTeamPopup.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import DIconTeams from "@/components/icons/colored/DIconTeams.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import { createTeam, updateTeam, deleteTeam } from "@/server/teams-server.js";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "AdminWorkspaceTeams",
  props: {
    drawerOpenPopupStyle: Object,
    workspaceId: String,
    currentWorkspace: Object,
    workspaceDictionary: Object,
    isOnOrganizationLevel: Boolean,
    workspacesForOldVersion: Array,
  },
  created() {},
  mounted() {
    this.getTeams();
  },
  data() {
    return {
      tableHeader: [
        {
          text: this.$t("all.name"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "team-name-sort",
        },
        {
          text: this.$t("all.users"),
          align: "left",
          sortable: true,
          value: "totalUsers",
          autotest: "team-users-sort",
        },
        {
          text: this.$t("all.workspaceGroup"),
          align: "left",
          sortable: true,
          value: "workspaceGroupName",
          autotest: "team-workspace-group-sort",
        },
        {
          text: this.$t("all.workspace"),
          align: "left",
          sortable: true,
          value: "workspaceName",
          autotest: "team-workspace-sort",
        },
        {
          text: "",
          sortable: false,
        },
      ],
      teams: [],
      isLoading: false,
      searchInput: "",
      showPopupType: "",
      editedTeam: {},
      showDeleteTeam: false,
      disabledDeleteWorkspace: false,
      deleteWorkspaceMessage: "",
      selectedWorkspace: {},
    };
  },
  computed: {
    ...mapGetters("workspaceGroups", ["getter_get_admin_workspace_group_by_workspace_id"]),
    isSideMenuOpen() {
      return this.$root.$children[0].drawer && this.$root.$children[0].isSupportWorkspaceGroup;
    },
  },
  components: {
    IconBase,
    DGroupMenu,
    DIconPen,
    DIconTrash,
    AdminWorkspaceTeamPopup,
    DConfirmationPopup,
    DIconTeams,
    DDataTable,
  },
  mixins: [MixinDB, MixinUser],
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    async getTeams() {
      this.isLoading = true;
      try {
        const result = await this.fetchTeams();
        this.teams = result.map((team) => ({
          ...team,
          workspaceGroupName: this.getter_get_admin_workspace_group_by_workspace_id(team.group)?.name || "",
          workspaceName: this.workspaceDictionary[team.group],
        }));
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchTeams() {
      const comparisons = [
        {
          field: "organization",
          comparison: "==",
          value: this.$organization,
        },
      ];
      if (this.workspaceId && this.workspaceId != "All") {
        comparisons.push({
          field: "group",
          comparison: "==",
          value: this.workspaceId,
        });
      }
      return await this.getDocumentByQuery("teams", comparisons, [
        {
          type: "limit",
          value: 1000,
        },
      ]);
    },
    async addTeam({ name, workspaceId }) {
      const { ok, data } = await createTeam({ workspace: workspaceId, name });
      if (ok) {
        const workspaceGroupName = this.getter_get_admin_workspace_group_by_workspace_id(workspaceId)?.name || "";
        const workspaceName = this.workspaceDictionary[workspaceId];
        const team = {
          organization: this.$organization,
          id: data.item,
          name,
          group: workspaceId,
          workspaceGroupName,
          workspaceName,
          totalUsers: 0,
        };
        this.teams.splice(0, 0, team);
        const message = this.$t("admin.alertSuccessfullyCreatedTeam");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
        this.closePopup();
      } else {
        const message = this.$t("admin.alertFailedCreatedTeam");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
    async editTeam({ teamId, name }) {
      const { ok } = await updateTeam({ teamId, name });
      if (ok) {
        const idx = this.teams.findIndex((team) => team.id === teamId);
        idx > -1 ? (this.teams[idx].name = name) : null;

        const message = this.$t("admin.alertSuccessfullyUpdatedTeam");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
        this.closePopup();
      } else {
        const message = this.$t("admin.alertFailedEditTeam");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
    async removeTeam() {
      const { ok, errorMessage } = await deleteTeam({ teamId: this.editedTeam.id });
      if (ok) {
        const idx = this.teams.findIndex((team) => team.id === this.editedTeam.id);
        this.teams.splice(idx, 1);

        const message = this.$t("admin.alertDeleteTeamSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } else {
        const message = errorMessage.includes("existing skill(s) assigned to")
          ? this.$t("admin.alertDeleteTeamFailedSkills")
          : this.$t("admin.alertDeleteTeamFailed");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      }
    },
    enterTeam(team) {
      if (this.isOnOrganizationLevel) return;
      this.$emit("enterTeam", team);
    },
    openEditTeamPopup(team) {
      this.editedTeam = team;
      this.showPopupType = "edit";
    },
    openDeleteGroupConfirmation(team) {
      this.editedTeam = team;
      this.disabledDeleteWorkspace = false;
      this.deleteWorkspaceMessage = this.$t("admin.popupMsgDeleteTeam", { name: this.editedTeam.name });
      this.showDeleteTeam = true;
    },
    closePopup() {
      this.showPopupType = "";
      this.editedTeam = {};
    },
  },
};
</script>

<style scoped>
.d-admin-setting {
  position: relative;
  height: 100%;
  width: 100%;
}
.tab-title-row {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 30px;
  padding: 0 28px;
}
.tab-title {
  color: #3f3f3f;
  font-size: 32px;
  line-height: 44px;
  text-align: left;
  /* width: 50%;
  min-width: 600px; */
  position: relative;
  display: flex;
}
.drop-list-arrow {
  padding: 12px 0 0 8px;
}
.d-group-menu {
  position: fixed;
  top: 210px;
  left: 0;
  height: calc(100% - 210px);
  width: 100%;
  z-index: 999;
}
.workspace-btn {
  position: relative;
  max-width: calc(100% - 200px);
  cursor: pointer;
  padding: 5px 0 0 8px;
  white-space: pre-wrap;
}
.btn-width {
  min-width: 140px;
  text-transform: none !important;
}
/* table */
.table-wrap {
  position: fixed;
  left: 0;
  width: 100%;
  height: calc(100% - 366px);
  padding: 0 28px;
  overflow: scroll;
}
.table-wrap--organization {
  height: calc(100% - 300px);
}
.table-wrap:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}

.head-text {
  text-transform: uppercase;
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
.team-name-hover:hover {
  color: #2d2e32;
  cursor: pointer;
  font-weight: 500;
}
.table-btn:hover {
  color: #2d2e32;
}
@media screen and (max-width: 959px) {
  .tab-title-row {
    padding: 0 20px;
  }
  .table-wrap {
    padding: 0 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1903px) {
}
</style>
