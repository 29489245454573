import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";

export const baseURL = environment.backendType === "firebase"
      ? environment.service[environment.env].deephowApiServer
      : environment.service[environment.env]["china"].deephowApiServer;
      

export async function getIdToken() {
  return authChoice().currentUser.getIdToken();
}

export async function saveUpdateSkillDue({skillId, requestBody}) {
  try {
    const token = await getIdToken();
    const response = await axios({
      method: "patch",
      url: `${baseURL}/v1/playlists/${skillId}/due`,
      headers: {
        "Content-Type": "application/json",
        "x-user-token": token
      },
      data: requestBody
    });
    return { ok: true, response}
  } catch (error) {
    return { ok: false, error}
  }
}

