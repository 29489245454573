<template>
  <div class="zoom" :style="{'background': backgroundColor }" :data-content="$t('editor.zoom')">
    <button
      v-ripple
      class="circle"
      :disabled="btnDisabled.minus"
      @click="mutateZoomValue(-50)"
    ><DIconMinus :disabled="btnDisabled.minus" /></button>
    <input
      type="text"
      class="input"
      :value="value | addPercentage"
      ref="zoomValue"
      :min="100"
      :max="max"
      disabled
      @keydown.enter="handleEnter($event)"
    />
    <button
      v-ripple
      class="circle"
      :disabled="btnDisabled.plus"
      @click="mutateZoomValue(50)"
    ><DIconPlus :disabled="btnDisabled.plus" /></button>
  </div>
</template>

<script>
import DIconMinus from "@/components/icons/editor/DIconMinus";
import DIconPlus from "@/components/icons/editor/DIconPlus";

export default {
  name: "Zoom",
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    backgroundColor: {
      type: String,
      default: () => "#2c2d32",
    },
    max: {
      type: Number,
      default: () => 100,
    },
    value: {
      type: [String, Number],
      default: () => 100,
    },
  },
  components: {
    DIconMinus,
    DIconPlus,
  },
  computed: {
    btnDisabled() {
      return {
        minus: this.disabled || this.value === 100,
        plus: this.disabled || this.value === this.max,
      };
    },
  },
  methods: {
    getValidValue(expectedValue) {
      const value = Number(expectedValue);
      if (value < 100) return 100;
      return value > this.max ? this.max : value;
    },
    handleEnter(e) {
      const inputValue = String(e.target.value).replace("%", "");
      if (/^\d{1,3}$/.test(inputValue)) {
        this.$emit("input", this.getValidValue(inputValue));
      } else if (inputValue === "" || +inputValue === 0) {
        this.$emit("input", 0);
      } else {
        e.target.value = String(this.value).replace(/$/, "%");
        e.preventDefault();
      }
    },
    mutateZoomValue(value) {
      if (this.value === 100 && value < 0) return;
      const inputValue = this.getValidValue(this.value + value);
      this.$emit("input", inputValue);
      this.$emit("trackZoomChangeByHeap");
    },
  },
  filters: {
    addPercentage(value) {
      if (!/%$/.test(value)) {
        value = value + "%";
      }
      return value;
    },
  },
};
</script>

<style scoped lang="scss">
.zoom {
  --radius: 40px;
  --border-color: #3d3d3d;
  position: relative;
  border-radius: 25px;
  background-color: #2c2d32;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;

  .input {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0 1rem;
    margin: 0 12px;
    height: 55%;
    width: 60%;
    text-align: center;
    outline: none;
    color: black;
  }

  .circle {
    color: white;
    min-width: var(--radius);
    height: var(--radius);
    border: 1px solid var(--border-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:disabled {
      color: #3d3d3d;
    }
  }

  &:before {
    content: attr(data-content);
    position: absolute;
    top: -1px;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    font-size: 12px;
    background-color: inherit;
    padding: 0 3px;
    text-transform: uppercase;
  }
}
</style>
