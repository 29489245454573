<template>
  <div class="playerlist-workflow-selector">
    <div class="tool-bar">
      <d-explorer-search
        ref="dExplorerSearch"
        v-model="searchInput"
        :isMobileDevice="false"
        :isEditor="false"
        :isSearchMode="isSearchMode"
        :didYouMean="didYouMean"
        :userGroupList="userGroupList"
        :currentGroupId="currentGroupId"
        :groupDictionary="groupDictionary"
        :isLoadingSearch="isLoadingSearch"
        @openSearch="isSearchMode = true"
        @closeSearch="closePlaylistSelector()"
        @getFullTextSearch="getFullTextSearch($event)"
        @clickSearchSuggestion="clickSearchSuggestion($event)"
        @updateCurrentGroup="updateCurrentGroup($event)"
        @mouseoverSearch="mouseoverSearch = true"
        @mouseleaveSearch="mouseoverSearch = false"
      ></d-explorer-search>
      <div v-if="$access.categories && searchInput.length == 0" class="filter-wrapper">
        <div class="arrow-div-l" :class="{ 'hide-arrow': scrollLeft <= 31 }">
          <div class="arrow-bg-l"></div>
          <button class="arrow-buttom" @click="onScrollLeft()">
            <icon-base color="#3f3f3f" height="20" width="20">
              <d-icon-arrow-back />
            </icon-base>
          </button>
        </div>
        <div class="arrow-div-r" :class="{ 'hide-arrow': !showArrowRight }">
          <div class="arrow-bg-r"></div>
          <button class="arrow-buttom arrow-right" @click="onScrollRight()">
            <icon-base color="#3f3f3f" height="20" width="20">
              <d-icon-arrow-back />
            </icon-base>
          </button>
        </div>
        <div v-if="loadedCategories" ref="itemScroll" class="filter-scroll center-horizontally" @scroll="onScroll()">
          <div v-if="scrollLeft > 0">
            <div class="padding-block"></div>
          </div>
          <div
            v-for="(filterCat, i) in filterCategories"
            :key="i"
            class="category-button"
            :class="{ 'hide-category': filterCat.items && filterCat.items.length == 0 }"
          >
            <v-menu
              transition="slide-y-transition"
              bottom
              right
              offset-y
              :close-on-content-click="false"
              @input="onMenuToggle($event, filterCat)"
            >
              <button
                slot="activator"
                class="text-no-wrap filter-button"
                :class="{ 'selected-button': filterCat.selected && filterCat.selected.length > 0 }"
              >
                {{ filterCat.name }}
              </button>
              <d-category-selector
                v-model="filterSearchInput"
                :placeholder="$t('builder.searchForCategoryName', { name: filterCat.name })"
                :filteredMenuItems="filteredMenuItems"
                :selectedItems="selectedItems"
                @remove-category-item="onClickCategoryItem(filterCat, $event)"
                @on-click-category-item="onClickCategoryItem(filterCat, $event)"
              ></d-category-selector>
            </v-menu>
          </div>
          <div v-if="showArrowRight">
            <div class="padding-block"></div>
          </div>
        </div>

        <button
          class="clear-filter-btn"
          :class="{ 'disable-clear-filter': disableClearFilter }"
          @click="clearAllFilters()"
        >
          <span>{{ $t("skills.clearFilter") }}</span>
        </button>
      </div>
    </div>
    <div class="sort-by">
      <v-menu transition="slide-y-transition" bottom left offset-y open-on-hover>
        <button slot="activator" class="center-horizontally text-xs-right" autotest="add-content-sort">
          <span class="sort-by-option">{{ selectedSortOption.name }}</span>
          <v-icon color="#52545D" class="sort-by-arrow">keyboard_arrow_down</v-icon>
        </button>
        <v-list dense light>
          <v-list-tile v-for="(sortOption, i) in sortOptions" :key="i" @click="selectedSortOption = sortOption">
            <v-list-tile-title
              class="v-list-content-text"
              :class="{ 'current-list-item': selectedSortOption.sort == sortOption.sort }"
              >{{ sortOption.name }}</v-list-tile-title
            >
          </v-list-tile>
        </v-list>
      </v-menu>
    </div>
    <div @scroll="handleScroll" class="videoList-card-container">
      <v-layout row wrap>
        <v-flex v-for="(workflow, idx) in allWorkflowsData" :key="idx" xs12 sm6 md4 lg3 pa-2 d-flex>
          <div class="video-card">
            <div v-if="isInList(workflow.id)" class="uncheckable-msg">
              <div class="uncheckable-msg-inner">
                <div class="fullscreen-center white--text">{{ $t("skills.AddedToSkill") }}</div>
              </div>
            </div>
            <div v-else class="checked-circle-div">
              <button @click="addOrRemoveSelectedWorkflows(workflow)" autotest="add-content-select-workflow">
                <icon-base
                  v-if="selectedWorkflowsIds.indexOf(workflow.id) === -1"
                  class="unchecked-circle"
                  width="20"
                  height="20"
                >
                  <d-icon-circle-unchecked />
                </icon-base>
                <icon-base v-else width="20" height="20">
                  <d-icon-circle-checked />
                </icon-base>
              </button>
            </div>
            <d-selector-workflow-card
              :workflow="workflow"
              :idx="idx"
              :isPlayingIndex="isPlayingIndex"
              @set-playing-index="isPlayingIndex = $event.idx"
            ></d-selector-workflow-card>
          </div>
        </v-flex>
      </v-layout>
      <d-no-result :showNoResult="allWorkflowsData.length === 0 && searchInput != ''" light />
      <div
        class="loading-search-div"
        :class="{ 'show-loading-search': allWorkflowsData.length === 0 && searchInput == '' }"
      >
        <div class="fullscreen-center">
          <icon-base class="mb-2" width="108" height="108">
            <d-icon-new-list />
          </icon-base>
          <p class="msg-line1">{{ $t("explorer.noPublishedWorkflows") }}</p>
        </div>
      </div>
    </div>
    <!-- loading search-->
    <div class="loading-container" :class="{ 'show-loading-search':isLoadingSearch || (isLoadingWorkflows && allWorkflowsData.length===0) }">
      <div class="fullscreen-center">
        <div>
          <d-icon-processing-bar-flex
            light
            height="8px"
            width="370px"
            class="loading-search-content"
          />
          <p v-if="isLoadingSearch" class="loading-search-text mt-3">{{$t('explorer.searchingForWorkflows')}}</p>
          <p v-else class="loading-search-text mt-3">{{ $t("player.loading") }}</p>
        </div>
      </div>
    </div>
    <!-- selected Workflows expand -->
    <v-expand-transition>
      <div v-if="selectedWorkflowsIds.length > 0" class="remove-div">
        <div class="fullscreen-center">
          <p class="remove-text">
            <span class="dBlue--text">{{ selectedWorkflowsIds.length }}</span>
            <!-- workflows selected -->
            {{ $tc("skills.workflowsSelected", selectedWorkflowsIds.length) }}
            <v-btn
              outline
              round
              color="#4689f4"
              class="body-2 text-capitalize btn-width ml-4"
              @click="clearAllWorkflows()"
              >{{ $t("builder.clear") }}</v-btn
            >
            <v-btn
              round
              color="#4689f4"
              class="elevation-0 body-2 white--text text-capitalize btn-width"
              @click="addMoreWorkflows()"
              >{{ $t("builder.insert") }}</v-btn
            >
          </p>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>


<script>
import Vue from "vue";
import IconBase from "./IconBase.vue";
import DIconClose2 from "./icons/DIconClose2.vue";
import DIconCloseLight from "./icons/player/DIconCloseLight.vue";
import DIconSearch from "./icons/DIconSearch.vue";
import DIconCircleUnchecked from "./icons/DIconCircleUnchecked.vue";
import DIconCircleChecked from "./icons/DIconCircleChecked.vue";
import DImage from "./ui_components/DImage.vue";
import DVideoPlayer from "./ui_components/DVideoPlayer.vue";
import DSelectorWorkflowCard from "./ui_components/DSelectorWorkflowCard.vue";
import DExplorerSearch from "./ui_components/DExplorerSearch.vue";
import DCategorySelector from "./ui_components/DCategorySelector.vue";
import DIconNewList from "./icons/colored/DIconNewList.vue";
import DIconArrowBack from "./icons/DIconArrowBack.vue";
import DNoResult from "./ui_components/DNoResult.vue";
import DIconProcessingBarFlex from "./icons/animated/DIconProcessingBarFlex.vue";
import MixinUser from "./MixinUser.vue";
import MixinDB from "./MixinDB.vue";
import { postSearch } from "@/server/search-server";
import { mapGetters } from "vuex";

export default {
  name: "SkillsWorkflowSelector",
  props: {
    groupId: String,
    workflowList: Array,
    groupDictionary: Object,
  },
  components: {
    IconBase,
    DIconClose2,
    DIconCloseLight,
    DIconSearch,
    DIconCircleUnchecked,
    DIconCircleChecked,
    DImage,
    DVideoPlayer,
    DSelectorWorkflowCard,
    DExplorerSearch,
    DCategorySelector,
    DIconNewList,
    DIconArrowBack,
    DNoResult,
    DIconProcessingBarFlex,
  },
  data: function () {
    return {
      userGroupList: [],
      sortOptions: [
        { name: this.$t("explorer.alphabetically"), sort: "alphabetically" },
        { name: this.$t("explorer.lastUpdated"), sort: "last-updated" },
      ],
      selectedSortOption: {
        name: this.$t("explorer.lastUpdated"),
        sort: "last-updated",
      },
      selectedWorkflowsIds: [],
      selectedWorkflows: [],
      isSearchMode: true,
      isLoadingSearch: false,
      searchResults: [],
      noSearchResults: false,
      searchedWorkflows: [],
      searchedFilteredWorkflows: [],
      suggestions: [],
      searchInput: "",
      didYouMean: "",
      showSearchResults: false,
      showGroupMenu: false,
      currentGroupId: null,
      filterSearchInput: "",
      inListWorkflowsIds: [],
      isPlayingIndex: -1,
      currentMenuItems: [],
      filterCategories: [],
      selectedItems: [],
      loadedCategories: false,
      scrollLeft: 0,
      showArrowRight: false,
      //select multiple cates
      searchedCatResults: [],
      noSearchedCatResults: false,
      searchedCatWorkflows: [],
      categoriesFilter: [],
      searchedCatCursor: "",
      isLoadingSearchedCat: false,
      isLoadingWorkflows: false
    };
  },
  mixins: [MixinDB, MixinUser],
  watch: {
    currentGroupId: function () {
      if (this.selectedItems.length > 0) {
        this.getCategoriesSearch();
      } else {
        if (!this.loading_workflow) this.getMoreWorkflows();
      }
    },
    allWorkflowsData: function () {
      //sort workflow when workflows updated
      //if there are only few workflows get some so people can scroll to load
      if (this.selectedItems.length == 0 && this.searchInput.length == 0 && this.allWorkflowsData.length < 13) {
        if (!this.loading_workflow) this.getMoreWorkflows();
      }
    },
    selectedItems: function () {
      //IF length == 0, go back to the default workflow view
      if (this.selectedItems.length > 0) {
        this.getCategoriesSearch();
      }
    },
    searchInput: function () {
      if (!this.searchInput) {
        this.clearSearchResults();
      }
    },
  },
  created() {
    this.$store.subscribe(
      function (mutation, state) {
        if (mutation.type == "getCategoriesEnd") {
          this.setCategoriesData();
        }
      }.bind(this)
    );
    this.workflowList.forEach((workflow) => {
      this.inListWorkflowsIds.push(workflow.id);
    });
    if (!this.currentGroupId) {
      this.currentGroupId = this.$editorGroup || this.groupId;
    }
    this.getWorkflows();
    this.getGroupList();
    this.getCategoriesFromVuex();
  },
  mounted() {
    this.$nextTick(() => {
      this.onScroll();
      window.addEventListener("resize", this.onScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onScroll);
  },
  computed: {
    ...mapGetters(["loading_workflow"]),
    allWorkflowsData() {
      if (this.searchInput.length > 0 && this.showSearchResults) {
        return this.searchedFilteredWorkflows.sort(this.compare);
      }
      if (this.selectedItems.length > 0) {
        return this.searchedCatWorkflows.sort(this.compare);
      }
      return this.$store.state.workflows
        .filter((workflow) => {
          if (this.currentGroupId && this.currentGroupId != "All") {
            return (
              workflow.group == this.currentGroupId && workflow.published && !workflow.archived && !workflow.deleted
            );
          } else {
            //for selection "All"
            return workflow.published && !workflow.archived && !workflow.deleted;
          }
        })
        .sort(this.compare);
    },
    disableClearFilter() {
      return this.selectedItems.length == 0;
    },
    filteredMenuItems() {
      return this.currentMenuItems
        .filter((item) => {
          if (item.name.toLowerCase().includes(this.filterSearchInput.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        })
        .sort(this.sortAlphabetically);
    },
    categories() {
      return this.$store.state.categoriesData;
    },
  },
  methods: {
    onScrollRight() {
      this.$refs.itemScroll.scrollLeft = this.scrollLeft + this.$refs.itemScroll.clientWidth - 80;
    },
    onScrollLeft() {
      this.$refs.itemScroll.scrollLeft = this.scrollLeft - this.$refs.itemScroll.clientWidth + 120;
    },
    onScroll() {
      if (this.$refs.itemScroll) {
        this.scrollLeft = this.$refs.itemScroll.scrollLeft;
        const maxScrollTop = this.$refs.itemScroll.scrollWidth - this.$refs.itemScroll.clientWidth;
        if (this.$refs.itemScroll.scrollWidth != this.$refs.itemScroll.clientWidth) {
          this.showArrowRight = true;
        }
        if (maxScrollTop - this.scrollLeft <= 30) {
          this.showArrowRight = false;
        }
      }
    },
    fetchAllGroups() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.getDocumentByFieldValue("groups", "organization", self.$organization).then((data) => {
          resolve(data);
        });
      });
    },
    getGroupList() {
      const self = this;
      this.fetchAllGroups().then((data) => {
        data.forEach((group) => {
          if (!group.private) {
            this.groupDictionary[group.id] = group.name;
            this.userGroupList.push({
              id: group.id,
              name: group.name,
              private: group.private,
            });
          }
        });
        this.userGroupList.sort(function (a, b) {
          const groupA = a.name.toUpperCase();
          const groupB = b.name.toUpperCase();
          if (groupA < groupB) {
            return -1;
          }
          if (groupA > groupB) {
            return 1;
          }
          return 0;
        });
        this.userGroupList.unshift({ name: this.$t("all.all"), id: null });
      });
    },
    getStepsLength(steps) {
      if (!steps) return 0;
      else return steps.length;
    },
    isInList(id) {
      if (this.inListWorkflowsIds.indexOf(id) === -1 || this.inListWorkflowsIds.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    videoDuration(duration) {
      let sec = duration;
      if (!sec || sec === Infinity) return "0:00";
      let minutes = Math.floor(sec / 60);
      let seconds = sec - minutes * 60;
      let padding = seconds < 10 ? "0" : "";
      return minutes + ":" + padding + Math.floor(seconds);
    },
    closePlaylistSelector() {
      this.$emit("close-playlist-selector");
    },
    findMatchGroup(groupId) {
      return this.userGroupList.find((group) => group.id === groupId);
    },
    getWorkflows() {
      const options = {
        organization: this.$organization,
      };
      if (this.currentGroupId && this.currentGroupId !== "All") {
        options["group"] = this.currentGroupId;

        const groupData = this.findMatchGroup(this.currentGroupId);
        options["privateWorkspace"] = groupData ? groupData.private : false;
      }
      this.$store.dispatch("getWorkflows", options);
      this.isLoadingWorkflows = true;
      setTimeout(()=>{
        this.isLoadingWorkflows = false;
      },3000)
    },
    getMoreWorkflows() {
      const options = {
        organization: this.$organization,
        quantity: 100,
      };

      if (this.currentGroupId && this.currentGroupId != "All") {
        options["group"] = this.currentGroupId;

        const groupData = this.findMatchGroup(this.currentGroupId);
        options["privateWorkspace"] = groupData ? groupData.private : false;
      }
      this.$store.dispatch("getMoreWorkflows", options);
    },
    handleScroll(event) {
      const areaToScroll = event.target.scrollHeight - event.target.offsetHeight;
      if (areaToScroll <= Math.ceil(event.target.scrollTop)) {
        this.getMoreWorkflows();
      }
    },
    //// select and remove ////
    addOrRemoveSelectedWorkflows(workflow) {
      const id = workflow.id;
      if (this.isPublished) {
        this.$emit("show-unpublish-list-popup");
      } else {
        const inArrayIndex = this.selectedWorkflowsIds.indexOf(id);
        if (inArrayIndex === -1) {
          this.selectedWorkflowsIds.push(id);
          this.selectedWorkflows.push(workflow);
        } else {
          this.selectedWorkflowsIds.splice(inArrayIndex, 1);
          this.selectedWorkflows.splice(inArrayIndex, 1);
        }
      }
    },
    clearAllWorkflows() {
      this.selectedWorkflowsIds = [];
      this.selectedWorkflows = [];
    },
    addMoreWorkflows() {
      this.$emit("add-more-workflows", this.selectedWorkflows);
    },
    getGroupNameById(id) {
      const groupName = this.groupDictionary[id];
      if (id == null) return this.$t("all.all");
      if (groupName) {
        return groupName.charAt(0).toUpperCase() + groupName.slice(1);
      } else {
        return "-";
      }
    },
    // for search
    updateCurrentGroup(group) {
      this.currentGroupId = !group.id ? "All" : group.id;
      Vue.prototype.$editorGroup = this.currentGroupId;
      this.getWorkflows();
      if (this.isSearchMode && this.searchInput) {
        this.getFullTextSearch(this.searchInput);
      }
    },
    async getFullTextSearch(input) {
      this.isLoadingSearch = true;
      this.clearSearchResults();
      this.didYouMean = "";
      const token = await this.getIdToken();
      const data = {
        published: true,
        text: input,
        locale: "en-us",
        token: token,
        organization: this.$organization,
        index: "workflows",
      };
      if (this.currentGroupId && this.currentGroupId != "All") {
        data["group"] = this.currentGroupId;
      }
      const { ok, data: responseData } = await postSearch({ path: "search", payload: data });
      if (ok) {
        this.searchResults = responseData.results;
        if (responseData.didYouMean) {
          this.didYouMean = responseData.didYouMean;
        }
        await this.getSearchedWorkflows();
        this.getSearchedFilteredWorkflows();
        this.isLoadingSearch = false;
        this.showSearchResults = true;
      }
      this.suggestions = [];
    },
    getSearchedFilteredWorkflows() {
      this.searchedFilteredWorkflows = [];
      this.selectedSortOption = {
        name: this.$t("explorer.sortBy"),
        sort: "",
      };
      let list;
      list = this.searchResults.filter((result) => {
        return result.highlight.includes("title") || result.highlight.includes("content");
      });

      this.searchedWorkflows.forEach((workflow) => {
        list.forEach((el) => {
          if (workflow.id == el.id) {
            this.searchedFilteredWorkflows.push(workflow);
          }
        });
      });
    },
    getSearchedWorkflows() {
      const self = this;
      return new Promise(function (resolve, reject) {
        let promises = [];
        if (!self.searchResults.length) {
          self.noSearchResults = true;
          self.searchedWorkflows = [];
          self.isLoadingSearch = false;
          resolve();
        } else {
          self.searchResults.forEach((result) => {
            if (result.highlight.includes("title")) {
              self.titleCounter++;
            }
            if (result.highlight.includes("content")) {
              self.contentCounter++;
            }
            promises.push(self.getDocument("workflows", result.id));
          });
          Promise.all(promises.map((p) => p.catch((error) => null)))
            .then((results) => {
              self.searchedWorkflows = results.filter(Boolean).filter((workflow) => !workflow.deleted);
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    async getSuggestions(input) {
      this.didYouMean = "";
      const { ok, data } = await postSearch({
        path: "suggest",
        data: {
          published: true,
          text: input,
          locale: "en-us",
          token: "rG5kXk0CDbhgF4RBlNoV",
          organization: "CyvXxtF8W32MmrZM4AFy",
          index: "workflows",
        },
      });
      if (ok) {
        this.suggestions = data.results[0].options;
      }
    },
    clickSearchSuggestion(word) {
      this.searchInput = word;
      this.$refs.dExplorerSearch.inputText = word;
      this.getFullTextSearch(this.searchInput);
    },
    clearSearchResults() {
      this.searchResults = [];
      this.noSearchResults = false;
      this.searchedWorkflows = [];
      this.didYouMean = "";
      this.titleCounter = this.contentCounter = 0;
      this.showSearchResults = false;
    },
    sortSearchedWrokflow() {
      if (this.searchedFilteredWorkflows.length > 0) {
        this.searchedFilteredWorkflows = this.searchedFilteredWorkflows.sort(this.compare).slice();
      }
    },
    // for filters
    onMenuToggle(opened, filterCat) {
      if (opened) {
        this.currentMenuItems = filterCat.items;
      } else {
        this.filterSearchInput = "";
      }
    },
    onClickCategoryItem(filterCat, event) {
      // multiple selected items are allowed for each Category
      const index = filterCat.selected.indexOf(event.item.id);
      if (index === -1) {
        filterCat.selected.push(event.item.id);
        this.selectedItems.push(event.item.id);
      } else {
        filterCat.selected.splice(index, 1);
        this.selectedItems.splice(this.selectedItems.indexOf(event.item.id), 1);
      }
      this.updateCategoriesFilter();
    },
    clearAllFilters() {
      this.filterCategories.forEach(function (cat) {
        cat.selected = [];
      });
      this.filterCategories.splice();
      this.selectedItems = [];
      this.selectedItems.length = 0;
    },
    compare(a, b) {
      //for workflow sorting
      if (this.selectedSortOption.sort == "last-updated") {
        if (a.publishedDate.seconds > b.publishedDate.seconds) {
          return -1;
        }
        if (a.publishedDate.seconds < b.publishedDate.seconds) {
          return 1;
        }
      } else if (this.selectedSortOption.sort == "alphabetically") {
        if (a.title.trim().toLowerCase() < b.title.trim().toLowerCase()) {
          return -1;
        } else {
          return 1;
        }
      }
      return 0;
    },
    //for cat and subCat
    async getCategoriesFromVuex() {
      const self = this;
      await this.$store.dispatch("getCategories", {
        organization: self.$organization,
      });
    },
    sortAlphabetically(a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else {
        return 1;
      }
      return 0;
    },
    setCategoriesData() {
      let cats = this.categories.sort(this.sortAlphabetically);
      cats.forEach((cat) => {
        cat.selected = [];
      });
      this.filterCategories = cats;
      this.loadedCategories = true;
      this.$nextTick(() => {
        this.onScroll();
      });
    },
    clearAllItems() {
      this.selectedItems = [];
      this.selectedItems.splice();
      this.categoriesFilter = [];
      for (let index = 0; index < this.filterCategories.length; index++) {
        this.filterCategories[index].selected = [];
      }
      if (this.isMobileDevice) {
        this.filterCategories.sort(this.sortAlphabetically);
      }
      this.filterCategories.splice();
      if (this.isMobileDevice) {
        this.$refs.categoriesRow.scrollLeftOnItemsDiv();
      }
    },
    clearCatItems(cat) {
      //clear filterCategories selected
      const selected = cat.selected;
      let i = this.filterCategories.findIndex((category) => category.id === cat.id);
      if (i > -1) {
        this.filterCategories[i].selected = [];
        this.filterCategories.splice();
      }

      //clear categoriesFilter
      let j = this.categoriesFilter.findIndex((filter) => filter.name === cat.id);
      if (j >= 0) {
        this.categoriesFilter.splice(j, 1);
      }

      //clear selectedItems
      this.selectedItems = this.selectedItems.filter((item) => selected.indexOf(item) === -1);
      this.selectedItems.splice();
      this.filterCategories.splice();
    },
    updateCategoriesFilter() {
      let filter = [];
      for (let i = 0; i < this.filterCategories.length; i++) {
        if (this.filterCategories[i].selected.length > 0) {
          const data = {
            name: this.filterCategories[i].id,
            values: this.filterCategories[i].selected,
          };
          filter.push(data);
        }
      }
      this.categoriesFilter = filter;
    },
    async getCategoriesSearch(getMore) {
      if (this.isLoadingSearchedCat || this.selectedItems.length == 0) {
        return;
      }
      this.isLoadingSearchedCat = true;
      if (!getMore) this.clearSearchCatResults();
      if (getMore && !this.searchedCatCursor) {
        this.isLoadingSearchedCat = false;
        return;
      }
      const self = this;
      const items = [...this.selectedItems];
      const token = await this.getIdToken();
      const data = {
        token: token,
        organization: self.$organization,
        published: true,
        index: "workflows",
        filters: self.categoriesFilter,
      };

      if (this.selectedSortOption.sort == "last-updated") {
        data["sort"] = {
          filed: "LAST_UPDATED",
          order: "DESC",
        };
      } else {
        data["sort"] = {
          filed: "NAME",
          order: "ASC",
        };
      }

      if (this.currentGroupId && this.currentGroupId != "All") {
        data["group"] = this.currentGroupId;
      }
      data["page"] = {
        first: 100,
      };
      if (this.searchedCatCursor) {
        data["page"].after = this.searchedCatCursor;
      }
      const { ok, data: responseData } = await postSearch({ path: "workflows", payload: data });
      if (ok) {
        console.log("responseData: ", responseData);
        this.searchedCatResults = responseData.workflows;
        if (responseData.pageInfo && responseData.pageInfo.hasNextPage) {
          this.searchedCatCursor = responseData.pageInfo.endCursor;
        } else {
          this.searchedCatCursor = "";
        }
        await this.getSearchedCatWorkflows();
        if (this.arrayCompare(items, this.selectedItems)) {
          this.isLoadingSearchedCat = false;
        } else {
          //search again
          this.isLoadingSearchedCat = false;
          await this.getCategoriesSearch();
        }
      }
    },
    getSearchedCatWorkflows() {
      const self = this;
      return new Promise(function (resolve, reject) {
        let promises = [];
        if (!self.searchedCatResults.length) {
          self.noSearchCatResults = true;
          self.isLoadingSearchedCat = false;
          resolve();
        } else {
          self.searchedCatResults.forEach((result) => {
            promises.push(self.getDocument("workflows", result.id));
          });
          Promise.all(promises.map((p) => p.catch((error) => null)))
            .then((results) => {
              const workflows = results.filter(Boolean).filter((workflow) => !workflow.deleted);
              self.searchedCatWorkflows.push(...workflows);
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    clearSearchCatResults() {
      this.searchedCatResults = [];
      this.noSearchCatResults = false;
      this.searchedCatWorkflows = [];
      this.searchedCatCursor = "";
    },
    arrayCompare(arr1, arr2) {
      if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
        return false;
      }

      // .concat() to not mutate arguments
      const array1 = arr1.concat().sort();
      const array2 = arr2.concat().sort();

      for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style scoped>
.playerlist-workflow-selector {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
  z-index: 100;
  overflow: auto;
}
.remove-div {
  position: fixed;
  height: 70px;
  width: 100%;
  background-color: #323338;
  bottom: 0;
  left: 0;
}
.tool-bar {
  position: fixed;
  width: 100%;
  height: 122px;
  top: 0;
  z-index: 4;
}
.filter-wrapper {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 52px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}
.arrow-div-l {
  position: absolute;
  top: 0;
  left: 30px;
  height: 100%;
  width: 20px;
  background-color: #fff;
  padding-top: 10px;
  z-index: 1;
  opacity: 1;
  transition: 0.3s;
}
.arrow-bg-l {
  position: absolute;
  top: 0;
  left: 20px;
  height: 100%;
  width: 20px;
  transform: scaleX(-1);
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0.1) 100%);
  z-index: 0;
  pointer-events: none;
}
.arrow-div-r {
  position: absolute;
  top: 0;
  right: 132px;
  height: 100%;
  width: 20px;
  background-color: #fff;
  padding-top: 10px;
  z-index: 1;
  opacity: 1;
  transition: 0.3s;
}
.arrow-bg-r {
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  width: 20px;
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0.1) 100%);
  z-index: 2;
  pointer-events: none;
}
.arrow-buttom {
  position: absolute;
  height: 32px;
  padding-top: 6px;
  left: 0;
  opacity: 0.7;
}
.arrow-buttom:hover {
  opacity: 1;
}
.arrow-right {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
}
.hide-arrow {
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
}
.padding-block {
  display: inline-block;
  height: 30px;
  width: 30px;
}
.filter-scroll {
  position: absolute;
  top: 0;
  left: 30px;
  width: calc(100% - 162px);
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-right: 32px;
  transition: 0.3s;
  scroll-behavior: smooth;
}
.filter-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.category-button {
  position: relative;
  margin-right: 16px;
}
.hide-category {
  display: none;
}
/* filter */
.filter-button {
  height: 32px;
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
  padding: 0 12px;
  margin: 0;
}
.filter-button:hover {
  border: 1px solid #3f3f3f;
}
.selected-button {
  border: 1px solid #4689f3;
  background-color: #f0f0f0;
}
.selected-button:hover {
  border: 1px solid #4689f3;
}
.v-list-content-text {
  height: 16px;
  color: #52545d;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}
.clear-filter-btn {
  position: absolute;
  right: 32px;
  top: 15px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  text-align: right;
  height: 24px;
  line-height: 24px;
  opacity: 1;
  pointer-events: auto;
}
.disable-clear-filter {
  opacity: 0.3;
  pointer-events: none;
}
.clear-filter-icon {
  margin-right: 6px;
}
.video-scroll-shade {
  position: absolute;
  top: 256px;
  height: 32px;
  width: 100%;
  background: linear-gradient(180deg, rgb(12, 12, 14, 1), rgba(12, 12, 14, 0) 100%);
  z-index: 1;
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
}
.show-shade {
  opacity: 1;
  transition: 0.3s;
}
.videoList-card-container {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  padding: 8px 14px 80px 22px;
  top: 176px;
  height: calc(100% - 176px);
  z-index: 0;
}
.videoList-card-container::-webkit-scrollbar {
  width: 8px;
}
.videoList-card-container:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.video-card {
  position: relative;
  overflow: hidden;
  margin: 0;
  opacity: 0.85;
  background-color: white;
}
.video-card:hover {
  opacity: 1;
  transition: 0.3s;
}
.uncheckable-msg {
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 */
  height: 0px;
  z-index: 3;
  pointer-events: none;
}
.uncheckable-msg-inner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0c0c0e9d;
}
.checked-circle-div {
  position: absolute;
  z-index: 3;
  top: 8px;
  right: 8px;
}
.unchecked-circle {
  opacity: 0;
}
.video-card:hover .unchecked-circle {
  opacity: 1;
}

.current-list-item {
  color: #4689f3;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sort-by {
  position: fixed;
  right: 26px;
  top: 116px;
  min-width: 120px;
  padding: 32px 0 0 0;
  z-index: 0;
}
.sort-by-option {
  height: 18px;
  width: 101px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  line-height: 18px;
}
.sort-by-arrow {
  position: relative;
  margin-left: 8px;
}
.btn-width {
  width: 120px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.remove-text {
  color: #ffffff;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}
.loading-container {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  top: 130px;
  height: calc(100% - 130px);
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  background-color: #f0f0f0;
}
.loading-search-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
}
.show-loading-search {
  opacity: 1;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.msg-line1 {
  color: #3f3f3f;
  font-size: 20px;
  text-align: center;
  margin: 0;
}
.msg-line2 {
  color: #8d909f;
  font-size: 16px;
  text-align: center;
}
.loading-search-content {
  position: relative;
  margin: 0 auto;
}
.loading-search-text {
  color: #3f3f3f;
  font-size: 20px;
  text-align: center;
  margin: 0;
}
</style>
