import { throttle, cloneDeep } from "lodash-es";
import { syncPrivatePlayerTrackingToServer, syncPublicPlayerTrackingToServer } from "@/server/video-tacking-server.js";

const QueueActionType = {
  FORCE_EXECUTE: 'forceExecute',
  FLUSH: 'flush',
  ADD_TO_BEGINNING: 'addToBeginning',
  ADD_TO_SYNC_SCHEDULE: 'AddToSyncSchedule',
  BREAK: 'break'
};

const config = {
  throttleIntervalMillionSeconds: 15000,
  updateTrackingDataQueue: new Map(),
  getVideoPlalyerEventArgs: {
    pause: undefined,
    play: undefined
  },
  isSyning: false
};

async function syncTrackingInfoToServer({ trackingInfo }) {
  if (trackingInfo.isPublic) {
    return syncPublicPlayerTrackingToServer({
      trackingInfo
    });
  } else {
    return syncPrivatePlayerTrackingToServer({
      trackingInfo
    });
  }
}

function getCurrentStepPlayedIntervals({ mixinTrackerRef, trackingDataArg }) {
  return mixinTrackerRef.workflowTracker.steps[trackingDataArg.step.id]
    .playedIntervals;
}

function fixInvalidHistoryOnPlayedIntervals({
  mixinTrackerRef,
  trackingDataArg
}) {
  const currentStepPlayedIntervals = getCurrentStepPlayedIntervals({
    mixinTrackerRef,
    trackingDataArg
  });

  const historyDataLength = currentStepPlayedIntervals.length - 2;
  for (let i = 0; i <= historyDataLength; i++) {
    const stepPalyedInterval = currentStepPlayedIntervals[i];
    if (isMissingOnpauseValueOnPlayedIntervals({ stepPalyedInterval })) {
      console.warn(
        "player tracking: invalid history data on PlayedIntervals",
        stepPalyedInterval
      );
      stepPalyedInterval.onPause = stepPalyedInterval.onPlay;
    }
  }
}
function isMissingOnpauseValueOnPlayedIntervals({ stepPalyedInterval }) {
  return (
    stepPalyedInterval &&
    stepPalyedInterval.onPlay >= 0 &&
    stepPalyedInterval.onPause === undefined
  );
}

function isMissingOnpauseValueOnLastPlayedIntervals({
  mixinTrackerRef,
  trackingDataArg
}) {
  const currentStepPlayedIntervals = getCurrentStepPlayedIntervals({
    mixinTrackerRef,
    trackingDataArg
  });
  const lastStepPalyedInterval =
    currentStepPlayedIntervals[currentStepPlayedIntervals.length - 1];
  return isMissingOnpauseValueOnPlayedIntervals({
    stepPalyedInterval: lastStepPalyedInterval
  });
}

function getQueueActionsAutoFillinOnpauseValue() {
  return [
    QueueActionType.FORCE_EXECUTE,
    QueueActionType.ADD_TO_SYNC_SCHEDULE,
    QueueActionType.FLUSH,
    QueueActionType.BREAK
  ];
}

function getQueueActionsAddTaskToBeginningOfQueue() {
  return [
    QueueActionType.ADD_TO_BEGINNING,
    QueueActionType.BREAK
  ];
}

function addTaskForAutoFillinOnpauseValue({
  mixinTrackerRef
}) {
  mixinTrackerRef.trackPlayer({
    ...config.getVideoPlalyerEventArgs.pause(),
    queueActions: getQueueActionsAutoFillinOnpauseValue()
  });
}

function addBeginningTaskForAutoFillinOnplayValue({
  mixinTrackerRef,
  trackingDataArg
}) {
  mixinTrackerRef.trackPlayer({
    ...config.getVideoPlalyerEventArgs.play(),
    step: trackingDataArg.step,
    queueActions: getQueueActionsAddTaskToBeginningOfQueue()
  });
}

function tryToSetupOnExitValue({ onExit, lastUpdated }) {
  return onExit || lastUpdated;
}

function isValidPlayedIntervals({
  mixinTrackerRef,
  trackingDataArg
}) {
  return isMissingOnpauseValueOnLastPlayedIntervals(
    {
      mixinTrackerRef,
      trackingDataArg
    }
  ) === false;
}

const throttleSyncPlayerTrackingToServer = throttle(
  ({ mixinTrackerRef, trackingDataArg }) => {
    // Do not request tracking API if it's embeded Deephow (preview in Builder and Analytics)
    const isEmbedDeephow = mixinTrackerRef.$route.name === "PlayerEmbed";
    if (isEmbedDeephow) return;

    /** if playedDuration/lastUpdated === 0, not request tracking API*/
    if (!mixinTrackerRef.workflowTracker.playedDuration || !mixinTrackerRef.workflowTracker.lastUpdated) return;

    config.isSyning = true;
    try {
      const isMissingOnpauseValueWhenUpdatingContinuousWatchTrackingInfo = isMissingOnpauseValueOnLastPlayedIntervals(
        {
          mixinTrackerRef,
          trackingDataArg
        }
      );
      if (isMissingOnpauseValueWhenUpdatingContinuousWatchTrackingInfo) {
        addTaskForAutoFillinOnpauseValue({
          mixinTrackerRef
        });
        return;
      }

      fixInvalidHistoryOnPlayedIntervals({
        mixinTrackerRef,
        trackingDataArg
      });

      const cloneData = cloneDeep(mixinTrackerRef.workflowTracker);
      const newOnExit = tryToSetupOnExitValue(cloneData)
      const newOnNext = removeInvalidOnNext(cloneData);
      const newOnPrevious = removeInvalidOnPrevious(cloneData);
      const newSteps = removeInvalidSteps(cloneData);
      const newStepsAry = removeInvalidStepsArray({ stepsArray: cloneData.stepsArray, steps: newSteps })

      const trackingInfo = {
        ...cloneData,
        onExit: newOnExit,
        onNext: newOnNext,
        onPrevious: newOnPrevious,
        steps: newSteps,
        stepsArray: newStepsAry,
      }

      syncTrackingInfoToServer({
        trackingInfo
      });
    } catch (err) {
      console.error(err);
    } finally {
      const isNeedResetDataFormateAfterAutoFillinOnpauseValue =
        trackingDataArg.event === 'on_pause' && (
          isValidPlayedIntervals({
            mixinTrackerRef,
            trackingDataArg
          }) &&
          trackingDataArg.queueActions &&
          trackingDataArg.queueActions.length > 0 &&
          trackingDataArg.queueActions.toString() === getQueueActionsAutoFillinOnpauseValue().toString()
        );
      if (isNeedResetDataFormateAfterAutoFillinOnpauseValue) {
        throttleSyncPlayerTrackingToServer.cancel();

        addBeginningTaskForAutoFillinOnplayValue({
          mixinTrackerRef,
          trackingDataArg
        });
        return;
      }

      config.isSyning = false;
    }
  },
  config.throttleIntervalMillionSeconds,
  {
    leading: false,
    trailing: true
  }
);
function removeInvalidSteps({ steps = {} }) {
  const filterData = Object.entries(steps).filter(([__, step]) => {
    const isNeedRemoveInavlidStep = !step.lastUpdated
      || (typeof step.lastUpdated !== 'number' || step.lastUpdated <= 0)
      || (typeof step.playedDuration !== 'number' || step.playedDuration <= 0)
      || (typeof step.stepDuration !== 'number' || step.stepDuration <= 0);
    return !isNeedRemoveInavlidStep;
  })
  return Object.fromEntries(filterData);
}
function removeInvalidStepsArray({ stepsArray = [], steps = {} }) {
  const stepsKeysList = Object.keys(steps);
  return stepsArray.filter((stepId) => stepsKeysList.indexOf(stepId) !== -1);
}
function removeInvalidOnNext({ onNext = [] }) {
  return onNext.filter(({ current, next }) => current && next);
}
function removeInvalidOnPrevious({ onPrevious = [] }) {
  return onPrevious.filter(({ current, previous }) => current && previous);
}

function addToUploadingPlayerTrackingSchedule({
  mixinTrackerRef,
  trackingDataArg
}) {
  throttleSyncPlayerTrackingToServer({
    mixinTrackerRef,
    trackingDataArg
  });
}

function addUpdateTrackingDataTaskOfUserToQueue({
  mixinTrackerRef,
  callback,
  trackingDataArg
}) {
  const updateTrackingDataQueue = config.updateTrackingDataQueue;
  const isTasksQueuNotYetCreated = !updateTrackingDataQueue.has(
    mixinTrackerRef
  );
  if (isTasksQueuNotYetCreated) {
    updateTrackingDataQueue.set(mixinTrackerRef, []);
  }

  const tasksQueue = updateTrackingDataQueue.get(mixinTrackerRef);

  const hasCustomQueueActions = Array.isArray(trackingDataArg.queueActions) && trackingDataArg.queueActions.length > 0;
  if (hasCustomQueueActions) {
    trackingDataArg.queueActions.forEach((queueAction) => {
      switch (queueAction) {
        case QueueActionType.ADD_TO_BEGINNING:
          tasksQueue.unshift({
            callback
          });
          break;
        case QueueActionType.FORCE_EXECUTE:
          if (typeof callback === "function") {
            callback();
          }
          break;
        case QueueActionType.ADD_TO_SYNC_SCHEDULE:
          addToUploadingPlayerTrackingSchedule({
            mixinTrackerRef,
            trackingDataArg
          });
          break;
        case QueueActionType.FLUSH:
          throttleSyncPlayerTrackingToServer.flush();
          break;
        case QueueActionType.BREAK:
          return;
      }
    });
  } else {
    tasksQueue.push({
      callback,
      trackingDataArg
    });
  }

  if (config.isSyning) {
    return;
  }

  while (tasksQueue.length) {
    const task = tasksQueue.shift();
    if (typeof task.callback === "function") {
      task.callback();

      addToUploadingPlayerTrackingSchedule({
        mixinTrackerRef,
        trackingDataArg
      });
    }
  }
}

const VSyncPlayerTracking = {
  install(Vue) {
    const name = "syncPlayerTracking";

    Vue.prototype[`$${name}`] = {
      forceSyncAllTrackingInfoToServer: () => {
        throttleSyncPlayerTrackingToServer.flush();
      },
      addUpdateTrackingDataTaskOfUserToQueue,
      bindingGetVideoPlayerPauseArgs: getArgs => {
        config.getVideoPlalyerEventArgs.pause = getArgs;
      },
      bindingGetVideoPlayerPlayArgs: getArgs => {
        config.getVideoPlalyerEventArgs.play = getArgs;
      }
    };
  }
};

export default VSyncPlayerTracking;
