export const playerRoutes = [
  "NavigatorMain",
  "NavigatorHome",
  "MyWorkspaces",
  "Recommended",
  "WorkspaceExplorer",
  "NavigatorSkills",
  "NavigatorSkillPlayer",
  "NavigatorFavorites",
  "FavoritesWorkflows",
  "FavoritesWorkspaces",
  "FavoritesWorkspaceExplorer",
  "NavigatorSearch",
  "SharedMain",
  "PlayerMain",
  "PlayerMainFullscreen",
  "PlayerPublic",
  "PlayerFullscreenPublic",
  "UserProfileMain",
  "NavigatorWorkspaceGroup",
];

export const editSkillsRoutes = [
  "SkillsMain",
  "SkillsExplorer",
  "SkillsMatrix",
  "SkillsBuilder",
  "SkillsBuilderUsers",
  "SkillsBuilderSettings",
];

export const editorRoutes = [
  "EditorExplorer",
  "StudioWorkspaceGroup",
  "StudioWorkspace",
  "EditorExplorer",
  "EditorMain2.5",
  "Transcription",
  "Segmentation",
  "BuilderMain",
]
export const adminRoutes = [
  "AdminGroup",
  "AdminOrganization",
  "AdminMain",
  "AdminWorkspaceGroup",
  "AdminWorkspaceGroupWorkspaces",
  "AdminWorkspaceUsers",
  "AdminWorkspaceTeams",
]